import React from 'react';
import { Redirect } from 'react-router-dom';
import { CapitalizeJsonKeys, CheckObjectBoolean, CheckObjectNumber, CheckObjectStringEmpty, Delay, DelayUntil } from '../components/GlobalFunctions';
import { GlobalSetting } from '../components/GlobalSetting';
import { Button, ProgressBar } from 'react-bootstrap';
import { Locale } from '../Localization/CustomLocalization';
// import Banner_Wordwall from '../../src/Screens/Quiz/images/Banner_Wordwall.png';
import Banner_MiniGame from '../../src/Screens/Quiz/images/Banner_MiniGame.png';

// const embedTypeOptions = [
//     { id: 0, value: '', label: '(Select)' },
//     { id: 1, value: 'IFrame', label: 'IFrame' },
//     { id: 2, value: 'Thumbnail', label: 'Thumbnail' },
//     { id: 3, value: 'Small-Icon', label: 'Small Icon' },
// ];

const titleName = 'Mini Game';

//2025.03.11+
export default class WordwallEntryScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        SubjectOptions: [],
        GroupOptions: [],
        IsLoading: false,
        IsLoaded: false,

        PageTitleComponents: null,
        PageBodyComponents: null,
        SelectedSubjectId: 0,
        SelectedGroupId: 0,

        List: [],
        IsListLoading: false,
        IsListLoaded: false,
    });

    componentDidMount = async () => {
        this.props.SetScreen(this.props.Screen.WordwallEntry);
        window.scrollTo(0, 0);
        await this.GetQuizWordwallSubjectsAndGroups_ViaApi();
    }

    GotoPage = (loc = '') => {
        if (loc === '' || loc === undefined || loc === null)
            return null;
        this.setState({
            redirect: true,
            redirectLink: loc,
        });
    }

    GetQuizWordwallSubjectsAndGroups_ViaApi = async () => {

        this.props.SetLoading('', Locale("loading...", this.props.Locale), false);

        this.setState({
            SubjectOptions: [],
            GroupOptions: [],
            IsLoaded: false,
            IsLoading: true,
        });

        let subjectOptions = [];
        let groupOptions = [];
        let success = false;
        let message = '';
        let done = false;

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Wordwall/SubjectAndGroup/List';
        if (this.props.isDevMode)
            console.log(`GetQuizWordwallSubjectsAndGroups_ViaApi \n ${url}`);

        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                success = CheckObjectBoolean(data, 'Success');
                if (success) {
                    const t_data = CapitalizeJsonKeys(data.data);
                    if (Array.isArray(t_data['Subjects']) && t_data['Subjects'].length > 0)
                        subjectOptions = t_data['Subjects'];
                    if (Array.isArray(t_data['Groups']) && t_data['Groups'].length > 0)
                        groupOptions = t_data['Groups'];
                }
                else {
                    message = CheckObjectStringEmpty(data, 'Message');
                }
                done = true;
                if (this.props.isDevMode)
                    if (data.success)
                        console.log('api - wordwall settings - fetch (success)\n' + JSON.stringify(data));
                    else
                        console.log('Error', 'api - wordwall settings - fetch (failed)\n' + JSON.stringify(data));
            })
            .catch(error => {
                message = error.message;
                done = true;
                if (this.props.isDevMode)
                    console.log('Error', 'api - wordwall settings - fetch (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        if (success) {
            if (Array.isArray(subjectOptions) && subjectOptions.length > 0) {
                let t_subjectOptions = [];
                subjectOptions.forEach((item) => {
                    t_subjectOptions.push({ id: CheckObjectNumber(item, 'Id'), label: CheckObjectStringEmpty(item, 'Name'), value: CheckObjectNumber(item, 'Id') });
                });
                subjectOptions = t_subjectOptions;
            }
            if (Array.isArray(groupOptions) && groupOptions.length > 0) {
                let t_groupOptions = [];
                groupOptions.forEach((item) => {
                    t_groupOptions.push({ id: CheckObjectNumber(item, 'Id'), label: CheckObjectStringEmpty(item, 'Name'), value: CheckObjectNumber(item, 'Id') });
                });
                groupOptions = t_groupOptions;
            }
            this.props.CloseAlert();
        }
        else {
            this.props.SetAlert(Locale("invalid-info", this.props.Locale), message.join('<br />'), true);
        }

        this.setState({
            SubjectOptions: subjectOptions,
            GroupOptions: groupOptions,
            IsLoaded: true,
            IsLoading: false,
        }, () => {
            if (success)
                this.PopulatePageUi();
        });
    }

    PopulatePageUi = () => {
        let titleComponents = [];
        let bodyComponents = [];
        const subjectOptions = this.state.SubjectOptions;
        const groupOptions = this.state.GroupOptions;
        const selectedSubjectId = this.state.SelectedSubjectId;
        const selectedGroupId = this.state.SelectedGroupId;
        if (!this.state.IsLoading && this.state.IsLoaded) {
            let tbodies = [];
            const arrowIcon = <div><i className="fa fa-angle-right"></i><i className="fa fa-angle-right"></i></div>;
            const title = <span className="start no-us">{titleName}</span>;
            //loaded.
            if (selectedSubjectId === 0 && selectedGroupId === 0) {
                //nothing is selected.
                // titleComponents = <>{titleName}{arrowIcon}<span className='no-us'>Select Subject</span></>;
                titleComponents = <><div style={{ display: 'flex', justifyContent: 'left', gap: 10 }}>{title}{arrowIcon}<span className='no-us'>Select Subject</span></div></>;
                //tbody.
                subjectOptions.forEach((data, key) => {
                    tbodies.push(<tr>
                        <td>{key + 1}</td>
                        <td className="left">{CheckObjectStringEmpty(data, 'label', '-')}</td>
                        <td style={{ textAlign: 'center' }}><Button variant="primary" onClick={() => this.SetSelectedOptions('subjectId', CheckObjectNumber(data, 'id'))}>Select</Button></td>
                    </tr>)
                });
                bodyComponents.push(<thead><tr><th width='50'>#</th><th className="left">Group</th><th width='75' style={{ textAlign: 'center' }}>Action</th></tr></thead>);
                bodyComponents.push(<tbody>{tbodies}</tbody>);
            }
            else if (selectedSubjectId > 0 && selectedGroupId === 0) {
                //selected subject, not group.
                const subjectLabel = subjectOptions.find(x => x.id === this.state.SelectedSubjectId).label;
                const titleButton_Subject = <button className="btn-link mw65" title="Back to Select Subject" onClick={() => this.SetSelectedOptions('reset')}>{subjectLabel}</button>;
                const titleButton_BackToSelectSubject = <button className="btn-link mw65" title="Back to Select Subject" onClick={() => this.SetSelectedOptions('reset')}>back</button>;
                // titleComponents = <>{title}{arrowIcon}{titleButton_Subject}{arrowIcon}<span className='no-us'>Select Group</span></>;
                titleComponents = <>
                    <div style={{ display: 'flex', justifyContent: 'left', gap: 10, flex: 2, }}>{title}{arrowIcon}{titleButton_Subject}{arrowIcon}<span className='no-us'>Select Group</span></div>
                    <div style={{ display: 'flex', justifyContent: 'right', gap: 10, flex: 1, }}>{titleButton_BackToSelectSubject}</div>
                </>;
                //tbody.
                groupOptions.forEach((data, key) => {
                    tbodies.push(<tr>
                        <td>{key + 1}</td>
                        <td className="left">{CheckObjectStringEmpty(data, 'label', '-')}</td>
                        <td style={{ textAlign: 'center' }}><Button variant="primary" onClick={() => this.SetSelectedOptions('groupId', CheckObjectNumber(data, 'id'))}>Select</Button></td>
                    </tr>)
                });
                bodyComponents.push(<thead><tr><th width='50'>#</th><th className="left">Subject</th><th width='75' style={{ textAlign: 'center' }}>Action</th></tr></thead>);
                bodyComponents.push(<tbody>{tbodies}</tbody>);
            }
            else if (selectedSubjectId > 0 && selectedGroupId > 0) {
                //selected subject & group.
                const subjectLabel = subjectOptions.find(x => x.id === this.state.SelectedSubjectId).label;
                const titleButton_Subject = <button className="btn-link mw65" title="Back to Select Subject" onClick={() => this.SetSelectedOptions('reset')}>{subjectLabel}</button>;
                const groupLabel = groupOptions.find(x => x.id === this.state.SelectedGroupId).label;
                const titleButton_Group = <button className="btn-link mw65" title="Back to Select Group" onClick={() => this.SetSelectedOptions('resetGroup')}>{groupLabel}</button>;
                const titleButton_BackToSelectGroup = <button className="btn-link mw65" title="Back to Select Group" onClick={() => this.SetSelectedOptions('resetGroup')}>back</button>;
                // titleComponents = <>{title}{arrowIcon}{titleButton_Subject}{arrowIcon}{titleButton_Group}</>;
                titleComponents = <>
                    <div style={{ display: 'flex', justifyContent: 'left', gap: 10, flex: 2, }}>{title}{arrowIcon}{titleButton_Subject}{arrowIcon}{titleButton_Group}</div>
                    <div style={{ display: 'flex', justifyContent: 'right', gap: 10, flex: 1, }}>{titleButton_BackToSelectGroup}</div>
                </>;
                //thead.
                bodyComponents.push(<thead><tr>
                    <th width='50' align='center'>#</th>
                    <th className="left" colSpan={2}>Name</th>
                    {/* <th width='125'>Embed Type</th> */}
                    {/* <th width='125'>Embed Codes</th> */}
                    {/* <th width='125'>Remark</th> */}
                    {/* <th width='125'>Always On Top</th> */}
                    {/* <th width='115'>Display Order</th> */}
                    {/* <th width='115'>Active</th> */}
                    {/* <th width='75'>Action</th> */}
                </tr></thead>);
                //tbody.
                if (this.state.IsListLoading && this.state.IsListLoaded === false) {
                    //loading.
                    tbodies.push(<tr><td colSpan='15' height={63}><ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10 }} /></td></tr>);
                }
                else if (this.state.IsListLoading === false && this.state.IsListLoaded) {
                    if (this.state.List.length === 0) {
                        //empty list.
                        tbodies.push(<tr><td colSpan='15' align='center'>- coming soon -</td></tr>);
                    }
                    else {
                        //Wordwall list.
                        this.state.List.forEach((item, key) => {
                            console.log(JSON.stringify(item));
                            const remark = CheckObjectStringEmpty(item, 'Remark');
                            const embedCodes = CheckObjectStringEmpty(item, 'EmbedCodes');
                            if (embedCodes !== '') {
                                tbodies.push(<tr>
                                    <td align='center'>{key + 1}</td>
                                    <td><div dangerouslySetInnerHTML={{ __html: embedCodes.replace('margin-bottom:-7px;', '') }}></div></td>
                                    <td width={25}>
                                        <button className='btn-link' type='button'
                                            onClick={() => this.props.SetAlert('Remark', remark, true)}
                                            disabled={remark === '' ? true : false}
                                            hidden={remark === '' ? true : false}
                                        ><i className='fa fa-info-circle icon-f20p0'></i></button>
                                    </td>
                                </tr>);
                            }
                        });
                    }
                }
                bodyComponents.push(<tbody>{tbodies}</tbody>);
            }
        }
        else {
            //loading.
        }
        this.setState({
            PageTitleComponents: titleComponents,
            PageBodyComponents: bodyComponents,
        });
    }

    SetSelectedOptions = (mode = '', value = null) => {
        if (mode === '' || mode === undefined)
            return null;
        let selectedSubjectId = this.state.SelectedSubjectId;
        let selectedGroupId = this.state.SelectedGroupId;
        switch (mode) {
            case 'reset': selectedSubjectId = 0; selectedGroupId = 0; break;
            case 'resetGroup': selectedGroupId = 0; break;
            case 'subjectId': selectedSubjectId = typeof value === 'number' ? Number(value) : 0; break;
            case 'groupId': selectedGroupId = typeof value === 'number' ? Number(value) : 0; break;
            default: break;
        }
        this.setState({
            SelectedSubjectId: selectedSubjectId,
            SelectedGroupId: selectedGroupId,
            List: [],
        }, () => {
            this.PopulatePageUi();
            if (mode === 'groupId')
                this.LoadWordwallList_ViaApi();
        });
    }

    LoadWordwallList_ViaApi = async () => {
        this.setState({
            List: [],
            TotalRows: 0,
            IsListLoading: true,
            IsListLoaded: false,
        });
        // window.scrollTo(0, 0);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Wordwall/List';

        const postData = JSON.stringify({
            SubjectId: this.state.SelectedSubjectId,
            GroupId: this.state.SelectedGroupId,
            IsActive: null,
        });
        if (this.props.isDevMode) {
            console.log('LoadWordwallList_ViaApi (url) \n' + url);
            console.log('LoadWordwallList_ViaApi (postData) \n' + postData);
        }

        let done = false;
        let _List = [];

        await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: postData,
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('LoadWordwallList_ViaApi (response)\n' + JSON.stringify(data));
                if (data.success) {
                    const result = JSON.parse(JSON.stringify(data));
                    if (result.data !== undefined && Array.isArray(result.data) && result.data.length > 0) {
                        result.data.forEach((item, key) => {
                            _List.push(this.PopulateListModal(item));
                        });
                    }
                    else {
                        if (this.props.isDevMode)
                            console.log('list is empty.');
                    }
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - wordwall - load list (failed)\n' + JSON.stringify(data));
                }
                done = true;
            })
            .catch(error => {
                done = true;
                if (this.props.isDevMode)
                    console.log('Error', 'api - wordwall - load list (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        this.setState({
            List: _List,
            IsListLoading: false,
            IsListLoaded: true,
        }, async () => {
            await Delay(0);
            this.PopulatePageUi();
            if (_List.length > 0) {
                const pageTitle = document.getElementById('pre-page-title');
                if (pageTitle !== null)
                    pageTitle.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
            }
        });
    }

    PopulateListModal = (modal = null) => {
        return {
            id: CheckObjectNumber(modal, 'Id'),
            subjectId: CheckObjectNumber(modal, 'SubjectId'),
            groupId: CheckObjectNumber(modal, 'GroupId'),

            name: CheckObjectStringEmpty(modal, 'Name'),
            remark: CheckObjectStringEmpty(modal, 'Remark'),

            embedType: CheckObjectStringEmpty(modal, 'EmbedType'),
            embedCodes: CheckObjectStringEmpty(modal, 'EmbedCodes'),

            alwaysOnTop: CheckObjectBoolean(modal, 'AlwaysOnTop'),
            displayOrder: CheckObjectNumber(modal, 'DisplayOrder'),
            active: CheckObjectBoolean(modal, 'Active'),
        };
    }

    render() {
        if (!this.props.user) {
            return <Redirect to="/login" />;   //back to login screen.
        }
        else if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <div style={{
                    backgroundColor: 'transparent',
                    minHeight: '100%',
                    width: '100%',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    paddingTop: window.innerWidth < 576 ? 5 : 25,
                }}>
                    <div className="container" style={{ maxWidth: '650px', backgroundColor: 'lavender', paddingTop: 15, paddingBottom: 15, borderRadius: 5 }}>
                        <div className="row">

                            <aside className="col-sm-12">
                                <div className="card" style={{ backgroundColor: '#007bff' }}>
                                    <article className="card-body text-center">
                                        <span style={{
                                            color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                            float: "left"
                                        }}>{titleName}</span>
                                        {/* <LocalizationSwitcher
                                            Locale={this.props.Locale}
                                            SetLocaleSetting={this.props.SetLocaleSetting}
                                        /> */}
                                    </article>
                                </div>
                                <div className="card">
                                    <article className="card-body">
                                        <div className='row'>
                                            <div className='col'>
                                                <img src={Banner_MiniGame} alt={titleName} width={'100%'} />
                                            </div>
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col'>
                                                <button type="button" className="btn btn-primary btn-block"
                                                    onClick={() => this.setState({
                                                        redirect: true,
                                                        redirectLink: '/home',
                                                    })}
                                                >{Locale("back-to-home", this.props.Locale)}</button>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </aside>

                            <span id='pre-page-title'>&nbsp;</span>

                            <aside className="col-sm-12">
                                <div className="card">
                                    <article className="card-body">
                                        <div id='page-title' style={{ display: 'flex', flexDirection: 'row', gap: 10, fontWeight: 'bold', padding: 10 }}>{this.state.PageTitleComponents}</div>
                                        <table className='table table-hover table-borderless' style={{ userSelect: 'none' }}>
                                            {this.state.PageBodyComponents}
                                        </table>
                                    </article>
                                </div>
                            </aside>


                        </div>
                    </div>

                    <div style={{ height: '1000px', width: '100%' }}>
                        <span>&nbsp;</span>
                    </div>
                </div>
            );
        }
    }
}