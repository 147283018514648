import React from 'react';
import {
    osName, browserName, //isMobile 
} from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

// import { Button, Modal } from 'react-bootstrap';
// import _ from 'lodash';

import { Lang, Locale } from '../Localization/CustomLocalization.js';
import LocalizationSwitcher from '../Localization/LocalizationSwitcher.js';
import LoadingIndicator from './Quiz/LoadingIndicator.js';
import { CapitalizeJsonKeys, CheckObjectBoolean, CheckObjectNullValue, CheckObjectStringEmpty, Delay, DelayUntil } from '../components/GlobalFunctions';      //2023.09.11
import { GlobalSetting, isStaging } from '../components/GlobalSetting.js';
import { Button, Card } from 'react-bootstrap';
// import { Col, Row } from 'react-bootstrap';

// import Background from '../Screens/Quiz/images/Background.jpg';

// import AppCtx, { AppCtxConsumer, GetValue, SetValue } from '../AppContext';     //2022.06.16
// import AppContext, { CtxVal } from '../AppContext';
// const appCtx = useContext(AppContext);

const customEvents = [
    {
        title: 'SPOT2024 万人测评争霸赛 (SJKC)',
        // from: '1/9/2024 (Sept 1, 2024)',
        // to: '9/11/2024 (Nov 9, 2024)',
        from: '1/9/2024',
        to: '9/11/2024',
        fromF2: 'Sep 1 (Sun), 2024',
        toF2: 'Nov 9 (Sat), 2024',
        gsLink: 'https://forms.gle/Nd1wSGL15ps1r9kq5',
    },
    {
        title: 'SPOT2024 Pertandingan School & Public Online Test (SK)',
        from: '1/9/2024',
        to: '9/11/2024',
        fromF2: 'Sep 1 (Sun), 2024',
        toF2: 'Nov 9 (Sat), 2024',
        gsLink: 'https://forms.gle/emmXEu9bvTedc88M9',
    },
    {
        title: 'SPOT2024 幼儿线上自我测评 (Preschool)',
        from: '1/9/2024',
        to: '9/11/2024',
        fromF2: 'Sep 1 (Sun), 2024',
        toF2: 'Nov 9 (Sat), 2024',
        gsLink: 'https://forms.gle/c2ZeXQzCira8vmCx8',
    }
];

export default class LoginScreen extends React.Component {



    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    state = {

        redirect: false,
        redirectLink: '',

        loginEmail: '',
        loginPassword: '',
        saveLoginCredential: true,
        errorMessage: '',

        toggleLoginNotice: false,

        //Hidden feature
        // hiddenSearchText: '',
        // hiddenResultEmail: '',
        // hiddenResultList: '',
        // showModal: false,
        // showResultModal: false,

        //2020.11.23
        IsRedirectToHistoryList: false,

        //2020.11.24
        // IsNationalQuizEventSignUp: false,

        //2020.12.04
        UpcomingQuizList: [],
        // UpcomingQuizList: [
        //     {
        //         Date: 'Dec 2020, 09~11',
        //         Event: 'spot2020',
        //         Subject: 'SPOT 2020',
        //         Organizer: 'SIM Education Group',
        //         ZoomRoomId: '123456789',
        //         ZoomPassCode: '123456789',
        //     },
        //     {
        //         Date: 'TBA',
        //         Event: 'abacus-challenge',
        //         Subject: 'Abacus Challenge',
        //         Organizer: 'Smart Newton',
        //         ZoomRoomId: '',
        //         ZoomPassCode: '',
        //     },
        // ],

        //2021.02.01
        SelectedEventCode: '',

        //2021.07.14
        HideUpcomingEventList: true,

        //2021.07.24
        SelectedEvent: null,

        //2021.07.27
        ShowLoginAsAdminUi: false,

        //2021.09.03
        PageLoadingCompleted: false,

        //2025.03.27
        MediaFile_IsLoading: true,
        MediaFile_IsLoaded: false,
    }

    componentWillUnmount = () => {
        //2021.02.01
        this.props.SetScreen(this.props.Screen.None);
        this.setState({ SelectedEventCode: '' });
    }

    componentDidMount = async () => {
        // console.log('Number = ' + Number(''));
        window.scrollTo(0, 0);

        this.setState({
            loginEmail: this.props.email !== '' ? this.props.email : '',
            loginPassword: this.props.password !== '' ? this.props.password : '',
            PageLoadingCompleted: false,
        });
        // }, async () => {

        //2021.02.01
        this.props.SetScreen(this.props.Screen.LoginScreen);

        // //2020.11.30 = moved to App.
        // //Check Last Update & todo Auto-reload webpage if needed.
        // await this.CheckLastUpdate();

        // //2021.08.16
        // await DelayUntil(() => this.props.Events.length > 0 || this.props.EventNotAvailable);
        // // while (this.props.Events.length <= 0) {
        // //     await Delay(500);
        // // }

        //2021.10.16
        await DelayUntil(() => this.props.IsActiveEventListLoaded === true);

        //2020.12.04
        let events = [];
        this.props.Events.map((data, key) => {
            if (data.Active === true) {
                let _IsEnded = this.CheckIfEventIsEnded(data);  //2020.12.14
                let _IsEnded_Registration = moment() >= moment(data.RegistrationEnd);  //2021.02.01

                // if (data.Role !== 'Admin')  //2021.04.03 - add condition on showing events.
                if (_IsEnded === false && _IsEnded_Registration === false)  //2021.11.19
                    events.push({
                        Date: data.Date,
                        EventDate: data.DateStart,  //2020.12.31
                        Event: data.Event,
                        EventCode: data.EventCode,    //2021.01.08
                        Subject: data.EventName,
                        Organizer: data.Organizer,
                        // ZoomRoomId: data.ZoomRoomId,
                        // ZoomPassCode: data.ZoomPassCode,
                        // ZoomUrl: data.ZoomUrl,
                        QuizRooms: data.QuizRooms,

                        //2020.12.14
                        Active: data.Active,
                        IsEnded: _IsEnded,

                        //202101.13
                        ZoomInfo: data.ZoomInfo,

                        //2021.02.01
                        RegistrationIsEnded: _IsEnded_Registration,
                        RegistrationEnd: data.RegistrationEnd,

                        //2021.11.10
                        RegistrationOverloaded: data.hasOwnProperty('RegistrationOverloaded') ? data.RegistrationOverloaded : false,

                        //2021.04.02
                        RegistrationEndDisplayName: data.RegistrationEndDisplayName,

                        //2021.02.10
                        // ExtraGradeSetting: data.ExtraGradeSetting,

                        //2021.03.17
                        RoomsHeldOnSelfTime: data.RoomsHeldOnSelfTime,
                        // IsPublic: data.IsPublic,

                        //2021.11.26
                        IsPublic: data.Role === 'Admin' || data.Role === 'Global' ? true : false,

                        //2021.03.18
                        OrganizerDisplayName: data.hasOwnProperty('OrganizerDisplayName') ? data.OrganizerDisplayName : data.Organizer,

                        //2021.07.14
                        OrganizerIdentity: data.hasOwnProperty('OrganizerIdentity') ? data.OrganizerIdentity : '',
                    });
            }
            return null;
        });

        //sort events by date, future at first.     //2020.12.31
        // events.sort(function (a, b) {
        //     if (moment(a.EventDate, 'YYYY-MM-DD') < moment(b.EventDate, 'YYYY-MM-DD')) {
        //         // return 1;   //future first
        //         return -1;  //coming first
        //     }
        //     if (moment(a.EventDate, 'YYYY-MM-DD') > moment(b.EventDate, 'YYYY-MM-DD')) {
        //         // return -1;  //future first
        //         return 1;   //coming first
        //     }
        //     //2021.08.13 - combined become 1 loop.
        //     if (moment() > moment(a.EventDate, 'YYYY-MM-DD')) {
        //         return 1;
        //     }
        //     if (moment() < moment(a.EventDate, 'YYYY-MM-DD')) {
        //         return -1;
        //     }
        //     return 0;
        // });
        events.sort((a, b) => moment(a.EventDate, 'YYYY-MM-DD') - moment(b.EventDate, 'YYYY-MM-DD'));

        //2021.11.19
        this.props.SetEventNotAvailable(events.length === 0);

        // //2021.01.14    //sort again   //sort overdue at last.
        // events.sort(function (a, b) {
        //     if (moment() > moment(a.EventDate, 'YYYY-MM-DD')) {
        //         return 1;
        //     }
        //     if (moment() < moment(a.EventDate, 'YYYY-MM-DD')) {
        //         return -1;
        //     }
        //     return 0;
        // });
        this.setState({
            UpcomingQuizList: events,
        });
        await Delay(500);

        if (!this.props.isFromParentApp) {
            this.LoadLoginDetailFromLocal();
        }

        // if (browserName.toLowerCase().includes('chrome') === false) {
        //     setTimeout(() => {
        //         // this.SetAlert(browserName + " - current browser is not recommended", "Seems like you're not using the recommended browser.<br /><br />Please use <b>Chrome</b> (Google) browser for best experience.");
        //         this.props.SetAlert(browserName + " - current browser is not recommended", "Seems like you're not using the recommended browser.<br /><br />Please use <b>Chrome</b> (Google) browser for best experience.");
        //     }, 1000);
        // }

        if (osName.toLowerCase().includes('ios') || osName.toLowerCase().includes('android')) {
            if (browserName.toLowerCase().includes('firefox') === true) {
                setTimeout(() => {
                    // this.SetAlert(browserName + " - current browser is not recommended", "Seems like you're not using the recommended browser.<br /><br />Please use <b>Chrome</b> (Google) browser for best experience.");
                    this.props.SetAlert(browserName + " - current browser is not recommended", "Seems like you're not using the recommended browser.<br /><br />Please use <b>Chrome</b> (Google) browser for best experience.");
                }, 1000);
            }
        }

        // alert(this.props.isFromParentApp);
        // setTimeout(() => {
        //     this.props.SetAlert('isFromParentApp', this.props.isFromParentApp);
        // }, 1000);

        //new added 2020.11.20
        // setTimeout(() => {
        await this.LocationPathParamsChecker();
        //     }, 0);
        // });

        // if (isMobile) {
        //     // alert('Login :' + isMobile);
        //     let bypass = localStorage.getItem('MobileSignUpPageReturnBypass');
        //     if (bypass === 'none') {
        //         localStorage.setItem('MobileSignUpPageReturnBypass', 'bypass');
        //         // this.setState({
        //         //     redirect: true,
        //         //     redirectLink: '/signup',
        //         // });
        //         if (this.props.OrganizerIdentity !== '') {
        //             window.localtion.href = 'https://livequiz.ikeyedutech.com.my/' + this.props.OrganizerIdentity;
        //         }
        //         else {
        //             this.setState({
        //                 redirect: true,
        //                 redirectLink: '/signup',
        //             });
        //         }
        //     }
        // }

        //2025.03.24 - load media files
        await this.LoadMediaFiles_ViaApi();

        //done.
        this.setState({ PageLoadingCompleted: true, });
    }


    // //2020.11.30 = moved to App.
    // CheckLastUpdate = async () => {
    //     // let _needReload = false;
    //     let _lastUpdate_fetched = '';
    //     let _error = '';

    //     await this.props.firestore
    //         .collection('LastUpdate')
    //         .where('Id', '==', 'LiveQuizWeb')
    //         .get()
    //         .then((querySnapshot) => {
    //             let data = [];
    //             if (querySnapshot !== null) {
    //                 querySnapshot.forEach((doc) => {
    //                     data.push(doc.data());
    //                 });
    //             }
    //             if (data.length > 0) {
    //                 _lastUpdate_fetched = data[0]['Date'];
    //             }
    //         })
    //         .catch(async (error) => {
    //             // await this.props.SetErrorLog(new Date(), "LoginScreen | CheckLastUpdate | Error = " + error);
    //             _error = error;
    //         });

    //     if (_lastUpdate_fetched.length > 0) {
    //         //Auto-Reload the webpage if found WebApp's LU is older than fetched LU.
    //         if (moment(this.props.LastUpdateDate) < moment(_lastUpdate_fetched)) {
    //             window.location.reload();
    //         }
    //         // alert("Current Build LU = " + this.props.LastUpdateDate + "\n\n" + "System Build LU = " + _lastUpdate_fetched);
    //     }

    //     alert(
    //         "Current Build LU = " + this.props.LastUpdateDate + "\n\n"
    //         + "System Build LU = " + _lastUpdate_fetched
    //         + "\n\n Error = " + _error
    //     );
    // }


    //=== location path params checker ===//

    //new added 2020.11.23
    LocationPathParamsChecker = async () => {

        // if (isMobile) {
        //     if (this.props.OrganizerIdentity !== '') {

        //     }
        // }

        let isLoginParam = false;
        let isSignUpParam = false;
        let isFromParentApp = false;
        let isRedirectToHistoryList = false;
        let isParticipatedEvent = false;

        //2020.11.24
        // let isNationalQuizEventSignUp = false;

        //2020.12.03
        let isEventParam = false;
        // let isSpot2020EventSignUp = false;
        let eventCode = '';

        //2021.07.01
        let isUniqueEventParam = false;
        let uniqueEventCode = '';

        //2021.11.12
        let isSpecialPage = false;
        let specialPageValue = '';

        let emailFoundInPath = '';
        // let params = window.location.search.split('?');
        // params = params.filter((el) => { return (el !== ''); });    //exclude empty.
        let params = window.location.search.replace('?', '');
        params = params.split('&');
        params = params.filter((el) => { return (el !== ''); });    //exclude empty.

        params.map((data, key) => {
            let paramArray = data.includes('=') ? data.split('=') : data;
            let param = data.includes('=') ? paramArray[0].toLowerCase() : paramArray;
            let paramValue = data.includes('=') ? paramArray[1].toLowerCase() : '';
            if (param === 'login' || param === 'signup') {
                emailFoundInPath = paramValue;
                isLoginParam = param === 'login';
                isSignUpParam = param === 'signup';
            }
            if (param === 'from') {
                if (paramValue.includes('parent')) {
                    isFromParentApp = true;
                }
            }
            if (param === 'to') {
                if (paramValue.includes('history')) {
                    isRedirectToHistoryList = true;
                }
                // if (paramValue.includes('national-quiz-event-signup')) {
                //     isNationalQuizEventSignUp = true;
                // }

                //2021.04.12
                if (paramValue.includes('participated-event')) {
                    isParticipatedEvent = true;
                }
            }

            //2020.12.03
            if (param === 'event') {
                isEventParam = param === 'event';
                eventCode = paramValue;
                // alert(eventCode);
            }

            //2021.07.01
            if (param === 'special-event-code') {
                isUniqueEventParam = true;
                uniqueEventCode = paramValue;
                // alert(uniqueEventCode);
            }

            //2021.11.12
            if (param === 'special') {
                isSpecialPage = true;
                specialPageValue = paramValue;
            }

            return null;
        });
        // if (emailFoundInPath.length > 0) {


        //App   //2020.11.27
        // if (emailFoundInPath.length > 0) {
        this.props.setEmail(emailFoundInPath);
        // }
        this.props.SetIsFromParentApp(isFromParentApp);
        this.props.SetIsRedirectToHistoryList(isRedirectToHistoryList);

        //2020.12.03
        this.props.SetIsEvent(isEventParam, eventCode, isSignUpParam);

        //2021.04.12
        this.props.SetIsFromAppParticipatedEvent(isParticipatedEvent);

        //2021.07.01
        this.props.SetIsUniqueEvent(isUniqueEventParam, uniqueEventCode);

        //2021.07.14
        let _isOrganizerIdentityExist = false;
        this.state.UpcomingQuizList.map((data, key) => {
            if (String(window.location.href).includes(String(data.OrganizerIdentity))) {
                this.props.SetOrganizerIdentity(data.OrganizerIdentity);
                _isOrganizerIdentityExist = true;
            }

            //2022.06.03
            if (data.hasOwnProperty('EventCode')) {
                if (data.EventCode === eventCode) {
                    var centerUserId = 0;
                    if (data.hasOwnProperty('CenterUserId'))
                        centerUserId = Number(data.CenterUserId);
                    var authorId = 0;
                    if (data.hasOwnProperty('AuthorId'))
                        authorId = Number(data.AuthorId);
                    this.props.SetEventInfo(eventCode, centerUserId, authorId);
                }
            }
            return null;
        });
        this.setState({ HideUpcomingEventList: _isOrganizerIdentityExist });

        //others
        if (isLoginParam) {
            let _loginPassword = this.state.loginPassword;
            if (this.state.loginEmail !== emailFoundInPath) {
                _loginPassword = '';
            }
            this.setState({
                loginEmail: emailFoundInPath,       //local
                loginPassword: _loginPassword,      //2020.11.27
            });
        }
        // if (!isMobile) {
        if (isSignUpParam) {
            this.setState({
                redirectLink: '/signup',
                redirect: true,
            });
        }
        // }

        // }
        // if (isFromParentApp && isRedirectToHistoryList) {
        //     this.setState({
        //         IsRedirectToHistoryList: isRedirectToHistoryList,
        //     }, () => {
        //         this.props.SetIsFromParentApp(true);
        //     });
        // }
        // if (isNationalQuizEventSignUp) {
        //     // alert(isNationalQuizEventSignUp);
        //     this.setState({
        //         IsNationalQuizEventSignUp: isNationalQuizEventSignUp,
        //     });
        // }

        // //2021.04.12
        // if (isParticipatedEvent) {
        //     this.setState({
        //         redirectLink: "/participatedEventList",
        //         redirect: true,
        //     });
        // }
        // await Delay(500);
        // alert(_isOrganizerIdentityExist);

        //2021.11.12
        if (isSpecialPage) {
            if (specialPageValue === 'a048ba0f-d147-490d-9113-7e1b6eb76de7') {
                localStorage.setItem('special-page-mode', 'a048ba0f-d147-490d-9113-7e1b6eb76de7');
                this.setState({
                    redirectLink: '/special/ai/list/edit/room',
                    redirect: true,
                });
            }
        }

        // //2021.09.03
        // this.setState({ PageLoadingCompleted: true, });
    }

    //====================================================//


    //=== Hidden feature / hidden to public ===//
    // CheckEmailByName = async () => {
    //     let lowerCaseText = this.state.hiddenSearchText.toLowerCase();
    //     // let upperCaseText = this.state.hiddenSearchText.toUpperCase();
    //     await this.props.dbCommon.ref("Common")
    //         .orderByChild("_1Name")
    //         // .equalTo(this.state.hiddenSearchText)
    //         .startAt(lowerCaseText)
    //         // .endAt("\uf8ff")
    //         .endAt("\u{F8FF}")
    //         .once('value', snapshot => {
    //             // isEmailNotExist = !snapshot.exists;

    //             let dataSnapVal = snapshot.val();
    //             let jsonStrings = JSON.stringify(this.processSnapshotToArray(dataSnapVal));

    //             //Save.
    //             this.setState({
    //                 hiddenResultEmail: '',
    //                 hiddenResultList: jsonStrings,
    //                 showModal: false,
    //                 showResultModal: true,
    //             }, () => {
    //                 // alert(JSON.stringify(dataSnapVal));
    //                 // this.props.SetAlert("Result", this.state.hiddenResultList);
    //             });
    //         })
    //         .catch(async (error) => {
    //             this.SetAlert("Error", error.code + "<br />" + error.message);
    //             // await this.props.SetErrorLog(new Date(), "CheckEmailOnRealtimeDatabase | Error = " + error);
    //         });
    // }
    // handleCloseModal = () => {
    //     this.setState({ showModal: false });
    // }
    // handleCloseResultModal = () => {
    //     this.setState({ showResultModal: false });
    // }
    // handleSetSearchText = (event) => {
    //     this.setState({ hiddenSearchText: String(event.target.value) });
    // }
    // processSnapshotToArray = (dataSnapVal) => {

    //     let dataKeys = _(dataSnapVal).keys().value();       //key
    //     let dataValues = _(dataSnapVal).values().value();   //values

    //     let detail = {};
    //     dataKeys.map((data, key) => {
    //         return detail[data] = _(dataValues[key]).value();
    //     });

    //     return detail;
    // }
    //====================================================//

    SetLocaleSetting = (setting) => {
        this.props.SetLocaleSetting(setting);
    }

    //=== Alert === starts.
    // SetAlert = (title, content) => {
    //     this.props.SetAlert(title, content);
    // }
    //=== Alert === ends.

    handleSetEmail = (event) => {
        let email = event.target.value !== null ? String(event.target.value) : '';
        email = email.length > 0 ? email.replace(new RegExp(' ', 'g'), '') : '';
        email = email.length > 0 ? email.toLowerCase() : '';
        this.setState({
            loginEmail: email,
        });
    }

    handleSetPassword = (event) => {
        this.setState({
            loginPassword: event.target.value,
        });
    }

    handleSetSavePasword = () => {
        this.setState({
            saveLoginCredential: !this.state.saveLoginCredential,
        });
    }

    handleLogin = async () => {
        //2020.11.30
        if (this.props.isInternetReachable === false) {
            this.props.ShowInternetNotAvailableAlert();
            return null;
        }

        var msg = "";
        var isValid = true;

        //Login inputs validation.
        var isEmailEmpty = this.state.loginEmail.length <= 0;
        var isPasswordEmpty = this.state.loginPassword.length <= 0;

        if (isEmailEmpty)
            msg += "Please do not leave your <b>Email</b> empty.<br /><br />";

        if (isPasswordEmpty)
            msg += "Please do not leave your <b>Password</b> empty.<br /><br />";

        var isLoginEmailValid = true;
        if (!isEmailEmpty) {
            let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            isLoginEmailValid = regexp.test(this.state.loginEmail);
            if (!isLoginEmailValid) {
                msg += "Please enter your <b>Email</b> with the correct format.<br />(E.g. user@company.com)";
            }
        }

        if (isEmailEmpty || isPasswordEmpty || !isLoginEmailValid)
            isValid = false;

        //Proceed to login.
        if (isValid) {
            // //Save login info in local.
            // this.SaveLoginDetailsInLocal();

            // this.SetAlert(Locale("logging-in", this.props.Locale), Locale("please-wait", this.props.Locale));
            this.props.SetAlertWithProgressBar(Locale("logging-in", this.props.Locale), Locale("please-wait", this.props.Locale), true);

            await this.props.login(this.state.loginEmail, this.state.loginPassword, this.state.saveLoginCredential);;
        }
        else {
            // this.SetAlert(Locale("invalid-login-info", this.props.Locale), msg);
            this.props.SetAlert(Locale("invalid-login-info", this.props.Locale), msg);
        }
    }

    LoadLoginDetailFromLocal = () => {
        if (typeof (Storage) !== 'undefined') {
            // code for localStorage/sessionStorage.
            // alert(localStorage.getItem("testing"));

            let saveLogin = localStorage.getItem('saveLoginCredential');

            // alert(saveLogin);
            // if (saveLogin === 'checked')
            //     saveLogin = true;
            this.setState({
                saveLoginCredential: saveLogin !== null ?
                    (String(saveLogin).toLowerCase() === 'true' ? true : false)
                    : true,
            },
                () => {
                    // alert(saveLogin + " " + this.state.saveLoginCredential)
                    if (this.state.saveLoginCredential) {
                        // replace(new RegExp('</color>', 'g'), '&nbsp;</span>')

                        let savedEmail = localStorage.getItem('email');
                        // event.target.value !== null ? String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), '') : '',

                        let email = savedEmail !== null ? savedEmail.toLowerCase().replace(new RegExp(' ', 'g'), '') : '';
                        let password = localStorage.getItem('password');
                        // alert(email + "\n" + password);

                        this.setState({
                            loginEmail: email,
                            loginPassword: password,
                        }, () => {
                            this.props.setEmailPassword(email, password);
                            // setTimeout(() => {
                            //     alert(this.props.email + "\n" + this.props.password);
                            // }, 1000);                            
                        });
                    }
                    else {
                        // setTimeout(() => {
                        //     alert(this.props.email + "\n" + this.props.password);
                        // }, 1000);
                    }
                });
        } else {
            // Sorry! No Web Storage support..      
        }
    }

    SaveLoginDetailsInLocal = () => {
        if (typeof (Storage) !== 'undefined') {
            // code for localStorage/sessionStorage.
            localStorage.setItem('saveLoginCredential', this.state.saveLoginCredential);
            if (this.state.saveLoginCredential) {
                if (this.state.loginEmail.length > 0)
                    localStorage.setItem('email', this.state.loginEmail);
                if (this.state.loginPassword.length > 0)
                    localStorage.setItem('password', this.state.loginPassword);
            }
            else {
                localStorage.setItem('email', '');
                localStorage.setItem('password', '');
            }
        } else {
            // Sorry! No Web Storage support..
        }
    }

    // GotoEventSignUp = (eventIsEnded, eventDate, eventCode, registrationIsEnded) => {
    //     // if (eventDate !== 'TBA') {
    //     if (eventIsEnded === false) {
    //         window.location.href = window.location.href + '?signup&event=' + eventCode;
    //     }
    // }

    //2021.02.01
    GotoSignUp = () => {
        localStorage.removeItem('RgistrationDataViaLoginEventList');
        localStorage.setItem('EnterFromLogin', 'true');
        this.setState({
            redirect: true,
            redirectLink: '/signup',
        });
    }

    //2021.02.01
    GotoEventSignUp = async () => {
        // alert(JSON.stringify(this.state.SelectedEvent));
        //2021.08.25
        await DelayUntil(() => this.props.Events.length > 0);

        if (this.state.SelectedEvent !== null) {
            if (this.state.SelectedEvent.IsEnded === false) {
                localStorage.setItem('EnterFromLogin', 'true');
                localStorage.setItem('SelectedEventCode', this.state.SelectedEvent.EventCode);

                //2022.05.12
                // this.props.SetIsEvent(true, this.state.SelectedEvent.EventCode, true);  //isEvent, Code, isReg. == assigned by LocalParamChecker
                this.props.SetOrganizerIdentity(this.state.SelectedEvent.OrganizerIdentity);

                let paramSymbol = window.location.href.includes('?') ? '&' : '?';
                window.location.href = window.location.href + paramSymbol + 'signup&event=' + this.state.SelectedEvent.EventCode;

                // if (isMobile) {
                //     // this.LocationPathParamsChecker();
                //     // window.stop();
                //     setTimeout(() => {
                //         this.setState({
                //             redirect: true,
                //             redirectLink: '/signup',
                //         });
                //     }, 1000);
                //     // return <Redirect to={'/signup'} />;
                // }

                // let location = window.location.href + '?signup&event=' + this.state.SelectedEvent.EventCode;
                // localStorage.setItem('EnterFromLogin_Location', location);
                // this.setState({
                //     redirect: true,
                //     redirectLink: '/signup',
                // });
            }
        }
    }

    //2021.02.01
    CheckEventSignUp = (event) => {
        // this.SetSelectedEvent(event);
        // alert(JSON.stringify(this.state.SelectedEvent));

        //2021.07.24 edited.
        this.setState({ SelectedEvent: event },
            () => {
                if (event.IsEnded === false) {

                    // let msg = '';
                    // if (!event.RegistrationIsEnded) {
                    //     msg = Locale("event-registration-end-datetime", this.props.Locale)
                    //         + moment(event.RegistrationEnd).format('DD MMM YYYY hh:mm A');
                    // }
                    // else {
                    //     msg = Locale("event-registration-ended", this.props.Locale)
                    //         + moment(event.RegistrationEnd).format('DD MMM YYYY hh:mm A');
                    // }


                    //2021.04.02
                    let registrationEnd = event.hasOwnProperty('RegistrationEndDisplayName') ?
                        String(event.RegistrationEndDisplayName).length > 0 ?
                            event.RegistrationEndDisplayName
                            : event.RegistrationEnd
                        : event.RegistrationEnd;

                    let time = '';
                    if (this.props.Locale === Lang.English)
                        time = moment(registrationEnd).format('DD MMMM [at] hh:mm A.');
                    else
                        time = moment(registrationEnd).format('DD MMM YYYY hh:mm A');

                    //2021.02.18
                    // let time = '';
                    // if (this.props.Locale === Lang.English)
                    //     time = moment(event.RegistrationEnd).format('DD MMMM [at] hh:mm A.');
                    // else
                    //     time = moment(event.RegistrationEnd).format('DD MMM YYYY hh:mm A');

                    let msg = '';
                    if (!event.RegistrationIsEnded) {
                        msg = Locale("event-registration-end-datetime", this.props.Locale) + time;
                    }
                    else {
                        msg = Locale("event-registration-ended", this.props.Locale) + time;
                    }

                    //2021.11.10
                    if (event.hasOwnProperty('RegistrationOverloaded')) {
                        if (event.RegistrationOverloaded === true) {
                            event.RegistrationIsEnded = true;
                            msg = Locale("event-registration-overloaded", this.props.Locale);
                        }
                    }

                    //2022.06.16
                    localStorage.setItem('RgistrationDataViaLoginEventList', JSON.stringify({
                        EventCode: event.EventCode,
                        AuthorId: event.AuthorId,
                        CenterUserId: event.CenterUserId,
                    }));

                    this.props.SetAlertConfirm(event.Subject + ' - ' + event.OrganizerDisplayName, msg,
                        // feedbackType, showYes, showNo, showConfirm, showCancel, showClose
                        this.props.Feedback.Confirm, false, false, !event.RegistrationIsEnded, false, event.RegistrationIsEnded
                    );
                }
            });
    }

    // //2021.02.01
    // SetSelectedEvent = (event) => {
    //     this.setState({ SelectedEvent: event });
    // }

    CheckIfEventIsEnded = (data) => {
        let isEnded = false;
        if (String(data.Date) !== 'TBA') {
            if (moment() >= moment(String(data.DateEnd)).add(1, 'day'))
                isEnded = true;
        }
        return isEnded;
    }

    // OpenZoomRoomInfo = (eventDate, eventName, actualEventName) => {
    //     let _popupText = '';
    //     let _eventModal = null;

    //     this.state.UpcomingQuizList.map((data, key) => {
    //         if (data.Event === eventName) {
    //             _eventModal = this.state.UpcomingQuizList[key];
    //         }
    //         return null;
    //     })

    //     if (_eventModal !== null && _eventModal.Date !== 'TBA') {
    //         // _popupText = "<div className='row'>"
    //         //     + "<div className='col'>Zoom Room Id</div><div className='col'>:</div><div className='col'>" + _eventModal.ZoomRoomId + "</div>"
    //         //     + "</div><div className='row'>"
    //         //     + "<div className='col'>Zoom Passcode</div><div className='col'>:</div><div className='col'>" + _eventModal.ZoomPassCode + "</div>"
    //         //     + "</div>"
    //         //     ;
    //         // <span style={{
    //         //     fontSize: 20
    //         // }}></span>

    //         _popupText = "<table width='100%'>"
    //             + "<tr>"
    //             + "<td width='10%' align='center'>"
    //             + ""
    //             + "</td>"
    //             + "</tr>"

    //             + "<tr>"
    //             + "<td width='45%' align='right'>Room Id</td>"
    //             + "<td width='10%' align='center'>:</td>"
    //             + "<td width='45%'>" + _eventModal.ZoomRoomId + "</td>"
    //             + "</tr>"

    //             + "<tr>"
    //             + "<td width='45%' align='right'>Passcode</td>"
    //             + "<td width='10%' align='center'>:</td>"
    //             + "<td width='45%'>" + _eventModal.ZoomPassCode + "</td>"
    //             + "</tr>"
    //             + "</table>"
    //             ;

    //         this.props.SetAlert(actualEventName, _popupText);
    //     }
    // }


    //2021.01.26
    ShowVideoPopup = () => {
        let _videoWidth = window.innerWidth - 60;   //560
        _videoWidth = _videoWidth > 460 ? 460 : _videoWidth;
        let _videoHeight = 315
        let _videoLink = 'https://www.youtube.com/embed/XqsmGwLEPsc';
        let _modalContent = '<center><iframe width="' + _videoWidth + '" height="' + _videoHeight + '" src="' + _videoLink + '" frameborder="0" allow="autoplay;" allowfullscreen></iframe></center>';
        // this.props.SetAlert("Video Guide - iKEY Live Quiz", _modalContent);
        return _modalContent;
    }

    // //2022.06.16 - testing
    // GetContextEventCode_Single = () => {
    //     // return <AppCtxConsumer>{() => GetValue('EventCode')}</AppCtxConsumer>;
    //     return appCtx.getValue(CtxVal.EventCode);
    // }
    // GetContextEventCode_Many = () => {
    //     // let appCtx = this.context;
    //     // const eventCode1 = appCtx.EventCode;
    //     // const eventCode2 = appCtx.EventCode;
    //     // const eventCode2 = GetValue('EventCode');
    //     // const eventCode1 = <AppCtxConsumer>{() => GetValue('EventCode')}</AppCtxConsumer>;
    //     // const eventCode2 = <AppCtxConsumer>{() => GetValue('EventCode')}</AppCtxConsumer>;

    //     let value1 = appCtx.getValue(CtxVal.EventCode);
    //     let value2 = appCtx.getValue(CtxVal.RoomCode);
    //     // <AppCtxConsumer>
    //     //     {value1 = () => GetValue('RoomCode')}
    //     //     {value2 = () => GetValue('EventCode')}
    //     // </AppCtxConsumer>
    //     return value1 + ' | ' + value2;

    //     // return '(' + value1.length + ') ' + value2;
    // }

    //2025.03.24+
    LoadMediaFiles_ViaApi = async () => {
        this.setState({
            MediaFile_IsLoading: true,
            MediaFile_IsLoaded: false,
        });
        let mediaFiles = [];
        let success = false;
        let done = false;
        let errorMessage = '';
        const json = JSON.stringify({
            OrganizerId: 0,
            AuthorId: 0,
            IsPrivate: false,
            Display: true,
            Platform: 'LiveQuiz',
        });
        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Organizer/Media/List',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: json,
            })
            .then(res => res.json())
            .then((data) => {
                if (this.props.isDevMode)
                    console.log('api login (response) =\n' + JSON.stringify(data));
                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    if (Array.isArray(data.data))
                        mediaFiles = CapitalizeJsonKeys(data.data);
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    if (this.props.isDevMode)
                        console.log('api - media files (failed) =\n' + errorMessage);
                }
                done = true;
            })
            .catch(error => {
                errorMessage = error.message;
                done = true;
                if (this.props.isDevMode)
                    console.log('api - media files (error) =\n' + error.message);
            });
        await DelayUntil(() => done === true);
        // await Delay(2000);
        if (success)
            this.props.SetMediaFiles(mediaFiles);

        this.setState({
            MediaFile_IsLoading: false,
            MediaFile_IsLoaded: true,
        });
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            if (this.props.user) {
                //Save login info in local.
                this.SaveLoginDetailsInLocal();     //2020.11.27

                //Redirection after login is successful.
                // if (this.state.IsRedirectToHistoryList) {
                // if (this.props.isRedirectToHistoryList) {
                //     return <Redirect to='/history' />;
                // }
                // else {
                //     return <Redirect to='/home' />;
                // }

                // //2020.11.27
                // return <Redirect to='/home' />;

                // //2021.08.13
                // if (this.props.isSuperAdmin)
                //     return <Redirect to='/admin' />;
                // else
                //     return <Redirect to='/home' />;

                //2023.11.30
                if (this.props.user.ForceResetPassword)
                    return <Redirect to='/organizer/changePassword' />;

                //2021.10.13
                if (this.props.isSuperAdmin)
                    return <Redirect to='/admin' />;
                else if (this.props.isAuthor)
                    return <Redirect to='/admin/quiz/management' />;
                else
                    return <Redirect to='/home' />;
            }
            else {
                if (this.state.IsNationalQuizEventSignUp) {
                    return <Redirect to='/national_quiz_event_signup' />;
                }
                else {
                    return (
                        <>
                            {/* <div
                                style={{
                                    position: "absolute",
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    top: 0,
                                    justifyContent: "center"
                                }}
                            >
                                <img src={Background} alt="Live Quiz" width={window.innerWidth} height={window.innerHeight} />
                            </div> */}

                            <div style={{
                                // backgroundImage: { Banner },
                                // backgroundColor: 'lavender',
                                backgroundColor: 'transparent',
                                height: '100%',
                                width: '100%',
                                position: 'absolute',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 0,
                                paddingTop: 25,
                            }}>
                                <div className="container" style={{ maxWidth: '550px' }}>
                                    <div className="row">
                                        <aside className="col-sm-12">
                                            <p style={{
                                                color: 'lavender', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                // textShadow: '1px 1px 1px red'
                                            }}>iKEY Live Quiz</p>

                                            {
                                                //2020.12.04
                                            }
                                            <div className="card"
                                            // hidden={this.state.HideUpcomingEventList}
                                            >
                                                <article className="card-body" style={{ padding: 25, paddingTop: 10, paddingBottom: 0 }}>

                                                    <div className='row' style={{ padding: 0, paddingBottom: 10 }}>
                                                        <div className='col-12 text-center'>
                                                            <span
                                                                style={{
                                                                    // color: 'white',
                                                                    // fontFamily: 'sans-serif',
                                                                    fontSize: 20,
                                                                    fontWeight: 'bold',
                                                                    color: '#fc3868',
                                                                }}
                                                            >{Locale("title-upcoming-quiz", this.props.Locale)}</span>
                                                        </div>
                                                    </div>

                                                    <div className='row'
                                                        // hidden={this.state.UpcomingQuizList.length > 0 || this.props.EventNotAvailable}
                                                        hidden={this.props.IsActiveEventListLoaded}
                                                        style={{ paddingBottom: 10 }}
                                                    ><LoadingIndicator /></div>

                                                    {/* <div className='row'
                                                        hidden={
                                                            this.props.IsActiveEventListLoaded && this.props.EventNotAvailable ? false : true
                                                        }
                                                        style={{ paddingBottom: 10 }}
                                                    ><span style={{ textAlign: 'center', width: '100%', }}>There is no future event available at the moment.</span></div> */}

                                                    {
                                                        //2024.09.03 - event link to google sheet.
                                                    }
                                                    <div className='row event-custom'
                                                        hidden={this.props.IsActiveEventListLoaded && this.props.EventNotAvailable ? false : true}
                                                        // hidden={moment() < moment('2024-11-10') ? false : true}
                                                        // hidden={
                                                        //     customEvents.length === 0 ? true :
                                                        //         (moment() < moment('2024-11-10') ? false : true)
                                                        // }
                                                        style={{ paddingBottom: 10 }}
                                                    >
                                                        {/* <span style={{ textAlign: 'center', width: '100%', }}>There is no future event available at the moment.</span> */}
                                                        {
                                                            customEvents.length > 0 && moment() < moment('2024-11-10') ?
                                                                customEvents.map((data, key) => {
                                                                    return (<Card className='event-card'>
                                                                        <Card.Header><Card.Title>{data.title}</Card.Title></Card.Header>
                                                                        <Card.Body>
                                                                            <table className='card-table'>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th colSpan={4}>Registration</th>
                                                                                        <td rowSpan={3} align='center' className='td-register'>
                                                                                            {/* <Card.Link href=`${data.gsLink}`>Register Here</Card.Link> */}
                                                                                            <Button variant='outline-primary' onClick={() => window.open(`${data.gsLink}`, '_blank')}>Register</Button>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr><td>From</td><td>:</td><td>{data.from}</td><td className='sub-date'>{data.fromF2}</td></tr>
                                                                                    <tr><td>To</td><td>:</td><td>{data.to}</td><td className='sub-date'>{data.toF2}</td></tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </Card.Body>
                                                                    </Card>);
                                                                })
                                                                :
                                                                <span style={{ textAlign: 'center', width: '100%', }}>There is no future event available at the moment.</span>
                                                        }
                                                    </div>

                                                    <div className='row'
                                                        hidden={this.state.UpcomingQuizList.length <= 0 || this.props.EventNotAvailable}
                                                    //this.state.UpcomingQuizList.findIndex(x => x.Event === 'ikeyteam_2021_beta_test_event') < 0
                                                    // hidden={this.props.IsActiveEventListLoaded && !this.props.EventNotAvailable}
                                                    >
                                                        <div className='col-12' style={{ padding: 10, paddingTop: 0, paddingBottom: 0 }}>

                                                            <div className="row custyle">
                                                                <table className="table table-striped custab" style={{
                                                                    fontSize: 12,
                                                                    // tableLayout: 'fixed',
                                                                    // // width: '100%',

                                                                    // //2021.04.10
                                                                    // display: 'block',
                                                                    // overflowY: 'scroll',
                                                                    // // scrollbarWidth: '15px',
                                                                    // height: '225px',
                                                                    // // overflowX: 'hidden',
                                                                    // // right: 0,

                                                                    // //2021.08.24
                                                                    // tableLayout: 'fixed',
                                                                    // display: 'block',
                                                                    // overflowY: 'scroll',
                                                                    // height: '225px',
                                                                }}>
                                                                    <thead>
                                                                        <tr>
                                                                            {/* <th width="3%">#</th>
                                                                            <th width="25%" className='text-left'>{Locale("event", this.props.Locale)}</th>
                                                                            <th width="32%" className='text-left'>{Locale("organise-by", this.props.Locale)}</th>
                                                                            <th width="24%" className='text-left'>{Locale("room-date", this.props.Locale)}</th>
                                                                            <th width="16%">{Locale("signup", this.props.Locale)}</th> */}
                                                                            {/* <th width="5%" className="sticky">#</th> */}
                                                                            <th width="30%" className='text-left sticky'>{Locale("event", this.props.Locale)}</th>
                                                                            <th width="30%" className='text-left sticky'>{Locale("organise-by", this.props.Locale)}</th>
                                                                            <th width="25%" className='text-left sticky'>{Locale("room-date", this.props.Locale)}</th>
                                                                            <th width="15%" className="sticky">{Locale("signup", this.props.Locale)}</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {
                                                                            this.state.UpcomingQuizList.map((data, key) => {
                                                                                // if (key < 15) {
                                                                                // if (moment() <= moment(data.EventDate).add(1, 'day')) {
                                                                                if (moment() <= moment(data.RegistrationEnd)) {
                                                                                    // if (data.IsPublic) {
                                                                                    if (
                                                                                        this.props.OrganizerIdentity !== '' ?
                                                                                            data.OrganizerIdentity === this.props.OrganizerIdentity
                                                                                            : data.IsPublic
                                                                                    ) {
                                                                                        return (<tr
                                                                                            key={key}
                                                                                        // onClick={() => this.EnterHistoryRoom(key)} key={key}
                                                                                        // title={Locale("click-to-enter-room", this.props.Locale) + " " + data.RoomCode}
                                                                                        >
                                                                                            {/* <td>{key + 1}</td> */}
                                                                                            <td className='text-left'>
                                                                                                {data.Subject}
                                                                                                {/* <br />
                                                                                                <span
                                                                                                    className={data.Date !== 'TBA' ? 'pointer' : ''}
                                                                                                    style={data.Date !== 'TBA' ? { color: "blue", } : {}}
                                                                                                    onClick={() => this.OpenZoomRoomInfo(data.Date, data.Event, data.Subject)}
                                                                                                >
                                                                                                    {
                                                                                                        data.Date !== 'TBA' ?
                                                                                                            <u>{Locale("more-info", this.props.Locale)}</u>
                                                                                                            :
                                                                                                            Locale("coming-soon", this.props.Locale)
                                                                                                    }
                                                                                                </span> */}
                                                                                            </td>
                                                                                            {/* <td className='text-left'>{data.Organizer}</td> */}
                                                                                            <td className='text-left'>{data.OrganizerDisplayName}</td>
                                                                                            <td className='text-left'>{data.Date}</td>
                                                                                            <td>
                                                                                                <span
                                                                                                    className={data.IsEnded !== true ? 'pointer' : ''}
                                                                                                    style={data.IsEnded !== true ? { color: "blue", } : {}}
                                                                                                    // onClick={() => this.GotoEventSignUp(data.IsEnded, data.Date, data.EventCode, data.RegistrationIsEnded)}    //data.Event to data.EventCode
                                                                                                    onClick={() => this.CheckEventSignUp(data)}     //2021.02.01
                                                                                                >
                                                                                                    {
                                                                                                        data.Active !== false ?
                                                                                                            data.IsEnded ?
                                                                                                                Locale("quiz-ended", this.props.Locale)
                                                                                                                :
                                                                                                                <u><div dangerouslySetInnerHTML={{ __html: Locale("click-here", this.props.Locale) }}></div></u>
                                                                                                            :
                                                                                                            Locale("coming-soon", this.props.Locale)
                                                                                                    }
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>);
                                                                                    }
                                                                                }
                                                                                return null;
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                {/* <div style={{ textAlign: 'center', width: '100%', marginTop: -12, paddingBottom: 15, }}>
                                                                    <span style={{ color: 'gray', fontSize: 14 }}>scroll to view for more events.</span>
                                                                </div> */}
                                                            </div>

                                                        </div>
                                                    </div>

                                                </article>
                                            </div>
                                            {/* <span>&nbsp;</span> */}
                                            <br />

                                            {/* Sponsors Logo */}
                                            <div style={{
                                                // height: this.state.HideUpcomingEventList ? 0 : 60,
                                                height: 60,
                                                width: 550,
                                                textAlign: 'center',
                                                verticalAlign: 'bottom',
                                                display: 'table-cell',
                                                // paddingBottom: 5,
                                                // border: '1px solid black',
                                            }}
                                                hidden
                                            >
                                                {/* <span
                                                    style={{
                                                        color: 'white',
                                                        textShadow: '1px 1px 1px #000000',
                                                    }}
                                                >Sponsors:</span>
                                                <div style={{ width: '100%', height: '3px' }} />
                                                <table cellPadding='0' cellSpacing='0' border='0' width='100%'>
                                                    <tbody>
                                                        <tr>
                                                            <td className="card-sponsor-mini">
                                                                <div className="card">
                                                                    <article className="card-body">
                                                                        <img
                                                                            src="https://ikeynew.blob.core.windows.net/ikeykidz/sponsor/lkl-logo-v1-2-gold.jpg"
                                                                            alt=""
                                                                            width="100%"
                                                                            height="100%"
                                                                        />
                                                                    </article>
                                                                </div>
                                                            </td>
                                                            <td>&nbsp;&nbsp;</td>
                                                            <td className="card-sponsor-mini">
                                                                <div className="card">
                                                                    <article className="card-body">
                                                                        <img
                                                                            src="https://ikeynew.blob.core.windows.net/ikeykidz/sponsor/ienergec-logo-v1-2-diamond.jpg"
                                                                            alt=""
                                                                            width="100%"
                                                                            height="100%"
                                                                        />
                                                                    </article>
                                                                </div>
                                                            </td>
                                                            <td>&nbsp;&nbsp;</td>
                                                            <td className="card-sponsor-mini">
                                                                <div className="card">
                                                                    <article className="card-body">
                                                                        <img
                                                                            src="https://ikeynew.blob.core.windows.net/ikeykidz/sponsor/dviria-blue-logo-v1-2-diamond.jpg"
                                                                            alt=""
                                                                            width="100%"
                                                                            height="100%"
                                                                        />
                                                                    </article>
                                                                </div>
                                                            </td>
                                                            <td>&nbsp;&nbsp;</td>
                                                            <td className="card-sponsor-mini">
                                                                <div className="card">
                                                                    <article className="card-body">
                                                                        <img
                                                                            src="https://ikeynew.blob.core.windows.net/ikeykidz/sponsor/smile-link-health-care-logo-v1-2-gold.jpg"
                                                                            alt=""
                                                                            width="100%"
                                                                            height="100%"
                                                                        />
                                                                    </article>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table> */}
                                                <br />
                                            </div>
                                            {/* <span>&nbsp;</span> */}


                                            <div style={{
                                                // height: this.state.HideUpcomingEventList ? 0 : 60,
                                                // height: 60,
                                                width: 550,
                                                textAlign: 'center',
                                                verticalAlign: 'bottom',
                                                display: 'table-cell',
                                                paddingBottom: 5,
                                            }}>
                                                <span
                                                    style={{
                                                        color: 'white',
                                                        // backgroundColor: 'black',
                                                        // opacity: 0.2,
                                                        // fontWeight: 'bold',
                                                        textShadow: '1px 1px 1px #000000'
                                                    }}
                                                    onClick={() => {
                                                        // if (this.props.isDevMode)
                                                        this.setState({ ShowLoginAsAdminUi: !this.state.ShowLoginAsAdminUi });
                                                    }}
                                                >{Locale("label-already-have-account-notice", this.props.Locale)}</span>
                                                {
                                                    this.state.ShowLoginAsAdminUi ?
                                                        <div style={{ padding: 5, }}>
                                                            {/* <div className="checkbox">
                                                                <label style={{ fontSize: 12, fontWeight: 'bold', color: 'white', marginBottom: 0 }}>
                                                                    <input type="checkbox"
                                                                        onChange={() => this.props.SetLoginAsAuthor(!this.props.LoginAsAuthor)}
                                                                    // onChange={() => this.props.SetLoginAsAuthor(true)}
                                                                    />&nbsp;Login as Author
                                                                </label>
                                                            </div> */}
                                                            {/* <a href='https://ile-portal.ikeyedutech.com.my/' title='Go to iKEY Learning Evaluation :: Portal'>iKEY Learning Evaluation :: Portal</a> */}
                                                            <button
                                                                className='btn btn-primary'
                                                                onClick={() => {
                                                                    window.location.href = 'https://ile-portal.ikeyedutech.com.my/';
                                                                }}
                                                                title='Go to iKEY Learning Evaluation :: Portal'
                                                            >iKEY Learning Evaluation :: Portal</button>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            {/* <span>&nbsp;</span> */}

                                            <div className="card">
                                                <div style={{ opacity: 0.5, backgroundColor: 'lavender', position: 'absolute', width: '100%', height: '100%' }}
                                                    // hidden={this.props.Events.length > 0}
                                                    hidden={this.state.PageLoadingCompleted}
                                                />
                                                <article className="card-body">
                                                    {/* <a href="/signup" className="float-right btn btn-outline-primary">{Locale("signup-register", this.props.Locale)}</a> */}
                                                    <button
                                                        type="button"
                                                        className="float-right btn btn-outline-primary"
                                                        onClick={this.GotoSignUp}
                                                    >{Locale("signup-register", this.props.Locale)}</button>
                                                    <h4 className="card-title mb-4 mt-1"><b>{Locale("signin-login", this.props.Locale)}</b></h4>
                                                    <form>
                                                        <div className="form-group">
                                                            <label>{Locale("your-email", this.props.Locale)}</label>
                                                            <input name="username"
                                                                className="form-control"
                                                                placeholder={Locale("email-address", this.props.Locale)}
                                                                type="email"
                                                                autoComplete='username'
                                                                onChange={this.handleSetEmail} value={this.state.loginEmail}
                                                                onFocus={() => this.setState({ toggleLoginNotice: true })}
                                                                onBlur={() => this.setState({ toggleLoginNotice: false })}
                                                            />
                                                            {
                                                                // this.state.toggleLoginNotice ?
                                                                //     <span
                                                                //         style={{ padding: 10, color: "gray" }}
                                                                //     >* {Locale("shared-login-notice", this.props.Locale)}</span>
                                                                //     : null
                                                            }
                                                        </div>

                                                        <div className="form-group">
                                                            <a className="float-right" href="/passwordReset">{Locale("forgot-password", this.props.Locale)}</a>
                                                            <label>{Locale("your-password", this.props.Locale)}</label>
                                                            <input className="form-control"
                                                                // placeholder={"******"}
                                                                placeholder={this.state.loginPassword !== null && this.state.loginPassword.length > 0 ? '******' : ''}   //2020.11.28
                                                                type="text"
                                                                autoComplete='current-password'
                                                                onChange={this.handleSetPassword}
                                                            // value={this.state.loginPassword}
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <div className="checkbox">
                                                                        <label> <input type="checkbox"
                                                                            onChange={this.handleSetSavePasword} checked={this.state.saveLoginCredential}
                                                                        />&nbsp;{Locale("keep-email-password", this.props.Locale)}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">

                                                                    {/* <table cellPadding="2" className="float-right">
                                                                        <tr>
                                                                            <td>
                                                                                <button type="button"
                                                                                    className={this.props.Locale === Lang.English ? "btn btn-primary btn-block" : "btn btn-outline-primary"}
                                                                                    onClick={() => this.SetLocaleSetting(Lang.English)}
                                                                                ><span>EN</span></button>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button"
                                                                                    className={this.props.Locale === Lang.Chinese ? "btn btn-primary btn-block" : "btn btn-outline-primary"}
                                                                                    onClick={() => this.SetLocaleSetting(Lang.Chinese)}
                                                                                ><span>CN</span></button>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button"
                                                                                    className={this.props.Locale === Lang.Malay ? "btn btn-primary btn-block" : "btn btn-outline-primary"}
                                                                                    onClick={() => this.SetLocaleSetting(Lang.Malay)}
                                                                                ><span>MA</span></button>
                                                                            </td>
                                                                        </tr>
                                                                    </table> */}

                                                                    <LocalizationSwitcher
                                                                        Locale={this.props.Locale}
                                                                        SetLocaleSetting={this.props.SetLocaleSetting}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-primary btn-block"
                                                                onClick={this.handleLogin}
                                                            >{Locale("signin-login", this.props.Locale)}</button>
                                                        </div>
                                                    </form>

                                                    <center>
                                                        {/* 2025.03.24 */}
                                                        <div className="icon-color pointer" title="Help files"
                                                            onClick={() => {
                                                                if (this.state.MediaFile_IsLoaded && !this.state.MediaFile_IsLoading) {
                                                                    if (
                                                                        Array.isArray(this.props.MediaFiles)
                                                                        && this.props.MediaFiles.length > 0
                                                                    ) {
                                                                        let mediaFileComponents = [];
                                                                        this.props.MediaFiles.forEach((item) => {
                                                                            mediaFileComponents.push(<tr>
                                                                                <td style={{ userSelect: 'none', textAlign: 'left' }}>{CheckObjectStringEmpty(item, 'Name', '-')}</td>
                                                                                <td width={75} align="center"><Button variant='info' onClick={() => {
                                                                                    // this.TogglePreviewMediaFileModal(item);
                                                                                    const url = CheckObjectStringEmpty(item, 'FileLocation');
                                                                                    if (url !== '')
                                                                                        window.open(url, '_new');
                                                                                }}>View</Button></td>
                                                                            </tr>)
                                                                        });
                                                                        this.props.SetAlert('Help files', <table className="table table-hover table-borderless" style={{ marginBottom: 0 }}><tbody>{mediaFileComponents}</tbody></table>);
                                                                    }
                                                                    else {
                                                                        this.props.SetAlert('Help files', 'coming soon...');
                                                                    }
                                                                }
                                                            }}
                                                            disabled={!this.state.MediaFile_IsLoaded && this.state.MediaFile_IsLoading}
                                                            hidden={this.state.MediaFile_IsLoaded ? !(Array.isArray(this.props.MediaFiles) && this.props.MediaFiles?.length > 0) : false}
                                                        ><span className="user-guide-span badge rounded-pill bg-info" style={{ width: 110, justifyContent: 'center' }}>
                                                                {!this.state.MediaFile_IsLoaded && this.state.MediaFile_IsLoading ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 18 }}></i> : <i className="fa fa-info-circle icon"></i>}User Guide</span>
                                                        </div>
                                                    </center>

                                                </article>
                                            </div>
                                        </aside>
                                    </div>

                                    <table style={{ marginTop: 25, color: 'lavender', fontSize: 12, textAlign: 'left' }}>
                                        <tbody>
                                            {
                                                isStaging ?
                                                    <tr>
                                                        <td colSpan={3}><b><i>=== STAGING ===</i></b></td>
                                                    </tr>
                                                    : null
                                            }
                                            <tr>
                                                <td width={150} valign='top'>Latest Build</td>
                                                <td width={10} valign='top'> : </td>
                                                <td valign='top'>{
                                                    this.props.isNewUpdate ?
                                                        <b>{moment(this.props.LastUpdateDate).format('lll')}</b>
                                                        :
                                                        moment(this.props.LastUpdateDate).format('lll')
                                                }</td>
                                            </tr>
                                            {
                                                //PreviousUpdateDate.
                                                CheckObjectNullValue(this.props, 'PreviousUpdateDate') === null ? null :
                                                    <tr>
                                                        <td valign='top'>Previous Build loaded on current device</td>
                                                        <td valign='top'> : </td>
                                                        <td valign='top'>{moment(this.props.PreviousUpdateDate).format('lll')}</td>
                                                    </tr>
                                            }
                                            <tr>
                                                <td valign='top'>Current Browser</td>
                                                <td valign='top'> : </td>
                                                <td valign='top'><b>{browserName}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{ marginTop: 25, color: 'lavender', fontSize: 12, textAlign: 'left' }}>
                                        {/* Latest Update : {
                                            this.props.isNewUpdate ?
                                                <b>{moment(this.props.LastUpdateDate).format('lll')}</b>
                                                :
                                                moment(this.props.LastUpdateDate).format('lll')
                                        }
                                        <br />
                                        {
                                            //PreviousUpdateDate.
                                            CheckObjectNullValue(this.props, 'PreviousUpdateDate') === null ? null :
                                                <>Previous Update : {moment(this.props.PreviousUpdateDate).format('lll')}<br /></>
                                        }
                                        Current Browser : <b>{browserName}</b> */}
                                        {/* <br />
                                        Event Code (1) : <AppCtxConsumer>{() => GetValue('EventCode')}</AppCtxConsumer> */}
                                        {/* <br />
                                        Event Code (2) : {this.GetContextEventCode_Single()} */}
                                        {/* <br />
                                        Event Code (3) : {this.GetContextEventCode_Many()} */}
                                        {/* <br />
                                        Video Guide : <a onClick={this.ShowVideoPopup} href="#" style={{ color: 'yellow' }}>Click here to view video.</a> */}

                                        {/* {
                                            browserName.toLowerCase().includes('chrome') === false
                                                ?
                                                <>
                                                    Current Browser : <b>{browserName}</b> (Not Recommended)
                                            <br />
                                            Recommended Browser : <u><b>Google Chrome</b></u>
                                                </>
                                                :
                                                <>
                                                    Current Browser : <b>{browserName}</b>
                                                </>
                                        } */}
                                    </p>

                                    {/* <p>{moment().get('date')}</p>
                                    <p>{moment('2023-08-15').get('date')}</p> */}

                                    {/* <div className="card">
                                        <article className="card-header">Video Guide</article>
                                        <article className="card-body">
                                            <div dangerouslySetInnerHTML={{ __html: this.ShowVideoPopup() }}></div>
                                        </article>
                                    </div> */}

                                    {/* <button type="button" className="btn btn-primary btn-block"
                                        onClick={() => this.setState({ showModal: true })}
                                    >{Locale("search-info", this.props.Locale)}</button> */}
                                </div>

                                <div style={{ height: '155px', width: '100%' }}>
                                    <span>&nbsp;</span>
                                </div>

                                {/* <p style={{ marginTop: 25, color: 'lavender', fontSize: 12, textAlign: 'center' }}>
                                    Last Update : {this.props.LastUpdateDate}
                                </p>

                                <div style={{ maxWidth: '650px' }}>
                                    <p style={{ marginTop: 25, color: 'lavender', fontSize: 12, textAlign: 'left' }}>
                                        Current Browser : {browserName}
                                        <br />
                                        Recommended Browser : <b>Chrome</b> (Google)
                                    </p>
                                </div> */}

                            </div>


                            {/* <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{Locale("search", this.props.Locale)}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="form-group">
                                        <label>{Locale("search", this.props.Locale)}</label>
                                        <input name="searchName"
                                            className="form-control"
                                            // placeholder={Locale("search-name", this.props.Locale)}
                                            placeholder='search by name'
                                            type="text"
                                            // autoComplete='username'
                                            onChange={this.handleSetSearchText}
                                            value={this.state.hiddenSearchText}
                                        />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" onClick={this.CheckEmailByName}>{Locale("search", this.props.Locale)}</Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={this.state.showResultModal} onHide={this.handleCloseResultModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{Locale("search-result", this.props.Locale)}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body><div dangerouslySetInnerHTML={{ __html: this.state.hiddenResultList }}></div></Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleCloseResultModal}>{Locale("close", this.props.Locale)}</Button>
                                </Modal.Footer>
                            </Modal> */}
                        </>
                    );
                }
            }
        }
    }
}