import React from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row, ProgressBar } from 'react-bootstrap';
// import moment from 'moment';
import '../../Admin/PageStyle.scss';
import '../../Admin/LiveQuizAdminPortal.scss';
// import { Locale, Lang } from '../../Localization/CustomLocalization.js';
// import { GlobalSetting, Toggle } from '../../../components/GlobalSetting';
import { CheckObjectNullValue, CheckObjectStringEmpty, GetPropIds } from '../../../components/GlobalFunctions';
import { CreateOrganizer_ViaAPI, GetAllOrganizers_ViaAPI, UpdateOrganizer_ViaAPI } from '../../../Service/OrganizerService';

//2024.01.08
export default class ManageOrganizer extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        IsLoading: false,
        IsTableListLoaded: false,

        TableList: [],

        EditOrganizerModal_Toggle: false,
        EditOrganizerIndex: -1,
        EditOrganizerObject: null,
        EditOrganizerCache: null,
        EditOrganizerNew: false,
    });

    componentDidMount = async () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        window.scrollTo(0, 0);

        await this.getAllOrganizers();
    }

    //get all organizers.
    getAllOrganizers = async () => {
        this.setState({
            IsLoading: true,
            IsTableListLoaded: false,
            TableList: [],
        });
        const { authorId } = GetPropIds(this.props);
        const { success, errorMessage, data } = await GetAllOrganizers_ViaAPI(authorId);
        if (!success) {
            this.props.SetAlert('', 'Failed to retrieve Organizer list.<br /><br />Error:' + errorMessage);
        }
        else {
            this.setState({
                TableList: data,
                IsTableListLoaded: true,
            });
        }
        this.setState({
            IsLoading: false,
        });
    }

    //get all schools.
    getAllGovernmentSchools_ViaAPI = async () => {

    }

    //get schools by params.
    searchGovernmentSchools_ViaAPI = async () => {

    }

    //create new organizer.
    createOrganizer = async () => {
        this.setState({
            IsLoading: true,
        });
        const { authorId } = GetPropIds(this.props);
        const { success, errorMessage, data } = await CreateOrganizer_ViaAPI(authorId, this.state.EditOrganizerObject);
        if (!success) {
            this.props.SetAlert('', 'Failed to create new Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            this.setState({
                TableList: data,
            });
        }
        this.setState({
            IsLoading: false,
        });
    }

    //update or remove.
    updateOrganizer = async (remove = false) => {
        this.setState({
            IsLoading: true,
        });
        const { authorId } = GetPropIds(this.props);
        const { success, errorMessage, data } = await UpdateOrganizer_ViaAPI(authorId, remove, this.state.EditOrganizerObject);
        if (!success) {
            this.props.SetAlert('', 'Failed to update Organizer.<br /><br />Error:' + errorMessage);
        }
        else {
            this.setState({
                TableList: data,
            });
        }
        this.setState({
            IsLoading: false,
        });
    }

    ToggleEditOrganizerModal = (index = -1, create = false) => {
        this.setState({
            EditOrganizerModal_Toggle: !this.state.EditOrganizerModal_Toggle,
            EditOrganizerIndex: index,
            EditOrganizerNew: create,
        }, () => {
            let obj = null;
            if (create) {
                obj = {
                    Id: 0,
                    Name: '',
                    DisplayName: '',
                    IdentityGuid: '',

                    Active: true,
                    SchoolCode: '',
                    NationalState: '',
                    DistrictArea: '',
                    SchoolName: '',
                    SchoolCompleteName: '',
                };
            }
            else {
                obj = !this.state.EditOrganizerModal_Toggle || index < 0 ? null : this.state.TableList[index];
            }
            this.setState({
                EditOrganizerObject: obj,
                EditOrganizerCache: obj === null ? obj : JSON.parse(JSON.stringify(obj)),
            });
        });
    }

    ResetEditOrganizerObject = () => {
        this.setState({
            EditOrganizerObject: this.state.EditOrganizerCache,
        });
    }

    FormatSchoolCompleteName = (data = null) => {
        if (data === null)
            return '';

        if (CheckObjectNullValue(data, 'SchoolCode') === null)
            return '-';

        const name = CheckObjectStringEmpty(data, 'SchoolCompleteName');
        if (name.includes(' - ')) {
            let array = name.split(' - ');
            if (Array.isArray(array) && array.length > 0) {
                array.shift();
                array[0] = '<span style="font-weight: 400;">' + array[0] + '</span>';
                return array.join('<br />');
            }
        }
        return '-';
    }

    TableListComponent = () => {
        return (<table className='table table-bordered tb-row-hover' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
            <thead>
                <tr>
                    <th style={{ textAlign: 'center', width: 50, }}>#</th>
                    <th style={{ textAlign: 'left', width: '1%', }}>Organizer</th>
                    <th style={{ textAlign: 'left', }}>School Name</th>
                    <th style={{ textAlign: 'left', width: '1%', }}>Other Info</th>
                    <th style={{ textAlign: 'center', width: 105, }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    this.state.TableList.length === 0 ?
                        <tr><td colSpan={4} align='center'>- list is empty -</td></tr>
                        :
                        this.state.TableList.map((data, itemKey) => {
                            return (<tr key={'row_' + itemKey} id={'row_' + itemKey}>
                                <td align='center'>{itemKey + 1}</td>
                                <td>
                                    <b>{CheckObjectStringEmpty(data, 'DisplayName', '-')}</b><br />
                                    <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'Name', '-')})</span><br />
                                    <span style={{ fontSize: 12, color: 'gray' }}>({CheckObjectStringEmpty(data, 'IdentityGuid', '-')})</span>
                                </td>
                                <td>
                                    {/* <div dangerouslySetInnerHTML={{
                                        __html:
                                            CheckObjectStringEmpty(data, 'SchoolName', '-') +
                                            (CheckObjectNullValue(data, 'SchoolCode') === null ? '' :
                                                '<br />School Code: <b>' + CheckObjectStringEmpty(data, 'SchoolCode', '-') + '</b>')
                                    }}></div> */}
                                    {CheckObjectStringEmpty(data, 'SchoolName', '-')}
                                    {CheckObjectNullValue(data, 'SchoolCode') === null ? <></> :
                                        <><br />School Code: <b>{CheckObjectStringEmpty(data, 'SchoolCode', '-')}</b></>}
                                </td>
                                <td>
                                    <div dangerouslySetInnerHTML={{
                                        __html: this.FormatSchoolCompleteName(data)
                                    }}></div>
                                </td>
                                <td align="center" style={{ verticalAlign: 'top', padding: 0 }}>
                                    <div className='row' style={{ padding: 5 }}>
                                        <div className='col'>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => this.ToggleEditOrganizerModal(itemKey)}
                                                style={{ width: '100%' }}
                                                disabled={this.state.IsLoading}
                                            >Edit</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>);
                        })
                }
            </tbody>
        </table>);
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <Row className='rowStyle'>
                        <Col>
                            <span style={{ fontSize: 20, fontWeight: 'bold' }}>Manage Organizer</span>
                        </Col>
                        <Col className='colBtn'>
                            <button type="button" className="btn btn-outline-primary"
                                onClick={this.ToggleCreateOrganizerModel}
                            >New Organizer</button>
                        </Col>
                    </Row>
                    <p />
                    {
                        this.state.IsLoading && !this.state.IsTableListLoaded ?
                            <ProgressBar animated now={100} className='progressbar1' style={{ marginTop: 10, }} />
                            :
                            this.TableListComponent()
                    }


                </>
            );
        }
    }
}