import React from 'react';
import { Redirect } from 'react-router-dom';
import { ProgressBar, Accordion, Card } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-to-excel';
import moment from 'moment';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";
import './LiveQuizAdminPortal.scss';
import { Delay, ScrollToElement } from '../../components/GlobalFunctions';      //2023.09.11

export const TargetList = {
    None: 'none',

    ikeyteam_2021_beta_test_event: 'ikeyteam_2021_beta_test_event', //2021.04.05 - event for testing.

    // spot_2020_1209_1211: 'spot_2020_1209_1211',
    // abacus_challenge_2021_0206: 'abacus_challenge_2021_0206',

    spot_2020_sim_1209_1211: 'spot_2020_sim_1209_1211',
    abacus_challenge_smartnewton_2021_0206: 'abacus_challenge_smartnewton_2021_0206',
    colin_bm_2021_0130: 'colin_bm_2021_0130',

    ptsmc_primary_test_2021_0306_0307: 'ptsmc_primary_test_2021_0306_0307',     //2021.03.01

    spot_2021_sim_0417_1st_sem: 'spot_2021_sim_0417_1st_sem',   //2021.03.17

    //2021.07.15
    // sim_membership_2021_h1_final_rev: 'b7192cbc98da4145a5f1e282e3a65f84',
    mega_2021_july_assessment_rev: '4c3fe3deaf78485a839f6ec350a76a20',

    //2021.08.13
    sjk_spot_2021_2nd_quarter: 'ced0217d39cb49a4bb810e4b1abd04ae',
    yct_hypy_2021_1: '9ca16982f8b845b8bc2831bb05fe275d',
    sk_spot_2021_1: 'e6c4de5a2b27406eb5d7d6e3db36fd74',

    //2021.09.18
    little_oppy_spot_2021_1: '783f207e4ab84f52af5494eac658bc8a',

    //2021.10.20
    sjkc_spot_2021_final: '8a99d6952ca0470481834023672e8d43',
    sim_spot_ai_2021_essay_1: '7565a9729ecb4747b665b38c9652982e',
}

const TargetTable = {
    Joined: 'Joined',
    Other: 'Other',
}

const SortBy = {
    Name: 'Name',
    Grade: 'Grade',
    Group: 'Group',
    Guardian: 'Guardian',
    Email: 'Email',
    State: 'NationalState',
    School: 'School',
    Center: 'IsCenterStudent',
    NewRegistered: 'NewRegistered',
    JoinedDate: 'JoinedDate',
    RegisteredDate: 'RegisteredDate',
}

//migrate & implement in LiveQuizPortal - 2021.12.30
//2021.01.05
export default class LiveQuizEventJoinedListBrowsing extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        SelectedEventCode: TargetList.None,
        selectedEvent: null,
        targetListCollection: '',       //FS repo. LiveQuiz_RegData.
        targetCenter: '',               //display name.

        progressText: '',
        progressCounterMax: 0,
        progressCounterCurrent: 0,

        isTargetListLoading: false,
        isTargetListLoaded: false,

        fetchedRegDataList: [],
        fetchedJoinedList: [],
        tableData_Joined: [],
        tableData_Other: [],
        tableData_Shared: [],

        lastUpdate: '',
        sortSelected: SortBy.Name,
        sortAscDesc: true,      //true = asc, false = desc
        sortTable: TargetTable.Joined,

        Hidden_Event_Joined_List_Table: '',
        // Hidden_Event_Joined_List_Table_Print: '',
        // Hidden_Event_Joined_List_Table_Excel: '',
        ViewInPrint: false,

        //2021.04.15
        refreshStartTime: '-',
        refreshEndTime: '-',
        refreshTimeTaken: '-',

        //2021.09.25
        loading_joined_list: false,
        loading_other_list: false,
        joined_list_loaded: false,
        other_list_loaded: false,

        //2021.12.30
        EventList: [],
        EventList_lastVisible: null,
        IsEventListLoaded: false,
        EventButtonsComponent: [],
        IsButtonEnabled: true,
    });

    componentDidMount = () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        this.LoadEventList();
    }

    // targetListPath() {
    //     return this.props.dbFireStore.ref(this.checkTestPhasePath(_date) + '/pkquiz-ranking-live/' + _roomId);
    // }

    //2021.12.30
    //=== Event List & Buttons === start ===//
    LoadEventList = async () => {
        let eventList_lastVisible = null;
        let eventList = [];
        this.setState({ IsEventListLoaded: false, EventButtonsComponent: [], });
        let docRef = this.props.dbFireStore.collection("LiveQuiz_UpcomingEvents");
        if (this.props.isSuperAdmin || this.props.isDevMode) {
            docRef = docRef.orderBy('DateStart', 'desc');
            // .limit(20);
        }
        else {
            docRef = docRef.where('CenterUserId', '==', Number(this.props.user.CenterUserId))
                .where('AuthorId', '==', Number(this.props.user.AuthorId))
                .orderBy('DateStart', 'desc');
            // .limit(20);
        }
        await docRef.get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        dataArray[dataArray.length - 1].Id = doc.id;    //important
                    });
                    if (dataArray.length > 0) {
                        eventList = dataArray;
                        eventList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(eventList));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('load event list (error) =\n' + error);
            });
        if (eventList.length > 0)
            eventList.sort((x, y) => moment(x.DateStart) - moment(y.DateStart));
        this.setState({
            EventList: eventList,
            EventList_lastVisible: eventList_lastVisible,
            IsEventListLoaded: true,
        }, () => {
            // this.PopulateEventButtons();
        });
    }
    EventButtonUiComponent = () => {
        if (this.state.EventList.length > 0) {
            let _eventButtons = [];
            this.state.EventList.map((data, key) => {
                let organizer = this.FindOrganizer(data);
                let dateRange = String(data.DateStart) === String(data.DateEnd) ? moment(data.DateStart).format('YYYY, MMM DD')
                    : moment(data.DateStart).format('YYYY, MMM DD ~') + moment(data.DateEnd).format('MMM DD');
                let buttonText = (organizer !== null ? String(organizer.DisplayName) + '<br />' : '')
                    + '<b>' + String(data.EventName) + '</b>' + (dateRange !== '' ? '<br />(' + dateRange + ')' : '');
                return _eventButtons.push(<div className='col' key={'evt_btn_' + key}>
                    <button
                        className={"btn btn-" + (this.state.SelectedEventCode === String(data.EventCode) ? "danger" : "primary")}
                        disabled={this.state.isTargetListLoading}
                        onClick={() => this.setState({
                            SelectedEventCode: String(data.EventCode)
                        }, () => {
                            // this.SetTargetData();
                            this.SetTargetEvent();
                        })}
                        style={{ borderColor: "white", width: 215, minHeight: 115, }}
                    ><div dangerouslySetInnerHTML={{ __html: buttonText }}></div></button >
                </div >);
            });
            let _extra = this.state.EventList.length % 5;
            // console.log('\nextra = ' + _extra);
            if (_extra > 0) {
                for (let i = 0; i < 5 - _extra; i++) {
                    _eventButtons.push(<div className='col'>&nbsp;</div>);
                }
            }
            return _eventButtons;
        }
        return null;
    }
    //=== Event List & Buttons === end ===//

    SetTargetData = () => {

        //2021.09.10
        let _selectedEvent = null;
        if (this.props.EventList !== undefined && this.props.EventList !== null) {
            this.props.EventList.map((data, key) => {
                if (String(data.EventCode) === this.state.SelectedEventCode) {
                    _selectedEvent = data;
                }
                return null;
            });
        }

        let _targetListCollection = '';
        let _targetCenter = '';

        //2022.01.26
        if (this.props.Organizers !== undefined && this.props.Organizers !== null) {
            if (this.props.Organizers.length > 0) {
                if (_selectedEvent !== null) {
                    let _OrganizerId = String(_selectedEvent['OrganizerId']);
                    let _OrganizerIdentity = String(_selectedEvent['OrganizerIdentity']);
                    let _Organizer = null;
                    this.props.Organizers.map((data, key) => {
                        if (Number(data['Id']) === Number(_OrganizerId) && String(data['Identity']) === _OrganizerIdentity)
                            _Organizer = data;
                        return null;
                    });
                    if (_Organizer !== null) {
                        _targetListCollection = String(_Organizer['Name']);
                        _targetCenter = String(_Organizer['DisplayName']);
                    }
                }
            }
        }
        //#region  //old codes.
        if (_targetListCollection === '' && _targetCenter === '') {

            if (this.state.SelectedEventCode === TargetList.spot_2020_sim_1209_1211) {
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "SIM";
                _targetCenter = "SIM";
            }
            else if (this.state.SelectedEventCode === TargetList.abacus_challenge_smartnewton_2021_0206) {
                // _targetListCollection = "SmartNewton_RegistrationData";
                _targetListCollection = "SMARTNEWTON";
                _targetCenter = "Smart Newton";
            }
            else if (this.state.SelectedEventCode === TargetList.colin_bm_2021_0130) {
                // _targetListCollection = "SmartNewton_RegistrationData";
                _targetListCollection = "COLIN";
                _targetCenter = "The Colin's Learning Centre";
            }
            else if (this.state.SelectedEventCode === TargetList.ptsmc_primary_test_2021_0306_0307) {     //2021.03.01
                // _targetListCollection = "SmartNewton_RegistrationData";
                _targetListCollection = "PTSMC";
                _targetCenter = "佳学教育 JiaXue Tuition Center";
            }
            if (this.state.SelectedEventCode === TargetList.spot_2021_sim_0417_1st_sem) {
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "SIM";
                _targetCenter = "SIM";
            }
            if (this.state.SelectedEventCode === TargetList.ikeyteam_2021_beta_test_event) {
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "iKEYTEAM";
                _targetCenter = "iKEY EDUTECH";
            }
            // if (this.state.SelectedEventCode === TargetList.sim_membership_2021_h1_final_rev) {
            //     // _targetListCollection = "RegistrationData";
            //     _targetListCollection = "SIM";
            //     _targetCenter = "SIM EDUCATION GROUP";
            // }
            if (this.state.SelectedEventCode === TargetList.mega_2021_july_assessment_rev) {
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "MEGA";
                _targetCenter = "MEGA EDUCATION CENTER";
            }
            if (this.state.SelectedEventCode === TargetList.sjk_spot_2021_2nd_quarter) {
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "SIM";
                _targetCenter = "SIM";
            }
            if (this.state.SelectedEventCode === TargetList.yct_hypy_2021_1) {
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "YCT";
                _targetCenter = "YCT";
            }
            if (this.state.SelectedEventCode === TargetList.sk_spot_2021_1) {
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "SIM";
                _targetCenter = "SIM";
            }
            if (this.state.SelectedEventCode === TargetList.little_oppy_spot_2021_1) {     //2021.09.18
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "LITTLEOPPY";
                _targetCenter = "LITTLEOPPY";
            }
            if (this.state.SelectedEventCode === TargetList.sjkc_spot_2021_final) {    //2021.10.20
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "SIM";
                _targetCenter = "SIM";
            }
            if (this.state.SelectedEventCode === TargetList.sim_spot_ai_2021_essay_1) {    //2021.10.21
                // _targetListCollection = "RegistrationData";
                _targetListCollection = "SIM";
                _targetCenter = "SIM";
            }

        }
        //#endregion

        this.setState({
            targetListCollection: _targetListCollection,    //FS repo. LiveQuiz_RegData.
            targetCenter: _targetCenter,                    //display name.

            //2021.04.15
            refreshStartTime: '-',
            refreshEndTime: '-',
            refreshTimeTaken: '-',

            //2021.09.10
            selectedEvent: _selectedEvent,

            //2021.09.25
            loading_joined_list: false,
            loading_other_list: false,
            joined_list_loaded: false,
            other_list_loaded: false,
        }, () => {
            this.fetchLegacyTargetListData();
        });
    }

    //2021.12.30
    SetTargetEvent = () => {
        if (Array(this.props.Organizers).length > 0) {
            if (this.state.EventList.length > 0) {
                let eventIndex = this.state.EventList.findIndex(x => String(x.EventCode) === this.state.SelectedEventCode);
                if (eventIndex > -1) {
                    let _selectedEvent = this.state.EventList[eventIndex];
                    if (_selectedEvent.hasOwnProperty('OrganizerIdentity')) {
                        let organizer = this.FindOrganizer(_selectedEvent);
                        if (organizer !== null) {
                            let _targetListCollection = String(organizer.Name);
                            let _targetCenter = String(organizer.DisplayName);
                            if (this.props.isDevMode)
                                console.log('\nCollection = ' + _targetListCollection + '\nTarget Center = ' + _targetCenter + '\nEventCode = ' + this.state.SelectedEventCode);

                            this.setState({
                                targetListCollection: _targetListCollection,    //FS repo. LiveQuiz_RegData.
                                targetCenter: _targetCenter,                    //display name.
                                selectedEvent: _selectedEvent,

                                refreshStartTime: '-',
                                refreshEndTime: '-',
                                refreshTimeTaken: '-',

                                loading_joined_list: false,
                                loading_other_list: false,
                                joined_list_loaded: false,
                                other_list_loaded: false,
                            }, () => {
                                this.fetchLegacyTargetListData();
                            });
                        }
                    }
                }
            }
        }
    }

    //2021.12.30
    FindOrganizer = (event) => {
        if (this.props.Organizers.length > 0) {
            let findIndex = this.props.Organizers.findIndex(x => String(x.Identity) === String(event.OrganizerIdentity));
            if (findIndex > -1) {
                return this.props.Organizers[findIndex];
            }
        }
        return null;
    }

    fetchLegacyTargetListData = () => {
        this.setState({
            isTargetListLoading: true,
            isTargetListLoaded: false,
            fetchedRegDataList: [],
            fetchedJoinedList: [],
            tableData_Joined: [],
            tableData_Other: [],
            tableData_Shared: [],
            lastUpdate: '',
            progressText: 'fetching data...',
            progressCounterMax: 0,
            progressCounterCurrent: 0,
        }, async () => {
            // alert(this.state.SelectedEventCode);

            let _tableData_Temp = [];

            await this.props.dbFireStore
                .collection("_Counters")
                .where("Counter", "==", "EventJoinList_" + this.state.SelectedEventCode)
                .where("EventCode", "==", this.state.SelectedEventCode)
                .get()
                .then((querySnapshot) => {
                    let data = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                    }
                    if (data.length > 0) {
                        _tableData_Temp = data[0];
                    }
                })
                .catch((error) => {
                    if (this.props.isDevMode)
                        console.log("Error\n\n" + error.code + "\n\n" + error.message);
                });

            this.setState({
                progressText: 'processing data...',
            });
            Delay(1000);

            // alert(JSON.stringify(_tableData_Temp) + "\n\n" + String(_tableData_Temp.length > 0));
            if (_tableData_Temp.length <= 0) {
                // alert("Empty Legacy Data.");
                this.setState({
                    lastUpdate: '',
                    tableData_Joined: [],
                    tableData_Other: [],
                    tableData_Shared: [],
                    isTargetListLoaded: false,
                    isTargetListLoading: false,
                });
            }
            else {
                // let _tableData_Joined = _tableData_Temp["Joined"];
                // let _tableData_Other = _tableData_Temp["Other"];
                let _tableData_Shared = _tableData_Temp["Shared"];
                let _lastUpdate = _tableData_Temp["LastUpdate"];

                //2021.09.10 - get list from all collection's divisions (Joined, Other).
                if (_tableData_Shared.TotalParticipants > 2000) {

                    //2021.09.25 === not loaded at first. only load list if trigger by user.
                    if (_tableData_Shared.TotalJoined > 2000)
                        _tableData_Temp["Joined"] = [];
                    if (_tableData_Shared.TotalRegisteredButNotJoined > 2000)
                        _tableData_Temp["Other"] = [];

                    // await this.props.dbFireStore
                    //     .collection("_Counters")
                    //     .doc("EventJoinList_" + this.state.SelectedEventCode)
                    //     .collection('Joined')
                    //     .get()
                    //     .then((querySnapshot) => {
                    //         let divisions = [];
                    //         if (querySnapshot !== null) {
                    //             querySnapshot.forEach((doc) => {
                    //                 divisions.push(doc.data());
                    //             });
                    //         }
                    //         let allData = [];
                    //         // console.log(JSON.stringify(divisions));
                    //         if (divisions.length > 0) {
                    //             divisions.forEach((div) => {
                    //                 div.data.forEach((data) => {
                    //                     allData.push(data);
                    //                 });
                    //             });
                    //             allData = allData.filter((el) => { return (el !== null); });
                    //             allData.sort(function (a, b) {
                    //                 if (a.Name < b.Name) { return -1; }
                    //                 if (a.Name > b.Name) { return 1; }
                    //                 return 0;
                    //             });
                    //             console.log(allData.length);
                    //             _tableData_Temp["Joined"] = allData;
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         console.log("Error(Div)(Joined)\n\n" + error.code + "\n\n" + error.message);
                    //     });

                    // await this.props.dbFireStore
                    //     .collection("_Counters")
                    //     .doc("EventJoinList_" + this.state.SelectedEventCode)
                    //     .collection('Other')
                    //     .get()
                    //     .then((querySnapshot) => {
                    //         let divisions = [];
                    //         if (querySnapshot !== null) {
                    //             querySnapshot.forEach((doc) => {
                    //                 divisions.push(doc.data());
                    //             });
                    //         }
                    //         let allData = [];
                    //         if (divisions.length > 0) {
                    //             divisions.forEach((div) => {
                    //                 allData.push(div.data);
                    //             });
                    //             allData = allData.filter((el) => { return (el !== null); });
                    //             allData.sort(function (a, b) {
                    //                 if (a.Name < b.Name) { return -1; }
                    //                 if (a.Name > b.Name) { return 1; }
                    //                 return 0;
                    //             });
                    //             _tableData_Temp["Other"] = allData;
                    //         }
                    //     })
                    //     .catch((error) => {
                    //         console.log("Error(Div)(Other)\n\n" + error.code + "\n\n" + error.message);
                    //     });
                }

                let _tableData_Joined = _tableData_Temp["Joined"];
                let _tableData_Other = _tableData_Temp["Other"];

                if (_tableData_Shared.hasOwnProperty('GroupCounters'))
                    _tableData_Shared.GroupCounters = this.sortTable_ByName_ByNullAtLast(_tableData_Temp["Shared"].GroupCounters, 'asc', SortBy.Name);
                if (_tableData_Shared.hasOwnProperty('GradeCounters'))
                    _tableData_Shared.GradeCounters = this.sortTable_ByName_ByNullAtLast(_tableData_Temp["Shared"].GradeCounters, 'asc', SortBy.Name);
                if (_tableData_Shared.hasOwnProperty('StateCounters'))
                    _tableData_Shared.StateCounters = this.sortTable_ByName_ByNullAtLast(_tableData_Temp["Shared"].StateCounters, 'asc', SortBy.Name);

                //2022.12.13
                let updated_tableData_Shared = this.mergeTablesAndCalculateCounters(_tableData_Shared, _tableData_Joined, _tableData_Other);
                if (JSON.stringify(_tableData_Shared) !== JSON.stringify(updated_tableData_Shared)) {

                    _tableData_Shared = updated_tableData_Shared;
                    console.log("\nNew Shared =\n" + JSON.stringify(updated_tableData_Shared));

                    // //update to fs.
                    // await this.props.dbFireStore.collection("_Counters")
                    //     .doc("EventJoinList_" + this.state.SelectedEventCode)
                    //     .update({
                    //         Shared: _tableData_Shared
                    //     })
                    //     .catch((error) => {
                    //         if (this.props.isDevMode)
                    //             console.log("Error\n\n" + error.code + "\n\n" + error.message);
                    //     });
                }
                else {
                    console.log("\nNew Shared = not require.");
                }

                this.setState({
                    lastUpdate: _lastUpdate,
                    tableData_Joined: _tableData_Joined,
                    tableData_Other: _tableData_Other,
                    tableData_Shared: _tableData_Shared,
                    isTargetListLoaded: true,
                    isTargetListLoading: false,

                    //2022.06.10
                    refreshStartTime: _tableData_Temp.hasOwnProperty('RefreshStartTime') ? String(_tableData_Temp.RefreshStartTime) : '-',
                    refreshEndTime: _tableData_Temp.hasOwnProperty('RefreshEndTime') ? String(_tableData_Temp.RefreshEndTime) : '-',
                    refreshTimeTaken: _tableData_Temp.hasOwnProperty('RefreshTimeTaken') ? String(_tableData_Temp.RefreshTimeTaken) : '-',

                }, () => {
                    ScrollToElement('OrganizerTitle');
                    this.populating_Hidden_EventJoinedListTable();
                });
            }
        });
    }

    //2021.12.31 === for cleaning possible duplcated entries.
    CheckTo_RemoveDuplicatedEnteries_And_RepostEntries = async () => {
        let _updateIsRequired = false;
        let _sharedData = this.state.tableData_Shared;

        //Check on Joined Students.
        let _joinedData = this.state.tableData_Joined;
        let _joinedData_Checked = [];
        if (_joinedData.length !== Number(_sharedData.TotalJoined)) {
            if (_joinedData.length > 0) {
                for (let p = 0; p < _joinedData.length; p++) {
                    if (_joinedData[p].Name !== '' && _joinedData[p].Name !== undefined) {
                        let findIndex = _joinedData_Checked.findIndex(x => x.Name === _joinedData[p].Name);
                        if (findIndex < 0)
                            _joinedData_Checked.push(_joinedData[p]);
                    }
                }
                _joinedData_Checked.sort((x, y) => x.Name - y.Name);
            }
            if (_updateIsRequired === false)
                _updateIsRequired = _joinedData_Checked.length > 0;
        }

        //Check on Other Students.
        let _otherData = this.state.tableData_Other;
        let _otherData_Checked = [];
        if (_otherData.length !== Number(_sharedData.TotalRegisteredButNotJoined)) {
            if (_otherData.length > 0) {
                for (let p = 0; p < _otherData.length; p++) {
                    if (_otherData[p].Name !== '' && _otherData[p].Name !== undefined) {
                        let findIndex = _otherData_Checked.findIndex(x => x.Name === _otherData[p].Name);
                        if (findIndex < 0)
                            _otherData_Checked.push(_otherData[p]);
                    }
                }
                _otherData_Checked.sort((x, y) => x.Name - y.Name);
            }
            if (_updateIsRequired === false)
                _updateIsRequired = _otherData_Checked.length > 0;
        }

        if (_updateIsRequired) {
            //save state.
            this.setState({
                tableData_Joined: _joinedData_Checked,
                tableData_Other: _otherData_Checked,
            });

            //Update - Remove Old & Repost Entries.
            await this.updateToFireStore_TableData(this.state.tableData_Shared, _joinedData_Checked, _otherData_Checked);
        }
    }

    //2021.09.25 === for joined TotalParticipants > 2000
    fetchParticipants_Joined = async () => {
        this.setState({ loading_joined_list: false, joined_list_loaded: false, IsButtonEnabled: false, });
        let _tableData_list = [];
        await this.props.dbFireStore
            .collection("_Counters")
            .doc("EventJoinList_" + this.state.SelectedEventCode)
            .collection('Joined')
            .get()
            .then((querySnapshot) => {
                let divisions = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        divisions.push(doc.data());
                    });
                }
                let allData = [];
                // console.log(JSON.stringify(divisions));
                if (divisions.length > 0) {
                    divisions.forEach((div) => {
                        div.data.forEach((data) => {
                            allData.push(data);
                        });
                    });
                    allData = allData.filter((el) => { return (el !== null); });
                    allData.sort(function (a, b) {
                        if (a.Name < b.Name) { return -1; }
                        if (a.Name > b.Name) { return 1; }
                        return 0;
                    });
                    if (this.props.isDevMode)
                        console.log(allData.length);
                    _tableData_list = allData;
                }
            })
            .catch((error) => {
                if (this.props.isDevMode)
                    console.log("Error(Div)(Joined)\n\n" + error.code + "\n\n" + error.message);
            });

        //2022.12.13
        //filter duplicated data.
        let _temp_List = [];
        _tableData_list.map((data, key) => {
            if (data.Email !== undefined) {
                let idx = _temp_List.findIndex(x => String(x.Email) === String(data.Email));
                if (idx < 0) {
                    _temp_List.push(data);
                }
            }
            return null;
        });
        _tableData_list = _temp_List;
        // let updated_tableData_Shared = this.mergeTablesAndCalculateCounters(this.state.tableData_Shared, _tableData_list, this.state.tableData_Other);
        //2022.12.13
        let updated_tableData_Shared = this.mergeTablesAndCalculateCounters({ ...this.state.tableData_Shared }, _tableData_list, this.state.tableData_Other);
        if (JSON.stringify(this.state.tableData_Shared) !== JSON.stringify(updated_tableData_Shared)) {

            console.log("\nNew Shared (load) =\n" + JSON.stringify(updated_tableData_Shared));

            // //update to fs.
            // await this.props.dbFireStore.collection("_Counters")
            //     .doc("EventJoinList_" + this.state.SelectedEventCode)
            //     .update({
            //         Shared: updated_tableData_Shared
            //     })
            //     .catch((error) => {
            //         if (this.props.isDevMode)
            //             console.log("Error\n\n" + error.code + "\n\n" + error.message);
            //     });
        }
        else {
            updated_tableData_Shared = this.state.tableData_Shared;
        }

        //2022.05.31
        //Sort List according to Name in ASC
        _tableData_list.sort((a, b) => {
            // if (String(a.Grade) === String(b.Grade)) {
            //     return a.Grade - b.Grade;
            // }
            // return a.Name > b.Name ? 1 : -1;
            return String(a.Grade).localeCompare(String(b.Grade)) || a.Name - b.Name;
            // return a.Grade - b.Grade;
        });

        //2022.05.31
        //Sort List according to Name in ASC
        _tableData_list.sort(function (a, b) {
            if (a.Grade < b.Grade) { return -1; }
            if (a.Grade > b.Grade) { return 1; }
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
        });

        this.setState({
            tableData_Joined: _tableData_list,
            tableData_Shared: updated_tableData_Shared,     //2022.12.13
            loading_joined_list: false,
            joined_list_loaded: true,
            IsButtonEnabled: true,
        }, () => {
            this.populating_Hidden_EventJoinedListTable();
        });
    }

    //2021.09.25 === for joined TotalParticipants > 2000
    fetchParticipants_Other = async () => {
        this.setState({ loading_other_list: false, other_list_loaded: false, });
        let _tableData_list = [];
        await this.props.dbFireStore
            .collection("_Counters")
            .doc("EventJoinList_" + this.state.SelectedEventCode)
            .collection('Other')
            .get()
            .then((querySnapshot) => {
                let divisions = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        divisions.push(doc.data());
                    });
                }
                let allData = [];
                if (divisions.length > 0) {
                    divisions.forEach((div) => {
                        allData.push(div.data);
                    });
                    allData = allData.filter((el) => { return (el !== null); });
                    allData.sort(function (a, b) {
                        if (a.Name < b.Name) { return -1; }
                        if (a.Name > b.Name) { return 1; }
                        return 0;
                    });
                    _tableData_list = allData;
                }
            })
            .catch((error) => {
                if (this.props.isDevMode)
                    console.log("Error(Div)(Other)\n\n" + error.code + "\n\n" + error.message);
            });

        //2022.05.31
        //Sort List according to Name in ASC
        _tableData_list.sort(function (a, b) {
            if (a.Grade < b.Grade) { return -1; }
            if (a.Grade > b.Grade) { return 1; }
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
        });

        this.setState({
            tableData_Other: _tableData_list,
            loading_other_list: false,
            other_list_loaded: true,
        }, () => {
            this.populating_Hidden_EventJoinedListTable();
        });
    }

    fetchNewTargetListData = () => {
        this.setState({
            isTargetListLoading: true,
            isTargetListLoaded: false,
            fetchedRegDataList: [],
            fetchedJoinedList: [],
            tableData_Joined: [],
            tableData_Other: [],
            tableData_Shared: [],
            lastUpdate: '',
            progressText: 'refreshing data...',
            progressCounterMax: 0,
            progressCounterCurrent: 0,
        }, async () => {
            // alert(this.state.SelectedEventCode);

            await this.fetchRegistrationData();
            await this.fetchJoinedListData();
            await this.PopulatingTableData();
        });
    }

    //Fetch the whole Registration list (massive data, will consumes alot quota in each trigger).
    fetchRegistrationData = async () => {
        // let fetchSuccess = false;
        // await this.joinedPlayersPathV2(this.state.liveQuizDate, _roomId).once('value', async (snapshot) => {
        //     await this.processJoinedPlayersData(snapshot.val());
        // });
        // alert(this.state.targetListCollection);
        let _regData = [];
        await this.props.dbFireStore
            // .collection(this.state.targetListCollection)
            .collection("LiveQuiz_RegData")
            .doc(this.state.targetListCollection)
            .collection("List")
            .where("EventCode", "==", this.state.SelectedEventCode)
            // .orderBy("Name")
            .get()
            .then((querySnapshot) => {
                _regData = querySnapshot.docs.map(doc => doc.data());

                // if (_regData.length > 0) {
                //     //Sort List according to Name in ASC
                //     _regData.sort(function (a, b) {
                //         if (a.Name < b.Name) { return -1; }
                //         if (a.Name > b.Name) { return 1; }
                //         return 0;
                //     });
                // }

                //Populating Registration Data.
                this.setState({
                    fetchedRegDataList: _regData,
                }, async () => {
                    // await this.PopulatingRegistrationData();
                });
            })
            .catch(async (error) => {
                if (this.props.isDevMode)
                    console.log("Reg Data : Fetch Failed...\n\n" + error.message);
                this.setState({
                    // isTargetListLoaded: false,
                    isTargetListLoading: false,
                });
            });

        // //Populating Registration Data.
        // this.setState({
        //     fetchedRegDataList: _regData.length > 0 ? _regData : [],
        // }, async () => {
        //     // await this.PopulatingRegistrationData();
        // });
    }

    // PopulatingRegistrationData = async () => {
    //     alert(this.state.fetchedRegDataList.length);
    // }

    fetchJoinedListData = async () => {
        let _regData = [];
        await this.props.dbFireStore
            .collection("LiveQuizEventJoinedList")
            .doc(this.state.SelectedEventCode)
            .collection("List")
            .orderBy("Date")
            .get()
            .then((querySnapshot) => {
                _regData = querySnapshot.docs.map(doc => doc.data());

                // if (_regData.length > 0) {
                //     //Sort List according to Name in ASC
                //     _regData.sort(function (a, b) {
                //         if (a.Name < b.Name) { return -1; }
                //         if (a.Name > b.Name) { return 1; }
                //         return 0;
                //     });
                // }

                //Populating Data.
                this.setState({
                    fetchedJoinedList: _regData,
                }, async () => {
                    // await this.PopulatingJoinedListData();
                });
            })
            .catch(async (error) => {
                if (this.props.isDevMode)
                    console.log("Joined List : Fetch Failed...\n\n" + error.message);
                this.setState({
                    // isTargetListLoaded: false,
                    isTargetListLoading: false,
                });
            });

        // //Populating Data.
        // this.setState({
        //     fetchedJoinedList: _regData.length > 0 ? _regData : [],
        // }, async () => {
        //     // await this.PopulatingJoinedListData();
        // });
    }

    // PopulatingJoinedListData = async () => {
    //     alert(this.state.fetchedJoinedList.length);
    //     // alert(JSON.stringify(this.state.fetchedJoinedList));
    // }

    FormatString = (text) => {
        return (String(text).length > 0 && text !== undefined ? text : "-");
    }

    PopulatingTableData = async () => {
        this.setState({
            progressText: 'fetching data...',
            progressCounterCurrent: 0,
            // progressCounterMax: this.state.fetchedJoinedList.length + this.state.fetchedRegDataList.length,
            progressCounterMax: this.state.fetchedJoinedList.length,
            refreshStartTime: moment().format(),
        });
        Delay(500);

        let _tableData_Joined = [];
        let _tableData_Other = [];
        let _tableData_Shared = [];
        let _tableData_Temp = [];

        //2021.04.15 - change map-loop to for-loop.
        // //Matched students put into Joined list.
        // this.state.fetchedJoinedList.map((data1, key1) => {
        //     this.state.fetchedRegDataList.map((data, key2) => {
        //         if (data1.Uid === data.Uid) {
        //             _tableData_Joined.push({
        //                 Uid: data.Uid,
        //                 Name: this.FormatString(data.Name),
        //                 Grade: this.FormatString(data.Grade),
        //                 Group: this.FormatString(data.Group),
        //                 Guardian: this.FormatString(data.Guardian),
        //                 Email: this.FormatString(data.Email),
        //                 NationalState: this.FormatString(data.NationalState),
        //                 School: this.FormatString(data.School),   //data.Uid,  //this.FormatString(data.School),
        //                 IsCenterStudent: this.FormatString(data.IsCenterStudent),
        //                 // IsCenterStudent: String(data.IsCenterStudent).toLowerCase() === 'yes' ? true : false,
        //                 // IsEventRegistration: data.IsEventRegistration,
        //                 NewRegistered: true,
        //                 Contact: this.FormatString(data.Contact), //2021.01.25

        //                 //2021.03.02
        //                 JoinedDate: moment(data1.Date).format('D/M/YYYY'),
        //                 RegisteredDate: moment(data.CreatedDate).format('D/M/YYYY'),
        //             });
        //             this.setState({
        //                 progressCounterCurrent: this.state.progressCounterCurrent + 1,
        //                 // progressText: 'processing data...(' + Math.round((((this.state.progressCounterCurrent + 1) / this.state.progressCounterMax) * 100)) + "%)",
        //             });
        //         }
        //         return null;
        //     });
        //     return null;
        // });
        // // Delay(1000);

        //2021.04.15 - revamped - change map-loop to for-loop.
        //Matched students put into Joined list.
        for (let key1 = 0; key1 < this.state.fetchedJoinedList.length; key1++) {
            let data1 = this.state.fetchedJoinedList[key1];

            for (let key2 = 0; key2 < this.state.fetchedRegDataList.length; key2++) {
                let data = this.state.fetchedRegDataList[key2];

                if (data1.Uid === data.Uid) {

                    let isCenterStudent = data1.hasOwnProperty('IsCenterStudent') ? data1.IsCenterStudent : data.IsCenterStudent;
                    // console.log(isCenterStudent);                    

                    _tableData_Joined.push({
                        Uid: data.Uid,
                        Name: this.FormatString(data.Name),
                        Grade: this.FormatString(data.Grade),
                        Group: this.FormatString(data.Group),
                        Guardian: this.FormatString(data.Guardian),
                        Email: this.FormatString(data.Email),
                        NationalState: this.FormatString(data.NationalState),
                        School: this.FormatString(data.School),   //data.Uid,  //this.FormatString(data.School),
                        // IsCenterStudent: this.FormatString(data.IsCenterStudent),
                        IsCenterStudent: this.FormatString(isCenterStudent),
                        NewRegistered: true,
                        Contact: this.FormatString(data.Contact),
                        JoinedDate: moment(data1.Date).format('D/M/YYYY'),
                        RegisteredDate: moment(data.CreatedDate).format('D/M/YYYY'),
                    });
                    this.setState({
                        progressCounterCurrent: this.state.progressCounterCurrent + 1,
                        progressText: 'fetching user data...(' + Math.round((((this.state.progressCounterCurrent + 1) / this.state.progressCounterMax) * 100)) + "%)",
                    });
                }
            }
        }

        //2021.04.15 - revamped - change map-loop to for-loop.
        // //Students that not join put into Other list.
        // this.state.fetchedRegDataList.map((data, key) => {
        //     let isExist = _tableData_Joined.findIndex(x => x.Uid === data.Uid);
        //     if (isExist < 0) {
        //         _tableData_Other.push({
        //             Uid: data.Uid,
        //             Name: this.FormatString(data.Name),
        //             Grade: this.FormatString(data.Grade),
        //             Group: this.FormatString(data.Group),
        //             Guardian: this.FormatString(data.Guardian),
        //             Email: this.FormatString(data.Email),
        //             NationalState: this.FormatString(data.NationalState),
        //             School: this.FormatString(data.School),   //data.Uid,  //this.FormatString(data.School),
        //             IsCenterStudent: this.FormatString(data.IsCenterStudent),
        //             NewRegistered: true,
        //             Contact: this.FormatString(data.Contact), //2021.01.25

        //             //2021.03.02
        //             JoinedDate: '-',
        //             RegisteredDate: moment(data.CreatedDate).format('D/M/YYYY'),
        //         });
        //         this.setState({
        //             progressCounterCurrent: this.state.progressCounterCurrent + 1,
        //             // progressText: 'processing data...(' + Math.round((((this.state.progressCounterCurrent + 1) / this.state.progressCounterMax) * 100)) + "%)",
        //         });
        //     }
        //     return null;
        // });
        // // Delay(1000);

        //2021.07.06
        this.setState({
            progressCounterCurrent: 0,
            progressCounterMax: this.state.fetchedRegDataList.length,
        });
        Delay(500);


        //2021.04.15 - revamped - change map-loop to for-loop.
        //Students that not join put into Other list.
        for (let k = 0; k < this.state.fetchedRegDataList.length; k++) {
            let data = this.state.fetchedRegDataList[k];
            let isExist = _tableData_Joined.findIndex(x => x.Uid === data.Uid);
            if (isExist < 0) {

                let isCenterStudent = data.IsCenterStudent;
                let joinedData = await this.fetchJoinedDataInFireStore(data.Uid);
                if (joinedData !== null)
                    if (joinedData.hasOwnProperty('IsCenterStudent'))
                        isCenterStudent = joinedData.IsCenterStudent;

                _tableData_Other.push({
                    Uid: data.Uid,
                    Name: this.FormatString(data.Name),
                    Grade: this.FormatString(data.Grade),
                    Group: this.FormatString(data.Group),
                    Guardian: this.FormatString(data.Guardian),
                    Email: this.FormatString(data.Email),
                    NationalState: this.FormatString(data.NationalState),
                    School: this.FormatString(data.School),   //data.Uid,  //this.FormatString(data.School),
                    // IsCenterStudent: this.FormatString(data.IsCenterStudent),
                    IsCenterStudent: this.FormatString(isCenterStudent),
                    NewRegistered: true,
                    Contact: this.FormatString(data.Contact),
                    JoinedDate: '-',
                    RegisteredDate: moment(data.CreatedDate).format('D/M/YYYY'),
                });
                this.setState({
                    progressCounterCurrent: this.state.progressCounterCurrent + 1,
                    progressText: 'fetching reg joined data...(' + Math.round((((this.state.progressCounterCurrent + 1) / this.state.progressCounterMax) * 100)) + "%) (" + (this.state.progressCounterCurrent + 1) + "/" + this.state.progressCounterMax + ")",
                });
            }
        }

        //Students not in Other list & Joined list, means is old Students who RegData is kept in profile only.
        this.state.fetchedJoinedList.map(async (data, key) => {
            let isExist = _tableData_Joined.findIndex(x => x.Uid === data.Uid);
            if (isExist < 0) {
                _tableData_Temp.push({ Uid: data.Uid, JoinedDate: data.Date });
            }
            return null;
        });
        this.setState({
            progressCounterCurrent: this.state.progressCounterCurrent + 1,
            progressCounterMax: this.state.fetchedJoinedList.length + _tableData_Other.length,
        });
        for (var i = 0; i < _tableData_Temp.length; i++) {
            let profile = await this.fetchProfileDataInFireStore(_tableData_Temp[i].Uid);
            let _joinedDate = moment(_tableData_Temp[i].JoinedDate);

            let isCenterStudent = '-';
            let joinedData = await this.fetchJoinedDataInFireStore(_tableData_Temp[i].Uid);
            if (joinedData !== null)
                if (joinedData.hasOwnProperty('IsCenterStudent'))
                    isCenterStudent = joinedData.IsCenterStudent;
            // if (isCenterStudent === '-')
            //     if (profile.hasOwnProperty('IsCenterStudent'))
            //         isCenterStudent = profile.IsCenterStudent;

            if (profile !== null) {
                if (isCenterStudent === '-')
                    if (profile.hasOwnProperty('IsCenterStudent'))
                        isCenterStudent = profile.IsCenterStudent;

                let _modal = {
                    Uid: _tableData_Temp[i],
                    Name: this.FormatString(profile.Name),
                    Grade: this.FormatString(profile.Grade),
                    Group: this.FormatString(profile.Group),
                    Guardian: this.FormatString(profile.Guardian),
                    Email: this.FormatString(profile.Email),
                    NationalState: this.FormatString(profile.NationalState),
                    School: this.FormatString(profile.School),   //_tableData_Temp[i],  //this.FormatString(profile.School),
                    // IsCenterStudent: this.FormatString(profile.IsCenterStudent),
                    IsCenterStudent: this.FormatString(isCenterStudent),
                    NewRegistered: false,
                    Contact: this.FormatString(profile.Contact), //2021.01.25

                    //2021.03.02
                    JoinedDate: String(_joinedDate).toLowerCase() !== 'invalid date' ? _joinedDate.format('D/M/YYYY') : '-',
                    RegisteredDate: moment(profile.PolicyTncAgreeDateTime).format('D/M/YYYY'),
                };
                _tableData_Joined.push(_modal);
                // alert(JSON.stringify(_modal));
                let _progressCounterCurrent = this.state.progressCounterCurrent + 1;
                this.setState({
                    progressCounterCurrent: _progressCounterCurrent,
                    progressText: 'processing data...(' + Math.round(((_progressCounterCurrent / this.state.progressCounterMax) * 100)) + "%) (" + _progressCounterCurrent + "/" + this.state.progressCounterMax + ")",
                    // progressText: 'processing data...(' + (this.state.progressCounterCurrent + 1) + "/" + this.state.progressCounterMax + ")",
                });
            }
        }
        Delay(500);

        //Other table data.
        _tableData_Shared = {
            // TotalJoined: this.state.fetchedJoinedList.length,
            TotalRegistered: this.state.fetchedRegDataList.length,
            TotalExistingStudentJoined: _tableData_Temp.length,
            TotalRegisteredButNotJoined: _tableData_Other.length,
            // TotalNewRegistered: this.state.fetchedJoinedList.length - _tableData_Temp.length,
            // TotalParticipants: this.state.fetchedJoinedList.length + _tableData_Other.length,

            //2021.04.15
            TotalJoined: _tableData_Joined.length,
            TotalNewRegistered: _tableData_Joined.length - _tableData_Temp.length,
            TotalParticipants: _tableData_Joined.length + _tableData_Other.length,

            Temp: 0,    //temp
        };

        //Sort List according to Name in ASC
        _tableData_Joined.sort(function (a, b) {
            if (a.Grade < b.Grade) { return -1; }
            if (a.Grade > b.Grade) { return 1; }
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
        });
        _tableData_Other.sort(function (a, b) {
            if (a.Grade < b.Grade) { return -1; }
            if (a.Grade > b.Grade) { return 1; }
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
        });

        //2022.12.13
        let updated_tableData_Shared = this.mergeTablesAndCalculateCounters(_tableData_Shared, _tableData_Joined, _tableData_Other);
        _tableData_Shared = updated_tableData_Shared;

        // //=== 2021.01.08 === starts ===//
        // //Merge tables & calculate other counters.
        // let _tableDate_Participants = [..._tableData_Joined, ..._tableData_Other];
        // // alert(_tableDate_Participants.length);   //87 = correct
        // let _GroupCounters = [];
        // let _GradeCounters = [];
        // let _StateCounters = [];
        // let _SchoolCounters = [];    //2022.05.31

        // //Jay added 2021.04.13
        // let _IsSimStudent_YES = 0;
        // let _IsSimStudent_NO = 0;
        // let _IsSimStudent_NULL = 0;

        // _tableDate_Participants.map((data, key) => {
        //     //Update Student Group array.
        //     if (data.Group !== undefined && data.Group !== null && data.Group.length > 0) {
        //         let _idx = _GroupCounters.findIndex(x => x.Name === data.Group);
        //         if (_idx > -1)
        //             _GroupCounters[_idx].Qty += 1;
        //         else
        //             _GroupCounters.push({ Name: data.Group, Qty: 1 });
        //     }

        //     //Update Student Grade array.
        //     if (data.Grade !== undefined && data.Grade !== null && data.Grade.length > 0) {
        //         let _idx = _GradeCounters.findIndex(x => x.Name === data.Grade);
        //         if (_idx > -1)
        //             _GradeCounters[_idx].Qty += 1;
        //         else
        //             _GradeCounters.push({ Name: data.Grade, Qty: 1 });
        //     }

        //     // //2022.06.02
        //     // //if Grade exist, Group unknown, auto assign to Group based on Grade.
        //     // if ((data.Grade !== undefined && data.Grade !== null && data.Grade.length > 0 && data.Grade !== '-')
        //     //     && (data.Group === undefined || data.Group === null || data.Group.length === 0 || data.Group === '-')) {
        //     //     let _idx = _GroupCounters.findIndex(x => x.Name === data.Grade);
        //     //     if (_idx > -1)
        //     //         _GroupCounters[_idx].Qty += 1;
        //     //     else
        //     //         _GroupCounters.push({ Name: data.Grade, Qty: 1 });
        //     // }

        //     //Update Student National State array.
        //     if (data.NationalState !== undefined && data.NationalState !== null && data.NationalState.length > 0) {
        //         let _idx = _StateCounters.findIndex(x => x.Name === data.NationalState);
        //         if (_idx > -1)
        //             _StateCounters[_idx].Qty += 1;
        //         else
        //             _StateCounters.push({ Name: data.NationalState, Qty: 1 });
        //     }

        //     //2022.05.31
        //     //Update Student School Counter array.
        //     if (data.School !== undefined && data.School !== null && data.School.length > 0) {
        //         let _idx = _SchoolCounters.findIndex(x => x.Name === data.School);
        //         if (_idx > -1)
        //             _SchoolCounters[_idx].Qty += 1;
        //         else
        //             _SchoolCounters.push({ Name: data.School, Qty: 1 });
        //     }

        //     //Jay added 2021.04.13
        //     let _IsSimStudent = String(data.IsCenterStudent).toLowerCase();
        //     if (_IsSimStudent === 'yes')
        //         _IsSimStudent_YES += 1;
        //     else if (_IsSimStudent === 'no')
        //         _IsSimStudent_NO += 1;
        //     else
        //         _IsSimStudent_NULL += 1;

        //     return null;
        // });

        // _tableData_Shared.GroupCounters = this.sortTable_ByName_ByNullAtLast(_GroupCounters, 'asc', SortBy.Name);
        // _tableData_Shared.GradeCounters = this.sortTable_ByName_ByNullAtLast(_GradeCounters, 'asc', SortBy.Name);
        // _tableData_Shared.StateCounters = this.sortTable_ByName_ByNullAtLast(_StateCounters, 'asc', SortBy.Name);
        // _tableData_Shared.SchoolCounters = this.sortTable_ByName_ByNullAtLast(_SchoolCounters, 'asc', SortBy.Name);     //2022.05.31

        // //=== 2021.01.08 === ends ===//

        // //Jay added 2021.04.13
        // _tableData_Shared.IsSimStudentYES = _IsSimStudent_YES;
        // _tableData_Shared.IsSimStudentNO = _IsSimStudent_NO;
        // _tableData_Shared.IsSimStudentNULL = _IsSimStudent_NULL;

        this.setState({
            progressText: 'saving table...',
        });
        Delay(1000);

        // //Update to FireStore > _Counters > EventJoinList > Shared / Joined / NewRegistered.
        // await this.updateToFireStore_TableData(_tableData_Shared, _tableData_Joined, _tableData_Other);

        //2021.04.15
        let _refreshStartTime = moment(this.state.refreshStartTime);
        let _refreshEndTime = moment();

        this.setState({
            tableData_Joined: _tableData_Joined,
            tableData_Other: _tableData_Other,
            tableData_Shared: _tableData_Shared,
            isTargetListLoaded: true,
            isTargetListLoading: false,

            //2021.04.15
            refreshStartTime: _refreshStartTime.format('LLL'),
            refreshEndTime: _refreshEndTime.format('LLL'),
            refreshTimeTaken: _refreshEndTime.diff(_refreshStartTime, 'minutes') + ' minutes',

        }, async () => {
            //Update to FireStore > _Counters > EventJoinList > Shared / Joined / NewRegistered.
            await this.updateToFireStore_TableData();

            this.populating_Hidden_EventJoinedListTable();
        });
    }

    //2022.12.13
    mergeTablesAndCalculateCounters = (_tableData_Shared, _tableData_Joined, _tableData_Other) => {
        //Merge tables & calculate other counters.
        let _tableDate_Participants = [..._tableData_Joined, ..._tableData_Other];
        // alert(_tableDate_Participants.length);   //87 = correct
        let _GroupCounters = [];
        let _GradeCounters = [];
        let _StateCounters = [];
        let _SchoolCounters = [];    //2022.05.31

        //Jay added 2021.04.13
        let _IsSimStudent_YES = 0;
        let _IsSimStudent_NO = 0;
        let _IsSimStudent_NULL = 0;

        _tableDate_Participants.map((data, key) => {
            //Update Student Group array.
            if (data.Group !== undefined && data.Group !== null && data.Group.length > 0) {
                let _idx = _GroupCounters.findIndex(x => x.Name === data.Group);
                if (_idx > -1)
                    _GroupCounters[_idx].Qty += 1;
                else
                    _GroupCounters.push({ Name: data.Group, Qty: 1 });
            }

            //Update Student Grade array.
            if (data.Grade !== undefined && data.Grade !== null && data.Grade.length > 0) {
                let _idx = _GradeCounters.findIndex(x => x.Name === data.Grade);
                if (_idx > -1)
                    _GradeCounters[_idx].Qty += 1;
                else
                    _GradeCounters.push({ Name: data.Grade, Qty: 1 });
            }

            // //2022.06.02
            // //if Grade exist, Group unknown, auto assign to Group based on Grade.
            // if ((data.Grade !== undefined && data.Grade !== null && data.Grade.length > 0 && data.Grade !== '-')
            //     && (data.Group === undefined || data.Group === null || data.Group.length === 0 || data.Group === '-')) {
            //     let _idx = _GroupCounters.findIndex(x => x.Name === data.Grade);
            //     if (_idx > -1)
            //         _GroupCounters[_idx].Qty += 1;
            //     else
            //         _GroupCounters.push({ Name: data.Grade, Qty: 1 });
            // }

            //Update Student National State array.
            if (data.NationalState !== undefined && data.NationalState !== null && data.NationalState.length > 0) {
                let _idx = _StateCounters.findIndex(x => x.Name === data.NationalState);
                if (_idx > -1)
                    _StateCounters[_idx].Qty += 1;
                else
                    _StateCounters.push({ Name: data.NationalState, Qty: 1 });
            }

            //2022.05.31
            //Update Student School Counter array.
            if (data.School !== undefined && data.School !== null && data.School.length > 0) {
                let _idx = _SchoolCounters.findIndex(x => x.Name === data.School);
                if (_idx > -1)
                    _SchoolCounters[_idx].Qty += 1;
                else
                    _SchoolCounters.push({ Name: data.School, Qty: 1 });
            }

            //Jay added 2021.04.13
            let _IsSimStudent = String(data.IsCenterStudent).toLowerCase();
            if (_IsSimStudent === 'yes')
                _IsSimStudent_YES += 1;
            else if (_IsSimStudent === 'no')
                _IsSimStudent_NO += 1;
            else
                _IsSimStudent_NULL += 1;

            return null;
        });

        _tableData_Shared.GroupCounters = this.sortTable_ByName_ByNullAtLast(_GroupCounters, 'asc', SortBy.Name);
        _tableData_Shared.GradeCounters = this.sortTable_ByName_ByNullAtLast(_GradeCounters, 'asc', SortBy.Name);
        _tableData_Shared.StateCounters = this.sortTable_ByName_ByNullAtLast(_StateCounters, 'asc', SortBy.Name);
        _tableData_Shared.SchoolCounters = this.sortTable_ByName_ByNullAtLast(_SchoolCounters, 'asc', SortBy.Name);     //2022.05.31

        //=== 2021.01.08 === ends ===//

        //Jay added 2021.04.13
        _tableData_Shared.IsSimStudentYES = _IsSimStudent_YES;
        _tableData_Shared.IsSimStudentNO = _IsSimStudent_NO;
        _tableData_Shared.IsSimStudentNULL = _IsSimStudent_NULL;

        return _tableData_Shared;
    }

    sortTable_ByName_ByNullAtLast = (_tableArray, _orderBy, _sortBy) => {
        // //sort tables by Name .
        // if (_orderBy === 'asc') {
        //     _tableArray.sort(function (a, b) {    //asc
        //         if (a[_sortBy] < b[_sortBy]) { return -1; }
        //         if (a[_sortBy] > b[_sortBy]) { return 1; }
        //         return 0;
        //     });
        // }
        // else {
        //     _tableArray.sort(function (a, b) {    //desc
        //         if (a[_sortBy] > b[_sortBy]) { return -1; }
        //         if (a[_sortBy] < b[_sortBy]) { return 1; }
        //         return 0;
        //     });
        // }

        // //sort tables by '-' at last.
        // _tableArray.sort(function (a, b) {
        //     return (a[_sortBy] === '-') - (b[_sortBy] === '-');
        // });


        //2021.03.04
        if (_sortBy === SortBy.RegisteredDate || _sortBy === SortBy.JoinedDate) {
            //sort table by Date.
            if (_orderBy === 'asc') {
                _tableArray.sort(function (a, b) {    //asc
                    if (moment(a[_sortBy], 'D/M/YYYY') < moment(b[_sortBy], 'D/M/YYYY')) { return -1; }
                    if (moment(a[_sortBy], 'D/M/YYYY') > moment(b[_sortBy], 'D/M/YYYY')) { return 1; }
                    return 0;
                });
            }
            else {
                _tableArray.sort(function (a, b) {    //desc
                    if (moment(a[_sortBy], 'D/M/YYYY') > moment(b[_sortBy], 'D/M/YYYY')) { return -1; }
                    if (moment(a[_sortBy], 'D/M/YYYY') < moment(b[_sortBy], 'D/M/YYYY')) { return 1; }
                    return 0;
                });
            }
        }
        else {
            //sort table by String.
            if (_orderBy === 'asc') {
                _tableArray.sort(function (a, b) {    //asc
                    if (a[_sortBy] < b[_sortBy]) { return -1; }
                    if (a[_sortBy] > b[_sortBy]) { return 1; }
                    return 0;
                });
            }
            else {
                _tableArray.sort(function (a, b) {    //desc
                    if (a[_sortBy] > b[_sortBy]) { return -1; }
                    if (a[_sortBy] < b[_sortBy]) { return 1; }
                    return 0;
                });
            }
        }

        //sort table by '-' at last.
        _tableArray.sort(function (a, b) {
            return (a[_sortBy] === '-') - (b[_sortBy] === '-');
        });

        return _tableArray;
    }

    updateToFireStore_TableData = async (_tableData_Shared = [], _tableData_Joined = [], _tableData_Other = [], update = 'all') => {
        let _lastUpdate = moment().format("lll");

        //#region old logic.
        // let _modal = {
        //     LastUpdate: _lastUpdate,
        //     Counter: "EventJoinList_" + this.state.SelectedEventCode,
        //     EventCode: this.state.SelectedEventCode,
        //     Shared: _tableData_Shared,
        //     Joined: _tableData_Joined,
        //     Other: _tableData_Other,
        // };
        // console.log('Shared = ' + _tableData_Shared.length
        //     + '\nJoined = ' + _tableData_Joined.length
        //     + '\nOther = ' + _tableData_Other.length);

        // await this.props.dbFireStore.collection("_Counters")
        //     .doc("EventJoinList_" + this.state.SelectedEventCode)
        //     .set(_modal)
        //     .catch((error) => {
        //         console.log("Error\n\n" + error.code + "\n\n" + error.message);
        //     });
        //#endregion

        //#region 2021.09.10 - batch query pattern.
        // let _modal = {
        //     LastUpdate: _lastUpdate,
        //     Counter: "EventJoinList_" + this.state.SelectedEventCode,
        //     EventCode: this.state.SelectedEventCode,
        //     Shared: [],
        //     Joined: [],
        //     Other: [],
        // };

        // await this.props.dbFireStore.collection("_Counters")
        //     .doc("EventJoinList_" + this.state.SelectedEventCode)
        //     .set(_modal)
        //     .catch((error) => {
        //         console.log("Error\n\n" + error.code + "\n\n" + error.message);
        //     });

        // await this.props.dbFireStore.collection("_Counters")
        //     .doc("EventJoinList_" + this.state.SelectedEventCode)
        //     // .update({ Shared: _tableData_Shared })
        //     .update({ Shared: this.state.tableData_Shared })
        //     .catch((error) => {
        //         console.log("Error (Update:Shared)\n\n" + error.code + "\n\n" + error.message);
        //     });
        // console.log('Shared = sent');

        // await this.props.dbFireStore.collection("_Counters")
        //     .doc("EventJoinList_" + this.state.SelectedEventCode)
        //     // .update({ Joined: _tableData_Joined })
        //     .update({ Joined: this.state.tableData_Joined })
        //     .catch((error) => {
        //         console.log("Error (Update:Joined)\n\n" + error.code + "\n\n" + error.message);
        //     });
        // console.log('Joined = sent');

        // await this.props.dbFireStore.collection("_Counters")
        //     .doc("EventJoinList_" + this.state.SelectedEventCode)
        //     // .update({ Other: _tableData_Other })
        //     .update({ Other: this.state.tableData_Other })
        //     .catch((error) => {
        //         console.log("Error (Update:Other)\n\n" + error.code + "\n\n" + error.message);
        //     });
        // console.log('Other = sent');

        // //batch query.
        // await Delay(500);
        // var batch = this.props.dbFireStore.batch();
        // var ref = this.props.dbFireStore.collection("_Counters").doc("EventJoinList_" + this.state.SelectedEventCode);
        // batch.update(ref, { "Shared": this.state.tableData_Shared });
        // batch.update(ref, { "Joined": this.state.tableData_Joined });
        // batch.update(ref, { "Other": this.state.tableData_Other });
        // batch.commit().then(() => {
        //     console.log('batch update process (completed) (' + moment().format('YYYY/MM/DD HH:mm') + ')');
        // })
        //     .catch((error) => {
        //         console.log('batch update process (failed) (' + moment().format('YYYY/MM/DD HH:mm') + ').\n\n'
        //             + error.code + "\n\n" + error.message);
        //     });
        //#endregion

        //#region 2021.09.10 - revamped pattern - collection division style.
        let _modal = {
            LastUpdate: _lastUpdate,
            Counter: "EventJoinList_" + this.state.SelectedEventCode,
            EventCode: this.state.SelectedEventCode,
            Shared: this.state.tableData_Shared,
            Joined: this.state.tableData_Shared.TotalParticipants > 2000 ? [] : this.state.tableData_Joined,
            Other: this.state.tableData_Shared.TotalParticipants > 2000 ? [] : this.state.tableData_Other,
            RefreshStartTime: this.state.refreshStartTime,
            RefreshEndTime: this.state.refreshEndTime,
            RefreshTimeTaken: this.state.refreshTimeTaken,
        };

        //Clear document & everything nested in it (collections) - EventJoinList_{EventCode}
        if (update === 'all' || update === 'joined') {
            await this.props.dbFireStore.collection("_Counters")
                .doc("EventJoinList_" + this.state.SelectedEventCode)  //2021.12.31 - remove all docs in this collection. each doc may contains 1~2000 items.
                .collection('Joined')
                .get()
                .then(querySnapshot => {
                    querySnapshot.docs.forEach(snapshot => {
                        snapshot.ref.delete();
                    })
                })
                .catch((error) => {
                    if (this.props.isDevMode)
                        console.log("Error\n\n" + error.code + "\n\n" + error.message);
                });
        }
        if (update === 'all' || update === 'other') {
            await this.props.dbFireStore.collection("_Counters")
                .doc("EventJoinList_" + this.state.SelectedEventCode)  //2021.12.31 - remove all docs in this collection. each doc may contains 1~2000 items.
                .collection('Other')
                .get()
                .then(querySnapshot => {
                    querySnapshot.docs.forEach(snapshot => {
                        snapshot.ref.delete();
                    })
                })
                .catch((error) => {
                    if (this.props.isDevMode)
                        console.log("Error\n\n" + error.code + "\n\n" + error.message);
                });
        }

        //Root data.
        if (update === 'all' || update === 'root') {
            //Clear document & everything nested in it (collections) - EventJoinList_{EventCode}
            await this.props.dbFireStore.collection("_Counters")
                .doc("EventJoinList_" + this.state.SelectedEventCode)
                .delete()
                .catch((error) => {
                    if (this.props.isDevMode)
                        console.log("Error\n\n" + error.code + "\n\n" + error.message);
                });

            //Add back the document.
            await this.props.dbFireStore.collection("_Counters")
                .doc("EventJoinList_" + this.state.SelectedEventCode)
                .set(_modal)
                .catch((error) => {
                    if (this.props.isDevMode)
                        console.log("Error\n\n" + error.code + "\n\n" + error.message);
                });
        }

        //Sub-data.
        if (this.state.tableData_Shared.TotalParticipants > 2000) {
            //Joined.
            if (update === 'all' || update === 'joined') {
                //seperate to multiple doc to hold arrays of data in list's collection.
                let tableLength = this.state.tableData_Joined.length;
                let division = tableLength / 2000;
                let repeat = ~~division;    //only get first int of a float, not round.
                let _array = [];
                let div = 1;
                let div_start = 0;
                let div_end = (div * 2000) - 1;
                div_end = div_end > tableLength ? tableLength - 1 : div_end;
                repeat = (division - repeat) > 0 ? repeat + 1 : repeat;
                for (var J = 0; J < tableLength; J++) {
                    _array.push(this.state.tableData_Joined[J]);
                    let t_array = _array;
                    let _div = div;
                    let _div_start = div_start;
                    let _div_end = div_end;
                    if (J === div_end) {    //0~1999 for division 1, etc.
                        await this.props.dbFireStore
                            .collection("_Counters")
                            .doc("EventJoinList_" + this.state.SelectedEventCode)
                            .collection('Joined')
                            .doc(String(div))       //no random doc id.
                            .set({ data: t_array })
                            .then(() => {
                                _div = _div + 1;  // div = 2
                                _div_start = _div_end + 1;    //1999 + 1 = 2000
                                _div_end = (_div * 2000) - 1; // (2 * 2000) - 1 = 3999
                                _div_end = _div_end > tableLength ? tableLength - 1 : _div_end;
                                t_array = [];
                                if (this.props.isDevMode)
                                    console.log('Done (Joined)(' + _div_start + ')(' + _div_end + ') ' + repeat);
                            })
                            .catch((error) => {
                                if (this.props.isDevMode)
                                    console.log('Error (Joined)(' + _div_start + ')(' + _div_end + ') ' + repeat + '\n\n' + error.code + "\n\n" + error.message);
                            });
                    }
                    _array = t_array;
                    div = _div;
                    div_start = _div_start;
                    div_end = _div_end;
                }
            }

            //Other.
            if (update === 'all' || update === 'other') {
                //seperate to multiple doc to hold arrays of data in list's collection.
                let tableLength = this.state.tableData_Other.length;
                let division = tableLength / 2000;
                let repeat = ~~division;    //only get first int of a float, not round.
                let _array = [];
                let div = 1;
                let div_start = 0;
                let div_end = (div * 2000) - 1;
                div_end = div_end > tableLength ? tableLength - 1 : div_end;
                repeat = (division - repeat) > 0 ? repeat + 1 : repeat;
                for (var K = 0; K < tableLength; K++) {
                    _array.push(this.state.tableData_Other[K]);
                    let t_array = _array;
                    let _div = div;
                    let _div_start = div_start;
                    let _div_end = div_end;
                    if (K === div_end) {    //0~2999 for division 1, etc.
                        await this.props.dbFireStore
                            .collection("_Counters")
                            .doc("EventJoinList_" + this.state.SelectedEventCode)
                            .collection('Other')
                            .doc(String(div))       //no random doc id.
                            .set({ data: t_array })
                            .then(() => {
                                _div = _div + 1;  // div = 2
                                _div_start = _div_end + 1;    //1999 + 1 = 2000
                                _div_end = (_div * 2000) - 1; // (2 * 2000) - 1 = 3999
                                _div_end = _div_end > tableLength ? tableLength - 1 : _div_end;
                                t_array = [];
                                if (this.props.isDevMode)
                                    console.log('Done (Other)(' + _div_start + ')(' + _div_end + ')(' + _div + ') ' + repeat);
                            })
                            .catch((error) => {
                                if (this.props.isDevMode)
                                    console.log('Error (Other)(' + _div_start + ')(' + _div_end + ')(' + _div + ') ' + repeat + '\n\n' + error.code + "\n\n" + error.message);
                            });
                    }
                    _array = t_array;
                    div = _div;
                    div_start = _div_start;
                    div_end = _div_end;
                }
            }
        }
        //#endregion

        this.setState({
            lastUpdate: _lastUpdate,
        });
    }

    fetchProfileDataInFireStore = async (_uid = '') => {
        let _regData = [];
        await this.props.dbFireStore
            .collection("User")
            // .where('Uid', '==', _uid)
            .doc(_uid)
            .get()
            .then((querySnapshot) => {
                // if (querySnapshot !== null) {
                //     querySnapshot.forEach((doc) => {
                //         _regData.push(doc.data());
                //     });
                // }
                if (querySnapshot.exists) {
                    _regData.push(querySnapshot.data());
                }
            });
        // .catch(()=> {
        //     return null;
        // });
        // .catch(async (error) => {
        //     alert("Profile Data : Fetch Failed...\n\n" + error.message);
        // });
        return _regData.length > 0 ? _regData[0] : null;
    }

    // 2021.04.15 - single user.
    fetchJoinedDataInFireStore = async (_uid = '') => {
        let joinedData = [];
        await this.props.dbFireStore
            .collection("LiveQuizEventJoinedList")
            .doc(String(this.state.SelectedEventCode))
            .collection("List")
            // .where('Uid', '==', _uid)
            .doc(_uid)
            .get()
            .then((querySnapshot) => {
                // if (querySnapshot !== null) {
                //     querySnapshot.forEach((doc) => {
                //         _regData.push(doc.data());
                //     });
                // }
                if (querySnapshot.exists) {
                    joinedData.push(querySnapshot.data());
                }
            });
        return joinedData.length > 0 ? joinedData[0] : null;
    }

    // toggleSortBy_tbJoined = (_sortBy) => {
    //     let _sortBy_ASC_DESC = false;
    //     if (_sortBy === SortBy.Name) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_Name;
    //         this.setState({ sort_tbJoined_Name: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.Grade) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_Grade;
    //         this.setState({ sort_tbJoined_Grade: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.Group) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_Group;
    //         this.setState({ sort_tbJoined_Group: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.Guardian) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_Guardian;
    //         this.setState({ sort_tbJoined_Guardian: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.Email) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_Email;
    //         this.setState({ sort_tbJoined_Email: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.State) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_State;
    //         this.setState({ sort_tbJoined_State: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.School) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_School;
    //         this.setState({ sort_tbJoined_School: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.Center) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_IsCenterStudent;
    //         this.setState({ sort_tbJoined_IsCenterStudent: _sortBy_ASC_DESC });
    //     }
    //     else if (_sortBy === SortBy.NewRegistered) {
    //         _sortBy_ASC_DESC = !this.state.sort_tbJoined_NewRegistered;
    //         this.setState({ sort_tbJoined_NewRegistered: _sortBy_ASC_DESC });
    //     }
    //     return _sortBy_ASC_DESC;
    // }

    tbJoined_sortBy = (_sortBy, _orderBy) => {
        // let _tableData = this.state.tableData_Joined;
        // let _sortBy_ASC_DESC = this.toggleSortBy_tbJoined(_sortBy);

        // if (_orderBy) {
        //     _tableData.sort(function (a, b) {    //asc
        //         if (a[_sortBy] < b[_sortBy]) { return -1; }
        //         if (a[_sortBy] > b[_sortBy]) { return 1; }
        //         return 0;
        //     });
        // }
        // else {
        //     _tableData.sort(function (a, b) {    //desc
        //         if (a[_sortBy] > b[_sortBy]) { return -1; }
        //         if (a[_sortBy] < b[_sortBy]) { return 1; }
        //         return 0;
        //     });
        // }
        // _tableData.sort(function (a, b) {
        //     return (a[_sortBy] === '-') - (b[_sortBy] === '-');
        // });

        let _tableData = this.sortTable_ByName_ByNullAtLast(this.state.tableData_Joined, _orderBy, _sortBy);

        this.setState({
            tableData_Joined: _tableData,
            sortSelected: _sortBy,
            sortAscDesc: _orderBy,
            sortTable: TargetTable.Joined,
        }, () => {
            this.populating_Hidden_EventJoinedListTable();
        });
    }

    tbOther_sortBy = (_sortBy, _orderBy) => {
        // let _tableData = this.state.tableData_Other;
        // let _sortBy_ASC_DESC = this.toggleSortBy_tbJoined(_sortBy);

        // if (_orderBy) {
        //     _tableData.sort(function (a, b) {    //asc
        //         if (a[_sortBy] < b[_sortBy]) { return -1; }
        //         if (a[_sortBy] > b[_sortBy]) { return 1; }
        //         return 0;
        //     });
        // }
        // else {
        //     _tableData.sort(function (a, b) {    //desc
        //         if (a[_sortBy] > b[_sortBy]) { return -1; }
        //         if (a[_sortBy] < b[_sortBy]) { return 1; }
        //         return 0;
        //     });
        // }
        // _tableData.sort(function (a, b) {
        //     return (a[_sortBy] === '-') - (b[_sortBy] === '-');
        // });

        let _tableData = this.sortTable_ByName_ByNullAtLast(this.state.tableData_Other, _orderBy, _sortBy);

        this.setState({
            tableData_Other: _tableData,
            sortSelected: _sortBy,
            sortAscDesc: _orderBy,
            sortTable: TargetTable.Other,
        }, () => {
            this.populating_Hidden_EventJoinedListTable();
        });
    }

    getNameOfReport = () => {
        // return "Event-Join-List_" + this.state.SelectedEventCode.toUpperCase().replace(new RegExp("_", "g"), "-") + "_" + this.state.targetCenter.replace(new RegExp(" ", "g"), "-") + "_(" + moment(this.state.lastUpdate).format("YYYY-MM-DD_HHmm") + ")";

        //2021.09.10
        let reportName = 'Event-Join-List_';
        if (this.state.selectedEvent !== null)
            reportName += String(this.state.selectedEvent.EventName).replace(new RegExp(" ", "g"), "-") + '_';
        reportName += this.state.SelectedEventCode.toUpperCase().replace(new RegExp("_", "g"), "-") + '_';
        reportName += this.state.targetCenter.replace(new RegExp(" ", "g"), "-") + "_(" + moment(this.state.lastUpdate).format("YYYY-MM-DD_HHmm") + ")";
        return reportName
    }

    getTableHeader = (_title, _sortBy, _table) => {
        return (
            <th style={{ verticalAlign: "bottom" }}>
                <div className="row">
                    <div className="col" style={{ textAlign: "center", paddingBottom: 10, lineHeight: '100%', }}><div dangerouslySetInnerHTML={{ __html: _title }}></div></div>
                </div>
                <div className="row">
                    <div className="col" style={{ textAlign: "center" }}>
                        <button
                            className="btn-sort"
                            onClick={() => { _table === TargetTable.Joined ? this.tbJoined_sortBy(_sortBy, 'asc') : this.tbOther_sortBy(_sortBy, 'asc') }}
                            style={this.state.sortSelected === _sortBy && this.state.sortAscDesc === 'asc' && _table === this.state.sortTable ? { backgroundColor: "#3750e0", color: "#fff" } : {}}
                        >↓</button>&nbsp;
                        <button
                            className="btn-sort"
                            onClick={() => { _table === TargetTable.Joined ? this.tbJoined_sortBy(_sortBy, 'desc') : this.tbOther_sortBy(_sortBy, 'desc') }}
                            style={this.state.sortSelected === _sortBy && this.state.sortAscDesc === 'desc' && _table === this.state.sortTable ? { backgroundColor: "#3750e0", color: "#fff" } : {}}
                        >↑</button>
                    </div>
                </div>
            </th>
        );
    }


    PrintDiv = () => {
        window.alert("Best View in Mode: Landscape, A3 size paper");

        // this.setState({
        //     Hidden_Event_Joined_List_Table: this.state.Hidden_Event_Joined_List_Table_Print,
        // });
        // Hidden_Event_Joined_List_Table = this.state.Hidden_Event_Joined_List_Table_Print;

        let objectData = 'Event-Joined-List-Table_hidden';

        var mywindow = window.open('', 'PRINT', 'height=700,width=1000');

        mywindow.document.write('<html><head><title>' + this.getNameOfReport() + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + document.title + '</h1>');
        mywindow.document.write(document.getElementById(objectData).innerHTML.replace('blue', 'black'));
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        // this.setState({
        //     Hidden_Event_Joined_List_Table: this.state.Hidden_Event_Joined_List_Table_Excel,
        // });
        // Hidden_Event_Joined_List_Table = this.state.Hidden_Event_Joined_List_Table_Excel;

        return true;
    }

    populating_Hidden_EventJoinedListTable = () => {

        let _space_1 = 13;  //15;  //13;  //10;  //6;
        let _space_2 = 10;  //12;  //10;  //7;   //3;

        let _htmlStrings = "<table id='Event-Joined-List-Table' border='0'>";

        //======== Table - FileName ========//
        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";
        _htmlStrings += "<tr><td colspan='" + _space_1 + "'><b>" + this.getNameOfReport() + "</b></td></tr>";
        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        //======== Table - Shared Info ======== starts ========//
        _htmlStrings += "<tr><td>&nbsp;&nbsp;</td><td width='375'>Last Update</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            // + "<b>" + this.state.lastUpdate  //moment(this.state.selectedDate).format('dddd, MMMM Do, YYYY')
            + "<b>" + moment(this.state.lastUpdate).format('LLLL')
            + "</b></td></tr>";

        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        _htmlStrings += "<tr><td></td><td colspan='" + (_space_1 - 1) + "'><b>Summary</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Total New Registered Students (" + this.state.targetCenter + ")</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + this.state.tableData_Shared.TotalRegistered
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Existing Students (Joined iKEY previously)</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + this.state.tableData_Shared.TotalExistingStudentJoined
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>New Registered Students (Registered in this Event)</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + this.state.tableData_Shared.TotalNewRegistered    //(this.state.tableData_Shared.TotalJoined - this.state.tableData_Shared.TotalExistingStudentJoined)
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Total Students joined in this Event</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + this.state.tableData_Shared.TotalJoined
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>Total Registered Students but not yet join</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + this.state.tableData_Shared.TotalRegisteredButNotJoined
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>(Summary) Total Participants</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + this.state.tableData_Shared.TotalParticipants
            + "</b></td></tr>";

        //Jay added 2021.04.13 === start ===
        _htmlStrings += "<tr><td></td><td>* Organizer Students (Yes)</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + (this.state.tableData_Shared.hasOwnProperty('IsSimStudentYES') ? this.state.tableData_Shared.IsSimStudentYES : '-')
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>* Organizer Students (No)</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + (this.state.tableData_Shared.hasOwnProperty('IsSimStudentNO') ? this.state.tableData_Shared.IsSimStudentNO : '-')
            + "</b></td></tr>";
        _htmlStrings += "<tr><td></td><td>* Organizer Students (-)</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
            + "<b>" + (this.state.tableData_Shared.hasOwnProperty('IsSimStudentNULL') ? this.state.tableData_Shared.IsSimStudentNULL : '-')
            + "</b></td></tr>";
        //Jay added 2021.04.13 === end ===

        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        //Group
        _htmlStrings += "<tr><td></td><td colspan='" + (_space_1 - 1) + "'><b>Group</b></td></tr>";
        if (this.state.tableData_Shared.hasOwnProperty('GroupCounters')) {
            this.state.tableData_Shared.GroupCounters.map((data, key) => {
                _htmlStrings += "<tr><td></td><td>" + data.Name + "</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
                    + "<b>" + data.Qty + "</b></td></tr>";
                return null;
            });
        }

        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        //Grade
        _htmlStrings += "<tr><td></td><td colspan='" + (_space_1 - 1) + "'><b>Grade</b></td></tr>";
        if (this.state.tableData_Shared.hasOwnProperty('GradeCounters')) {
            this.state.tableData_Shared.GradeCounters.map((data, key) => {
                _htmlStrings += "<tr><td></td><td>" + data.Name + "</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
                    + "<b>" + data.Qty + "</b></td></tr>";
                return null;
            });
        }

        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        //Country State
        _htmlStrings += "<tr><td></td><td colspan='" + (_space_1 - 1) + "'><b>Country State</b></td></tr>";
        if (this.state.tableData_Shared.hasOwnProperty('StateCounters')) {
            this.state.tableData_Shared.StateCounters.map((data, key) => {
                _htmlStrings += "<tr><td></td><td>" + data.Name + "</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
                    + "<b>" + data.Qty + "</b></td></tr>";
                return null;
            });
        }

        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        //2022.05.31
        //School
        _htmlStrings += "<tr><td></td><td colspan='" + (_space_1 - 1) + "'><b>School</b></td></tr>";
        if (this.state.tableData_Shared.hasOwnProperty('SchoolCounters')) {
            this.state.tableData_Shared.SchoolCounters.map((data, key) => {
                _htmlStrings += "<tr><td></td><td>" + data.Name + "</td><td>:</td><td colspan='" + _space_2 + "' align='left'>"
                    + "<b>" + data.Qty + "</b></td></tr>";
                return null;
            });
        }

        //======== Table - Shared Info ======== ends ========//

        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        //======== Table - Total Joined Students ======== starts ========//
        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "' align='left'><b>Total Joined Students</b></td></tr>";

        // _htmlStrings += "<table width='100%' border='1'>";
        _htmlStrings += "<tr>";
        _htmlStrings += "<td><b>#</b></td> <td><b>Name</b></td> <td width='100'><b>Grade</b></td> <td width='100'><b>Group</b></td>";
        // _htmlStrings += "<td width='100'><b>Gender</b></td> <td width='100'><b>Race</b></td>";    //2022.06.13
        _htmlStrings += "<td width='300'><b>Guardian</b></td> <td width='300'><b>Contact</b></td> <td width='300'><b>Email</b></td>";
        _htmlStrings += "<td width='350'><b>State</b></td> <td width='500'><b>School</b></td> <td><b>Student of Organizer</b></td>";
        _htmlStrings += "<td><b>New Registered</b></td>";

        //2021.03.02
        _htmlStrings += "<td><b>Date of Registered</b></td>";
        _htmlStrings += "<td><b>Date of Joined</b></td>";
        _htmlStrings += "</tr>";

        if (this.state.tableData_Joined.length > 0) {
            this.state.tableData_Joined.map((data, key) => {
                let _temp = "<tr>";
                _temp += "<td>" + (key + 1) + "</td>";
                _temp += "<td>" + data.Name + "</td>";
                _temp += "<td>" + data.Grade + "</td>";
                _temp += "<td>" + data.Group + "</td>";
                // _temp += "<td>" + this.FormatGender(data.Gender) + "</td>";     //2022.06.13
                // _temp += "<td>" + this.FormatRace(data.Race) + "</td>";         //2022.06.13
                _temp += "<td>" + data.Guardian + "</td>";

                _temp += "<td>'" + this.FormatContact(data.Contact) + "</td>";
                _temp += "<td>" + data.Email + "</td>";
                _temp += "<td>" + data.NationalState + "</td>";
                _temp += "<td>" + data.School + "</td>";
                _temp += "<td>" + data.IsCenterStudent + "</td>";

                _temp += "<td>" + (data.NewRegistered ? 'Yes' : 'No') + "</td>";

                //2021.03.02
                _temp += "<td>" + data.RegisteredDate + "</td>";
                _temp += "<td>" + data.JoinedDate + "</td>";

                _temp += "</tr>";
                return _htmlStrings += _temp;
            });
        }
        else {
            _htmlStrings += '<tr><td colspan="13">list not loaded.</td></tr>';
        }
        //======== Table - Total Joined Students ======== ends ========//

        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "'></td></tr>";

        //======== Table - Other Registered Students ======== starts ========//
        _htmlStrings += "<tr height='30'><td colspan='" + _space_1 + "' align='left'><b>Other Registered Students (Students that are still haven't join the current event)</b></td></tr>";

        _htmlStrings += "<tr>";
        _htmlStrings += "<td><b>#</b></td> <td><b>Name</b></td> <td><b>Grade</b></td> <td><b>Group</b></td>";
        _htmlStrings += "<td width='100'><b>Gender</b></td> <td width='100'><b>Race</b></td>";    //2022.06.13
        _htmlStrings += "<td><b>Guardian</b></td> <td><b>Contact</b></td> <td><b>Email</b></td>";
        _htmlStrings += "<td><b>State</b></td> <td><b>School</b></td> <td><b>Student of Organizer</b></td>";
        // _htmlStrings += "<td><b>New Registered</b></td>";
        _htmlStrings += "<td><b>Date of Registered</b></td>";  //2021.03.02
        _htmlStrings += "</tr>";

        if (this.state.tableData_Other.length > 0) {
            this.state.tableData_Other.map((data, key) => {
                let _temp = "<tr>";
                _temp += "<td>" + (key + 1) + "</td>";
                _temp += "<td>" + data.Name + "</td>";
                _temp += "<td>" + data.Grade + "</td>";
                _temp += "<td>" + data.Group + "</td>";
                // _temp += "<td>" + this.FormatGender(data.Gender) + "</td>";     //2022.06.13
                // _temp += "<td>" + this.FormatRace(data.Race) + "</td>";         //2022.06.13
                _temp += "<td>" + data.Guardian + "</td>";

                _temp += "<td>'" + this.FormatContact(data.Contact) + "</td>";
                _temp += "<td>" + data.Email + "</td>";
                _temp += "<td>" + data.NationalState + "</td>";
                _temp += "<td>" + data.School + "</td>";
                _temp += "<td>" + data.IsCenterStudent + "</td>";

                // _temp += "<td>" + (data.NewRegistered ? 'Yes' : 'No') + "</td>";
                _temp += "<td>" + data.RegisteredDate + "</td>";   //2021.03.02
                _temp += "</tr>";
                return _htmlStrings += _temp;
            });
        }
        else {
            _htmlStrings += '<tr><td colspan="11">list not loaded.</td></tr>';
        }
        //======== Table - Other Registered Students ======== ends ========//


        // _htmlStrings += "</table>";
        _htmlStrings += "</td></tr>";
        _htmlStrings += "</table>";

        // Hidden_Event_Joined_List_Table = _htmlStrings;
        this.setState({
            Hidden_Event_Joined_List_Table: _htmlStrings,
            // Hidden_Event_Joined_List_Table_Print: String(_htmlStrings).replace(new RegExp("+", ""), "g"),
            // Hidden_Event_Joined_List_Table_Excel: _htmlStrings,
        });
    }

    FormatContact = (_contact) => {
        // _contact = _contact.replace("＋", "+");
        // _contact = String(_contact).includes('+') ? _contact : "+" + _contact;
        // return _contact;
        return String(_contact);
    }

    //2022.06.13
    FormatGender = (_gender) => {
        if (_gender === null)
            return '';

        const value = String(_gender).toLowerCase();
        if (value.includes('fe') || value === 'female')
            return 'F';
        else
            return 'M';
    }

    //2022.06.13
    FormatRace = (_race) => {
        if (_race === null)
            return '-';

        const value = String(_race).toLowerCase();
        if (value.includes('chi') || value === 'chinese')
            return 'CINA';
        else if (value.includes('mal') || value === 'malay')
            return 'MELAYU';
        else if (value.includes('ind') || value === 'indian')
            return 'INDIA';
        else if (value.includes('oth') || value === 'other')
            return 'OTHER';
        else
            return '-';
    }

    // //2021.01.29
    // BackToMenu = () => {
    //     // this.props.SetStage(this.props.StageMode.Begin);
    //     sessionStorage.setItem('redirectRequired', this.props.StageMode.Begin);
    //     window.location.reload();
    // }

    render = () => {
        if (!this.props.user) {
            return <Redirect link="/login" />;   //back to login screen.
        }
        return (
            <>
                {/* <div style={{ width: "100%", padding: 15 }} id="top"> */}

                {/* <div className="row">
                        <div className="col" align="center">Welcome to Event Join List.&nbsp;&nbsp;
                            <button
                                className="btn btn-primary"
                                onClick={this.BackToMenu}
                            >Back</button>
                        </div>
                    </div> */}

                {/* <div className="row" id="top">
                    <div className="col" align="center"> */}

                {/* <table cellPadding="0" style={{ marginTop: 0, border: 1, }} border="0">
                    <tbody>
                        <tr>
                            <td align="center"> */}

                <div className="row" id="top">
                    <div className="col" align="center">

                        <div className='row' align='left'>
                            {this.EventButtonUiComponent()}
                        </div>

                        {
                            //#region old manual assigned buttons.
                            /* 
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.ikeyteam_2021_beta_test_event ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.ikeyteam_2021_beta_test_event
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >iKEY<br />Beta Test<br />Event</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.spot_2020_sim_1209_1211 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.spot_2020_sim_1209_1211
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SIM<br />SPOT 2020<br />2020 (Dec 9~11)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.abacus_challenge_smartnewton_2021_0206 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.abacus_challenge_smartnewton_2021_0206
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SMART NEWTON<br />Abacus Challenge<br />2021 (Feb 6)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.colin_bm_2021_0130 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.colin_bm_2021_0130
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >Colin's BM<br />Secondary BM<br />2021 (Jan 30)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.ptsmc_primary_test_2021_0306_0307 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.ptsmc_primary_test_2021_0306_0307
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >佳学教育<br />全马线上考试<br />2021 (Mar 6~7)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.spot_2021_sim_0417_1st_sem ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.spot_2021_sim_0417_1st_sem
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SIM<br />SPOT 2021<br />2021 (Apr 17)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.sim_membership_2021_h1_final_rev ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.sim_membership_2021_h1_final_rev
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SIM<br />Membership 2021<br />(Jul 15 ~ Oct 15)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.mega_2021_july_assessment_rev ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.mega_2021_july_assessment_rev
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >MEGA<br />July Assessment<br />(Jul 24 ~ 25)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.sjk_spot_2021_2nd_quarter ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.sjk_spot_2021_2nd_quarter
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SIM<br />SJKC SPOT 2021<br />(Aug 28)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.yct_hypy_2021_1 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.yct_hypy_2021_1
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >YCT<br />【国际汉语】<br />(Sep 04)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.sk_spot_2021_1 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.sk_spot_2021_1
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SIM<br />SK&SJKT SPOT 2021<br />(Sep 11)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.little_oppy_spot_2021_1 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.little_oppy_spot_2021_1
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >Little Oppy<br />Preschool SPOT 2021<br />(Oct 16)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.sjkc_spot_2021_final ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.sjkc_spot_2021_final
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SIM<br />SJKC SPOT 2021 Final<br />(Nov 13)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     
                            <button
                                className={"btn btn-" + (this.state.SelectedEventCode === TargetList.sim_spot_ai_2021_essay_1 ? "danger" : "primary")}
                                disabled={this.state.isTargetListLoading}
                                onClick={() => this.setState({
                                    SelectedEventCode: TargetList.sim_spot_ai_2021_essay_1
                                }, () => {
                                    this.SetTargetData();
                                })}
                                style={{ borderColor: "white" }}
                            >SIM<br />SPOT AI 2021 ESSAY<br />(Nov 11~13)</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                            
                            */
                            //#endregion
                        }
                    </div>
                </div>

                {/* </td>
                        </tr>

                        <tr>
                            <td align="center"> */}

                <div className="row">
                    <div className="col" align="center">

                        {
                            this.state.IsEventListLoaded ?
                                this.state.SelectedEventCode === TargetList.None ?
                                    <div style={{ width: "100%", textAlign: "center", marginTop: 15 }}>
                                        Select an item to load.
                                    </div>
                                    :
                                    null
                                : <ProgressBar animated now={100} />
                        }

                        {
                            // this.state.SelectedEventCode === TargetList.abacus_challenge_smartnewton_2021_0206
                            //     || this.state.SelectedEventCode === TargetList.colin_bm_2021_0130
                            //     || this.state.SelectedEventCode === TargetList.ptsmc_primary_test_2021_0306_0307
                            //     || this.state.SelectedEventCode === TargetList.spot_2021_sim_0417_1st_sem
                            //     || this.state.SelectedEventCode === TargetList.ikeyteam_2021_beta_test_event
                            this.state.SelectedEventCode !== TargetList.None
                                ?
                                // <div style={{ width: "100%", textAlign: "center", }}>
                                // {
                                this.state.isTargetListLoading ?
                                    <div style={{ margin: 0, marginTop: 15, }}>
                                        <ProgressBar animated now={100} />
                                        <br />
                                        {/* <p className="blink">{this.state.progressText}</p> */}
                                        <p>{this.state.progressText}</p>
                                    </div>
                                    :
                                    !this.state.isTargetListLoaded ?
                                        <>
                                            <p>Empty Legacy Data.</p>
                                            <button
                                                className="btn btn-primary"
                                                style={{ borderColor: "white" }}
                                                // disabled
                                                onClick={() => {
                                                    this.fetchNewTargetListData();
                                                }}
                                            >Load Data</button>
                                        </>
                                        :
                                        <div style={{ width: '100%' }}>
                                            <hr id='OrganizerTitle' />
                                            <span style={{
                                                fontSize: 24, fontWeight: 'bold',
                                                // margin: 0, marginTop: 15, marginBottom: 25,
                                            }}>{this.state.targetCenter.toUpperCase()}</span>
                                            <br />{this.state.SelectedEventCode}<br /><br />

                                            <div className="row">
                                                <div className="col">
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ borderColor: "white", width: 275, }}
                                                        // disabled
                                                        onClick={() => {
                                                            // alert(this.getNameOfReport());
                                                            this.PrintDiv();
                                                        }}
                                                    >PRINT REPORT</button>

                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                                                    {/* <button
                                                                        className="btn btn-primary"
                                                                        style={{ borderColor: "white" }}
                                                                        // disabled
                                                                        onClick={() => {
                                                                            alert(this.getNameOfReport());
                                                                        }}
                                                                    >DOWNLOAD REPORT AS EXCEL</button> */}
                                                    <ReactHTMLTableToExcel
                                                        className="buttonStyleDownloadExcel-event"
                                                        style={{ borderColor: "white", }}
                                                        table="Event-Joined-List-Table"
                                                        filename={this.getNameOfReport()}
                                                        sheet="Sheet"
                                                        buttonText="DOWNLOAD REPORT AS EXCEL"
                                                    />

                                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ borderColor: "white" }}
                                                        // disabled
                                                        onClick={() => {
                                                            this.fetchNewTargetListData();
                                                        }}
                                                        // disabled={moment() > moment(this.state.lastUpdate).add(1, 'month')}
                                                        disabled={this.props.isDevMode ? false : moment() > moment(this.state.lastUpdate).add(1, 'month')}
                                                    >Refresh Data (Last Update: <span className="blink">{this.state.lastUpdate}</span>)</button>
                                                    {/* <OverlayTrigger overlay={<Tooltip><div dangerouslySetInnerHTML={{
                                                        __html:
                                                            this.state.selectedEvent !== null ?
                                                                this.state.selectedEvent.hasOwnProperty('RefreshStartTime') ?
                                                                    '<table><tbody><tr><td>Refresh Start Time</td><td>:</td><td>' + String(this.state.selectedEvent.RefreshStartTime)
                                                                    + '</td></tr><tr><td>Refresh End Time</td><td>:</td><td>' + String(this.state.selectedEvent.RefreshEndTime)
                                                                    + '</td></tr><tr><td>Refresh Time Taken</td><td>:</td><td>' + String(this.state.selectedEvent.RefreshTimeTaken)
                                                                    + '</td></tr></tbody></table>'
                                                                    : ''
                                                                : ''

                                                    }}></div></Tooltip>}>
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{ borderColor: "white" }}
                                                            // disabled
                                                            onClick={() => {
                                                                this.fetchNewTargetListData();
                                                            }}
                                                            disabled={moment() > moment(this.state.lastUpdate).add(1, 'month')}
                                                        >Refresh Data (Last Update: <span className="blink">{this.state.lastUpdate}</span>)</button>
                                                    </OverlayTrigger> */}
                                                </div>
                                            </div >

                                            {
                                                this.state.refreshStartTime !== '-' ?
                                                    <>
                                                        <div className="row">
                                                            <div className="col">

                                                                <table width={600} cellPadding={10} border={1} style={{ border: '1px solid white', marginTop: 50, }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Refresh Start Time</td>
                                                                            <td>{this.state.refreshStartTime}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Refresh End Time</td>
                                                                            <td>{this.state.refreshEndTime}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Refresh Time Taken</td>
                                                                            <td>{this.state.refreshTimeTaken}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                    : <hr />
                                            }

                                            <div className="row">
                                                <div className="col">

                                                    {/* SUMMARY */}
                                                    <Accordion defaultActiveKey='summary'>
                                                        <Card style={{ border: '3px solid lavender', paddingBottom: 0, }}>
                                                            <Accordion.Toggle
                                                                as={Card.Header}
                                                                key='acc_summary'
                                                                eventKey='summary'
                                                                style={{
                                                                    backgroundColor: '#007bff',
                                                                    cursor: 'pointer',
                                                                    color: 'white',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >Summary</Accordion.Toggle>
                                                            <Accordion.Collapse eventKey='summary' key='acc_toggle_summary'>
                                                                <table width="550" cellPadding="5" border="0" className='table' style={{ border: '3px solid lightgray', marginBottom: 0, }}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>(Summary) Total Participants</td><td>{this.state.tableData_Shared.TotalParticipants}</td>
                                                                        </tr>
                                                                        {/* <tr><td colSpan="2" style={{ backgroundColor: "white" }}></td></tr> */}
                                                                        <tr>
                                                                            <td>Total New Registered Students ({this.state.targetCenter})</td><td width='75'>{this.state.tableData_Shared.TotalRegistered}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Existing Students (Joined iKEY previously)</td><td>{this.state.tableData_Shared.TotalExistingStudentJoined}</td>
                                                                        </tr>
                                                                        {/* <tr><td colSpan="2" style={{ backgroundColor: "white" }}></td></tr> */}
                                                                        <tr>
                                                                            <td>Total Students joined in this Event</td><td>{this.state.tableData_Shared.TotalJoined}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Total Registered Students but not yet join</td><td>{this.state.tableData_Shared.TotalRegisteredButNotJoined}</td>
                                                                        </tr>
                                                                        {/* <tr><td colSpan="2" style={{ backgroundColor: "white" }}></td></tr> */}
                                                                        <tr>
                                                                            <td>New Registered Students (Registered in this Event)</td><td>{this.state.tableData_Shared.TotalJoined - this.state.tableData_Shared.TotalExistingStudentJoined}</td>
                                                                        </tr>
                                                                        {/* <tr><td colSpan="2" style={{ backgroundColor: "white" }}></td></tr> */}
                                                                        <tr>
                                                                            <td>Organizer Students (YES)</td><td>{this.state.tableData_Shared.hasOwnProperty('IsSimStudentYES') ? this.state.tableData_Shared.IsSimStudentYES : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Organizer Students (NO)</td><td>{this.state.tableData_Shared.hasOwnProperty('IsSimStudentNO') ? this.state.tableData_Shared.IsSimStudentNO : '-'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Organizer Students (-)</td><td>{this.state.tableData_Shared.hasOwnProperty('IsSimStudentNULL') ? this.state.tableData_Shared.IsSimStudentNULL : '-'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                    {/* COUNTRY STATES */}
                                                    <Accordion defaultActiveKey='0'>
                                                        <Card style={{ border: '3px solid lavender', paddingBottom: 0, }}>
                                                            <Accordion.Toggle
                                                                as={Card.Header}
                                                                key='acc_country_states'
                                                                eventKey='country_states'
                                                                style={{
                                                                    backgroundColor: '#007bff',
                                                                    cursor: 'pointer',
                                                                    color: 'white',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >Country States</Accordion.Toggle>
                                                            <Accordion.Collapse eventKey='country_states' key='acc_toggle_country_states'>
                                                                <table width="550" cellPadding="5" border="0" className='table' style={{ border: '3px solid lightgray', marginBottom: 0, }}>
                                                                    <tbody>
                                                                        {
                                                                            this.state.tableData_Shared.hasOwnProperty('StateCounters') && this.state.tableData_Shared.StateCounters.length > 0 ?
                                                                                this.state.tableData_Shared.StateCounters.map((data, key) => {
                                                                                    return (<tr><td>{data.Name}</td><td width='75'>{data.Qty}</td></tr>);
                                                                                })
                                                                                : <tr><td colSpan={2} align='center'>no data (refresh or load required)</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                    {/* SHORTCUTS */}
                                                    <Accordion defaultActiveKey='shortcuts'>
                                                        <Card style={{ border: '3px solid lavender', paddingBottom: 0, }}>
                                                            <Accordion.Toggle
                                                                as={Card.Header}
                                                                key='acc_shortcuts'
                                                                eventKey='shortcuts'
                                                                style={{
                                                                    backgroundColor: '#007bff',
                                                                    cursor: 'pointer',
                                                                    color: 'white',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >Shortcuts</Accordion.Toggle>
                                                            <Accordion.Collapse eventKey='shortcuts' key='acc_toggle_shortcuts'>
                                                                <table width="550" cellPadding="5" border="0" className='table' style={{ border: '3px solid lightgray', marginBottom: 0, }}>
                                                                    <tbody>
                                                                        <tr><td><a href="#TotalJoinedStudents">Jump to table: <u>Total Joined Students</u></a></td></tr>
                                                                        <tr><td><a href="#OtherRegisteredStudents">Jump to table: <u>Other Registered Students</u></a></td></tr>
                                                                    </tbody>
                                                                </table>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                </div>
                                                <div className="col">

                                                    {/* GROUPS */}
                                                    <Accordion defaultActiveKey='0'>
                                                        <Card style={{ border: '3px solid lavender', paddingBottom: 0, }}>
                                                            <Accordion.Toggle
                                                                as={Card.Header}
                                                                key='acc_groups'
                                                                eventKey='groups'
                                                                style={{
                                                                    backgroundColor: '#007bff',
                                                                    cursor: 'pointer',
                                                                    color: 'white',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >Groups</Accordion.Toggle>
                                                            <Accordion.Collapse eventKey='groups' key='acc_toggle_groups'>
                                                                <table width="550" cellPadding="5" border="0" className='table' style={{ border: '3px solid lightgray', marginBottom: 0, }}>
                                                                    <tbody>
                                                                        {
                                                                            this.state.tableData_Shared.hasOwnProperty('GroupCounters') && this.state.tableData_Shared.GroupCounters.length > 0 ?
                                                                                this.state.tableData_Shared.GroupCounters.map((data, key) => {
                                                                                    return (<tr><td>{data.Name}</td><td width='75'>{data.Qty}</td></tr>);
                                                                                })
                                                                                : <tr><td colSpan={2} align='center'>no data (refresh or load required)</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                    {/* GRADES */}
                                                    <Accordion defaultActiveKey='0'>
                                                        <Card style={{ border: '3px solid lavender', paddingBottom: 0, }}>
                                                            <Accordion.Toggle
                                                                as={Card.Header}
                                                                key='acc_grades'
                                                                eventKey='grades'
                                                                style={{
                                                                    backgroundColor: '#007bff',
                                                                    cursor: 'pointer',
                                                                    color: 'white',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >Grades</Accordion.Toggle>
                                                            <Accordion.Collapse eventKey='grades' key='acc_toggle_grades'>
                                                                <table width="550" cellPadding="5" border="0" className='table' style={{ border: '3px solid lightgray', marginBottom: 0, }}>
                                                                    <tbody>
                                                                        {
                                                                            this.state.tableData_Shared.hasOwnProperty('GradeCounters') && this.state.tableData_Shared.GradeCounters.length > 0 ?
                                                                                this.state.tableData_Shared.GradeCounters.map((data, key) => {
                                                                                    return (<tr><td>{data.Name}</td><td width='75'>{data.Qty}</td></tr>);
                                                                                })
                                                                                : <tr><td colSpan={2} align='center'>no data (refresh or load required)</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                    {/* SCHOOLS */}
                                                    <Accordion defaultActiveKey='0'>
                                                        <Card style={{ border: '3px solid lavender', paddingBottom: 0, }}>
                                                            <Accordion.Toggle
                                                                as={Card.Header}
                                                                key='acc_schools'
                                                                eventKey='schools'
                                                                style={{
                                                                    backgroundColor: '#007bff',
                                                                    cursor: 'pointer',
                                                                    color: 'white',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >Schools</Accordion.Toggle>
                                                            <Accordion.Collapse eventKey='schools' key='acc_toggle_schools'>
                                                                <table width="550" cellPadding="5" border="0" className='table' style={{ border: '3px solid lightgray', marginBottom: 0, }}>
                                                                    <tbody>
                                                                        {
                                                                            this.state.tableData_Shared.hasOwnProperty('SchoolCounters') && this.state.tableData_Shared.SchoolCounters.length > 0 ?
                                                                                this.state.tableData_Shared.SchoolCounters.map((data, key) => {
                                                                                    return (<tr><td>{data.Name}</td><td width='75'>{data.Qty}</td></tr>);
                                                                                })
                                                                                : <tr><td colSpan={2} align='center'>no data (refresh or load required)</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>

                                                </div>
                                            </div>


                                        </div >
                                // }
                                // </div>
                                : null
                        }
                    </div>
                </div >

                <div className='row'>
                    <div className='col' align='center'>
                        {
                            this.state.isTargetListLoaded ?
                                <>
                                    <hr />

                                    <p style={{ fontSize: 20 }} id="TotalJoinedStudents">Total Joined Students <a href="#top"><u>top</u></a></p>
                                    <table width="100%" cellPadding="5" border="1" bordercolor="lightgray" style={{ backgroundColor: 'white', border: '3px solid lightgray', }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/* <th>
                                <div className="row">
                                    <div className="col">Name</div>
                                    <div className="col text-right">
                                        <button className="btn btn-sort" onClick={() => this.tbJoined_sortBy(SortBy.Name, true)}
                                            style={this.state.sortSelected === SortBy.Name && this.state.sortAscDesc ? { backgroundColor: "#3750e0", color: "#fff" } : {}}
                                        >↓</button>&nbsp;
                                        <button className="btn btn-sort" onClick={() => this.tbJoined_sortBy(SortBy.Name, false)}
                                            style={this.state.sortSelected === SortBy.Name && !this.state.sortAscDesc ? { backgroundColor: "#3750e0", color: "#fff" } : {}}
                                        >↑</button>
                                    </div>
                                </div>
                            </th> */}
                                                {this.getTableHeader("Name", SortBy.Name, TargetTable.Joined)}
                                                {this.getTableHeader("Grade", SortBy.Grade, TargetTable.Joined)}
                                                {this.getTableHeader("Group", SortBy.Group, TargetTable.Joined)}
                                                {this.getTableHeader("Guardian", SortBy.Guardian, TargetTable.Joined)}
                                                {this.getTableHeader("Contact", SortBy.Contact, TargetTable.Joined)}
                                                {this.getTableHeader("Email", SortBy.Email, TargetTable.Joined)}
                                                {this.getTableHeader("State", SortBy.State, TargetTable.Joined)}
                                                {this.getTableHeader("School", SortBy.School, TargetTable.Joined)}
                                                {this.getTableHeader("Student of<br />Organizer", SortBy.Center, TargetTable.Joined)}
                                                {this.getTableHeader("New<br />Registered", SortBy.NewRegistered, TargetTable.Joined)}

                                                {/* 2021.03.02 */}
                                                {this.getTableHeader("Date of<br />Registered", SortBy.RegisteredDate, TargetTable.Joined)}
                                                {this.getTableHeader("Date of<br />Joined", SortBy.JoinedDate, TargetTable.Joined)}

                                                {/* <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.Name, true)}>Name</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.Grade, true)}>Grade</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.Group, true)}>Group</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.Guardian, true)}>Guardian</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.Email, true)}>Email</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.State, true)}>State</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.School, true)}>School</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.Center, true)}>Student of<br />{this.state.targetCenter}</a></th>
                            <th><a href="javascript:void(0);" onClick={() => this.tbJoined_sortBy(SortBy.NewRegistered, true)}>New<br />Registered</a></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.tableData_Joined.length > 0 ?
                                                    this.state.tableData_Joined.map((data, key) => {
                                                        return (
                                                            <tr>
                                                                <td>{key + 1}</td>
                                                                <td>{data.Name}</td>
                                                                <td>{data.Grade}</td>
                                                                <td>{data.Group}</td>
                                                                <td>{data.Guardian}</td>
                                                                <td>{data.Contact}</td>
                                                                <td>{data.Email}</td>
                                                                <td>{data.NationalState}</td>
                                                                <td>{data.School}</td>
                                                                {/* <td>{data.IsCenterStudent}</td> */}
                                                                <td><div dangerouslySetInnerHTML={{ __html: String(data.IsCenterStudent).toLowerCase() === 'yes' ? data.IsCenterStudent : "<span style='color:gray'>" + data.IsCenterStudent + "</span>" }}></div></td>
                                                                <td><div dangerouslySetInnerHTML={{ __html: data.NewRegistered ? 'Yes' : "<span style='color:gray'>No</span>" }}></div></td>
                                                                <td>{data.RegisteredDate}</td>
                                                                <td>{data.JoinedDate}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    this.state.tableData_Shared.TotalParticipants > 2000 ?
                                                        this.state.joined_list_loaded ?
                                                            this.state.tableData_Joined.length > 0 ? null
                                                                : <tr><td colSpan="13" align="center">list is empty.</td></tr>
                                                            :
                                                            this.state.loading_joined_list ?
                                                                <tr><td colSpan="13" align="center"><ProgressBar animated now={100} /></td></tr>
                                                                :
                                                                <tr>
                                                                    <td colSpan="13" align="center">
                                                                        Quantity of joined participants is over 2000 (<span style={{ color: 'blue' }}>{this.state.tableData_Shared.TotalJoined}</span>),<br />
                                                                        list is not loaded defaultly to save quota.<br />
                                                                        {
                                                                            this.state.isTargetListLoaded ?
                                                                                <div style={{ margin: 15, }}>
                                                                                    <button
                                                                                        className="btn btn-primary"
                                                                                        onClick={this.fetchParticipants_Joined}
                                                                                        disabled={!this.state.IsButtonEnabled}
                                                                                    >load list</button>
                                                                                </div>
                                                                                : <div style={{ margin: 15, }}><ProgressBar animated now={100} /></div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                        : <tr><td colSpan="13" align="center">list is empty.</td></tr>
                                            }
                                        </tbody>
                                    </table>

                                    <br />
                                    <p style={{ fontSize: 20 }} id="OtherRegisteredStudents">Other Registered Students (Students that are still haven't join the current event) <a href="#top"><u>top</u></a></p>
                                    <table width="100%" cellPadding="5" border="1" style={{ backgroundColor: 'white', border: '3px solid lightgray', }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/* <th>Name</th>
                            <th>Grade</th>
                            <th>Group</th>
                            <th>Guardian</th>
                            <th>Email</th>
                            <th>State</th>
                            <th>School</th>
                            <th>Student of<br />{this.state.targetCenter}</th> */}
                                                {/* <th>New<br />Registered</th> */}

                                                {this.getTableHeader("Name", SortBy.Name, TargetTable.Other)}
                                                {this.getTableHeader("Grade", SortBy.Grade, TargetTable.Other)}
                                                {this.getTableHeader("Group", SortBy.Group, TargetTable.Other)}
                                                {this.getTableHeader("Guardian", SortBy.Guardian, TargetTable.Other)}
                                                {this.getTableHeader("Contact", SortBy.Contact, TargetTable.Other)}
                                                {this.getTableHeader("Email", SortBy.Email, TargetTable.Other)}
                                                {this.getTableHeader("State", SortBy.State, TargetTable.Other)}
                                                {this.getTableHeader("School", SortBy.School, TargetTable.Other)}
                                                {this.getTableHeader("Student of<br />Organizer", SortBy.Center, TargetTable.Other)}
                                                {this.getTableHeader("Date of<br />Registered", SortBy.RegisteredDate, TargetTable.Other)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.tableData_Other.length > 0 ?
                                                    this.state.tableData_Other.map((data, key) => {
                                                        return (
                                                            <tr>
                                                                <td>{key + 1}</td>
                                                                <td>{data.Name}</td>
                                                                <td>{data.Grade}</td>
                                                                <td>{data.Group}</td>
                                                                <td>{data.Guardian}</td>
                                                                <td>{data.Contact}</td>
                                                                <td>{data.Email}</td>
                                                                <td>{data.NationalState}</td>
                                                                <td>{data.School}</td>
                                                                {/* <td>{data.IsCenterStudent}</td> */}
                                                                {/* <td>{data.NewRegistered ? '<b>Yes</b>' : 'No'}</td> */}
                                                                <td><div dangerouslySetInnerHTML={{ __html: String(data.IsCenterStudent).toLowerCase() === 'yes' ? data.IsCenterStudent : "<span style='color:gray'>" + data.IsCenterStudent + "</span>" }}></div></td>
                                                                <td>{data.RegisteredDate}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    this.state.tableData_Shared.TotalRegisteredButNotJoined > 2000 ?
                                                        this.state.other_list_loaded ?
                                                            this.state.tableData_Other.length > 0 ? null
                                                                : <tr><td colSpan="13" align="center">list is empty.</td></tr>
                                                            :
                                                            this.state.loading_other_list ?
                                                                <tr><td colSpan="13" align="center"><ProgressBar animated now={100} /></td></tr>
                                                                :
                                                                <tr>
                                                                    <td colSpan="11" align="center">
                                                                        Quantity of other participants is over 2000 (<span style={{ color: 'blue' }}>{this.state.tableData_Shared.TotalRegisteredButNotJoined}</span>),<br />
                                                                        list is not loaded defaultly to save quota.<br />
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={this.fetchParticipants_Other}
                                                                        >load list</button>
                                                                    </td>
                                                                </tr>
                                                        : <tr><td colSpan="11" align="center">list is empty.</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </>
                                : null
                        }
                    </div>
                </div>


                {/* </td>
                        </tr>
                    </tbody>
                </table> */}

                {/* </div>
                </div> */}

                {/* <div className="row" style={{ paddingTop: 50 }}>
                    <div className="col" align="center"> */}
                {/* hidden table<br /> */}
                <div
                    id='Event-Joined-List-Table_hidden'
                    hidden={true}
                    dangerouslySetInnerHTML={{ __html: this.state.Hidden_Event_Joined_List_Table }}
                // dangerouslySetInnerHTML={{ __html: Hidden_Event_Joined_List_Table }}
                />
                {/* </div>
                </div> */}



                {/* <div className="row" style={{ height: 200 }}>
                    <div className="col" align="center">
                        <span>&nbsp;</span>
                    </div>
                </div> */}

                {/* </div> */}
            </>
        )
    }
}