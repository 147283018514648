import { PDFDocument, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'
import React from 'react';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Spinner, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import {
//     // osVersion, osName, browserName, fullBrowserVersion
//     browserName
// } from "react-device-detect";

import { Locale, Lang } from '../../src/Localization/CustomLocalization.js';
import LoadingIndicator from '../../src/Screens/Quiz/LoadingIndicator.js';
import { Accordion, Card, } from 'react-bootstrap';

// import Icon_Cert_Hat from '../../src/Screens/Quiz/images/icon_cert_hat_trimmed.png';
import PE_Banner_Count from '../../src/Screens/Quiz/images/Participated_Events_Banner_EventCount.png';
import LocalizationSwitcher from '../Localization/LocalizationSwitcher.js';
import firebase from 'firebase';
import { GlobalSetting } from '../components/GlobalSetting';
import { CheckBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay } from '../components/GlobalFunctions';      //2023.09.11
// import NotoSansBold from '../fonts/NotoSans-Bold.ttf';

let EventTriggerDownloadPDF_evt = null;
let downloadPdfGuidUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/How-to-download-e-cert-for-ikey-parent-users-latest.pdf';

export default class ParticipatedEventList extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',

        //2021.03.29
        Participated_Event_List: [],
        PEList_isLoading: false,
        PEList_isLoaded: false,
        PEList_More_isLoading: false,
        PEList_More_isLoaded: false,

        //2021.03.30
        PEList_lastVisible: null,
        EventDetails: [{ EventCode: '' }],

        //2021.03.31
        // PEList_Item_isLoaded: [],
        // PEList_Item_isLoading: [],
        PEList_QuizRoomResult: [],
        PEList_QuizRoomResult_isLoading: [],

        //2021.04.07
        Participated_Event_List_Toggle: [],

        //2021.04.13
        Participated_Event_Counter: 0,

        //2021.04.14
        AllowDownloadPdfOnWeb: true,

        //2021.04.23
        Base64PdfUri: null,

        //2021.09.03
        ShowPopupBlockerStepsModal: false,
        ShowPopupErrorModal: false,
    });

    componentDidMount = async () => {
        if (this.props.user !== null) {
            await this.Load_ParticipatedEventList();
        }
        else {
            //Back to Login.
            this.Goto('/');
        }

        //2021.04.23
        EventTriggerDownloadPDF_evt = window.addEventListener('message', (event) => {
            if (
                event.origin !== 'http://localhost:3000'
                || event.origin !== 'http://localhost:3001'
                || event.origin !== 'https://livequiz.ikeyedutech.com.my'
            ) {
                if (event.data === 'EventTriggerDownloadPDF') {
                    this.EventTriggerDownloadPDF();
                }
            }
        }, false);
    }

    componentWillUnmount = async () => {
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        //2021.04.23
        window.removeEventListener('message', EventTriggerDownloadPDF_evt);
    }

    Goto = (loc) => {
        this.setState({
            redirect: true,
            redirectLink: loc,
        });
    }

    //#region not using atm.
    // //2021.12.01
    // CheckOn_Participant_CertInfo = async (_eventCode = '', _event_idx = -1) => {
    //     let _List = null;
    //     await this.props.firestore
    //         .collection("LiveQuiz_Certifications")
    //         .doc(String(this.props.user.uid))
    //         .collection('Certificates')
    //         .where('EventCode', '==', _eventCode)
    //         .orderBy('CreatedOnUtc', 'asc')
    //         .limit(1)
    //         .get()
    //         .then(querySnapshot => {
    //             let dataArray = [];
    //             if (querySnapshot !== null) {
    //                 querySnapshot.forEach((doc) => {
    //                     dataArray.push(doc.data());
    //                 });
    //                 if (dataArray.length > 0) {
    //                     // dataArray.map((data, key) => {
    //                     //     return _List.push(data);
    //                     // });
    //                     _List = dataArray[0];
    //                 }
    //                 // console.log('(0) OrganizerInfo =\n' + JSON.stringify(_List));
    //             }
    //         })
    //         .catch(error => {
    //             if (this.props.isDevMode)
    //                 console.log(error.message);
    //         });
    //     //Sort.
    //     // _List.sort((a, b) => a.Id - b.Id);
    //     if (this.props.isDevMode)
    //         console.log('\nParticipant CertInfo =\n' + JSON.stringify(_List));
    //     // //Save.
    //     // this.setState({
    //     //     OrganizerInfo: _List,
    //     // });
    //     if (_List !== null) {
    //         if (_event_idx > -1) {
    //             let temp = this.state.Participated_Event_List;
    //             let certSN = _List.hasOwnProperty('CertSN') ? String(_List.CertSN) : '';
    //             temp[_event_idx].CertSN = certSN;
    //             this.setState({
    //                 Participated_Event_List: temp,
    //             });
    //             return certSN;
    //         }
    //     }
    //     return null;
    // }
    //#endregion

    //2024.11.06 - updated to new api V3.
    //2021.11.18 - Api to generate Serial Number upon download if CertSN is null or empty string.
    TriggerGenerateCertSNViaApi = async (event, idx) => {
        this.props.SetLoading('e-Certificate of Participation', 'Generating Serial Number...', true);

        let serialNumber = '';
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Event/Certificate/Generate/V3/'
            + CheckObjectNumber(event, 'EventId') + '/'
            + CheckObjectStringEmpty(event, 'EventCode', '-') + '/'
            + String(this.props.user.uid),
            // + String(this.state.Participated_Event_List[idx].Id),
            // Api/LearningCentre/Event/Certificate/Generate/V2/{eventcode}/{uid}/{timestampid}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('res json =\n' + JSON.stringify(data));
                if (data.success) {
                    if (data.hasOwnProperty('data'))
                        if (data.data !== undefined)
                            serialNumber = String(data.data);
                }
                else {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - generate cert sn (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - generate cert sn (failed)\n' + error.message);
            });
        if (serialNumber.length > 0) {
            let temp = this.state.Participated_Event_List;
            temp[idx].CertSN = serialNumber;
            this.setState({
                Participated_Event_List: temp,
            }, () => {
                return true;
            });
        }
        else {
            return false;
        }
    }

    //2021.03.29
    DownloadCertPDF = async (idx) => {

        //2021.11.18 - revamped.
        //2021.11.13
        let currentParticipatedEvent = this.state.Participated_Event_List[idx];
        let event = this.state.EventDetails.find(x => x.EventCode === currentParticipatedEvent.EventCode);
        if (event.hasOwnProperty('CertLayoutTypeId')) {
            if (event.hasOwnProperty('CertDownloadDelayed') === false)
                event.CertDownloadDelayed = false;

            // event.CertDownloadDelayed = false;  //testing only.

            //2022.06.24
            let _isDelayed = event.CertDownloadDelayed ? true : false;
            if (event.hasOwnProperty('CertDownloadDelayedEndDateTime')) {
                const dlDelayEndDT = moment(String(event.CertDownloadDelayedEndDateTime), 'YYYY-MM-DD HH:mm:ss', true);
                if (dlDelayEndDT.isValid()) {
                    if (moment() > dlDelayEndDT) {
                        _isDelayed = false;
                    }
                    else {
                        _isDelayed = true;
                    }
                }
            }
            // _isDelayed = false;     //testing.

            //2022.06.24
            let proceedToGenerateCertSN = false;
            if (
                (event.CertLayoutTypeId === 2 || event.CertLayoutTypeId === 3)
                &&
                (_isDelayed === false)
            ) {
                proceedToGenerateCertSN = true;
            }

            //2022.06.24
            if (event.CertLayoutTypeId === 1 && _isDelayed) {
                this.props.SetAlert('e-Cert is not available to download at the moment, please try again at later time.');
                return;
            }

            //proceed.
            if (
                // (event.CertLayoutTypeId === 2 || event.CertLayoutTypeId === 3)
                // && event.CertDownloadDelayed === false
                proceedToGenerateCertSN     //2022.06.24
            ) {
                //2021.12.01
                //Check on Cert Info.
                // let certSN = await this.CheckOn_Participant_CertInfo(String(currentParticipatedEvent.EventCode), idx);
                let certSN = currentParticipatedEvent.hasOwnProperty('CertSN') === false ?
                    null
                    : String(currentParticipatedEvent.CertSN) === '' ?
                        null
                        : String(currentParticipatedEvent.CertSN);
                if (certSN === null) {
                    var result = await this.TriggerGenerateCertSNViaApi(event, idx);

                    //Jay added 2021.12.06
                    if (result === false) {
                        var maxRetries = 5;
                        var currRetries = 1;
                        do {
                            if (currRetries > maxRetries) {
                                this.props.SetAlert('Incomplete Operation', 'System is unable to generate Serial Number for your e-Cert currently.<br />Please try again at a later time.');
                                return null;
                            }
                            else {
                                currRetries++;
                                this.props.SetLoading('System Busy', 'System is quite busy right now, retrying in 5 seconds.', true);
                                await Delay(5000);
                                result = await this.TriggerGenerateCertSNViaApi(event, idx);
                            }
                        } while (result === false);
                    }
                }
                currentParticipatedEvent = this.state.Participated_Event_List[idx];     //refresh.
            }
        }
        else {
            event.CertLayoutTypeId = 1;
        }

        //2021.04.12
        if (this.props.isFromParentApp === false && this.state.AllowDownloadPdfOnWeb === false) {
            let notice = Locale("notice-pe-download-pdf", this.props.Locale);
            notice = String(notice).replace('<a>',
                '<button class="btn-link" onClick={window.open("' + downloadPdfGuidUrl + '")}><b>');
            notice = String(notice).replace('</a>', '</b></button>');

            this.props.SetAlert(
                Locale("label-pe-feature", this.props.Locale),
                notice,
                false
            );
            return null;
        }

        //show processing notice.
        this.props.SetLoading('e-Certificate of Participation', 'Processing PDF file...', true);

        //Create Pdf object.
        let pdfDoc = await PDFDocument.create();

        //Add a blank page to the document.
        let page = pdfDoc.addPage();

        // let bgUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_spot_2021_sem1.jpg';
        // let bgUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/test-cert-sample.jpg';
        let defaultCertImgUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/CERT/cert_spot_2021_q1.jpg';  //final

        //Set Student Name.
        // let studentName = String(this.state.Participated_Event_List[idx].Participant);
        //2021.04.07 - follow Profile name.
        let studentName = String(this.props.profile.Name).toUpperCase().trim();
        let studentNameForFile = studentName.trim().replace(new RegExp(' ', 'g'), '-');

        //get event details.
        // let event = this.state.EventDetails.find(x => x.EventCode === this.state.Participated_Event_List[idx].EventCode);
        // let event = this.state.EventDetails.find(x => x.EventCode === currentParticipatedEvent.EventCode);   //2021.11.18 - moved to top.
        let eventName = String(event.EventName).replace(new RegExp(' ', 'g'), '-');
        let eventDate = event.DateStart === event.DateEnd ?
            moment(event.DateStart + ' 00:00:00').format('YYYY_MMDD')
            : moment(event.DateStart + ' 00:00:00').format('YYYY')
            + '_' + moment(event.DateStart + ' 00:00:00').format('MMDD')
            + '-' + moment(event.DateEnd + ' 00:00:00').format('MMDD');

        //assign cert bg image url.
        // let bgUrl = event.hasOwnProperty('CertImgUrl') ? event.CertImgUrl : defaultCertImgUrl;

        //2021.07.05
        let bgUrl = defaultCertImgUrl;
        if (event.hasOwnProperty('CertImgUrl')) {
            if (String(event.CertImgUrl) !== '' || String(event.CertImgUrl).length > 0) {
                bgUrl = String(event.CertImgUrl);
            }
        }
        if (this.props.isDevMode)
            console.log('\nbgUrl = ' + bgUrl);

        //Fetch image file.
        let bgImgBytes = await fetch(bgUrl, {
            method: 'GET',
            // mode: 'no-cors',
            headers: { 'Access-Control-Allow-Origin': false }
        }, true).then(res => res.arrayBuffer()).catch(error => { return null; });
        // console.log('bgImgBytes = ' + bgImgBytes.byteLength + ' | ' + bgImgBytes);

        // console.log(bgImgBytes.byteLength);  //failed = 215.
        // if (bgImgBytes.byteLength < 500) {
        if (bgImgBytes === null || bgImgBytes.byteLength < 500) {
            this.props.SetAlert(
                Locale("label-pdf-download-failed", this.props.Locale),
                Locale("notice-pdf-download-failed", this.props.Locale)
            );
            return null;
        }

        //2021.11.18 - moved to top.
        // //2021.11.08
        // if (event.hasOwnProperty('CertLayoutTypeId') === false) {
        //     event.CertLayoutTypeId = 1;
        // }

        //Add picture.
        // let bgImg = await pdfDoc.embedJpg('data:image/jpeg;base64,' + bgImgBase64);
        let bgImg = await pdfDoc.embedJpg(bgImgBytes);
        if (this.props.isDevMode)
            console.log('\nbgImg = w (' + bgImg.width + ') h (' + bgImg.height + ')');

        let bgImgDims = event.CertLayoutTypeId === 2 || event.CertLayoutTypeId === 3 ? bgImg.scale(0.24) : bgImg.scale(0.25);
        if (this.props.isDevMode)
            console.log('\nbgImgDims = w (' + bgImgDims.width + ') h (' + bgImgDims.height + ')');

        //Set Bg picture.
        page.drawImage(bgImg, {
            x: page.getWidth() / 2 - bgImgDims.width / 2,
            y: page.getHeight() / 2 - bgImgDims.height / 2,
            width: bgImgDims.width,
            height: bgImgDims.height,
        });

        // let regexJapanese = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;

        let regexChinese = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

        let hasChineseCharactors = regexChinese.test(studentName);
        // console.log('hasChineseCharactors = ' + hasChineseCharactors);
        // return null;

        //2023.11.14 - simplified codes, added new type 4 (Student Name, School Name).
        //#region 2023.11.14 - simplified codes.
        let showSchoolName = false;
        let showSerialNumber = false;
        let studentNameOffsetForPosY = 0;
        let studentNameSubOffsetForPosY = 25;
        switch (event.CertLayoutTypeId) {
            //1 = Student Name.
            //2 = Student Name, Serial Number, School Name.
            //3 = Student Name, Serial Number.
            //4 = Student Name, School Name.
            case 1: studentNameSubOffsetForPosY = 0; break;
            case 2: showSchoolName = true; showSerialNumber = true; break;
            case 3: showSerialNumber = true; break;
            case 4: showSchoolName = true; break;
            default: break;
        }
        const studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        //Serial Number.
        if (showSerialNumber) {
            const serialNumberFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
            let serialNumber_fontSize = 14;
            let serialNumber = currentParticipatedEvent.hasOwnProperty('CertSN') ?
                String(currentParticipatedEvent.CertSN).length > 0 ?
                    String(currentParticipatedEvent.CertSN)
                    : '-'
                : '-';
            let offsetX = Number(page.getWidth() / 4) - serialNumber.length - 4;
            page.drawText(serialNumber, {
                x: (page.getWidth() / 2) - (serialNumberFont.widthOfTextAtSize(serialNumber, serialNumber_fontSize) / 2) + offsetX,  //167, //177,
                y: (page.getHeight() / 2) - (serialNumberFont.heightAtSize(serialNumber_fontSize) / 2) - 317,
                size: serialNumber_fontSize,
                font: serialNumberFont,
            });
        }

        //School Name.
        if (showSchoolName) {
            let schoolName = this.props.profile.hasOwnProperty('School') ?
                String(this.props.profile.School).length > 0 ? String(this.props.profile.School) : ''
                : '';
            let schoolName_isExtraLongName = schoolName.length > 69;    //2022.11.04
            let schoolName_isLongName = schoolName.length > 29;
            let schoolName_fontSize = schoolName_isLongName ? 18 : 24;  //smaller size than student name.
            if (schoolName_isLongName) {
                let nameArray = schoolName.split(' ');

                let newNameFormat = '';
                if (schoolName_isExtraLongName) {
                    //2022.11.04
                    // schoolName_fontSize = 14;
                    nameArray.map((data, key) => {
                        const divider = newNameFormat.split('<>').length;
                        const limit = 40 * divider;
                        const predict = String(newNameFormat + data);
                        if (predict.length >= limit)
                            newNameFormat = newNameFormat.trimEnd() + '<>' + data + ' ';
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                }
                else {
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    // let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                }
                schoolName = newNameFormat.trim();
                schoolName.split('<>').map((txt, key) => {
                    return page.drawText(txt, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(txt, schoolName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + (20 - (20 * key)) + 5,
                        size: schoolName_fontSize,
                        font: studentNameFont,
                    });
                });
            }
            else {
                if (schoolName.length === 0) {
                    //2022.05.12 - move student name down abit since school name is missing & extra space appeared.
                    studentNameOffsetForPosY = 22;
                }
                else {
                    page.drawText(schoolName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(schoolName, schoolName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 20,
                        size: schoolName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
        }

        //Student Name.
        if (hasChineseCharactors) {
            //register 'fontkit' instance
            pdfDoc.registerFontkit(fontkit);

            //set custom font.
            let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSansSC-Bold.otf';    //SC support chinese.
            let fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
            let customFont = await pdfDoc.embedFont(fontBytes);

            //Text (1) Student Name.
            let studentName_fontSize = 28;  //charactors width are small even with long name, so fixed at 28.
            page.drawText(studentName, {
                x: (page.getWidth() / 2) - (customFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                y: (page.getHeight() / 2) - (customFont.heightAtSize(studentName_fontSize) / 2) + 50 + 34 - studentNameOffsetForPosY,
                size: studentName_fontSize,
                font: customFont,
            });
        }
        else {
            //2021.04.14
            let isLongName = studentName.length > 29;
            if (isLongName) {
                let nameArray = studentName.split(' ');
                let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                let newNameFormat = '';
                nameArray.map((data, key) => {
                    if (key === nextLineIndex)
                        newNameFormat += data + '<>';
                    else if (key === nameArray.length)
                        newNameFormat += data;
                    else
                        newNameFormat += data + ' ';
                    return null;
                });
                studentName = newNameFormat;
            }
            // let posHeight = isLongName ? 75 : 50;

            // //set font.
            // let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

            //non-chinese-names = alphanumeric charactors = aka English & etc.
            let studentName_fontSize = isLongName ? 22 : 28;

            //draw name.
            //Text (1) Student Name.
            if (isLongName) {
                let firstLine = studentName.split('<>')[0];
                page.drawText(firstLine, {
                    x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + studentNameSubOffsetForPosY + 19 - studentNameOffsetForPosY,
                    size: studentName_fontSize,
                    font: studentNameFont,
                });
                let secondLine = studentName.split('<>')[1];
                page.drawText(secondLine, {
                    x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 19 - studentNameOffsetForPosY,
                    size: studentName_fontSize,
                    font: studentNameFont,
                });
            }
            else {
                page.drawText(studentName, {
                    x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + studentNameSubOffsetForPosY - studentNameOffsetForPosY,
                    size: studentName_fontSize,
                    font: studentNameFont,
                });
            }
        }
        //#endregion

        //#region 2023.11.14 - simplified.
        /*
        //2023.11.14 - new type 4 (Student Name, School Name).
        if (event.CertLayoutTypeId === 4) {
            let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

            //#region === Text (2) School Name.
            let studentNameOffsetForPosY = 0;
            let schoolName = this.props.profile.hasOwnProperty('School') ?
                String(this.props.profile.School).length > 0 ? String(this.props.profile.School) : ''
                : '';
            let schoolName_isExtraLongName = schoolName.length > 69;    //2022.11.04
            let schoolName_isLongName = schoolName.length > 29;
            let schoolName_fontSize = schoolName_isLongName ? 18 : 24;  //smaller size than student name.
            if (schoolName_isLongName) {
                let nameArray = schoolName.split(' ');

                let newNameFormat = '';
                if (schoolName_isExtraLongName) {
                    //2022.11.04
                    // schoolName_fontSize = 14;
                    nameArray.map((data, key) => {
                        const divider = newNameFormat.split('<>').length;
                        const limit = 40 * divider;
                        const predict = String(newNameFormat + data);
                        if (predict.length >= limit)
                            newNameFormat = newNameFormat.trimEnd() + '<>' + data + ' ';
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                }
                else {
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    // let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                }
                schoolName = newNameFormat.trim();
                // if (schoolName.includes(' - ')) {
                //     //2022.11.04
                //     let splits = schoolName.split(' - ');
                //     // newNameFormat = '';
                //     // splits.map((txt, key) => {
                //     //     if (key === splits.length - 1)
                //     //         newNameFormat += ' - ' + txt.replace('<>', ' ');
                //     //     else if (key === 1)
                //     //         newNameFormat += '<>' + txt.replace('<>', ' ');
                //     //     else
                //     //         newNameFormat += txt;
                //     //     return null;
                //     // });
                //     // schoolName = newNameFormat.trim();
                //     let shifted = splits.shift();
                //     schoolName = shifted + '<>' + splits.join(' - ');
                // }
                // console.log('\n school name: \n' + schoolName);
                schoolName.split('<>').map((txt, key) => {
                    return page.drawText(txt, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(txt, schoolName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + (20 - (20 * key)) + 5,
                        size: schoolName_fontSize,
                        font: studentNameFont,
                    });
                });
                // let firstLine = schoolName.split('<>')[0];
                // page.drawText(firstLine, {
                //     x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, schoolName_fontSize) / 2),
                //     y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 20 + 5,
                //     size: schoolName_fontSize,
                //     font: studentNameFont,
                // });
                // let secondLine = schoolName.split('<>')[1];
                // page.drawText(secondLine, {
                //     x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, schoolName_fontSize) / 2),
                //     y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 5,
                //     size: schoolName_fontSize,
                //     font: studentNameFont,
                // });
            }
            else {
                if (schoolName.length === 0) {
                    //2022.05.12 - move student name down abit since school name is missing & extra space appeared.
                    studentNameOffsetForPosY = 22;
                }
                else {
                    page.drawText(schoolName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(schoolName, schoolName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 20,
                        size: schoolName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
            //#endregion

            //#region  Text (1) Student Name.
            if (hasChineseCharactors) {
                //register 'fontkit' instance
                pdfDoc.registerFontkit(fontkit);

                //set custom font.
                let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSansSC-Bold.otf';    //SC support chinese.
                let fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
                let customFont = await pdfDoc.embedFont(fontBytes);

                //Text (1) Student Name.
                let studentName_fontSize = 28;  //charactors width are small even with long name, so fixed at 28.
                page.drawText(studentName, {
                    x: (page.getWidth() / 2) - (customFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (customFont.heightAtSize(studentName_fontSize) / 2) + 50 + 34 - studentNameOffsetForPosY,
                    size: studentName_fontSize,
                    font: customFont,
                });
            }
            else {
                //2021.04.14
                let isLongName = studentName.length > 29;
                if (isLongName) {
                    let nameArray = studentName.split(' ');
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                    studentName = newNameFormat;
                }
                // let posHeight = isLongName ? 75 : 50;

                // //set font.
                // let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

                //non-chinese-names = alphanumeric charactors = aka English & etc.
                let studentName_fontSize = isLongName ? 22 : 28;

                //draw name.
                //Text (1) Student Name.
                if (isLongName) {
                    let firstLine = studentName.split('<>')[0];
                    page.drawText(firstLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 75 + 19 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                    let secondLine = studentName.split('<>')[1];
                    page.drawText(secondLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 19 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
                else {
                    page.drawText(studentName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
            //#endregion
        }
        //2022.06.09 - new type 3 (Student Name, Serial Number).
        else if (event.CertLayoutTypeId === 3) {
            //event.CertLayoutTypeId === 3 ===> Student Name, Serial Number.

            //set font.
            let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            let serialNumberFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            //Text (2) School Name.
            let studentNameOffsetForPosY = 0;

            //#region === Text (1) Student Name.
            if (hasChineseCharactors) {
                //register 'fontkit' instance
                pdfDoc.registerFontkit(fontkit);

                //set custom font.
                let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSansSC-Bold.otf';    //SC support chinese.
                let fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
                let customFont = await pdfDoc.embedFont(fontBytes);

                //Text (1) Student Name.
                let studentName_fontSize = 28;  //charactors width are small even with long name, so fixed at 28.
                page.drawText(studentName, {
                    x: (page.getWidth() / 2) - (customFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (customFont.heightAtSize(studentName_fontSize) / 2) + 50 + 34 - studentNameOffsetForPosY,
                    size: studentName_fontSize,
                    font: customFont,
                });
            }
            else {
                //2021.04.14
                let isLongName = studentName.length > 29;
                if (isLongName) {
                    let nameArray = studentName.split(' ');
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                    studentName = newNameFormat;
                }
                // let posHeight = isLongName ? 75 : 50;

                // //set font.
                // let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

                //non-chinese-names = alphanumeric charactors = aka English & etc.
                let studentName_fontSize = isLongName ? 22 : 28;

                //draw name.
                //Text (1) Student Name.
                if (isLongName) {
                    let firstLine = studentName.split('<>')[0];
                    page.drawText(firstLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25 + 19 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                    let secondLine = studentName.split('<>')[1];
                    page.drawText(secondLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 19,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
                else {
                    page.drawText(studentName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
            //#endregion

            //#region === Text (3) Serial Number.
            let serialNumber_fontSize = 14;
            let serialNumber = currentParticipatedEvent.hasOwnProperty('CertSN') ?
                String(currentParticipatedEvent.CertSN).length > 0 ?
                    String(currentParticipatedEvent.CertSN)
                    // : event.hasOwnProperty('CertSerialPrefix') ?
                    //     String(event.CertSerialPrefix) + ''
                    : '-'
                : '-';

            //2022.11.02
            let offsetX = Number(page.getWidth() / 4) - serialNumber.length - 4;   //25;
            // console.log('\n width (half) = ' + Number(page.getWidth() / 4));

            // //2022.06.24
            // let offsetX = 167;
            // if (serialNumber.length > 15 && serialNumber.length < 20)
            //     offsetX = 177;
            // else if (serialNumber.length >= 20)
            //     offsetX = 187;

            page.drawText(serialNumber, {
                x: (page.getWidth() / 2) - (serialNumberFont.widthOfTextAtSize(serialNumber, serialNumber_fontSize) / 2) + offsetX,  //167, //177,
                y: (page.getHeight() / 2) - (serialNumberFont.heightAtSize(serialNumber_fontSize) / 2) - 317,
                size: serialNumber_fontSize,
                font: serialNumberFont,
            });
            //#endregion
        }
        //2021.11.08 - revamped - type 2 (Student Name, Serial Number, School Name).
        else if (event.CertLayoutTypeId === 2) {
            //event.CertLayoutTypeId === 2 ===> Student Name, Serial Number, School Name.

            //set font.
            let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
            let serialNumberFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            //#region === Text (2) School Name.
            let studentNameOffsetForPosY = 0;
            let schoolName = this.props.profile.hasOwnProperty('School') ?
                String(this.props.profile.School).length > 0 ? String(this.props.profile.School) : ''
                : '';
            let schoolName_isExtraLongName = schoolName.length > 69;    //2022.11.04
            let schoolName_isLongName = schoolName.length > 29;
            let schoolName_fontSize = schoolName_isLongName ? 18 : 24;  //smaller size than student name.
            if (schoolName_isLongName) {
                let nameArray = schoolName.split(' ');

                let newNameFormat = '';
                if (schoolName_isExtraLongName) {
                    //2022.11.04
                    // schoolName_fontSize = 14;
                    nameArray.map((data, key) => {
                        const divider = newNameFormat.split('<>').length;
                        const limit = 40 * divider;
                        const predict = String(newNameFormat + data);
                        if (predict.length >= limit)
                            newNameFormat = newNameFormat.trimEnd() + '<>' + data + ' ';
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                }
                else {
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    // let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                }
                schoolName = newNameFormat.trim();
                // if (schoolName.includes(' - ')) {
                //     //2022.11.04
                //     let splits = schoolName.split(' - ');
                //     // newNameFormat = '';
                //     // splits.map((txt, key) => {
                //     //     if (key === splits.length - 1)
                //     //         newNameFormat += ' - ' + txt.replace('<>', ' ');
                //     //     else if (key === 1)
                //     //         newNameFormat += '<>' + txt.replace('<>', ' ');
                //     //     else
                //     //         newNameFormat += txt;
                //     //     return null;
                //     // });
                //     // schoolName = newNameFormat.trim();
                //     let shifted = splits.shift();
                //     schoolName = shifted + '<>' + splits.join(' - ');
                // }
                // console.log('\n school name: \n' + schoolName);
                schoolName.split('<>').map((txt, key) => {
                    return page.drawText(txt, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(txt, schoolName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + (20 - (20 * key)) + 5,
                        size: schoolName_fontSize,
                        font: studentNameFont,
                    });
                });
                // let firstLine = schoolName.split('<>')[0];
                // page.drawText(firstLine, {
                //     x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, schoolName_fontSize) / 2),
                //     y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 20 + 5,
                //     size: schoolName_fontSize,
                //     font: studentNameFont,
                // });
                // let secondLine = schoolName.split('<>')[1];
                // page.drawText(secondLine, {
                //     x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, schoolName_fontSize) / 2),
                //     y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 5,
                //     size: schoolName_fontSize,
                //     font: studentNameFont,
                // });
            }
            else {
                if (schoolName.length === 0) {
                    //2022.05.12 - move student name down abit since school name is missing & extra space appeared.
                    studentNameOffsetForPosY = 22;
                }
                else {
                    page.drawText(schoolName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(schoolName, schoolName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(schoolName_fontSize) / 2) + 20,
                        size: schoolName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
            //#endregion

            //#region  Text (1) Student Name.
            if (hasChineseCharactors) {
                //register 'fontkit' instance
                pdfDoc.registerFontkit(fontkit);

                //set custom font.
                let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSansSC-Bold.otf';    //SC support chinese.
                let fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
                let customFont = await pdfDoc.embedFont(fontBytes);

                //Text (1) Student Name.
                let studentName_fontSize = 28;  //charactors width are small even with long name, so fixed at 28.
                page.drawText(studentName, {
                    x: (page.getWidth() / 2) - (customFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (customFont.heightAtSize(studentName_fontSize) / 2) + 50 + 34 - studentNameOffsetForPosY,
                    size: studentName_fontSize,
                    font: customFont,
                });
            }
            else {
                //2021.04.14
                let isLongName = studentName.length > 29;
                if (isLongName) {
                    let nameArray = studentName.split(' ');
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                    studentName = newNameFormat;
                }
                // let posHeight = isLongName ? 75 : 50;

                // //set font.
                // let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

                //non-chinese-names = alphanumeric charactors = aka English & etc.
                let studentName_fontSize = isLongName ? 22 : 28;

                //draw name.
                //Text (1) Student Name.
                if (isLongName) {
                    let firstLine = studentName.split('<>')[0];
                    page.drawText(firstLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25 + 19 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                    let secondLine = studentName.split('<>')[1];
                    page.drawText(secondLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 19 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
                else {
                    page.drawText(studentName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25 - studentNameOffsetForPosY,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
            //#endregion

            //#region  Text (3) Serial Number.
            let serialNumber_fontSize = 14;
            let serialNumber = currentParticipatedEvent.hasOwnProperty('CertSN') ?
                String(currentParticipatedEvent.CertSN).length > 0 ?
                    String(currentParticipatedEvent.CertSN)
                    // : event.hasOwnProperty('CertSerialPrefix') ?
                    //     String(event.CertSerialPrefix) + ''
                    : '-'
                : '-';

            //2022.11.02
            let offsetX = Number(page.getWidth() / 4) - serialNumber.length - 4;

            // //2022.06.24
            // let offsetX = 167;
            // if (serialNumber.length > 15 && serialNumber.length < 20)
            //     offsetX = 177;
            // else if (serialNumber.length >= 20)
            //     offsetX = 187;

            page.drawText(serialNumber, {
                x: (page.getWidth() / 2) - (serialNumberFont.widthOfTextAtSize(serialNumber, serialNumber_fontSize) / 2) + offsetX, //167, //177, //,offsetX
                y: (page.getHeight() / 2) - (serialNumberFont.heightAtSize(serialNumber_fontSize) / 2) - 317,
                size: serialNumber_fontSize,
                font: serialNumberFont,
            });
            //#endregion
        }
        //type 1 (Student Name only).
        else {
            //event.CertLayoutTypeId === 1 ===> default layout (Student Name only).
            if (hasChineseCharactors) {
                //register 'fontkit' instance
                pdfDoc.registerFontkit(fontkit);

                //set custom font.
                // let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSans-Bold.ttf';   //not support chinese.
                let fontUrl = 'https://ikeynew.blob.core.windows.net/ikeykidz/helpfile/NotoSansSC-Bold.otf';    //SC support chinese.
                let fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
                let customFont = await pdfDoc.embedFont(fontBytes);

                let studentName_fontSize = 28;  //charactors width are small even with long name, so fixed at 28.

                page.drawText(studentName, {
                    x: (page.getWidth() / 2) - (customFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                    y: (page.getHeight() / 2) - (customFont.heightAtSize(studentName_fontSize) / 2) + 50,
                    size: studentName_fontSize,
                    font: customFont,
                });
            }
            else {
                // studentName = 'PUTERI DAMIA AMANI BINTI MOHD KHAIRUL RAIS'; //43 chars
                // studentName = 'MUHAMMAD AIMAN HAKIM BIN REZA ROSDEE';   //37 chars
                // studentName = 'Ernest Tan Wui Shean';   //21 chars

                //2021.04.14
                let isLongName = studentName.length > 29;
                if (isLongName) {
                    let nameArray = studentName.split(' ');
                    let nextLineIndex = Math.round(nameArray.length / 2) - 1;
                    let newNameFormat = '';
                    nameArray.map((data, key) => {
                        if (key === nextLineIndex)
                            newNameFormat += data + '<>';
                        else if (key === nameArray.length)
                            newNameFormat += data;
                        else
                            newNameFormat += data + ' ';
                        return null;
                    });
                    studentName = newNameFormat;
                }
                // let posHeight = isLongName ? 75 : 50;

                //set font.
                let studentNameFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

                //non-chinese-names = alphanumeric charactors = aka English & etc.
                let studentName_fontSize = isLongName ? 22 : 28;

                //draw name.
                if (isLongName) {
                    let firstLine = studentName.split('<>')[0];
                    page.drawText(firstLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(firstLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25 + 19,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                    let secondLine = studentName.split('<>')[1];
                    page.drawText(secondLine, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(secondLine, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 19,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
                else {
                    page.drawText(studentName, {
                        x: (page.getWidth() / 2) - (studentNameFont.widthOfTextAtSize(studentName, studentName_fontSize) / 2),
                        y: (page.getHeight() / 2) - (studentNameFont.heightAtSize(studentName_fontSize) / 2) + 50 + 25,
                        size: studentName_fontSize,
                        font: studentNameFont,
                    });
                }
            }
        }
        */
        //#endregion

        // //Serialize the PDFDocument to bytes (a Uint8Array)
        // let pdfBytes = await pdfDoc.save();

        // //Reduce buffer.
        // let arrayBytes = new Uint8Array(pdfBytes);
        // let bgImgBase64 = btoa(arrayBytes.reduce((data, byte) => {
        //     return data + String.fromCharCode(byte);
        // }, ''));

        //2021.04.23 - new built-in function.
        let bgImgBase64 = await pdfDoc.saveAsBase64();
        let bgImgBase64Uri = await pdfDoc.saveAsBase64({ dataUri: true });
        //this method greatly shorten the steps to get the needed final format === 'data:application/pdf;base64,' + bgImgBase64

        // console.log(bgImgBase64);

        //2021.04.23 - backup pdf base64 data uri.
        this.setState({ Base64PdfUri: bgImgBase64 });

        //show download notice.
        await Delay(1000);
        this.props.SetLoading('e-Certificate of Participation', 'Downloading PDF file...', false);
        await Delay(1000);

        //filename
        let _filename = eventDate + '_' + eventName + '_' + studentNameForFile; // + '.pdf';
        if (!this.props.isFromParentApp && this.state.AllowDownloadPdfOnWeb)
            _filename += '.pdf';

        //download file.
        // if (this.props.isFromParentApp && this.state.AllowDownloadPdfOnWeb === false) {
        if (this.props.isFromParentApp) {
            // if (false) {

            //callback data back to app.
            // let data = JSON.stringify({ filename: _filename, base64: 'data:application/pdf;base64,' + bgImgBase64 });

            //2021.04.23
            let data = JSON.stringify({ filename: _filename, base64: bgImgBase64Uri });

            // initialize 
            let a = document.createElement('a');
            // eslint-disable-next-line
            a.href = "javascript:window.ReactNativeWebView.postMessage('" + data + "');";
            document.body.appendChild(a);
            a.click();

            //close loading ui.
            // this.props.CloseAlert();

            //save counter of downloads on FS.
            await this.SaveDownloadCounters(false, event.EventCode);  //2021.04.22

            //ux
            await Delay(2000);

            //show success ui.
            // this.props.SetAlert('', Locale("pdf-download-success", this.props.Locale), false);
            // this.props.SetAlert('', Locale("pdf-download-notice", this.props.Locale), false);
            // this.props.SetAlert(Locale("label-pdf-download-reminder", this.props.Locale), Locale("notice-pdf-download-reminder", this.props.Locale));

            //2021.08.30
            this.props.SetAlert(
                Locale("label-pdf-download-success", this.props.Locale),
                Locale("notice-pdf-download-success", this.props.Locale)
            );

            // //2021.04.23
            // this.props.SetAlertWithComponent(
            //     Locale("label-pdf-download-reminder", this.props.Locale),
            //     <>
            //         <button className='btn btn-primary' onClick={() => window.postMessage('EventTriggerDownloadPDF', '*')}>
            //             {Locale("label-pdf-download-manual-btn", this.props.Locale)}
            //         </button><br /><br />
            //         <span>{Locale("notice-pdf-download-reminder", this.props.Locale)}</span>
            //     </>,
            //     true);
        }
        else if (this.state.AllowDownloadPdfOnWeb) {
            //Trigger the browser to download the PDF document.
            // await this.SaveFile(_filename, 'data:application/pdf;base64,' + bgImgBase64, event.EventCode);
            await this.SaveFile(_filename, bgImgBase64Uri, event.EventCode);
        }
        else {
            let notice = Locale("notice-pe-download-pdf", this.props.Locale);
            notice = String(notice).replace('<a>',
                '<button class="btn-link" onClick={window.open("' + downloadPdfGuidUrl + '")}><b>');
            notice = String(notice).replace('</a>', '</b></button>');

            this.props.SetAlert(
                Locale("label-pe-feature", this.props.Locale),
                notice,
                false
            );
        }
    }

    //2021.03.29 - Download file.
    SaveFile = async (filename, data, eventCode) => {

        // let useWindowOpenMethod = true;

        // if (useWindowOpenMethod === false) {
        // create link element
        let a = document.createElement('a');
        // let url = window.URL.createObjectURL(data);
        let url = data;

        // initialize 
        a.href = url;
        a.download = filename;

        // append element to the body, 
        // a must, due to Firefox
        document.body.appendChild(a);

        // trigger download
        a.click();

        // delay a bit deletion of the element
        setTimeout(function () {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 100);

        // }
        // else {
        //     // 2021.09.03
        //     let popup = window.open(data, '_blank');

        var havePopupBlockers = ('' + window.open).indexOf('[native code]') === -1;
        if (havePopupBlockers) {
            // this.props.SetAlert('Download Failed', 'Download has been blocked by your browser.<br />Please whitelist this website & try again.');
            this.props.CloseAlert();
            this.setState({ ShowPopupErrorModal: true });
            return null;
        }
        // }

        //close loading ui. if from desktop browser.
        // this.props.CloseAlert();

        //save counter of downloads on FS.
        await this.SaveDownloadCounters(true, eventCode);  //2021.04.22

        //show success ui.
        // this.props.SetAlert('', Locale("pdf-download-success", this.props.Locale), false);
        // this.props.SetAlert(Locale("label-pdf-download-reminder", this.props.Locale), Locale("notice-pdf-download-reminder", this.props.Locale));

        //2021.08.30
        this.props.SetAlert(
            Locale("label-pdf-download-success", this.props.Locale),
            Locale("notice-pdf-download-success", this.props.Locale)
        );

        // //2021.04.23
        // this.props.SetAlertWithComponent(
        //     Locale("label-pdf-download-reminder", this.props.Locale),
        //     <>
        //         <button className='btn btn-primary' onClick={() => window.postMessage('EventTriggerDownloadPDF', '*')}>
        //             {Locale("label-pdf-download-manual-btn", this.props.Locale)}
        //         </button><br /><br />
        //         <span>{Locale("notice-pdf-download-reminder", this.props.Locale)}</span>
        //     </>,
        //     true);
    }

    //2021.09.03
    PopupReminderComponent = () => {
        // let text = <><b>Download the e-cert from each event below</b>👇<br /></>;
        // text += <>Reminder: Please ensure that Pop-up Blocker is disabled on your device before download.</>;
        // text += <><br /><br />Click <a href='#'>here</a> to check the steps to disable Pop-up blockers in the most common internet browsers.</>;
        return (
            <>
                <b>Download the e-cert from each event below</b> 👇<br />
                Reminder: Please ensure that Pop-up Blocker is disabled on your device before download.<div style={{ height: 5 }} />
                Click <button type="button" className="btn-link" onClick={this.Toggle_PopupBlockerStepsModal}
                >here</button> to check the steps to disable Pop-up blockers in the most common internet browsers.
            </>
        );
    }

    //2021.09.03
    Toggle_PopupBlockerStepsModal = () => {
        // alert('popup callback');
        this.setState({ ShowPopupBlockerStepsModal: !this.state.ShowPopupBlockerStepsModal });
    }

    //2021.04.23
    EventTriggerDownloadPDF = () => {
        if (this.props.isDevMode)
            console.log('EventTriggerDownloadPDF');
        let blob = this.SaveDataUriToBlob(this.state.Base64PdfUri);
        let url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    }

    //2021.04.23
    SaveDataUriToBlob = (dataUri) => {
        let byteString = window.atob(dataUri);
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([int8Array], { type: 'application/pdf' });
        return blob;
    }

    // SaveBlobAsFile = (fileName, fileContents) => {
    //     if (typeof (Blob) != 'undefined') { // using Blob
    //         var textFileAsBlob = new Blob([fileContents], {
    //             type: 'text/plain'
    //         });
    //         var downloadLink = document.createElement("a");
    //         downloadLink.download = fileName;
    //         if (window.webkitURL != null) {
    //             downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
    //         } else {
    //             downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
    //             downloadLink.onclick = document.body.removeChild(event.target);
    //             downloadLink.style.display = "none";
    //             document.body.appendChild(downloadLink);
    //         }
    //         downloadLink.click();
    //     } else {
    //         var pp = document.createElement('a');
    //         pp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContents));
    //         pp.setAttribute('download', fileName);
    //         pp.onclick = document.body.removeChild(event.target);
    //         pp.click();
    //     }
    // } //saveBlobAsFile

    //2021.04.22
    SaveDownloadCounters = async (fromWeb, eventCode) => {

        if (!this.props.isDevMode)
            return null;

        //FS ref.
        let rootRef = this.props.firestore.collection('_Counters').doc('CertDownload');
        let statisticRef = rootRef.collection('Statistic').doc(eventCode);
        let userCounterRef = statisticRef.collection('Users').doc(this.props.profile.Uid);
        let userListRef = userCounterRef.collection('List');

        //debug
        if (this.props.isDevMode)
            console.log('\nPaths:\n' + rootRef.path + '\n' + statisticRef.path + '\n' + userCounterRef.path + '\n' + userListRef.path + '\n');

        //fetch if counter data is exist.
        let isRootCounterExists = await this.CheckExistence(rootRef);
        let isStatCounterExists = await this.CheckExistence(statisticRef);
        let isUserCounterExists = await this.CheckExistence(userCounterRef);

        //debug
        if (this.props.isDevMode)
            console.log('\ncounter is existed:\nRoot = ' + isRootCounterExists + '\nStat = ' + isStatCounterExists + '\nUser = ' + isUserCounterExists + '\n');

        //Save Counters ============= start
        let _current = moment();
        let _lastUpdate = _current.format('YYYY-MM-DD HH:mm:ss');
        let _lastUpdateUtc = _current.utc().format('YYYY-MM-DD HH:mm:ss');

        let db = this.props.firestore;
        let batch = db.batch();

        //Root        
        let _root_DataToUpdate = {};
        _root_DataToUpdate['LastUpdate'] = _lastUpdate;
        _root_DataToUpdate['LastUpdateUtc'] = _lastUpdateUtc;
        _root_DataToUpdate['TotalDownloads'] = isRootCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
        // _root_DataToUpdate['TotalDownloadUsers'] = isRootCounterExists ? (isUserCounterExists === false ? this.props.firestore.FieldValue.increment(1) : 1) : 1;

        if (fromWeb) {
            _root_DataToUpdate['TotalWebDLs'] = isRootCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
            if (isRootCounterExists === false)
                _root_DataToUpdate['TotalParentAppDLs'] = 0;
        }
        else {
            _root_DataToUpdate['TotalParentAppDLs'] = isRootCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
            if (isRootCounterExists === false)
                _root_DataToUpdate['TotalWebDLs'] = 0;
        }
        if (isRootCounterExists === false) {
            _root_DataToUpdate['Counter'] = 'CertDownload';
            _root_DataToUpdate['TotalDownloadUsers'] = 1;
        }
        else {
            if (isUserCounterExists === false)
                _root_DataToUpdate['TotalDownloadUsers'] = firebase.firestore.FieldValue.increment(1);
        }

        //Event's Statistic        
        let _stat_DataToUpdate = {};
        _stat_DataToUpdate['LastUpdate'] = _lastUpdate;
        _stat_DataToUpdate['LastUpdateUtc'] = _lastUpdateUtc;
        _stat_DataToUpdate['TotalDownloads'] = isStatCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
        // _stat_DataToUpdate['TotalDownloadUsers'] = isUserCounterExists === false ? this.props.firestore.FieldValue.increment(1) : 1;
        if (fromWeb) {
            _stat_DataToUpdate['TotalWebDLs'] = isStatCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
            if (isStatCounterExists === false)
                _stat_DataToUpdate['TotalParentAppDLs'] = 0;
        }
        else {
            _stat_DataToUpdate['TotalParentAppDLs'] = isStatCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
            if (isStatCounterExists === false)
                _stat_DataToUpdate['TotalWebDLs'] = 0;
        }
        if (isStatCounterExists === false) {
            _stat_DataToUpdate['EventCode'] = eventCode;
            _stat_DataToUpdate['TotalDownloadUsers'] = 1;
        }
        else {
            if (isUserCounterExists === false)
                _stat_DataToUpdate['TotalDownloadUsers'] = firebase.firestore.FieldValue.increment(1);
        }

        //User's counter        
        let _user_DataToUpdate = {};
        _user_DataToUpdate['LastUpdate'] = _lastUpdate;
        _user_DataToUpdate['LastUpdateUtc'] = _lastUpdateUtc;
        _user_DataToUpdate['TotalDownloads'] = isUserCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
        if (fromWeb) {
            _user_DataToUpdate['TotalWebDLs'] = isUserCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
            if (isUserCounterExists === false)
                _user_DataToUpdate['TotalParentAppDLs'] = 0;
        }
        else {
            _user_DataToUpdate['TotalParentAppDLs'] = isUserCounterExists ? firebase.firestore.FieldValue.increment(1) : 1;
            if (isUserCounterExists === false)
                _user_DataToUpdate['TotalWebDLs'] = 0;
        }
        if (isUserCounterExists === false)
            _user_DataToUpdate['Uid'] = this.props.profile.Uid;


        //User's List for every download.
        userListRef = userListRef.doc(_current.utc().format('YYYYMMDDHHmmss'));
        let _user_list_DataToUpdate = {};
        _user_list_DataToUpdate['LastUpdate'] = _lastUpdate;
        _user_list_DataToUpdate['LastUpdateUtc'] = _lastUpdateUtc;
        _user_list_DataToUpdate['FromWeb'] = fromWeb;

        //debug
        if (this.props.isDevMode)
            console.log('\nPath:\nUser List = ' + userListRef.path + '\nFromWeb = ' + fromWeb);


        //set action - Root
        if (isRootCounterExists)
            batch.update(rootRef, _root_DataToUpdate);
        else
            batch.set(rootRef, _root_DataToUpdate);


        //set action - Statistic
        if (isStatCounterExists)
            batch.update(statisticRef, _stat_DataToUpdate);
        else
            batch.set(statisticRef, _stat_DataToUpdate);


        //set action - User
        if (isUserCounterExists)
            batch.update(userCounterRef, _user_DataToUpdate);
        else
            batch.set(userCounterRef, _user_DataToUpdate);


        //set action - User's List
        batch.set(userListRef, _user_list_DataToUpdate);


        // Commit the batch
        batch.commit().then(() => {
            if (this.props.isDevMode)
                console.log('All Download Counters are saved.');
        });
        //Save Counters ============= end
    }

    //2021.04.22
    CheckExistence = async (_ref) => {
        return await _ref.get().then((doc) => { return doc.exists; });
    }

    //2021.03.29
    Load_ParticipatedEventList = async () => {
        let _PEList_lastVisible = null;
        let _FS_Participated_Event_List = [];
        this.setState({
            PEList_isLoading: true,
            EventDetails: null,
        });

        //Fetch list.
        await this.props.firestore
            .collection('LiveQuiz_Certifications')
            .doc(String(this.props.user.uid))
            // .doc('08QRy0oxKgf17Svlg3kuDhodgoA3')     //testing/
            .collection('Certificates')
            // .collection('LiveQuiz_Certifications/' + this.props.user.uid + '/Certificates')
            .where('CreatedOnUtc', '>=', '2020-01-01 00:00:00')
            .orderBy('CreatedOnUtc', 'desc')
            // .where('EventCode', '==', 'ikeyteam_2021_beta_test_event')      //testing.
            // .limit(10)
            .get()
            .then((querySnapshot) => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        dataArray[dataArray.length - 1].Id = doc.id;    //important     //2021.11.18
                    });
                    // alert(JSON.stringify(dataArray));
                    if (dataArray.length > 0) {
                        // dataArray = await this.CheckAndRemoveDuplicatedCertSN(dataArray);

                        _FS_Participated_Event_List = dataArray;

                        // //2021.03.30 - set last item
                        // _PEList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
            })
            .catch(error => {
                alert(error);
            });

        if (this.props.isDevMode)
            console.log(JSON.stringify(_FS_Participated_Event_List));

        //2021.12.17
        _FS_Participated_Event_List = await Promise.all(await this.CheckAndRemoveDuplicatedCertSN(_FS_Participated_Event_List));
        _PEList_lastVisible = _FS_Participated_Event_List[_FS_Participated_Event_List.length - 1];

        //2021.09.14
        _FS_Participated_Event_List.map((data, key) => {
            if (data.DateStart === undefined || data.DateStart === '') {
                data.DateStart = '2020-01-01';
                let findIndex = this.props.Events.findIndex(x => x.EventCode === data.EventCode);
                if (findIndex > -1) {
                    data.DateStart = this.props.Events[findIndex].DateStart;

                    //2021.12.09
                    if (data.CertDownloadEnabled !== this.props.Events[findIndex].CertDownloadEnabled)
                        data.CertDownloadEnabled = this.props.Events[findIndex].CertDownloadEnabled;
                }
            }
            return null;
        });
        // _FS_Participated_Event_List.sort((a, b) => moment(b.DateStart, 'YYYY-MM-DD') - moment(a.DateStart, 'YYYY-MM-DD'));
        _FS_Participated_Event_List.sort((a, b) => Number(b.Id) - Number(a.Id));

        //Set values.
        if (_FS_Participated_Event_List.length > 0) {

            if (this.props.isDevMode)
                console.log(JSON.stringify(_FS_Participated_Event_List));

            let default_Null_Value = new Array(_FS_Participated_Event_List.length).fill([]);
            let default_Loading_Value = new Array(_FS_Participated_Event_List.length).fill(true);
            let default_Loading_Value_False = new Array(_FS_Participated_Event_List.length).fill(false);
            default_Loading_Value_False[0] = true;

            this.setState({
                //2021.04.07
                Participated_Event_List_Toggle: default_Loading_Value_False,

                Participated_Event_List: _FS_Participated_Event_List,
                // PEList_isLoaded: true,
                // PEList_isLoading: false,
                PEList_lastVisible: _PEList_lastVisible,

                PEList_QuizRoomResult: default_Null_Value,
                PEList_QuizRoomResult_isLoading: default_Loading_Value,
                // });
                // }, async () => {
                //     await this.Load_RoomHistoryByEventCode();
                // });

                //2021.04.13
                // Participated_Event_Counter: _FS_Participated_Event_List.length,
            }, () => {
                this.Tigger_Load_RoomHistoryByEventCode();
            });
        }
        else {
            this.setState({
                PEList_isLoaded: true,
                PEList_isLoading: false,
            });
        }
    }

    //2021.12.17
    CheckAndRemoveDuplicatedCertSN = async (dataArray = []) => {
        let _eventCodeArray = [];
        let _new_dataArray = [];
        dataArray.sort((x, y) => x.Id - y.Id);
        for (let i = 0; i < dataArray.length; i++) {
            let index = _eventCodeArray.findIndex(x => x === dataArray[i].EventCode);
            if (index < 0) {
                _eventCodeArray.push(String(dataArray[i].EventCode));
                _new_dataArray.push(dataArray[i]);
            }
            else {
                //in case CertSN is assigned to 1 of the duplicated record.
                let currSN = dataArray[i].hasOwnProperty('CertSN') ? String(dataArray[i].CertSN) : '';
                if (currSN !== '') {
                    let existSN_index = _new_dataArray.findIndex(x => x.EventCode === String(dataArray[i].EventCode));
                    if (existSN_index > -1)
                        if (String(_new_dataArray[existSN_index].CertSN) === '')
                            await this.props.firestore
                                .collection('LiveQuiz_Certifications')
                                .doc(String(this.props.user.uid))
                                .collection('Certificates')
                                .doc(String(_new_dataArray[existSN_index].Id))
                                .update({
                                    CertSN: currSN
                                });
                }
                //found duplicated record. proceed to remove.
                await this.props.firestore
                    .collection('LiveQuiz_Certifications')
                    .doc(String(this.props.user.uid))
                    .collection('Certificates')
                    .doc(String(dataArray[i].Id))
                    .delete();
            }
        }
        // await Promise.all(
        //     dataArray.map(async (data, key) => {
        //         let index = _eventCodeArray.findIndex(x => x === data.EventCode);
        //         if (index < 0) {
        //             _eventCodeArray.push(String(data.EventCode));
        //             _new_dataArray.push(data);
        //         }
        //         else {
        //             //found duplicated record. proceed to remove.
        //             await this.props.firestore
        //                 .collection('LiveQuiz_Certifications')
        //                 .doc(String(this.props.user.uid))
        //                 .collection('Certificates')
        //                 .doc(String(data.Id))
        //                 .delete();
        //         }
        //         return null;
        //     })
        // );
        //Check & replace counter.
        let updateCounter = false;
        let counter = 0;
        let lastUpdate = '';
        await this.props.firestore
            .collection('LiveQuiz_Certifications')
            .doc(String(this.props.user.uid))
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    if (data.hasOwnProperty('Counter')) {
                        if (Number(data.Counter) !== _new_dataArray.length) {
                            updateCounter = true;
                            counter = _new_dataArray.length;
                            if (_new_dataArray[_new_dataArray.length - 1].hasOwnProperty('CreatedOnUtc'))
                                lastUpdate = String(_new_dataArray[_new_dataArray.length - 1].CreatedOnUtc);
                            else
                                lastUpdate = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                        }
                    }
                }
            });
        if (updateCounter) {
            await this.props.firestore
                .collection('LiveQuiz_Certifications')
                .doc(String(this.props.user.uid))
                .update({
                    Counter: counter,
                    LastUpdatedOnUtc: lastUpdate,
                });
        }
        return _new_dataArray;
    }

    LoadMore_ParticipatedEventList = async (start, end) => {

        //2021.04.12
        if (this.props.isFromParentApp === false && this.state.AllowDownloadPdfOnWeb === false) {
            this.props.SetAlert(
                Locale("label-pe-feature", this.props.Locale),
                Locale("notice-pe-load-more", this.props.Locale)
            );
            return null;
        }

        // //2021.04.13
        // if (this.state.Participated_Event_Counter <= this.state.Participated_Event_List.length) {
        //     this.props.SetAlert(
        //         Locale("label-pe-feature-load-more", this.props.Locale),
        //         Locale("notice-pe-feature-load-more-nomore", this.props.Locale)
        //     );
        //     return null;
        // }

        // ScrollToElement('bottomPage', 500);

        let _PEList_More_lastVisible = null;
        let _FS_Participated_Event_List = [];
        this.setState({
            PEList_More_isLoading: true,
        });
        await this.props.firestore
            .collection('LiveQuiz_Certifications')
            .doc(String(this.props.user.uid))
            // .doc('08QRy0oxKgf17Svlg3kuDhodgoA3')     //testing
            .collection('Certificates')
            // .collection('LiveQuiz_Certifications/' + this.props.user.uid + '/Certificates')            
            .orderBy("CreatedOnUtc", "desc")
            .startAfter(this.state.PEList_lastVisible)
            .limit(5)
            .get()
            .then((querySnapshot) => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                        dataArray[dataArray.length - 1].Id = doc.id;    //important     //2021.11.18
                    });
                    // alert(JSON.stringify(dataArray));
                    if (dataArray.length > 0) {
                        _FS_Participated_Event_List = dataArray;

                        //2021.03.30 - set last item
                        _PEList_More_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                    }
                }
            })
            .catch(error => {
                alert(error);
            });

        if (_FS_Participated_Event_List.length > 0) {

            //2021.04.12
            let _Participated_Event_List = this.state.Participated_Event_List;
            let _Participated_Event_List_Toggle = this.state.Participated_Event_List_Toggle;
            let _PEList_QuizRoomResult = this.state.PEList_QuizRoomResult;
            let _PEList_QuizRoomResult_isLoading = this.state.PEList_QuizRoomResult_isLoading;

            _FS_Participated_Event_List.map((data, key) => {
                _Participated_Event_List.push(data);
                _Participated_Event_List_Toggle.push(false);
                _PEList_QuizRoomResult.push([]);
                _PEList_QuizRoomResult_isLoading.push(true);
                return null;
            });

            //2021.09.14
            _FS_Participated_Event_List.map((data, key) => {
                if (data.DateStart === undefined || data.DateStart === '') {
                    data.DateStart = '2020-01-01';
                    let findIndex = this.props.Events.findIndex(x => x.EventCode === data.EventCode);
                    if (findIndex > -1) {
                        data.DateStart = this.props.Events[findIndex].DateStart;

                        //2021.12.09
                        if (data.CertDownloadEnabled !== this.props.Events[findIndex].CertDownloadEnabled)
                            data.CertDownloadEnabled = this.props.Events[findIndex].CertDownloadEnabled;
                    }
                }
                return null;
            });
            _FS_Participated_Event_List.sort((a, b) => moment(b.DateStart, 'YYYY-MM-DD') - moment(a.DateStart, 'YYYY-MM-DD'));

            this.setState({
                Participated_Event_List: _FS_Participated_Event_List,
                // PEList_isLoaded: true,
                // PEList_More_isLoading: false,
                PEList_lastVisible: _PEList_More_lastVisible,

                //2021.04.12
                Participated_Event_List_Toggle: _Participated_Event_List_Toggle,
                PEList_QuizRoomResult: _PEList_QuizRoomResult,
                PEList_QuizRoomResult_isLoading: _PEList_QuizRoomResult_isLoading,
            }, () => {
                this.Tigger_Load_RoomHistoryByEventCode();
            });
        }
        else {
            setTimeout(() => {
                this.setState({
                    PEList_isLoaded: true,
                    PEList_isLoading: false,
                    PEList_More_isLoaded: true,
                    PEList_More_isLoading: false,
                });
            }, 2000);
        }
    }

    //2021.03.31
    Tigger_Load_RoomHistoryByEventCode = async () => {
        await this.Load_RoomHistoryByEventCode();
    }

    //2021.03.30
    Load_RoomHistoryByEventCode = async () => {

        // console.log('Events = ' + this.state.Participated_Event_List.length + '\nDetails = ' + this.state.EventDetails.length);

        if (this.state.Participated_Event_List.length > 0) {

            let eventDetails = [];
            let data = this.state.Participated_Event_List;

            for (let i = 0; i < data.length; i++) {

                // console.log('data.EventCode = ' + data[i].EventCode);

                // let data = this.state.Participated_Event_List[i];
                // eventDetails = this.state.EventDetails;

                //Check if Event details adi cached or not.
                let eventIndex = eventDetails.length > 0 ?
                    eventDetails.findIndex(x => String(x.EventCode) === String(data[i].EventCode))
                    : -1;
                // let eventIndex = eventDetails.length > 0 ?
                //     eventDetails.findIndex(x => Array(x).hasOwnProperty('EventCode') ? x.EventCode === data[i].EventCode : false)
                //     : -1;

                // let eventIndex = -1;
                // if (eventDetails.length > 0) {
                //     eventDetails.map((dt, ky) => {
                //         if (eventDetails[ky].hasOwnProperty('EventCode')) {
                //             if (dt.EventCode === data[i].EventCode) {
                //                 eventIndex = i;
                //             }
                //         }
                //         return null;
                //     });
                // }

                // console.log('eventIndex = ' + eventIndex);

                //Fetch Event details if not found in cached array.
                if (eventIndex < 0) {

                    await this.props.firestore
                        .collection('LiveQuiz_UpcomingEvents')
                        .where('EventCode', '==', String(data[i].EventCode))
                        .get()
                        .then((querySnapshot) => {
                            let dataArray = [];
                            if (querySnapshot !== null) {
                                dataArray = querySnapshot.docs.map(doc => doc.data())[0];

                                // console.log(dataArray.length > 0 ? 'EventDetails = ' + JSON.stringify(dataArray) : 'receive nothing');
                                // console.log(dataArray.length > 0 ? 'EventDetails = ' + dataArray.EventCode : 'receive nothing');
                                // console.log(JSON.stringify(dataArray));

                                eventDetails.push(dataArray);

                                // //update EventDetails cache.
                                // this.setState({
                                //     EventDetails: eventDetails,
                                // });
                            }
                        })
                        .catch(error => {
                            alert(error);
                        });
                }
            }

            //update ui toggle.
            this.setState({
                EventDetails: eventDetails,
                PEList_isLoaded: true,
                PEList_isLoading: false,
                PEList_More_isLoaded: true,
                PEList_More_isLoading: false,
            }, () => {
                this.Trigger_Fetch_ParticipatedQuizRoomResults();
                // await this.Fetch_ParticipatedQuizRoomResults();
            });
            // this.Trigger_Fetch_ParticipatedQuizRoomResults();
        }
        else {
            // console.log(eventDetails.length > 0 ? JSON.stringify(eventDetails) : 'receive nothing');
            this.setState({
                PEList_isLoaded: true,
                PEList_isLoading: false,
                PEList_More_isLoaded: true,
                PEList_More_isLoading: false,
            });
        }
    }

    //2021.04.07
    SetToggleState = (key) => {
        let toggleState = this.state.Participated_Event_List_Toggle;
        // console.log(toggleState[key] + ' = ' + key);
        toggleState.map((data, _key) => {
            if (_key === key)
                toggleState[_key] = !toggleState[_key];
            else
                toggleState[_key] = false;
            return null;
        });
        // console.log(toggleState[key] + ' = ' + key);
        // toggleState[key] = !toggleState[key];
        this.setState({ Participated_Event_List_Toggle: toggleState });
        // ScrollToElement(key > 0 ? 'ele_event_acc_' + (key - 1) : 'top', 500);
    }

    //2022.06.22
    SetToggleState_ON = (key) => {
        let toggleState = this.state.Participated_Event_List_Toggle;
        toggleState.map((data, _key) => {
            if (_key === key)
                toggleState[_key] = true;
            else
                toggleState[_key] = false;
            return null;
        });
        this.setState({ Participated_Event_List_Toggle: toggleState });
    }

    //2021.11.08
    Check_CertDownloadDelayed = (event) => {
        if (event === null || event === undefined)
            return true;

        let isDelayed = false;
        if (event.hasOwnProperty('CertDownloadDelayed')) {
            isDelayed = CheckBoolean(event.CertDownloadDelayed);

            //2022.06.22
            if (event.hasOwnProperty('CertDownloadDelayedEndDateTime')) {
                if (event.CertDownloadDelayedEndDate !== null && event.CertDownloadDelayedEndDateTime !== undefined) {
                    const endDT = moment(String(event.CertDownloadDelayedEndDateTime), 'YYYY-MM-DD HH:mm:ss', true);
                    if (endDT.isValid()) {
                        if (moment() > endDT)
                            isDelayed = false;
                        else
                            isDelayed = true;
                    }
                }
            }
        }
        return isDelayed;
        // return false;       //testing.
    }

    //2022.06.22
    CertDownloadDelayed_DisplayText = (event) => {
        if (event === null || event === undefined)
            return Locale("label-download-certificate-delayed", this.props.Locale);

        let component = <>{Locale("label-download-certificate-delayed-deadline", this.props.Locale, moment(event['DateStart']).add(3, 'months').format('D MMM YYYY'))}
            <br />{Locale("label-download-certificate-delayed-deadline-1", this.props.Locale)}</>;
        if (event.hasOwnProperty('CertDownloadDelayedEndDateTime')) {
            const endDT_text = String(event.CertDownloadDelayedEndDateTime);
            if (
                event.CertDownloadDelayedEndDate !== null
                && event.CertDownloadDelayedEndDateTime !== undefined
                && endDT_text !== ''
                && endDT_text.includes('null') === false
                && endDT_text.includes('undefined') === false
                && endDT_text.includes('date') === false
            ) {
                const endDT = moment(endDT_text, 'YYYY-MM-DD HH:mm:ss', true);
                if (endDT.isValid()) {
                    component = <>{Locale("label-download-certificate-delayed-deadline", this.props.Locale, endDT.format('D MMM YYYY, HH:mm A'))}
                        <br />{Locale("label-download-certificate-delayed-deadline-1", this.props.Locale)}</>;
                }
            }
        }
        return component;
    }

    //2021.03.31
    GetComponent_ParticipatedEventList = (event, key) => {
        // console.log('key ' + key + ' =\n' + JSON.stringify(event));
        // if (event === null || this.state.EventDetails === null)
        //     return null;

        let _index = this.state.EventDetails.findIndex(x => x.EventCode === String(event.EventCode));
        // let _index = this.state.EventDetails.findIndex(x => x.hasOwnProperty('EventCode') ? x.EventCode === event.EventCode : false);
        if (_index < 0) {
            return null;
        }
        else {
            let certData = this.state.Participated_Event_List[key];
            let data = this.state.EventDetails[_index];
            let keyStr = String(key);
            // console.log(JSON.stringify(data));

            //return Ui component.
            return <>
                <div
                    id={'event_div_acc_' + key}
                    key={'event_div_acc_' + key}
                    hidden={
                        this.state.PEList_QuizRoomResult_isLoading[key] === false
                            &&
                            this.state.PEList_QuizRoomResult[key].length <= 0 ? true : false
                    }
                >
                    <Card style={{ border: '5px solid lavender' }}>
                        <Accordion.Toggle
                            as={Card.Header}
                            key={'event_acc_' + key}
                            eventKey={keyStr}
                            style={{
                                backgroundColor: '#007bff',
                                cursor: 'pointer',
                            }}
                            onClick={() => this.SetToggleState(key)}
                        >
                            <table width={'100%'} height={38}>
                                <tbody>
                                    <tr>
                                        <td valign={'middle'}>
                                            <span
                                                style={{
                                                    color: 'white',
                                                    fontSize: 20,
                                                    fontWeight: 'bold',
                                                }}
                                            >{data.EventName}</span>
                                        </td>
                                        <td valign={'middle'} align={'right'}>
                                            {
                                                this.state.PEList_QuizRoomResult_isLoading[key] ?
                                                    <Spinner variant={'light'} as={'span'} animation={'border'} size={'s'} role={'status'} />
                                                    :
                                                    this.state.Participated_Event_List_Toggle[key] ?
                                                        <i className="fa fa-chevron-up" style={{ color: 'white' }}></i>
                                                        :
                                                        <i className="fa fa-chevron-down" style={{ color: 'white' }}></i>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={keyStr} key={'event_acc_toggle_' + key}>
                            <Card.Body style={{ padding: 10 }}>
                                {
                                    this.Check_CertDownloadDelayed(data) ?
                                        <div className='row'>
                                            <div className='col' style={{ width: '100%', justifyContent: 'center', }}>
                                                <div style={{ padding: '10px 0px 0px 0px', textAlign: 'center', }}>
                                                    <div
                                                        style={{
                                                            // border: '3px solid gray',
                                                            borderRadius: 5,
                                                            padding: 10,
                                                            backgroundColor: 'rgb(0, 123, 255)',
                                                            color: 'white',
                                                            letterSpacing: 0.5,
                                                            width: 'fit-content',
                                                            display: 'inline-block',
                                                        }}
                                                    // ><i>{Locale("label-download-certificate-delayed-deadline", this.props.Locale, moment('2021-12-15').format('D MMM YY'))}</i></div>
                                                    // ><i>{Locale("label-download-certificate-delayed-deadline", this.props.Locale, moment(data['DateStart']).add(3, 'months').format('D MMM YYYY'))}
                                                    //         <br />{Locale("label-download-certificate-delayed-deadline-1", this.props.Locale)}</i></div>
                                                    ><i>{this.CertDownloadDelayed_DisplayText(data)}</i></div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        certData.hasOwnProperty('CertDownloadEnabled') ?
                                            //for newer record with property 'CertDownloadEnabled'
                                            certData.CertDownloadEnabled ?
                                                <div className='row'>
                                                    <div className='col'>
                                                        <button type="button" className="btn btn-danger btn-block"
                                                            onClick={() => this.DownloadCertPDF(key)}
                                                        ><span style={{ color: 'white', fontWeight: 500 }}>{Locale("label-download-certificate", this.props.Locale)}</span></button>
                                                    </div>
                                                </div>
                                                : null
                                            :
                                            //for older record without property 'CertDownloadEnabled'
                                            <div className='row'>
                                                <div className='col'>
                                                    <button type="button" className="btn btn-danger btn-block"
                                                        onClick={() => this.DownloadCertPDF(key)}
                                                    ><span style={{ color: 'white', fontWeight: 500 }}>{Locale("label-download-certificate", this.props.Locale)}</span></button>
                                                </div>
                                            </div>
                                }
                                <div className='row'>
                                    <div className='col' key={'event_acc_col_' + key}>
                                        {
                                            this.props.isFromParentApp ?
                                                <table cellPadding={5} cellSpacing={5} border={0} style={{ marginTop: 10, marginBottom: 12, }}>
                                                    <tbody>
                                                        <tr key={'tb_organizer'}>
                                                            <td align={'right'}>{Locale("organise-by", this.props.Locale)}</td>
                                                            <td width={'5%'}></td>
                                                            <td>{data.hasOwnProperty('OrganizerDisplayName') ? data.OrganizerDisplayName : data.Organizer}</td>
                                                        </tr>
                                                        <tr key={'tb_roomdate'}>
                                                            <td align={'right'}>{Locale("room-date", this.props.Locale)}</td>
                                                            <td>&nbsp;</td>
                                                            <td>{data.Date}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                :
                                                <table cellPadding={5} cellSpacing={5} border={0} width={'100%'} style={{ marginTop: 10, marginBottom: 12, }}>
                                                    <tbody>
                                                        <tr key={'tb_organizer'}>
                                                            <td width={'45%'} align={'right'}>{Locale("organise-by", this.props.Locale)}</td>
                                                            <td width={'5%'}></td>
                                                            <td width={'45%'}>{data.hasOwnProperty('OrganizerDisplayName') ? data.OrganizerDisplayName : data.Organizer}</td>
                                                        </tr>
                                                        <tr key={'tb_roomdate'}>
                                                            <td align={'right'}>{Locale("room-date", this.props.Locale)}</td>
                                                            <td>&nbsp;</td>
                                                            <td>{data.Date}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                        }
                                        {/* <br /> */}
                                        {
                                            certData.hasOwnProperty('CertDownloadEnabled') ?
                                                certData.CertDownloadEnabled ?
                                                    this.Check_CertDownloadDelayed(data) ? null
                                                        :
                                                        data.hasOwnProperty('GovRecognitionLetterUrl') ?
                                                            String(data.GovRecognitionLetterUrl).length > 0 && data.GovRecognitionLetterUrl !== undefined ?
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col'>
                                                                            <OverlayTrigger
                                                                                overlay={<Tooltip><span>PAJSK (Pentaksiran Aktiviti Jasmani, Sukan dan Kokurikulum)</span></Tooltip>}>
                                                                                <button type="button" className="btn btn-success btn-block"
                                                                                    onClick={() => window.open(String(data.GovRecognitionLetterUrl), '_new')}
                                                                                >
                                                                                    {/* <span style={{ color: 'white', fontWeight: 500 }}>Surat Pentaksiran Aktiviti Jasmani, Sukan dan Kokurikulum (PAJSK)</span> */}
                                                                                    <span style={{ color: 'white', fontWeight: 500 }}>Surat Pengiktirafan Markah PAJSK</span>
                                                                                </button>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                </>
                                                                : null
                                                            : null
                                                    : null
                                                : null
                                        }
                                        {
                                            this.state.PEList_QuizRoomResult_isLoading[key] ?
                                                <LoadingIndicator />
                                                :
                                                // this.state.PEList_QuizRoomResult[key].length > 0 ?
                                                this.state.PEList_QuizRoomResult.map((room, eventKey) => {
                                                    // console.log(room[0].EventCode + ' === ' + data.EventCode);
                                                    if (room[0] !== undefined && room[0].EventCode === data.EventCode) {
                                                        return (
                                                            room.map((roomData, roomKey) => {
                                                                // if (roomData.EventCode === data.EventCode) {
                                                                return (
                                                                    <>
                                                                        <div className="custyle">
                                                                            <table
                                                                                // className="table table-striped custab table-text"
                                                                                className="table custab"
                                                                                cellPadding={5} cellSpacing={5} border={1} width={'100%'} height={65}
                                                                                key={'room_' + roomKey}
                                                                                style={{ marginBottom: 15, tableLayout: 'fixed' }}
                                                                            // title={Locale("click-to-enter-room", this.props.Locale) + " " + roomData.RoomCode}
                                                                            >
                                                                                <tbody>
                                                                                    <tr>
                                                                                        {/* 50 / 20 / 15 / 15 */}
                                                                                        {/* 35 / 25 / 20 / 20 */}
                                                                                        {/* 43 / 23 / 17 / 17 */}
                                                                                        {/* 43 / 23 / 20 / 14 === best ratio */}
                                                                                        <td width={'43%'} rowSpan={String(roomData.FlipbookUrl).length > 0 && roomData.FlipbookUrl !== undefined ? '2' : '0'}
                                                                                        ><b>{Locale("room-code", this.props.Locale)} #{roomKey + 1} ({roomData.RoomCode})</b><br />{roomData.RoomTitle}</td>
                                                                                        <td width={'23%'}><b>{Locale("room-date", this.props.Locale)}</b><br />{roomData.RoomDate}</td>
                                                                                        <td width={'20%'}><b>{Locale("result", this.props.Locale)}</b><br />{roomData.Result === undefined ? '-' : roomData.Result + '%'}</td>
                                                                                        <td width={'14%'}>
                                                                                            {/* <b>View<br />History</b> */}
                                                                                            <span
                                                                                                className={'pointer'}
                                                                                                style={{ color: "blue", }}
                                                                                                onClick={() => this.EnterHistoryRoom(eventKey, roomKey, roomData.RoomCode, roomData.RoomId, roomData.RoomDate_Moment)} key={roomData.RoomId}
                                                                                                title={Locale("click-to-enter-room", this.props.Locale) + " " + roomData.RoomCode}
                                                                                            ><u><b><div dangerouslySetInnerHTML={{ __html: Locale("label-view-history", this.props.Locale) }}></div></b></u></span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {
                                                                                        certData.hasOwnProperty('CertDownloadEnabled') ?
                                                                                            certData.CertDownloadEnabled ?
                                                                                                this.Check_CertDownloadDelayed(data) ? null
                                                                                                    :
                                                                                                    // String(data.EventSharedReportUrl).length > 0 && data.EventSharedReportUrl !== undefined ?
                                                                                                    String(roomData.ExtraUrl).length > 0 && roomData.ExtraUrl !== undefined && roomData.ExtraUrl !== '' ?
                                                                                                        <tr>
                                                                                                            <td colSpan='3' align='center' style={{ padding: 0, }}>
                                                                                                                <span
                                                                                                                    className={'pointer'}
                                                                                                                    style={{ color: "blue", }}
                                                                                                                    onClick={() => window.open(String(roomData.ExtraUrl), '_new')}
                                                                                                                    title={Locale("click-to-view-participant-works", this.props.Locale) + " " + roomData.RoomCode}
                                                                                                                ><u><b>{Locale("participant-submitted-entries", this.props.Locale)}</b></u></span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        : null
                                                                                                : null
                                                                                            : null
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {/* <br /> */}
                                                                    </>
                                                                );
                                                                // }
                                                                // else {
                                                                //     return null;
                                                                // }
                                                            })
                                                        );
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })
                                        }
                                    </div>
                                </div>
                                {/* <div className='row'>
                                <div className='col'>
                                    etc etc
                                </div>
                            </div> */}

                            </Card.Body>
                        </Accordion.Collapse>
                        <Card.Footer
                            as={Card.Footer}
                            style={{
                                backgroundColor: '#00a6ff',
                                color: 'white'
                            }}
                        >
                            <div className='row'>
                                {/* <div className='col'>* Participated on {moment(certData.CreatedOnUtc).format('ll')}</div> */}
                                {/* <div style={{ textAlign: 'right' }}>
                                    <span style={{ fontWeight: 500 }}>Result {certData.Result + '%'}</span>
                                </div> */}
                                <div className='col'>
                                    {
                                        this.props.Locale === Lang.Chinese ?
                                            Locale("label-participated-on-date-1", this.props.Locale)
                                            + moment(certData.CreatedOnUtc).format('ll')
                                            + Locale("label-participated-on-date-2", this.props.Locale) + '。'
                                            :
                                            Locale("label-participated-on-date", this.props.Locale)
                                            + moment(certData.CreatedOnUtc).format('ll') + '.'
                                    }
                                </div>

                            </div>
                        </Card.Footer>
                    </Card>
                    <span id={'ele_event_acc_' + key}>&nbsp;</span>
                </div>
            </>;
        }
    }

    //2021.03.31
    Trigger_Fetch_ParticipatedQuizRoomResults = async () => {
        await this.Fetch_ParticipatedQuizRoomResults();
    }

    //2021.04.02 - revamp
    //2021.03.31
    Fetch_ParticipatedQuizRoomResults = async () => {

        let quizRoomResult = this.state.PEList_QuizRoomResult;
        // let quizRoomResult = [];
        // let roomCount = 0;
        let rooms = [];
        let roomDetails = [];

        for (let key = 0; key < this.state.Participated_Event_List.length; key++) {

            //2021.04.12
            if (this.state.PEList_QuizRoomResult[key].length <= 0) {

                // console.log(JSON.stringify(this.state.Participated_Event_List[key]));

                let eventDetails = this.state.EventDetails.find(x => x.EventCode === this.state.Participated_Event_List[key].EventCode);
                // let eventIndex = this.state.EventDetails.findIndex(x => Array(x).hasOwnProperty('EventCode') ? x.EventCode === this.state.Participated_Event_List[key].EventCode : false);
                // if (eventIndex > -1) {

                // let eventDetails = this.state.Participated_Event_List[eventIndex];

                // console.log(key + ' = ' + JSON.stringify(this.state.Participated_Event_List[key]));
                // console.log(key + ' = ' + JSON.stringify(eventDetails));
                rooms = [];


                let startDate = moment(eventDetails.DateStart + ' 00:00:00');
                let endDate = moment(eventDetails.DateEnd + ' 00:00:00');

                let JoinedDays = 1;
                if (startDate !== endDate) {
                    JoinedDays = endDate.diff(startDate, 'days') + 1;
                }
                if (this.props.isDevMode)
                    console.log(
                        '\nEventCode = ' + eventDetails.EventCode + '\n' +
                        'DateStart = ' + eventDetails.DateStart + '\n' +
                        'DateEnd = ' + eventDetails.DateEnd + '\n' +
                        'JoinedDays = ' + JoinedDays
                    );

                //process per day held by the event.
                for (var i = 0; i < JoinedDays; i++) {

                    let eventDate = i > 0 ?
                        moment(eventDetails.DateStart).add(i, 'days')
                        : moment(eventDetails.DateStart);

                    let quizRoomCodes = [];
                    let joinedRoomResult = [];

                    if (this.props.isDevMode)
                        console.log('eventDate = ' + eventDate.format('YYYYMMDD'));

                    // let pendingFetches = [];

                    //Fetch room code(s).
                    await this.props.dbLiveQuiz
                        .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-room-code')
                        .once('value', (snapshot) => {
                            if (snapshot.exists() && snapshot.val() !== null) {
                                // let snapArray = snapshot.val();
                                // if (snapArray !== null) {
                                let dataArray = [];
                                snapshot.forEach(snap => {
                                    dataArray.push({
                                        RoomId: snap.val(),
                                        RoomCode: snap.key,
                                    });
                                    return false;
                                });
                                quizRoomCodes = dataArray;
                                // }
                            }
                        });

                    //2024.11.14 - backup
                    if (quizRoomCodes.length === 0) {
                        for (let a = 0; a < eventDetails.QuizRooms.length; a++) {
                            for (let b = 0; b < eventDetails.QuizRooms[a].Rooms.length; b++) {
                                quizRoomCodes.push({
                                    RoomId: String(eventDetails.QuizRooms[a].Rooms[b].RoomTimeStampId),
                                    RoomCode: String(eventDetails.QuizRooms[a].Rooms[b].RoomCode),
                                });
                            }
                        }
                    }

                    // //before filter
                    // if (this.props.isDevMode)
                    //     console.log('\n= before filter =\nquizRoomCodes = ' + JSON.stringify(quizRoomCodes));

                    //find the room codes of the event.
                    let tempRoomCodes = [];
                    for (let a = 0; a < eventDetails.QuizRooms.length; a++) {
                        for (let b = 0; b < eventDetails.QuizRooms[a].Rooms.length; b++) {
                            for (let c = 0; c < quizRoomCodes.length; c++) {
                                if (String(eventDetails.QuizRooms[a].Rooms[b].RoomCode) === String(quizRoomCodes[c].RoomCode))
                                    tempRoomCodes.push(quizRoomCodes[c]);
                            }
                            // tempRoomCodes.push(eventDetails.QuizRooms[a].Rooms[b].RoomCode);
                        }
                    }
                    // if (this.props.isDevMode)
                    //     console.log('tempRoomCodes = ' + JSON.stringify(tempRoomCodes));

                    //filter the room codes based on room codes found in event.
                    // quizRoomCodes = quizRoomCodes.filter(function (x) { return x.findIndex(k => k.Room) < 0; }, tempRoomCodes);
                    quizRoomCodes = tempRoomCodes;

                    //after filter
                    if (this.props.isDevMode)
                        console.log('quizRoomCodes = ' + JSON.stringify(quizRoomCodes));

                    //Fetch personal result(s).
                    await this.props.dbLiveQuiz
                        .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-personal-data/'
                            // + '08QRy0oxKgf17Svlg3kuDhodgoA3' //testing
                            + this.props.user.uid
                        )
                        .once('value', (snapshot) => {
                            if (snapshot.exists && snapshot.val() !== null) {
                                // let snapArray = snapshot.val();
                                // if (snapArray !== null) {
                                let dataArray = [];
                                snapshot.forEach(snap => {
                                    let snapValue = snap.val();
                                    dataArray.push({
                                        // RoomId: snap.key,
                                        // ASelects: snapValue.ASelects,
                                        // IsDone: snapValue.IsDone,
                                        // QResults: snapValue.QResults,
                                        // ScoreRecord: snapValue.ScoreRecord,

                                        //2021.12.06 - revamp/add items.
                                        RoomId: snap.key,
                                        ASelects: snapValue.hasOwnProperty('ASelects') ? String(snapValue.ASelects) : '',
                                        IsDone: snapValue.hasOwnProperty('IsDone') ? CheckBoolean(snapValue.IsDone) : false,
                                        QResults: snapValue.hasOwnProperty('QResults') ? String(snapValue.QResults) : '',
                                        ScoreRecord: snapValue.hasOwnProperty('ScoreRecord') ? String(snapValue.ScoreRecord) : '',
                                        //new items: Document Room.
                                        CertSN: snapValue.hasOwnProperty('CertSN') ? String(snapValue.CertSN) : '',
                                        FileUploadedDate: snapValue.hasOwnProperty('FileUploadedDate') ? String(snapValue.FileUploadedDate) : '',
                                        Participant: snapValue.hasOwnProperty('Participant') ? String(snapValue.Participant) : '',
                                        SchoolName: snapValue.hasOwnProperty('SchoolName') ? String(snapValue.SchoolName) : '',
                                        Score: snapValue.hasOwnProperty('Score') ? Number(snapValue.Score) : 0,
                                        ScoreIssuedDate: snapValue.hasOwnProperty('ScoreIssuedDate') ? String(snapValue.ScoreIssuedDate) : '',
                                        Uid: snapValue.hasOwnProperty('Uid') ? String(snapValue.Uid) : '',
                                        UploadedFileName: snapValue.hasOwnProperty('UploadedFileName') ? String(snapValue.UploadedFileName) : '',
                                        //Room indication - new
                                        RoomType: snapValue.hasOwnProperty('ASelects') ? 0 : 1,
                                    });
                                    return false;
                                });
                                // console.log('snapArray = ' + JSON.stringify(snapArray));
                                // let dataArray = snapArray;
                                // let dataArray = snapArray[0];
                                // dataArray.RoomId = snapArray[0].key;

                                joinedRoomResult = dataArray;
                                // }
                                // console.log('snapArray = ' + JSON.stringify(snapArray));
                            }
                        });

                    //debug
                    // console.log('quizRoomCodes = ' + JSON.stringify(quizRoomCodes));
                    // console.log('joinedRoomResult = ' + JSON.stringify(joinedRoomResult));
                    // console.log(joinedRoomResult.length);
                    if (this.props.isDevMode)
                        console.log('joinedRoomResult (count) = ' + joinedRoomResult.length);

                    if (joinedRoomResult.length > 0) {
                        //process per room within the day.
                        for (var jrkey = 0; jrkey < joinedRoomResult.length; jrkey++) {

                            let data = joinedRoomResult[jrkey];
                            // console.log('joinedRoomResult = Room Id = ' + data.RoomId);

                            //2021.04.17
                            let roomIndex = quizRoomCodes.findIndex(x => String(x.RoomId) === String(data.RoomId));
                            if (roomIndex > -1) {

                                // let roomCode = quizRoomCodes.find(x => x.RoomId === data.RoomId).RoomCode;
                                // let scoreResult = String(data.ScoreRecord).split(';')[0];
                                let roomTitle = '';
                                let roomDetail = [];

                                //2021.12.06
                                let roomCode = String(quizRoomCodes[roomIndex].RoomCode);
                                let scoreRecord = data.ScoreRecord !== undefined ?
                                    String(data.ScoreRecord).includes(';') ?
                                        String(data.ScoreRecord).split(';')[0]
                                        : String(data.ScoreRecord)
                                    : '0';
                                let scoreResult =
                                    data.RoomType === 0 ?
                                        scoreRecord.includes('.') ?
                                            scoreRecord
                                            : scoreRecord + '.00'
                                        :
                                        data.RoomType === 1 ?
                                            String(data.Score).includes('.') ?
                                                String(data.Score)
                                                : String(data.Score) + '.00'
                                            : undefined;

                                //Fetch room detail.
                                const roomDetailIndex = roomDetails.findIndex(x => String(x.RoomId) === String(data.RoomId));   //2023.11.15
                                if (roomDetailIndex > 0) {
                                    roomDetail = roomDetails[roomDetailIndex];
                                }
                                else {
                                    await this.props.dbLiveQuiz
                                        .ref('pkquiz/' + eventDate.format('YYYYMMDD') + '/pkquiz-room-detail/' + data.RoomId)
                                        .once('value', (snapshot) => {
                                            if (snapshot.exists && snapshot.val() !== null) {
                                                roomDetail = snapshot.val();
                                                roomDetails.push(roomDetail);   //2023.11.15
                                            }
                                        });
                                }
                                //Sub-location for Room Title. //2023.11.15 - obsolete.
                                if (CheckObjectNullValue(roomDetail, 'RoomTitle') === null) {
                                    await this.props.dbQuestion
                                        .ref('LiveQuiz/' + roomDetail.Subject + '/' + roomDetail.Grade + '/' + roomDetail.QnSet + '/RoomTitle')
                                        .once('value', (snapshot) => {
                                            if (snapshot.exists && snapshot.val() !== null) {
                                                roomTitle = snapshot.val();
                                            }
                                        });
                                }
                                else {
                                    roomTitle = CheckObjectStringEmpty(roomDetail, 'RoomTitle', '-');
                                }

                                //filter room title for the last time.
                                roomTitle = CheckStringEmpty(roomTitle, '-');
                                // roomTitle = roomTitle.length > 0 && roomTitle !== undefined ? roomTitle : '-';

                                //debug
                                // console.log('room id = ' + data.RoomId);
                                // console.log('room code = ' + roomCode);
                                // console.log('score = ' + scoreResult);
                                // console.log('room title = ' + roomTitle);

                                //set to array.
                                rooms.push({
                                    RoomCode: roomCode,
                                    RoomId: data.RoomId,
                                    RoomTitle: roomTitle,
                                    // Result: scoreResult === undefined ? '0.00' : scoreResult,
                                    Result: scoreResult,
                                    RoomDate: eventDate.format('ll'),
                                    RoomDate_Moment: eventDate,
                                    EventCode: eventDetails.EventCode,

                                    //2021.12.06
                                    //Room indication - new
                                    RoomType: Number(data.RoomType),
                                    //new items: Document Room.
                                    CertSN: String(data.CertSN),
                                    FileUploadedDate: String(data.FileUploadedDate),
                                    Participant: String(data.Participant),
                                    SchoolName: String(data.SchoolName),
                                    Score: String(data.Score),
                                    ScoreIssuedDate: String(data.ScoreIssuedDate),
                                    Uid: String(data.Uid),
                                    UploadedFileName: String(data.UploadedFileName),

                                    //2021.12.09
                                    ExtraUrl: '',   //for Flipbook.
                                });

                                //2021.12.09
                                //find the room details for this room code / room Id
                                let thisRoom = null;
                                for (let a = 0; a < eventDetails.QuizRooms.length; a++) {
                                    for (let b = 0; b < eventDetails.QuizRooms[a].Rooms.length; b++) {
                                        for (let c = 0; c < quizRoomCodes.length; c++) {
                                            if (String(eventDetails.QuizRooms[a].Rooms[b].RoomCode) === roomCode)
                                                thisRoom = eventDetails.QuizRooms[a].Rooms[b];
                                        }
                                    }
                                }
                                if (thisRoom !== null) {
                                    if (thisRoom.hasOwnProperty('ExtraUrl'))
                                        if (String(thisRoom.ExtraUrl).length > 0 && thisRoom.ExtraUrl !== undefined && thisRoom.ExtraUrl !== '')
                                            rooms[rooms.length - 1].ExtraUrl = String(thisRoom.ExtraUrl);
                                }

                                //debug
                                // console.log('Room = ' + JSON.stringify(rooms));
                            }
                        }
                        //room data assigned.
                    }
                    //debug
                    // roomCount += joinedRoomResult.length;
                    // console.log('roomCount = ' + roomCount);
                }
                //per day ends.
                // }
                //update array.
                quizRoomResult[key] = rooms;

                //update loading state.
                let _PEList_QuizRoomResult_isLoading = this.state.PEList_QuizRoomResult_isLoading;
                _PEList_QuizRoomResult_isLoading[key] = false;
                this.setState({
                    // PEList_isLoaded: true,
                    // PEList_isLoading: false,

                    PEList_QuizRoomResult: quizRoomResult,
                    PEList_QuizRoomResult_isLoading: _PEList_QuizRoomResult_isLoading,
                    Participated_Event_Counter: this.state.Participated_Event_Counter + 1,
                });
            }

        }

        //debug
        if (this.props.isDevMode) {
            console.log('loop ends');
            console.log('quizRoomResult = ' + JSON.stringify(quizRoomResult));
        }
        //update array.
        // this.setState({
        //     PEList_QuizRoomResult: quizRoomResult,
        // });

        this.setState({
            PEList_isLoaded: true,
            PEList_isLoading: false,
            PEList_More_isLoaded: true,
            PEList_More_isLoading: false,
        });

        // setTimeout(() => {
        //     // ScrollToElement('bottomPage', 500);
        //     ScrollToElement('event_div_acc_0', 500);
        // }, 1000);
    }

    //2021.04.02 - similar function in QuizHistoryList.
    EnterHistoryRoom = async (eventKey, roomKey, roomCode, roomId, roomDate_Moment) => {
        if (this.props.isInternetReachable === false) {
            this.props.ShowInternetNotAvailableAlert();
            return null;
        }

        this.props.SetAlertWithProgressBar(Locale("entering-room", this.props.Locale) + (roomKey + 1) + " (" + roomCode + ")...",
            Locale("please-wait", this.props.Locale), true);

        //Update Room Code & Room Id.
        this.props.setRoomCodeId(roomCode, roomId);

        //Check if Viewing history Quiz.
        if (moment().format("YYYYMMDD") > roomDate_Moment.format("YYYYMMDD"))
            this.props.SetViewHistoryQuiz(roomDate_Moment.format("YYYYMMDD"));
        else
            this.props.SetViewHistoryQuiz(null);

        // alert(moment().toString() + "\n\n" + quizDate.toString() + "\n\n" + (moment() > quizDate));

        //2021.12.06
        let roomLink = '';
        let findIndex = -1; //this.state.PEList_QuizRoomResult.findIndex(x => String(x.RoomCode) === String(roomCode) && String(x.RoomId) === String(roomId));
        let room_found = null;
        let list = this.state.PEList_QuizRoomResult;
        if (list.length > 0) {
            list.forEach(rooms => {
                if (rooms.length > 0) {
                    rooms.forEach(room => {
                        if (String(room.RoomCode) === String(roomCode) && String(room.RoomId) === String(roomId))
                            room_found = room;
                    });
                }
            });
        }
        if (room_found !== null) {
            if (Number(room_found.RoomType) === 0)        //Basic Room.
                roomLink = '/quiz/live';
            else if (Number(room_found.RoomType) === 1)   //Document Room.
                roomLink = '/quiz/roomTypeUploadFile';
            else
                this.props.CloseAlert();

            if (this.props.isDevMode)
                console.log('findIndex = ' + findIndex + '\nroom link =' + roomLink + '\nRoom = \n' + JSON.stringify(room_found));
        }
        if (roomLink !== '') {
            setTimeout(() => {
                this.props.CloseAlert();
                this.setState({
                    redirectLink: roomLink,
                    redirect: true,
                });
            }, 1000);
        }
    }

    //disabled - position unstable due to inconsistent screen width - 2021.04.14
    // //2021.04.06
    // GetEventCountText = () => {
    //     let endText = '';
    //     switch (this.props.Locale) {
    //         default: endText = 'event' + (this.state.Participated_Event_List.length > 1 ? 's' : ''); break;
    //         case Lang.Chinese: endText = '个活动'; break;
    //         case Lang.Malay: endText = 'acara'; break;
    //     };
    //     return this.state.Participated_Event_List.length + ' ' + endText;
    // }

    render = () => {
        if (this.props.user === null || this.props.user === undefined) {     //2021.04.12
            return <Redirect to='/' />;
        }
        // else if (this.props.isFromParentApp === false) {     //2021.04.12
        //     this.props.SetAlert(
        //         'Accessible only in <b>iKEY</b> App',
        //         'Please install <b>iKEY</b> app in order to view all <u>participated events</u>.'
        //     );
        //     return <Redirect to='/home' />;
        // }
        else if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <div style={{
                        backgroundColor: 'transparent',
                        minHeight: '100%',
                        width: '100%',
                        position: 'absolute',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 0,
                        paddingTop: window.innerWidth < 576 ? 5 : 25,
                    }}>
                        <div className="container" style={{
                            maxWidth: '650px', backgroundColor: 'lavender', borderRadius: 5,
                            paddingTop: 15, paddingBottom: 15,
                        }}>
                            <div className="row">
                                <aside className="col-sm-12">

                                    {/* <p style={{
                                    color: 'black', fontSize: '1.2rem', textAlign: 'center', fontWeight: 'bold',
                                }}>iKEY Live Quiz</p> */}

                                    <div className="card" style={{ backgroundColor: '#007bff' }}>
                                        <article className="card-body text-center">

                                            {/* <span style={{
                                            color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                            float: "left"
                                        }}>{Locale("quiz-result", this.props.Locale)}</span> */}

                                            <span style={{
                                                color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                float: "left"
                                            }}>Participated Events</span>

                                            <LocalizationSwitcher
                                                Locale={this.props.Locale}
                                                SetLocaleSetting={this.props.SetLocaleSetting}
                                            />
                                        </article>
                                    </div>

                                    <div className="card">
                                        <article className="card-body">
                                            <div className='row'>
                                                <div className='col'>
                                                    <img src={PE_Banner_Count} alt="Participated Quiz Events" width={'100%'} />
                                                    {/* <span
                                                    className="text-in-banner-pe"
                                                    hidden={this.state.PEList_isLoading}
                                                >{this.GetEventCountText()}</span> */}
                                                </div>
                                            </div>
                                            <br />
                                            <div className='row'>
                                                <div className='col'>
                                                    <button type="button" className="btn btn-primary btn-block"
                                                        onClick={() => this.Goto('/home')}
                                                    >{Locale("back-to-home", this.props.Locale)}</button>
                                                </div>
                                            </div>
                                            <br />
                                            {/* 2024.11.20 - disabled by Calvin request. */}
                                            {/* <div className='row'>
                                                <div className='col'>
                                                    <button type="button" className="btn-link"
                                                        //2024.11.20 - disabled by Calvin request.
                                                        // onClick={() => window.open(downloadPdfGuidUrl, '_new')}
                                                        disabled={true}
                                                        style={{ width: '100%' }}
                                                    >
                                                        <table cellSpacing='0' cellPadding='5' border='0' width='100%'>
                                                            <tbody>
                                                                <tr>
                                                                    <td align='right' width='30%'>
                                                                        <img src={Icon_Cert_Hat} alt='' width={'35px'} />
                                                                    </td>
                                                                    <td align='left'>
                                                                        <div dangerouslySetInnerHTML={{ __html: Locale("label-notice-participated-event-innerpagebtn", this.props.Locale) }} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </button>
                                                </div>
                                            </div> */}
                                            <table cellPadding='5' cellSpacing='0' border='0' width='100%'>
                                                <tbody>
                                                    {/* <tr>
                                                    <td width='10%' valign='top' align='right'>
                                                        <span style={{ fontSize: 14, color: 'gray' }}>* </span>
                                                    </td>
                                                    <td>
                                                        <span style={{ fontSize: 14, color: 'gray' }}>Certificates are opened temporarily to download via this website, remember to <b><i><u>whitelist this website</u></i></b> if using any popup blocker plugin like AdBlock, or browsers with builtin popup blocker. If still unable to download via this website, do update your browser to latest version & try again, or try again with iKEY app instead, there may have some issues to your browser settings (e.g. whitelist this website, or other unknown reasons), as the download feature are tested working fine using latest Microsoft Edge, Google Chrome, and Mozilla Firefox browsers.</span>
                                                    </td>
                                                </tr> */}
                                                    {/* <tr>
                                                    <td width='10%' valign='top' align='right'>
                                                        <span style={{ fontSize: 14, color: 'gray' }}>* </span>
                                                    </td>
                                                    <td>
                                                        <span style={{ fontSize: 14, color: 'gray' }}>Certificates are opened temporarily to download via this website, remember to <b><i><u>whitelist this website</u></i></b> if using any popup blocker plugin like AdBlock, or browsers with builtin popup blocker.</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width='10%' valign='top' align='right'>
                                                        <span style={{ fontSize: 14, color: 'gray' }}>* </span>
                                                    </td>
                                                    <td>
                                                        <span style={{ fontSize: 14, color: 'gray' }}>If still unable to download via this website, do update your browser to the latest version & try again, or download via iKEY app instead, as this may caused by how your browser settings are set (e.g. haven't whitelist this website, or other unknown reasons), as the download feature are tested working fine using latest Microsoft Edge, Google Chrome, and Mozilla Firefox browsers.</span>
                                                    </td>
                                                </tr> */}
                                                    <tr>
                                                        <td width='10%' valign='top' align='right'>
                                                            <span style={{ fontSize: 14, color: 'gray' }}>* </span>
                                                        </td>
                                                        <td>
                                                            <span style={{ fontSize: 14, color: 'gray' }}>
                                                                {/* <div
                                                                dangerouslySetInnerHTML={{ __html: this.PopupReminderText() }}
                                                                onClick={this.PopupReminderTextCallback}
                                                            /> */}
                                                                {this.PopupReminderComponent()}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </article>
                                    </div>

                                    <span>&nbsp;</span>

                                    {/* <div className="card">
                                    <article className="card-body">

                                        Participated Event List

                                        <button type="button" className="btn btn-primary btn-block"
                                            onClick={this.DownloadCertPDF}
                                        >Download<br />Certificate (PDF)</button>
                                    </article>
                                </div>

                                <span>&nbsp;</span> */}

                                    {
                                        this.state.PEList_isLoading ?
                                            <div className="card">
                                                <article className="card-body" id="top">
                                                    <LoadingIndicator />
                                                </article>
                                            </div>
                                            :
                                            this.state.PEList_isLoaded ?
                                                <>
                                                    <div className="card">
                                                        <article className="card-body" id="top">

                                                            {
                                                                this.state.Participated_Event_List.length > 0 ?
                                                                    <Accordion defaultActiveKey='0'>
                                                                        {
                                                                            // this.state.Participated_Event_List.length > 0 ?
                                                                            this.state.Participated_Event_List.map((data, key) => {
                                                                                return this.GetComponent_ParticipatedEventList(data, key);
                                                                                // if (this.state.PEList_QuizRoomResult[key].length > 0)
                                                                                //     return this.GetComponent_ParticipatedEventList(data, key);
                                                                                // else
                                                                                //     return null;

                                                                                // //2022.06.22
                                                                                // const event = this.state.EventDetails.find(x => x.EventCode === String(data.EventCode));
                                                                                // if (this.Check_CertDownloadDelayed(event)) {
                                                                                //     if (this.state.Participated_Event_List_Toggle[key])
                                                                                //         this.SetToggleState(key)
                                                                                //     else
                                                                                //         this.SetToggleState_ON(key);
                                                                                // }
                                                                                // return this.GetComponent_ParticipatedEventList(data, key);
                                                                            })
                                                                            // :
                                                                            // <LoadingIndicator />
                                                                        }
                                                                    </Accordion>
                                                                    : null
                                                            }

                                                            {
                                                                //2024.11.20 - disabled by Calvin request.
                                                                // this.props.isFromParentApp ?
                                                                //     null :
                                                                //     <>
                                                                //         <span style={{ fontSize: 12, }}><div dangerouslySetInnerHTML={{ __html: "&nbsp;&nbsp;&nbsp;" + Locale("notice-pe-list-limit-5", this.props.Locale) }}></div></span>
                                                                //         <br />
                                                                //     </>
                                                            }

                                                            {/* {this.state.PEList_QuizRoomResult.length} */}
                                                            {
                                                                this.state.PEList_isLoaded
                                                                    && !this.state.PEList_More_isLoading
                                                                    && this.state.PEList_QuizRoomResult.length <= 0
                                                                    ?
                                                                    <span>{Locale("no-event-found", this.props.Locale)}</span>
                                                                    :
                                                                    // //2021.04.12
                                                                    // !this.state.PEList_isLoading && !this.state.PEList_More_isLoading ?
                                                                    //     this.state.Participated_Event_Counter <= this.state.Participated_Event_List.length ?
                                                                    //         null :
                                                                    //         <button type="button" className="btn btn-primary btn-block"
                                                                    //             onClick={this.LoadMore_ParticipatedEventList}
                                                                    //         // disabled={this.state.Participated_Event_Counter <= this.state.Participated_Event_List.length}
                                                                    //         >{Locale("label-load-more", this.props.Locale)}</button>
                                                                    //     : <LoadingIndicator />

                                                                    //hide for now - 2022.06.09
                                                                    null
                                                                // //2021.09.10
                                                                // this.state.PEList_More_isLoaded && this.state.PEList_More_isLoading ?
                                                                //     <LoadingIndicator />
                                                                //     :
                                                                //     this.state.Participated_Event_Counter < this.state.Participated_Event_List.length ?
                                                                //         null
                                                                //         :
                                                                //         <button type="button" className="btn btn-primary btn-block"
                                                                //             onClick={this.LoadMore_ParticipatedEventList}
                                                                //         // disabled={this.state.Participated_Event_Counter <= this.state.Participated_Event_List.length}
                                                                //         >{Locale("label-load-more", this.props.Locale)}</button>
                                                            }

                                                            {
                                                                // this.state.EventDetails.length > 0 ?
                                                                //     this.state.EventDetails.map((data, key) => {
                                                                //         return (<>
                                                                //             <div className='row' key={'event_' + key}>
                                                                //                 <div className='col'>
                                                                //                     {data.CreatedDate}<br />
                                                                //                     {data.EventCode}
                                                                //                 </div>
                                                                //             </div>
                                                                //         </>);
                                                                //     })
                                                                //     : null
                                                            }
                                                        </article>
                                                    </div>
                                                    {/* <span>&nbsp;</span> */}
                                                    {/* <div className="card">
                                                        <article className="card-body">
                                                            {
                                                                //2021.04.12
                                                                !this.state.PEList_isLoading && !this.state.PEList_More_isLoading ?
                                                                    <button type="button" className="btn btn-primary btn-block"
                                                                        onClick={this.LoadMore_ParticipatedEventList}
                                                                    >Load More</button>
                                                                    : <LoadingIndicator />
                                                            }
                                                        </article>
                                                    </div> */}
                                                </>
                                                : null

                                    }

                                    <span id={'bottomPage'}>&nbsp;</span>

                                </aside>
                            </div>
                        </div>

                        <div style={{ height: '200px', width: '100%' }}>
                            <span>&nbsp;</span>
                        </div>
                    </div>

                    {/* 2021.09.03 */}
                    <Modal show={this.state.ShowPopupBlockerStepsModal}
                        onHide={this.Toggle_PopupBlockerStepsModal}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="steps-top">Steps to disable Pop-up Blockers in Most Common Internet Browsers</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <a href="#section-edge">Edge®</a><br />
                            <a href="#section-ie">Internet Explorer®</a><br />
                            <a href="#section-ch">Chrome®</a><br />
                            <a href="#section-ff">Firefox®</a><br />
                            <a href="#section-sf">Safari®</a><br />

                            <hr id="section-edge" />
                            <h5>Edge®</h5>
                            Take the following steps to disable pop-up blockers:<br />
                            <ol style={{ listStyleType: 'decimal' }}>
                                <li>Click <u>Tools</u> or the gear icon.</li>
                                <li>Click <u>Settings</u>.</li>
                                <li>Click left pane's <u>Cookies and site permissions</u>.</li>
                                <li>Scroll down & find <u>Pop-ups and redirects</u>.</li>
                                <li>At the <u>Allow</u> section, click <u>Add</u>.</li>
                                <li>Enter the address of this website (e.g. livequiz.ikeyedutech.com.my).</li>
                                <li>Click <u>Add</u>.</li>
                            </ol>
                            {/* <a href="#steps-top">top</a> */}
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <a href="#steps-top">top</a>
                            </div>

                            <hr id="section-ie" />
                            <h5>Internet Explorer®</h5>
                            Take the following steps to disable pop-up blockers:<br />
                            <ol style={{ listStyleType: 'decimal' }}>
                                <li>Click <u>Tools</u> or the gear icon.</li>
                                <li>Click <u>Internet options</u>.</li>
                                <li>Click the <u>Privacy</u> tab.</li>
                                <li>Uncheck <u>Turn on Pop-up Blocker</u>.</li>
                                <li>Click <u>OK</u>.</li>
                            </ol>
                            {/* <a href="#steps-top">top</a> */}
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <a href="#steps-top">top</a>
                            </div>

                            <hr id="section-ch" />
                            <h5>Chrome®</h5>
                            Take the following steps to disable pop-up blockers:<br />
                            <ol style={{ listStyleType: 'decimal' }}>
                                <li>Click the <u>Chrome menu</u> button.<br />
                                    <u>Note:</u> The button is on the upper-right of the browser and is indicated by three dots.</li>
                                <li>Select <u>Settings</u>.</li>
                                <li>At the bottom of the page, click <u>Advanced</u>.<br />
                                    <u>Note:</u> If Advanced is already selected, additional options will be available below it.</li>
                                <li>Under <u>Privacy and security</u>, click <u>Site settings</u>.</li>
                                <li>Click <u>Pop-ups and redirects</u>.</li>
                                <li>Click <u>Blocked (recommended)</u>.<br />
                                    <u>Note:</u> After the popup blocker is disabled, the option will show as enabled and display <u>Allowed</u>.</li>
                            </ol>
                            {/* <a href="#steps-top">top</a> */}
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <a href="#steps-top">top</a>
                            </div>

                            <hr id="section-ff" />
                            <h5>Firefox®</h5>
                            Take the following steps to disable pop-up blockers:<br />
                            <ol style={{ listStyleType: 'decimal' }}>
                                <li>Click the <u>Open menu</u> button (three bars) in the upper-right corner.</li>
                                <li>Click <u>Options</u> or <u>Preferences</u>.</li>
                                <li>Select <u>Privacy & Security</u> on the left.</li>
                                <li>Uncheck <u>Block pop-up windows</u> to disable the pop-up blocker.</li>
                                <li>Close and relaunch Firefox.</li>
                            </ol>
                            {/* <a href="#steps-top">top</a> */}
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <a href="#steps-top">top</a>
                            </div>

                            <hr id="section-sf" />
                            <h5>Safari®</h5>
                            Take the following steps to disable pop-up blockers:<br />
                            <ol style={{ listStyleType: 'decimal' }}>
                                <li>Click the <u>Safari</u> menu.</li>
                                <li>Select <u>Preferences</u> from the drop-down.</li>
                                <li>Click <u>Websites</u>.</li>
                                <li>Click <u>Pop-up Windows</u> located on the left side.</li>
                                <li>Click the drop-down next to <u>iKEY Live Quiz</u> and select <u>Allow</u>.</li>
                                <li>Close and reopen Safari.</li>
                            </ol>
                            iPhone or iPad IOS:<br />
                            <ol style={{ listStyleType: 'decimal' }}>
                                <li>Open the <u>Settings</u> app in iOS and go to <u>Safari</u>.</li>
                                <li>Under the <u>General Safari</u> settings, toggle the switch next to “<u>Block Pop-ups</u>” to the <u>OFF</u> position to disable the popup blocker.</li>
                            </ol>
                            {/* <a href="#steps-top">top</a> */}
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <a href="#steps-top">top</a>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.Toggle_PopupBlockerStepsModal}
                            >Close</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* 2021.09.03 */}
                    <Modal show={this.state.ShowPopupErrorModal}
                        onHide={() => this.setState({ ShowPopupErrorModal: false })}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="steps-top">Download Failed.</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Download has been blocked by your browser.<br />Please whitelist this website & try again.<br /><br />
                            <u>Reminder:</u><br />Please ensure that Pop-up Blocker is disabled on your device before download.<div style={{ height: 15 }} />
                            Click <button type="button" className="btn-link" onClick={this.Toggle_PopupBlockerStepsModal}
                            >here</button> to check the steps to disable Pop-up blockers in the most common internet browsers.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ ShowPopupErrorModal: false })}
                            >Close</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            );
        }
    }
}