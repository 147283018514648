import React from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
// import moment from 'moment';
import '../../Admin/PageStyle.scss';
import '../../Admin/LiveQuizAdminPortal.scss';
// import { Locale, Lang } from '../../Localization/CustomLocalization.js';
// import { GlobalSetting, Toggle } from '../../../components/GlobalSetting';
// import { Delay, DelayUntil, GetPropIds } from '../../../components/GlobalFunctions';

//2023.09.11
export default class ManageStudentReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        redirect: false,
        redirectLink: '',
    });

    componentDidMount = () => {
        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }
        window.scrollTo(0, 0);
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <>
                    <Row className='rowStyle'>
                        <Col>
                            <span style={{ fontSize: 20, fontWeight: 'bold' }}>Manage Student Report</span>
                        </Col>
                        <Col className='colBtn'>
                            <button type="button" className="btn btn-outline-primary"
                                onClick={this.ToggleUploadStudentProfileModel}
                            >Upload Profile</button>
                        </Col>
                    </Row>
                    <Row className='rowStyle'>
                        <Col>
                            student profile table list.
                        </Col>
                    </Row>
                </>
            );
        }
    }
}