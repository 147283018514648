import React from 'react';
// import { Redirect } from 'react-router-dom';
import { Col, Row, Modal, Button, ProgressBar, DropdownButton, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import './PageStyle.scss';
import { Locale } from '../../Localization/CustomLocalization.js';
import LoadingIndicator from '../Quiz/LoadingIndicator';
import QuizBankManageRoom from './QM_ManageRoom';
import { GlobalSetting, Toggle, PermissionAccessType } from '../../components/GlobalSetting';
import { Delay, DelayUntil, BlockInvalidPermissionFeatureAccess, GetPropIds, CheckBoolean } from '../../components/GlobalFunctions';      //2023.09.11
import _ from 'lodash';

//2021.11.23
const CertLayoutTypeOptions = [
    { value: 1, label: 'Type 1 (Participant Name only)', name: 'basic', subLabel: 'Type 1' },
    { value: 2, label: 'Type 2 (Participant Name, Serial Number, School Name)', name: 'basic', subLabel: 'Type 2' },
    { value: 3, label: 'Type 3 (Participant Name, Serial Number)', name: 'basic', subLabel: 'Type 3' },
    { value: 4, label: 'Type 4 (Participant Name, School Name)', name: 'basic', subLabel: 'Type 4' }
];

//2021.11.25
const PublishModeOptions = [
    { value: 0, label: 'Mode 0 - Admin, Testing (Not visible to anyone except Admin)', name: 'admin', subLabel: 'Admin, Testing', role: 'Admin' },
    { value: 1, label: 'Mode 1 - Public (Global, visible in Event list by everyone)', name: 'global', subLabel: 'Public', role: 'Global' },
    { value: 2, label: 'Mode 2 - Private (Visible only in Event list via Organizer domain)', name: 'private', subLabel: 'Private', role: 'Private' }
];

//2021.12.09
const FileMode = {
    None: 0,
    CertImg: 1,
    PAJSK: 2,
    CertImgTop100: 3,   //2022.09.30
    CertImgHonor: 4,    //2022.09.30
};
const UploadState = {
    None: 0,
    Browse: 1,
    Validation: 2,
    Uploading: 3,
    Failed: 4,
    Success: 5,
};

//2021.06.30
export default class QuizBankManageEvent extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.

        this.SearchRoom_ByRoomCode_InputRef = React.createRef();
        this.ManageRoomRef = React.createRef();
        this.TooltipRef_1 = React.createRef();
        this.TooltipRef_2 = React.createRef();
    }

    getInitState = () => ({

        PA_View: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageEvent, PermissionAccessType.View),
        PA_Search: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageEvent, PermissionAccessType.Search),
        PA_Create: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageEvent, PermissionAccessType.Create),
        PA_Update: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageEvent, PermissionAccessType.Update),
        PA_Delete: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageEvent, PermissionAccessType.Delete),
        PA_Upload: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageEvent, PermissionAccessType.Upload),
        PA_Download: !BlockInvalidPermissionFeatureAccess(this.props, Toggle.ManageEvent, PermissionAccessType.Download),

        EventList: [],
        EventList_lastVisible: [],
        IsEventListLoaded: false,
        ListMaxQueryQty: '10',     //2021.11.17

        ShowDeleteEventModal: false,
        ShowViewEventModal: false,
        TargetEventIndex: -1,
        TargetEventModal: [],
        TargetEventRooms: [],
        TargetEventIsEnded: false,

        //2021.11.22
        ToggleEditEvent: false,
        TargetEventEditedModal: null,
        IsCreateNewEvent: false,        //2021.11.24
        New_EventCode_isValid: false,   //2021.11.25
        IsEventEditing: false,  //2023.05.11

        //2021.10.06
        ShowEditEventRoomModal: false,
        EditEventRoomState: 0,
        SelectedEventRoom: null,
        EditEventRoomState_RoomCode_Options: 0,
        SearchRoom_ByRoomCode: '',
        SearchRoom_ResultList: [],
        // SearchRoom_Result_SelectedRoom: null,
        SearchRoom_ResultList_Completed: false,
        IsEventRoomStateDirty: false,   //2021.11.25

        //2022.10.26
        EditEventRoomState_Idx: -1,
        EditModal_EventRoom_Subject: false,

        //2021.10.12
        Temp_TargetEventRooms: [],
        Prev_EventIndex: -1,

        //2021.12.09
        ToggleFileUploadUi: false,
        UploadFileMode: FileMode.None,
        UploadState_CertImage: UploadState.Browse,
        UploadState_PAJSK: UploadState.Browse,
        FileToUpload: null,
        UploadErrorMessage: [],

        //2022.10.06
        TargetEventGroups: [],
        ShowEditEventGroupModal: false,
        Cache_TargetEventModal: [],
    });

    componentDidMount = async () => {

        if (this.props.user === null) {
            this.setState({
                redirectLink: '/',
                redirect: true,
            });
            return null;
        }

        await this.LoadSavedListMaxQueryQtySetting();     //2021.11.17

        if (this.state.EventList.length <= 0) {
            // var events = this.props.Events;
            // events.sort((a, b) => moment(b.DateStart) - moment(a.DateStart));
            // this.setState({ EventList: events });

            //2021.10.06
            await DelayUntil(() => this.props.OrganizerInfoIsLoaded === true);
            this.LoadEventList();
        }
    }

    DoNothing = () => { }

    ScrollTo = (elementName) => {
        document.getElementById(elementName).scrollIntoView();
    }

    GetValue = (state, name) => {
        if (state === undefined || state === null)
            return '';

        if (state[name] === undefined || state[name] === null)
            return '';

        if (name === 'Group') {
            let findIndex = this.props.GroupOptions.findIndex(x => x.value === state[name]);
            if (findIndex > -1)
                return this.props.GroupOptions[findIndex].label;
        }
        else if (name === 'GroupId') {
            let findIndex = this.props.GroupOptions.findIndex(x => x.id === state[name]);
            if (findIndex > -1)
                return this.props.GroupOptions[findIndex].label;
        }
        else if (name === 'TimeLimit') {
            let splits = String(state[name]).split(':');
            let hr = Number(splits[0]);
            let min = Number(splits[1]);
            return (hr > 1 ? (hr + ' hrs ') : hr > 0 ? (hr + ' hr ') : '')
                + (min > 1 ? (min + ' mins') : min > 0 ? (min + ' min') : '');
        }
        // else if (name === 'Subject') {
        //     if ((state[name] === undefined || state[name] === 'TBA') && state['SubjectName'] !== undefined)
        //         return state['SubjectName'];
        // }
        return state !== null ? (String(state[name]).length > 0 ? state[name] : '-') : '';
    }

    ValueLabel = (value = '') => {
        return String(value).length > 0 ? value : '-';
    }

    //10 alphanumeric letters
    RandomId = () => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < 10; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    //2021.11.17
    LoadSavedListMaxQueryQtySetting = async () => {
        let _maxQueryQty = localStorage.getItem('ManageEvent_List_MaxQueryQty');
        this.setState({
            ListMaxQueryQty: _maxQueryQty !== null ? _maxQueryQty : this.state.ListMaxQueryQty,
        });
    }

    //2021.07.27
    //=== Event List === start ===//
    LoadEventList = async () => {
        let eventList_lastVisible = null;
        let eventList = [];
        this.setState({ IsEventListLoaded: false, });

        //2023.09.29
        const { organizerId } = GetPropIds(this.props);
        // const { centerUserId, authorId, organizerId } = GetPropIds(this.props);
        // const _CenterUserId = centerUserId;
        // const _AuthorId = authorId;
        // const _OrganizerId = organizerId;

        // //2022.10.17
        // let _CenterUserId = Number(this.props.user.CenterUserId);
        // let _AuthorId = Number(this.props.user.AuthorId);
        // let _OrganizerId = 0;
        // if (this.props.OrganizerInfo !== null) {
        //     _CenterUserId = Number(this.props.OrganizerInfo.CenterUserId);
        //     _AuthorId = Number(this.props.OrganizerInfo.AuthorId);
        //     _OrganizerId = Number(this.props.OrganizerInfo.OrganizerId);
        // }
        // console.log(JSON.stringify(this.props.OrganizerInfo));

        //2022.06.03 edited.
        let action = this.props.firestore.collection("LiveQuiz_UpcomingEvents");

        //2023.09.29 - revamp.
        let proceed = true;
        if (organizerId > 0) {
            action = action.where('OrganizerId', '==', organizerId)
        }
        else {
            if (this.props.isSuperAdmin === false)
                proceed = false;
        }
        // console.log('LoadEventList (isSuperAdmin)', this.props.isSuperAdmin);

        // if (!CheckBoolean(this.props.isSuperAdmin) || this.props.OrganizerInfo !== null) {
        //     action = action
        //         .where('OrganizerId', '==', _OrganizerId)
        //     // .where('CenterUserId', '==', _CenterUserId)
        //     // .where('AuthorId', '==', _AuthorId);
        //     // .where('OrganizerIdentity', '==', String(this.props.OrganizerInfo.Identity))     //2022.10.17

        //     // //standby for future update. 2022.06.03
        //     // if (CheckBoolean(this.props.user.IsMasterAuthor)) {
        //     //     action = action
        //     //         .where('OrganizerId', '==', Number(this.props.OrganizerInfo['Id']));
        //     // }
        //     // else {
        //     //     action = action
        //     //         .where('CenterUserId', '==', Number(this.props.user.CenterUserId))
        //     //         .where('AuthorId', '==', Number(this.props.user.AuthorId));
        //     // }
        // }

        if (proceed) {
            await action.orderBy('DateStart', 'desc')
                .limit(200)
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                            dataArray[dataArray.length - 1].Id = doc.id;    //important
                        });
                        if (dataArray.length > 0) {
                            eventList = dataArray;
                            eventList_lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                        }
                    }
                    // if (this.props.isDevMode)
                    //     console.log(JSON.stringify(eventList));
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('load event list (error) =\n' + error);
                });
        }
        this.setState({
            EventList: eventList,
            EventList_lastVisible: eventList_lastVisible,
            IsEventListLoaded: true,
        });
    }
    EventListComponents = () => {
        if (this.state.IsEventListLoaded) {
            let listRows = [];
            if (this.state.EventList.length > 0) {
                this.state.EventList.map((data, key) => {
                    return listRows.push(
                        <tr key={'event_' + key}>
                            <td>{key + 1}</td>
                            <td style={{ textAlign: 'left' }}>{data['EventName']}</td>
                            <td hidden={this.props.isSuperAdmin === false}>{
                                data['OrganizerDisplayName'] !== undefined ?
                                    data['OrganizerDisplayName']
                                    :
                                    data['Organizer']
                            }</td>
                            <td>{data['DateStart']}</td>
                            <td>{data['DateEnd']}</td>
                            {/* <td>{data['Role']}</td> */}
                            <td>
                                <button type="button" className="btn btn-primary"
                                    onClick={() => this.ViewEventDetail(key)}
                                >View</button>
                            </td>
                        </tr>
                    );
                });
            }
            if (listRows.length > 0)
                return listRows;
            else
                return <tr><td colSpan='15' align='center'>list is empty</td></tr>;
        }
        return <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>;
    }
    //=== Event List === end ===//


    //=== View Event === start ===//
    ToggleViewEventModal = () => {
        this.setState({ ShowViewEventModal: !this.state.ShowViewEventModal },
            () => {
                if (!this.state.ShowViewEventModal) {
                    this.setState({
                        TargetEventIsEnded: false,
                        ToggleEditEvent: false,
                        // TargetEventEditedModal: { ...this.state.TargetEventModal },
                        // TargetEventRooms: { ...this.state.Cache_TargetEventRooms },
                        // TargetEventModal: null,
                        // TargetEventEditedModal: null,
                        // TargetEventRooms: [],
                        // Temp_TargetEventRooms: [],
                    });
                    // console.log(JSON.stringify(this.state.TargetEventModal));

                    //2023.11.01
                    this.setState({
                        // EditEventRoomState: 0,
                        SelectedEventRoom: null,
                        Temp_TargetEventRooms: [],
                    }, () => this.ResetEditRoomState());
                }
            });
        // if (!this.state.ShowViewEventModal) {
        //     this.setState({
        //         TargetEventIsEnded: false,
        //         ToggleEditEvent: false,
        //         // TargetEventEditedModal: { ...this.state.TargetEventModal },
        //         // TargetEventRooms: { ...this.state.Cache_TargetEventRooms },
        //         // TargetEventModal: null,
        //         // TargetEventEditedModal: null,
        //         // TargetEventRooms: [],
        //         // Temp_TargetEventRooms: [],
        //     });
        //     console.log(JSON.stringify(this.state.TargetEventModal));
        // }
        if (this.props.isDevMode)
            console.log(JSON.stringify(this.state.TargetEventModal));
    }
    ViewEventDetail = async (_idx, _edit = false, _new = false) => {

        this.props.SetLoading('', 'loading...', true);

        const { organizerId, organizerDiplayName, organizerIdentity, organizerName } = GetPropIds(this.props);

        //refresh cache.
        if (this.state.Prev_EventIndex > -1) {
            if (this.state.Prev_EventIndex !== _idx) {
                this.setState({
                    Prev_EventIndex: _idx,
                    TargetEventIndex: _idx,
                    TargetEventModal: null,
                    TargetEventEditedModal: null,       //2022.10.14
                    TargetEventRooms: [],
                    TargetEventIsEnded: false,
                    Temp_TargetEventRooms: [],
                });
            }
        }
        else {
            this.setState({
                Prev_EventIndex: _idx,
            });
        }

        if (_new) {
            //Create new Event.

            let _current = moment();

            //default modal.
            let _targetEvent = {
                Id: moment.utc().unix() + '000',
                CenterUserId: Number(this.props.user.CenterUserId),
                AuthorId: Number(this.props.user.AuthorId),

                Active: true,
                IsPublic: true,
                CreatedDate: _current.format('YYYY-MM-DD hh:mm:ss A'),

                Event: '',
                EventName: '',
                EventCode: '',

                CertDownloadDelayed: false,
                CertDownloadEnabled: false,
                CertImgUrl: '',
                CertLayoutTypeId: 1,
                CertSerialPrefix: '',

                Date: _current.format('YYYY-MM-DD HH:mm:ss'),
                DateEnd: _current.format('YYYY-MM-DD'),
                DateStart: _current.format('YYYY-MM-DD'),
                TimeStart: '00:00',
                TimeEnd: '23:59',

                EventSharedReportUrl: '',
                RegistrationEnd: _current.format('YYYY-MM-DD HH:mm:ss'),
                RegistrationEndDisplayName: _current.format('lll'),
                RegistrationOverloaded: false,
                RoomsHeldOnSelfTime: true,

                // OrganizerId: this.props.OrganizerInfo === null ? 0 : Number(this.props.OrganizerInfo['Id']),
                // Organizer: this.props.OrganizerInfo === null ? '' : String(this.props.OrganizerInfo['Name']),
                // OrganizerIdentity: this.props.OrganizerInfo === null ? '' : String(this.props.OrganizerInfo['Identity']),
                // OrganizerDisplayName: this.props.OrganizerInfo === null ? '' : String(this.props.OrganizerInfo['DisplayName']),

                OrganizerId: organizerId,
                Organizer: organizerName,
                OrganizerIdentity: organizerIdentity,
                OrganizerDisplayName: organizerDiplayName,

                ZoomInfo: null,
                FacebookInfo: null,
                TelegramInfo: [{ Lang: 'chi', Content: '' }, { Lang: 'mal', Content: '' }, { Lang: 'en', Content: '' }],     //2022.10.12

                // QuizGroups: [],
                Groups: [],             //2022.10.12
                QuizRooms: [],

                //2021.11.25
                PublishModeId: 1,
                Role: 'Global',
                GlobalScheduleTimeStart: '00:00',
                GlobalScheduleTimeEnd: '23:59',

                //2021.12.09
                GovRecognitionLetterFileId: 0,
                GovRecognitionLetterUrl: '',
                CertImgFileId: 0,
            };

            // if (this.props.user.Email === 'simmembership@sim.edu.my') {
            //     //2022.05.11 - hardcoded
            //     _targetEvent.OrganizerId = 6;
            //     _targetEvent.Organizer = 'SIM';
            //     _targetEvent.OrganizerIdentity = 'simeducationgroup';
            //     _targetEvent.OrganizerDisplayName = 'SIM EDUCATION GROUP';
            // }
            // else {
            if (this.props.isSuperAdmin) {
                _targetEvent.OrganizerId = 7;
                _targetEvent.Organizer = 'iKEYTEAM';
                _targetEvent.OrganizerIdentity = 'ikeyedutech';
                _targetEvent.OrganizerDisplayName = 'iKEY EDUTECH';
            }
            else {
                _targetEvent.OrganizerId = this.props.OrganizerInfo === null ? 0 : Number(this.props.OrganizerInfo['Id']);
                _targetEvent.Organizer = this.props.OrganizerInfo === null ? '' : String(this.props.OrganizerInfo['Name']);
                _targetEvent.OrganizerIdentity = this.props.OrganizerInfo === null ? '' : String(this.props.OrganizerInfo['Identity']);
                _targetEvent.OrganizerDisplayName = this.props.OrganizerInfo === null ? '' : String(this.props.OrganizerInfo['DisplayName']);
            }
            // }

            this.setState({
                TargetEventIndex: -1,
                TargetEventModal: _targetEvent,
                TargetEventRooms: [],
                TargetEventIsEnded: false,

                Temp_TargetEventRooms: [],
                ToggleEditEvent: _edit, //false
                TargetEventEditedModal: { ..._targetEvent },
                // TargetEventEditedModal: JSON.parse(JSON.stringify(_targetEvent)),

                IsCreateNewEvent: _new, //true
                IsEventEditing: _edit,   //2023.05.11
            }, () => {
                this.ToggleViewEventModal();
                this.props.CloseAlert();

                if (this.props.isDevMode)
                    console.log(JSON.stringify(_targetEvent));
            });

            await Delay(1000);
            this.GenerateRandomEventCode();
        }
        else {
            // View or Edit Event.

            // var targetEvent = JSON.parse(JSON.stringify(this.state.EventList[_idx]));
            let _targetEvent = { ...this.state.EventList[_idx] };
            // let _targetEvent = JSON.parse(JSON.stringify(this.state.EventList[_idx]));
            // console.log(JSON.stringify(targetEvent));

            if (_targetEvent === undefined)
                return null;

            let _targetEventIsEnded = moment() >= moment(_targetEvent['DateEnd'] + ' 23:59:00');

            //2022.10.06
            _targetEvent = this.Populate_DefaultValue_For_TargetEventModal(_targetEvent);
            //#region //==== simplified === 2022.10.06 ===//
            // // //2021.11.25
            // // if (_targetEvent.hasOwnProperty('CenterUserId') === false)
            // //     _targetEvent.CenterUserId = Number(this.props.user.CenterUserId);
            // // if (_targetEvent.hasOwnProperty('AuthorId') === false)
            // //     _targetEvent.AuthorId = Number(this.props.user.AuthorId);
            // // if (_targetEvent.hasOwnProperty('RoomsHeldOnSelfTime') === false)
            // //     _targetEvent.RoomsHeldOnSelfTime = true;
            // // if (_targetEvent.hasOwnProperty('GlobalScheduleTimeStart') === false)
            // //     _targetEvent.GlobalScheduleTimeStart = '00:00';
            // // if (_targetEvent.hasOwnProperty('GlobalScheduleTimeEnd') === false)
            // //     _targetEvent.GlobalScheduleTimeEnd = '23:59';
            // // if (_targetEvent.hasOwnProperty('Role') === false)
            // //     _targetEvent.Role = 'Global';
            // // if (_targetEvent.hasOwnProperty('PublishModeId') === false)
            // //     _targetEvent.PublishModeId = Number(PublishModeOptions.find(x => x.role.toLowerCase() === String(_targetEvent.Role).toLowerCase()).value);
            // // else
            // //     _targetEvent.PublishModeId = 1; //default (Role) = Global

            // //2022.05.11 - start -
            // let temp_group1 = [];
            // let temp_group2 = [];
            // for (var ii = 1; ii <= 6; ii++) {
            //     temp_group1.push({ Group: 'Standard ' + ii, GroupId: ii, Rooms: [] });      //default QuizRooms.
            //     temp_group2.push({ Name: 'Standard ' + ii, Id: ii });                       //default Groups.
            // }
            // if (!_targetEvent.QuizRooms)
            //     _targetEvent.QuizRooms = temp_group1;
            // if (!_targetEvent.Groups)
            //     _targetEvent.Groups = temp_group2;
            // if (!_targetEvent.GovRecognitionLetterFileId)
            //     _targetEvent.GovRecognitionLetterFileId = 0;
            // if (!_targetEvent.GovRecognitionLetterUrl)
            //     _targetEvent.GovRecognitionLetterUrl = '';
            // if (!_targetEvent.CenterUserId)
            //     _targetEvent.CenterUserId = Number(this.props.user.CenterUserId);
            // if (!_targetEvent.AuthorId)
            //     _targetEvent.AuthorId = Number(this.props.user.AuthorId);
            // if (!_targetEvent.RoomsHeldOnSelfTime)
            //     _targetEvent.RoomsHeldOnSelfTime = true;
            // if (!_targetEvent.GlobalScheduleTimeStart)
            //     _targetEvent.GlobalScheduleTimeStart = '00:00';
            // if (!_targetEvent.GlobalScheduleTimeEnd)
            //     _targetEvent.GlobalScheduleTimeEnd = '23:59';
            // if (!_targetEvent.Role)
            //     _targetEvent.Role = 'Global';
            // if (!_targetEvent.PublishModeId)
            //     _targetEvent.PublishModeId = Number(PublishModeOptions.find(x => x.role.toLowerCase() === String(_targetEvent.Role).toLowerCase()).value);
            // else
            //     _targetEvent.PublishModeId = 1; //default (Role) = Global
            // //2022.05.11 - end -
            //#endregion //==== simplified === 2022.10.06 ===//

            //2022.10.06            
            const { _targetEventRooms, _temp_TargetEventRooms } = await this.Populate_TargetEventRooms(_targetEvent, _targetEventIsEnded);
            //#region //==== simplified === 2022.10.06 ===//
            // let _targetEventRooms = [];
            // // let _temp = [];
            // // if (_targetEvent.hasOwnProperty('QuizRooms')) {
            // //     if (_targetEvent.QuizRooms.length > 0) {
            // for (var i = 0; i < _targetEvent.QuizRooms.length; i++) {
            //     if (_targetEvent.QuizRooms[i].hasOwnProperty('Rooms')) {
            //         if (_targetEvent.QuizRooms[i].Rooms.length > 0) {
            //             for (var j = 0; j < _targetEvent.QuizRooms[i].Rooms.length; j++) {
            //                 let room = _targetEvent.QuizRooms[i].Rooms[j];
            //                 if (room !== undefined) {

            //                     // let _subjectName = roomInfo === null ? String(room.Subject) : String(roomInfo.SubjectName);
            //                     // let _subject_Idx = this.props.SubjectOptions.findIndex(x => x.value === _subjectName);
            //                     // let _subjectId = _subject_Idx < 0 ? 0 : Number(this.props.SubjectOptions[_subject_Idx].id);

            //                     //Get Room Info.
            //                     // let roomInfo = room.RoomCode !== 'TBA' && room.RoomCode.length > 3 ? await this.GetRoomInfo(room.RoomCode) : null;
            //                     let roomInfo = null;
            //                     if (String(room.RoomCode) !== 'TBA' && String(room.RoomCode).length > 3)
            //                         roomInfo = await this.GetRoomInfo(room.RoomCode);

            //                     //Subject.
            //                     let _subjectName = '';
            //                     let _subjectId = -1;
            //                     if (room.hasOwnProperty('Subject') && room.hasOwnProperty('SubjectId')) {
            //                         _subjectName = String(room.Subject);
            //                         _subjectId = Number(room.SubjectId);
            //                     }
            //                     else {
            //                         if (roomInfo !== null && roomInfo !== undefined) {
            //                             if (roomInfo.hasOwnProperty('SubjectName'))
            //                                 _subjectName = String(roomInfo.SubjectName);

            //                             if (roomInfo.hasOwnProperty('SubjectId'))
            //                                 _subjectId = Number(roomInfo.SubjectId);

            //                             if (_subjectId <= 0) {
            //                                 let _subject_Idx = this.props.SubjectOptions.findIndex(x => x.value === _subjectName);
            //                                 _subjectId = _subject_Idx < 0 ? 0 : Number(this.props.SubjectOptions[_subject_Idx].id);
            //                             }
            //                         }
            //                     }

            //                     //set.
            //                     _targetEventRooms.push({
            //                         Group: String(_targetEvent.QuizRooms[i].Group),
            //                         GroupId: Number(_targetEvent.QuizRooms[i].GroupId),
            //                         Subject: _subjectName,
            //                         SubjectId: _subjectId,
            //                         RoomCode: String(roomInfo === null ? room.RoomCode : roomInfo.RoomCode),
            //                         DateStart: String(roomInfo === null ? room.DateStart : roomInfo.DateStart),
            //                         DateEnd: String(roomInfo === null ? room.DateEnd : roomInfo.DateEnd),
            //                         TimeLimit: String(roomInfo === null ? room.TimeLimit : this.GetDurationToTimeLimit_HHMM(roomInfo.Duration)),
            //                         TimeStart: _targetEvent.RoomsHeldOnSelfTime ? (roomInfo === null ? room.TimeStart : this.GetTimeFormat_hhmmA(roomInfo.TimeStart)) : this.GetTimeFormat_hhmmA(_targetEvent.GlobalScheduleTimeStart),
            //                         TimeEnd: _targetEvent.RoomsHeldOnSelfTime ? (roomInfo === null ? room.TimeEnd : this.GetTimeFormat_hhmmA(roomInfo.TimeEnd)) : this.GetTimeFormat_hhmmA(_targetEvent.GlobalScheduleTimeEnd),
            //                     });

            //                     // _targetEventRooms.push({
            //                     //     Group: _targetEvent.QuizRooms[i].Group,
            //                     //     GroupId: _targetEvent.QuizRooms[i].GroupId,  //2021.10.08
            //                     //     Subject: room.Subject,
            //                     //     RoomCode: room.RoomCode,
            //                     //     DateStart: room.DateStart,
            //                     //     DateEnd: room.DateEnd,
            //                     //     TimeLimit: room.TimeLimit,
            //                     //     TimeStart: _targetEvent.RoomsHeldOnSelfTime ? room.TimeStart : '',
            //                     //     TimeEnd: _targetEvent.RoomsHeldOnSelfTime ? room.TimeEnd : '',
            //                     // });
            //                 }
            //             }
            //         }
            //     }
            // }
            // _targetEventRooms.sort((a, b) => a.GroupId - b.GroupId);
            // // alert(JSON.stringify(targetEventRooms));
            // // _targetEvent.QuizRooms = _targetEventRooms;

            // //2021.10.12
            // let _temp_TargetEventRooms = [];
            // if (this.state.Temp_TargetEventRooms.length <= 0) {
            //     if (_targetEventIsEnded === false) {
            //         // _temp = JSON.parse(JSON.stringify(_targetEventRooms));
            //         _temp_TargetEventRooms = [..._targetEventRooms];
            //         _temp_TargetEventRooms.map((data, key) => {
            //             if (data.RoomCode === 'TBA') {
            //                 data.matched = false;
            //                 delete data.room;
            //             }
            //             return null;
            //         });
            //     }
            // }
            // //     }
            // // }
            //#endregion //==== simplified === 2022.10.06 ===//

            // //2022.10.07
            // let _targetEventGroups = [];
            // const _groups = _targetEvent.Groups;
            // for (let k = 0; k < this.props.GroupOptions.length; k++) {
            //     const option = this.props.GroupOptions[k];
            //     const opIdx = _groups.findIndex(x => Number(x.Id) === Number(option.id));
            //     _targetEventGroups.push({ Name: option.value, Id: option.id, checked: opIdx > -1, });
            // }

            this.props.CloseAlert();
            this.setState({
                TargetEventIndex: _idx,
                TargetEventModal: _targetEvent,
                TargetEventRooms: _targetEventRooms,
                // TargetEventModal: JSON.parse(JSON.stringify(_targetEvent)),
                // TargetEventRooms: JSON.parse(JSON.stringify(_targetEventRooms)),
                TargetEventIsEnded: _targetEventIsEnded,

                //2021.10.12
                // Temp_TargetEventRooms: this.state.Temp_TargetEventRooms.length <= 0 ? _temp : this.state.Temp_TargetEventRooms,
                Temp_TargetEventRooms: _temp_TargetEventRooms,
                // Temp_TargetEventRooms: JSON.parse(JSON.stringify(_temp_TargetEventRooms)),
                // Cache_TargetEventRooms: _targetEventRooms,

                //2022.10.06
                // TargetEventGroups: _targetEventGroups,  //_targetEvent.Groups,
                // Cache_TargetEventModal: { ..._targetEvent },
                Cache_TargetEventModal: JSON.parse(JSON.stringify(_targetEvent)),

                IsEventEditing: _edit,   //2023.05.11

                //2021.11.22
                ToggleEditEvent: _edit, //true
                // TargetEventEditedModal: _edit ? JSON.parse(JSON.stringify(_targetEvent)) : null,
                // TargetEventEditedModal: JSON.parse(JSON.stringify(_targetEvent)),
                // TargetEventEditedModal: { ..._targetEvent },
                TargetEventEditedModal: JSON.parse(JSON.stringify(_targetEvent)),

                //2021.11.24
                IsCreateNewEvent: _new, //false
            }, () => {
                this.ToggleViewEventModal();
                this.props.CloseAlert();

                if (this.props.isDevMode) {
                    if (_edit === false) {
                        console.log(JSON.stringify(_targetEvent));
                        // console.log(JSON.stringify(_targetEventGroups));
                        console.log(JSON.stringify(_targetEventRooms));
                    }
                }
            });
        }
    }
    //2022.10.06
    Populate_DefaultValue_For_TargetEventModal = (_targetEvent = null) => {
        if (_targetEvent !== null) {
            // //2021.11.25
            // if (_targetEvent.hasOwnProperty('CenterUserId') === false)
            //     _targetEvent.CenterUserId = Number(this.props.user.CenterUserId);
            // if (_targetEvent.hasOwnProperty('AuthorId') === false)
            //     _targetEvent.AuthorId = Number(this.props.user.AuthorId);
            // if (_targetEvent.hasOwnProperty('RoomsHeldOnSelfTime') === false)
            //     _targetEvent.RoomsHeldOnSelfTime = true;
            // if (_targetEvent.hasOwnProperty('GlobalScheduleTimeStart') === false)
            //     _targetEvent.GlobalScheduleTimeStart = '00:00';
            // if (_targetEvent.hasOwnProperty('GlobalScheduleTimeEnd') === false)
            //     _targetEvent.GlobalScheduleTimeEnd = '23:59';
            // if (_targetEvent.hasOwnProperty('Role') === false)
            //     _targetEvent.Role = 'Global';
            // if (_targetEvent.hasOwnProperty('PublishModeId') === false)
            //     _targetEvent.PublishModeId = Number(PublishModeOptions.find(x => x.role.toLowerCase() === String(_targetEvent.Role).toLowerCase()).value);
            // else
            //     _targetEvent.PublishModeId = 1; //default (Role) = Global

            //2022.05.11 - start -

            if (!_targetEvent.QuizRooms || !_targetEvent.Groups) {
                let temp_group1 = [];
                let temp_group2 = [];
                for (var ii = 1; ii <= 6; ii++) {
                    temp_group1.push({ Group: 'Standard ' + ii, GroupId: ii, Rooms: [] });      //default QuizRooms.
                    temp_group2.push({ Name: 'Standard ' + ii, Id: ii });                       //default Groups.
                }
                if (!_targetEvent.QuizRooms)
                    _targetEvent.QuizRooms = temp_group1;
                if (!_targetEvent.Groups)
                    _targetEvent.Groups = temp_group2;
            }
            if (!_targetEvent.GovRecognitionLetterFileId)
                _targetEvent.GovRecognitionLetterFileId = 0;
            if (!_targetEvent.GovRecognitionLetterUrl)
                _targetEvent.GovRecognitionLetterUrl = '';
            if (!_targetEvent.CenterUserId)
                _targetEvent.CenterUserId = Number(this.props.user.CenterUserId);
            if (!_targetEvent.AuthorId)
                _targetEvent.AuthorId = Number(this.props.user.AuthorId);
            if (!_targetEvent.RoomsHeldOnSelfTime)
                _targetEvent.RoomsHeldOnSelfTime = true;
            if (!_targetEvent.GlobalScheduleTimeStart)
                _targetEvent.GlobalScheduleTimeStart = '00:00';
            if (!_targetEvent.GlobalScheduleTimeEnd)
                _targetEvent.GlobalScheduleTimeEnd = '23:59';
            if (!_targetEvent.TelegramInfo || _targetEvent.TelegramInfo === null || _targetEvent.TelegramInfo === '' || (Array.isArray(_targetEvent.TelegramInfo) && _targetEvent.TelegramInfo.length === 0))
                _targetEvent.TelegramInfo = [{ Lang: 'chi', Content: '' }, { Lang: 'mal', Content: '' }, { Lang: 'en', Content: '' }];     //2022.10.13
            if (!_targetEvent.Role)
                _targetEvent.Role = 'Global';
            if (!_targetEvent.PublishModeId)
                _targetEvent.PublishModeId = Number(PublishModeOptions.find(x => x.role.toLowerCase() === String(_targetEvent.Role).toLowerCase()).value);
            else
                _targetEvent.PublishModeId = 1; //default (Role) = Global
            //2022.05.11 - end -
        }
        return _targetEvent;
    }
    //2022.10.06
    Populate_TargetEventRooms = async (_targetEvent = null, _targetEventIsEnded = false) => {
        let _targetEventRooms = [];
        let _temp_TargetEventRooms = [];

        if (_targetEvent !== null) {
            if (_targetEvent.QuizRooms.length > 0) {
                for (var i = 0; i < _targetEvent.QuizRooms.length; i++) {
                    if (_targetEvent.QuizRooms[i].hasOwnProperty('Rooms')) {
                        if (_targetEvent.QuizRooms[i].Rooms.length > 0) {
                            for (var j = 0; j < _targetEvent.QuizRooms[i].Rooms.length; j++) {
                                let room = _targetEvent.QuizRooms[i].Rooms[j];
                                if (room !== undefined) {

                                    // // let _subjectName = roomInfo === null ? String(room.Subject) : String(roomInfo.SubjectName);
                                    // // let _subject_Idx = this.props.SubjectOptions.findIndex(x => x.value === _subjectName);
                                    // // let _subjectId = _subject_Idx < 0 ? 0 : Number(this.props.SubjectOptions[_subject_Idx].id);

                                    // //Get Room Info.
                                    // // let roomInfo = room.RoomCode !== 'TBA' && room.RoomCode.length > 3 ? await this.GetRoomInfo(room.RoomCode) : null;
                                    // let roomInfo = null;
                                    // // if (String(room.RoomCode) !== 'TBA' && String(room.RoomCode).length > 3)
                                    // //     roomInfo = await this.GetRoomInfo(room.RoomCode);

                                    // //Subject.
                                    // let _subjectName = '';
                                    // let _subjectId = -1;
                                    // if (room.hasOwnProperty('Subject') && room.hasOwnProperty('SubjectId')) {
                                    //     _subjectName = String(room.Subject);
                                    //     _subjectId = Number(room.SubjectId);
                                    // }
                                    // else {
                                    //     // if (roomInfo !== null && roomInfo !== undefined) {
                                    //     if (room.hasOwnProperty('SubjectName'))
                                    //         _subjectName = String(room.SubjectName);

                                    //     //2022.10.19
                                    //     if (_subjectName === '' && room.hasOwnProperty('Subject'))
                                    //         _subjectName = String(room.Subject);

                                    //     if (room.hasOwnProperty('SubjectId'))
                                    //         _subjectId = Number(room.SubjectId);

                                    //     if (_subjectId <= 0) {
                                    //         if (this.props.SubjectIsLoaded) {
                                    //             let _subject_Idx = this.props.SubjectOptions.findIndex(x => String(x.value) === _subjectName);
                                    //             _subjectId = _subject_Idx < 0 ? 0 : Number(this.props.SubjectOptions[_subject_Idx].id);
                                    //         }
                                    //     }
                                    //     // }
                                    // }

                                    // //set.
                                    // _targetEventRooms.push({
                                    //     Group: String(_targetEvent.QuizRooms[i].Group),
                                    //     GroupId: Number(_targetEvent.QuizRooms[i].GroupId),
                                    //     Subject: _subjectName,
                                    //     SubjectId: _subjectId,
                                    //     RoomCode: String(roomInfo === null ? room.RoomCode : roomInfo.RoomCode),
                                    //     DateStart: String(roomInfo === null ? room.DateStart : roomInfo.DateStart),
                                    //     DateEnd: String(roomInfo === null ? room.DateEnd : roomInfo.DateEnd),
                                    //     TimeLimit: String(roomInfo === null ? room.TimeLimit : this.GetDurationToTimeLimit_HHMM(roomInfo.Duration)),
                                    //     TimeStart: _targetEvent.RoomsHeldOnSelfTime ? (roomInfo === null ? room.TimeStart : this.GetTimeFormat_hhmmA(roomInfo.TimeStart)) : this.GetTimeFormat_hhmmA(_targetEvent.GlobalScheduleTimeStart),
                                    //     TimeEnd: _targetEvent.RoomsHeldOnSelfTime ? (roomInfo === null ? room.TimeEnd : this.GetTimeFormat_hhmmA(roomInfo.TimeEnd)) : this.GetTimeFormat_hhmmA(_targetEvent.GlobalScheduleTimeEnd),
                                    // });

                                    if (this.props.SubjectIsLoaded) {
                                        let _subject_Idx = -1;
                                        if (room.hasOwnProperty('SubjectId'))
                                            _subject_Idx = this.props.SubjectOptions.findIndex(x => Number(x.id) === Number(room.SubjectId));
                                        else
                                            _subject_Idx = this.props.SubjectOptions.findIndex(x => String(x.value) === String(room.Subject));
                                        room.SubjectId = _subject_Idx < 0 ? 0 : Number(this.props.SubjectOptions[_subject_Idx].id);
                                    }
                                    room.Group = String(_targetEvent.QuizRooms[i].Group);
                                    room.GroupId = Number(_targetEvent.QuizRooms[i].GroupId);
                                    _targetEventRooms.push(room);
                                }
                            }
                        }
                        else {
                            //2022.10.26
                            _targetEventRooms.push({
                                Group: String(_targetEvent.QuizRooms[i].Group),
                                GroupId: Number(_targetEvent.QuizRooms[i].GroupId),
                                Subject: 'TBA',
                                SubjectId: 0,
                                RoomCode: 'TBA',
                                DateStart: '-',
                                DateEnd: '-',
                                TimeLimit: '-',
                                TimeStart: '-',
                                TimeEnd: '-',
                            });
                        }
                    }
                }
            }
            else {
                //2022.10.26
                if (_targetEvent.Groups.length > 0) {
                    _targetEvent.Groups.map((group, key) => {
                        return _targetEventRooms.push({
                            Group: String(group.Name),
                            GroupId: Number(group.Id),
                            Subject: 'TBA',
                            SubjectId: 0,
                            RoomCode: 'TBA',
                            DateStart: '-',
                            DateEnd: '-',
                            TimeLimit: '-',
                            TimeStart: '-',
                            TimeEnd: '-',
                        });
                    });
                }
            }
            _targetEventRooms.sort((a, b) => a.GroupId - b.GroupId);
            // alert(JSON.stringify(targetEventRooms));
            // _targetEvent.QuizRooms = _targetEventRooms;

            //2021.10.12
            // let _temp_TargetEventRooms = [];
            if (this.state.Temp_TargetEventRooms.length <= 0) {
                if (_targetEventIsEnded === false) {
                    // _temp_TargetEventRooms = JSON.parse(JSON.stringify(_targetEventRooms));
                    _temp_TargetEventRooms = [..._targetEventRooms];
                    _temp_TargetEventRooms.map((data, key) => {
                        if (data.RoomCode === 'TBA') {
                            data.matched = false;
                            delete data.room;
                        }
                        return null;
                    });
                }
            }
        }
        return { _targetEventRooms, _temp_TargetEventRooms };
    }
    //2022.10.07
    SelectAllOnTargetEventGroups = () => {
        // if (this.props.GroupIsLoaded === false || this.props.GroupOptions.length === 0
        //     || this.state.TargetEventModal === null || this.state.TargetEventModal.Groups === undefined)
        //     return null;

        // // const defaultGroups = [...this.props.GroupOptions];
        // let _targetEvent = this.state.TargetEventModal;
        // let _groups = [..._targetEvent.Groups];
        // let _groups_checked = this.state.TargetEventGroups;
        // for (let i = 0; i < _groups_checked.length; i++) {
        //     _groups_checked[i].checked = true;
        //     let idx = _groups.findIndex(x => Number(x.Id) === Number(_groups_checked[i].Id));
        //     if (idx < 0)
        //         _groups.push({ Name: _groups[idx].value, Id: _groups[idx].id });
        // }
        // _groups.sort((a, b) => a.Name - b.Name);
        // _targetEvent.Groups = _groups;
        // this.setState({ TargetEventGroups: _groups_checked, TargetEventModal: _targetEvent });
    }
    //2022.10.07
    ToggleGroupCheckbox_TargetEventGroup = (id) => {
        //Edit Checkbox.
        if (this.props.GroupIsLoaded === false || this.props.GroupOptions.length === 0
            || this.state.TargetEventEditedModal === null || this.state.TargetEventEditedModal.Groups === undefined)
            return null;

        const defaultGroups = this.props.GroupOptions;
        const default_idx = defaultGroups.findIndex(x => Number(x.id) === Number(id));
        if (default_idx < 0)
            return null;

        let _targetEvent = this.state.TargetEventEditedModal;
        // let _targetEvent = JSON.parse(JSON.stringify(this.state.TargetEventEditedModal));

        let _rooms = _targetEvent.QuizRooms;
        const _room_idx = _rooms.findIndex(x => Number(x.GroupId) === Number(id));

        let _groups = _targetEvent.Groups;
        const _group_idx = _groups.findIndex(x => Number(x.Id) === Number(id));

        let _targetEventRooms = this.state.TargetEventRooms;
        const _target_room_idx = _targetEventRooms.findIndex(x => Number(x.GroupId) === Number(id));

        if (_group_idx < 0) {
            //add into group.
            _groups.push({ Name: defaultGroups[default_idx].value, Id: defaultGroups[default_idx].id });
            _rooms.push({ Group: defaultGroups[default_idx].label, GroupId: defaultGroups[default_idx].id, Rooms: [] });
            _targetEventRooms.push({
                Group: String(defaultGroups[default_idx].label),
                GroupId: Number(defaultGroups[default_idx].id),
                Subject: '',
                SubjectId: 0,
                RoomCode: 'TBA',
                DateStart: 'TBA',
                DateEnd: 'TBA',
                TimeLimit: 'TBA',
                TimeStart: 'TBA',
                TimeEnd: 'TBA',
            });
        }
        else {
            //remove from group.
            if (_group_idx > -1)
                _groups.splice(_group_idx, 1);
            //remove from rooms.
            if (_room_idx > -1)
                _rooms.splice(_room_idx, 1);
            //remove from target event rooms.
            if (_target_room_idx > -1)
                _targetEventRooms.splice(_target_room_idx, 1);
        }
        //clean items.
        _groups.sort((a, b) => {
            if (a.Name > b.Name) return 1;
            else if (a.Name < b.Name) return -1;
            else return 0;
        });
        _rooms.sort((a, b) => {
            if (a.Group > b.Group) return 1;
            else if (a.Group < b.Group) return -1;
            else return 0;
        });
        _targetEventRooms.sort((a, b) => a.GroupId - b.GroupId);
        if (this.props.isDevMode)
            console.log(JSON.stringify(_groups));
        // console.log(JSON.stringify(_rooms));
        _targetEvent.Groups = _groups;
        _targetEvent.QuizRooms = _rooms;
        this.setState({ TargetEventEditedModal: { ..._targetEvent }, TargetEventRooms: [..._targetEventRooms], });
    }
    //2022.10.06
    GetTargetEvent_EditGroups_TableRows = (isEditGroup = false) => {
        let rows = [];
        let modal = null;
        // const isEditMode = this.state.ShowEditEventGroupModal;
        if (isEditGroup) {
            //Edit.
            modal = this.state.TargetEventEditedModal;
            if (this.props.GroupIsLoaded) {
                if (this.props.GroupOptions.length > 0) {
                    this.props.GroupOptions.map((option, key) => {
                        let idx = modal === null || modal.Groups === null || modal.Groups === undefined ? -1
                            : modal.Groups.findIndex(x => Number(x.Id) === Number(option.id));
                        return rows.push(<tr key={'grp-op-' + key} onClick={() => this.ToggleGroupCheckbox_TargetEventGroup(option.id)} style={{ cursor: 'pointer' }}>
                            <td><input type='checkbox' className='disabled-pointer-events'
                                checked={idx > -1}
                                onChange={() => { }}
                            ></input></td>
                            <td>{option.label}</td>
                        </tr>);
                    });
                }
            }
        }
        else {
            //View.
            // console.log(JSON.stringify(this.state.TargetEventEditedModal?.Groups));
            // console.log(JSON.stringify(this.state.TargetEventModal?.Groups));
            // console.log(JSON.stringify(this.state.Cache_TargetEventModal?.Groups));
            modal = this.state.ToggleEditEvent ? this.state.TargetEventEditedModal : this.state.TargetEventModal;
            if (modal !== null && modal.Groups?.length > 0) {
                modal.Groups.map((group, key) => {
                    return rows.push(<tr className='hide-row-hover'>
                        <td>{key + 1}</td>
                        <td>{group.Name}</td>
                    </tr>);
                });
            }
        }
        return rows;
    }
    //2022.10.06
    ToggleEditEventGroupModal = () => {
        if (this.state.PA_Update === false)
            return null;
        this.setState({ ShowEditEventGroupModal: !this.state.ShowEditEventGroupModal });
    }
    GetTargetEvent_Rooms_TableRows = (isEditRoom = false) => {
        let rows = [];
        if (this.state.TargetEventRooms.length > 0) {
            let seperators = [];
            let prev = '';
            let counter = 0;
            for (let p = 0; p < this.state.TargetEventRooms.length; p++) {
                let data = this.state.TargetEventRooms[p];
                if (data.Group !== prev) {
                    prev = data.Group;
                    counter = 1;
                    seperators.push({ Group: data.Group, Total: counter, Index: p, });
                }
                else {
                    counter++;
                    let idx = seperators.findIndex(x => x.Group === data.Group);
                    if (idx > -1)
                        seperators[idx].Total = counter;
                }
            }
            for (let k = 0; k < this.state.TargetEventRooms.length; k++) {
                let data = this.state.TargetEventRooms[k];
                let group_idx = -1;
                let idx = seperators.findIndex(x => x.Group === data.Group);
                if (idx > -1)
                    group_idx = seperators[idx].Index;

                rows.push(
                    <tr key={'target_evt_room_' + k}>
                        {/* <td>{key + 1}</td> */}
                        {/* <td>{data.Group}</td> */}
                        {
                            group_idx === k ?
                                // <td rowSpan={seperators[idx].Total} className='hide-column-hover'>{seperators[idx].Group}</td>
                                <td rowSpan={seperators[idx].Total} className='hide-column-hover'>{this.GetValue(seperators[idx], 'Group')}</td>
                                : null
                        }
                        <td>{isEditRoom ? data.Subject : data.Subject}</td>
                        {/* <td>{data.RoomCode}</td> */}
                        <td>
                            {
                                isEditRoom ?
                                    <>
                                        <Button
                                            variant={
                                                data.RoomCode === 'TBA' ? 'primary' :
                                                    this.state.Temp_TargetEventRooms[k] === undefined ? 'primary' :
                                                        this.state.Temp_TargetEventRooms[k].matched ? 'primary' : 'secondary'
                                            }
                                            onClick={() => this.EditEventRoom(data, k)}
                                        >{data.RoomCode}</Button>
                                        {
                                            this.state.Temp_TargetEventRooms[k] === undefined || this.state.Temp_TargetEventRooms[k].length <= 0 ||
                                                this.state.Temp_TargetEventRooms[k].matched === undefined || this.state.Temp_TargetEventRooms[k].matched === true
                                                || this.state.Temp_TargetEventRooms[k].RoomCode === undefined || this.state.Temp_TargetEventRooms[k].RoomCode === 'TBA'
                                                // this.state.Temp_TargetEventRooms[k].matched === true ? null :
                                                //     this.state.Temp_TargetEventRooms[k].RoomCode === 'TBA' ? null :
                                                ? null :
                                                <span style={{ color: 'gray', fontSize: 14, textAlign: 'center', width: '100%', marginTop: 5, }}>
                                                    <br /><i><b>Room not match</b></i><br />
                                                    <table className='tb-no-padding' cellPadding='0' cellSpacing='0' width='100%' border='0' style={{ marginTop: 3, }}>
                                                        <tbody>
                                                            <tr><td>Group</td><td>:</td><td>{
                                                                this.state.Temp_TargetEventRooms[k].room === undefined ? ''
                                                                    : this.GetValue(this.state.Temp_TargetEventRooms[k].room, 'GroupId')
                                                            }</td></tr>
                                                            <tr><td>Subject</td><td>:</td><td>{
                                                                this.state.Temp_TargetEventRooms[k].room === undefined ? ''
                                                                    : this.state.Temp_TargetEventRooms[k].room.Subject
                                                            }</td></tr>
                                                            <tr>
                                                                <td colSpan='3'>
                                                                    <button className='link-button' onClick={() => this.ClearRoomAllocation(data)}>clear</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </span>
                                        }
                                    </>
                                    :
                                    data.RoomCode
                            }
                        </td>
                        {
                            this.state.TargetEventModal['RoomsHeldOnSelfTime'] ?
                                <>
                                    <td>{data.TimeStart}</td>
                                    <td>{data.TimeEnd}</td>
                                </>
                                : null
                        }
                    </tr>
                );
            }
        }
        else {
            //normally wont reach here, backup code.
            let modal = null;
            if (isEditRoom)
                modal = this.state.TargetEventEditedModal;
            else
                modal = this.state.TargetEventModal;

            if (this.props.isDevMode)
                if (modal !== null && modal.QuizRooms !== undefined)
                    console.log(JSON.stringify(modal.QuizRooms));

            if (modal !== null) {
                if (modal.Groups !== null && modal.Groups !== undefined) {
                    if (modal.Groups.length >= 0) {
                        modal.Groups.map((group, key) => {
                            return rows.push(<tr key={'target_evt_room_' + key}>
                                <td>{group.Name}</td>
                                <td>-</td>
                                <td>-</td>
                                {
                                    this.state.TargetEventModal['RoomsHeldOnSelfTime'] ?
                                        <>
                                            <td>-</td>
                                            <td>-</td>
                                        </>
                                        : null
                                }
                            </tr>);
                        });
                    }
                }

                // if (modal.QuizRooms !== null && modal.QuizRooms !== undefined && modal.QuizRooms.length > 0) {
                //     modal.QuizRooms.map((group, key) => {
                //         if (group.Rooms !== undefined && group.Rooms !== null) {
                //             if (group.Rooms.length > 0) {
                //                 group.Rooms.map((room, rkey) => {
                //                     return rows.push(<tr key={'target_evt_room_' + rkey}>
                //                         <td>{group.Group}</td>
                //                         <td>{room.Subject}</td>
                //                         <td>
                //                             {
                //                                 String(room.RoomCode) === '' || room.RoomCode === undefined || room.RoomCode === null ?
                //                                     <Button
                //                                         variant="primary"
                //                                         onClick={() => this.ToggleEditModal_EventRoom_Subject(group.Id)}
                //                                     >Add Room</Button>
                //                                     :
                //                                     room.RoomCode
                //                             }
                //                         </td>
                //                         {
                //                             modal['RoomsHeldOnSelfTime'] !== undefined && modal['RoomsHeldOnSelfTime'] ?
                //                                 <>
                //                                     <td>-</td>
                //                                     <td>-</td>
                //                                 </>
                //                                 : null
                //                         }
                //                     </tr>);
                //                 });
                //             }
                //         }
                //         else {
                //             //if no room(s) attached.
                //             rows.push(<tr key={'target_evt_room_' + key}>
                //                 <td>{group.Group}</td>
                //                 <td>
                //                     <Button
                //                         variant="primary"
                //                         onClick={() => this.ToggleEditModal_EventRoom_Subject(group.GroupId)}
                //                     >Add Subject</Button>
                //                 </td>
                //                 <td>-</td>
                //                 {
                //                     this.state.TargetEventModal['RoomsHeldOnSelfTime'] ?
                //                         <>
                //                             <td>-</td>
                //                             <td>-</td>
                //                         </>
                //                         : null
                //                 }
                //             </tr>);
                //         }
                //         return null;
                //     });
                // }
                // else {

                // }
            }
        }
        return rows;
    }
    //2021.11.26
    GetDurationToTimeLimit_HHMM = (_totalSeconds = 0) => {
        var hours = Number((_totalSeconds / 3600).toFixed(3).split('.')[0]);
        var minutes = Number((_totalSeconds / 60).toFixed(3).split('.')[0]);
        // var seconds = (_totalSeconds % 60);
        return String((hours > 9 ? '' : '0') + hours + ':' + (minutes - (hours * 60)));
    }
    //2021.11.26
    GetTimeFormat_hhmmA = (_time = '') => {
        if (_time.toLowerCase().includes('m'))  //AM or PM.
            return _time;
        else {
            if (_time.includes(':')) {
                let hh = Number(_time.split(':')[0].trim());
                let mm = Number(_time.split(':')[1].trim());
                let _hh = 0;
                if (hh > 9) { if (hh > 12) { _hh = hh - 12; } else { _hh = hh; } } else { _hh = hh; };
                return String((_hh > 9 ? _hh : '0' + _hh) + ':' + (mm > 9 ? mm : '0' + mm)
                    + ' ' + (hh > 11 ? 'PM' : (hh === 12 && mm > 0 ? 'PM' : 'AM')));
            }
        }
    }
    //=== View Event === end ===//


    //=== Delete Event === start ===//
    DeleteThisEvent = async () => {
        this.props.SetAlert('', '<b>Delete Event currently not available.</b>', false);
    }
    ToggleDeleteEventModal = () => {
        if (this.state.PA_Delete === false)
            return null;
        this.setState({ ShowDeleteEventModal: !this.state.ShowDeleteEventModal });
    }
    //=== Delete Event === end ===//


    //=== Create New Event === start ===//
    CreateNewEvent = () => {
        if (this.state.PA_Create === false)
            return null;
        // this.props.SetAlert('', '<b>Create New Event currently not available.</b>', false);
        this.ViewEventDetail(-1, true, true);
    }
    //2021.11.25
    GenerateRandomEventCode = async () => {
        //local function.
        let RandomEventCode_uuid_v4 = () => {
            // return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            // );
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[0-9a-f]/g, () =>
                Math.floor(Math.random() * 16).toString(16)
            );
        }
        let _New_RandomEventCode = '';

        this.setState({ New_EventCode_isValid: false });
        let _New_EventCode_isValid = false;
        do {
            _New_RandomEventCode = String(RandomEventCode_uuid_v4()).replaceAll('-', '');
            _New_EventCode_isValid = await this.CheckIfEventCodeIsValid(_New_RandomEventCode);
            await Delay(300);
        } while (_New_EventCode_isValid === false);

        if (this.props.isDevMode)
            console.log("[GenerateRandomEventCode]\nEvent Code : " + _New_RandomEventCode + "\nIsValid : " + _New_EventCode_isValid);

        let _temp = this.state.TargetEventEditedModal;
        _temp['EventCode'] = _New_RandomEventCode;
        this.setState({ TargetEventEditedModal: _temp, New_EventCode_isValid: _New_EventCode_isValid });
    }
    //2021.11.25
    CheckIfEventCodeIsValid = async (_EventCode = '') => {
        let _Code_isValid = false;
        if (_EventCode.length > 0) {
            //simplified.
            let eventInfo = await this.GetEventInfoViaEventCode(_EventCode);
            if (eventInfo === null)
                _Code_isValid = true;
            else
                _Code_isValid = false;
            // //check on loaded rooms first.
            // if (this.state.EventList.length > 0) {
            //     this.state.EventList.map((data, key) => {
            //         if (data.EventCode === _EventCode)
            //             _Code_isValid = false;
            //         else
            //             _Code_isValid = true;
            //         return null;
            //     });
            // }
            // else {
            //     _Code_isValid = true;
            // }
            // //check via FireStore.
            // if (_Code_isValid) {
            //     let eventInfo = await this.GetEventInfoViaEventCode(_EventCode);
            //     if (eventInfo === null)
            //         _Code_isValid = true;
            //     else
            //         _Code_isValid = false;
            // }
        }
        return _Code_isValid;
    }
    //2021.11.25
    GetEventInfoViaEventCode = async (_EventCode = '') => {
        let done = false;
        let _counter = 0;
        // let _success = false;
        let _eventInfo = null;
        let async_action = async () => {
            await this.props.firestore
                .collection("LiveQuiz_UpcomingEvents")
                .where('EventCode', '==', _EventCode)
                .orderBy('DateStart', 'asc')
                .limit(1)
                .get()
                .then(querySnapshot => {
                    let data = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        if (data.length > 0)
                            data = data[0];
                        else
                            data = null;
                    }
                    if (data !== null && data.hasOwnProperty('EventCode')) {
                        _eventInfo = data;
                    }
                    if (this.props.isDevMode)
                        console.log("[GetEventInfoViaEventCode]\nEvent Code : " + _EventCode + "\nModal : " + JSON.stringify(_eventInfo));
                    // _success = true;
                    _counter++;
                    done = true;
                })
                .catch(error => {
                    // _success = false;
                    done = true;
                    if (this.props.isDevMode)
                        console.log('bad Internet connection - retrying...\n\n' + error.message);
                });
            await DelayUntil(() => done === true);
        }
        do {
            // _success = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
            await Delay(500);
            // } while (_success === false);
        } while (_eventInfo === null && _counter < 5);
        return _eventInfo;
    }
    //=== Create New Event === end ===//


    //2021.11.22
    //=== Edit Event === start ===//
    EditEvent = async () => {
        // this.props.SetAlert('', '<b>Edit Event currently not available.</b>', false);
        this.setState({
            ShowViewEventModal: false,
            IsCreateNewEvent: false,
            TargetEventIndex: this.state.TargetEventIndex < 0 ? 0 : this.state.TargetEventIndex,
        });
        await Delay(300);
        // if (this.state.TargetEventIndex < 0) {

        // }
        // else {
        //     this.ViewEventDetail(this.state.TargetEventIndex, true);
        // }
        this.ViewEventDetail(this.state.TargetEventIndex, true);
    }
    SetTargetEventEditedValue = (field = '', value = undefined) => {
        let _state = this.state.TargetEventEditedModal;
        _state[field] = value;
        //2021.11.23 - add.
        if (field === 'CertDownloadEnabled') {
            if (value === true) {
                if (_state.hasOwnProperty('CertLayoutTypeId')) {
                    if (
                        _state['CertLayoutTypeId'] === null
                        || _state['CertLayoutTypeId'] === undefined
                        || Number(_state['CertLayoutTypeId']) < 1
                    ) {
                        _state['CertLayoutTypeId'] = 1;
                    }
                }
                else {
                    _state['CertLayoutTypeId'] = 1;
                }
            }
        }
        else if (field === 'CertDownloadDelayed') {                 //2022.06.22
            if (CheckBoolean(_state['CertDownloadDelayed']) === false) {
                _state['CertDownloadDelayedEndDateTime'] = '';
            }
        }
        else if (field === 'CertDownloadDelayedEndDateTime') {      //2022.06.22
            _state['CertDownloadDelayedEndDateTime'] = '';
            if (CheckBoolean(_state['CertDownloadDelayed'])) {
                const _valueText = String(value);
                if (moment(_valueText, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
                    _state['CertDownloadDelayedEndDateTime'] = _valueText;
                }
            }
        }
        // else if (field.includes('Time')) {
        //     _state[field] = value;
        // }
        this.setState({
            TargetEventEditedModal: _state,
        }, () => {
            if (this.props.isDevMode)
                console.log('\nTargetEventEditedModal =\n' + JSON.stringify(_state));
        });
    }
    GetEventUpdateModal = (source, edited) => {

        //2022.10.11
        // //2022.05.12
        // if (this.state.IsCreateNewEvent) {
        //     delete edited.QuizGroups;

        //     let temp_group1 = [];
        //     let temp_group2 = [];
        //     for (var i = 1; i <= 6; i++) {
        //         temp_group1.push({ Group: 'Standard ' + i, GroupId: i, Rooms: [] });
        //         temp_group2.push({ Name: 'Standard ' + i, Id: i });
        //     }
        //     if (!edited.QuizRooms || edited.QuizRooms.length === 0)
        //         edited.QuizRooms = temp_group1;
        //     if (!edited.Groups || edited.Groups.length === 0)
        //         edited.Groups = temp_group2;

        //     return edited;
        // }

        let modal = {};
        let fieldNames = [
            'CenterUserId', 'AuthorId',
            'Active', 'IsPublic', 'CreatedDate', 'Event', 'EventName', 'EventCode',
            'CertDownloadDelayed', 'CertDownloadEnabled', 'CertImgUrl', 'CertLayoutTypeId', 'CertSerialPrefix',
            'Date', 'DateEnd', 'DateStart', 'TimeEnd', 'TimeStart', 'EventSharedReportUrl',
            'RegistrationEnd', 'RegistrationEndDisplayName', 'RegistrationOverloaded', 'RoomsHeldOnSelfTime',
            'OrganizerId', 'Organizer', 'OrganizerIdentity', 'OrganizerDisplayName',
            'ZoomInfo', 'FacebookInfo', 'Role', 'PublishModeId', 'Remark',
            //'QuizGroups', 
            'QuizRooms', 'Groups',      //2022.10.11
            'TelegramInfo',     //2022.10.12
            'GlobalScheduleTimeEnd', 'GlobalScheduleTimeStart',
            'CertDownloadDelayedEndDateTime'    //2022.06.22
        ];
        // if (this.state.IsCreateNewEvent) {
        //     fieldNames.push('Id');
        //     fieldNames.push('CreatedDate');
        //     // fieldNames.push('EventCode');
        //     // fieldNames.push('EventName');
        //     fieldNames.push('CreatedDate');
        // }
        let field = '';
        for (var ii = 0; ii < fieldNames.length; ii++) {
            field = fieldNames[ii];
            if (source[field] !== edited[field])
                modal[field] = edited[field];
        }
        return modal;
    }
    SaveEventSettings = async (_isViewing = false) => {
        this.props.SetLoading('', 'Saving Event settings...', true);

        if (this.state.TargetEventModal === null || this.state.TargetEventModal.hasOwnProperty('Id') === false) {
            this.props.SetAlert('Invalid Event', 'event settings are not save.');
            return null;
        }

        //populate update modal.
        let eventUpdateModal = this.state.IsCreateNewEvent ? this.state.TargetEventEditedModal :
            this.GetEventUpdateModal(this.state.TargetEventModal, this.state.TargetEventEditedModal);
        if (this.props.isDevMode) {
            console.log('\nupdate modal =\n' + JSON.stringify(eventUpdateModal));
            console.log('\neventUpdateModal !== {}\n' + JSON.stringify(!Object.keys(eventUpdateModal).length));
        }

        if (Object.keys(eventUpdateModal).length > 0) {
            if (this.props.isDevMode)
                console.log('\nsaving settings to FS.');

            //save settings to FS.
            // let success = false;

            //2022.05.12
            let action = this.props.firestore
                .collection('LiveQuiz_UpcomingEvents')
                .doc(String(this.state.TargetEventModal.Id));
            if (this.state.IsCreateNewEvent) {
                action
                    .set(eventUpdateModal)
                    .then(async () => {
                        // success = true;
                        if (this.props.isDevMode)
                            console.log('FS save event (success).');

                        //2023.08.28
                        //trigger sync to db table.
                        await Delay(500);
                        const _eventCode = String(this.state.TargetEventModal.EventCode);
                        let syncSuccess = await this.TriggerToSyncQuizEventSettingsViaApi(_eventCode);
                        if (syncSuccess === false) {
                            if (this.props.isDevMode)
                                console.log('Failed to sync event setting.');
                        }
                        else {
                            if (this.props.isDevMode)
                                console.log('DB update event (success).');

                            //2024.01.16
                            // const _eventCode = String(this.state.TargetEventModal.EventCode);
                            this.props.firestore
                                .collection('LiveQuizEventJoinedList')
                                .doc(_eventCode)
                                .set({
                                    Code: _eventCode,
                                    Date: moment.utc().format('YYYY-MM-DD 16:00:00'),
                                })
                                .then(() => {
                                    if (this.props.isDevMode)
                                        console.log('LiveQuizEventJoinedList init entry point (success).');
                                })
                                .catch(error => {
                                    if (this.props.isDevMode)
                                        console.log('LiveQuizEventJoinedList init entry point (failed).\n' + error.message);
                                });
                        }
                    })
                    .catch(error => { if (this.props.isDevMode) { console.log('FS save event (failed).\n' + error.message); } });
            }
            else {
                action
                    .update(eventUpdateModal)
                    .then(async () => {
                        // success = true;
                        if (this.props.isDevMode)
                            console.log('FS update event (success).');

                        //2022.10.14
                        // if (success) {
                        //2022.06.09
                        //trigger sync to db table.
                        await Delay(500);
                        let syncSuccess = await this.TriggerToSyncQuizEventSettingsViaApi(String(this.state.TargetEventModal.EventCode));
                        if (syncSuccess === false) {
                            if (this.props.isDevMode)
                                console.log('Failed to sync event setting.');
                        }
                        else {
                            if (this.props.isDevMode)
                                console.log('DB update event (success).');
                        }
                        // }

                        //2022.10.11
                        // //2022.06.09
                        // //trigger sync to db table.
                        // // if (success) {
                        // let syncSuccess = await this.TriggerToSyncQuizEventSettingsViaApi(String(this.state.TargetEventModal.EventCode));
                        // if (syncSuccess === false) {
                        //     // this.props.SetAlert('', 'Failed to sync event setting.', false);
                        //     // return null;
                        //     if (this.props.isDevMode)
                        //         console.log('Failed to sync event setting.');
                        // }
                        // // }
                        // // else {
                        // //     this.props.SetAlert('', 'Failed to update & sync event setting.', false);
                        // //     return null;
                        // // }
                    })
                    .catch(error => { if (this.props.isDevMode) { console.log('FS update event (failed).\n' + error.message); } });
            }
            await action;

            // await this.props.firestore
            //     .collection('LiveQuiz_UpcomingEvents')
            //     .doc(String(this.state.TargetEventModal.Id))
            //     .update(eventUpdateModal)
            //     .then(() => {
            //         success = true;
            //         if (this.props.isDevMode)
            //             console.log('FS save event (success).');
            //     })
            //     .catch(error => { if (this.props.isDevMode) console.log('FS save event (failed).\n' + error.message); });

            // //trigger sync to db table.
            // if (success) {
            //     let syncSuccess = await this.TriggerToSyncQuizEventSettingsViaApi(String(eventUpdateModal.EventCode));
            //     if (syncSuccess === false) {
            //         this.props.SetAlert('', 'Failed to sync event setting.', false);
            //         return null;
            //     }
            //     // //2021.11.29 - not using atm as is handled by api on last setting sync attempt.
            //     // else {
            //     //     //2021.11.27
            //     //     if (this.state.TargetEventEditedModal.CertDownloadEnabled) {
            //     //         syncSuccess = await this.Trigger_ToCheckAndSync_QuizEventSerialNumberCounterSettings_ViaApi(String(this.state.TargetEventModal.EventCode));
            //     //         if (syncSuccess === false) {
            //     //             if (this.props.isDevMode) {
            //     //                 this.props.SetAlert('', 'Failed to configure serial number counter.', false);
            //     //                 console.log('Failed to configure serial number counter.');
            //     //             }
            //     //         }
            //     //     }
            //     // }
            // }
            // else {
            //     this.props.SetAlert('', 'Failed to update & sync event setting.', false);
            //     return null;
            // }
        }
        else {
            this.props.SetAlert('', 'Nothing to update.', false);
            return null;
        }

        // //2024.01.16
        // if (this.state.IsCreateNewEvent) {
        //     const _eventCode = String(this.state.TargetEventModal.EventCode);
        //     this.props.firestore
        //         .collection('LiveQuizEventJoinedList')
        //         .doc(_eventCode)
        //         .set({
        //             Code: _eventCode,
        //             Date: moment.utc().format('YYYY-MM-DD 16:00:00'),
        //         });
        // }

        //done.
        await this.LoadEventList();     //refresh event list.
        await DelayUntil(() => this.state.IsEventListLoaded);
        await Delay(1500);
        this.ToggleViewEventModal();
        await Delay(1500);
        this.props.CloseAlert();
        if (this.state.EventList.length > 0) {
            if (_isViewing)
                await this.ViewEventDetail(this.state.TargetEventIndex);  //2021.12.09
            else
                await this.EditEvent();   //reopen edit event.
            await Delay(500);
            this.props.SetAlert('', 'Event &lt;<b> '
                + (this.state.TargetEventModal === null ? '' : String(this.state.TargetEventModal.EventName))
                + ' </b>&gt; has been save.', false);
        }
        else {
            this.props.SetAlert('', 'Event save unsuccessful.<br />list is empty.', false);
        }
    }
    //2021.11.20
    TriggerToSyncQuizEventSettingsViaApi = async (_eventCode = '') => {
        let success = false;
        let path = GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Event/Settings/Sync/'
            + String(this.props.user.CenterUserId) + '/'
            + String(this.props.user.AuthorId) + '/'
            + _eventCode;
        if (this.props.isDevMode)
            console.log('TriggerToSyncQuizEventSettingsViaApi \n' + path);
        await fetch(path,
            // Api/LearningCentre/Event/Settings/Sync/{centerUserId}/{authorId}/{event_code}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                success = data.success;
                if (!data.success)
                    if (this.props.isDevMode)
                        console.log('Error', 'api - event sync (failed)\n' + JSON.stringify(data));
                return success;
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('Error', 'api - event sync (failed)\n' + error.message);
            });
        return success;
    }
    //2021.11.23
    IsEditedStyle = (componentName = '') => {
        return JSON.stringify(this.state.TargetEventEditedModal[componentName])
            === JSON.stringify(this.state.TargetEventModal[componentName])
            ? '' : 'edited'
    }
    //2021.11.29 - not using atm as is handled by api on last setting sync attempt.
    //2021.11.27
    Trigger_ToCheckAndSync_QuizEventSerialNumberCounterSettings_ViaApi = async (_eventCode = '') => {
        let _serialNumberCounterModal = null;
        //fetch modal.
        await this.props.dbLQ_SN_Counter
            .ref(_eventCode)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    _serialNumberCounterModal = snapshot.val();
                    if (this.props.isDevMode)
                        console.log(JSON.stringify(_serialNumberCounterModal));
                }
            })
            .catch(error => { if (this.props.isDevMode) { console.log('SN Num Counter (fetch) (failed).\n' + error.message); } });

        //check.
        if (_serialNumberCounterModal === null) {
            //Not found, proceed to add modal to RTDB.
            _serialNumberCounterModal = {
                CertSerialPrefix: String(this.state.TargetEventEditedModal.CertSerialPrefix),
                Counter: 0,
                EventCode: _eventCode,
                LastUpdate: moment.utc().format('YYYY-MM-DD HH:mm:ss.sss'),
            };
            await this.props.dbLQ_SN_Counter
                .ref(_eventCode)
                .set(_serialNumberCounterModal)
                .catch(error => { if (this.props.isDevMode) { console.log('SN Num Counter (set) (failed).\n' + error.message); } });

            //Trigger Sync to db via API.
            // if (_serialNumberCounterModal !== null) {
            await fetch(GlobalSetting.ApiUrl
                + 'Api/LearningCentre/Event/Certificate/SerialNumberCounter/Sync/'
                + String(this.props.user.CenterUserId) + '/'
                + String(this.props.user.AuthorId) + '/'
                + _eventCode,
                // Api/LearningCentre/Event/Certificate/SerialNumberCounter/Sync/{centerUserId}/{authorId}/{event_code}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if (!data.success)
                        if (this.props.isDevMode)
                            console.log('Error', 'api - event cert setting sync (failed)\n' + JSON.stringify(data));
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - event cert setting sync (failed)\n' + error.message);
                });
            // }
        }
        if (this.props.isDevMode)
            console.log('\nsn modal <> null ==' + String(_serialNumberCounterModal !== null)
                + ' \nsn modal (key) > 0 == ' + String(_(_serialNumberCounterModal).keys().value().length > 0));
        return _serialNumberCounterModal !== null;
        // return _(_serialNumberCounterModal).keys().value().length > 0;
    }
    //=== Edit Event === end ===//


    //2021.10.07
    //=== Edit Event Room === start ===//
    ToggleEditEventRoomModal = async () => {

        if (this.state.PA_Update === false)
            return null;

        //2021.11.25
        if (this.state.IsEventRoomStateDirty === false) {
            //2021.10.12
            let allow = await this.CheckIfAllowToProceed();
            if (allow === false)
                return null;
        }

        this.setState({
            ShowEditEventRoomModal: !this.state.ShowEditEventRoomModal,
            IsEventRoomStateDirty: false,
        }, () => {
            if (this.state.ShowEditEventRoomModal === false) {
                // await Delay(500);
                this.setState({
                    // EditEventRoomState: 0,
                    SelectedEventRoom: null,
                    Temp_TargetEventRooms: [],     //2021.10.12
                }, () => this.ResetEditRoomState());
            }
        });
    }
    EditEventRoom = (room, idx = -1) => {
        // this.props.SetAlert('', JSON.stringify(room), false);
        // console.log(JSON.stringify(room));
        this.setState({
            EditEventRoomState: 1,
            EditEventRoomState_Idx: idx,
            SelectedEventRoom: room,
            EditEventRoomState_RoomCode_Options: 0,
            SearchRoom_ResultList_Completed: false,
        });
    }
    EditEventRoomComponents = () => {
        if (this.state.TargetEventModal === null)
            return null;

        //Massive codes.
        switch (this.state.EditEventRoomState) {
            default: return null;
            case 0:
                return (
                    this.state.TargetEventModal['QuizRooms'] !== null
                        && Array(this.state.TargetEventModal['QuizRooms']).length > 0 ?
                        <table className='table tbStyle' style={{ borderTop: 'hidden' }}>
                            <thead>
                                <tr className='hide-row-hover'>
                                    {/* <td>#</td> */}
                                    <td>Group</td>
                                    <td>Subject</td>
                                    <td>Room Code</td>
                                    {
                                        this.state.TargetEventModal['RoomsHeldOnSelfTime'] ?
                                            <>
                                                <td>Time (From)</td>
                                                <td>Time (To)</td>
                                            </>
                                            : null
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {this.GetTargetEvent_Rooms_TableRows(true)}
                            </tbody>
                        </table>
                        :
                        'no room is found.'
                );
            case 1:
                return (
                    <table className='tb-edit-event-room' cellPadding='5'>
                        <tbody>
                            {
                                //#region === event room details.
                            }
                            <tr>
                                <td className='left'>Current Room Code</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'RoomCode')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Subject</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'Subject')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Group</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'Group')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Time (From)</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'TimeStart')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Time (To)</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'TimeEnd')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Date (From)</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'DateStart')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Date (To)</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'DateEnd')}</td>
                            </tr>
                            <tr>
                                <td className='left'>Time Limit</td>
                                <td>:</td>
                                <td className='right'>{this.GetValue(this.state.SelectedEventRoom, 'TimeLimit')}</td>
                            </tr>
                            {
                                //#endregion
                            }
                            <tr><td colSpan='3'><hr /></td></tr>
                            <tr>
                                <td className='left'>
                                    <Button variant={this.state.EditEventRoomState_RoomCode_Options === 1 ? 'secondary' : 'primary'}
                                        onClick={() => this.setState({
                                            EditEventRoomState_RoomCode_Options: 1, SearchRoom_ByRoomCode: '', SearchRoom_ResultList: [],
                                        }, () => {
                                            this.ManageRoomRef.current.ToggleCreateRoomModal();
                                            // setTimeout(() => {
                                            //     this.ManageRoomRef.current.ToggleCreateRoomModal();      //testing response.
                                            // }, 2000);
                                        })}
                                        disabled={this.state.EditEventRoomState_RoomCode_Options === 1}
                                    >New Room</Button>
                                </td>
                                <td>&nbsp;</td>
                                <td className='right'>
                                    <Button variant={this.state.EditEventRoomState_RoomCode_Options === 2 ? 'secondary' : 'primary'}
                                        onClick={() => this.setState({ EditEventRoomState_RoomCode_Options: 2, SearchRoom_ByRoomCode: '', SearchRoom_ResultList: [], })}
                                        disabled={this.state.EditEventRoomState_RoomCode_Options === 2}>Search Room</Button>
                                </td>
                            </tr>
                            {
                                //#region === New Room.
                            }
                            {
                                //2021.11.20 - revamped - disabled as not needed atm.
                                // //New Room.
                                // this.state.EditEventRoomState_RoomCode_Options === 1 ?
                                //     <tr>
                                //         <td colSpan='3'>
                                //         </td>
                                //     </tr>
                                //     : null
                            }
                            {
                                //#endregion    === New Room === end
                            }
                            {
                                //#region === Search Room by Room Code.
                            }
                            {
                                this.state.EditEventRoomState_RoomCode_Options === 2 ?
                                    <tr>
                                        <td colSpan='3'>
                                            <table cellPadding='15' className='tb-search-room'>
                                                <tbody>
                                                    <tr className='title'>
                                                        <td colSpan='3'>Search Room by Room Code</td>
                                                    </tr>
                                                    <tr className='item'>
                                                        <td align='right'>Room Code</td>
                                                        <td>
                                                            <input type='text' width='100%' placeholder='(e.g. 82716)'
                                                                ref={this.SearchRoom_ByRoomCode_InputRef}
                                                                onChange={(e) => this.setState({
                                                                    SearchRoom_ByRoomCode: e.currentTarget.value.trim(),
                                                                    SearchRoom_ResultList: [],
                                                                }, () => {
                                                                    this.SearchRoom_ByRoomCode_InputRef.current.value = this.state.SearchRoom_ByRoomCode;
                                                                })} />
                                                        </td>
                                                        <td>
                                                            <Button variant="primary" onClick={this.SearchRoom}
                                                                disabled={this.state.SearchRoom_ByRoomCode === ''}
                                                            >Search</Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    : null
                            }
                            {
                                this.state.SearchRoom_ByRoomCode === '' ?
                                    null
                                    :
                                    this.state.SearchRoom_ResultList === null ?
                                        <tr><td colSpan='3' align='center'><ProgressBar animated now={100} className='progressbar1' /></td></tr>
                                        :
                                        this.state.SearchRoom_ResultList_Completed ?
                                            this.state.SearchRoom_ResultList.length > 0 ?
                                                <tr>
                                                    <td colSpan='3'>
                                                        <b>Result (Room Code: {this.state.SearchRoom_ByRoomCode})</b><br />
                                                        <table cellPadding='15' className='tb-search-room' border='0'>
                                                            <tbody>
                                                                {
                                                                    this.state.SearchRoom_ResultList.map((data, key) => {
                                                                        if (data === null) {
                                                                            return (<tr><td colSpan='3' align='center'>no result</td></tr>);
                                                                        }
                                                                        else {
                                                                            return <>
                                                                                <tr className='result'>
                                                                                    <td className='title'>Group</td>
                                                                                    <td>{this.GetValue(data, 'GroupId')}</td>
                                                                                    <td className='action' rowSpan='3'>
                                                                                        <Button
                                                                                            variant='primary'
                                                                                            // variant={this.CheckIfResultIsMatched(data) ? 'primary' : 'secondary'}
                                                                                            // disabled={this.CheckIfResultIsMatched(data) === false}
                                                                                            onClick={async () => await this.SelectRoomFromSearchResult(data)}
                                                                                        >Select</Button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr className='result'>
                                                                                    <td className='title'>Room Title</td>
                                                                                    <td>{this.GetValue(data, 'RoomTitle')}</td>
                                                                                </tr>
                                                                                <tr className='result'>
                                                                                    <td className='title'>Subject</td>
                                                                                    <td>{this.GetValue(data, 'Subject')}</td>
                                                                                </tr>
                                                                                {
                                                                                    this.CheckIfResultIsMatched(data) ?
                                                                                        <tr className='result-match'><td colSpan='3'>This room's Group & Subject are matched.</td></tr>
                                                                                        : <tr className='result-not-match'><td colSpan='3'>This room's Group or Subject is not match.</td></tr>
                                                                                }
                                                                            </>
                                                                        }
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                : <tr><td colSpan='3' align='center'>- no result -</td></tr>
                                            : null
                            }
                            {
                                //#endregion    === Search Room by Room Code === end
                            }
                        </tbody>
                    </table>
                );
        }
    }
    EditEventRoomModalSize = () => {
        switch (this.state.EditEventRoomState) {
            default: return '';
            case 0: return 'lg';
            case 1: return '';
        }
    }
    ResetEditRoomState = () => {
        this.setState({
            EditEventRoomState: 0,
            EditEventRoomState_Idx: -1,
            EditEventRoomState_RoomCode_Options: 0,
            SearchRoom_ByRoomCode: '',
            SearchRoom_ResultList: [],
        });
    }
    SearchRoom = async () => {
        this.setState({
            SearchRoom_ResultList: null,
            // SearchRoom_Result_SelectedRoom: null,
            SearchRoom_ResultList_Completed: false,
        });
        let _resultList = [];     //[null];
        await this.props.firestore
            .collection('LiveQuiz_UniqueRoomCode')
            .where('RoomCode', '==', Number(this.state.SearchRoom_ByRoomCode))
            .get()
            .then(querySnapshot => {
                let dataArray = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        dataArray.push(doc.data());
                    });
                    if (dataArray.length > 0) {
                        _resultList = dataArray;
                    }
                }
                // if (this.props.isDevMode)
                //     console.log(JSON.stringify(_resultList));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log('search room code (error) =\n' + error);
            });
        if (_resultList.length > 0) {
            //2022.10.26
            for (let k = 0; k < _resultList.length; k++) {
                let room = _resultList[k];
                if (room.Subject === undefined || room.SubjectName !== undefined) {
                    room.Subject = String(room.SubjectName);
                    delete room.SubjectName;
                }
                _resultList[k] = room;
            }
        }
        this.setState({
            // SearchRoom_ResultList: [{ RoomCode: 82761, RoomTitle: 'title of the room', GroupId: 25, SubjectName: 'MixedQuestions' }],
            SearchRoom_ResultList: _resultList,
            SearchRoom_ResultList_Completed: true,
        }, () => {
            this.ScrollTo('editEventRoomUiBottom');
        });
    }
    //2021.10.08
    SelectRoomFromSearchResult = async (room) => {

        //2022.10.26
        if (room.Subject === undefined || room.SubjectName !== undefined) {
            room.Subject = String(room.SubjectName);
            delete room.SubjectName;
        }
        if (this.props.isDevMode)
            console.log(JSON.stringify(room));

        // this.setState({
        //     SearchRoom_Result_SelectedRoom: room,   //set
        // }, ()=> {
        let eventRooms = this.state.TargetEventRooms;
        eventRooms.sort((a, b) => a.GroupId - b.GroupId);

        let selectedEventRoom = this.state.SelectedEventRoom;

        let _findIndex = eventRooms.findIndex(x => Number(x.GroupId) === Number(selectedEventRoom.GroupId));
        // let _findIndex = eventRooms.findIndex(x => x.GroupId === selectedEventRoom.GroupId && x.Subject === selectedEventRoom.Subject);
        // let _findIndex = eventRooms.findIndex(x => JSON.stringify(x) === JSON.stringify(selectedEventRoom));

        //2022.10.26
        if (_findIndex < 0 && this.state.EditEventRoomState_Idx > -1) {
            _findIndex = this.state.EditEventRoomState_Idx;
        }

        if (_findIndex > -1) {
            // // if (eventRooms[_findIndex].RoomCode === 'TBA') {
            // if (String(eventRooms[_findIndex].RoomCode) === 'TBA'
            //     || String(eventRooms[_findIndex].RoomCode).length < 5
            //     || String(eventRooms[_findIndex].RoomCode) !== String(room.RoomCode)) {   //2022.06.11
            //     eventRooms[_findIndex].RoomCode = room.RoomCode;
            //     selectedEventRoom.RoomCode = room.RoomCode;
            //     // console.log(JSON.stringify(eventRooms[_findIndex]));

            //     //2022.10.26
            //     eventRooms[_findIndex].Subject = selectedEventRoom.Subject;
            //     eventRooms[_findIndex].SubjectId = selectedEventRoom.SubjectId;
            //     eventRooms[_findIndex].DateStart = room.DateStart;
            //     eventRooms[_findIndex].DateEnd = room.DateEnd;
            //     eventRooms[_findIndex].TimeStart = room.TimeStart;
            //     eventRooms[_findIndex].TimeEnd = room.TimeEnd;
            //     eventRooms[_findIndex].TimeLimit = room.TimeLimit;
            // }

            //2022.10.26
            if (eventRooms[_findIndex] !== undefined) {
                if (String(eventRooms[_findIndex].RoomCode) === 'TBA'
                    || String(eventRooms[_findIndex].RoomCode).length < 5
                    || String(eventRooms[_findIndex].RoomCode) !== String(room.RoomCode)) {

                    eventRooms[_findIndex].RoomCode = room.RoomCode;
                    selectedEventRoom.RoomCode = room.RoomCode;

                    eventRooms[_findIndex].DateStart = room.DateStart;
                    eventRooms[_findIndex].DateEnd = room.DateEnd;
                    eventRooms[_findIndex].TimeStart = room.TimeStart;
                    eventRooms[_findIndex].TimeEnd = room.TimeEnd;
                    eventRooms[_findIndex].TimeLimit = room.TimeLimit;
                }
                if (String(eventRooms[_findIndex].Subject) === 'TBA'
                    || Number(eventRooms[_findIndex].SubjectId) === 0
                    || String(eventRooms[_findIndex].Subject) !== String(room.Subject)
                    || Number(eventRooms[_findIndex].SubjectId) !== Number(room.SubjectId)) {

                    eventRooms[_findIndex].Subject = room.Subject;
                    eventRooms[_findIndex].SubjectId = room.SubjectId;
                }
                eventRooms[_findIndex].EventCode = String(this.state.TargetEventModal.EventCode);   //2022.06.11
            }
        }
        if (this.props.isDevMode)
            console.log('eventRooms =\n' + JSON.stringify(eventRooms));

        // let targetEvent = this.state.TargetEventModal;
        // targetEvent.QuizRooms.map((group, key1) => {
        //     if (Number(group.GroupId) === Number(selectedEventRoom.GroupId)) {
        //         group.Rooms.map((groom, key2) => {
        //             // if (groom.Subject === selectedEventRoom.Subject) {
        //             //     groom.RoomCode = room.RoomCode;
        //             // }

        //             //2022.10.26
        //             if (Number(groom.SubjectId) === 0 || String(groom.Subject).length === 0) {
        //                 groom.Subject = selectedEventRoom.Subject;
        //                 groom.SubjectId = selectedEventRoom.SubjectId;
        //             }
        //             if (String(groom.RoomCode) === 'TBA' || String(groom.RoomCode).length < 5) {
        //                 groom.RoomCode = room.RoomCode;
        //                 groom.DateStart = room.DateStart;
        //                 groom.DateEnd = room.DateEnd;
        //                 groom.TimeStart = room.TimeStart;
        //                 groom.TimeEnd = room.TimeEnd;
        //                 groom.TimeLimit = room.TimeLimit;
        //             }
        //             return null;
        //         });
        //     }
        //     return null;
        // });

        //2022.10.26
        let targetEvent = { ...this.state.TargetEventModal };
        targetEvent.QuizRooms.sort((a, b) => a.GroupId - b.GroupId);
        for (let e = 0; e < targetEvent.QuizRooms.length; e++) {
            let group = { ...targetEvent.QuizRooms[e] };
            if (Number(group.GroupId) === Number(selectedEventRoom.GroupId)) {
                if (group.Rooms.length > 0) {
                    for (let f = 0; f < group.Rooms.length; f++) {
                        let groom = { ...group.Rooms[f] };
                        if (Number(groom.SubjectId) === 0 || String(groom.Subject).length === 0) {
                            groom.Subject = room.Subject;
                            groom.SubjectId = room.SubjectId;
                        }
                        if (String(groom.RoomCode) === 'TBA' || String(groom.RoomCode).length < 5) {
                            groom.RoomCode = room.RoomCode;
                            groom.DateStart = room.DateStart;
                            groom.DateEnd = room.DateEnd;
                            groom.TimeStart = room.TimeStart;
                            groom.TimeEnd = room.TimeEnd;
                            groom.TimeLimit = room.TimeLimit;
                        }
                        group.Rooms[f] = groom;
                    }
                }
                else {
                    group.Rooms.push({
                        // Group: group.Group,
                        // GroupId: group.GroudId,
                        Subject: room.Subject,
                        SubjectId: room.SubjectId,
                        RoomCode: room.RoomCode,
                        DateStart: room.DateStart,
                        DateEnd: room.DateEnd,
                        Date: room.Date,
                        TimeStart: room.TimeStart,
                        TimeEnd: room.TimeEnd,
                        TimeLimit: room.TimeLimit,
                        ExtraUrl: room.ExtraUrl,
                    });
                }
                targetEvent.QuizRooms[e] = group;
            }
        }
        if (this.props.isDevMode)
            console.log('targetEvent =\n' + JSON.stringify(targetEvent));

        //2021.10.12
        let _temp = this.state.Temp_TargetEventRooms;
        if (_temp.length > 0) {
            _temp.sort((a, b) => a.GroupId - b.GroupId);
            // _temp[_findIndex].matched = this.CheckIfResultIsMatched(room);
            if (_temp[_findIndex] !== undefined) {
                if (_temp[_findIndex].GroupId !== undefined
                    && _temp[_findIndex].Subject !== undefined
                    && _temp[_findIndex].RoomCode !== undefined) {

                    _temp[_findIndex].RoomCode = room.RoomCode;

                    //2022.10.26
                    if (Number(_temp[_findIndex].SubjectId) === 0
                        || String(_temp[_findIndex].Subject) === 'TBA'
                        || String(_temp[_findIndex].Subject).length === 0) {

                        _temp[_findIndex].Subject = room.Subject;
                        _temp[_findIndex].SubjectId = room.SubjectId;
                        _temp[_findIndex].DateStart = room.DateStart;
                        _temp[_findIndex].DateEnd = room.DateEnd;
                        _temp[_findIndex].TimeStart = room.TimeStart;
                        _temp[_findIndex].TimeEnd = room.TimeEnd;
                        _temp[_findIndex].TimeLimit = room.TimeLimit;

                        _temp[_findIndex].matched = true;
                    }
                    else {
                        if (Number(_temp[_findIndex].GroupId) === Number(room.GroupId)
                            && Number(_temp[_findIndex].SubjectId) === Number(room.SubjectId)) {
                            _temp[_findIndex].matched = true;
                        }
                        else {
                            _temp[_findIndex].matched = false;
                            _temp[_findIndex].room = room;      //extra info to show on Ui for not-match-only.
                        }
                    }
                }
            }
        }

        // console.log('selectedEventRoom =\n' + JSON.stringify(selectedEventRoom));
        // console.log('eventRooms =\n' + JSON.stringify(eventRooms));
        // console.log('_findIndex = ' + _findIndex);
        // // console.log(_temp[_findIndex].GroupId + ' vs ' + room.GroupId);
        // // console.log(_temp[_findIndex].Subject + ' vs ' + room.SubjectName);
        // console.log('_temp =\n' + JSON.stringify(_temp));
        // console.log('_temp[_findIndex] =\n' + JSON.stringify(_temp[_findIndex]));
        // console.log('room =\n' + JSON.stringify(room));



        this.setState({
            TargetEventModal: targetEvent,
            TargetEventRooms: eventRooms,
            SelectedEventRoom: selectedEventRoom,
            // SearchRoom_Result_SelectedRoom: null,   //reset

            Temp_TargetEventRooms: _temp,

            IsEventRoomStateDirty: true,    //2021.11.25
        }, async () => {
            // await Delay(200);
            this.ResetEditRoomState();
            // this.ToggleViewEventModal();
            // this.ViewEventDetail(this.state.TargetEventIndex);
        });
        // });
    }
    CheckIfResultIsMatched = (room) => {
        //room = found room from LiveQuiz_UniqueRoomCode.
        // return this.state.TargetEventRooms.findIndex(x => x.GroupId === room.GroupId && x.Subject === room.SubjectName) > -1;

        // //2021.10.12
        // let _findIndex = -1;
        // if (room !== undefined) {
        //     let _temp = this.state.TargetEventRooms;
        //     _temp.sort((a, b) => a.GroupId - b.GroupId);
        //     _temp.map((data, key) => {
        //         if (data.hasOwnProperty('GroupId') && room.hasOwnProperty('GroupId')) {
        //             if (data.GroupId !== undefined && room.GroupId !== undefined) {
        //                 if (data.GroupId === room.GroupId && data.Subject === room.SubjectName) {
        //                     _findIndex = key;
        //                 }
        //             }
        //         }
        //         return null;
        //     });
        // }
        // return _findIndex > -1;

        // //2021.10.12
        // let _temp = this.state.TargetEventRooms;
        // _temp.sort((a, b) => a.GroupId - b.GroupId);
        // let _findIndex = this.state.TargetEventRooms.findIndex(x => JSON.stringify(x) === JSON.stringify(this.state.SelectedEventRoom));
        // return (_temp[_findIndex].GroupId === room.GroupId && _temp[_findIndex].SubjectId === room.SubjectId);

        //2022.10.26
        let _temp = this.state.TargetEventRooms;
        let _findIndex = this.state.TargetEventRooms.findIndex(x => Number(x.GroupId) === Number(this.state.SelectedEventRoom.GroupId));
        if (Number(_temp[_findIndex].SubjectId) > 0)
            return (Number(_temp[_findIndex].GroupId) === Number(room.GroupId) && Number(_temp[_findIndex].SubjectId) === Number(room.SubjectId));
        else
            return true;
    }
    CheckIfAllowToProceed = async () => {
        if (!this.state.ShowEditEventRoomModal === false) {
            let _temp = this.state.Temp_TargetEventRooms;
            if (_temp.length > 0) {
                _temp.sort((a, b) => a.GroupId - b.GroupId);
                // console.log(JSON.stringify(this.state.Temp_TargetEventRooms));

                let isRoomNotMatch = false;
                _temp.map((data, key) => {
                    if (data.RoomCode !== 'TBA')
                        if (data.matched === false)
                            isRoomNotMatch = true;
                    return null;
                });
                if (isRoomNotMatch) {
                    this.props.SetAlert('Attention Needed', 'Please check if all rooms are matched with its Group & Subject before proceed.');
                    return false;
                }
            }
        }
        return true;
    }
    ClearRoomAllocation = async (room) => {
        if (this.state.Temp_TargetEventRooms.length > 0) {

            let _temp = this.state.Temp_TargetEventRooms;
            _temp.sort((a, b) => a.GroupId - b.GroupId);

            let index = -1;
            _temp.map((data, key) => {
                // if (data.GroupId !== undefined && data.Subject !== undefined)
                // if (room !== undefined)
                //     if (room.GroupId !== undefined && room.SubjectName !== undefined)
                if (data !== undefined)
                    if (room.GroupId === data.GroupId && room.Subject === data.Subject)
                        index = key;
                return null;
            });

            // console.log(JSON.stringify(room));
            // console.log('index = ' + index);
            // console.log(JSON.stringify(_temp));
            // console.log(JSON.stringify(this.state.TargetEventRooms[index]));

            //clear edit target rooms.
            let _rooms = this.state.TargetEventRooms;
            if (_rooms[index] !== undefined)
                _rooms[index].RoomCode = 'TBA';

            //clear event's rooms.
            let _targetEvent = this.state.TargetEventModal;
            if (_temp[index] !== undefined) {
                _targetEvent.QuizRooms.map((groups, key1) => {
                    if (groups.GroupId === _temp[index].GroupId) {
                        groups.Rooms.map((groom, key2) => {
                            if (groom.Subject === _temp[index].Subject) {
                                groom.RoomCode = 'TBA';
                            }
                            return null;
                        });
                    }
                    return null;
                });
            }

            //clear edit temp rooms.
            // let _temp = this.state.Temp_TargetEventRooms;
            if (_temp[index] !== undefined) {
                _temp[index].matched = false;
                _temp[index].RoomCode = 'TBA';
                delete _temp[index].room;
                // _temp[index] = undefined;
                // _temp[index] = null;
            }

            // await Delay(200);
            this.setState({
                Temp_TargetEventRooms: _temp,
                TargetEventRooms: _rooms,
                TargetEventModal: _targetEvent,
            });
        }
    }
    //2021.11.20
    CancelCreateNewRoom_Callback = () => {
        this.setState({ EditEventRoomState_RoomCode_Options: 0, SearchRoom_ByRoomCode: '', SearchRoom_ResultList: [], });
        // setTimeout(() => {
        //     this.setState({ EditEventRoomState_RoomCode_Options: 0, });      //testing response.
        // }, 2000);
    }
    //2021.11.20 - revamped.
    CreateNewRoomIsCompleted_Callback = (roomCode) => {
        this.setState({
            EditEventRoomState_RoomCode_Options: 2,
            SearchRoom_ByRoomCode: roomCode,
            SearchRoom_ResultList: [],
        }, async () => {
            this.SearchRoom_ByRoomCode_InputRef.current.value = roomCode;
            await Delay(500);
            this.SearchRoom();
        });
    }
    //2021.11.25
    SaveEventRoomState = async () => {
        if (this.state.IsEventRoomStateDirty) {

            if (this.state.TargetEventModal.hasOwnProperty('Id') === false)
                return null;

            this.props.SetLoading('', 'saving event rooms...', true);

            let updateModal = {
                QuizRooms: this.state.TargetEventModal.QuizRooms,
            };

            if (this.props.isDevMode)
                console.log('\nQuizRooms =\n' + JSON.stringify(updateModal));

            await this.props.firestore
                .collection('LiveQuiz_UpcomingEvents')
                .doc(String(this.state.TargetEventModal.Id))
                .update(JSON.parse(JSON.stringify(updateModal)))
                .then(() => { if (this.props.isDevMode) { console.log('update rooms (done)'); } })
                .catch(error => { if (this.props.isDevMode) { console.log('update rooms (failed) =\n' + error.message); } });

            await Delay(500);

            let syncSuccess = await this.TriggerToSyncQuizEventSettingsViaApi(String(this.state.TargetEventModal.EventCode));
            if (syncSuccess === false) {
                if (this.props.isDevMode)
                    console.log('Failed to sync event setting.');
            }

            await Delay(1500);

            //done.
            // this.ResetEditRoomState();
            this.ToggleEditEventRoomModal();
            this.ToggleViewEventModal();
            await Delay(500);
            this.ViewEventDetail(this.state.TargetEventIndex, true);
            this.props.CloseAlert();
            await Delay(500);
            this.props.SetAlert('', 'Event setting has been updated.', false);
        }
    }
    //2021.11.26
    GetRoomInfo = async (_roomCode) => {
        let done = false;
        let _counter = 0;
        // let _success = false;
        let _roomInfo = null;
        let async_action = async () => {
            await this.props.firestore
                .collection("LiveQuiz_UniqueRoomCode")
                .where('RoomCode', '==', Number(_roomCode))
                .orderBy('DateStart', 'asc')
                .get()
                .then(querySnapshot => {
                    let data = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        if (data.length > 0)
                            data = data[0];
                        else
                            data = null;
                    }
                    if (data !== null && data.hasOwnProperty('RoomId')) {
                        _roomInfo = data;
                    }
                    if (this.props.isDevMode)
                        console.log("\nGetRoomInfo =\nRoom Code : " + _roomCode + "\nModal : " + JSON.stringify(_roomInfo));
                    // _success = true;
                    _counter++;
                    done = true;
                })
                .catch(error => {
                    // _success = false;
                    done = true;
                    if (this.props.isDevMode)
                        console.log('\nGetRoomInfo =\nbad Internet connection - retrying...\n\n' + error.message);
                });
            await DelayUntil(() => done === true);
        }
        do {
            // _success = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
            await Delay(500);
            // } while (_success === false);
        } while (_roomInfo === null && _counter < 5);

        // //2022.10.26
        // if (_roomInfo.Subject === undefined || _roomInfo.SubjectName !== undefined) {
        //     _roomInfo.Subject = String(_roomInfo.SubjectName);
        //     delete _roomInfo.SubjectName;
        // }

        return _roomInfo;
    }
    //=== Edit Event Room === end ===//


    // //=== Edit Event Room - Subject === start ===//
    // ToggleEditModal_EventRoom_Subject = (groupId = 0) => {
    //     this.setState({
    //         EditModal_EventRoom_Subject: !this.state.EditModal_EventRoom_Subject,
    //     });
    // }
    // //=== Edit Event Room - Subject === end ===//


    //2021.12.09
    //=== View Mode - Upload File (Cert Img / PAJSK pdf) === start ===//
    ToggleFileUploadUi = () => {
        if (this.state.PA_Update === false)
            return null;

        this.setState({
            ToggleFileUploadUi: !this.state.ToggleFileUploadUi,
        }, () => {
            if (this.state.ToggleFileUploadUi === false) {
                //Reset States.
                if (this.state.UploadState_CertImage === UploadState.Success
                    || this.state.UploadState_PAJSK === UploadState.Success) {
                    //done.
                    this.ActionsAfterUploadSuccess();
                }
                // this.setState({
                //     UploadFileMode: FileMode.None,
                //     UploadState_CertImage: UploadState.Browse,
                //     UploadState_PAJSK: UploadState.Browse,
                // }, () => {
                //     if (this.state.UploadState_CertImage === UploadState.Success
                //         || this.state.UploadState_PAJSK === UploadState.Success) {
                //         //done.
                //         this.ActionsAfterUploadSuccess();
                //     }
                // });
            }
            else {
                //Reset All related States.
                this.setState({
                    UploadState_CertImage: UploadState.Browse,
                    UploadState_PAJSK: UploadState.Browse,
                    FileToUpload: null,
                    UploadErrorMessage: [],
                });
            }
        });
    }
    ActionsAfterUploadSuccess = async () => {
        let _isViewing = this.state.ShowViewEventModal;
        await Delay(500);
        this.ToggleViewEventModal();
        await this.LoadEventList();     //refresh event list.
        await Delay(300);
        this.props.CloseAlert();
        if (this.state.EventList.length > 0) {
            if (_isViewing)
                await this.ViewEventDetail(this.state.TargetEventIndex);  //2021.12.09
            else
                await this.EditEvent();   //reopen edit event.
            await Delay(1000);
            // this.props.SetAlert('', 'Event &lt;<b> '
            //     + (this.state.TargetEventModal === null ? '' : String(this.state.TargetEventModal.EventName))
            //     + ' </b>&gt; has been save.', false);
            this.props.SetAlert('', Locale("file-upload-success", this.props.Locale), false);
        }
        else {
            this.props.SetAlert('', 'Event save unsuccessful.<br />list is empty.', false);
        }
        //Reset All related States.
        this.setState({
            UploadState_CertImage: UploadState.Browse,
            UploadState_PAJSK: UploadState.Browse,
            FileToUpload: null,
            UploadErrorMessage: [],
        });
    }
    Get_FileName_Long = (text = '') => {
        // let text = String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']);
        let eventCode = this.state.TargetEventEditedModal['EventCode'];
        if (text.includes(eventCode))
            text = text.replace('https://ikeynew.blob.core.windows.net/ikeykidz/CERT/' + eventCode + '/', '');
        else
            text = text.replace('https://ikeynew.blob.core.windows.net/ikeykidz/CERT/', '');
        return text;
    }
    Get_FileName_Short = (text = '') => {
        // let text = String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']);
        let eventCode = this.state.TargetEventEditedModal['EventCode'];
        if (text.includes(eventCode))
            text = text.replace('ikeynew.blob.core.windows.net/ikeykidz/CERT/' + eventCode, '{hidden}');
        else
            text = text.replace('ikeynew.blob.core.windows.net/ikeykidz/CERT', '{hidden}');

        if (text.length > 50) {
            let url = 'https://{hidden}/';
            let ext = '';
            let lowerText = text.toLowerCase();
            if (lowerText.includes('.pdf'))
                ext = '.pdf';
            else if (lowerText.includes('.rtf'))
                ext = '.rtf';
            else if (lowerText.includes('.txt'))
                ext = '.txt';
            else if (lowerText.includes('.doc'))
                ext = '.doc';
            else if (lowerText.includes('.docx'))
                ext = '.docx';
            else if (lowerText.includes('.jpg'))
                ext = '.jpg';
            else if (lowerText.includes('.png'))
                ext = '.png';
            text = text.replace(url, '').replace(ext, '');
            let front = text.substring(0, 15);
            let rear = text.substring(text.length - 10, text.length);
            text = url + front + '...' + rear + ext;
        }
        return text;
    }
    ViewEventMode_Upload_File = (fileMode = FileMode.None) => {
        // this.props.SetLoading('', 'Uploading...', true);

        if (this.state.TargetEventModal === null || this.state.TargetEventModal.hasOwnProperty('Id') === false) {
            this.props.SetAlert('', 'Invalid Event.', false);
            return null;
        }

        this.setState({
            UploadFileMode: fileMode,
            UploadState_CertImage: UploadState.Browse,
            UploadState_PAJSK: UploadState.Browse,
        }, () => {
            this.ToggleFileUploadUi();
        });
    }
    FileUploadComponentUi = () => {

        let _state = 0;
        if (this.state.UploadFileMode === FileMode.CertImg)
            _state = this.state.UploadState_CertImage;
        else if (this.state.UploadFileMode === FileMode.PAJSK)
            _state = this.state.UploadState_PAJSK;

        //0 = browse file, 1 = wrong file format, 2 = uploading, 3 = upload failed, 4 = upload success.
        switch (_state) {
            default: return null;
            case UploadState.Browse:
                return (<>
                    <span>Browse for a file to upload </span><br />
                    <span style={{ color: 'gray', paddingBottom: 10, }}>(supported file format: <b><i>{this.GetSupportedFileExt_Label(this.state.UploadFileMode)}</i></b> )</span><p />
                    <input
                        type="file"
                        name="file"
                        accept={this.GetSupportedFileExt_Accept(this.state.UploadFileMode)}
                        onChange={this.onUploadFileChange}
                        style={{ width: '100%' }}
                        disabled={this.state.PA_Update === false}
                    />
                </>);
            case UploadState.Validation:
                return (<>
                    <span>Validating file...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
            case UploadState.Uploading:
                return (<>
                    <span>Uploading file...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
            case UploadState.Failed:
                return (<>
                    {this.state.UploadErrorMessage.map((data, key) => { return <>{data}<br /></>; })}
                    <button type='button btn-primary'
                        onClick={() => this.setState({ FileToUpload: null, FileUploadState: UploadState.Browse, UploadErrorMessage: [], })}>Retry</button>
                </>);
            case UploadState.Success:
                return (<>
                    <span>Updating data...</span>
                    <ProgressBar animated now={100} style={{ marginTop: 7, }} />
                </>);
        }
    }
    onUploadFileChange = (event) => {

        this.props.SetAlertWithProgressBar('', 'uploading picture...', true);

        if (event === null) {
            this.props.SetAlert('', 'Invalid Operation.');
        }
        else {
            this.setState({
                FileToUpload: event === null ? null : event.target.files[0],
                ToggleUploadFileUi_ViewMode: event === null ? false : !this.state.ToggleUploadFileUi_ViewMode,
            }, async () => {

                //Set UploadState.
                this.SetFileModeUploadState(UploadState.Validation);

                //FileType. FileExtension.
                let _fileName = String(this.state.FileToUpload.name);
                let _fileType = this.GetFileType(_fileName.toLowerCase());
                let _fileExt = this.GetFileExt(_fileName.toLowerCase());

                //FileName.
                _fileName = String(this.state.FileToUpload.name)
                    .replace(_fileExt, '')
                    // .replaceAll(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-')     //to replace all possible symbols & spaces to -.
                    .replace(/[^a-zA-Z0-9]/g, '-')
                    + '_' + this.RandomId();
                // let _fileName = String(this.state.FileToUpload.name)
                //     .replaceAll(/[$-/:-?{-~!"^_`\[\]\@\#\s]/g, '-')     //to replace all possible symbols & spaces to -.
                //     + '_' + this.RandomId();
                // let _fileName = this.RandomId();

                //Convert File to Base64.
                let _base64 = '';
                if (this.state.FileToUpload !== null) {
                    let reader = new FileReader();
                    reader.onload = (evt) => {
                        let binaryString = evt.target.result;
                        _base64 = btoa(binaryString);
                        // console.log('inner : ' + _base64);
                    };
                    reader.readAsBinaryString(this.state.FileToUpload);
                }

                //Set property name for api.
                let _propertyName = '';
                if (this.state.UploadFileMode === FileMode.CertImg)
                    _propertyName = 'CertImage';
                else if (this.state.UploadFileMode === FileMode.PAJSK)
                    _propertyName = 'GovRecognitionLetter';
                else if (this.state.UploadFileMode === FileMode.CertImgTop100)  //2022.09.30
                    _propertyName = 'CertImage_Top100';
                else if (this.state.UploadFileMode === FileMode.CertImgHonor)   //2022.09.30
                    _propertyName = 'CertImage_Honor';

                //Set UploadState.
                await Delay(1000);
                this.SetFileModeUploadState(UploadState.Uploading);

                //Upload picture file.
                this.UploadFileForQuizEventSettings(_fileName, _fileExt, _fileType, _base64, _propertyName);
            });
        }
    };
    SetFileModeUploadState = (_state = UploadState.Browse) => {
        if (this.state.UploadFileMode === FileMode.CertImg) {
            this.setState({ UploadState_CertImage: _state, });
        }
        else if (this.state.UploadFileMode === FileMode.PAJSK) {
            this.setState({ UploadState_PAJSK: _state, });
        }
    }
    UploadFileForQuizEventSettings = async (_fileName = '', _fileExt = '', _fileType = '', _base64 = '', _propertyName = '') => {

        if (this.props.isDevMode)
            console.log('\nFileName = ' + _fileName + '\nFileExt = ' + _fileExt + '\nFileType = ' + _fileType + '\nFileSize = ' + _base64.length + '\nPropertyName = ' + _propertyName);

        let file = this.state.FileToUpload;

        let isFetchFailed = false;
        try {
            if (file !== null && file !== undefined && _fileName !== '' && _fileExt !== '' && _fileType !== '' && _base64 !== '' && _propertyName !== '') {
                let isUploadDone = false;
                let errorMessage = '';
                let apiResponse = null;

                //check file size.
                if (this.props.isDevMode)
                    console.log('file_base64 = ' + _base64.length);
                if (_base64.length <= 0) {
                    this.setState({ FileUploadState: UploadState.Failed, UploadErrorMessage: ['file size = ' + _base64.length], });
                    return null;
                }

                //modal.
                let jsonModel = {
                    CenterUserId: this.props.isAuthor ? Number(this.props.user.CenterUserId) : '',
                    AuthorId: this.props.isAuthor ? Number(this.props.user.AuthorId) : '',
                    AuthorRoleId: this.props.isAuthor ? Number(this.props.user.AuthorRoleId) : '',    //1 = admin, 4 = center, 11 = Author
                    FirebaseUserId: String(this.props.user.uid),

                    EventCode: String(this.state.TargetEventModal.EventCode),
                    PropertyName: _propertyName,
                    TimeStampId: Number(this.state.TargetEventModal.Id),

                    FileName: _fileName,
                    FileType: _fileType.toLowerCase(),
                    FileExt: _fileExt.toLowerCase(),
                    FileBase64: _base64,
                };
                if (this.props.isDevMode)
                    console.log(JSON.stringify(jsonModel));

                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Event/Settings/File/Upload',
                    {
                        method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(jsonModel), // body data type must match "Content-Type" header
                        //Api/LearningCentre/Event/Settings/File/Upload
                    })
                    .then(res => res.json())
                    .then(data => {
                        isUploadDone = data.success;
                        if (data.success)
                            apiResponse = data;
                        else
                            errorMessage = 'api - event - file upload (failed)\n' + JSON.stringify(data);
                    })
                    .catch(error => {
                        errorMessage = 'Error : ' + error.message;
                    });

                await Delay(1000);
                if (isUploadDone === false) {
                    this.setState({ FileUploadState: UploadState.Failed, UploadErrorMessage: [errorMessage] });
                }
                else {
                    //Set UploadState.
                    this.SetFileModeUploadState(UploadState.Success);

                    //Close Upload File Ui.
                    this.props.CloseAlert();
                    this.ToggleFileUploadUi();
                }
                if (this.props.isDevMode)
                    console.log(errorMessage.length > 0 ? errorMessage : JSON.stringify(apiResponse));
                if (errorMessage.length > 0)
                    isFetchFailed = true;
            }
            else {
                isFetchFailed = true;
            }
        }
        catch (error) {
            isFetchFailed = true;
            if (this.props.isDevMode)
                console.log(error + '\n' + error.message);
        }

        if (isFetchFailed) {
            //Set UploadState.
            this.SetFileModeUploadState(UploadState.Failed);
            this.props.CloseAlert();
            // this.props.SetAlert('Invalid Operation', 'upload failed.');
        }
    }
    GetSupportedFileExt_Accept = (uploadFileMode = FileMode.None) => {
        let fileExts = '';

        let supportedFormats = [];
        if (uploadFileMode === FileMode.CertImg)
            supportedFormats = ['.jpg', '.png'];
        else if (uploadFileMode === FileMode.PAJSK)
            supportedFormats = ['.pdf', '.doc', '.docx', '.rtf'];

        supportedFormats.map((data, key) => {
            if (data.includes('.txt')) {
                fileExts += 'text/plain';
            }
            else if (data.includes('.rtf')) {
                fileExts += 'application/rtf';
            }
            else if (data.includes('.docx')) {
                fileExts += 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            }
            else if (data.includes('.doc')) {
                fileExts += 'application/msword';
            }
            else if (data.includes('.pdf')) {
                fileExts += 'application/pdf';
            }
            else if (data.includes('.jpg')) {
                fileExts += 'image/jpeg';
            }
            else if (data.includes('.png')) {
                fileExts += 'image/png';
            }
            if (key < supportedFormats.length - 1)
                fileExts += ',';
            return null;
        });
        if (fileExts.length > 0)
            return fileExts;

        return 'text/plain,application/rtf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    GetSupportedFileExt_Label = (uploadFileMode = FileMode.None) => {
        let fileExts = '';

        let supportedFormats = [];
        if (uploadFileMode === FileMode.CertImg)
            supportedFormats = ['.jpg', '.png'];
        else if (uploadFileMode === FileMode.PAJSK)
            supportedFormats = ['.pdf', '.doc', '.docx', '.rtf'];

        supportedFormats.map((data, key) => {
            fileExts += data;
            if (key < supportedFormats.length - 1)
                fileExts += ', ';
            return null;
        });
        if (fileExts.length > 0)
            return fileExts;

        return '.txt, .rtf, .doc, .docx, .pdf, .jpg, .png';
    }
    GetFileExt = (fileName) => {
        if (fileName.includes('.txt')) {
            return '.txt';
        }
        else if (fileName.includes('.rtf')) {
            return '.rtf';
        }
        else if (fileName.includes('.docx')) {
            return '.docx';
        }
        else if (fileName.includes('.doc')) {
            return '.doc';
        }
        else if (fileName.includes('.pdf')) {
            return '.pdf';
        }
        else if (fileName.includes('.jpg')) {
            return '.jpg';
        }
        else if (fileName.includes('.png')) {
            return '.png';
        }
        return '';
    }
    GetFileType = (fileName) => {
        if (fileName.includes('.txt')) {
            return 'text/plain';
        }
        else if (fileName.includes('.rtf')) {
            return 'application/rtf';
        }
        else if (fileName.includes('.docx')) {
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        }
        else if (fileName.includes('.doc')) {
            return 'application/msword';
        }
        else if (fileName.includes('.pdf')) {
            return 'application/pdf';
        }
        else if (fileName.includes('.jpg')) {
            return 'image/jpeg';
        }
        else if (fileName.includes('.png')) {
            return 'image/png';
        }
        return '';
    }
    //=== View Mode - Upload File === start ===//

    //2022.05.12 - temporary use.
    UpdateEventRoomButtonComponent = () => {

        let updateToEventQuizRooms = async (event, docId, rooms, loop) => {

            if (this.props.isDevMode)
                console.log('start updating...', docId);
            // console.log('event = ', event);

            let _rooms = event.QuizRooms;
            for (var p = 0; p < loop; p++) {
                //find event room.
                for (var i = 0; i < _rooms.length; i++) {
                    let id = p + 1;
                    if (event.EventCode === '5fc9181ff9214cebb17ab184e54b2281') {  //KD
                        id = 28 + p;
                    }
                    if (Number(_rooms[i].GroupId) === id) {
                        //find room to push.
                        _rooms[i].Rooms = [];
                        // _rooms[i].Rooms.push(rooms[p]);
                        _rooms[i].Rooms.push({
                            Date: 'Jun 11, 2022',
                            DateEnd: '2022-06-11',
                            DateStart: '2022-06-11',
                            ExtraUrl: '',
                            RoomCode: 'TBA',
                            Subject: 'MixedQuestions',
                            SubjectId: 7,
                            TimeEnd: rooms[p].TimeEnd,
                            TimeStart: rooms[p].TimeStart,
                            TimeLimit: rooms[p].TimeLimit
                        });
                        break;
                    }
                }
            }
            if (this.props.isDevMode)
                console.log('rooms = ', _rooms);

            await this.props.firestore
                .collection('LiveQuiz_UpcomingEvents')
                .doc(docId)
                .update({ QuizRooms: _rooms })
                .then(() => {
                    if (this.props.isDevMode)
                        console.log('rooms update done.', docId);
                })
                .catch((err) => {
                    if (this.props.isDevMode)
                        console.log('rooms update failed.', err);
                });


            //update Groups. or QuizGroups.
            let temp_group = [];
            if (event.EventCode === '5fc9181ff9214cebb17ab184e54b2281') {
                for (var pp = 0; pp < 3; pp++) {
                    temp_group.push({ Name: 'KD' + (pp + 4) + ' (' + (pp + 4) + ' years old)', Id: pp + 28 });
                }
            } else {
                for (var ppp = 1; ppp <= 6; ppp++) {
                    temp_group.push({ Name: 'Standard ' + ppp, Id: ppp });
                }
            }
            await this.props.firestore
                .collection('LiveQuiz_UpcomingEvents')
                .doc(docId)
                .update({ Groups: temp_group })
                .then(() => {
                    if (this.props.isDevMode)
                        console.log('rooms update done.', docId);
                })
                .catch((err) => {
                    if (this.props.isDevMode)
                        console.log('rooms update failed.', err);
                });

        };

        const e1_rooms = [{
            TimeEnd: '11:30 AM',
            TimeStart: '10:00 AM',
            TimeLimit: '01:30'
        },
        {
            TimeEnd: '12:00 PM',
            TimeStart: '10:30 AM',
            TimeLimit: '01:30'
        },
        {
            TimeEnd: '12:30 PM',
            TimeStart: '11:00 AM',
            TimeLimit: '01:30'
        },
        {
            TimeEnd: '01:30 PM',
            TimeStart: '11:30 AM',
            TimeLimit: '01:30'
        },
        {
            TimeEnd: '01:30 PM',
            TimeStart: '12:00 PM',
            TimeLimit: '01:30'
        },
        {
            TimeEnd: '02:00 PM',
            TimeStart: '12:30 PM',
            TimeLimit: '01:30'
        }];

        // let e2_rooms = [{
        //     Date: '',
        //     DateEnd: '',
        //     DateStart: '',
        //     ExtraUrl: '',
        //     RoomCode: 'TBA',
        //     Subject: 'MixedQuestions',
        //     SubjectId: 7,
        //     TimeEnd: '',
        //     TimeStart: '',
        //     TimeLimit: ''
        // }];

        const e3_rooms = [{
            TimeEnd: '10:45 AM',
            TimeStart: '09:45 AM',
            TimeLimit: '01:00'
        },
        {
            TimeEnd: '11:45 AM',
            TimeStart: '10:45 AM',
            TimeLimit: '01:00'
        },
        {
            TimeEnd: '12:45 PM',
            TimeStart: '11:45 AM',
            TimeLimit: '01:00'
        }];

        // let _event = null
        let _components = [];

        _components.push(<span>event 1 - sjkc - (5707df5e48d44772ba5d694ea7658776 / 1652261323824)</span>);
        _components.push(<br />);
        let _event1 = this.state.EventList.find(x => x.EventCode === '5707df5e48d44772ba5d694ea7658776');
        _components.push(<button type='button' onClick={() => updateToEventQuizRooms(_event1, '1652261323824', e1_rooms, 6)}>Room Std 1 ~ 6</button>);
        _components.push(<br />);
        _components.push(<br />);

        _components.push(<span>event 2 - sk - (51e5dfdb6fbd498ca8e89cdd6d5ad62a / 1652322378000)</span>);
        _components.push(<br />);
        let _event2 = this.state.EventList.find(x => x.EventCode === '51e5dfdb6fbd498ca8e89cdd6d5ad62a');
        _components.push(<button onClick={() => updateToEventQuizRooms(_event2, '1652322378000', e1_rooms, 6)}>Room Std 1 ~ 6</button>);
        _components.push(<br />);
        _components.push(<br />);

        _components.push(<span>event 3 - kd - (5fc9181ff9214cebb17ab184e54b2281 / 1652323541000)</span>);
        _components.push(<br />);
        let _event3 = this.state.EventList.find(x => x.EventCode === '5fc9181ff9214cebb17ab184e54b2281');
        _components.push(<button onClick={() => updateToEventQuizRooms(_event3, '1652323541000', e3_rooms, 3)}>Room KD 4 ~ 6</button>);
        // for (var c = 0; c < 3; c++) {
        //     _components.push(<button onClick={() => updateToEventQuizRooms(_event3, '1652323541000', e3_rooms[c], c)}>Room Kd {c + 1}</button>);
        //     _components.push(<>&nbsp;&nbsp;</>);
        // }

        return _components;
    }

    //2022.10.15
    LangLabel = (lang = '') => {
        switch (lang) {
            case 'chi': return 'Chinese';
            case 'mal': return 'Malay';
            default: return 'English';
        }
    }
    // LangContent = (lang = '', array = []) => {
    //     const idx = array.findIndex(x => String(x.Lang) === String(lang));
    //     return String(array[idx].Content);
    // }
    SetTargetEventEditedValue_Info = (lang = '', value = '', type = '') => {
        let modal = this.state.TargetEventEditedModal;
        let array = [...modal[type]];
        const idx = array.findIndex(x => String(x.Lang) === String(lang));
        array[idx].Content = value;
        modal[type] = array;
        this.setState({ TargetEventEditedModal: modal, });
    }
    IsEditedStyle_Info = (lang = '', type = '') => {
        const idx = this.state.TargetEventEditedModal[type].findIndex(x => String(x.Lang) === String(lang));
        if (idx > -1) {
            return this.state.TargetEventEditedModal[type][idx].Content
                === this.state.TargetEventModal[type][idx].Content
                ? '' : 'edited'
        }
        return '';
    }


    render = () => {
        return (
            <>
                <QuizBankManageRoom
                    ref={this.ManageRoomRef}

                    user={this.props.user}
                    isSuperAdmin={this.props.isSuperAdmin}

                    Locale={this.props.Locale}
                    SetLocaleSetting={this.props.SetLocaleSetting}

                    firestore={this.props.firestore}     //Everything
                    dbLiveQuiz={this.props.dbLiveQuiz}
                    dbCommon={this.props.dbCommon}
                    // dbQuestion={this.props.dbQuestion}
                    dbLogs={this.props.dbLogs}
                    dbQuizBank={this.props.dbQuizBank}   //Quiz Bank
                    dbLQ_SN_Counter={this.props.dbLQ_SN_Counter}     //2021.11.12

                    SetLoading={this.props.SetLoading}
                    SetAlert={this.props.SetAlert}
                    // SetAlertWithComponent={this.props.SetAlertWithComponent}
                    CloseAlert={this.props.CloseAlert}
                    SetLog={this.props.SetLog}

                    Events={this.props.Events}
                    CheckIfRoomBelongsToEvent={this.props.CheckIfRoomBelongsToEvent}
                    isDevMode={this.props.isDevMode}

                    GroupId={this.props.GroupId}
                    SubjectId={this.props.SubjectId}

                    // props={this.props}

                    //2021.07.27
                    TogglePage={this.props.TogglePage}
                    Toggle={this.props.Toggle}

                    //2021.08.02
                    GroupOptions={this.props.GroupOptions}
                    RefreshGroupOptions={this.props.Refresh_GroupOptions}

                    //2021.09.24
                    GroupList={this.props.GroupList}

                    //2021.10.08
                    SubjectList={this.props.SubjectList}
                    SubjectOptions={this.props.SubjectOptions}

                    //2021.10.13
                    OrganizerInfo={this.props.OrganizerInfo}
                    // OrganizerOptions={this.state.OrganizerOptions}

                    //2021.10.23
                    credential={this.props.credential}  //aka Firebase Login Credential

                    //2021.10.27
                    roomTypeFile={this.props.toggleMenuOption === this.props.Toggle.RoomTypeFile}

                    //2021.11.20
                    CancelCreateNewRoom_Callback={this.CancelCreateNewRoom_Callback}
                    CreateNewRoomIsCompleted_Callback={this.CreateNewRoomIsCompleted_Callback}
                    IsChild={true}
                />
                <Row className='rowStyle'>
                    <Col>
                        <span style={{ fontSize: 20, fontWeight: 'bold' }}>Manage Events</span>
                    </Col>
                    <Col className='colBtn'>
                        {
                            this.state.PA_Create === false ? null :
                                <button type="button" className="btn btn-outline-primary"
                                    onClick={this.CreateNewEvent}
                                    disabled={this.props.isDevMode && this.props.isSuperAdmin ? false : this.props.OrganizerInfo === null}
                                >New Event</button>
                        }
                    </Col>
                </Row>
                <p />

                {/* Event List */}
                <table className='table table-hover table-bordered tbStyle' cellPadding='10' cellSpacing='10' style={{ fontSize: 14 }}>
                    <thead>
                        <tr>
                            <th width='50'>#</th>
                            <th style={{ textAlign: 'left' }}>Event</th>
                            <th width='200' hidden={this.props.isSuperAdmin === false}>Organizer</th>
                            <th width='100'>Begin</th>
                            <th width='100'>End</th>
                            {/* <th width='90'>Role</th> */}
                            <th width='90'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            // this.props.Events !== null ?
                            //     this.EventListComponents()
                            //     : <tr aria-colSpan='7'><td></td></tr>

                            // //2021.10.06
                            // this.state.IsEventListLoaded ?
                            //     this.state.EventList.length > 0 ?
                            //         this.EventListComponents()
                            //         : <tr><td colSpan='15' align='center'>list is empty</td></tr>
                            //     : <tr><td colSpan='15' align='center'><LoadingIndicator /></td></tr>

                            //2022.09.30
                            this.EventListComponents()
                        }
                    </tbody>
                </table>

                {/* Event - View Details - Modal */}
                <Modal size='xl' show={this.state.ShowViewEventModal} onHide={this.ToggleViewEventModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.IsCreateNewEvent ? 'New ' : this.state.ToggleEditEvent ? 'Edit ' : ''}Event : {this.state.TargetEventEditedModal === null ? '' : this.state.TargetEventEditedModal['EventName']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className='table table-hover table-bordered tbStyle1' cellPadding='10' cellSpacing='10' style={{ marginBottom: 0, }}>
                            <tbody className='tbody'>
                                {
                                    this.state.TargetEventModal === null ? null
                                        :
                                        <>
                                            {/* Upper part before View/Edit Room */}
                                            <>
                                                {
                                                    this.state.ToggleEditEvent === false ?
                                                        /* Only View Event Settings */
                                                        <>
                                                            {/* <tr>
                                                                <td width='285'>Role</td>
                                                                <td>{this.state.TargetEventModal['Role']}</td>
                                                            </tr> */}
                                                            <tr>
                                                                <td>Created Date</td>
                                                                <td>{this.state.TargetEventModal['CreatedDate']}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Active</td>
                                                                <td>
                                                                    <input type='checkbox' checked={this.state.TargetEventModal['Active']}
                                                                        className='disabled-pointer-events' disabled={true}></input>
                                                                    &nbsp;&nbsp;<span style={{ color: 'gray', fontSize: 14, position: 'absolute', }}>(checked = open to register/join publicly)</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Event Name</td>
                                                                <td>{this.state.TargetEventModal['EventName']}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Event Code</td>
                                                                <td>{this.state.TargetEventModal['EventCode']}</td>
                                                            </tr>
                                                            {
                                                                this.props.isSuperAdmin === false ? null
                                                                    :
                                                                    <>
                                                                        <tr style={{ color: 'gray' }}>
                                                                            <td>Organizer Code</td>
                                                                            <td>{this.state.TargetEventModal['Organizer']}</td>
                                                                        </tr>
                                                                        <tr style={{ color: 'gray' }}>
                                                                            <td>Organizer Identity</td>
                                                                            <td>{this.state.TargetEventModal['OrganizerIdentity']}</td>
                                                                        </tr>
                                                                        <tr style={{ color: 'gray' }}>
                                                                            <td>Organizer (Display)</td>
                                                                            <td>{this.state.TargetEventModal['OrganizerDisplayName']}</td>
                                                                        </tr>
                                                                    </>
                                                            }
                                                            <tr>
                                                                <td>Event Date (Display)</td>
                                                                <td>{this.state.TargetEventModal['Date']}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Registration End (Display)</td>
                                                                <td>{this.state.TargetEventModal['RegistrationEndDisplayName']}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Registration End (Actual Deadline)</td>
                                                                <td>{this.state.TargetEventModal['RegistrationEnd']}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Registration Overloaded</td>
                                                                <td><input type='checkbox' checked={this.state.TargetEventModal['RegistrationOverloaded']}
                                                                    className='disabled-pointer-events' disabled={true}></input>
                                                                    &nbsp;&nbsp;<span style={{ color: 'gray', fontSize: 14, position: 'absolute', }}>(check this box to stop registration via Login page)</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Active Period{
                                                                    this.state.TargetEventModal['DateStart'] === this.state.TargetEventModal['DateEnd'] ?
                                                                        '' : ' (From ~ To)'
                                                                }</td>
                                                                <td>{
                                                                    moment(this.state.TargetEventModal['DateStart']).format('ll')
                                                                    + (
                                                                        this.state.TargetEventModal['DateStart'] === this.state.TargetEventModal['DateEnd'] ?
                                                                            '' : ' ~ ' + moment(this.state.TargetEventModal['DateEnd']).format('ll')
                                                                    )
                                                                }</td>
                                                            </tr>
                                                            <tr><td colSpan='2' className='row-seperator'></td></tr>
                                                            <tr><td>Time Start (Display)</td><td>{this.state.TargetEventModal['TimeStart']}</td></tr>
                                                            <tr><td>Time End (Display)</td><td>{this.state.TargetEventModal['TimeEnd']}</td></tr>
                                                            <tr>
                                                                <td>Rooms held on own Time Schedule</td>
                                                                <td>
                                                                    <input type='checkbox' checked={this.state.TargetEventModal['RoomsHeldOnSelfTime']}
                                                                        className='disabled-pointer-events' disabled={true}></input>
                                                                    &nbsp;&nbsp;<span style={{ color: 'gray', fontSize: 14, position: 'absolute', }}>(checked = each room follow its own Begin & End time, unchecked = all rooms shared a same universal time)</span>
                                                                </td>
                                                            </tr>
                                                            {
                                                                this.state.TargetEventModal['RoomsHeldOnSelfTime'] === false ?
                                                                    <>
                                                                        <tr><td>Time Start</td><td>{this.state.TargetEventModal['GlobalScheduleTimeStart']}</td></tr>
                                                                        <tr><td>Time End</td><td>{this.state.TargetEventModal['GlobalScheduleTimeEnd']}</td></tr>
                                                                    </>
                                                                    : null
                                                            }
                                                            <tr><td colSpan='2' className='row-seperator'></td></tr>
                                                            <tr>
                                                                <td>Enable Certificate</td>
                                                                <td><input type='checkbox' checked={this.state.TargetEventModal['CertDownloadEnabled']}
                                                                    className='disabled-pointer-events' disabled={true}></input></td>
                                                            </tr>
                                                            {
                                                                this.state.TargetEventModal['CertDownloadEnabled'] === false ? null :
                                                                    <>
                                                                        <tr>
                                                                            <td>Certificate Layout Type</td>
                                                                            <td><DropdownButton
                                                                                id='cert-layout-type-dropdown-button'
                                                                                title={
                                                                                    Number(this.state.TargetEventModal['CertLayoutTypeId']) > 0 ?
                                                                                        CertLayoutTypeOptions[Number(this.state.TargetEventModal['CertLayoutTypeId']) - 1].label
                                                                                        : 'Select a Layout Type'
                                                                                }
                                                                                drop='down'
                                                                                // onSelect={(option) => {
                                                                                //     let _state = this.state.TargetEventEditedModal;
                                                                                //     _state['CertLayoutTypeId'] = Number(option);
                                                                                //     this.setState({ TargetEventEditedModal: _state }, () => {
                                                                                //         if (this.props.isDevMode)
                                                                                //             console.log('item #' + JSON.stringify(option) + '\nCertLayoutTypeId = ' + this.state.TargetEventEditedModal['CertLayoutTypeId']);
                                                                                //     });
                                                                                // }}
                                                                                style={{ width: 515 }}
                                                                                className='disabled-pointer-events'
                                                                                disabled={true}
                                                                            >
                                                                                <Dropdown.Item disabled={true}>Select a Layout Type</Dropdown.Item>
                                                                                {
                                                                                    CertLayoutTypeOptions.map((data, key) => {
                                                                                        return (<Dropdown.Item as="button" eventKey={key + 1} value={key + 1}>{CertLayoutTypeOptions[key].label}</Dropdown.Item>);
                                                                                    })
                                                                                }
                                                                                {/* <Dropdown.Item as="button" eventKey={1} value={1}>{CertLayoutTypeOptions[0].label}</Dropdown.Item>
                                                                                <Dropdown.Item as="button" eventKey={2} value={2}>{CertLayoutTypeOptions[1].label}</Dropdown.Item>
                                                                                <Dropdown.Item as="button" eventKey={3} value={3}>{CertLayoutTypeOptions[2].label}</Dropdown.Item>
                                                                                <Dropdown.Item as="button" eventKey={4} value={4}>{CertLayoutTypeOptions[3].label}</Dropdown.Item> */}
                                                                            </DropdownButton></td>
                                                                        </tr>
                                                                        {
                                                                            this.state.TargetEventModal['CertLayoutTypeId'] === 1 || this.state.TargetEventModal['CertLayoutTypeId'] !== 4 ? null :
                                                                                <tr>
                                                                                    <td>Serial Number Prefix</td>
                                                                                    <td>{this.state.TargetEventModal['CertSerialPrefix']}{String(this.state.TargetEventModal['CertSerialPrefix']).length > 0 ?
                                                                                        ' (e.g. ' + this.state.TargetEventModal['CertSerialPrefix'] + '000001)' : ''}</td>
                                                                                </tr>
                                                                        }
                                                                        {/* <tr>
                                                                            <td>Certificate Image Url</td>
                                                                            <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, }}>
                                                                                <OverlayTrigger overlay={this.state.TargetEventModal.length === 0 ? <span></span> : this.state.TargetEventModal['CertImgUrl'] === '' ? <span></span> :
                                                                                    <Tooltip><img src={String(this.state.TargetEventModal['CertImgUrl'])} alt="" width="100%" /></Tooltip>}>
                                                                                    <span>{String(this.state.TargetEventModal['CertImgUrl']).replace('ikeynew.blob.core.windows.net/ikeykidz/CERT', '{hidden}')}</span>
                                                                                </OverlayTrigger></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Shared Report URL by Organizer<br />(for Document Room)</td>
                                                                            <td>{this.state.TargetEventModal['EventSharedReportUrl']}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Surat Pengikhtirafan PAJSK</td>
                                                                            <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, }}>
                                                                                <OverlayTrigger overlay={this.state.TargetEventModal.length === 0 ? <span></span> : this.state.TargetEventModal['GovRecognitionLetterUrl'] === '' ? <span></span> :
                                                                                    <Tooltip><img src={String(this.state.TargetEventModal['GovRecognitionLetterUrl'])} alt="" width="100%" /></Tooltip>}>
                                                                                    <span>{String(this.state.TargetEventModal['GovRecognitionLetterUrl']).replace('ikeynew.blob.core.windows.net/ikeykidz/PAJSK', '{hidden}')}</span>
                                                                                </OverlayTrigger></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Enable Delayed Download</td>
                                                                            <td><input type='checkbox' checked={this.state.TargetEventModal['CertDownloadDelayed']} className='disabled-pointer-events'></input></td>
                                                                        </tr> */}

                                                                        {
                                                                            this.state.TargetEventEditedModal === null ? null
                                                                                :
                                                                                <>
                                                                                    <tr>
                                                                                        <td>Certificate Image Url</td>
                                                                                        <td>
                                                                                            <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, border: 'none', }}>
                                                                                                            <OverlayTrigger overlay={this.state.TargetEventEditedModal === null ?
                                                                                                                <span></span>
                                                                                                                : String(this.state.TargetEventEditedModal['CertImgUrl']) === '' ?
                                                                                                                    <span></span>
                                                                                                                    : <Tooltip id='btn-tooltip-certImg'>
                                                                                                                        <img src={this.state.TargetEventEditedModal['CertImgUrl']} alt="" width="100%" />
                                                                                                                        <span>{this.Get_FileName_Long(String(this.state.TargetEventEditedModal['CertImgUrl']))}</span>
                                                                                                                    </Tooltip>
                                                                                                            }>
                                                                                                                {/* <span>{String(this.state.TargetEventEditedModal['CertImgUrl']).replace('ikeynew.blob.core.windows.net/ikeykidz/CERT', '{hidden}')}</span> */}
                                                                                                                <span>{this.Get_FileName_Short(String(this.state.TargetEventEditedModal['CertImgUrl']))}</span>
                                                                                                            </OverlayTrigger>
                                                                                                        </td>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                                                                            <button className='btn btn-primary' style={{ width: 80 }}
                                                                                                                onClick={() => this.ViewEventMode_Upload_File(FileMode.CertImg)}>upload</button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Shared Report URL by Organizer<br />(for Document Room)</td>
                                                                                        <td>
                                                                                            {/* <input type='text' className={'form-control ' + this.IsEditedStyle('EventSharedReportUrl')}
                                                                                                value={this.state.TargetEventEditedModal['EventSharedReportUrl']}
                                                                                                onChange={(e) => this.SetTargetEventEditedValue('EventSharedReportUrl', e.target.value)}
                                                                                            ></input> */}
                                                                                            <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, border: 'none', }}>
                                                                                                            <input type='text' className={'form-control ' + this.IsEditedStyle('EventSharedReportUrl')}
                                                                                                                value={this.state.TargetEventEditedModal['EventSharedReportUrl']}
                                                                                                                onChange={(e) => this.SetTargetEventEditedValue('EventSharedReportUrl', e.target.value)}
                                                                                                            ></input>
                                                                                                        </td>
                                                                                                        <td style={{ width: 10, border: 'none', }}></td>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                                                                            <button className='btn btn-primary' style={{ width: 80 }}
                                                                                                                onClick={() => this.SaveEventSettings(this.state.ShowViewEventModal)}
                                                                                                                disabled={this.state.TargetEventModal['EventSharedReportUrl'] === this.state.TargetEventEditedModal['EventSharedReportUrl']}
                                                                                                            >save</button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Surat Pengikhtirafan PAJSK</td>
                                                                                        <td>
                                                                                            <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, border: 'none', }}>
                                                                                                            {
                                                                                                                this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === ''
                                                                                                                    || this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === undefined ?
                                                                                                                    null
                                                                                                                    :
                                                                                                                    // <OverlayTrigger overlay={this.state.TargetEventEditedModal === null ? <span></span> : this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === '' ? <span></span> :
                                                                                                                    //     <Tooltip><img src={this.state.TargetEventEditedModal['GovRecognitionLetterUrl']} alt="" width="100%" /></Tooltip>}>
                                                                                                                    //     <span>{String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']).replace('ikeynew.blob.core.windows.net/ikeykidz/PAJSK', '{hidden}')}</span>
                                                                                                                    // </OverlayTrigger>
                                                                                                                    <OverlayTrigger overlay={this.state.TargetEventEditedModal === null ? <span></span> : this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === '' ? <span></span> :
                                                                                                                        <Tooltip>{this.Get_FileName_Long(String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']))}</Tooltip>}>
                                                                                                                        <span>{this.Get_FileName_Short(String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']))}</span>
                                                                                                                    </OverlayTrigger>
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                                                                            <button className='btn btn-primary' style={{ width: 80 }}
                                                                                                                onClick={() => this.ViewEventMode_Upload_File(FileMode.PAJSK)}>upload</button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Enable Delayed Download</td>
                                                                                        <td>
                                                                                            <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, border: 'none', }}>
                                                                                                            <input type='checkbox'
                                                                                                                // className='disabled-pointer-events'
                                                                                                                checked={this.state.TargetEventEditedModal['CertDownloadDelayed']}
                                                                                                                onChange={(e) => this.SetTargetEventEditedValue('CertDownloadDelayed', e.currentTarget.checked)}
                                                                                                            ></input>{
                                                                                                                CheckBoolean(this.state.TargetEventEditedModal['CertDownloadDelayed']) ?
                                                                                                                    <div style={{ padding: '0px 0px 0px 25px', display: 'inline-flex', alignItems: 'center', }}>
                                                                                                                        <span style={{ flex: 1, padding: '0px 10px 0px 0px', }}>Delayed until</span>
                                                                                                                        <input type='datetime-local' className={'form-control ' + this.IsEditedStyle('CertDownloadDelayedEndDateTime')}
                                                                                                                            style={{ flex: 1, padding: '0px 10px 0px 10px', marginRight: 10, }}
                                                                                                                            value={
                                                                                                                                this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime'] !== null
                                                                                                                                    && this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime'] !== undefined
                                                                                                                                    && String(this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime']) !== ''
                                                                                                                                    ?
                                                                                                                                    moment(this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime']).format('YYYY-MM-DDTHH:mm')
                                                                                                                                    : moment(this.state.TargetEventEditedModal['DateStart']).add(3, 'months').format('YYYY-MM-DDTHH:mm')
                                                                                                                            }
                                                                                                                            onChange={(e) => this.SetTargetEventEditedValue('CertDownloadDelayedEndDateTime', moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))}
                                                                                                                        ></input>
                                                                                                                        <button className='btn-link'
                                                                                                                            onClick={() => {
                                                                                                                                let _state = this.state.TargetEventEditedModal;
                                                                                                                                _state['CertDownloadDelayedEndDateTime'] = this.state.TargetEventModal['CertDownloadDelayedEndDateTime'];
                                                                                                                                this.setState({ TargetEventEditedModal: _state, });
                                                                                                                            }}
                                                                                                                        >reset</button>
                                                                                                                    </div>
                                                                                                                    : null
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                                                                            <button className='btn btn-primary' style={{ width: 80 }}
                                                                                                                onClick={() => this.SaveEventSettings(this.state.ShowViewEventModal)}
                                                                                                                disabled={
                                                                                                                    this.state.TargetEventModal['CertDownloadDelayedEndDateTime'] === this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime']
                                                                                                                    && this.state.TargetEventModal['CertDownloadDelayedEndDateTime'] === this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime']
                                                                                                                }
                                                                                                            >save</button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                        }
                                                                    </>
                                                            }
                                                            <tr>
                                                                <td>Publish Mode</td>
                                                                <td>{this.state.TargetEventModal['PublishModeId'] === undefined ? 'Global' : PublishModeOptions[Number(this.state.TargetEventModal['PublishModeId'])].subLabel}</td>
                                                            </tr>
                                                        </>
                                                        :
                                                        this.state.TargetEventEditedModal === null ? null
                                                            :
                                                            /* Edit Event Settings */
                                                            <>
                                                                {/* <tr>
                                                                    <td width='285'>Role</td>
                                                                    <td>{this.state.TargetEventModal['Role']}</td>
                                                                </tr> */}
                                                                <tr hidden={this.state.IsCreateNewEvent}>
                                                                    <td style={{ width: 350, }}>Created Date</td>
                                                                    <td>{this.state.TargetEventModal['CreatedDate']}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Active</td>
                                                                    <td>
                                                                        <input type='checkbox'
                                                                            checked={this.state.TargetEventEditedModal['Active']}
                                                                            onChange={(e) => this.SetTargetEventEditedValue('Active', e.currentTarget.checked)}
                                                                        ></input>&nbsp;&nbsp;<span style={{ color: 'gray', fontSize: 14, position: 'absolute', }}>(checked = open to register/join publicly)</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Event Name</td>
                                                                    <td><input type='text' className={'form-control ' + this.IsEditedStyle('EventName')}
                                                                        value={this.state.TargetEventEditedModal['EventName']}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('EventName', e.target.value)}
                                                                    ></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Event Code</td>
                                                                    <td>
                                                                        {
                                                                            this.state.IsCreateNewEvent ?
                                                                                this.state.New_EventCode_isValid ?
                                                                                    //edit event.
                                                                                    <>
                                                                                        {this.state.TargetEventEditedModal['EventCode']}
                                                                                        &nbsp;&nbsp;
                                                                                        <button type="button" className="btn btn-outline btn1"
                                                                                            onClick={() => this.GenerateRandomEventCode()}
                                                                                        ><i className="fa fa-refresh icon1"></i></button>
                                                                                    </>
                                                                                    : <ProgressBar animated now={100} className='progressbar1' />
                                                                                :
                                                                                //view event.
                                                                                this.state.TargetEventModal['EventCode']
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    this.props.isSuperAdmin === false ?
                                                                        <tr style={{ color: 'gray' }}>
                                                                            <td>Organizer (Display)</td>
                                                                            {/* <td>{this.state.TargetEventEditedModal['OrganizerDisplayName']}</td> */}
                                                                            <td><input type='text' className={'form-control ' + this.IsEditedStyle('OrganizerDisplayName')}
                                                                                value={this.state.TargetEventEditedModal['OrganizerDisplayName']}
                                                                                onChange={(e) => this.SetTargetEventEditedValue('OrganizerDisplayName', e.target.value)}
                                                                            ></input>{
                                                                                    this.props.OrganizerInfo === null ? null :
                                                                                        <span style={{ color: 'gray', fontSize: 14, paddingLeft: 14 }}>Original: {this.props.OrganizerInfo['DisplayName']}</span>
                                                                                }</td>
                                                                        </tr>
                                                                        :
                                                                        <>
                                                                            <tr style={{ color: 'gray' }}>
                                                                                <td>Organizer Code</td>
                                                                                <td>{this.state.TargetEventEditedModal['Organizer']}</td>
                                                                            </tr>
                                                                            <tr style={{ color: 'gray' }}>
                                                                                <td>Organizer Identity</td>
                                                                                <td>{this.state.TargetEventEditedModal['OrganizerIdentity']}</td>
                                                                            </tr>
                                                                            <tr style={{ color: 'gray' }}>
                                                                                <td>Organizer (Display)</td>
                                                                                {/* <td>{this.state.TargetEventEditedModal['OrganizerDisplayName']}</td> */}
                                                                                <td><input type='text' className={'form-control ' + this.IsEditedStyle('OrganizerDisplayName')}
                                                                                    value={this.state.TargetEventEditedModal['OrganizerDisplayName']}
                                                                                    onChange={(e) => this.SetTargetEventEditedValue('OrganizerDisplayName', e.target.value)}
                                                                                ></input>{
                                                                                        this.props.OrganizerInfo === null ? null :
                                                                                            <span style={{ color: 'gray', fontSize: 14, paddingLeft: 14 }}>Original: {this.props.OrganizerInfo['DisplayName']}</span>
                                                                                    }</td>
                                                                            </tr>
                                                                        </>
                                                                }
                                                                <tr><td colSpan='2' className='row-seperator'></td></tr>
                                                                <tr>
                                                                    <td>Event Date (Display)</td>
                                                                    <td><input type='text' className={'form-control ' + this.IsEditedStyle('Date')}
                                                                        value={this.state.TargetEventEditedModal['Date']}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('Date', e.target.value)}
                                                                    ></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Registration End (Display)</td>
                                                                    <td><input type='text' className={'form-control ' + this.IsEditedStyle('RegistrationEndDisplayName')}
                                                                        value={this.state.TargetEventEditedModal['RegistrationEndDisplayName']}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('RegistrationEndDisplayName', e.target.value)}
                                                                    ></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Registration End (Actual Deadline)</td>
                                                                    <td><input type='datetime-local' className={'form-control ' + this.IsEditedStyle('RegistrationEnd')}
                                                                        // value={this.state.TargetEventEditedModal['RegistrationEnd']}
                                                                        value={moment(this.state.TargetEventEditedModal['RegistrationEnd']).format('YYYY-MM-DDTHH:mm')}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('RegistrationEnd', moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))}
                                                                    ></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Registration Overloaded</td>
                                                                    <td><input type='checkbox'
                                                                        checked={this.state.TargetEventEditedModal['RegistrationOverloaded']}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('RegistrationOverloaded', e.currentTarget.checked)}
                                                                    ></input>&nbsp;&nbsp;<span style={{ color: 'gray', fontSize: 14, position: 'absolute', }}>(check this box to stop registration via Login page)</span></td>
                                                                </tr>
                                                                <tr><td colSpan='2' className='row-seperator'></td></tr>
                                                                <tr>
                                                                    <td>Active Period{
                                                                        this.state.TargetEventEditedModal['DateStart'] === this.state.TargetEventEditedModal['DateEnd'] ?
                                                                            '' : ' (From ~ To)'
                                                                    }</td>
                                                                    <td>{
                                                                        moment(this.state.TargetEventEditedModal['DateStart']).format('ll')
                                                                        + (
                                                                            this.state.TargetEventEditedModal['DateStart'] === this.state.TargetEventEditedModal['DateEnd'] ?
                                                                                '' : ' ~ ' + moment(this.state.TargetEventEditedModal['DateEnd']).format('ll')
                                                                        )
                                                                    }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Date (Start)</td>
                                                                    <td><input type='date' className={'form-control ' + this.IsEditedStyle('DateStart')}
                                                                        value={this.state.TargetEventEditedModal['DateStart']}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('DateStart', e.target.value)}
                                                                    ></input></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Date (End)</td>
                                                                    <td><input type='date' className={'form-control ' + this.IsEditedStyle('DateEnd')}
                                                                        value={this.state.TargetEventEditedModal['DateEnd']}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('DateEnd', e.target.value)}
                                                                    ></input></td>
                                                                </tr>
                                                                <tr><td colSpan='2' className='row-seperator'></td></tr>
                                                                <tr><td>Time Start (Display)</td><td><input type='text' className={'form-control ' + this.IsEditedStyle('TimeStart')}
                                                                    value={this.state.TargetEventEditedModal['TimeStart']}
                                                                    onChange={(e) => this.SetTargetEventEditedValue('TimeStart', e.target.value)}
                                                                ></input></td></tr>
                                                                <tr><td>Time End (Display)</td><td><input type='text' className={'form-control ' + this.IsEditedStyle('TimeEnd')}
                                                                    value={this.state.TargetEventEditedModal['TimeEnd']}
                                                                    onChange={(e) => this.SetTargetEventEditedValue('TimeEnd', e.target.value)}
                                                                ></input></td></tr>
                                                                <tr>
                                                                    <td>Rooms held on own Time Schedule</td>
                                                                    <td>
                                                                        <input type='checkbox'
                                                                            // className='disabled-pointer-events'
                                                                            checked={this.state.TargetEventEditedModal['RoomsHeldOnSelfTime']}
                                                                            onChange={(e) => this.SetTargetEventEditedValue('RoomsHeldOnSelfTime', e.currentTarget.checked)}
                                                                        ></input>
                                                                        &nbsp;&nbsp;<span style={{ color: 'gray', fontSize: 14, position: 'absolute', }}>(checked = each room follow its own Begin & End time, unchecked = all rooms shared a same universal time)</span>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    CheckBoolean(this.state.TargetEventEditedModal['RoomsHeldOnSelfTime']) ? null
                                                                        :
                                                                        <>
                                                                            <tr><td>Time Start</td><td><input type='time' className={'form-control ' + this.IsEditedStyle('GlobalScheduleTimeStart')}
                                                                                value={this.state.TargetEventEditedModal['GlobalScheduleTimeStart']}
                                                                                onChange={(e) => this.SetTargetEventEditedValue('GlobalScheduleTimeStart', e.currentTarget.value)}
                                                                            ></input></td></tr>
                                                                            <tr><td>Time End</td><td><input type='time' className={'form-control ' + this.IsEditedStyle('GlobalScheduleTimeEnd')}
                                                                                value={this.state.TargetEventEditedModal['GlobalScheduleTimeEnd']}
                                                                                onChange={(e) => this.SetTargetEventEditedValue('GlobalScheduleTimeEnd', e.currentTarget.value)}
                                                                            ></input></td></tr>
                                                                        </>
                                                                }
                                                                <tr><td colSpan='2' className='row-seperator'></td></tr>
                                                                <tr>
                                                                    <td>Enable Certificate</td>
                                                                    <td><input type='checkbox'
                                                                        checked={this.state.TargetEventEditedModal['CertDownloadEnabled']}
                                                                        onChange={(e) => this.SetTargetEventEditedValue('CertDownloadEnabled', e.currentTarget.checked)}
                                                                    // className='disabled-pointer-events'
                                                                    ></input></td>
                                                                </tr>
                                                                {
                                                                    this.state.TargetEventEditedModal['CertDownloadEnabled'] === false ? null :
                                                                        <>
                                                                            <tr>
                                                                                <td>Certificate Layout Type</td>
                                                                                <td><DropdownButton
                                                                                    id='edit-cert-layout-type-dropdown-button'
                                                                                    title={
                                                                                        Number(this.state.TargetEventEditedModal['CertLayoutTypeId']) > 0 ?
                                                                                            CertLayoutTypeOptions[Number(this.state.TargetEventEditedModal['CertLayoutTypeId']) - 1].label
                                                                                            : 'Select a Layout Type'
                                                                                    }
                                                                                    drop='down'
                                                                                    onSelect={(option) => {
                                                                                        let _state = this.state.TargetEventEditedModal;
                                                                                        _state['CertLayoutTypeId'] = Number(option);
                                                                                        this.setState({ TargetEventEditedModal: _state }, () => {
                                                                                            if (this.props.isDevMode)
                                                                                                console.log('item #' + JSON.stringify(option) + '\nCertLayoutTypeId = ' + this.state.TargetEventEditedModal['CertLayoutTypeId']);
                                                                                        });
                                                                                    }}
                                                                                    style={{ width: 515 }}
                                                                                // className='disabled-pointer-events'
                                                                                >
                                                                                    <Dropdown.Item disabled={true}>Select a Layout Type</Dropdown.Item>
                                                                                    {
                                                                                        CertLayoutTypeOptions.map((data, key) => {
                                                                                            return (<Dropdown.Item as="button" eventKey={key + 1} value={key + 1}>{CertLayoutTypeOptions[key].label}</Dropdown.Item>);
                                                                                        })
                                                                                    }
                                                                                    {/* <Dropdown.Item as="button" eventKey={1} value={1}>{CertLayoutTypeOptions[0].label}</Dropdown.Item>
                                                                                    <Dropdown.Item as="button" eventKey={2} value={2}>{CertLayoutTypeOptions[1].label}</Dropdown.Item>
                                                                                    <Dropdown.Item as="button" eventKey={3} value={3}>{CertLayoutTypeOptions[2].label}</Dropdown.Item>
                                                                                    <Dropdown.Item as="button" eventKey={4} value={4}>{CertLayoutTypeOptions[3].label}</Dropdown.Item> */}
                                                                                </DropdownButton></td>
                                                                            </tr>
                                                                            {
                                                                                this.state.TargetEventEditedModal['CertLayoutTypeId'] === 1 || this.state.TargetEventEditedModal['CertLayoutTypeId'] === 4 ? null :
                                                                                    <tr>
                                                                                        <td>Serial Number Prefix</td>
                                                                                        <td><input type='text' className={'form-control ' + this.IsEditedStyle('CertSerialPrefix')}
                                                                                            value={this.state.TargetEventEditedModal['CertSerialPrefix']}
                                                                                            onChange={(e) => this.SetTargetEventEditedValue('CertSerialPrefix', e.target.value)}
                                                                                        ></input>{String(this.state.TargetEventEditedModal['CertSerialPrefix']).length > 0 ?
                                                                                            ' (e.g. ' + this.state.TargetEventEditedModal['CertSerialPrefix'] + '000001)' : ''}</td>
                                                                                    </tr>
                                                                            }
                                                                            <tr>
                                                                                <td>Certificate Image Url</td>
                                                                                <td>
                                                                                    {
                                                                                        this.state.IsCreateNewEvent ?
                                                                                            <span style={{ color: 'gray' }}>(Available only after save.)</span>
                                                                                            :
                                                                                            this.state.TargetEventModal['CertDownloadEnabled'] === false ?
                                                                                                <span style={{ color: 'gray' }}>(Available only after Certificate is enabled & saved.)</span>
                                                                                                :
                                                                                                <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, }}>
                                                                                                                <OverlayTrigger overlay={this.state.TargetEventEditedModal === null ?
                                                                                                                    <span></span>
                                                                                                                    : String(this.state.TargetEventEditedModal['CertImgUrl']) === '' ?
                                                                                                                        <span></span>
                                                                                                                        : <Tooltip id='btn-tooltip-certImg'>
                                                                                                                            <img src={this.state.TargetEventEditedModal['CertImgUrl']} alt="" width="100%" />
                                                                                                                            <span>{this.Get_FileName_Long(String(this.state.TargetEventEditedModal['CertImgUrl']))}</span>
                                                                                                                        </Tooltip>
                                                                                                                }>
                                                                                                                    {/* <span>{String(this.state.TargetEventEditedModal['CertImgUrl']).replace('ikeynew.blob.core.windows.net/ikeykidz/CERT', '{hidden}')}</span> */}
                                                                                                                    <span>{this.Get_FileName_Short(String(this.state.TargetEventEditedModal['CertImgUrl']))}</span>
                                                                                                                </OverlayTrigger>
                                                                                                            </td>
                                                                                                            {/* <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 70, }}>
                                                                                                            <button className='btn btn-primary' onClick={() => this.props.SetAlert('', 'test', false)}>upload</button>
                                                                                                        </td> */}
                                                                                                            <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                                                                                <button className='btn btn-primary' style={{ width: 80 }}
                                                                                                                    onClick={() => this.ViewEventMode_Upload_File(FileMode.CertImg)}>upload</button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Shared Report URL by Organizer<br />(for Document Room)</td>
                                                                                <td><input type='text' className={'form-control ' + this.IsEditedStyle('EventSharedReportUrl')}
                                                                                    value={this.state.TargetEventEditedModal['EventSharedReportUrl']}
                                                                                    onChange={(e) => this.SetTargetEventEditedValue('EventSharedReportUrl', e.target.value)}
                                                                                ></input></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Surat Pengikhtirafan PAJSK</td>
                                                                                <td>
                                                                                    {
                                                                                        this.state.IsCreateNewEvent ?
                                                                                            <span style={{ color: 'gray' }}>(Available only after save.)</span>
                                                                                            :
                                                                                            this.state.TargetEventModal['CertDownloadEnabled'] === false ?
                                                                                                <span style={{ color: 'gray' }}>(Available only after Certificate is enabled & saved.)</span>
                                                                                                :
                                                                                                <table cellPadding='0' cellSpacing='0' className='tb-no-padding'>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            {/* <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, }}> */}
                                                                                                            {/* <OverlayTrigger overlay={this.state.TargetEventEditedModal === null ? <span></span> : this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === '' ? <span></span> :
                                                                                                                <Tooltip><img src={this.state.TargetEventEditedModal['GovRecognitionLetterUrl']} alt="" width="100%" /></Tooltip>}>
                                                                                                                <span>{String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']).replace('ikeynew.blob.core.windows.net/ikeykidz/PAJSK', '{hidden}')}</span>
                                                                                                            </OverlayTrigger> */}
                                                                                                            {/* <OverlayTrigger overlay={this.state.TargetEventEditedModal === null ? <span></span> : this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === '' ? <span></span> :
                                                                                                                <Tooltip>{this.Get_FileName_Long(String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']))}</Tooltip>}>
                                                                                                                <span>{this.Get_FileName_Short(String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']))}</span>
                                                                                                            </OverlayTrigger>
                                                                                                        </td> */}
                                                                                                            {/* <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 70, }}>
                                                                                                            <button className='btn btn-primary' onClick={() => this.props.SetAlert('', 'test', false)}>upload</button>
                                                                                                        </td> */}
                                                                                                            <td style={{ padding: 0, verticalAlign: 'middle', borderWidth: 0, paddingLeft: 10, border: 'none', }}>
                                                                                                                {
                                                                                                                    this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === ''
                                                                                                                        || this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === undefined ?
                                                                                                                        null
                                                                                                                        :
                                                                                                                        <OverlayTrigger overlay={this.state.TargetEventEditedModal === null ? <span></span> : this.state.TargetEventEditedModal['GovRecognitionLetterUrl'] === '' ? <span></span> :
                                                                                                                            <Tooltip>{this.Get_FileName_Long(String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']))}</Tooltip>}>
                                                                                                                            <span>{this.Get_FileName_Short(String(this.state.TargetEventEditedModal['GovRecognitionLetterUrl']))}</span>
                                                                                                                        </OverlayTrigger>
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td style={{ padding: 0, verticalAlign: 'middle', textAlign: 'right', width: 80, border: 'none', }}>
                                                                                                                <button className='btn btn-primary' style={{ width: 80 }}
                                                                                                                    onClick={() => this.ViewEventMode_Upload_File(FileMode.PAJSK)}>upload</button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Enable Delayed Download</td>
                                                                                <td><input type='checkbox'
                                                                                    checked={this.state.TargetEventEditedModal['CertDownloadDelayed']}
                                                                                    onChange={(e) => this.SetTargetEventEditedValue('CertDownloadDelayed', e.currentTarget.checked)}
                                                                                // className='disabled-pointer-events'
                                                                                ></input>{
                                                                                        CheckBoolean(this.state.TargetEventEditedModal['CertDownloadDelayed']) ?
                                                                                            <div style={{ padding: '0px 0px 0px 25px', display: 'inline-flex', alignItems: 'center', }}>
                                                                                                <span style={{ flex: 1, padding: '0px 10px 0px 0px', }}>Delayed until</span>
                                                                                                <input type='datetime-local' className={'form-control ' + this.IsEditedStyle('CertDownloadDelayedEndDateTime')}
                                                                                                    style={{ flex: 1, }}
                                                                                                    value={
                                                                                                        this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime'] !== null
                                                                                                            && this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime'] !== undefined
                                                                                                            && String(this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime']) !== ''
                                                                                                            ?
                                                                                                            moment(this.state.TargetEventEditedModal['CertDownloadDelayedEndDateTime']).format('YYYY-MM-DDTHH:mm')
                                                                                                            : moment(this.state.TargetEventEditedModal['DateStart']).add(3, 'months').format('YYYY-MM-DDTHH:mm')
                                                                                                    }
                                                                                                    onChange={(e) => this.SetTargetEventEditedValue('CertDownloadDelayedEndDateTime', moment(e.target.value).format('YYYY-MM-DD HH:mm:ss'))}
                                                                                                ></input>
                                                                                                <button className='btn-link'
                                                                                                    onClick={() => {
                                                                                                        let _state = this.state.TargetEventEditedModal;
                                                                                                        _state['CertDownloadDelayedEndDateTime'] = this.state.TargetEventModal['CertDownloadDelayedEndDateTime'];
                                                                                                        this.setState({ TargetEventEditedModal: _state, });
                                                                                                    }}
                                                                                                >reset</button>
                                                                                            </div>
                                                                                            : null
                                                                                    }</td>
                                                                            </tr>
                                                                        </>
                                                                }
                                                                <tr>
                                                                    <td>Publish Mode</td>
                                                                    <td><DropdownButton
                                                                        id='edit-role-dropdown-button'
                                                                        title={
                                                                            Number(this.state.TargetEventEditedModal['PublishModeId']) >= 0 ?
                                                                                PublishModeOptions[Number(this.state.TargetEventEditedModal['PublishModeId'])].label
                                                                                : 'Select a Publish Mode'
                                                                        }
                                                                        drop='down'
                                                                        onSelect={(option) => {
                                                                            let _state = this.state.TargetEventEditedModal;
                                                                            _state['PublishModeId'] = Number(option) - 1;
                                                                            switch (Number(option)) {
                                                                                default: _state['Role'] = PublishModeOptions[1].role; break;  //default is Global, open to all.
                                                                                case 1: _state['Role'] = PublishModeOptions[0].role; break;     //Admin
                                                                                case 3: _state['Role'] = PublishModeOptions[2].role; break;     //Private
                                                                            }
                                                                            this.setState({ TargetEventEditedModal: _state }, () => {
                                                                                if (this.props.isDevMode)
                                                                                    console.log('item #' + JSON.stringify(option) + '\nPublishModeId = ' + this.state.TargetEventEditedModal['PublishModeId']);
                                                                            });
                                                                        }}
                                                                        style={{ width: 575 }}
                                                                    // className='disabled-pointer-events'
                                                                    >
                                                                        <Dropdown.Item disabled={true}>Select a Publish Mode</Dropdown.Item>
                                                                        <Dropdown.Item as="button" eventKey={1} value={1} hidden={this.props.isSuperAdmin === false}>{PublishModeOptions[0].label}</Dropdown.Item>
                                                                        <Dropdown.Item as="button" eventKey={2} value={2}>{PublishModeOptions[1].label}</Dropdown.Item>
                                                                        <Dropdown.Item as="button" eventKey={3} value={3}>{PublishModeOptions[2].label}</Dropdown.Item>
                                                                    </DropdownButton></td>
                                                                </tr>
                                                            </>
                                                }
                                            </>
                                            {/* 2022.10.06 - Groups */}
                                            <tr className='hide-row-hover'>
                                                <td style={{ width: 350, }}>Groups {
                                                    this.state.ToggleEditEvent
                                                        && this.state.TargetEventModal['Groups'] !== null
                                                        && Array(this.state.TargetEventModal['Groups']).length > 0 ?
                                                        <>
                                                            <Button
                                                                variant="primary"
                                                                onClick={this.ToggleEditEventGroupModal}
                                                            >Edit Groups</Button>
                                                        </>
                                                        :
                                                        null
                                                }</td>
                                                <td>
                                                    {
                                                        this.state.TargetEventModal['Groups'] !== null
                                                            && Array(this.state.TargetEventModal['Groups']).length > 0 ?
                                                            <table className='table tbStyle' width={'100%'}>
                                                                <thead>
                                                                    <tr className='hide-row-hover'>
                                                                        <td>No.</td>
                                                                        <td>Group Name</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.GetTargetEvent_EditGroups_TableRows()}
                                                                </tbody>
                                                            </table>
                                                            :
                                                            // 'no group is found.'
                                                            <Button
                                                                variant="primary"
                                                                onClick={this.ToggleEditEventGroupModal}
                                                            >Assign Groups</Button>
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                /* Rooms */
                                                this.state.IsCreateNewEvent ? null :
                                                    <>
                                                        <tr><td colSpan='2' className='row-seperator'></td></tr>
                                                        {/* Lower part after Event details */}
                                                        <tr className='hide-row-hover'>
                                                            <td style={{ width: 350, }}>
                                                                Rooms
                                                                <div
                                                                    // hidden={this.state.ToggleEditEvent ? this.state.TargetEventIsEnded : true}
                                                                    hidden={this.state.ToggleEditEvent === true ? false : true}
                                                                >
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={this.ToggleEditEventRoomModal}
                                                                        disabled={
                                                                            this.state.ToggleEditEvent === false ?
                                                                                //View Event detail.
                                                                                false
                                                                                :
                                                                                //Edit Event detail.
                                                                                JSON.stringify(this.state.TargetEventModal) !== JSON.stringify(this.state.TargetEventEditedModal)
                                                                        }
                                                                    >Edit Rooms</Button>
                                                                    <div hidden={
                                                                        this.state.ToggleEditEvent === false ?
                                                                            //View Event detail.
                                                                            true
                                                                            :
                                                                            //Edit Event detail.
                                                                            (this.state.TargetEventModal.QuizRooms === undefined || (Array.isArray(this.state.TargetEventModal.QuizRooms) && this.state.TargetEventModal.QuizRooms.length === 0))
                                                                                && (this.state.TargetEventEditedModal.QuizRooms === undefined || (Array.isArray(this.state.TargetEventEditedModal.QuizRooms) && this.state.TargetEventEditedModal.QuizRooms.length === 0))
                                                                                ? true :
                                                                                JSON.stringify(this.state.TargetEventModal) === JSON.stringify(this.state.TargetEventEditedModal)
                                                                    }>
                                                                        <span style={{ color: 'gray', fontWeight: 'bold', fontSize: 12, }}
                                                                        >* One or more event setting(s) had been modified, it is require to save current event setting(s) before edit any room setting(s).</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    this.state.TargetEventModal['QuizRooms'] !== null
                                                                        && Array(this.state.TargetEventModal['QuizRooms']).length > 0 ?
                                                                        <table className='table tbStyle'>
                                                                            <thead>
                                                                                <tr className='hide-row-hover'>
                                                                                    {/* <td>#</td> */}
                                                                                    <td>Group</td>
                                                                                    <td>Subject</td>
                                                                                    <td>Room Code</td>
                                                                                    {
                                                                                        this.state.TargetEventModal['RoomsHeldOnSelfTime'] ?
                                                                                            <>
                                                                                                <td>Time Start</td>
                                                                                                <td>Time End</td>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.GetTargetEvent_Rooms_TableRows()}
                                                                                {
                                                                                    //#region old code
                                                                                    // this.state.TargetEventRooms.map((data, key) => {
                                                                                    //     return (
                                                                                    //         <tr>
                                                                                    //             {/* <td>{key + 1}</td> */}
                                                                                    //             <td>{data.Group}</td>
                                                                                    //             <td>{data.Subject}</td>
                                                                                    //             <td>{data.RoomCode}</td>
                                                                                    //             {
                                                                                    //                 this.state.TargetEventModal['RoomsHeldOnSelfTime'] ?
                                                                                    //                     <>
                                                                                    //                         <td>{data.TimeStart}</td>
                                                                                    //                         <td>{data.TimeEnd}</td>
                                                                                    //                     </>
                                                                                    //                     : null
                                                                                    //             }
                                                                                    //         </tr>
                                                                                    //     );
                                                                                    // })
                                                                                    //#endregion
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        'no room is found.'
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                            }
                                            {
                                                this.state.TargetEventModal === null
                                                    || this.state.TargetEventModal.TelegramInfo === ''
                                                    // || this.state.TargetEventModal.TelegramInfo === []
                                                    || this.state.TargetEventModal.TelegramInfo === null
                                                    || this.state.TargetEventModal.TelegramInfo === undefined ? null
                                                    :
                                                    <tr className='hide-row-hover'>
                                                        <td>Telegram Info</td>
                                                        <td>
                                                            {
                                                                this.state.ToggleEditEvent ?
                                                                    //edit mode.
                                                                    this.state.TargetEventEditedModal.TelegramInfo.map((data, key) => {
                                                                        return <Row className='row-lang'>
                                                                            <Col className='col-1 label'>{this.LangLabel(data.Lang)}</Col>
                                                                            <Col className='content'>
                                                                                <textarea rows={5} cols={50} style={{ width: '100%' }}
                                                                                    className={'form-control ' + this.IsEditedStyle_Info(data.Lang, 'TelegramInfo')}
                                                                                    placeholder={'...html tags...'}
                                                                                    onChange={(e) => this.SetTargetEventEditedValue_Info(data.Lang, e.target.value, 'TelegramInfo')}
                                                                                >{data.Content}</textarea>
                                                                            </Col>
                                                                        </Row>
                                                                    })
                                                                    :
                                                                    //view mode.
                                                                    this.state.TargetEventEditedModal.TelegramInfo.map((data, key) => {
                                                                        return <Row className='row-lang'>
                                                                            <Col className='col-1 label'>{this.LangLabel(data.Lang)}</Col>
                                                                            <Col className='content'>
                                                                                <textarea rows={5} cols={50} disabled={true} style={{ width: '100%' }}
                                                                                    placeholder={'...html tags...'}
                                                                                >{data.Content}</textarea>
                                                                            </Col>
                                                                        </Row>
                                                                    })
                                                            }
                                                        </td>
                                                    </tr>
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer style={{ padding: '10px 0px', }}>
                        <Row style={{ width: '100%', margin: 0, }}>
                            <Col>
                                {
                                    this.state.PA_Delete === false || this.state.TargetEventIsEnded ? null :
                                        <Button
                                            variant="secondary"
                                            onClick={this.ToggleDeleteEventModal}
                                        >Delete Event</Button>
                                }
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <span style={{ color: 'gray' }}>{
                                    this.state.TargetEventIsEnded ? ' (current event has ended)' : ''
                                }</span>
                            </Col>
                            <Col style={{ textAlign: 'end' }}>
                                <Button variant="secondary" onClick={this.ToggleViewEventModal}>Close</Button>
                                {
                                    this.state.ToggleEditEvent ?
                                        <>
                                            &nbsp;&nbsp;
                                            <Button variant="secondary"
                                                onClick={async () => {
                                                    // this.setState({
                                                    //     TargetEventEditedModal: { ...this.state.TargetEventModal },
                                                    //     // TargetEventEditedModal: JSON.parse(JSON.stringify(this.state.TargetEventModal)),
                                                    // }, () => {
                                                    //     this.ToggleViewEventModal();
                                                    //     this.ViewEventDetail(this.state.TargetEventIndex);
                                                    // });
                                                    this.ToggleViewEventModal();
                                                    await Delay(500);
                                                    this.ViewEventDetail(this.state.TargetEventIndex);
                                                }}
                                            >Exit Edit Mode</Button>
                                        </>
                                        : null
                                }
                                {
                                    //2022.10.06
                                    // !this.props.isSuperAdmin ||
                                    this.state.PA_Update === false ||
                                        (this.state.TargetEventIsEnded && !this.props.isDevMode) ?
                                        null
                                        :
                                        this.state.ToggleEditEvent ?
                                            <>
                                                &nbsp;&nbsp;
                                                <Button
                                                    variant="success"
                                                    // onClick={this.SaveEventSettings}
                                                    onClick={() => this.SaveEventSettings(!this.state.IsEventEditing)}
                                                >Save Event</Button>
                                            </>
                                            :
                                            <>
                                                &nbsp;&nbsp;
                                                <Button
                                                    variant="primary"
                                                    onClick={this.EditEvent}
                                                >Edit Event</Button>
                                            </>
                                }
                                {
                                    // this.state.TargetEventIsEnded && !this.props.isDevMode ?
                                    //     null
                                    //     :
                                    //     this.state.ToggleEditEvent ?
                                    //         <>
                                    //             &nbsp;&nbsp;
                                    //             <Button
                                    //                 variant="success"
                                    //                 onClick={this.SaveEventSettings}
                                    //             >Save Event</Button>
                                    //         </>
                                    //         :
                                    //         <>
                                    //             &nbsp;&nbsp;
                                    //             <Button
                                    //                 variant="primary"
                                    //                 onClick={this.EditEvent}
                                    //             >Edit Event</Button>
                                    //         </>
                                }
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>

                {/* Event - Delete - Modal */}
                <Modal show={this.state.ShowDeleteEventModal} onHide={this.ToggleDeleteEventModal} centered
                    className='alert-dialog-bordered'>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>
                            Event: <b>{this.state.TargetEventModal === null ? '' : this.state.TargetEventModal['EventName']}</b>
                            <br />Organizer: <b>{this.state.TargetEventModal === null ? '' : this.state.TargetEventModal['OrganizerDisplayName']}</b>
                            <p />Are you sure you want to <b>delete</b> this event ?
                            <br /><b><i>The deletion is not reversible.</i></b>
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.ToggleDeleteEventModal}>Cancel</Button>
                        &nbsp;&nbsp;
                        <Button variant="primary" onClick={this.DeleteThisEvent}>Confirm</Button>
                    </Modal.Footer>
                </Modal>

                {/* Event - Edit Event Room - Modal */}
                <Modal size={this.EditEventRoomModalSize()} show={this.state.ShowEditEventRoomModal} onHide={this.ToggleEditEventRoomModal} centered
                    className='alert-dialog-bordered'>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Event Room</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '100%' }}>
                            {this.EditEventRoomComponents()}
                        </div>
                    </Modal.Body>
                    <Modal.Footer id='editEventRoomUiBottom'>
                        {/* <Button variant="secondary"
                            style={{ width: 100 }}
                            onClick={this.ToggleEditEventRoomModal}
                            hidden={!(this.state.EditEventRoomState === 0)}>Close</Button>
                        <Button variant="secondary"
                            style={{ width: 100 }}
                            onClick={this.ResetEditRoomState}
                            hidden={!(this.state.EditEventRoomState === 1)}>Back</Button> */}
                        {
                            this.state.EditEventRoomState === 0 ?
                                <Button variant="secondary"
                                    style={{ width: 100 }}
                                    onClick={this.ToggleEditEventRoomModal}
                                >Close</Button>
                                : null
                        }
                        {
                            this.state.EditEventRoomState === 1 ?
                                <Button variant="secondary"
                                    style={{ width: 100 }}
                                    onClick={this.ResetEditRoomState}
                                >Back</Button>
                                : null
                        }
                        {
                            this.state.IsEventRoomStateDirty ?
                                <>
                                    &nbsp;&nbsp;
                                    <Button variant="primary"
                                        style={{ width: 100 }}
                                        onClick={this.SaveEventRoomState}
                                    >Save</Button>
                                </>
                                : null
                        }
                    </Modal.Footer>
                </Modal>

                {/* Event - File Upload - Modal */}
                <Modal show={this.state.ToggleFileUploadUi} onHide={this.ToggleFileUploadUi} centered
                // className='alert-dialog-bordered'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Upload File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '100%' }}>
                            {this.FileUploadComponentUi()}
                        </div>
                    </Modal.Body>
                    <Modal.Footer id='editEventRoomUiBottom'>
                        <Button variant="secondary" onClick={this.ToggleFileUploadUi}>Close</Button>
                    </Modal.Footer>
                </Modal>

                {/* Event - Edit Group - Modal - 2022.10.07 */}
                <Modal show={this.state.ShowEditEventGroupModal} onHide={this.ToggleEditEventGroupModal} centered
                    // className='alert-dialog-bordered'
                    size={'sm'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Group Selection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ width: '100%' }}>
                            <table className='table tbStyle' width={'100%'}>
                                <thead>
                                    <tr>
                                        <th>
                                            {/* <input type='checkbox' className='disabled-pointer-events'
                                                onClick={this.SelectAllOnTargetEventGroups}
                                            ></input> */}
                                            No.
                                        </th>
                                        <th>Group Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.GetTargetEvent_EditGroups_TableRows(true)}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer id='editEventRoomUiBottom'>
                        <Button variant="secondary" onClick={this.ToggleEditEventGroupModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

                {/*temporary component*/}
                {/* <Row className='rowStyle'>
                    <Col>
                        {this.UpdateEventRoomButtonComponent()}
                    </Col>
                </Row> */}
            </>
        );
    }
}

