import React from 'react';
// import firebase from '../components/Firebase.js';
// import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';

import { Locale } from '../Localization/CustomLocalization.js';
import { CheckObjectBoolean, CheckObjectStringEmpty, CheckStringEmpty, DelayUntil } from '../components/GlobalFunctions.js';
import { GlobalSetting } from '../components/GlobalSetting.js';
import { SchoolList } from '../components/NationalQuizEventSignUpSettings.js';
import SchoolListWithDetails from '../components/SchoolListWithDetails.js';

export default class PasswordResetScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        loginEmail: '',
        loginEmailConfirm: '',

        schoolCode: '',
        schoolListArray: [],
        showSelectSchoolListOption: false,
        selectedSchoolName: '',
        selectedSchoolIndex: -1,

        errorMessage: '',
        showModal: false,
    });

    componentDidMount = () => {
        // this.SettingListToArray();
    }

    handleSetEmail = (event) => {
        this.setState({
            // loginEmail: String(event.target.value).toLowerCase(),
            loginEmail: event.target.value !== null ? String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), '') : '',
        });
    }

    handleSetEmailConfirm = (event) => {
        this.setState({
            // loginEmailConfirm: String(event.target.value).toLowerCase(),
            loginEmailConfirm: event.target.value !== null ? String(event.target.value).toLowerCase().replace(new RegExp(' ', 'g'), '') : '',
        });
    }

    handleCheckEmail = () => {
        //2020.11.30
        if (this.props.isInternetReachable === false) {
            this.props.ShowInternetNotAvailableAlert();
            return null;
        }

        this.props.SetAlertWithProgressBar(Locale("logging-in", this.props.Locale), Locale("please-wait", this.props.Locale), true);
        this.setState(
            {
                errorMessage: '',
            },
            async () => {
                var isLoginEmpty = this.state.loginEmail.length <= 0;
                var isLoginConfirmEmpty = this.state.loginEmailConfirm.length <= 0;

                var msg = '';
                if (isLoginEmpty) {
                    // msg += "Please do not leave your email empty.<br />";
                    msg += Locale("email-not-empty", this.props.Locale) + "<br /><br />";
                }
                if (isLoginConfirmEmpty) {
                    // msg += "Please do not leave your confirmation email empty.<br />";
                    msg += Locale("confirm-email-not-empty", this.props.Locale) + "<br /><br />";
                }

                let regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                var isLoginEmailValid = regexp.test(this.state.loginEmail);
                var isLoginEmailConfirmValid = regexp.test(this.state.loginEmailConfirm);
                if (!isLoginEmailValid) {
                    // msg += "Please enter your email correctly.<br />";
                    msg += Locale("enter-email-correctly", this.props.Locale) + "<br /><br />";
                }
                if (!isLoginEmailConfirmValid) {
                    // msg += "Please enter your confirmation email correctly.<br />";
                    msg += Locale("enter-confirm-email-correctly", this.props.Locale) + "<br /><br />";
                }


                //no content = all fields are filled.
                if (msg.length <= 0) {
                    if (this.state.loginEmail === this.state.loginEmailConfirm) {

                        //send password reset email via firebase.
                        var success = await this.SendEmailPasswordViaApi();     //Jay added 2024.09.02
                        // var success = await this.SendResetPasswordLinkViaEmailByFirebase();

                        if (success.result) {
                            // msg = "Reset password link has been sent to your registered email address (" + this.state.loginEmailConfirm + ").";
                            // msg += Locale("reset-password-sent", this.props.Locale) + " (" + this.state.loginEmailConfirm + ").";
                            this.props.CloseAlert();
                            setTimeout(() => {
                                this.props.SetAlert(Locale("success", this.props.Locale),
                                    Locale("reset-password-sent", this.props.Locale) + " (" + this.state.loginEmailConfirm + ").");
                            }, 700);
                        }
                        else {
                            // // msg = "Fail to send Reset password link to Email (" + this.state.loginEmailConfirm + ").";
                            // // msg += Locale("reset-password-failed", this.props.Locale) + " (" + this.state.loginEmailConfirm + ").";
                            // this.props.CloseAlert();
                            // setTimeout(() => {
                            //     this.props.SetAlert(Locale("failed", this.props.Locale),
                            //         Locale("reset-password-failed", this.props.Locale) + " (" + this.state.loginEmailConfirm + ").");
                            // }, 700);

                            //2021.03.24
                            msg = Locale("reset-password-failed", this.props.Locale) + " (" + this.state.loginEmailConfirm + ").";
                            // var reason = "<br /><br />" + Locale("reset-pass-failed-label-possible-reason", this.props.Locale) + "<br />";
                            switch (success.error) {
                                case 'auth/user-not-found':
                                    msg += "<br /><br /><b><i>" + Locale("reset-pass-failed-user-not-found", this.props.Locale) + "</i></b>";; break;
                                case 'auth/invalid-email':
                                    msg += "<br /><br /><b><i>" + Locale("reset-pass-failed-invalid-email", this.props.Locale) + "</i></b>"; break;
                                default:
                                    msg += String(success.error).length > 0 ? "<br /><br /><b><i>Error Code: " + success.error + "</i></b>" : ""; break;
                            }
                            this.props.CloseAlert();
                            setTimeout(() => {
                                this.props.SetAlert(Locale("failed", this.props.Locale), msg);
                            }, 700);
                        }
                    }
                    else {
                        // msg = "Both of the entered emails are not match.<br />";
                        // msg += Locale("email-not-match", this.props.Locale) + "<br /><br />";
                        this.props.CloseAlert();
                        setTimeout(() => {
                            this.props.SetAlert(Locale("error", this.props.Locale),
                                Locale("email-not-match", this.props.Locale) + "<br /><br />");
                        }, 700);
                    }
                }
                else {
                    this.props.SetAlert(Locale("error", this.props.Locale), msg);
                }

                //show msg.
                // alert(msg);
                // this.setState({
                //     errorMessage: msg,
                //     showModal: true,
                // });
            });
    }

    //2024.09.02 - obsolete codes.
    // SendResetPasswordLinkViaEmailByFirebase = async () => {
    //     let isSuccess = false;
    //     let errorCode = '';
    //     await firebase.auth().sendPasswordResetEmail(this.state.loginEmailConfirm)
    //         .then(function () {
    //             isSuccess = true;
    //         })
    //         .catch(function (e) {
    //             errorCode = e.code;
    //             // alert(e.code);
    //         });
    //     return { result: isSuccess, error: errorCode };
    // }

    //2024.09.02
    handleSetSelectedSchool = (option = null) => {
        // console.log('handleSetSelectedSchool \n' + JSON.stringify(school));
        if (option === null || option === undefined)
            return null;
        this.setState({
            selectedSchoolIndex: -1,
            selectedSchoolName: '',
            schoolCode: '',
        });
        for (let i = 0; i < SchoolListWithDetails.length; i++) {
            const schoolName = CheckObjectStringEmpty(option, 'value').split('-')[0].trim();
            if (SchoolListWithDetails[i].Name === schoolName) {
                this.setState({
                    selectedSchoolIndex: i,
                    selectedSchoolName: schoolName,
                    schoolCode: SchoolListWithDetails[i].Kod,
                });
                if (this.props.isDevMode)
                    console.log('handleSetSelectedSchool \n' + JSON.stringify(SchoolListWithDetails[i]));
                break;
            }
        }
    }

    //2024.09.02
    SettingListToArray = () => {
        let _schoolListArray = [];
        SchoolList.map((data, key) => {
            return _schoolListArray.push({ value: data, label: data });
        });
        _schoolListArray = [...new Set(_schoolListArray)];  //remove duplicates.
        this.setState({
            schoolListArray: _schoolListArray,
        });
    }

    //2024.09.02
    SendEmailPasswordViaApi = async () => {
        let success = false;
        let message = '';

        const schoolCode = CheckStringEmpty(this.state.schoolCode, '0');
        const email = CheckStringEmpty(this.state.loginEmailConfirm);
        if (email !== '') {

            const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Student/ForgotPassword/Request/${email}/${schoolCode}`;
            // Api/LearningCentre/Student/ForgotPassword/Request/{email}/{schoolCode}
            if (this.props.isDevMode)
                console.log(`SendEmailPasswordViaApi \n (${email}) (${schoolCode}) \n ${url}`)

            let done = false;
            await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        // 'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    success = CheckObjectBoolean(data, 'success');
                    if (!success) {
                        message = CheckObjectStringEmpty(data, 'message');
                        if (this.props.isDevMode)
                            console.log('Error', 'api - student forgot password - fetch (failed)\n' + JSON.stringify(data));
                    }
                    done = true;
                })
                .catch(error => {
                    message = error.message;
                    done = true;
                    if (this.props.isDevMode)
                        console.log('Error', 'api - student forgot password - fetch (error)\n' + error.message);
                });
            await DelayUntil(() => done === true);
        }
        return { result: success, error: message };
    }

    // getMessage() {
    //     return { __html: this.state.errorMessage };
    // }

    // handleCloseModal = () => {
    //     this.setState({ showModal: false });
    // }

    render = () => {
        return (
            <div style={{
                backgroundColor: 'transparent',
                // backgroundColor: 'pink', 
                height: '100%',
                width: '100%',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                paddingTop: 85,
            }}>
                <div className="container" style={{ maxWidth: '550px', backgroundColor: 'transparent', paddingTop: 15, paddingBottom: 15, borderRadius: 5 }}>
                    <div className="row">
                        <aside className="col-sm-12 font-weight-bold">
                            <p style={{
                                color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                            }}>iKEY Live Quiz</p>
                            <div className="card">
                                <article className="card-body">
                                    <a href="/" className="float-right btn btn-outline-primary">{Locale("back", this.props.Locale)}</a>
                                    <h4 className="card-title mb-4 mt-1"><b>{Locale("password-reset", this.props.Locale)}</b></h4>

                                    <div className="form-group">
                                        <label>{Locale("your-email", this.props.Locale)}</label>
                                        <input name="email" className="form-control" placeholder={Locale("email-address", this.props.Locale)} type="email"
                                            onChange={this.handleSetEmail}
                                            value={this.state.loginEmail}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>{Locale("confirm-your-email", this.props.Locale)}</label>
                                        <input name="emailConfirm" className="form-control" placeholder={Locale("email-address", this.props.Locale)} type="email"
                                            onChange={this.handleSetEmailConfirm}
                                            value={this.state.loginEmailConfirm}
                                        />
                                    </div>

                                    <div className="form-group" hidden>
                                        <label>{Locale("label-school", this.props.Locale)} (optional)</label>
                                        <Select
                                            id='r-select-school-home'
                                            classNamePrefix={'r-select'}
                                            options={this.state.schoolListArray}
                                            menuIsOpen={this.state.showSelectSchoolListOption}
                                            placeholder={
                                                this.state.selectedSchoolName.length > 0 ?
                                                    this.state.selectedSchoolName
                                                    : Locale("text-school-name", this.props.Locale)
                                            }
                                            onInputChange={(e) => {
                                                let inputText = String(e);
                                                this.setState({
                                                    showSelectSchoolListOption: inputText.length >= 2 ? true : false,
                                                });
                                            }}
                                            onChange={(val) => this.handleSetSelectedSchool(val)}
                                            value={this.state.selectedSchoolName}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <div className='form-warning-text' dangerouslySetInnerHTML={{ __html: Locale("reset-password-warning-text", this.props.Locale) }}></div>
                                    </div>

                                    <div className="form-group">
                                        <button type="button" className="btn btn-primary btn-block"
                                            onClick={this.handleCheckEmail}
                                        >{Locale("get-password-reset-link", this.props.Locale)}</button>
                                    </div>

                                </article>
                            </div>
                        </aside>
                    </div>
                </div>

                {/* <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{Locale("invalid-info", this.props.Locale)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><div dangerouslySetInnerHTML={this.getMessage()}></div></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>{Locale("close", this.props.Locale)}</Button>
                    </Modal.Footer>
                </Modal> */}
            </div>
        );
    }
}