import React from 'react';
import { Redirect } from 'react-router-dom';
// import { renderToStaticMarkup } from 'react-dom/server'
import {
    // osVersion, osName, browserName, fullBrowserVersion
    browserName
} from "react-device-detect";
// import ReactDOM from 'react-dom';
// import Banner from './images/Live_Quiz_Banner.png';
import Banner from './images/Live_Quiz_Banner_v2.png';
import PE_Banner from './images/Participated_Events_Banner.png';
import Banner_PrivateEvent_1 from './images/Banner_UniqueEvent_v1.png';
import Banner_PrivateEvent_2 from './images/Banner_UniqueEvent_v1_1.png';
// import Banner_Wordwall from './images/Banner_Wordwall.png';
import Banner_MiniGame from './images/Banner_MiniGame.png';
// import { Dropdown, DropdownButton, Button, Modal } from 'react-bootstrap';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
// import _ from 'lodash';
import Select from 'react-select';
import LoadingIndicator from './LoadingIndicator.js';
import { Locale, Lang } from '../../Localization/CustomLocalization.js';
import { NationalState, SchoolList, District } from '../../components/NationalQuizEventSignUpSettings.js';
// import firebase, {
//     app, firestore, loggedIn$,
//     dbCommon, dbLiveQuiz, dbQuestion, dbLogs
// } from '../components/Firebase.js'
import './css/QuizHomeCSS.css';
import LocalizationSwitcher from '../../Localization/LocalizationSwitcher';
import { GlobalSetting } from '../../components/GlobalSetting';
import { CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, DelayUntil, GradeOptions, Profile_ValidateProperties } from '../../components/GlobalFunctions';      //2023.09.11
import QuizHistoryList from './QuizHistoryList';
// import app_store_logo from './images/app_store_logo.png';
// import google_play_logo from './images/google_play_logo.png';
// import firebase from 'firebase';


const Profile = {
    Name: 'Name',
    Center: 'Center',
    Grade: 'Grade',
    Contact: 'Contact',
    NationalState: 'NationalState',
    Guardian: 'Guardian',
    School: 'School',
    PolicyTncAgree: 'PolicyTncAgree',
    PolicyTncAgreeDateTime: 'PolicyTncAgreeDateTime',
    Below13: 'Below13',
    SimStudent: 'SimStudent',

    //2021.07.30
    Gender: 'Gender',
    Race: 'Race',
    Religion: 'Religion',

    //2021.10.12
    DistrictArea: 'DistrictArea',

    //2023.06.14
    Classroom: 'Classroom',
};

export default class QuizHome extends React.Component {

    constructor(props) {
        super(props);

        //2021.03.23
        this.ref_IsCenterStudent = React.createRef();
        this.ref_EventGroupOption = React.createRef();

        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        debugMode: false,

        redirect: false,
        redirectLink: '',

        showQuizModal: false,
        quizRoomCode: '',
        quizRoomId: '',

        quizRoomInfo: [],   //2021.01.19

        todayDT: '',
        isProcessing: false,

        isRealtimeProfileExists: false,
        isProfileExists: false,
        profileSnapshot: {},
        profileFetched: {},
        isProfileSyncDone: false,

        toggleEditProfileUi: false,
        cachedProfile: {},
        gradeOptions: [],
        gradeSelection: '',
        errorMessage: '',

        //2020.11.21
        hasNewPropertyInProfile: false,
        toggleShowQuizHistoryList: false,
        isHistoryListSyncDone: false,

        //2020.11.24
        toggleShowUpcomingQuiz: false,

        //2020.11.26
        schoolListArray: [],
        nationalStateListArray: [],
        showSelectSchoolListOption: false,
        showSelectNationalStateOption: false,

        //2024.05.23
        policyTnc_enableChecking: false,

        //2020.11.27
        showPolicyTncAlertModal: false,
        // isPolicyTncStateTheLatest: false,

        //2020.11.28
        policyTncVersion: 0,
        policyTncVersion_PublishedDate: '',
        policyTncVersion_User: 0,
        policyTncVersion_User_AgreeDate: '',
        toggleShowHide_GuardianNameField: false,

        //2020.12.02
        policyTnc_isNewAgree: false,

        //2020.12.03
        show_ProfileInfoRequireUpdate_AlertModal: false,

        //2020.12.04
        UpcomingQuizList: [],

        //2020.12.15
        selectedEvent_Code: '',
        toggle_LiveQuiz_EventDetail_AlertModal: false,
        liveQuiz_EventDetail_RoomDetail: '',
        liveQuiz_EventDetail_ZoomMeetingDetail: '',
        liveQuiz_EventDetail_GroupDetail: '',
        liveQuiz_EventDetail_HasJoined: false,
        liveQuiz_EventDetail_ActionInProgress: false,
        liveQuiz_EventDetail_UpdateActionInProgress: false,

        //2022.10.15
        liveQuiz_EventDetail_TelegramInfo: '',

        //2021.02.10
        // eventGradeOptions: [],
        selectedEventGroupOption: '',
        selectedEventGroupOptionId: 0,  //2021.07.02
        selectedEventGroupOptionLabel: '',   //2022.05.17
        // selectedGroupEventOptions: [],  //2022.05.17

        //2021.02.17
        selectedEventIsCenterStudent: '',
        selectedCenterName: '',
        selectedEventName: '',
        // selectedPartnerName: '',    //2022.05.13

        //2021.02.23
        selectedEventModal: null,

        //2021.03.04
        quizGlobalTimeStart: '',
        quizGlobalTimeEnd: '',

        //2021.04.17
        isNameEmpty: false,
        isContactNumberEmpty: false,
        isNationalStateUnselected: false,
        isGradeUnselected: false,
        isGuardianNameEmpty: false,

        //2021.07.30
        // GenderOptions: [],
        // RaceOptions: [],
        GenderOptions: [
            { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", this.props.Locale) },
            { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", this.props.Locale) },
            { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", this.props.Locale) },
        ],
        RaceOptions: [
            { value: Locale("label-race-1", Lang.English), label: Locale("label-race-1", this.props.Locale) },
            { value: Locale("label-race-2", Lang.English), label: Locale("label-race-2", this.props.Locale) },
            { value: Locale("label-race-3", Lang.English), label: Locale("label-race-3", this.props.Locale) },
            { value: Locale("label-race-0", Lang.English), label: Locale("label-race-0", this.props.Locale) },
        ],

        //2021.10.12
        filteredByState_DistrictAreaList: [],
        showSelectDistrictAreaOption: false,
        isDistrictAreaUnselected: false,

        //2022.08.08
        toggleChangePasswordUi: false,
        change_newPassword: '',
        change_newPassword_confirm: '',

        //2023.11.20
        toggleChangeEmailUi: false,
        change_newEmail: '',
        change_newEmail_confirm: '',

        //2023.12.06
        ProfileEditPermissionAllowed: null,
        ProfileLinkedOrganizers: [],
        ProfileLinkedOrganizersNameList: [],

        //2024.08.05
        StudentQuizRoomList: [],
        StudentQuizRoomList_Processing: true,
    });

    componentDidMount = async () => {

        window.scrollTo(0, 0);
        //2024.06.19
        const _browserName = browserName.toLowerCase();
        if (_browserName.includes('safari'))
            document.body.scrollTop = 0;
        else if (_browserName.includes('chrome')
            || _browserName.includes('firefox')
            || _browserName.includes('edge'))
            document.documentElement.scrollTop = 0;

        //2022.06.16
        localStorage.removeItem('RgistrationDataViaLoginEventList');

        //2021.08.19
        this.props.SetScreen(this.props.Screen.HomeScreen);

        //2021.09.01 - reset event list.
        this.props.ResetEventList();
        await Delay(500);

        //2022.06.03
        this.props.SetEventInfo('', 0, 0);

        this.setState({
            todayDT: moment().format('YYYYMMDD'),
            policyTncVersion_PublishedDate: this.state.policyTncVersion_PublishedDate !== '' ? this.state.policyTncVersion_PublishedDate : this.props.policyTncVersion_PublishedDate,
        });

        if (this.props.user !== null) {

            //2023.11.15
            this.props.SetLoading('', Locale("processing", this.props.Locale), false);

            // localStorage.removeItem("LiveQuizHistoryList_LU_" + this.props.user.uid);
            // localStorage.removeItem("LiveQuizHistoryList_List_" + this.props.user.uid);

            //2020.12.11
            if (this.props.reEnterQuizRoom) {
                this.props.SetReEnterQuizRoom(false);
                this.GotoQuizLive();
                return null;
            }

            //2021.09.01
            if (this.props.isDevMode === false) {
                this.props.LoadAllEvents(false);
                await DelayUntil(() => this.props.Events.length > 0);

                //2020.12.04
                let events = [];
                // Promise.all(
                this.props.Events.map((data, key) => {
                    if (data.Active === true) {
                        let _IsEnded = this.CheckIfEventIsEnded(data);  //2020.12.15
                        // let _hasJoinedEvent = data.Date !== 'TBA' ? await this.CheckIfEventIsJoined(data) : null;    //2021.02.17

                        //2021.04.03 - add condition on showing events.
                        let canShowEvent = false;
                        if (
                            data.Role === 'Admin'
                            // (
                            //     this.props.user.email === 'jaychb@yahoo.com'
                            //     || this.props.user.email === '1@test.com'
                            // )
                        ) { canShowEvent = true; }
                        else if (data.Role === 'Global') { canShowEvent = true; }
                        else if (data.Role === 'Private' && data.OrganizerIdentity === this.props.OrganizerIdentity) { canShowEvent = true; }   //2021.11.25
                        else { canShowEvent = false; }

                        if (canShowEvent)   //2021.04.03 - add condition on showing events.
                        {
                            events.push({
                                CreatedDate: data.CreatedDate ?? '',  //2022.05.30

                                Date: data.Date,
                                EventDate: data.DateStart,  //2020.12.31
                                Event: data.Event,
                                Subject: data.EventName,
                                EventName: data.EventName,
                                Organizer: data.Organizer,
                                // ZoomRoomId: data.ZoomRoomId,
                                // ZoomPassCode: data.ZoomPassCode,
                                // ZoomUrl: data.ZoomUrl,
                                QuizRooms: data.QuizRooms,

                                //2020.12.15
                                Active: data.Active,
                                IsEnded: _IsEnded,
                                EventCode: data.EventCode,

                                //2021.01.13
                                // ZoomInfo: data.ZoomInfo,
                                //2021.02.10
                                ZoomInfo: data.ZoomInfo !== null ? data.ZoomInfo[0] : null,

                                //2021.01.18
                                // FacebookInfo: data.FacebookInfo[0]
                                //2021.02.10
                                FacebookInfo: data.FacebookInfo !== null ? data.FacebookInfo[0] : null,

                                //2022.10.15
                                TelegramInfo: data.TelegramInfo !== null ? data.TelegramInfo : null,

                                //2021.02.10
                                // ExtraGradeSetting: data.ExtraGradeSetting,

                                //2021.02.17
                                HasJoinedEvent: false,  //_hasJoinedEvent,
                                // HasJoinedEvent: _hasJoinedEvent,

                                //2021.02.23
                                DateStart: data.DateStart,
                                DateEnd: data.DateEnd,

                                //2021.03.04
                                TimeStart: data.hasOwnProperty('TimeStart') ?
                                    data.TimeStart
                                    : data.QuizRooms[0].Rooms[0].hasOwnProperty('TimeStart') ? data.QuizRooms[0].Rooms[0].TimeStart : '-',
                                TimeEnd: data.hasOwnProperty('TimeEnd') ?
                                    data.TimeEnd
                                    : data.QuizRooms[0].Rooms[0].hasOwnProperty('TimeEnd') ? data.QuizRooms[0].Rooms[0].TimeEnd : '-',

                                //2021.03.17
                                RoomsHeldOnSelfTime: data.RoomsHeldOnSelfTime,
                                // IsPublic: data.IsPublic,

                                //2021.11.26
                                IsPublic: data.Role === 'Admin' || data.Role === 'Global' ? true : false,

                                //2021.03.18
                                OrganizerDisplayName: data.hasOwnProperty('OrganizerDisplayName') ? data.OrganizerDisplayName : data.Organizer,

                                //2021.07.14
                                OrganizerIdentity: data.hasOwnProperty('OrganizerIdentity') ? data.OrganizerIdentity : '',
                                PrivateDistributeRoomCode: data.hasOwnProperty('PrivateDistributeRoomCode') ? data.PrivateDistributeRoomCode : false,

                                //2021.11.10
                                RegistrationIsEnded: moment() >= moment(data.RegistrationEnd),
                                RegistrationEnd: data.RegistrationEnd,
                                RegistrationOverloaded: data.hasOwnProperty('RegistrationOverloaded') ? data.RegistrationOverloaded : false,

                                // //2021.11.11
                                // CertSerialPrefix: data.hasOwnProperty('CertSerialPrefix') ? data.CertSerialPrefix : '',
                                // CertLayoutTypeId: data.hasOwnProperty('CertLayoutTypeId') ? data.CertLayoutTypeId : 1,
                                // EventSharedReportUrl: data.hasOwnProperty('EventSharedReportUrl') ? data.EventSharedReportUrl : '',
                                // CertDownloadDelayed: data.hasOwnProperty('CertDownloadDelayed') ? data.CertDownloadDelayed : false,
                            });
                        }
                    }
                    return null;
                });
                // );
                if (events.length > 0) {
                    // await Delay(3000);

                    // //sort events by date, future at first.     //2020.12.31
                    // events.sort(function (a, b) {
                    //     if (moment(a.EventDate, 'YYYY-MM-DD') < moment(b.EventDate, 'YYYY-MM-DD')) {
                    //         return 1;
                    //     }
                    //     if (moment(a.EventDate, 'YYYY-MM-DD') > moment(b.EventDate, 'YYYY-MM-DD')) {
                    //         return -1;
                    //     }
                    //     //2021.08.13 - combined become 1 loop.
                    //     if (moment() > moment(a.EventDate, 'YYYY-MM-DD')) {
                    //         return 1;
                    //     }
                    //     if (moment() < moment(a.EventDate, 'YYYY-MM-DD')) {
                    //         return -1;
                    //     // }
                    //     return 0;
                    // });

                    // //2021.01.14    //sort again   //sort overdue at last.
                    // events.sort(function (a, b) {
                    //     if (moment() > moment(a.EventDate, 'YYYY-MM-DD')) {
                    //         return 1;
                    //     }
                    //     if (moment() < moment(a.EventDate, 'YYYY-MM-DD')) {
                    //         return -1;
                    //     }
                    //     return 0;
                    // });

                    //2021.08.30 = moved to top.
                    // //2021.02.17
                    // events.map(async (data, key) => {
                    //     let _hasJoinedEvent = data.Date !== 'TBA' ? await this.CheckIfEventIsJoined(data) : null; //2021.02.17
                    //     return events[key].HasJoinedEvent = _hasJoinedEvent;
                    // });
                    // await Delay(2000);

                    //2021.08.30
                    // events.sort((a, b) => moment(b.EventDate, 'YYYY-MM-DD') - moment(a.EventDate, 'YYYY-MM-DD'));

                    //2022.05.30
                    let newList = [];
                    let endedList = [];
                    events.map((data, key) => {
                        if (!data.IsEnded)
                            newList.push(data);
                        else
                            endedList.push(data);
                        return null;
                    });
                    newList.sort((a, b) => moment(a.CreatedDate, 'YYYY-MM-DD hh:mm:ss A') - moment(b.CreatedDate, 'YYYY-MM-DD hh:mm:ss A'));
                    endedList.sort((a, b) => moment(b.EventDate, 'YYYY-MM-DD') - moment(a.EventDate, 'YYYY-MM-DD'));
                    endedList.map((data, key) => {
                        return newList.push(data);
                    });
                    events = newList;

                    //2021.08.30
                    events.map(async (data, key) => {
                        let _hasJoinedEvent = data.Date !== 'TBA' ? await this.CheckIfEventIsJoined(data) : null; //2021.02.17
                        events[key].HasJoinedEvent = _hasJoinedEvent;
                        events[key].Subject = events[key].Subject + (_hasJoinedEvent ? '<br /><b><u><i>Registered</i></u></b>' : '');
                        return null;
                    });
                    await Delay(500);

                    this.ResetOptionsLabel();   //2022.11.01
                    this.setState({
                        UpcomingQuizList: events,
                        // GenderOptions: [
                        //     { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", this.props.Locale) },
                        //     { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", this.props.Locale) },
                        //     { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", this.props.Locale) },
                        // ],
                        // RaceOptions: [
                        //     { value: Locale("label-race-1", Lang.English), label: Locale("label-race-1", this.props.Locale) },
                        //     { value: Locale("label-race-2", Lang.English), label: Locale("label-race-2", this.props.Locale) },
                        //     { value: Locale("label-race-3", Lang.English), label: Locale("label-race-3", this.props.Locale) },
                        //     { value: Locale("label-race-0", Lang.English), label: Locale("label-race-0", this.props.Locale) },
                        // ],
                    });
                }
            }

            if (this.props.isSuperAdmin === false) {

                //Check & Sync Profile.
                //2023.12.06 - revamp, now fetch from API.
                //2022.11.01 - only fetch Profile from FS, no more fetch & sync on both FS & RTDB.
                await this.LoadProfile();
                // await this.LoginInfoValidation();    //2023.09.14 - obsolete RTDB.

                this.Get_ProfileEditPermissionAllowed_And_OrganizerList();  //2024.01.31

                //Load history.
                // if (this.state.isProfileSyncDone)
                //     await this.LoadQuizHistoryFromLocal();

                //Set Data.
                this.setState({
                    gradeSelection: this.props.profile !== null ? this.props.profile.Grade : '',
                    // toggleShowQuizHistoryList: this.state.isProfileSyncDone,
                });


                // //2020.12.05 = moved to here from bottom.
                // let isValid = await this.ProfileEditedDataValidation();
                // if (!isValid) {
                //     this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                // }

                //2024.05.23 - no more policyTnc checking needed by Calvin request.
                let isValid = this.state.policyTnc_enableChecking === false ? true : await this.CheckPolicyTncAgreeState();
                // //2020.11.27    //2020.12.05
                // const isDone = await this.CheckPolicyTncAgreeState();
                if (isValid) {
                    isValid = await this.ProfileEditedDataValidation();
                    if (!isValid) {
                        this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                    }
                    // else {

                    //     //2021.01.14    //disabled as the event has already over.
                    //     // //2020.12.09
                    //     // if (moment().format('YYYYMMDD') <= moment('2020-12-11').format('YYYYMMDD')) {
                    //     //     setTimeout(async () => {
                    //     //         // this.ShowEventDetails(this.state.UpcomingQuizList[0].Date,
                    //     //         //     this.state.UpcomingQuizList[0].Event,
                    //     //         //     this.state.UpcomingQuizList[0].Organizer);

                    //     //         //2020.12.15
                    //     //         await this.ShowEventDetails(this.state.UpcomingQuizList[0].EventCode);
                    //     //     }, 1500);
                    //     // }
                    // }
                }
                // //2020.11.27
                // await this.CheckPolicyTncAgreeState();

                // await Delay(3000);

                // //2020.12.03
                // setTimeout(async () => {
                //     let isValid = await this.ProfileEditedDataValidation();
                //     if (!isValid) {
                //         this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                //     }
                // }, 1000);
            }
            else {
                this.setState({ isProfileSyncDone: true });
            }

            //2020.11.26
            this.SettingListToArray();

            // //2020.12.03
            // this.PopulateGradeOptions();

            // this.props.SetAlert('isRedirectToHistoryList', this.props.isRedirectToHistoryList);

            // console.log(JSON.stringify(this.props.profile));

            //2025.01.10 - calvin requested to take out welcome popup.
            // //2023.11.15
            // this.props.SetAlert(Locale("welcome-title", this.props.Locale), Locale("welcome-note", this.props.Locale), true);

            //2024.08.05
            await this.GetStudentQuizRoomList();
        }
        else {
            this.GotoLoginPage();
        }
    }

    //2023.10.20

    //2022.11.01
    ResetOptionsLabel = () => {
        this.PopulateGradeOptions();
        this.setState({
            GenderOptions: [
                { value: Locale("label-gender-male", Lang.English), label: Locale("label-gender-male", this.props.Locale) },
                { value: Locale("label-gender-female", Lang.English), label: Locale("label-gender-female", this.props.Locale) },
                { value: Locale("label-gender-other", Lang.English), label: Locale("label-gender-other", this.props.Locale) },
            ],
            RaceOptions: [
                { value: Locale("label-race-1", Lang.English), label: Locale("label-race-1", this.props.Locale) },
                { value: Locale("label-race-2", Lang.English), label: Locale("label-race-2", this.props.Locale) },
                { value: Locale("label-race-3", Lang.English), label: Locale("label-race-3", this.props.Locale) },
                { value: Locale("label-race-0", Lang.English), label: Locale("label-race-0", this.props.Locale) },
            ],
        });
    }

    //2021.07.30
    GetOptionsLabel = (options, value) => {
        let label = '';
        if (options.length > 0 && value !== '') {
            let index = options.findIndex(x => x.value === value);
            if (index > -1)
                label = options[index].label;
            // console.log('index : ' + index + '\nlabel : ' + label + '\nvalue : ' + value + '\n' + JSON.stringify(options[index]));
        }
        return label;
    }

    GotoPage = (path) => { this.setState({ redirectLink: path, redirect: true, }); }    //2021.10.12

    //#region ==== Quiz Events ====

    //2020.12.14
    CheckIfEventIsEnded = (data) => {
        let isEnded = false;
        if (String(data.Date) !== 'TBA') {
            if (moment() > moment(String(data.DateEnd)).add(1, 'days'))
                isEnded = true;
        }
        return isEnded;
    }

    // //2020.12.04
    // ShowEventDetails = (eventDate, eventName, organizer) => {
    //     let _popupText = '';
    //     let _eventModal = null;

    //     this.state.UpcomingQuizList.map((data, key) => {
    //         if (data.Event === eventName && data.Organizer === organizer) {
    //             _eventModal = this.state.UpcomingQuizList[key];
    //         }
    //         return null;
    //     });

    //     if (_eventModal !== null && _eventModal.Date !== 'TBA') {

    //         _popupText = "<table width='100%'>"
    //             + "<tr>"
    //             + "<td width='45%' align='right'>" + Locale("event", this.props.Locale) + "</td>"
    //             + "<td width='10%' align='center'>:</td>"
    //             + "<td width='45%'><b>" + _eventModal.Subject + "</b></td>"
    //             + "</tr>"

    //             + "<tr>"
    //             + "<td width='45%' align='right'>" + Locale("organise-by", this.props.Locale) + "</td>"
    //             + "<td width='10%' align='center'>:</td>"
    //             + "<td width='45%'><b>" + _eventModal.Organizer + "</b></td>"
    //             + "</tr>"

    //             + "<tr><td colspan='3'><hr /></td></tr>"
    //             + "<tr><td colspan='3' align='center'><b><u>" + Locale("details", this.props.Locale) + "</u></b></td></tr>"

    //             + "<tr>"
    //             + "<td align='right'>Zoom Meeting Id</td>"
    //             + "<td align='center'>:</td>"
    //             + "<td>" + _eventModal.ZoomInfo.RoomId + "</td>"
    //             + "</tr>"

    //             + "<tr>"
    //             + "<td align='right'>Zoom Meeting Passcode</td>"
    //             + "<td align='center'>:</td>"
    //             + "<td>" + _eventModal.ZoomPassCode + "</td>"
    //             + "</tr>"

    //             + "<tr><td colspan='3' height='10'></td></tr>"
    //             + "<tr><td colspan='3' align='center'><a href='" + _eventModal.ZoomUrl + "' target='_blank'>Launch Zoom</a></td></tr>"
    //             // + "<tr><td colspan='3' align='center'><button onclick='window.open('" + _eventModal.ZoomUrl + "', '_blank')'>Launch Zoom</button></td></tr>"
    //             ;

    //         _eventModal.QuizRooms.map((data1, key1) => {

    //             _popupText += "<tr><td colspan='3'><hr /></td></tr>"
    //                 + "<tr><td colspan='3' align='center'><b><u>" + this.getGradeOptionText(data1.Group) + " " + Locale("room-code-1", this.props.Locale) + "</u></b></td></tr>";

    //             data1.Rooms.map((data2, key2) => {
    //                 // _popupText += "<tr>"
    //                 //     + "<td align='right'>" + Locale(String(data2.Subject).toLowerCase(), this.props.Locale) + "</td>"
    //                 //     + "<td align='center'>:</td>"
    //                 //     + "<td>" + d2_roomCode + "(" + d2_date + ")" + "(" + d2_timeStart + "~" + d2_timeEnd + ")" + "</td>"
    //                 //     + "</tr>";

    //                 //2020.12.08
    //                 let isQuizOnToday = moment(data2.Date) <= moment() && moment(data2.Date).add(1, 'day') > moment() ? true : false;
    //                 let isQuizOver = moment(data2.Date) <= moment() ? true : false;

    //                 let d2_roomCode = data2.RoomCode.length > 0 ?
    //                     !isQuizOver ?
    //                         data2.RoomCode
    //                         :
    //                         // String(data2.RoomCode) === 'ended' ?
    //                         //     Locale("quiz-ended", this.props.Locale)
    //                         //     :
    //                         //     "<b>" + data2.RoomCode + "</b>"
    //                         moment(data2.Date).format('YYYYMMDD') === moment().format('YYYYMMDD') ?
    //                             "<b>" + data2.RoomCode + "</b>"
    //                             :
    //                             Locale("quiz-ended", this.props.Locale)
    //                     : Locale("coming-soon", this.props.Locale);

    //                 let d2_date = data2.Date.length > 0 ? data2.Date : Locale("coming-soon", this.props.Locale);
    //                 let d2_timeStart = data2.TimeStart.length > 0 ? data2.TimeStart : Locale("coming-soon", this.props.Locale);
    //                 let d2_timeEnd = data2.TimeEnd.length > 0 ? data2.TimeEnd : Locale("coming-soon", this.props.Locale);

    //                 _popupText += "<tr><td colspan='3'>"
    //                     + "<table width='100%' cellpadding='5'><tr " + (isQuizOnToday ? "bgColor='lavender'" : "") + ">"
    //                     + "<td width='15%'>" + Locale(String(data2.Subject).toLowerCase(), this.props.Locale) + "</td>"
    //                     + "<td>:</td>"
    //                     + "<td width='25%'>" + d2_roomCode + "</td>"
    //                     + "<td></td>"
    //                     + "<td width='28%'>(" + d2_timeStart + "~" + d2_timeEnd + ")</td>"
    //                     + "<td></td>"
    //                     + "<td width='22%'>(" + d2_date + ")</td>"
    //                     + "</tr></table>"
    //                     + "</td></tr>";

    //                 return null;
    //             })
    //             return null;
    //         })

    //         // _popupText += "<tr><td colspan='3'><hr /></td></tr>"
    //         //     + "<tr><td colspan='3' align='center'><u>Standard 1 Room Code</u></td></tr>"

    //         //     + "<tr>"
    //         //     + "<td align='right'>English</td>"
    //         //     + "<td align='center'>:</td>"
    //         //     + "<td>12345</td>"
    //         //     + "</tr>"

    //         //     + "<tr>"
    //         //     + "<td align='right'>Chinese</td>"
    //         //     + "<td align='center'>:</td>"
    //         //     + "<td>12345</td>"
    //         //     + "</tr>"

    //         //     + "<tr>"
    //         //     + "<td align='right'>Malay</td>"
    //         //     + "<td align='center'>:</td>"
    //         //     + "<td>12345</td>"
    //         //     + "</tr>"
    //         //     ;

    //         _popupText += "</table>";

    //         // let _title = '';
    //         // if (this.props.Locale === Lang.Chinese)
    //         //     _title = '《' + _eventModal.Subject + '》由 ' + _eventModal.Organizer + Locale("co-op-ikey", this.props.Locale);
    //         // else
    //         //     _title = '<' + _eventModal.Subject + '> ' + String(Locale("organise-by", this.props.Locale)).toLowerCase() + ' ' + _eventModal.Organizer + Locale("co-op-ikey", this.props.Locale);

    //         this.props.SetAlert('LIVE QUIZ EVENT DETAILS', _popupText);
    //     }
    // }

    //2020.12.15    //massively modified.
    ShowEventDetails = async (eventCode) => {

        this.setState({
            selectedEvent_Code: eventCode,
            liveQuiz_EventDetail_ActionInProgress: this.state.liveQuiz_EventDetail_UpdateActionInProgress ? false : true,

            //2021.02.17
            selectedEventGroupOption: '',
            selectedEventIsCenterStudent: '',

            //2022.05.17
            selectedEventGroupOptionLabel: '',
            // selectedGroupEventOptions: [],
        });

        // let _popupText = '';
        let _eventModal = null;
        let _roomDetailText = '';
        let _zoomMeetingDetailText = '';
        let _groupDetailText = '';

        this.state.UpcomingQuizList.map((data, key) => {
            // if (data.Event === eventName && data.Organizer === organizer) {
            // if (data.Event === eventCode) {
            if (data.EventCode === eventCode) {
                _eventModal = this.state.UpcomingQuizList[key];
            }
            return null;
        });


        //2020.12.15
        // let _hasJoinedEvent = _eventModal !== null && _eventModal.Date !== 'TBA' ? await this.CheckIfEventIsJoined(_eventModal) : null;

        // if (_eventModal !== null && _eventModal.Date !== 'TBA') {
        if (
            _eventModal !== null
            && _eventModal.IsEnded === false
        ) {
            if (_eventModal !== null) {
                if (_eventModal.Date !== 'TBA') {

                    _roomDetailText = "<table width='100%'>"
                        + "<tr>"
                        + "<td width='25%' align='right'>" + Locale("event", this.props.Locale) + "</td>"
                        + "<td width='10%' align='center'>:</td>"
                        + "<td width='65%'><b>" + _eventModal.Subject + "</b></td>"
                        + "</tr>"

                        + "<tr>"
                        + "<td width='25%' align='right'>" + Locale("organise-by", this.props.Locale) + "</td>"
                        + "<td width='10%' align='center'>:</td>"
                        // + "<td width='45%'><b>" + _eventModal.Organizer + "</b></td>"
                        + "<td width='65%'><b>" + _eventModal.OrganizerDisplayName + "</b></td>"
                        + "</tr>";

                    // //2022.05.13
                    // // _eventModal.PartnerDisplayName = 'SK2 Partner';
                    // if (_eventModal.PartnerDisplayName) {
                    //     if (String(_eventModal.PartnerDisplayName).length > 0) {
                    //         _roomDetailText += "<tr>"
                    //             + "<td width='25%' align='right'>" + Locale("partnered-with", this.props.Locale) + "</td>"
                    //             + "<td width='10%' align='center'>:</td>"
                    //             + "<td width='65%'><b>" + _eventModal.PartnerDisplayName + "</b></td>"
                    //             + "</tr>";
                    //     }
                    // }

                    // + "</table>";

                    //2021.01.18
                    if (_eventModal.FacebookInfo !== null && _eventModal.FacebookInfo.Active) {
                        _roomDetailText += "<tr><td colspan='3' style='height:55px;text-align:center;vertical-align:middle;'>"
                            + "<u><a href='" + _eventModal.FacebookInfo.Url + "' target='_blank'>"
                            + Locale("quiz-event-join-facebook", this.props.Locale)
                            + "</a></u></td></tr>";
                    }

                    _roomDetailText += "</table>";

                    //2020.12.31    //Abacus no need Zoom section.
                    // if (String(_eventModal.EventCode).toLowerCase().includes('abacus') === false) {

                    //2021.01.14    //change condition.
                    if (_eventModal.ZoomInfo !== null && _eventModal.ZoomInfo.Active) {

                        //2020.12.23
                        let zoomRoomId = String(_eventModal.ZoomInfo.RoomId) === 'TBA' ?
                            Locale("coming-soon", this.props.Locale)
                            :
                            _eventModal.ZoomInfo.RoomId;

                        let zoomRoomPasscode = String(_eventModal.ZoomInfo.PassCode) === 'TBA' ?
                            Locale("coming-soon", this.props.Locale)
                            :
                            _eventModal.ZoomInfo.PassCode;

                        _zoomMeetingDetailText = "<table width='100%'>"
                            + "<tr><td colspan='3'><hr /></td></tr>"
                            + "<tr><td colspan='3' align='center'><b><u>" + Locale("details", this.props.Locale) + "</u></b></td></tr>"

                            + "<tr>"
                            + "<td width='45%' align='right'>Zoom Meeting Id</td>"
                            + "<td width='10%' align='center'>:</td>"
                            // + "<td width='45%'>" + _eventModal.ZoomInfo.RoomId + "</td>"
                            + "<td width='45%'>" + zoomRoomId + "</td>"
                            + "</tr>"

                            + "<tr>"
                            + "<td align='right'>Zoom Meeting Passcode</td>"
                            + "<td align='center'>:</td>"
                            // + "<td>" + _eventModal.ZoomPassCode + "</td>"
                            + "<td>" + zoomRoomPasscode + "</td>"
                            + "</tr>"

                            + "<tr><td colspan='3' height='10'></td></tr>"
                            + "<tr><td colspan='3' align='center'><a href='" + _eventModal.ZoomInfo.Url + "' target='_blank'>Launch Zoom</a></td></tr>"
                            // + "<tr><td colspan='3' align='center'><button onclick='window.open('" + _eventModal.ZoomInfo.Url + "', '_blank')'>Launch Zoom</button></td></tr>"

                            + "</table>";

                    }

                    //2021.07.14
                    let _PrivateDistributeRoomCode = false;
                    if (_eventModal.hasOwnProperty('PrivateDistributeRoomCode')) {
                        _PrivateDistributeRoomCode = _eventModal.PrivateDistributeRoomCode;
                    }

                    if (CheckBoolean(_eventModal.RoomsHeldOnSelfTime) === true) {
                        //2021.09.10 - now using this pattern as main.
                        //disabled on 2021.02.18 - replaced by new pattern at below.
                        _groupDetailText += "<table width='100%'>";
                        _eventModal.QuizRooms.map((room, key1) => {

                            //2020.12.15
                            let _subTitleText = '';
                            if (_eventModal.EventCode.includes('spot')) {
                                _subTitleText = this.getGradeOptionText(room.Group);
                            }
                            else if (_eventModal.EventCode.includes('abacus')) {
                                _subTitleText = this.getGroupAbacusText(room.Group);
                            }
                            else if (_eventModal.EventCode.includes('colin')) {
                                _subTitleText = room.Group;
                            }
                            else {
                                _subTitleText = room.Group;
                            }

                            //2021.08.25
                            if (room.hasOwnProperty('Remark')) {
                                if (room.Remark.length > 0) {
                                    _subTitleText += ' ' + room.Remark;
                                }
                            }

                            // _groupDetailText += "<tr><td colspan='3'><hr /></td></tr>"
                            //     + "<tr><td colspan='3' align='center'><b><u>" + _subTitleText + " " + Locale("room-code-1", this.props.Locale) + "</u></b></td></tr>";

                            // _groupDetailText += "<tr><td colspan='3'><hr /></td></tr>"
                            //     + "<tr><td colspan='3' align='center'><b><u>" + _subTitleText + "</u></b></td></tr>";

                            //2021.02.10
                            _groupDetailText += "<tr><td colspan='3'>" + (key1 < 1 ? "<hr />" : "&nbsp;") + "</td></tr>";
                            _groupDetailText += "<tr><td colspan='3' align='center'><b><u>" + _subTitleText + "</u></b></td></tr>";

                            //2022.06.09
                            const currentTime = moment();
                            const eventDateHeld = moment(String(_eventModal.DateStart) + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss');
                            const eventDateHeldOneMoreDay = moment(String(_eventModal.DateStart) + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss').add(1, 'day');
                            const eventDateEnd = moment(String(_eventModal.DateEnd) + ' 23:59:00', 'YYYY-MM-DD HH:mm:ss');
                            const isQuizOnToday = currentTime >= eventDateHeld && eventDateHeldOneMoreDay > currentTime ? true : false;
                            const isQuizOver = currentTime >= eventDateEnd ? true : false;

                            if (room.Rooms.length > 0) {
                                room.Rooms.map((room, key2) => {
                                    // _popupText += "<tr>"
                                    //     + "<td align='right'>" + Locale(String(data2.Subject).toLowerCase(), this.props.Locale) + "</td>"
                                    //     + "<td align='center'>:</td>"
                                    //     + "<td>" + d2_roomCode + "(" + d2_date + ")" + "(" + d2_timeStart + "~" + d2_timeEnd + ")" + "</td>"
                                    //     + "</tr>";

                                    // //2020.12.08
                                    // let isQuizOnToday = moment(room.Date) <= moment() && moment(room.Date).add(1, 'day') > moment() ? true : false;
                                    // let isQuizOver = moment(room.Date) <= moment() ? true : false;

                                    //2021.01.21
                                    const _idName = 'groupRoomCode_' + room.RoomCode;
                                    // let _revealedRoomCode = renderToStaticMarkup(<span id={_idName} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>{room.RoomCode}</ span>);
                                    const _revealedRoomCode = '<span id="' + _idName + '" style="color:blue;cursor:pointer;text-decoration:underline">' + room.RoomCode + '</span>';

                                    //#region  old codes
                                    // let _todayDate = moment().format('YYYYMMDD');
                                    // let d2_roomCode = room.RoomCode.length > 0 ?
                                    //     !isQuizOver ?
                                    //         // data2.RoomCode
                                    //         String(room.RoomCode) === 'TBA' ?
                                    //             Locale("coming-soon", this.props.Locale)    //2020.12.23
                                    //             :
                                    //             // room.RoomCode
                                    //             _PrivateDistributeRoomCode ?
                                    //                 Locale("private-distribution", this.props.Locale)    //2021.07.14
                                    //                 :
                                    //                 _revealedRoomCode
                                    //         :
                                    //         // String(data2.RoomCode) === 'ended' ?
                                    //         //     Locale("quiz-ended", this.props.Locale)
                                    //         //     :
                                    //         //     "<b>" + data2.RoomCode + "</b>"

                                    //         //2021.09.14 - modified to juz the show room code.
                                    //         // moment(room.DateStart).format('YYYYMMDD') <= _todayDate
                                    //         //     && moment(room.DateEnd).format('YYYYMMDD') >= _todayDate ?
                                    //         //     // "<b>" + room.RoomCode + "</b>"
                                    //         //     "<b>" + _revealedRoomCode + "</b>"
                                    //         //     :
                                    //         // Locale("quiz-ended", this.props.Locale)
                                    //         "<b>" + _revealedRoomCode + "</b>"

                                    //     : Locale("coming-soon", this.props.Locale);
                                    //#endregion

                                    //2022.06.09
                                    let d2_date = room.Date.length > 0 ?
                                        // moment().format('YYYY-MM-DD') === String(_eventModal.DateStart) ?
                                        //     moment(String(_eventModal.DateStart) + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss').format('MMM <b>D</b>, YYYY')
                                        //     : 
                                        eventDateHeld.format('MMM <b>D</b>, YYYY')
                                        : Locale("coming-soon", this.props.Locale);
                                    // const d2_timeStart = room.TimeStart.length > 0 ? room.TimeStart : Locale("coming-soon", this.props.Locale);
                                    // const d2_timeEnd = room.TimeEnd.length > 0 ? room.TimeEnd : Locale("coming-soon", this.props.Locale);
                                    const d2_timeStart = CheckObjectStringEmpty(room, 'TimeStart');
                                    const d2_timeEnd = CheckObjectStringEmpty(room, 'TimeEnd');
                                    if (room.DateStart !== room.DateEnd)
                                        d2_date = eventDateHeld.format('<b>MMM D</b>') + ' ~ ' + eventDateEnd.format('<b>MMM D</b>') + eventDateEnd.format(', YYYY');

                                    //2022.06.11
                                    const isQuizNowStarted = d2_timeStart === '' ? false : currentTime >= moment(String(_eventModal.DateStart) + ' ' + d2_timeStart, 'YYYY-MM-DD HH:mm A');
                                    let d2_roomCode = Locale("coming-soon", this.props.Locale);
                                    if (_PrivateDistributeRoomCode) {
                                        d2_roomCode = Locale("private-distribution", this.props.Locale);
                                    }
                                    else {
                                        if (String(room.RoomCode).length > 0) {
                                            if (!isQuizOver && isQuizNowStarted) {
                                                if (String(room.RoomCode) !== 'TBA') {
                                                    d2_roomCode = "<b>" + _revealedRoomCode + "</b>";
                                                }
                                            }
                                        }
                                    }
                                    if (this.props.isDevMode) {
                                        console.log('isQuizOnToday', isQuizOnToday);
                                        console.log('isQuizOver', isQuizOver);
                                        console.log('isQuizNowStarted', isQuizNowStarted, String(_eventModal.DateStart) + ' ' + d2_timeStart);
                                    }

                                    //#region  old codes
                                    // let d2_date = room.Date.length > 0 ? moment(room.Date).format('MMM <b>D</b>, YYYY') : Locale("coming-soon", this.props.Locale);
                                    // let d2_timeStart = room.TimeStart.length > 0 ? room.TimeStart : Locale("coming-soon", this.props.Locale);
                                    // let d2_timeEnd = room.TimeEnd.length > 0 ? room.TimeEnd : Locale("coming-soon", this.props.Locale);

                                    // //2021.04.05
                                    // d2_date = String(d2_date).toLowerCase().includes('invalid') ? Locale("coming-soon", this.props.Locale) : d2_date;

                                    // // //2021.07.14
                                    // // if (String(room.Date).includes('~'))
                                    // if (room.DateStart !== room.DateEnd)
                                    //     d2_date = moment(room.DateStart).format('<b>MMM D</b>') + ' ~ ' + moment(room.DateEnd).format('<b>MMM D</b>') + moment(room.DateEnd).format(', YYYY');

                                    // _groupDetailText += "<tr><td colspan='3'>"
                                    //     + "<table width='100%' cellpadding='5'><tr " + (isQuizOnToday ? "bgColor='lavender'" : "") + ">"
                                    //     // + "<td width='15%'>" + Locale(String(data2.Subject).toLowerCase(), this.props.Locale) + "</td>"
                                    //     + "<td width='20%'>" + data2.Subject + "</td>"
                                    //     + "<td>:</td>"
                                    //     + "<td width='15%'>" + d2_roomCode + "</td>"
                                    //     + "<td></td>"
                                    //     + "<td width='33%'>(" + d2_timeStart + "~" + d2_timeEnd + ")</td>"
                                    //     + "<td></td>"
                                    //     + "<td width='22%'>(" + d2_date + ")</td>"
                                    //     + "</tr></table>"
                                    //     + "</td></tr>";
                                    //#endregion

                                    //2020.12.29
                                    // isQuizOnToday = true;    //testing
                                    _groupDetailText += "<tr><td colspan='3'>"
                                        + "<table width='100%' cellpadding='5' style='border:2px lavender solid;'>"

                                        //2021.01.19    //2021.02.10
                                        + "<tr>"
                                        + "<td width='45%' align='right'>Subject</td>"
                                        + "<td width='10%' align='center'>:</td>"
                                        + "<td width='45%'><span style='font-weight:500;'><i>" + room.Subject + "</i></span></td>"
                                        + "</tr>"

                                        + "<tr " + (isQuizOnToday ? "bgColor='lavender'" : "") + ">"
                                        // + "<td width='45%' align='right'>" + data2.Subject + "</td>"
                                        + "<td width='45%' align='right'>Room Code</td>"    //2021.01.05 - subject name changed to Room Code.
                                        + "<td width='10%' align='center'>:</td>"
                                        + "<td width='45%'>" + d2_roomCode + "</td>"
                                        + "</tr><tr>"
                                        + "<td align='right'>Time</td>"
                                        + "<td align='center'>:</td>"
                                        + "<td>" + d2_timeStart + "~" + d2_timeEnd + "</td>"
                                        + "</tr><tr>"
                                        + "<td align='right'>Date</td>"
                                        + "<td align='center'>:</td>"
                                        + "<td>" + d2_date + "</td>"
                                        + "</tr></table>"
                                        + "</td></tr>";

                                    return null;
                                });
                            }
                            else {
                                //filled with empty/coming-soon value.
                                _groupDetailText += "<tr><td colspan='3'>"
                                    + "<table width='100%' cellpadding='5' style='border:2px lavender solid;'>"

                                    + "<tr>"
                                    + "<td width='45%' align='right'>Subject</td>"
                                    + "<td width='10%' align='center'>:</td>"
                                    + "<td width='45%'><span style='font-weight:500;'><i>" + Locale("coming-soon", this.props.Locale) + "</i></span></td>"
                                    + "</tr>"

                                    + "<tr " + (isQuizOnToday ? "bgColor='lavender'" : "") + ">"
                                    + "<td width='45%' align='right'>Room Code</td>"    //2021.01.05 - subject name changed to Room Code.
                                    + "<td width='10%' align='center'>:</td>"
                                    + "<td width='45%'><i>" + Locale("coming-soon", this.props.Locale) + "</i></td>"
                                    + "</tr><tr>"
                                    + "<td align='right'>Time</td>"
                                    + "<td align='center'>:</td>"
                                    + "<td><i>" + Locale("coming-soon", this.props.Locale) + "</i></td>"
                                    + "</tr><tr>"
                                    + "<td align='right'>Date</td>"
                                    + "<td align='center'>:</td>"
                                    + "<td>" + eventDateHeld.format('MMM <b>D</b>, YYYY') + "</td>"
                                    + "</tr></table>"
                                    + "</td></tr>";
                            }
                            return null;
                        });
                        _groupDetailText += "</table><table width='100%'>";
                    }
                    else {
                        //=== 2021.02.18 - QuizRooms - new arrange pattern. === starts ===//

                        let _QuizRooms = [];
                        _eventModal.QuizRooms.map((room, key1) => {

                            let _subTitleText = '';
                            if (_eventModal.EventCode.includes('spot')) {
                                _subTitleText = this.getGradeOptionText(room.Group);
                            }
                            else if (_eventModal.EventCode.includes('abacus')) {
                                _subTitleText = this.getGroupAbacusText(room.Group);
                            }
                            else if (_eventModal.EventCode.includes('colin')) {
                                _subTitleText = room.Group;
                            }
                            else {
                                _subTitleText = room.Group;
                            }

                            //2021.08.25
                            if (room.hasOwnProperty('Remark')) {
                                if (room.Remark.length > 0) {
                                    _subTitleText += ' ' + room.Remark;
                                }
                            }

                            room.Rooms.map((room, key2) => {
                                return _QuizRooms.push({
                                    Group: _subTitleText,
                                    Subject: room.Subject,
                                    RoomCode: room.RoomCode,
                                    Date: room.Date,
                                    TimeStart: room.TimeStart,
                                    TimeEnd: room.TimeEnd,
                                    TimeLimit: room.TimeLimit,  //2021.02.23
                                });
                            });

                            return null;
                        });

                        //sort by Subject
                        _QuizRooms.sort(function (a, b) {
                            if (a.Subject > b.Subject) {
                                return 1;
                            }
                            if (a.Subject < b.Subject) {
                                return -1;
                            }
                            return 0;
                        });

                        //sort by TimeStart
                        _QuizRooms.sort(function (a, b) {
                            let _date = moment(a.Date, 'MMM D, YYYY').format('YYYY-MM-DD');
                            if (moment(_date + " " + a.TimeStart, 'MMM D, YYYY HH:mm A') > moment(_date + " " + b.TimeStart, 'MMM D, YYYY HH:mm A')) {
                                return 1;
                            }
                            if (moment(_date + " " + a.TimeStart, 'MMM D, YYYY HH:mm A') < moment(_date + " " + b.TimeStart, 'MMM D, YYYY HH:mm A')) {
                                return -1;
                            }
                            return 0;
                        });

                        //sort by Date
                        _QuizRooms.sort(function (a, b) {
                            if (moment(a.Date, 'MMM D, YYYY') > moment(b.Date, 'MMM D, YYYY')) {
                                return 1;
                            }
                            if (moment(a.Date, 'MMM D, YYYY') < moment(b.Date, 'MMM D, YYYY')) {
                                return -1;
                            }
                            return 0;
                        });

                        //sample data.
                        // navigator.clipboard.writeText(JSON.stringify(_QuizRooms));
                        // alert("copied");


                        //draw Ui.
                        // _groupDetailText += "<hr />";
                        // _groupDetailText += "<hr /><table width='100%' cellpadding='5' cellspacing='5' style='border:2px lavender solid;'>";
                        let _sectionDivider_byDate = '';
                        let _sectionDivider_bySubject = '';
                        let _sectionHtmlCode = '';
                        _QuizRooms.map((data, key) => {

                            // let isQuizOnToday = moment(data.Date) <= moment() && moment(data.Date).add(1, 'day') > moment() ? true : false;
                            let isQuizOver = moment() >= moment(data.Date, 'MMM D, YYYY') ? true : false;

                            let _idName = 'groupRoomCode_' + data.RoomCode;
                            // let _revealedRoomCode = renderToStaticMarkup(<span id={_idName} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>{data.RoomCode}</ span>);
                            let _revealedRoomCode = '<span id="' + _idName + '" style="color:blue;cursor:pointer;text-decoration:underline">' + data.RoomCode + '</span>';

                            let _todayDate = moment().format('YYYYMMDD');
                            let d2_roomCode = data.RoomCode.length > 0 ?
                                !isQuizOver ?
                                    String(data.RoomCode) === 'TBA' ?
                                        Locale("coming-soon", this.props.Locale)    //2020.12.23
                                        :
                                        _revealedRoomCode
                                    :
                                    moment(data.DateStart).format('YYYYMMDD') <= _todayDate
                                        && moment(data.DateEnd).format('YYYYMMDD') >= _todayDate ?
                                        "<b>" + _revealedRoomCode + "</b>"
                                        :
                                        Locale("quiz-ended", this.props.Locale)
                                : Locale("coming-soon", this.props.Locale);

                            //2021.02.23 - disable due to new Ui pattern.
                            // let d2_date = data.Date.length > 0 ? moment(data.Date).format('MMM <b>D</b>, YYYY') + " (" + moment(data.Date).format('ddd') + ")" : Locale("coming-soon", this.props.Locale);
                            // let d2_timeStart = data.TimeStart.length > 0 ? data.TimeStart : Locale("coming-soon", this.props.Locale);
                            // let d2_timeEnd = data.TimeEnd.length > 0 ? data.TimeEnd : Locale("coming-soon", this.props.Locale);


                            //2021.02.23 - Time Limit.
                            let _timeLimit = moment.utc(moment(data.Date + ' ' + data.TimeEnd, 'MMM D, YYYY HH:mm A').diff(moment(data.Date + ' ' + data.TimeStart, 'MMM D, YYYY HH:mm A'))).format('HH:mm');
                            // let _timeLimit = data.TimeLimit === undefined ? '01:00' : data.TimeLimit;
                            let _timeLimit_Hour = Number(_timeLimit.split(':')[0]);
                            // _timeLimit_Hour = Number(_timeLimit_Hour) > 0 ? (Number(_timeLimit_Hour) > 1 ? _timeLimit_Hour + ' hours' : _timeLimit_Hour + ' hour') : '';
                            _timeLimit_Hour = _timeLimit_Hour > 0 ?
                                (
                                    _timeLimit_Hour > 1 ?
                                        _timeLimit_Hour + ' ' + Locale("time-hour-full-s", this.props.Locale)
                                        : _timeLimit_Hour + ' ' + Locale("time-hour-full", this.props.Locale)
                                )
                                : '';
                            let _timeLimit_Minute = Number(_timeLimit.split(':')[1]);
                            // _timeLimit_Minute = Number(_timeLimit_Minute) > 0 ? (Number(_timeLimit_Minute) > 1 ? _timeLimit_Minute + ' minutes' : _timeLimit_Minute + ' minute') : '';
                            _timeLimit_Minute = _timeLimit_Minute > 0 ?
                                (
                                    _timeLimit_Minute > 1 ?
                                        ' ' + _timeLimit_Minute + ' ' + Locale("time-min-full-s", this.props.Locale)
                                        : ' ' + _timeLimit_Minute + ' ' + Locale("time-min-full", this.props.Locale)
                                )
                                : '';
                            let _timeLimitText = _timeLimit_Hour + _timeLimit_Minute;
                            //ends.


                            if (_sectionDivider_bySubject === '')
                                _sectionDivider_bySubject = data.Subject;

                            if (_sectionDivider_byDate === '')
                                _sectionDivider_byDate = data.Date;

                            //items.
                            _sectionHtmlCode += "<tr>"
                                // + "<td width='45%' align='right'>" + data.Group + "</td>"
                                + "<td width='45%' align='right'>" + data.Group + (data.Group.hasOwnProperty('Remark') ? data.Group.Remark.length > 0 ? ' (' + data.Group.Remark + ')' : '' : '') + "</td>"
                                + "<td width='10%' align='center'>:</td>"
                                + "<td width='45%'><span style='font-weight:500;'><i>" + d2_roomCode + "</i></span></td>"
                                + "</tr>";

                            //debug.
                            // _sectionHtmlCode += "<tr>"
                            //     + "<td width='25%' align='right'>" + data.Group + "</td>"
                            //     + "<td width='10%' align='center'>:</td>"
                            //     + "<td width='25%'><span style='font-weight:500;'><i>" + d2_roomCode + "</i></span></td>"
                            //     + "<td width='40%' align='center'>" + data.Date + " / " + data.Subject + "</td>"
                            //     + "</tr>";

                            let nextItem = key + 1 < _QuizRooms.length ? _QuizRooms[key + 1] : null;

                            if (
                                (nextItem !== null && (
                                    nextItem.Date !== data.Date ||
                                    nextItem.Subject !== data.Subject
                                ))
                                ||
                                (nextItem === null && key + 1 <= _QuizRooms.length)
                            ) {
                                // if (_sectionDivider_byDate === data.Date && _sectionDivider_bySubject === data.Subject) {
                                _groupDetailText += "<br /><table width='100%' cellpadding='5' cellspacing='5' style='border:2px lavender solid;'>";

                                // isQuizOnToday = key > 6;
                                //Date.
                                // // _groupDetailText += "<tr><td colspan='3' align='center'><b><u>" + d2_date + "</u></b></td></tr>";
                                // _groupDetailText += "<tr " + (isQuizOnToday ? "bgColor='red'" : "") + "><td colspan='3' align='center'>"
                                //     + "<span style='color:" + (isQuizOnToday ? "white" : "red") + ";text-decoration:underline;font-weight:bold;'>"
                                //     + d2_date + "</span></td></tr>";

                                //Subject.
                                // _groupDetailText += "<tr><td width='45%' align='right'>" + Locale("subject", this.props.Locale) + "</td>";
                                // _groupDetailText += "<td width='10%' align='center'>:</td>";
                                // _groupDetailText += "<td width='45%'><b>" + data.Subject + "</b></td></tr>";

                                //Time.
                                // _groupDetailText += "<tr><td align='right'>" + Locale("time", this.props.Locale) + "</td>";
                                // _groupDetailText += "<td align='center'>:</td>";
                                // _groupDetailText += "<td><b>" + d2_timeStart + " ~ " + d2_timeEnd + "</b></td></tr>";

                                //2021.02.23 - new pattern - Time Lmit.
                                _groupDetailText += "<tr><td colspan='3' align='center'><b><u>" + data.Subject + "</u></b></td></tr>";
                                _groupDetailText += "<tr><td width='45%' align='right'>" + Locale("time-limit", this.props.Locale) + "</td>";
                                _groupDetailText += "<td width='10%' align='center'>:</td>";
                                _groupDetailText += "<td width='45%'><b>" + _timeLimitText + "</b></td></tr>";

                                //Testing.
                                // _groupDetailText += "<tr><td width='45%' align='center'>" + _timeLimit + "</td>";
                                // _groupDetailText += "<td width='10%' align='center'>" + _timeLimit_Hour + "</td>";
                                // _groupDetailText += "<td width='45%' align='center'>" + _timeLimit_Minute + "</td></tr>";
                                // _groupDetailText += "<tr><td width='45%' align='center'>" + data.Date + ' ' + data.TimeStart + "</td>";
                                // _groupDetailText += "<td width='10%' align='center'>" + '' + "</td>";
                                // _groupDetailText += "<td width='45%' align='center'>" + data.Date + ' ' + data.TimeEnd + "</td></tr>";


                                //Groups.
                                _groupDetailText += "<tr><td colspan='3'>"
                                    + "<table width='100%' cellpadding='5' style='border:1px lightgrey solid;'>"
                                    + "<tr bgColor='lavender'><td colspan='3' align='center'>" + Locale("room-code", this.props.Locale) + "</td></tr>"
                                    + _sectionHtmlCode + "</table></td></tr></table>";

                                //reset.
                                _sectionHtmlCode = "";
                                _sectionDivider_byDate = data.Date;
                                _sectionDivider_bySubject = data.Subject;
                            }

                            return null;
                        });


                        //=== 2021.02.18 - QuizRooms - new arrange pattern. === ends ===//
                    }

                    //2022.10.15 - Telegram Info.
                    let _telegramInfoHtmlTags = '';
                    if (_eventModal.hasOwnProperty('TelegramInfo')) {
                        if (_eventModal.TelegramInfo?.length > 0) {
                            const info_idx = _eventModal.TelegramInfo.findIndex(x => String(x.Lang) === String(this.props.Locale));
                            if (info_idx > -1)
                                _telegramInfoHtmlTags = String(_eventModal.TelegramInfo[info_idx].Content);
                        }
                    }
                    console.log('html tags:\n' + _telegramInfoHtmlTags);

                    // _popupText += "<tr><td colspan='3'><hr /></td></tr>"
                    //     + "<tr><td colspan='3' align='center'><u>Standard 1 Room Code</u></td></tr>"

                    //     + "<tr>"
                    //     + "<td align='right'>English</td>"
                    //     + "<td align='center'>:</td>"
                    //     + "<td>12345</td>"
                    //     + "</tr>"

                    //     + "<tr>"
                    //     + "<td align='right'>Chinese</td>"
                    //     + "<td align='center'>:</td>"
                    //     + "<td>12345</td>"
                    //     + "</tr>"

                    //     + "<tr>"
                    //     + "<td align='right'>Malay</td>"
                    //     + "<td align='center'>:</td>"
                    //     + "<td>12345</td>"
                    //     + "</tr>"
                    //     ;

                    // _groupDetailText += "</table>";

                    //2020.12.15
                    this.setState({
                        //2021.02.17
                        liveQuiz_EventDetail_HasJoined: _eventModal.HasJoinedEvent,
                        // selectedCenterName: _eventModal.Organizer,
                        selectedCenterName: _eventModal.OrganizerDisplayName,   //2021.07.14
                        selectedEventName: _eventModal.Subject,
                        // selectedPartnerName: _eventModal.PartnerDisplayName ?? '',  //2022.05.13

                        //2021.02.23
                        selectedEventModal: _eventModal,

                        //2021.03.04
                        quizGlobalTimeStart: _eventModal.TimeStart,
                        quizGlobalTimeEnd: _eventModal.TimeEnd,

                        // liveQuiz_EventDetail_HasJoined: _hasJoinedEvent,                        
                        liveQuiz_EventDetail_RoomDetail: _roomDetailText,
                        liveQuiz_EventDetail_ZoomMeetingDetail: _zoomMeetingDetailText,
                        liveQuiz_EventDetail_GroupDetail: _groupDetailText,
                        toggle_LiveQuiz_EventDetail_AlertModal: true,
                        liveQuiz_EventDetail_UpdateActionInProgress: false,
                        liveQuiz_EventDetail_ActionInProgress: false,

                        liveQuiz_EventDetail_TelegramInfo: _telegramInfoHtmlTags,   //2022.10.15
                    });
                    // this.Toggle_liveQuiz_EventDetail_AlertModal();
                    // this.props.SetAlert('LIVE QUIZ EVENT DETAILS', _popupText);


                    //2021.02.17
                    let _regData = null;
                    let _targetRepo = '';
                    let _organizer = String(_eventModal.OrganizerIdentity).toLowerCase();   //2021.09.10
                    if (eventCode.includes('abacus') || _organizer.includes('smartnewton')) {
                        //SMARTNEWTON = ABACUS CHALLENGE
                        _targetRepo = 'SMARTNEWTON';
                    }
                    else if (eventCode.includes('colin') || _organizer.includes('colin')) {
                        //COLIN = SPOT 2020/2021.
                        _targetRepo = 'COLIN';
                    }
                    else if (eventCode.includes('spot') || _organizer.includes('simedu')) {
                        //SIM = SPOT 2020/2021.
                        _targetRepo = 'SIM';
                    }
                    else if (eventCode.includes('ptsmc') || _organizer.includes('jiaxue')) {
                        //Pusat Tuisyen Sri Mulia Cahaya = Primary Test 2021
                        _targetRepo = 'PTSMC';
                    }
                    if (_targetRepo.length > 0) {
                        await this.props.firestore
                            .collection('LiveQuiz_RegData')
                            .doc(_targetRepo)
                            .collection('List')
                            .where('Uid', '==', this.props.user.uid)
                            .get()
                            .then((querySnapshot) => {
                                let data = [];
                                if (querySnapshot !== null) {
                                    querySnapshot.forEach((doc) => {
                                        data.push(doc.data());
                                    });
                                }
                                if (data.length > 0) {
                                    _regData = data[0];
                                    // alert(JSON.stringify(_regData));
                                }
                            });
                        // alert(_targetRepo + "\n\n" + JSON.stringify(_regData) + "\n\n" + _regData['']);
                        // alert(_regData['Group']);

                        if (_regData !== null) {

                            //2021.03.23
                            let _groupOrGrade = _regData.hasOwnProperty("Group") ?
                                _regData["Group"]
                                : _regData.hasOwnProperty("Grade") ?
                                    _regData["Grade"]
                                    : '';
                            let _isCenterStudent = _regData.hasOwnProperty("IsCenterStudent") ?
                                _regData["IsCenterStudent"]
                                : '';

                            //2021.07.14
                            if (this.props.OrganizerIdentity !== '')
                                _isCenterStudent = true;

                            //2022.05.17 - set Group to higher age group if reg-group not found in groupOptions.
                            let _groupId = 0;
                            let _groupLabel = '';
                            const quizGroups = this.getEventGroupOptions();     //{ value, label, id, }
                            if (quizGroups.length > 0) {
                                let groupIndex = quizGroups.findIndex(x => String(x.value).toLowerCase() === _groupOrGrade.toLowerCase()
                                    || String(x.label).toLowerCase().includes(_groupOrGrade.toLowerCase()));
                                let group = null;
                                if (groupIndex < 0)
                                    group = quizGroups[quizGroups.length - 1];  //use the last item in group (highest age)
                                else
                                    group = quizGroups[groupIndex];
                                if (group !== null) {
                                    _groupOrGrade = String(group.value);
                                    _groupLabel = String(group.label);
                                    _groupId = Number(group.id);
                                }
                            }


                            if (this.ref_IsCenterStudent.current !== undefined && this.ref_IsCenterStudent.current !== null)
                                this.ref_IsCenterStudent.current.value = String(_isCenterStudent);
                            if (this.ref_EventGroupOption.current !== undefined && this.ref_EventGroupOption.current !== null)
                                this.ref_EventGroupOption.current.select.value = String(_groupOrGrade);

                            this.setState({
                                //2022.05.17
                                selectedEventGroupOptionLabel: _groupLabel,
                                selectedEventGroupOptionId: _groupId,

                                selectedEventGroupOption: String(_groupOrGrade),
                                selectedEventIsCenterStudent: String(_isCenterStudent),
                            });
                        }
                        // else {
                        //     //2021.07.14
                        //     if (this.props.OrganizerIdentity !== '') {
                        //         this.ref_IsCenterStudent.current.select.value = 'Yes';
                        //         this.setState({ selectedEventIsCenterStudent: 'Yes' });
                        //     }
                        // }
                    }
                }
                else {
                    this.props.SetAlert('LIVE QUIZ EVENT DETAILS', Locale("coming-soon", this.props.Locale));
                }
            }
        }
    }

    //2020.12.15
    CheckIfEventIsJoined = async (_eventModal) => {
        let _dataModal = null;
        let isJoined = false;
        await this.props.firestore
            .collection('LiveQuizEventJoinedList')
            .doc(_eventModal.EventCode)
            .collection('List')
            .where('Uid', '==', this.props.user.uid)
            .get()
            .then((querySnapshot) => {
                let data = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        data.push(doc.data());
                    });
                }
                if (data.length > 0) {
                    _dataModal = data[0];
                }
            });
        // if (_dataModal !== null) {
        //     // if (moment(_dataModal.Date) > moment()) {
        //     //     isJoined = true;
        //     // }
        //     // alert("Date : " + _dataModal.Date + "\nIs User : " + (_dataModal.Uid === this.props.user.uid));
        //     // alert(_eventModal.EventCode + "\n\n" + JSON.stringify(_dataModal));
        //     isJoined = true;
        // }
        // // else {
        // //     alert("Data not found.");
        // // }
        isJoined = _dataModal !== null;
        return isJoined;
    }

    //2020.12.15
    Toggle_LiveQuiz_EventDetail_AlertModal = () => {
        this.setState({
            toggle_LiveQuiz_EventDetail_AlertModal: !this.state.toggle_LiveQuiz_EventDetail_AlertModal,
        });
    }

    //2024.11.06 - new join event api - replace Update_Join_LiveQuiz_Event & TriggerToSyncQuizEventParticipation.
    ProcessJoinEventViaAPI = async () => {
        let eventList = this.state.UpcomingQuizList;
        const eventIndex = eventList.findIndex(x => String(x.EventCode) === this.state.selectedEvent_Code);
        if (eventIndex > -1) {
            let success = false;
            // let done = false;
            let retry = false;
            let counter = 0;
            let errorMessage = '';
            do {
                let _retry = false;
                let _success = false;
                let _done = false;
                let _errorMessage = '';
                await fetch(GlobalSetting.ApiUrl
                    + 'Api/LearningCentre/Event/Student/Join/'
                    + CheckObjectNumber(eventList[eventIndex], 'OrganizerId') + '/'
                    + this.state.selectedEvent_Code + '/'
                    + String(this.props.user.uid),
                    // Api/LearningCentre/Event/Student/Join/{organizerId}/{eventCode}/{uid}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (this.props.isDevMode)
                            console.log('res json =\n' + JSON.stringify(data));
                        _success = data.success;
                        if (!_success)
                            if (this.props.isDevMode)
                                console.log('Error', 'api - join event (failed)\n' + data.message);
                        _done = true;
                    })
                    .catch(error => {
                        _retry = true;
                        _errorMessage = error.message;
                        if (this.props.isDevMode)
                            console.log('Error', 'api - join event (failed)\n' + error.message);
                        _done = true;
                    });
                await DelayUntil(() => _done === true);
                await Delay(1000);
                counter++;
                success = _success;
                retry = _retry;
                errorMessage = _errorMessage;
            } while (counter < 5 && retry);
            if (success) {
                //update state.
                eventList[eventIndex].HasJoinedEvent = true;
                this.setState({
                    UpcomingQuizList: eventList,
                    liveQuiz_EventDetail_HasJoined: true,
                });
                await Delay(500);
                this.ShowEventDetails(this.state.selectedEvent_Code);
            }
            else {
                this.props.SetAlert('Failed to join Event', errorMessage);
            }
        }
    }

    //2024.11.06 - replaced by new join event api - ProcessJoinEventViaAPI.
    //2020.12.15
    Update_Join_LiveQuiz_Event = async () => {
        // alert(this.state.selectedEvent_Code);

        //2022.05.14/17
        let groupOption = this.state.selectedEventGroupOption;
        let groupOptionId = this.state.selectedEventGroupOptionId;
        let groupOptionLabel = this.state.selectedEventGroupOptionLabel;
        const quizGroups = this.getEventGroupOptions();     //{ value, label, id, }
        let groupFound = null;
        if (groupOptionId === 0) {
            if (groupOption.length > 0) {
                // for (let tt = 0; tt < groups.length; tt++) {
                //     if (groupOption.toLowerCase() === String(groups[tt].value).toLowerCase()
                //         || String(groups[tt].label).toLowerCase().includes(groupOption.toLowerCase())) {
                //         groupOptionId = Number(groups[tt].id);
                //         groupOptionLabel = String(groups[tt].label);
                //         break;
                //     }
                // }
                const index = quizGroups.findIndex(x => String(x.value).toLowerCase() === groupOption.toLowerCase()
                    || String(x.label).toLowerCase().includes(groupOption.toLowerCase()));
                if (index > -1) {
                    groupFound = quizGroups[index];
                    groupOption = String(groupFound.value);
                    groupOptionId = Number(groupFound.id);
                    groupOptionLabel = String(groupFound.label);
                }
            }
        }
        else {
            let index = quizGroups.findIndex(x => Number(x.id) === groupOptionId);
            if (index > -1) {
                groupFound = quizGroups[index];
                groupOption = String(groupFound.value);
                groupOptionId = Number(groupFound.id);
                groupOptionLabel = String(groupFound.label);
            }
        }
        //set last group (highest age) as default if nothing match.
        if (groupFound === null) {
            groupFound = quizGroups[quizGroups.length - 1];
            groupOption = String(groupFound.value);
            groupOptionId = Number(groupFound.id);
            groupOptionLabel = String(groupFound.label);
        }

        //Update Join state.
        //2021.09.13 - add retry.
        let retryAgain = false;
        let joinModel = {
            Uid: String(this.props.user.uid),
            Date: moment().format('YYYY-MM-DD HH:mm:ss'),
            // Group: this.state.selectedEventGroupOption,
            // GroupId: this.state.selectedEventGroupOptionId,     //2021.07.02

            //2022.05.14
            Group: groupOptionLabel,
            GroupId: groupOptionId,

            IsCenterStudent: this.state.selectedEventIsCenterStudent,
        };
        // let async_action = async () => await this.props.firestore
        //     .collection('LiveQuizEventJoinedList')
        //     .doc(this.state.selectedEvent_Code)
        //     .collection('List')
        //     .doc(joinModel.Uid)
        //     .set(joinModel)
        //     .then(async () => {
        //         // //2021.02.17
        //         // this.state.UpcomingQuizList.map((data, key) => {
        //         //     if (data.EventCode === this.state.selectedEvent_Code) {
        //         //         this.state.UpcomingQuizList[key].HasJoinedEvent = true;
        //         //         this.setState({
        //         //             liveQuiz_EventDetail_HasJoined: true,
        //         //         });
        //         //     }
        //         //     return null;
        //         // });

        //         //2022.05.12
        //         let _UpcomingQuizList = this.state.UpcomingQuizList;
        //         for (let i = 0; i < _UpcomingQuizList.length; i++) {
        //             if (_UpcomingQuizList[i].EventCode === this.state.selectedEvent_Code) {
        //                 _UpcomingQuizList[i].HasJoinedEvent = true;
        //                 this.setState({
        //                     UpcomingQuizList: _UpcomingQuizList,
        //                     liveQuiz_EventDetail_HasJoined: true,
        //                 });
        //                 break;
        //             }
        //         }

        //         await this.TriggerToSyncQuizEventParticipation(joinModel);    //2022.01.26

        //         await Delay(1500);
        //         this.ShowEventDetails(this.state.selectedEvent_Code);
        //     })
        //     .catch(() => {
        //         retryAgain = true;
        //     });

        let done = false;
        // let allDone = false;
        //revamped 2022.11.14
        let async_action = async () => {
            await this.props.firestore
                .collection('LiveQuizEventJoinedList')
                .doc(this.state.selectedEvent_Code)
                .collection('List')
                .doc(joinModel.Uid)
                .set(joinModel)
                .then(() => {
                    done = true;
                })
                .catch(() => {
                    retryAgain = true;
                    done = true;
                });
            await DelayUntil(() => done === true);
            if (done && !retryAgain) {
                let _UpcomingQuizList = this.state.UpcomingQuizList;
                const eventIndex = _UpcomingQuizList.findIndex(x => String(x.EventCode) === this.state.selectedEvent_Code);
                if (eventIndex > -1) {
                    _UpcomingQuizList[eventIndex].HasJoinedEvent = true;
                    this.setState({
                        UpcomingQuizList: _UpcomingQuizList,
                        liveQuiz_EventDetail_HasJoined: true,
                    });
                }
                await this.TriggerToSyncQuizEventParticipation(joinModel);    //2022.01.26
                await Delay(1500);
                this.ShowEventDetails(this.state.selectedEvent_Code);
            }
            // allDone = true;
        }
        do {
            retryAgain = false;
            done = false;
            // allDone = false;
            await async_action();
            // await DelayUntil(() => allDone === true);
        } while (retryAgain);
    }

    //2024.11.06 - replaced by new join event api - ProcessJoinEventViaAPI.
    //2022.01.26
    TriggerToSyncQuizEventParticipation = async (joinModel) => {
        //trigger sync to db.
        let retryAgain = false;
        let done = false;
        let async_action = async () => {
            await fetch(GlobalSetting.ApiUrl
                + 'Api/LearningCentre/Event/Participation/Sync/'
                + String(this.state.selectedEventModal['EventCode']) + '/'
                + String(joinModel['Uid']) + '/'
                + String(joinModel['Date']) + '/'
                + String(joinModel['GroupId']) + '/'
                + (String(joinModel['IsCenterStudent']).toLowerCase() === 'yes' ? 'true' : 'false'),
                // Api/LearningCentre/Event/Participation/Sync/{eventCode}/{firebaseUserId}/{participationOnUtc}/{groupId}/{isOrganizerStudent}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    // success = data.success;
                    done = true;
                    if (this.props.isDevMode)
                        if (data.success)
                            console.log('api - participation - sync (success)\n' + JSON.stringify(data));
                        else
                            console.log('Error', 'api - participation - sync (failed)\n' + JSON.stringify(data));
                })
                .catch(error => {
                    retryAgain = true;
                    done = true;
                    if (this.props.isDevMode)
                        console.log('Error', 'api - participation - sync (failed)\n' + error.message);
                });
            await DelayUntil(() => done === true);
        }
        do {
            retryAgain = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
        } while (retryAgain);
    }

    //2020.11.26
    SettingListToArray = () => {
        let _schoolListArray = [];
        SchoolList.map((data, key) => {
            return _schoolListArray.push({ value: data, label: data });
        });
        _schoolListArray = [...new Set(_schoolListArray)];  //remove duplicates.    //2020.12.05

        let _nationalStateListArray = [];
        NationalState.map((data, key) => {
            return _nationalStateListArray.push({ value: data, label: data });
        });
        _nationalStateListArray = [...new Set(_nationalStateListArray)];  //remove duplicates.  //2020.12.05

        this.setState({
            schoolListArray: _schoolListArray,
            nationalStateListArray: _nationalStateListArray,
        });
        // navigator.clipboard.writeText(JSON.stringify(schoolListArray));
        // alert("SchoolList converted to JSON & copied to clipboard");
    }

    //2021.02.10
    getEventGroupOptions = () => {
        let _eventModal = null;
        this.props.Events.map((data, key) => {
            if (data.EventCode === this.state.selectedEvent_Code)
                _eventModal = data;
            return null;
        });
        // let _options = new Array({ value: '', label: 'select group' });
        let _options = [];
        if (_eventModal !== null) {
            // _eventModal.Groups.map((data, key) => {
            //     return _options.push({ value: data.Name, label: data.Name, id: data.Id });
            // });

            //2021.08.27
            _eventModal.QuizRooms.map((data, key) => {
                // return _options.push({ value: data, label: data });
                let _label = data.Group + (data.hasOwnProperty('Remark') ? data.Remark.length > 0 ? ' ' + data.Remark : '' : '');
                return _options.push({ value: data.Group, label: _label, id: data.GroupId, });
            });
        }
        return _options;
        // this.setState({
        //     selectedGroupEventOptions: _options,
        // });
    }

    //#endregion ==== Quiz Events ====

    //2021.02.10
    // setGroupOption = (_option) => {
    //     this.setState({ selectedEventGroupOption: _option });
    // }

    //2021.02.17
    // setIsCenterStudentOption = (_option) => {
    //     this.setState({ selectedEventIsCenterStudent: _option });
    // }

    // componentWillUnmount() {
    //     this.setState({
    //         _isMounted: false,
    //     });
    // }


    // SetHistoryListSyncState = (isDone) => {
    //     this.setState({
    //         isHistoryListSyncDone: isDone,
    //     });
    // }

    //#region ==== Alerts ====

    SetAlert = (title, content) => {
        this.props.SetAlert(title, content);
        this.setState({
            isProcessing: false,
        });
    }

    SetAlertWithProgressBar = (title, content, showBar) => {
        // this.props.SetAlertWithProgressBar(title, content, showBar);
        this.props.SetLoading(title, content, showBar);
        this.setState({
            submitInProgress: false,
        });
    }

    //2020.11.27
    TogglePolicyTncAlertModal = (forceClose = false) => {
        this.setState({
            showPolicyTncAlertModal: forceClose || !this.state.policyTnc_enableChecking ? false : !this.state.showPolicyTncAlertModal,
        });
    }

    //2020.12.10
    CheckBeforeClose_TogglePolicyTncAlertModal = async () => {
        //2024.05.23 - no more policyTnc checking needed by Calvin request.
        const isValid = this.state.policyTnc_enableChecking === false ? true : await this.CheckPolicyTncAgreeState();
        // let isValid = await this.CheckPolicyTncAgreeState();
        if (isValid)
            this.TogglePolicyTncAlertModal(true);   //close ui.
    }

    //2020.11.27
    //popup Custom Alert Modal for agree on new Policy & Tnc
    CheckPolicyTncAgreeState = async () => {
        let _state = CheckBoolean(this.props.isPolicyTncStateTheLatest);
        let _userProfile = CheckNullValue(this.props.profile);

        //2024.05.15
        if (_state === false) {
            const policyTncAgree = CheckObjectBoolean(_userProfile, 'PolicyTncAgree');
            if (policyTncAgree)
                _state = true;
        }

        if (!_state && _userProfile !== null) {

            // this.props.SetAlertWithProgressBar(Locale("processing", this.props.Locale), '', true);
            this.props.SetLoading('', Locale("processing", this.props.Locale), false);

            // #region === obsolete part for PolicyTncAgree, now direct fetch frm profile & save to db, no more FS === 2024.05.15 ===

            // _state = this.getProfile(Profile.PolicyTncAgree);
            // let _state = false;

            // let temp1 = '';
            // let temp2 = '';

            //fetch Policy Tnc Version settings.
            let _version = 0;
            let _published = '';
            let _fetchSuccess = false;
            if (this.state.policyTncVersion <= 0) {
                await this.props.firestore
                    .collection('LiveQuizPolicyTncAgreement')
                    .where('Search', '==', 'Setting')
                    .get()
                    .then((querySnapshot) => {
                        let data = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                data.push(doc.data());
                            });
                        }
                        if (data.length > 0) {
                            _version = data[0]['LatestVersion'];
                            _published = data[0]['LatestPublished'];
                            _fetchSuccess = true;
                        }
                        // temp1 = JSON.stringify(querySnapshot);
                    })
                    .catch(async (error) => {
                        await this.props.SetErrorLog(new Date(), "QuizHome | CheckPolicyTncAgreeState | Setting | Error = " + error);
                    });
            }
            else {
                _fetchSuccess = true;
                _version = this.state.policyTncVersion;
                _published = this.state.policyTncVersion_PublishedDate;
            }

            //fetch user's Policy Tnc agreement history record.
            let _user_version = 0;
            let _user_lastUpdate = '2000-01-01 00:00:00';
            let _user_fetchSuccess = false;
            if (this.state.policyTncVersion_User <= 0) {
                await this.props.firestore
                    .collection('LiveQuizPolicyTncAgreement')
                    .where('Uid', '==', this.props.user.uid)
                    .get()
                    .then((querySnapshot) => {
                        let data = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                data.push(doc.data());
                            });
                        }
                        if (data.length > 0) {
                            _user_version = data[0]['Version'];
                            _user_lastUpdate = data[0]['LastUpdate'];
                            _user_fetchSuccess = true;
                        }
                        // temp2 = JSON.stringify(querySnapshot);
                    })
                    .catch(async (error) => {
                        await this.props.SetErrorLog(new Date(), "QuizHome | CheckPolicyTncAgreeState | User | Error = " + error);
                    });
            }
            else {
                _user_fetchSuccess = true;
                _user_version = this.state.policyTncVersion_User;
                _user_lastUpdate = this.state.policyTncVersion_User_AgreeDate;
            }

            let done = false;

            //2021.04.20 - Check if Profile has the TnC info, if not found on FS = Re-Apply TnC info.
            // let _userProfile = this.props.profile;
            if (
                // String(_userProfile.PolicyTncAgree).toLowerCase() === 'true'
                // && String(_userProfile.PolicyTncAgreeDateTime).length > 0
                // && String(_userProfile.PolicyTncAgreeDateTime).toLowerCase().includes('invalid') === false
                // && _user_fetchSuccess === false && _fetchSuccess === true

                //2023.10.19
                CheckNullValue(_userProfile) !== null
                && CheckObjectBoolean(_userProfile, 'PolicyTncAgree')
                && CheckObjectNullValue(_userProfile, 'PolicyTncAgreeDateTime') !== null
                && CheckObjectStringEmpty(_userProfile, 'PolicyTncAgreeDateTime').includes('invalid') === false
                && _user_fetchSuccess === false && _fetchSuccess === true
            ) {

                // let currentDateLocal = moment().format("YYYY-MM-DD HH:mm:ss");

                //2021.09.13 - add retry.
                let retryAgain = false;

                //Re-Apply to user's Policy Tnc agreement.
                let async_action_1 = async () => {
                    await this.props.firestore.collection('LiveQuizPolicyTncAgreement')
                        .doc(this.props.user.uid)
                        .set({
                            // LastUpdate: currentDateLocal,   //currentDateUTC,
                            LastUpdate: _userProfile.PolicyTncAgreeDateTime,
                            Uid: this.props.user.uid,
                            // Version: this.state.policyTncVersion,
                            Version: _version,
                        })
                        .then(() => {
                            done = true;
                        })
                        .catch((error) => {
                            // await this.props.SetErrorLog(new Date(), "QuizHome | CheckPolicyTncAgreeState | Re-Apply Policy | User | Error = " + error);
                            retryAgain = true;
                            done = true;
                        });
                    await DelayUntil(() => done === true);
                }
                do {
                    retryAgain = false;
                    done = false;
                    await async_action_1();
                    // await DelayUntil(() => done === true);
                } while (retryAgain);

                //Re-Apply to user's Policy Tnc agreement List.
                let async_action_2 = async () => {
                    await this.props.firestore.collection('LiveQuizPolicyTncAgreement')
                        .doc(this.props.user.uid)
                        .collection('List')
                        .doc(moment(_userProfile.PolicyTncAgreeDateTime).format('YYYYMMDDHHmmss'))
                        .set({
                            // DateTime: currentDateLocal,     //currentDateUTC,
                            DateTime: _userProfile.PolicyTncAgreeDateTime,
                            // Version: this.state.policyTncVersion,
                            Version: _version,
                        })
                        .then(() => {
                            done = true;
                        })
                        .catch((error) => {
                            // await this.props.SetErrorLog(new Date(), "QuizHome | CheckPolicyTncAgreeState | Re-Apply Policy | User (List) | Error = " + error);
                            retryAgain = true;
                            done = true;
                        });
                    await DelayUntil(() => done === true);
                }
                do {
                    retryAgain = false;
                    done = false;
                    await async_action_2();
                    // await DelayUntil(() => done === true);
                } while (retryAgain);

                // if (this.props.isDevMode)
                //     console.log(_version + ' | ' + _user_version);

                //set states.
                _user_version = _version;
                _state = true;
                _fetchSuccess = true;
                _user_fetchSuccess = true;
            }

            //Check for Version comparisons.
            if (_fetchSuccess && _user_fetchSuccess) {
                // let _userProfile = this.props.profile;
                if (_version > _user_version) {
                    _state = false;
                    // this.handleSetProfile(Profile.PolicyTncAgree, { target: 'off' });
                    // this.handleSetProfile(Profile.PolicyTncAgreeDateTime, '');
                    _userProfile.PolicyTncAgree = 'false';
                    _userProfile.PolicyTncAgreeDateTime = '';
                    await this.props.SetProfile(_userProfile);
                }
                else {
                    if (
                        _version === _user_version
                        && moment(_published) <= moment(_user_lastUpdate)
                    ) {
                        _state = true;
                        // this.handleSetProfile(Profile.PolicyTncAgree, { target: 'on' });
                        // this.handleSetProfile(Profile.PolicyTncAgreeDateTime, _user_lastUpdate);

                        if (this.props.isDevMode)
                            console.log('TnC LU: ' + _published + '\nUser agreed LU: ' + _user_lastUpdate);

                        _userProfile.PolicyTncAgree = 'true';
                        _userProfile.PolicyTncAgreeDateTime = _user_lastUpdate;
                        await this.props.SetProfile(_userProfile);
                    }
                }
            }
            else {
                _state = false;
                // this.handleSetProfile(Profile.PolicyTncAgree, { target: 'off' });
                // this.handleSetProfile(Profile.PolicyTncAgreeDateTime, '');
                // let _userProfile = this.props.profile;
                _userProfile.PolicyTncAgree = 'false';
                _userProfile.PolicyTncAgreeDateTime = '';
                await this.props.SetProfile(_userProfile);
            }

            //save state.
            this.setState({
                policyTncVersion: _version,
                policyTncVersion_PublishedDate: _published,
                policyTncVersion_User: _user_version,
                policyTncVersion_User_AgreeDate: _user_lastUpdate,
            }, () => {
                // alert(
                //     "policyTncVersion = " + this.state.policyTncVersion + "\n" +
                //     "policyTncVersion_PublishedDate = " + this.state.policyTncVersion_PublishedDate + "\n" +
                //     "policyTncVersion_User = " + this.state.policyTncVersion_User + "\n" +
                //     "policyTncVersion_User_AgreeDate = " + this.state.policyTncVersion_User_AgreeDate
                // );
            });

            // alert(
            //     '_state = ' + _state + "\n\n" +

            //     '_version = ' + _version + "\n" +
            //     '_published = ' + _published + "\n" +
            //     '_fetchSuccess = ' + _fetchSuccess + "\n\n" +
            //     // 'temp1 = ' + temp1 + "\n\n" +

            //     '_user_version = ' + _user_version + "\n" +
            //     '_user_lastUpdate = ' + _user_lastUpdate + "\n" +
            //     '_user_fetchSuccess = ' + _user_fetchSuccess + "\n"
            //     // 'temp2 = ' + temp2
            // );

            //shortcut
            // _state = true;
            // _state = false;

            // this.setState({
            //     isPolicyTncStateTheLatest: _state,
            // });
            this.props.SetIsPolicyTncStateTheLatest(_state);
            this.props.Set_PolicyTncVersion_PublishedDate(_published);

            //Toggle Ui.
            this.props.CloseAlert();
            if (_state === false) {
                this.TogglePolicyTncAlertModal();   //open ui.

                if (this.state.showQuizModal) {     //close Enter Room modal if opened.
                    this.ToggleQuizModal();
                }

                // setTimeout(() => {

                // }, 1000);
            }
        }
        // this.SetAlert('', 'Done');
        return _state;
    }

    //2020.12.03
    Toggle_ProfileInfoRequireUpdate_AlertModal = () => {
        this.setState({
            show_ProfileInfoRequireUpdate_AlertModal: !this.state.show_ProfileInfoRequireUpdate_AlertModal,
        });
    }

    //2020.12.10
    CheckBeforeClose_Toggle_ProfileInfoRequireUpdate_AlertModal = async () => {
        let isDone = await this.ProfileEditedDataValidation();
        if (isDone)
            this.Toggle_ProfileInfoRequireUpdate_AlertModal();  //close ui.
        else
            this.props.SetAlert(Locale("invalid-ops", this.props.Locale),
                Locale("invalid-ops-text", this.props.Locale));
    }

    //#endregion ==== Alerts ====

    // //=== Database path === starts.
    // getRoomIdPath = () => {
    //     return 'pkquiz/' + this.state.todayDT + '/pkquiz-room-code/' + this.state.quizRoomCode;
    // }
    // //=== Database path === end.

    //#region ==== Quiz Room ====

    ToggleQuizModal = () => {
        this.setState({ showQuizModal: !this.state.showQuizModal });
    }

    RoomCodeValidation = () => {
        this.setState({
            isProcessing: true,
        }, async () => {
            const regexp = /^[0-9]*$/;  //all numbers.
            let isRoomCodeValid = regexp.test(this.state.quizRoomCode);

            if (this.state.quizRoomCode.length <= 0) {
                this.SetAlert(Locale("invalid-info", this.props.Locale), Locale("room-code-required", this.props.Locale));
            }
            else if (!isRoomCodeValid) {
                this.SetAlert(Locale("invalid-info", this.props.Locale), Locale("invalid-room-code-entered", this.props.Locale));
            }
            else {
                //2022.11.05
                if (this.state.selectedEvent_Code !== '') {
                    let _lastEndDate = '';
                    // let _lastEndTime = '';
                    // let _eventModal = null;
                    // this.state.UpcomingQuizList.map((data, key) => {
                    //     if (String(data.EventCode) === String(this.state.selectedEvent_Code))
                    //         _eventModal = data;
                    //     return null;
                    // });
                    const eventIndex = this.state.UpcomingQuizList.findIndex(x => String(x.EventCode) === String(this.state.selectedEvent_Code));
                    let _eventModal = eventIndex < 0 ? null : this.state.UpcomingQuizList[eventIndex];
                    if (_eventModal !== null) {
                        // console.log('(check) = event found.');
                        let _room = null;
                        _eventModal.QuizRooms.map((rooms, rooms_Key) => {
                            if (rooms.Rooms !== undefined) {
                                rooms.Rooms.map((room, room_Key) => {
                                    if (room.RoomCode !== undefined) {
                                        if (String(room.RoomCode) === String(this.state.quizRoomCode))
                                            _room = room;
                                    }
                                    if (room.DateEnd !== undefined) {
                                        if (_lastEndDate !== '') {
                                            if (moment(String(room.DateEnd)) > moment(_lastEndDate))
                                                _lastEndDate = String(room.DateEnd);
                                        }
                                        else {
                                            _lastEndDate = String(room.DateEnd);
                                        }
                                    }
                                    // if (room.TimeEnd !== undefined) {
                                    //     if (_lastEndTime !== '') {
                                    //         if (moment(_lastEndDate + ' ' + String(room.TimeEnd)) > moment(_lastEndDate + ' ' + _lastEndTime))
                                    //             _lastEndTime = String(room.TimeEnd);
                                    //     }
                                    //     else {
                                    //         _lastEndTime = String(room.TimeEnd);
                                    //     }
                                    // }
                                    return null;
                                });
                            }
                            return null;
                        });
                        if (_room !== null) {
                            console.log('(check) = room found.');
                            let _startDate = _room.DateStart !== undefined ? String(_room.DateStart) : '';
                            let _startTime = _room.TimeStart !== undefined ? String(_room.TimeStart) : '';
                            // let _endDate = _room.DateEnd !== undefined ? String(_room.DateEnd) : '';
                            // let _endTime = _room.TimeEnd !== undefined ? String(_room.TimeEnd) : '';
                            const _startDT = moment(_startDate + ' ' + _startTime);
                            // const _endDT = moment(_endDate + ' ' + _endTime);
                            const _endDT = moment(_lastEndDate + ' 23:59:59');
                            if (moment() > _startDT) {
                                // console.log('(check) = room started.');
                                if (moment() > _endDT) {
                                    // console.log('(check) = room adi ended. ' + _endDT.format('YYYY-MM-DD HH:mm:ss'));
                                    this.props.SetAlert(
                                        'Event &#60;' + String(_eventModal.EventName) + '&#62; ' +
                                        '<br />Quiz Room <' + this.state.quizRoomCode + '>',
                                        "Quiz room of current event has been ended.<br />Or check if this device's Date & Time is correctly configured."
                                        + "<br /><br />Current Event's held date : " + moment(String(_eventModal.EventDate)).format('MMM DD, YYYY')
                                        + "<br /><br />Current device's Date : "
                                        + moment().format('MMM DD, YYYY') + "<br />Current device's Time : " + moment().format('HH:mm A')
                                    );
                                    this.setState({ isProcessing: false, });
                                    return null;    //stop entering room.
                                }
                            }
                        }
                    }
                }

                // await this.FetchRoomIdFromFirebaseAndEnterRoom();
                await this.CheckRoomCodeAndAccess_ViaAPI();
            }
        });
    }

    //2024.01.30+
    //to be replace with "FetchRoomIdFromFirebaseAndEnterRoom".
    CheckRoomCodeAndAccess_ViaAPI = async () => {

        this.SetAlertWithProgressBar(Locale("loading-room-info", this.props.Locale),
            Locale("please-wait", this.props.Locale), true);

        this.setState({
            isProcessing: true,
        });

        //new Api for Room Access - 2024.04.29
        const url = GlobalSetting.ApiUrl
            + 'Api/LearningCentre/Quiz/Room/Get/Access/'
            + this.state.quizRoomCode + '/0/' + CheckObjectNumber(this.state.profileFetched, 'Id');
        // Api/LearningCentre/Quiz/Room/Get/Access/{roomCode}/{roomId}/{userId}

        // const { organizerList } = this.Get_ProfileEditPermissionAllowed_And_OrganizerList();
        // const organizerId = organizerList.length > 0 ? CheckObjectNumber(organizerList.at(0), 'OrganizerId') : 0;
        // const url = GlobalSetting.ApiUrl
        //     + 'Api/LearningCentre/Quiz/Room/Get/'
        //     + organizerId + '/0/'
        //     + this.state.quizRoomCode + '/0';
        // // Api/LearningCentre/Quiz/Room/Get/{organizerId}/{authorId}/{roomCode}/{roomId}

        if (this.props.isDevMode)
            console.log('CheckRoomCodeAndAccess_ViaAPI (url) \n' + url);

        let errorMessage = '';
        let roomData = null;
        let done = false;
        await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('api-room-query (response) =\n', JSON.stringify(data));

                // let proceedSuccess = false;
                // let proceedMessage = '';
                if (data.success) {
                    roomData = data.data;
                    // //check can enter or not.
                    // const { success, message } = this.ProceedCheckRoomCodeAndAccess(data.data);
                    // proceedSuccess = success;
                    // proceedMessage = message;
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                }
                // if (CheckObjectBoolean(data, 'success') === false || proceedSuccess === false) {
                //     this.props.SetAlert('Failed', 'Unable to find room.'
                //         + (CheckNullValue(proceedMessage) === null ? '' : '<br /><br />Error:<br />' + proceedMessage));
                //     if (this.props.isDevMode)
                //         console.log('Error', 'api-room-query (failed)\n' + JSON.stringify(data));
                // }
                done = true;
            })
            .catch(error => {
                done = true;
                // this.props.SetAlert('Failed (Error)', 'Unable to find room.'
                //     + (CheckObjectNullValue(error, 'message') === null ? '' : '<br /><br />Error:<br />' + error.message));
                if (this.props.isDevMode)
                    console.log('Error', 'api-room-query (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        if (roomData !== null) {
            roomData = CapitalizeJsonKeys(roomData);
            this.ProceedCheckRoomCodeAndAccess(roomData);
        }
        else {
            this.setState({
                isProcessing: false,
            });
            if (CheckNullValue(errorMessage) !== null)
                this.props.SetAlert('Room ' + this.state.quizRoomCode + ' - Access Denied', errorMessage);
            // this.props.SetAlert('Failed', 'Unable to find room.'
            //     + (CheckNullValue(errorMessage) === null ? '' : '<br /><br />Error:<br />' + errorMessage));
            // if (this.props.isDevMode)
            //     console.log('Error', 'api-room-query (failed)\n' + JSON.stringify(data));
        }
    }
    ProceedCheckRoomCodeAndAccess = (roomData = null) => {
        let _roomInfo = CapitalizeJsonKeys(roomData);
        const _roomId = CheckObjectNumber(_roomInfo, 'RoomId');

        this.setState({
            quizRoomId: _roomId,
            quizRoomInfo: _roomInfo,
        }, () => {

            //revamped 2023.10.27 - simplified some codes.
            //revamped 2023.10.18
            //Check if Room can be enter on today.

            let _quizEnded = CheckObjectBoolean(_roomInfo, 'QuizEnded');    //2023.10.30
            if (_quizEnded)
                _quizEnded = moment.utc() > moment(CheckObjectStringEmpty(_roomInfo, 'DateEnd') + ' ' + CheckObjectStringEmpty(_roomInfo, 'TimeEnd', '23:59:00')).utc();

            let _forceRestrictedAccess = CheckObjectBoolean(_roomInfo, 'ForceRetrictedAccess');
            let _restrictAccessToTimeRangeOnly = CheckObjectBoolean(_roomInfo, 'RestrictAccessToTimeRangeOnly');
            let _roomsHeldOnSelfTime = CheckObjectBoolean(this.state.selectedEventModal, 'RoomsHeldOnSelfTime');
            let _roomDateStart = CheckObjectStringEmpty(this.state.quizRoomInfo, 'DateStart', CheckObjectStringEmpty(_roomInfo, 'Date'));
            let _roomDateEnd = CheckObjectStringEmpty(this.state.quizRoomInfo, 'DateEnd');
            let _roomTimeStart = CheckObjectStringEmpty(this.state.quizRoomInfo, 'TimeStart');
            let _roomTimeEnd = CheckObjectStringEmpty(this.state.quizRoomInfo, 'TimeEnd');

            const _today = moment().format('YYYY-MM-DD');
            let _roomDateTimeStart = moment(_roomDateStart + ' 00:00:00');
            let _roomDateTimeEnd = moment(_roomDateEnd + ' 23:59:59');
            if (this.state.selectedEventModal === null) {
                if (_restrictAccessToTimeRangeOnly === true) {
                    _roomDateTimeStart = moment(_today + ' ' + _roomTimeStart + ':00');
                    _roomDateTimeEnd = moment(_today + ' ' + _roomTimeEnd + ':00');
                }
            }
            else {
                if (_roomsHeldOnSelfTime) {
                    _roomDateTimeStart = moment(_today + ' ' + _roomTimeStart + ':00');
                    _roomDateTimeEnd = moment(_today + ' ' + _roomTimeEnd + ':00');
                }
            }

            let _canEnterRoom = false;
            let _roomAlreadyEnded = false;
            let _notYetStart = false;

            if (_quizEnded === false) {
                if (_forceRestrictedAccess === false) {
                    if (moment() < _roomDateTimeStart) {
                        //not yet start.
                        _notYetStart = true;
                    }
                    else if (moment() > _roomDateTimeEnd) {
                        //already over.
                        _roomAlreadyEnded = true;
                    }
                    else {
                        //started, within DateStart & DateEnd.
                        _canEnterRoom = true;
                    }
                }
            }
            else {
                _roomAlreadyEnded = true;   //2023.10.30
            }

            // //Close Alert.
            // this.props.CloseAlert();

            //not allow to access current quiz room.
            if (_forceRestrictedAccess === true) {
                this.SetAlert(Locale("quiz-room-access-restricted-label", this.props.Locale), Locale("quiz-room-access-restricted-notice", this.props.Locale));
            }

            //already over.
            if (_roomAlreadyEnded === true) {
                this.SetAlert('Room [' + this.state.quizRoomCode + '] ' + Locale("quiz-ended", this.props.Locale), Locale("cannot-enter-room-today-title", this.props.Locale));
            }

            //started, within DateStart & DateEnd.
            if (_canEnterRoom) {
                //Update Room Code & Room Id.
                this.props.setRoomCodeId(this.state.quizRoomCode, this.state.quizRoomId, _roomDateStart);

                //2021.10.12
                //Check if this room is belong to new Room Type = File Upload.
                let isRoomTypeFileUpload = false;
                if (_roomInfo.hasOwnProperty('RoomType')) {
                    if (_roomInfo.RoomType === 1)
                        isRoomTypeFileUpload = true;
                }

                //2021.11.08
                if (_roomInfo.hasOwnProperty('EventCode')) {
                    if (String(_roomInfo.EventCode).length > 0) {
                        this.props.SetEventInfo(_roomInfo.EventCode);
                    }
                }

                setTimeout(() => {
                    this.props.CloseAlert();    //2023.10.20

                    //2021.10.12
                    if (isRoomTypeFileUpload)
                        this.GotoPage('/quiz/roomTypeUploadFile');
                    else
                        this.GotoQuizLive();

                }, 1000);
            }

            //not yet start.
            if (_notYetStart) {

                //Alert: Room will be available to enter in future.
                let _date = moment(this.state.quizRoomInfo.DateStart).format('ll');
                let _remainingDate = moment(this.state.quizRoomInfo.DateStart).add(1, 'day').diff(moment(), 'days');
                let _daysToGo = _remainingDate > 0 ? ' (' + _remainingDate + ' day' + (_remainingDate > 1 ? 's' : '') + ' to go)' : '';

                let _alertContent = "<table width='100%'>";

                _alertContent += '<tr><td>Organizer</td><td>:</td><td>' + this.state.quizRoomInfo.Organizer + '</td></tr>';
                _alertContent += '<tr><td>Room Code</td><td>:</td><td><b>' + this.state.quizRoomInfo.RoomCode + '</b></td></tr>';
                _alertContent += '<tr><td>Room Title</td><td>:</td><td>' + this.state.quizRoomInfo.RoomTitle + '</td></tr>';
                _alertContent += '<tr><td>Subject</td><td>:</td><td>' + this.state.quizRoomInfo.Subject + '</td></tr>';
                _alertContent += '<tr><td>Date</td><td>:</td><td><b>' + _date + '</b>' + _daysToGo + '</td></tr>';
                _alertContent += '<tr><td>Start Time</td><td>:</td><td>' + _roomDateTimeStart.format('HH:mm A') + '</td></tr>';
                _alertContent += '<tr><td>End Time</td><td>:</td><td>' + _roomDateTimeEnd.format('HH:mm A') + '</td></tr>';

                let _duration = this.state.quizRoomInfo.Duration !== undefined ?
                    this.FormatedDuration(this.state.quizRoomInfo.Duration) : '-';

                _alertContent += '<tr><td>Duration (Time Limit)</td><td>:</td><td>'
                    // + '<span class="blink" style="font-weight: bold;color: red;">'
                    + '<span style="font-weight: bold;color: red;">'
                    + _duration
                    + '</span></td></tr>';

                _alertContent += "<tr><td colspan='3'>";
                _alertContent += '<br />' + Locale("cannot-enter-room-today-content", this.props.Locale);
                _alertContent += '</td></tr>';

                _alertContent += "<tr><td colspan='3'>";
                _alertContent += 'Current Time is <u>' + moment().format('LLLL') + '</u>';
                _alertContent += '</td></tr>';

                _alertContent += '</td></tr></table>';

                this.SetAlert(Locale("cannot-enter-room-today-title", this.props.Locale), _alertContent);
            }
        });
    }

    //obsolete, checked on 2024.04.17
    // //revamped 2023.10.18
    // FetchRoomIdFromFirebaseAndEnterRoom = async () => {

    //     this.SetAlertWithProgressBar(Locale("loading-room-info", this.props.Locale),
    //         Locale("please-wait", this.props.Locale), true);

    //     this.setState({
    //         isProcessing: true,
    //     });

    //     let _roomInfo = [];
    //     let _roomId = '';
    //     let isRoomFound = false;    //always is false.

    //     //#region old code
    //     // await this.props.dbLiveQuiz
    //     //     .ref('pkquiz/' + this.state.todayDT + '/pkquiz-room-code/' + this.state.quizRoomCode)
    //     //     .once('value', snapshot => {
    //     //         // handle read data.
    //     //         if (snapshot.exists()) {
    //     //             _roomId = String(snapshot.val());
    //     //             isRoomFound = true;
    //     //         }
    //     //     });

    //     // //2021.01.19
    //     // if (isRoomFound) {
    //     //     this.setState({
    //     //         quizRoomId: _roomId,
    //     //     });
    //     //     await Delay(500);

    //     //     //Update Room Code & Room Id.
    //     //     this.props.setRoomCodeId(this.state.quizRoomCode, this.state.quizRoomId);

    //     //     //2021.10.13
    //     //     let _roomType = 0;
    //     //     await this.props.dbLiveQuiz
    //     //         .ref('pkquiz/' + this.state.todayDT + '/pkquiz-room-detail/' + this.state.quizRoomId + '/RoomType')
    //     //         .once('value', snapshot => {
    //     //             if (snapshot.exists()) {
    //     //                 _roomType = Number(snapshot.val());
    //     //             }
    //     //         });

    //     //     //Close Alert.
    //     //     this.props.CloseAlert();

    //     //     //Enter Room.
    //     //     setTimeout(() => {
    //     //         // this.GotoQuizLive();

    //     //         //2021.10.13
    //     //         if (_roomType === 1)
    //     //             this.GotoPage('/quiz/roomTypeUploadFile');
    //     //         else
    //     //             this.GotoQuizLive();

    //     //     }, 1000);
    //     // }
    //     //#endregion

    //     // console.log('RoomCode = ' + this.state.quizRoomCode + '\nRoomId = ' + this.state.quizRoomId)

    //     //Search Room in new FS locaition.
    //     let counter = 0;
    //     let done = false;
    //     do {
    //         await this.props.firestore.collection("LiveQuiz_UniqueRoomCode")
    //             .where('RoomCode', '==', Number(this.state.quizRoomCode))
    //             // .where('RoomId', '==', Number(this.state.quizRoomId))
    //             .get()
    //             .then(querySnapshot => {
    //                 let data = [];
    //                 if (querySnapshot !== null) {
    //                     querySnapshot.forEach((doc) => {
    //                         data.push(doc.data());
    //                     });
    //                     if (data.length > 0)
    //                         data = data[0];
    //                     else
    //                         data = null;
    //                 }
    //                 // console.log('data =\n', JSON.stringify(data));
    //                 if (data !== null && data.hasOwnProperty('RoomId')) {
    //                     isRoomFound = true;
    //                     _roomId = data.RoomId;
    //                     _roomInfo = data;
    //                 }
    //                 done = true;
    //             })
    //             .catch((error) => {
    //                 console.log('error =\n', error);
    //             });
    //         counter++;
    //         await Delay(1000);
    //     } while (done === false && counter < 5);

    //     if (isRoomFound) {
    //         this.setState({
    //             quizRoomId: _roomId,
    //             quizRoomInfo: _roomInfo,
    //         }, () => {

    //             //revamped 2023.10.27 - simplified some codes.
    //             //revamped 2023.10.18
    //             //Check if Room can be enter on today.

    //             let _quizEnded = CheckObjectBoolean(_roomInfo, 'QuizEnded');    //2023.10.30
    //             if (_quizEnded)
    //                 _quizEnded = moment.utc() > moment(CheckObjectStringEmpty(_roomInfo, 'DateEnd') + ' ' + CheckObjectStringEmpty(_roomInfo, 'TimeEnd', '23:59:00')).utc();

    //             let _forceRestrictedAccess = CheckObjectBoolean(_roomInfo, 'ForceRetrictedAccess');
    //             let _restrictAccessToTimeRangeOnly = CheckObjectBoolean(_roomInfo, 'RestrictAccessToTimeRangeOnly');
    //             let _roomsHeldOnSelfTime = CheckObjectBoolean(this.state.selectedEventModal, 'RoomsHeldOnSelfTime');
    //             let _roomDateStart = CheckObjectStringEmpty(this.state.quizRoomInfo, 'DateStart', CheckObjectStringEmpty(_roomInfo, 'Date'));
    //             let _roomDateEnd = CheckObjectStringEmpty(this.state.quizRoomInfo, 'DateEnd');
    //             let _roomTimeStart = CheckObjectStringEmpty(this.state.quizRoomInfo, 'TimeStart');
    //             let _roomTimeEnd = CheckObjectStringEmpty(this.state.quizRoomInfo, 'TimeEnd');
    //             //#region old codes
    //             // let _roomDateStart = _roomInfo.Date;
    //             // if (this.state.quizRoomInfo.hasOwnProperty('DateStart')) {
    //             //     _roomDateStart = this.state.quizRoomInfo.DateStart;
    //             // }
    //             // let _roomDateEnd = '';
    //             // if (this.state.quizRoomInfo.hasOwnProperty('DateEnd')) {
    //             //     _roomDateEnd = this.state.quizRoomInfo.DateEnd;
    //             // }
    //             // let _roomTimeStart = '';
    //             // if (this.state.quizRoomInfo.hasOwnProperty('TimeStart')) {
    //             //     _roomTimeStart = this.state.quizRoomInfo.TimeStart;
    //             // }
    //             // let _roomTimeEnd = '';
    //             // if (this.state.quizRoomInfo.hasOwnProperty('TimeEnd')) {
    //             //     _roomTimeEnd = this.state.quizRoomInfo.TimeEnd;
    //             // }
    //             // let _restrictAccessToTimeRangeOnly = false;
    //             // if (this.state.quizRoomInfo.hasOwnProperty('RestrictAccessToTimeRangeOnly')) {
    //             //     _restrictAccessToTimeRangeOnly = this.state.quizRoomInfo.RestrictAccessToTimeRangeOnly;
    //             // }
    //             // let _roomsHeldOnSelfTime = false;
    //             // if (this.state.selectedEventModal !== null && this.state.selectedEventModal.hasOwnProperty('RoomsHeldOnSelfTime')) {
    //             //     _roomsHeldOnSelfTime = this.state.selectedEventModal.RoomsHeldOnSelfTime;
    //             // }
    //             //#endregion

    //             const _today = moment().format('YYYY-MM-DD');
    //             let _roomDateTimeStart = moment(_roomDateStart + ' 00:00:00');
    //             let _roomDateTimeEnd = moment(_roomDateEnd + ' 23:59:59');
    //             if (this.state.selectedEventModal === null) {
    //                 if (_restrictAccessToTimeRangeOnly === true) {
    //                     _roomDateTimeStart = moment(_today + ' ' + _roomTimeStart + ':00');
    //                     _roomDateTimeEnd = moment(_today + ' ' + _roomTimeEnd + ':00');
    //                 }
    //             }
    //             else {
    //                 if (_roomsHeldOnSelfTime) {
    //                     _roomDateTimeStart = moment(_today + ' ' + _roomTimeStart + ':00');
    //                     _roomDateTimeEnd = moment(_today + ' ' + _roomTimeEnd + ':00');
    //                 }
    //             }

    //             let _canEnterRoom = false;
    //             let _roomAlreadyEnded = false;
    //             let _notYetStart = false;

    //             if (_quizEnded === false) {
    //                 if (_forceRestrictedAccess === false) {
    //                     if (moment() < _roomDateTimeStart) {
    //                         //not yet start.
    //                         _notYetStart = true;
    //                     }
    //                     else if (moment() > _roomDateTimeEnd) {
    //                         //already over.
    //                         _roomAlreadyEnded = true;
    //                     }
    //                     else {
    //                         //started, within DateStart & DateEnd.
    //                         _canEnterRoom = true;
    //                     }
    //                 }
    //             }
    //             else {
    //                 _roomAlreadyEnded = true;   //2023.10.30
    //             }

    //             // //Close Alert.
    //             // this.props.CloseAlert();

    //             //not allow to access current quiz room.
    //             if (_forceRestrictedAccess === true) {
    //                 this.SetAlert(Locale("quiz-room-access-restricted-label", this.props.Locale), Locale("quiz-room-access-restricted-notice", this.props.Locale));
    //             }

    //             //already over.
    //             if (_roomAlreadyEnded === true) {
    //                 this.SetAlert('Room [' + this.state.quizRoomCode + '] ' + Locale("quiz-ended", this.props.Locale), Locale("cannot-enter-room-today-title", this.props.Locale));
    //             }

    //             //started, within DateStart & DateEnd.
    //             if (_canEnterRoom) {
    //                 //Update Room Code & Room Id.
    //                 this.props.setRoomCodeId(this.state.quizRoomCode, this.state.quizRoomId, _roomDateStart);

    //                 //2021.10.12
    //                 //Check if this room is belong to new Room Type = File Upload.
    //                 let isRoomTypeFileUpload = false;
    //                 if (_roomInfo.hasOwnProperty('RoomType')) {
    //                     if (_roomInfo.RoomType === 1)
    //                         isRoomTypeFileUpload = true;
    //                 }

    //                 //2021.11.08
    //                 if (_roomInfo.hasOwnProperty('EventCode')) {
    //                     if (String(_roomInfo.EventCode).length > 0) {
    //                         this.props.SetEventInfo(_roomInfo.EventCode);
    //                     }
    //                 }

    //                 setTimeout(() => {
    //                     this.props.CloseAlert();    //2023.10.20

    //                     //2021.10.12
    //                     if (isRoomTypeFileUpload)
    //                         this.GotoPage('/quiz/roomTypeUploadFile');
    //                     else
    //                         this.GotoQuizLive();

    //                 }, 1000);
    //             }

    //             //not yet start.
    //             if (_notYetStart) {

    //                 //Alert: Room will be available to enter in future.
    //                 let _date = moment(this.state.quizRoomInfo.DateStart).format('ll');
    //                 let _remainingDate = moment(this.state.quizRoomInfo.DateStart).add(1, 'day').diff(moment(), 'days');
    //                 let _daysToGo = _remainingDate > 0 ? ' (' + _remainingDate + ' day' + (_remainingDate > 1 ? 's' : '') + ' to go)' : '';

    //                 //#region old codes
    //                 // let _startTime = this.state.quizRoomInfo.TimeStart === '_none' ?
    //                 //     'Quiz is available for this day (' + _date + ') until 12 AM.'
    //                 //     : moment(this.state.quizRoomInfo.TimeStart).format('HH:mm A');
    //                 // let _endTime = this.state.quizRoomInfo.TimeEnd === '_none' ?
    //                 //     'Quiz is available for this day (' + _date + ') until 12 AM.'
    //                 //     : moment(this.state.quizRoomInfo.TimeEnd).format('HH:mm A');

    //                 // //2021.03.04
    //                 // let _startTime = this.state.selectedEventModal.hasOwnProperty('TimeStart') ?
    //                 //     this.state.selectedEventModal.TimeStart
    //                 //     :
    //                 //     this.state.quizRoomInfo.TimeStart === '_none' ?
    //                 //         'Quiz is available for this day (' + _date + ') until 12 AM.'
    //                 //         : moment(this.state.quizRoomInfo.TimeStart).format('HH:mm A');
    //                 // let _endTime = this.state.selectedEventModal.hasOwnProperty('TimeEnd') ?
    //                 //     this.state.selectedEventModal.TimeEnd
    //                 //     :
    //                 //     this.state.quizRoomInfo.TimeEnd === '_none' ?
    //                 //         'Quiz is available for this day (' + _date + ') until 12 AM.'
    //                 //         : moment(this.state.quizRoomInfo.TimeEnd).format('HH:mm A');

    //                 // //2021.07.16
    //                 // let _startTime = this.state.selectedEventModal.hasOwnProperty('TimeStart') ?
    //                 //     this.state.selectedEventModal.TimeStart
    //                 //     :
    //                 //     this.state.quizRoomInfo.TimeStart === '_none' ?
    //                 //         'Quiz is available for this day (' + _date + ') until 12 AM.'
    //                 //         : String(this.state.quizRoomInfo.TimeStart).includes(' ') ?
    //                 //             moment(this.state.quizRoomInfo.TimeStart).format('HH:mm A')
    //                 //             : moment(moment().format('YYYY-MM-DD ') + this.state.quizRoomInfo.TimeStart).format('HH:mm A');
    //                 // let _endTime = this.state.selectedEventModal.hasOwnProperty('TimeEnd') ?
    //                 //     this.state.selectedEventModal.TimeEnd
    //                 //     :
    //                 //     this.state.quizRoomInfo.TimeEnd === '_none' ?
    //                 //         'Quiz is available for this day (' + _date + ') until 12 AM.'
    //                 //         : String(this.state.quizRoomInfo.TimeEnd).includes(' ') ?
    //                 //             moment(this.state.quizRoomInfo.TimeEnd).format('HH:mm A')
    //                 //             : moment(moment().format('YYYY-MM-DD ') + this.state.quizRoomInfo.TimeEnd).format('HH:mm A');

    //                 // //2021.04.16
    //                 // if (this.state.selectedEventModal.hasOwnProperty('RoomsHeldOnSelfTime')) {
    //                 //     if (this.state.selectedEventModal.RoomsHeldOnSelfTime) {
    //                 //         // _startTime = moment(this.state.quizRoomInfo.TimeStart).format('HH:mm A');
    //                 //         // _endTime = moment(this.state.quizRoomInfo.TimeEnd).format('HH:mm A');

    //                 //         //2021.07.16
    //                 //         _startTime = String(this.state.quizRoomInfo.TimeStart).includes(' ') ?
    //                 //             moment(this.state.quizRoomInfo.TimeStart).format('HH:mm A')
    //                 //             : moment(moment().format('YYYY-MM-DD ') + this.state.quizRoomInfo.TimeStart).format('HH:mm A');
    //                 //         _endTime = String(this.state.quizRoomInfo.TimeEnd).includes(' ') ?
    //                 //             moment(this.state.quizRoomInfo.TimeEnd).format('HH:mm A')
    //                 //             : moment(moment().format('YYYY-MM-DD ') + this.state.quizRoomInfo.TimeEnd).format('HH:mm A');
    //                 //     }
    //                 // }
    //                 //#endregion

    //                 let _alertContent = "<table width='100%'>";

    //                 _alertContent += '<tr><td>Organizer</td><td>:</td><td>' + this.state.quizRoomInfo.Organizer + '</td></tr>';
    //                 _alertContent += '<tr><td>Room Code</td><td>:</td><td><b>' + this.state.quizRoomInfo.RoomCode + '</b></td></tr>';
    //                 _alertContent += '<tr><td>Room Title</td><td>:</td><td>' + this.state.quizRoomInfo.RoomTitle + '</td></tr>';
    //                 _alertContent += '<tr><td>Subject</td><td>:</td><td>' + this.state.quizRoomInfo.Subject + '</td></tr>';
    //                 _alertContent += '<tr><td>Date</td><td>:</td><td><b>' + _date + '</b>' + _daysToGo + '</td></tr>';
    //                 _alertContent += '<tr><td>Start Time</td><td>:</td><td>' + _roomDateTimeStart.format('HH:mm A') + '</td></tr>';
    //                 _alertContent += '<tr><td>End Time</td><td>:</td><td>' + _roomDateTimeEnd.format('HH:mm A') + '</td></tr>';

    //                 let _duration = this.state.quizRoomInfo.Duration !== undefined ?
    //                     this.FormatedDuration(this.state.quizRoomInfo.Duration) : '-';

    //                 _alertContent += '<tr><td>Duration (Time Limit)</td><td>:</td><td>'
    //                     // + '<span class="blink" style="font-weight: bold;color: red;">'
    //                     + '<span style="font-weight: bold;color: red;">'
    //                     + _duration
    //                     + '</span></td></tr>';

    //                 _alertContent += "<tr><td colspan='3'>";
    //                 _alertContent += '<br />' + Locale("cannot-enter-room-today-content", this.props.Locale);
    //                 _alertContent += '</td></tr>';

    //                 _alertContent += "<tr><td colspan='3'>";
    //                 _alertContent += 'Current Time is <u>' + moment().format('LLLL') + '</u>';
    //                 _alertContent += '</td></tr>';

    //                 _alertContent += '</td></tr></table>';

    //                 this.SetAlert(Locale("cannot-enter-room-today-title", this.props.Locale), _alertContent);
    //             }
    //         });
    //     }
    //     else {
    //         this.SetAlert(Locale("invalid-room-code-entered", this.props.Locale), Locale("room-not-found", this.props.Locale));
    //     }
    // }

    FormatedDuration = (_totalSeconds) => {
        // let min = Locale("time-min", this.props.Locale);
        // let sec = Locale("time-sec", this.props.Locale);

        // let totalSeconds = Number(_totalSeconds);

        // let minutes = (totalSeconds / 60).toFixed(3).split('.')[0];
        // let seconds = (totalSeconds % 60).toFixed(1);
        // return minutes + ' ' + min + ' ' + seconds + ' ' + sec;

        //2022.06.07
        let hr = Locale("time-hour-full", this.props.Locale);
        let hrs = Locale("time-hour-full-s", this.props.Locale);
        let min = Locale("time-min", this.props.Locale);
        let sec = Locale("time-sec", this.props.Locale);

        let totalSeconds = Number(_totalSeconds);

        let hours = (totalSeconds / 3600).toFixed(3).split('.')[0];
        let minutes = ((totalSeconds - (hours * 3600)) / 60).toFixed(3).split('.')[0];
        let seconds = (totalSeconds % 60).toFixed(3).split('.')[0];
        return (hours > 0 ? hours + ' ' + (hours > 1 ? hrs : hr) + ' ' : '') + minutes + ' ' + min + ' ' + seconds + ' ' + sec;
    }

    GotoQuizLive = async () => {
        this.setState({
            redirectLink: "/quiz/live",
            redirect: true,
        });
    }

    GotoLoginPage = () => {
        let _loc = '/';
        if (this.props.isFromParentApp || this.props.isRedirectToHistoryList) {
            _loc = '/?';
            if (this.props.isFromParentApp && this.props.isRedirectToHistoryList)
                _loc = _loc + 'from=parent&to=history';
            else if (this.props.isFromParentApp && !this.props.isRedirectToHistoryList)
                _loc = _loc + 'from=parent';
            else if (!this.props.isFromParentApp && this.props.isRedirectToHistoryList)
                // _loc = '/';
                _loc = _loc + 'to=history';
        }
        this.setState({
            redirectLink: _loc,
            redirect: true,
        });
    }

    handleSetRoomCode = (event) => {
        // alert(event.target.value);
        this.setState({
            quizRoomCode: String(event.target.value),
        });
    }

    //#endregion ==== Quiz Room ====

    handleLogout = async () => {
        this.setState(this.getInitState(),
            async () => {
                await this.props.logout();
                this.GotoLoginPage();
            });
    }

    //#region === 2023.10.31 - obsolete function. ===
    /*
    //2023.10.31 - no need adi, handled in global func Profile_ValidateProperties.
    //2023.09.14
    CheckNewField = () => {
        let profile = this.state.profileFetched;

        if (profile.hasOwnProperty('Coin') === false)
            profile['Coin'] = "0";
        if (profile.hasOwnProperty('AchievementPoint') === false)
            profile['AchievementPoint'] = "0";
        if (profile.hasOwnProperty('Classroom') === false)
            profile['Classroom'] = "";

        this.setState({
            profileFetched: profile,
        });
    }

    LoginInfoValidation = async () => {
        // let isValid = false;

        //Check Email on Realtime Database.
        // isValid = await this.CheckProfileOnRealtimeDatabase();
        await this.CheckProfileOnRealtimeDatabase();

        //Check Email on FireStore.
        await this.CheckProfileOnFirestore();

        // Promise.all(
        //     await this.CheckProfileOnRealtimeDatabase(),
        //     await this.CheckProfileOnFirestore()
        // ).then(async () => {

        //Debug.
        // this.SetAlert("Profile Data", JSON.stringify(this.state.profileSnapshot) + "<br /><br />" + JSON.stringify(this.state.profileFetched));
        // alert(this.state.isRealtimeProfileExists + "\n\n" + this.state.isProfileExists);

        //2020.11.21  //New property Contact in profile added by Calvin request.
        await this.CheckForNewPropertyExistence();

        if (this.props.isDevMode) {
            console.log(JSON.stringify(this.state.profileSnapshot));
            console.log(JSON.stringify(this.state.profileFetched));
            // return null;
        }

        if (this.state.isRealtimeProfileExists && this.state.isProfileExists && !this.state.hasNewPropertyInProfile) {
            //Compare & Update if profile in both location are valid.
            // alert('CompareAndUpdateProfile');
            let isUpdateDone = await this.CompareAndUpdateProfile();
            if (isUpdateDone) {
                this.setState({
                    isProfileSyncDone: true,
                    gradeSelection: this.state.profileFetched.Grade,
                });
                // },
                //     () => {
                //         this.props.SetProfile(this.state.profileFetched);
                //         // alert(JSON.stringify(this.state.profileFetched));
                //     });
                await this.props.SetProfile(this.state.profileFetched);
            }
        }
        else {
            //Profile Sync is needed.
            let isUpdateDone = await this.SyncUserProfile();
            if (isUpdateDone) {
                // this.props.SetProfile(this.state.profileFetched);
                this.setState({
                    isProfileSyncDone: true,
                    // gradeSelection: this.state.profileFetched.Grade,
                });
                // },
                //     () => {
                //         this.props.SetProfile(this.state.profileFetched);
                //     });
                await this.props.SetProfile(this.state.profileFetched);
            }
        }

        // let isBelow13 = this.props.profile.Guardian.length <= 0 ? false : (String(this.props.profile.Below13).toLowerCase() === 'true' ? true : false);
        // let isBelow13 = this.props.profile.Guardian.length > 0 ? true : this.props.profile.Below13;
        let isBelow13 = String(this.props.profile.Below13).toLowerCase() === 'true' ? true : false;
        this.setState({
            toggleShowHide_GuardianNameField: isBelow13,
            cachedProfile: this.state.profileFetched,
        });

        // if (!isUpdateDone)
        //     this.SetAlert("Error", JSON.stringify(this.state.profileSnapshot) + "<br /><br />" + JSON.stringify(this.state.profileFetched));

        // });

        //2021.04.12
        if (this.props.isFromParentApp && this.props.isFromApp_ParticipatedEvent) {
            this.Goto_ParticipatedEventList();
        }
    }
    */
    //#endregion

    //#region === 2023.10.31 - obsolete function. ===
    /*
    CheckProfileOnRealtimeDatabase = async () => {
        // let isEmailNotExist = false;

        // await this.props.dbCommon.ref('Common/' + this.props.user.uid + "/_7Email").once('value', snapshot => {
        //     isEmailNotExist = !snapshot.exists;
        // });

        // await this.props.dbCommon.ref("Common")
        //     .orderByChild("_7Email").equalTo(this.props.user.email)
        //     .once('value')
        //     .then((snapshot) => {
        //         // isEmailNotExist = !snapshot.exists;

        //         let dataSnapVal = snapshot.val()[this.props.user.uid];

        //         //Save.
        //         this.setState({
        //             isRealtimeProfileExists: snapshot.exists(),
        //             profileSnapshot: dataSnapVal,
        //         }, () => {
        //             // alert(JSON.stringify(dataSnapVal));
        //         });
        //     })
        //     .catch(async (error) => {
        //         // this.SetAlert("Error", error.code + "<br />" + error.message);
        //         await this.props.SetErrorLog(new Date(), "QuizHome | CheckProfileOnRealtimeDatabase | Error = " + error + " | " + this.props.user.email);
        //     });

        //2020.12.09
        //old fetch method unreliable. now change to this method.
        await this.props.dbCommon
            .ref('Common/' + this.props.user.uid)
            .once('value', snapshot => {
                // isEmailNotExist = !snapshot.exists;

                //Save.
                this.setState({
                    isRealtimeProfileExists: snapshot.exists(),
                    profileSnapshot: snapshot.toJSON(),
                }, () => {
                    // alert(this.state.isRealtimeProfileExists + "\n\n" + JSON.stringify(this.state.profileSnapshot));
                });
            })
            .catch(async (error) => {
                // this.SetAlert("Error", error.code + "<br />" + error.message);
                await this.props.SetErrorLog(new Date(), "QuizHome | CheckProfileOnRealtimeDatabase | Error = " + error + " | " + this.props.user.email);
            });

        // return isEmailNotExist;
    }
    */
    //#endregion

    //#region === 2023.10.31 - obsolete function. ===
    /*
    CheckForNewPropertyExistence = async () => {
        //2020.11.21    //new property Contact added due to Calvin request.

        let _hasNewPropertyInProfile = false;

        // let rtdb_profile_instance = JSON.parse(JSON.stringify(this.state.profileSnapshot));
        // let fs_profile_instance = JSON.parse(JSON.stringify(this.state.profileFetched));

        //2021.04.13
        let rtdb_profile_instance = this.state.profileSnapshot ?? {};
        let fs_profile_instance = this.state.profileFetched ?? {};

        //2020.11.26
        //most logic structures are revamped.

        //modified to counter-measure very old accounts that failed partly when updating profile items.
        let is_RTDB_profile_has_LastUpdate = rtdb_profile_instance.hasOwnProperty('_0LastUpdate');
        let is_RTDB_profile_has_Name = rtdb_profile_instance.hasOwnProperty('_1Name');
        let is_RTDB_profile_has_Grade = rtdb_profile_instance.hasOwnProperty('_2Grade');
        let is_RTDB_profile_has_Center = rtdb_profile_instance.hasOwnProperty('_3Center');
        let is_RTDB_profile_has_Score = rtdb_profile_instance.hasOwnProperty('_4Score');
        let is_RTDB_profile_has_Mode = rtdb_profile_instance.hasOwnProperty('_5Mode');
        let is_RTDB_profile_has_ICNo = rtdb_profile_instance.hasOwnProperty('_6ICNo');
        let is_RTDB_profile_has_Email = rtdb_profile_instance.hasOwnProperty('_7Email');
        let is_RTDB_profile_has_Contact = rtdb_profile_instance.hasOwnProperty('_8Contact');
        let is_RTDB_profile_has_Guardian = rtdb_profile_instance.hasOwnProperty('_9Guardian');
        let is_RTDB_profile_has_School = rtdb_profile_instance.hasOwnProperty('_10School');
        let is_RTDB_profile_has_PolicyTncAgree = rtdb_profile_instance.hasOwnProperty('_11PolicyTncAgree');
        let is_RTDB_profile_has_PolicyTncAgreeDateTime = rtdb_profile_instance.hasOwnProperty('_12PolicyTncAgreeDateTime');
        let is_RTDB_profile_has_NationalState = rtdb_profile_instance.hasOwnProperty('_13NationalState');
        let is_RTDB_profile_has_Below13 = rtdb_profile_instance.hasOwnProperty('_14Below13');  //2020.11.28
        // let is_RTDB_profile_has_SimStudent = rtdb_profile_instance.hasOwnProperty('_15SimStudent');  //2020.12.03
        let is_RTDB_profile_has_Uid = rtdb_profile_instance.hasOwnProperty('_15Uid');  //2020.12.15
        //2021.07.30
        let is_RTDB_profile_has_Gender = rtdb_profile_instance.hasOwnProperty('_16Gender');
        let is_RTDB_profile_has_Race = rtdb_profile_instance.hasOwnProperty('_17Race');
        // let is_RTDB_profile_has_Religion = rtdb_profile_instance.hasOwnProperty('_18Religion');
        let is_RTDB_profile_has_DistrictArea = rtdb_profile_instance.hasOwnProperty('_18DistrictArea');     //2021.10.12
        let is_RTDB_profile_has_Classroom = rtdb_profile_instance.hasOwnProperty('_19Classroom');     //2023.06.14

        //FireStore.
        let is_FS_profile_has_LastUpdate = fs_profile_instance.hasOwnProperty('LastUpdate');
        let is_FS_profile_has_Name = fs_profile_instance.hasOwnProperty('Name');
        let is_FS_profile_has_Grade = fs_profile_instance.hasOwnProperty('Grade');
        let is_FS_profile_has_Center = fs_profile_instance.hasOwnProperty('Center');
        let is_FS_profile_has_Score = fs_profile_instance.hasOwnProperty('Score');
        let is_FS_profile_has_Mode = fs_profile_instance.hasOwnProperty('Mode');
        let is_FS_profile_has_ICNo = fs_profile_instance.hasOwnProperty('ICNo');
        let is_FS_profile_has_Email = fs_profile_instance.hasOwnProperty('Email');
        let is_FS_profile_has_Contact = fs_profile_instance.hasOwnProperty('Contact');
        let is_FS_profile_has_Guardian = fs_profile_instance.hasOwnProperty('Guardian');
        let is_FS_profile_has_School = fs_profile_instance.hasOwnProperty('School');
        let is_FS_profile_has_PolicyTncAgree = fs_profile_instance.hasOwnProperty('PolicyTncAgree');
        let is_FS_profile_has_PolicyTncAgreeDateTime = fs_profile_instance.hasOwnProperty('PolicyTncAgreeDateTime');
        let is_FS_profile_has_NationalState = fs_profile_instance.hasOwnProperty('NationalState');
        let is_FS_profile_has_Below13 = fs_profile_instance.hasOwnProperty('Below13');    //2020.11.28
        // let is_FS_profile_has_SimStudent = fs_profile_instance.hasOwnProperty('SimStudent');    //2020.12.03
        let is_FS_profile_has_Uid = fs_profile_instance.hasOwnProperty('Uid');    //2020.12.15
        //2021.07.30
        let is_FS_profile_has_Gender = fs_profile_instance.hasOwnProperty('Gender');
        let is_FS_profile_has_Race = fs_profile_instance.hasOwnProperty('Race');
        // let is_FS_profile_has_Religion = fs_profile_instance.hasOwnProperty('Religion');
        let is_FS_profile_has_DistrictArea = fs_profile_instance.hasOwnProperty('DistrictArea');    //2021.10.12
        let is_FS_profile_has_Classroom = fs_profile_instance.hasOwnProperty('Classroom');    //2023.06.14

        //RTDB modal.
        rtdb_profile_instance = {
            _0LastUpdate: is_RTDB_profile_has_LastUpdate ? rtdb_profile_instance['_0LastUpdate'] + '' : '',
            _1Name: is_RTDB_profile_has_Name ? rtdb_profile_instance['_1Name'] + '' : '',
            _2Grade: is_RTDB_profile_has_Grade ? rtdb_profile_instance['_2Grade'] + '' : '',
            _3Center: is_RTDB_profile_has_Center ? rtdb_profile_instance['_3Center'] + '' : '',
            _4Score: is_RTDB_profile_has_Score ? rtdb_profile_instance['_4Score'] + '' : '0',
            _5Mode: is_RTDB_profile_has_Mode ? rtdb_profile_instance['_5Mode'] + '' : 'Student',
            _6ICNo: is_RTDB_profile_has_ICNo ? rtdb_profile_instance['_6ICNo'] + '' : '',
            _7Email: is_RTDB_profile_has_Email ? rtdb_profile_instance['_7Email'] + '' : '',
            _8Contact: is_RTDB_profile_has_Contact ? rtdb_profile_instance['_8Contact'] + '' : '',
            _9Guardian: is_RTDB_profile_has_Guardian ? rtdb_profile_instance['_9Guardian'] + '' : '',
            _10School: is_RTDB_profile_has_School ? rtdb_profile_instance['_10School'] + '' : '',
            _11PolicyTncAgree: is_RTDB_profile_has_PolicyTncAgree ? rtdb_profile_instance['_11PolicyTncAgree'] + '' : 'false',
            _12PolicyTncAgreeDateTime: is_RTDB_profile_has_PolicyTncAgreeDateTime ? rtdb_profile_instance['_12PolicyTncAgreeDateTime'] + '' : '',
            _13NationalState: is_RTDB_profile_has_NationalState ? rtdb_profile_instance['_13NationalState'] + '' : '',
            _14Below13:
                is_RTDB_profile_has_Below13 ?
                    String(rtdb_profile_instance['_14Below13']).toLowerCase() === 'true' ?
                        'true'
                        :
                        'false'
                    :
                    is_RTDB_profile_has_Guardian ?
                        rtdb_profile_instance['_9Guardian'].length > 0 ?
                            'true'
                            :
                            'false'
                        :
                        'false',
            //2020.12.03
            // _15SimStudent: is_RTDB_profile_has_SimStudent ? rtdb_profile_instance._15SimStudent : 'Not Specify',

            //2020.12.15
            _15Uid: is_RTDB_profile_has_Uid ? rtdb_profile_instance['_15Uid'] + '' : '',

            //2021.07.30
            _16Gender: is_RTDB_profile_has_Gender ? rtdb_profile_instance['_16Gender'] + '' : '',
            _17Race: is_RTDB_profile_has_Race ? rtdb_profile_instance['_17Race'] + '' : '',
            // _18Religion: is_RTDB_profile_has_Religion ? rtdb_profile_instance['_18Religion'] + '' : '',

            //2021.10.12
            _18DistrictArea: is_RTDB_profile_has_DistrictArea ? rtdb_profile_instance['_18DistrictArea'] + '' : '',

            //2023.06.14
            _19Classroom: is_RTDB_profile_has_Classroom ? rtdb_profile_instance['_19Classroom'] + '' : '',
        };

        //FireStore modal.
        fs_profile_instance = {
            LastUpdate: is_FS_profile_has_LastUpdate ? fs_profile_instance.LastUpdate + '' : '',
            Name: is_FS_profile_has_Name ? fs_profile_instance.Name + '' : '',
            Grade: is_FS_profile_has_Grade ? fs_profile_instance.Grade + '' : '',
            Center: is_FS_profile_has_Center ? fs_profile_instance.Center + '' : '',
            Score: is_FS_profile_has_Score ? fs_profile_instance.Score + '' : '0',
            Mode: is_FS_profile_has_Mode ? fs_profile_instance.Mode + '' : 'Student',
            ICNo: is_FS_profile_has_ICNo ? fs_profile_instance.ICNo + '' : '',
            Email: is_FS_profile_has_Email ? fs_profile_instance.Email + '' : '',
            Contact: is_FS_profile_has_Contact ? fs_profile_instance.Contact + '' : '',    //2020.11.21

            //2020.11.26
            Guardian: is_FS_profile_has_Guardian ? fs_profile_instance.Guardian + '' : '',
            School: is_FS_profile_has_School ? fs_profile_instance.School + '' : '',
            PolicyTncAgree: is_FS_profile_has_PolicyTncAgree ? fs_profile_instance.PolicyTncAgree + '' : 'false',
            PolicyTncAgreeDateTime: is_FS_profile_has_PolicyTncAgreeDateTime ? fs_profile_instance.PolicyTncAgreeDateTime + '' : '',
            NationalState: is_FS_profile_has_NationalState ? fs_profile_instance.NationalState + '' : '',

            //2020.11.28
            // Below13: is_FS_profile_has_Below13 ? fs_profile_instance.Below13 + '' : 'false',
            // Below13:
            //     is_FS_profile_has_Below13 ?
            //         CheckBoolean(fs_profile_instance.Below13) ?
            //             'true'
            //             :
            //             is_FS_profile_has_Guardian ?
            //                 fs_profile_instance.Guardian.length > 0 ?
            //                     'true'
            //                     :
            //                     'false'
            //                 :
            //                 'false'
            //         :
            //         is_FS_profile_has_Guardian ?
            //             fs_profile_instance.Guardian.length > 0 ?
            //                 'true'
            //                 :
            //                 'false'
            //             :
            //             'false',
            Below13:
                is_FS_profile_has_Below13 ?
                    String(fs_profile_instance.Below13).toLowerCase() === 'true' ?
                        'true'
                        :
                        'false'
                    :
                    is_FS_profile_has_Guardian ?
                        fs_profile_instance.Guardian.length > 0 ?
                            'true'
                            :
                            'false'
                        :
                        'false',

            //2020.12.03
            // SimStudent: is_FS_profile_has_SimStudent ? fs_profile_instance.SimStudent : 'Not Specify',

            //2020.12.15
            Uid: is_FS_profile_has_Uid ? fs_profile_instance.Uid + '' : '',

            //2021.07.30
            Gender: is_FS_profile_has_Gender ? fs_profile_instance.Gender + '' : '',
            Race: is_FS_profile_has_Race ? fs_profile_instance.Race + '' : '',
            // Religion: is_FS_profile_has_Religion ? fs_profile_instance.Religion + '' : '',

            //2021.10.12
            DistrictArea: is_FS_profile_has_DistrictArea ? fs_profile_instance.DistrictArea + '' : '',

            //2023.06.14
            Classroom: is_FS_profile_has_Classroom ? fs_profile_instance.Classroom + '' : '',
        };

        //202104.13
        if (this.props.isDevMode) {
            console.log(JSON.stringify(rtdb_profile_instance));
            console.log(JSON.stringify(fs_profile_instance));
        }

        //=== Email related === starts ===//

        //RTDB Profile Email.
        let rtdb_email = String(rtdb_profile_instance._7Email);
        if (rtdb_email === 'null' || rtdb_email === '') {
            rtdb_profile_instance._7Email = this.props.email;
            _hasNewPropertyInProfile = true;    //force update profile
        }
        else if (rtdb_email !== '' || rtdb_email.length > 0) {
            //remove any empty spaces due to user missed-added, and set all letters in email to lower cases.
            rtdb_email = rtdb_email.toLowerCase();
            if (rtdb_email.includes(' ')) {
                rtdb_email = rtdb_email.replace(new RegExp(' ', 'g'), '');
                rtdb_profile_instance._7Email = rtdb_email;
                _hasNewPropertyInProfile = true;    //force update profile
            }
        }

        //FireStore Profile Email.
        let fs_email = String(fs_profile_instance.Email);
        if (fs_email === 'null' || fs_email === '') {
            fs_profile_instance.Email = this.props.email;
            _hasNewPropertyInProfile = true;    //force update profile
        }
        else if (fs_email !== '' || fs_email.length > 0) {
            //remove any empty spaces due to user missed-added, and set all letters in email to lower cases.
            fs_email = fs_email.toLowerCase();
            if (fs_email.includes(' ')) {
                fs_email = fs_email.replace(new RegExp(' ', 'g'), '');
                fs_profile_instance.Email = fs_email;
                _hasNewPropertyInProfile = true;    //force update profile
            }
        }
        //=== Email related === ends ===//

        //2020.12.15
        if (rtdb_profile_instance._15Uid.length <= 0)
            rtdb_profile_instance._15Uid = this.props.user.uid;
        if (fs_profile_instance.Uid.length <= 0)
            fs_profile_instance.Uid = this.props.user.uid;


        if (
            //2020.11.26
            !is_RTDB_profile_has_LastUpdate
            || !is_RTDB_profile_has_Name
            || !is_RTDB_profile_has_Grade
            || !is_RTDB_profile_has_Center
            || !is_RTDB_profile_has_Score
            || !is_RTDB_profile_has_Mode
            || !is_RTDB_profile_has_ICNo
            || !is_RTDB_profile_has_Email
            || !is_RTDB_profile_has_Contact
            || !is_RTDB_profile_has_Guardian
            || !is_RTDB_profile_has_School
            || !is_RTDB_profile_has_PolicyTncAgree
            || !is_RTDB_profile_has_PolicyTncAgreeDateTime
            || !is_RTDB_profile_has_NationalState
            || !is_RTDB_profile_has_Below13     //2020.11.28
            // || !is_RTDB_profile_has_SimStudent  //2020.12.03
            || !is_RTDB_profile_has_Uid     //2020.12.15
            //2021.07.30
            || !is_RTDB_profile_has_Gender
            || !is_RTDB_profile_has_Race
            // || !is_RTDB_profile_has_Religion
            || !is_RTDB_profile_has_DistrictArea    //2021.10.12
            || !is_RTDB_profile_has_Classroom    //2023.06.14

            //FireStore.
            || !is_FS_profile_has_LastUpdate
            || !is_FS_profile_has_Name
            || !is_FS_profile_has_Grade
            || !is_FS_profile_has_Center
            || !is_FS_profile_has_Score
            || !is_FS_profile_has_Mode
            || !is_FS_profile_has_ICNo
            || !is_FS_profile_has_Email
            || !is_FS_profile_has_Contact
            || !is_FS_profile_has_Guardian
            || !is_FS_profile_has_School
            || !is_FS_profile_has_PolicyTncAgree
            || !is_FS_profile_has_PolicyTncAgreeDateTime
            || !is_FS_profile_has_NationalState
            || !is_FS_profile_has_Below13       //2020.11.28
            // || !is_FS_profile_has_SimStudent    //2020.12.03
            || !is_FS_profile_has_Uid       //2020.12.15
            //2021.07.30
            || !is_FS_profile_has_Gender
            || !is_FS_profile_has_Race
            // || !is_FS_profile_has_Religion
            || !is_FS_profile_has_DistrictArea   //2021.10.12
            || !is_FS_profile_has_Classroom   //2023.06.14
        ) {
            _hasNewPropertyInProfile = true;
        }

        // alert(JSON.stringify(rtdb_profile_instance) + "\n\n" + JSON.stringify(fs_profile_instance));
        // alert(JSON.stringify(this.state.profileSnapshot) + "\n\n" + JSON.stringify(this.state.profileFetched));
        // return null;

        this.setState({
            profileSnapshot: rtdb_profile_instance,
            profileFetched: fs_profile_instance,
            hasNewPropertyInProfile: _hasNewPropertyInProfile,
        });
    }

    CompareAndUpdateProfile = async () => {
        let isUpdateDone = false;

        let rtdb_LU = moment(this.state.profileSnapshot._0LastUpdate);
        let fs_LU = moment(this.state.profileFetched.LastUpdate);

        // alert(rtdb_LU.toString() + "\n\n" + fs_LU.toString());

        if (rtdb_LU === fs_LU) {
            //Same LastUpdate dt, no update is require.
            isUpdateDone = true;
        }

        // //2020.11.21    //new property Contact added due to Calvin request.
        // let is_RTDB_profile_has_Contact = this.state.profileSnapshot.hasOwnProperty('_8Contact');
        // let is_FS_profile_has_Contact = this.state.profileFetched.hasOwnProperty('Contact');
        // if (!is_RTDB_profile_has_Contact || !is_FS_profile_has_Contact) {
        //     isUpdateDone = false;
        //     if (!is_RTDB_profile_has_Contact) {
        //         let newObjectInstance = {
        //             _0LastUpdate: this.state.profileSnapshot._0LastUpdate,
        //             _1Name: this.state.profileSnapshot._1Name,
        //             _2Grade: this.state.profileSnapshot._2Grade,
        //             _3Center: this.state.profileSnapshot._3Center,
        //             _4Score: this.state.profileSnapshot._4Score,
        //             _5Mode: this.state.profileSnapshot._5Mode,
        //             _6ICNo: this.state.profileSnapshot._6ICNo,
        //             _7Email: this.state.profileSnapshot._7Email,
        //             _8Contact: '',      //2020.11.21
        //         };
        //         this.state.profileSnapshot = newObjectInstance;
        //     }
        //     if (!is_FS_profile_has_Contact) {
        //         let newObjectInstance = {
        //             LastUpdate: this.state.profileFetched.LastUpdate,
        //             Name: this.state.profileFetched.Name,
        //             Grade: this.state.profileFetched.Grade,
        //             Center: this.state.profileFetched.Center,
        //             Score: this.state.profileFetched.Score,
        //             Mode: this.state.profileFetched.Mode,
        //             ICNo: this.state.profileFetched.ICNo,
        //             Email: this.state.profileFetched.Email,
        //             Contact: '',      //2020.11.21
        //         };
        //         this.state.profileFetched = newObjectInstance;
        //     }
        // }

        if (!isUpdateDone) {

            //2021.02.02
            if (String(rtdb_LU).toLowerCase() !== 'invalid date' && String(fs_LU).toLowerCase() !== 'invalid date') {
                if (rtdb_LU > fs_LU) {
                    isUpdateDone = await this.SyncToFireStore();
                }
                else {
                    isUpdateDone = await this.SyncToRealtimeDB();
                }
            }

            // if (rtdb_LU > fs_LU) {
            //     //Realtime data is newer than FireStore.

            //     this.state.profileFetched.Name = this.state.profileSnapshot._1Name;
            //     this.state.profileFetched.Grade = this.state.profileSnapshot._2Grade;
            //     this.state.profileFetched.Center = this.state.profileSnapshot._3Center;
            //     this.state.profileFetched.Score = this.state.profileSnapshot._4Score;
            //     this.state.profileFetched.Mode = this.state.profileSnapshot._5Mode;
            //     this.state.profileFetched.ICNo = this.state.profileSnapshot._6ICNo;

            //     this.state.profileFetched.Contact = this.state.profileSnapshot._8Contact;   //2020.11.21

            //     // let dtNow = moment().format("YYYY-MM-DD HH:mm:ss");
            //     // this.state.profileFetched.LastUpdate = dtNow;
            //     this.state.profileFetched.LastUpdate = this.state.profileSnapshot._0LastUpdate;

            //     //Update to FireStore data.
            //     await this.props.firestore.collection("User").doc(this.props.user.uid)
            //         .set(this.state.profileFetched)
            //         .then(() => {
            //             isUpdateDone = true;
            //             this.setState({
            //                 profileFetched: this.state.profileFetched,
            //             });
            //         })
            //         .catch(async (error) => {
            //             // this.SetAlert("Error", error.code + "<br />" + error.message);
            //             await this.props.SetErrorLog(new Date(), "CompareAndUpdateProfile | FireStore | Error = " + error);
            //         });
            // }
            // else {
            //     //Realtime data is older than FireStore.

            //     this.state.profileSnapshot._1Name = this.state.profileFetched.Name;
            //     this.state.profileSnapshot._2Grade = this.state.profileFetched.Grade;
            //     this.state.profileSnapshot._3Center = this.state.profileFetched.Center;
            //     this.state.profileSnapshot._4Score = this.state.profileFetched.Score;
            //     this.state.profileSnapshot._5Mode = this.state.profileFetched.Mode;
            //     this.state.profileSnapshot._6ICNo = this.state.profileFetched.ICNo;

            //     this.state.profileSnapshot._8Contact = this.state.profileFetched.Contact;   //2020.11.21

            //     // let dtNow = moment().format("YYYY-MM-DD HH:mm:ss");
            //     // this.state.profileSnapshot._0LastUpdate = dtNow;
            //     this.state.profileSnapshot._0LastUpdate = this.state.profileFetched.LastUpdate;

            //     //Update to Realtime data.
            //     await this.props.dbCommon.ref("Common/" + this.props.user.uid)
            //         .set(this.state.profileSnapshot)
            //         .then(() => {
            //             isUpdateDone = true;
            //             this.setState({
            //                 profileSnapshot: this.state.profileSnapshot,
            //             });
            //         })
            //         .catch(async (error) => {
            //             // this.SetAlert("Error", error.code + "<br />" + error.message);
            //             await this.props.SetErrorLog(new Date(), "CompareAndUpdateProfile | RTDB | Error = " + error);
            //         });
            // }
        }

        return isUpdateDone;
    }

    SyncUserProfile = async () => {
        let isUpdateDone = false;

        //Sync record if needed.
        if (this.state.hasNewPropertyInProfile) {

            //2020.11.26
            let _profileInFireStore = this.state.profileFetched;
            let _profileInRTDB = this.state.profileSnapshot;

            let isProfileCompareNeeded = true;
            let rtdb_LU = moment(_profileInRTDB._0LastUpdate);
            let fs_LU = moment(_profileInFireStore.LastUpdate);

            // alert(rtdb_LU.format('lll') + " vs " + fs_LU.format('lll'));

            if (rtdb_LU === fs_LU) {
                //Same LastUpdate dt, no update is require.
                isProfileCompareNeeded = false;
            }


            if (isProfileCompareNeeded) {
                if (this.state.isProfileExists) {
                    if (rtdb_LU > fs_LU) {
                        _profileInFireStore.Name = _profileInRTDB._1Name;
                        _profileInFireStore.Grade = _profileInRTDB._2Grade;
                        _profileInFireStore.Center = _profileInRTDB._3Center;
                        _profileInFireStore.Score = _profileInRTDB._4Score;
                        _profileInFireStore.Mode = _profileInRTDB._5Mode;
                        _profileInFireStore.ICNo = _profileInRTDB._6ICNo;
                        _profileInFireStore.Email = _profileInRTDB._7Email;
                        _profileInFireStore.Contact = _profileInRTDB._8Contact;
                        _profileInFireStore.Guardian = _profileInRTDB._9Guardian;
                        _profileInFireStore.School = _profileInRTDB._10School;
                        _profileInFireStore.PolicyTncAgree = _profileInRTDB._11PolicyTncAgree;
                        _profileInFireStore.PolicyTncAgreeDateTime = _profileInRTDB._12PolicyTncAgreeDateTime;
                        _profileInFireStore.NationalState = _profileInRTDB._13NationalState;
                        _profileInFireStore.Below13 = _profileInRTDB._14Below13;
                        // _profileInFireStore.SimStudent = _profileInRTDB._15SimStudent;
                        _profileInFireStore.Uid = _profileInRTDB._15Uid;

                        //2021.07.30
                        _profileInFireStore.Gender = _profileInRTDB._16Gender;
                        _profileInFireStore.Race = _profileInRTDB._17Race;
                        // _profileInFireStore.Religion = _profileInRTDB._18Religion;

                        //2021.10.12
                        _profileInFireStore.DistrictArea = _profileInRTDB._18DistrictArea;

                        //2023.06.14
                        _profileInFireStore.Classroom = _profileInRTDB._19Classroom;
                    }
                    else {
                        _profileInRTDB._1Name = _profileInFireStore.Name;
                        _profileInRTDB._2Grade = _profileInFireStore.Grade;
                        _profileInRTDB._3Center = _profileInFireStore.Center;
                        _profileInRTDB._4Score = _profileInFireStore.Score;
                        _profileInRTDB._5Mode = _profileInFireStore.Mode;
                        _profileInRTDB._6ICNo = _profileInFireStore.ICNo;
                        _profileInRTDB._7Email = _profileInFireStore.Email;
                        _profileInRTDB._8Contact = _profileInFireStore.Contact;
                        _profileInRTDB._9Guardian = _profileInFireStore.Guardian;
                        _profileInRTDB._10School = _profileInFireStore.School;
                        _profileInRTDB._11PolicyTncAgree = _profileInFireStore.PolicyTncAgree;
                        _profileInRTDB._12PolicyTncAgreeDateTime = _profileInFireStore.PolicyTncAgreeDateTime;
                        _profileInRTDB._13NationalState = _profileInFireStore.NationalState;
                        _profileInRTDB._14Below13 = _profileInFireStore.Below13;
                        // _profileInRTDB._15SimStudent = _profileInFireStore.SimStudent;
                        _profileInRTDB._15Uid = _profileInFireStore.Uid;

                        //2021.07.30
                        _profileInRTDB._16Gender = _profileInFireStore.Gender;
                        _profileInRTDB._17Race = _profileInFireStore.Race;
                        // _profileInRTDB._18Religion = _profileInFireStore.Religion;

                        //2021.10.12
                        _profileInRTDB._18DistrictArea = _profileInFireStore.DistrictArea;

                        //2023.06.14
                        _profileInRTDB._19Classroom = _profileInFireStore.Classroom;
                    }
                }
                else {

                    //2020.12.09
                    //only insert data into FS using RTDB data, coz FS data is not existed.
                    _profileInFireStore.Name = _profileInRTDB._1Name;
                    _profileInFireStore.Grade = _profileInRTDB._2Grade;
                    _profileInFireStore.Center = _profileInRTDB._3Center;
                    _profileInFireStore.Score = _profileInRTDB._4Score;
                    _profileInFireStore.Mode = _profileInRTDB._5Mode;
                    _profileInFireStore.ICNo = _profileInRTDB._6ICNo;
                    _profileInFireStore.Email = _profileInRTDB._7Email;
                    _profileInFireStore.Contact = _profileInRTDB._8Contact;
                    _profileInFireStore.Guardian = _profileInRTDB._9Guardian;
                    _profileInFireStore.School = _profileInRTDB._10School;
                    _profileInFireStore.PolicyTncAgree = _profileInRTDB._11PolicyTncAgree;
                    _profileInFireStore.PolicyTncAgreeDateTime = _profileInRTDB._12PolicyTncAgreeDateTime;
                    _profileInFireStore.NationalState = _profileInRTDB._13NationalState;
                    _profileInFireStore.Below13 = _profileInRTDB._14Below13;
                    // _profileInFireStore.SimStudent = _profileInRTDB._15SimStudent;
                    _profileInFireStore.Uid = _profileInRTDB._15Uid;

                    // alert(JSON.stringify(_profileInFireStore));

                    //2021.07.30
                    _profileInFireStore.Gender = _profileInRTDB._16Gender;
                    _profileInFireStore.Race = _profileInRTDB._17Race;
                    // _profileInFireStore.Religion = _profileInRTDB._18Religion;

                    //2021.10.12
                    _profileInFireStore.DistrictArea = _profileInRTDB._18DistrictArea;

                    //2023.06.14
                    _profileInFireStore.Classroom = _profileInRTDB._19Classroom;
                }
            }


            let lastUpdate = moment().format('YYYY-MM-DD HH:mm:ss');
            _profileInFireStore.LastUpdate = lastUpdate;
            _profileInRTDB._0LastUpdate = lastUpdate;

            this.setState({
                profileFetched: _profileInFireStore,
                profileSnapshot: _profileInRTDB,
            });

            let successUpdateToFS = await this.SyncToFireStore();
            let successUpdateToRTDB = await this.SyncToRealtimeDB();
            isUpdateDone = successUpdateToFS && successUpdateToRTDB;
        }
        else {
            if (!this.state.isRealtimeProfileExists || !this.state.isProfileExists) {

                //Sync to FireStore if record not exist.
                if (this.state.isRealtimeProfileExists && !this.state.isProfileExists) {
                    isUpdateDone = await this.SyncToFireStore();
                }

                //Sync to RTDB if record not exist.
                if (!this.state.isRealtimeProfileExists && this.state.isProfileExists) {
                    isUpdateDone = await this.SyncToRealtimeDB();
                }
            }
        }

        return isUpdateDone;
    }

    SyncToRealtimeDB = async () => {
        // alert(JSON.stringify(this.state.profileFetched));

        let isSyncToRealtimeDatabaseSuccess = false;

        let _profileInRTDB = {
            // _0LastUpdate: moment().format("YYYY-MM-DD HH:mm:ss"),
            _0LastUpdate: CheckObjectStringEmpty(this.state.profileFetched, 'LastUpdate'),
            _1Name: CheckObjectStringEmpty(this.state.profileFetched, 'Name'),
            _2Grade: CheckObjectStringEmpty(this.state.profileFetched, 'Grade'),
            _3Center: CheckObjectStringEmpty(this.state.profileFetched, 'Center'),
            _4Score: Number(CheckObjectStringEmpty(this.state.profileFetched, 'Score')),
            _5Mode: CheckObjectStringEmpty(this.state.profileFetched, 'Mode'),
            _6ICNo: CheckObjectStringEmpty(this.state.profileFetched, 'ICNo'),
            _7Email: CheckObjectStringEmpty(this.state.profileFetched, 'Email'),
            _8Contact: CheckObjectStringEmpty(this.state.profileFetched, 'Contact'),       //2020.11.21

            //2020.11.26
            _9Guardian: CheckObjectStringEmpty(this.state.profileFetched, 'Guardian'),
            _10School: CheckObjectStringEmpty(this.state.profileFetched, 'School'),
            _11PolicyTncAgree: this.state.profileFetched.PolicyTncAgree,
            _12PolicyTncAgreeDateTime: CheckObjectStringEmpty(this.state.profileFetched, 'PolicyTncAgreeDateTime'),
            _13NationalState: CheckObjectStringEmpty(this.state.profileFetched, 'NationalState'),

            //2020.11.28
            _14Below13: this.state.profileFetched.Below13,

            //2020.12.03
            // _15SimStudent: this.state.profileFetched.SimStudent,

            //2020.12.15
            _15Uid: CheckObjectStringEmpty(this.state.profileFetched, 'Uid'),

            //2021.07.30
            _16Gender: CheckObjectStringEmpty(this.state.profileFetched, 'Gender'),
            _17Race: CheckObjectStringEmpty(this.state.profileFetched, 'Race'),
            // _18Religion: this.state.profileFetched.Religion,

            //2021.10.12
            _18DistrictArea: CheckObjectStringEmpty(this.state.profileFetched, 'DistrictArea'),

            //2023.06.14
            _19Classroom: CheckObjectStringEmpty(this.state.profileFetched, 'Classroom'),
        };

        _profileInRTDB = JSON.parse(JSON.stringify(_profileInRTDB));

        //Save to RTDB.
        //2021.09.13 - add retry.
        let retryAgain = false;
        let done = false;
        let async_action = async () => {
            await this.props.dbCommon.ref("Common/" + this.props.user.uid)
                .set(_profileInRTDB)
                .then(() => {
                    isSyncToRealtimeDatabaseSuccess = true;
                    this.setState({
                        isRealtimeProfileExists: true,
                        profileSnapshot: _profileInRTDB,
                    });
                    done = true;
                })
                .catch((error) => {
                    // errorMessage += "Realtime : " + error.code + "<br />" + error.message + "<br /><br />";
                    // await this.props.SetErrorLog(new Date(), "QuizHome | SyncToRealtimeDB | Error = " + error);
                    retryAgain = true;
                    done = true;
                });
            await DelayUntil(() => done === true);
        }

        do {
            retryAgain = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);

        } while (retryAgain);

        return isSyncToRealtimeDatabaseSuccess;
    }

    SyncToFireStore = async () => {
        // alert(JSON.stringify(this.state.profileSnapshot));

        let isSyncToFireStoreSuccess = false;

        let _profileInRTDB = JSON.parse(JSON.stringify(this.state.profileSnapshot));

        let _profileInFireStore = {
            // LastUpdate: moment().format("YYYY-MM-DD HH:mm:ss")'],
            LastUpdate: CheckObjectStringEmpty(_profileInRTDB, '_0LastUpdate'),
            Name: CheckObjectStringEmpty(_profileInRTDB, '_1Name'),
            Grade: CheckObjectStringEmpty(_profileInRTDB, '_2Grade'),
            Center: CheckObjectStringEmpty(_profileInRTDB, '_3Center'),
            Score: CheckObjectStringEmpty(_profileInRTDB, '_4Score'),
            Mode: CheckObjectStringEmpty(_profileInRTDB, '_5Mode'),
            ICNo: CheckObjectStringEmpty(_profileInRTDB, '_6ICNo'),
            Email: CheckObjectStringEmpty(_profileInRTDB, '_7Email'),
            Contact: CheckObjectStringEmpty(_profileInRTDB, '_8Contact'),
            Guardian: CheckObjectStringEmpty(_profileInRTDB, '_9Guardian'),
            School: CheckObjectStringEmpty(_profileInRTDB, '_10School'),
            PolicyTncAgree: _profileInRTDB['_11PolicyTncAgree'],
            PolicyTncAgreeDateTime: CheckObjectStringEmpty(_profileInRTDB, '_12PolicyTncAgreeDateTime'),
            NationalState: CheckObjectStringEmpty(_profileInRTDB, '_13NationalState'),
            Below13: _profileInRTDB['_14Below13'],
            // SimStudent: _profileInRTDB['_15SimStudent'],
            Uid: CheckObjectStringEmpty(_profileInRTDB, '_15Uid'),  //2020.12.15

            //2021.07.30
            Gender: CheckObjectStringEmpty(_profileInRTDB, '_16Gender'),
            Race: CheckObjectStringEmpty(_profileInRTDB, '_17Race'),
            // Religion: _profileInRTDB['_18Religion'],

            //2021.10.12
            DistrictArea: CheckObjectStringEmpty(_profileInRTDB, '_18DistrictArea'),

            //2023.06.14
            Classroom: CheckObjectStringEmpty(_profileInRTDB, '_19Classroom'),
        };

        // alert(JSON.stringify(this.state.profileSnapshot) + "\n\n" + JSON.stringify(_profileInFireStore) + "\n\n" + JSON.stringify(_profileInRTDB));
        // return null;

        //Save to FireStore.

        //2021.09.13 - add retry.
        let retryAgain = false;
        let done = false;
        let async_action = async () => {
            await this.props.firestore.collection("User")
                .doc(this.props.user.uid)
                .set(JSON.parse(JSON.stringify(_profileInFireStore)))
                .then(() => {
                    isSyncToFireStoreSuccess = true;
                    this.setState({
                        isProfileExists: true,
                        profileFetched: _profileInFireStore,
                    });
                    done = true;
                })
                .catch((error) => {
                    // errorMessage += "FireStore : " + error.code + "<br />" + error.message + "<br /><br />";
                    // await this.props.SetErrorLog(new Date(), "QuizHome | SyncToFireStore | Error = " + error);
                    retryAgain = true;
                    done = true;
                });
            await DelayUntil(() => done === true);
        }

        do {
            retryAgain = false;
            done = false;
            await async_action();
            // await DelayUntil(() => done === true);
        } while (retryAgain);

        return isSyncToFireStoreSuccess;
    }
    */
    //#endregion

    //#region ==== Sync & Update - Profile ====

    //2023.12.06 - revamp, now fetch from API.
    //2022.11.01 - only fetch Profile from FS, no more fetch & sync on both FS & RTDB.
    LoadProfile = async () => {
        // await this.CheckProfileOnFirestore();

        //2023.12.06
        let retryCounter = 0;
        do {
            await this.CheckProfile_ViaApi();
            //backup fetch - from FS.
            if (this.state.profileFetched === null) {
                await this.CheckProfileOnFirestore();
                await Delay(1000);
                // if (this.state.profileFetched !== null)
                //     await this.CheckProfile_ViaApi();
            }
            retryCounter++;
        }
        while (this.state.profileFetched === null && retryCounter < 4);

        if (this.state.isProfileExists) {
            this.setState({
                isProfileSyncDone: true,
                gradeSelection: this.state.profileFetched.Grade,
            });
            // this.CheckNewField();
            this.props.SetProfile(this.state.profileFetched);
            if (this.props.isDevMode)
                console.log('LoadProfile', JSON.stringify(this.state.profileFetched));
            await this.UpdateToProfileBackUp();
            if (this.props.profile !== null) {
                await this.UpdateSchoolSelectHeight();      //2023.10.25
                const isBelow13 = CheckObjectStringEmpty(this.props.profile, 'Below13').toLowerCase() === 'true' ? true : false;
                this.setState({
                    toggleShowHide_GuardianNameField: isBelow13,
                    cachedProfile: this.state.profileFetched,
                });
                if (this.props.isFromParentApp && this.props.isFromApp_ParticipatedEvent) {
                    this.Goto_ParticipatedEventList();
                }
            }
        }
    }

    //2023.09.14
    UpdateToProfileBackUp = async () => {
        try {
            await this.props.dbProfileBackUp.ref(String(this.props.user.uid)).set(this.state.profileFetched);
        }
        catch (e) {
            if (this.props.isDevMode)
                console.log('UpdateToProfileBackUp (Failed) ', e);
        }
    }

    //2024.05.23 - checked, now work as backup fetch from FS (primary fetch now on API).
    CheckProfileOnFirestore = async () => {
        // let isEmailNotExist = false;
        let isProfileExist = false;
        let done = false;
        await this.props.firestore
            .collection("User")
            .where('Email', '==', String(this.props.user.email))
            .where('Uid', '==', String(this.props.user.uid))
            .get()
            .then(querySnapshot => {
                // isEmailNotExist = !querySnapshot.exists;
                let data = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        data.push(doc.data());
                    });
                    if (data.length > 0 || data.hasOwnProperty('Uid'))
                        data = data[0];
                    else
                        data = null;
                }
                // if (this.props.isDevMode)
                //     console.log('CheckProfileOnFirestore', JSON.stringify(data));

                isProfileExist = data !== null;     //2023.12.06 - revamp
                if (isProfileExist) {
                    const { profile, isUpdateToFirestoreNeeded } = Profile_ValidateProperties(this.props.user, data);   //2023.10.19

                    //Save.
                    this.setState({
                        isProfileExists: profile !== null,
                        profileFetched: profile,
                        cachedProfile: JSON.parse(JSON.stringify(profile)),
                    }, async () => {
                        //2023.10.31
                        if (isUpdateToFirestoreNeeded) {
                            await this.UpdateProfileOnFirebase();
                        }
                        // if (this.props.isDevMode)
                        //     console.log('CheckProfileOnFirestore', JSON.stringify(this.state.profileFetched));
                        // alert(this.state.isProfileExists + "\n\n" + JSON.stringify(this.state.profileFetched));
                    });
                }
                done = true;
            })
            .catch(async (error) => {
                done = true;
                if (this.props.isDevMode)
                    console.log('CheckProfileOnFirestore', error);
                await this.props.SetErrorLog(new Date(), "QuizHome | CheckProfileOnFirestore | Error = " + error);
            });
        await DelayUntil(() => done === true);

        // //2023.10.24
        // if (this.state.isProfileExists === false)
        //     this.CheckProfile_ViaApi();

        // return isEmailNotExist;
        return isProfileExist;
    }

    //2023.12.06 - revamp
    //2023.10.24
    CheckProfile_ViaApi = async () => {

        if (this.props.user !== null) {
            if (this.props.isDevMode)
                console.log('CheckProfile_ViaApi', 'checking...');

            //fetch profile frm api.
            let fetched_profile = null;
            await fetch(GlobalSetting.ApiUrl
                + 'Api/LearningCentre/User/Profile/Get/' + String(this.props.user.uid) + '/0',
                // Api/LearningCentre/User/Profile/Get/{firebaseUserId}/{organizerId}
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(res => res.json())
                .then(data => {
                    // if (this.props.isDevMode)
                    //     console.log('CheckProfile_ViaApi (json)', JSON.stringify(data));
                    if (data.success) {
                        fetched_profile = data.data !== null && data.data !== undefined ? data.data : null;
                        // if (CheckObjectNullValue(data, 'data') !== null) {
                        //     // fetched_profile = {};
                        //     // fetched_profile['Uid'] = CheckObjectStringEmpty(data.data, 'firebaseUserId');
                        //     // fetched_profile['Email'] = CheckObjectStringEmpty(data.data, 'email');
                        //     // fetched_profile['PolicyTncAgreeDateTime'] = CheckObjectStringEmpty(data.data, 'policyTncAgreeDateTime');
                        //     // fetched_profile['LastUpdate'] = CheckObjectStringEmpty(data.data, 'lastUpdatedOnUtc');

                        //     //2023.11.15
                        //     fetched_profile = data.data;
                        //     // fetched_profile = ApiProfileToProfileModal(data.data);
                        // }
                    }
                    else {
                        if (this.props.isDevMode)
                            console.log('Error', 'api - fetch profile (failed)\n' + JSON.stringify(data));
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log('Error', 'api - fetch profile (failed)\n' + error.message);
                });

            if (fetched_profile !== null) {
                // console.log('CheckProfile_ViaApi (json)', JSON.stringify(fetched_profile));
                fetched_profile = CapitalizeJsonKeys(fetched_profile);
                // console.log('CheckProfile_ViaApi (CapitalizeJsonKeys)', JSON.stringify(fetched_profile));

                let { profile, isUpdateToFirestoreNeeded } = Profile_ValidateProperties(this.props.user, fetched_profile);
                this.setState({
                    isProfileExists: profile !== null,
                    profileFetched: profile,
                    cachedProfile: JSON.parse(JSON.stringify(profile)),
                });
                if (isUpdateToFirestoreNeeded) {
                    await this.UpdateProfileOnFirebase(false);
                    await this.UpdateToProfileBackUp();
                }

                //2024.05.15
                const policyTncAgree = CheckObjectBoolean(profile, 'PolicyTncAgree');
                if (policyTncAgree)
                    this.props.SetIsPolicyTncStateTheLatest(true);
            }
            else {
                this.setState({
                    isProfileExists: false,
                    profileFetched: null,
                    cachedProfile: null,
                });
            }
        }
    }

    //FS & RTDB.
    UpdateProfileOnFirebase = async (syncToBaseDB = true) => {
        // alert(JSON.stringify(this.state.cachedProfile)); 

        this.SetAlertWithProgressBar(Locale("profile-update-in-progress", this.props.Locale),
            Locale("wait-patiently", this.props.Locale), true);

        //2020.11.28
        let currentMoment = moment();
        let currentMomentUTC = currentMoment.utc();   //moment(); //2021.02.02
        let currentDateUTC = currentMomentUTC.format("YYYY-MM-DD HH:mm:ss");
        let currentDateLocal = currentMoment.format("YYYY-MM-DD HH:mm:ss");

        //2021.09.13 - add retry.
        let retryAgain = false;
        let done = false;

        //2023.10.19
        let { profile } = Profile_ValidateProperties(this.props.user, this.state.cachedProfile);
        profile['LastUpdate'] = currentDateUTC;
        this.setState({ cachedProfile: profile, });

        //Save to FireStore.
        if (this.state.isProfileExists) {
            let async_action_1 = async () => {
                await this.props.firestore
                    .collection("User")
                    .doc(String(this.props.user.uid))
                    .set(profile)   //2023.10.19
                    // .set({
                    //     LastUpdate: currentDateUTC,
                    //     Name: CheckObjectStringEmpty(this.state.cachedProfile, 'Name'),
                    //     Grade: CheckObjectStringEmpty(this.state.cachedProfile, 'Grade'),
                    //     Center: CheckObjectStringEmpty(this.state.cachedProfile, 'Center'),
                    //     Score: this.state.cachedProfile.Score,
                    //     Mode: CheckObjectStringEmpty(this.state.cachedProfile, 'Mode'),
                    //     ICNo: CheckObjectStringEmpty(this.state.cachedProfile, 'ICNo'),
                    //     Email: CheckObjectStringEmpty(this.state.cachedProfile, 'Email'),
                    //     Contact: CheckObjectStringEmpty(this.state.cachedProfile, 'Contact'),      //2020.11.21

                    //     //2020.11.26
                    //     Guardian: CheckObjectStringEmpty(this.state.cachedProfile, 'Guardian'),
                    //     School: CheckObjectStringEmpty(this.state.cachedProfile, 'School'),
                    //     PolicyTncAgree: this.state.cachedProfile.PolicyTncAgree,
                    //     PolicyTncAgreeDateTime: CheckObjectStringEmpty(this.state.cachedProfile, 'PolicyTncAgreeDateTime'),
                    //     // PolicyTncAgreeDateTime: moment(this.state.cachedProfile.PolicyTncAgreeDateTime).utc().format("YYYY-MM-DD HH:mm:ss"),    //2022.11.04
                    //     NationalState: CheckObjectStringEmpty(this.state.cachedProfile, 'NationalState'),

                    //     //2020.11.28
                    //     Below13: this.state.cachedProfile.Below13,

                    //     //2020.12.03
                    //     // SimStudent: this.state.cachedProfile.SimStudent,

                    //     //2020.12.15
                    //     Uid: CheckObjectStringEmpty(this.state.cachedProfile, 'Uid'),

                    //     //2021.07.30
                    //     Gender: CheckObjectStringEmpty(this.state.cachedProfile, 'Gender'),
                    //     Race: CheckObjectStringEmpty(this.state.cachedProfile, 'Race'),
                    //     // Religion: this.state.cachedProfile.Religion,

                    //     //2021.10.12
                    //     DistrictArea: CheckObjectStringEmpty(this.state.cachedProfile, 'DistrictArea'),

                    //     //2023.06.14
                    //     Classroom: CheckObjectStringEmpty(this.state.cachedProfile, 'Classroom'),
                    // })
                    .then(() => {
                        done = true;
                    })
                    .catch((error) => {
                        // await this.props.SetErrorLog(new Date(), "QuizHome | UpdateProfileOnFirebase | FS | Error = " + error);
                        retryAgain = true;
                        done = true;
                    });
                await DelayUntil(() => done === true);
            }

            do {
                retryAgain = false;
                done = false;
                await async_action_1();
                // await DelayUntil(() => done === true);
            } while (retryAgain);
        }

        //2023.10.31 - obsolete profile location, isRealtimeProfileExists will always be false.
        //Save to RTDB.
        // let ignoreLegacyMethod = true;
        // if (ignoreLegacyMethod) {
        //     await this.props.dbCommon.ref("Common/" + this.props.user.uid + "/_0LastUpdate")
        //         .set(currentDate)
        //         .catch(async (error) => {
        //             await this.props.SetErrorLog(new Date(), "QuizHome | UpdateProfileOnFirebase | RTDB | Error = " + error);
        //         });
        // }
        // else {
        if (this.state.isRealtimeProfileExists) {
            let async_action_2 = async () => {
                await this.props.dbCommon.ref("Common/" + String(this.props.user.uid))
                    .set({
                        _0LastUpdate: currentDateUTC,
                        _1Name: CheckObjectStringEmpty(this.state.cachedProfile, 'Name'),
                        _2Grade: CheckObjectStringEmpty(this.state.cachedProfile, 'Grade'),
                        _3Center: CheckObjectStringEmpty(this.state.cachedProfile, 'Center'),
                        _4Score: CheckObjectNumber(this.state.cachedProfile, 'Score'),
                        _5Mode: CheckObjectStringEmpty(this.state.cachedProfile, 'Mode'),
                        _6ICNo: CheckObjectStringEmpty(this.state.cachedProfile, 'ICNo'),
                        _7Email: CheckObjectStringEmpty(this.state.cachedProfile, 'Email'),
                        _8Contact: CheckObjectStringEmpty(this.state.cachedProfile, 'Contact'),        //2020.11.21

                        //2020.11.26
                        _9Guardian: CheckObjectStringEmpty(this.state.cachedProfile, 'Guardian'),
                        _10School: CheckObjectStringEmpty(this.state.cachedProfile, 'School'),
                        _11PolicyTncAgree: this.state.cachedProfile.PolicyTncAgree,
                        _12PolicyTncAgreeDateTime: CheckObjectStringEmpty(this.state.cachedProfile, 'PolicyTncAgreeDateTime'),
                        _13NationalState: CheckObjectStringEmpty(this.state.cachedProfile, 'NationalState'),

                        //2020.11.28
                        _14Below13: this.state.cachedProfile.Below13,

                        //2020.12.03
                        // _15SimStudent: this.state.cachedProfile.SimStudent,

                        //2020.12.15
                        _15Uid: CheckObjectStringEmpty(this.state.cachedProfile, 'Uid'),

                        //2021.07.30
                        _16Gender: CheckObjectStringEmpty(this.state.cachedProfile, 'Gender'),
                        _17Race: CheckObjectStringEmpty(this.state.cachedProfile, 'Race'),
                        // _18Religion: this.state.cachedProfile.Religion,

                        //2021.10.12
                        _18DistrictArea: CheckObjectStringEmpty(this.state.cachedProfile, 'DistrictArea'),

                        //2023.06.14
                        _19Classroom: CheckObjectStringEmpty(this.state.cachedProfile, 'Classroom'),
                    })
                    .then(() => {
                        done = true;
                    })
                    .catch((error) => {
                        // await this.props.SetErrorLog(new Date(), "QuizHome | UpdateProfileOnFirebase | RTDB | Error = " + error);
                        retryAgain = true;
                        done = true;
                    });
                await DelayUntil(() => done === true);
            }
            do {
                retryAgain = false;
                done = false;
                await async_action_2();
                // await DelayUntil(() => done === true);
            } while (retryAgain);
        }

        //2020.11.28
        if (
            //2024.05.23 - no more policyTnc checking needed by Calvin request.
            this.state.policyTnc_enableChecking &&

            // this.getProfile(Profile.PolicyTncAgree)

            //2020.12.02
            this.state.policyTnc_isNewAgree && this.getProfile(Profile.PolicyTncAgree)
        ) {
            //Save to user's Policy Tnc agreement (Root).
            let async_action_3 = async () => {
                await this.props.firestore.collection('LiveQuizPolicyTncAgreement')
                    .doc(String(this.props.user.uid))
                    .set({
                        LastUpdate: currentDateLocal,   //currentDateUTC,
                        Uid: String(this.props.user.uid),
                        Version: this.state.policyTncVersion,
                    })
                    .then(() => {
                        done = true;
                    })
                    .catch((error) => {
                        // await this.props.SetErrorLog(new Date(), "QuizHome | UpdateProfileOnFirebase | Save Policy | User | Error = " + error);
                        retryAgain = true;
                        done = true;
                    });
                await DelayUntil(() => done === true);
            }
            do {
                retryAgain = false;
                done = false;
                await async_action_3();
                // await DelayUntil(() => done === true);
            } while (retryAgain);

            //Save to user's Policy Tnc agreement List.
            let async_action_4 = async () => {
                await this.props.firestore.collection('LiveQuizPolicyTncAgreement')
                    .doc(String(this.props.user.uid))
                    .collection('List')
                    .doc(currentMomentUTC.format('YYYYMMDDHHmmss'))
                    .set({
                        DateTime: currentDateLocal,     //currentDateUTC,
                        Version: this.state.policyTncVersion,
                    })
                    .then(() => {
                        done = true;
                    })
                    .catch((error) => {
                        // await this.props.SetErrorLog(new Date(), "QuizHome | UpdateProfileOnFirebase | Save Policy | User (List) | Error = " + error);
                        retryAgain = true;
                        done = true;
                    });
                await DelayUntil(() => done === true);
            }
            do {
                retryAgain = false;
                done = false;
                await async_action_4();
                // await DelayUntil(() => done === true);
            } while (retryAgain);

            //save state.
            this.setState({
                policyTncVersion_User: this.state.policyTncVersion,
                policyTncVersion_User_AgreeDate: currentDateUTC,
            });
        }

        //done. update local profile.
        await this.props.SetProfile(this.state.cachedProfile);

        //2022.06.03
        //trigger profile sync to db.
        if (syncToBaseDB)
            await this.SyncUserProfileToBaseDB_ViaAPI();

        //close popups.
        this.props.CloseAlert();
        if (this.state.toggleEditProfileUi) {   //close Edit Profile if opened.
            this.ToggleEditProfileUi();
            await Delay(500);
            this.ToggleEditProfileUi();
        }

        // setTimeout(() => {
        //     alert(this.props.profile.Below13);
        // }, 1000);


        //2021.01.14    //disabled as the event has already over.
        // //2020.12.09
        // if (moment().format('YYYYMMDD') <= moment('2020-12-11').format('YYYYMMDD')) {
        //     setTimeout(async () => {
        //         // this.ShowEventDetails(this.state.UpcomingQuizList[0].Date,
        //         //     this.state.UpcomingQuizList[0].Event,
        //         //     this.state.UpcomingQuizList[0].Organizer);

        //         //2020.12.15
        //         await this.ShowEventDetails(this.state.UpcomingQuizList[0].EventCode);
        //     }, 1000);
        // }
    }

    //api - TriggerToSyncUserProfileV2.
    SyncUserProfileToBaseDB_ViaAPI = async () => {
        const failedMsg = 'home \n api \n profile \n sync (failed). | ';
        await fetch(GlobalSetting.ApiUrl
            + 'Api/LearningCentre/User/Registration/Profile/Sync',
            // 'Api/LearningCentre/User/Profile/Sync',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    FirebaseUserId: String(this.props.user.uid),
                    OrganizerIdentity: null,
                    EventCode: null,            //for registration only.
                    CenterUserId: null,         //for sync event setting.
                    AuthorId: null,             //for sync event setting.
                    RegistrationDate: null,     //for registration only.

                    //2024.05.06
                    Email: this.props.email,
                })
            })
            .then(res => res.json())
            .then((data) => {
                if (!data.success)
                    if (this.props.isDevMode)
                        console.log(failedMsg + JSON.stringify(data));
            })
            .catch(error => {
                if (this.props.isDevMode)
                    console.log(failedMsg + error.message);
            });
    }

    //#endregion ==== Sync & Update - Profile ====

    //#region ==== Quiz History List ====

    // LoadQuizHistoryFromLocal = async () => {
    //     // let historyInJson = localStorage.getItem("liveQuizHistoryList");
    //     // alert(historyInJson.length);

    //     let historyInJson = null;
    //     if (this.props.historyList.length <= 0) {
    //         historyInJson = localStorage.getItem("LiveQuizHistoryList");
    //         // historyInJson = localStorage.getItem("LiveQuizHistoryList_" + this.props.user.uid);
    //     }
    //     else {
    //         historyInJson = JSON.stringify(this.props.historyList);
    //     }
    //     // alert(historyInJson);

    //     this.setState({
    //         isHistoryExists: historyInJson != null ? true : false,
    //     },
    //         () => {
    //             // alert(saveLogin + " " + this.state.saveLoginCredential)
    //             if (this.state.isHistoryExists) {
    //                 let historyInLocal = JSON.parse(historyInJson);
    //                 let tempList = [];
    //                 historyInLocal.map((data, key) => {
    //                     return tempList.push(data);
    //                 });
    //                 // alert(JSON.stringify(tempList));

    //                 //Sorted list by Date desc, latest on top.
    //                 tempList.sort(function (a, b) {
    //                     // if (a.RoomId < b.RoomId) {
    //                     //     return 1;
    //                     // }
    //                     // if (a.RoomId > b.RoomId) {
    //                     //     return -1;
    //                     // }
    //                     if (moment(a.Date) < moment(b.Date)) {
    //                         return 1;
    //                     }
    //                     if (moment(a.Date) > moment(b.Date)) {
    //                         return -1;
    //                     }
    //                     return 0;
    //                 });

    //                 this.setState({
    //                     historyListInQuizHome: tempList,
    //                     toggleHistoryList: true,
    //                 }, () => {
    //                     //Save history list to App.js
    //                     this.props.SetHistoryList(this.state.historyListInQuizHome);

    //                     // this.props.editHistory();
    //                 });
    //             }
    //         });
    // }

    // EnterHistoryRoom = async (id) => {
    //     // alert("Enter Room #" + (id + 1) + " (" + this.props.historyList[id].RoomCode + ")");
    //     // this.SetAlertWithProgressBar("Entering Room #" + (id + 1) + " (" + this.props.historyList[id].RoomCode + ")...", "Please wait for awhile.", true);
    //     this.SetAlertWithProgressBar(Locale("entering-room", this.props.Locale) + (id + 1) + " (" + this.props.historyList[id].RoomCode + ")...",
    //         Locale("please-wait", this.props.Locale), true);

    //     //Update Room Code & Room Id.
    //     this.props.setRoomCodeId(this.props.historyList[id].RoomCode, this.props.historyList[id].RoomTimeStampId);

    //     //Check if Viewing history Quiz.
    //     let quizDate = moment(this.props.historyList[id].Date);

    //     if (moment().format("YYYYMMDD") > quizDate.format("YYYYMMDD"))
    //         this.props.SetViewHistoryQuiz(quizDate.format("YYYYMMDD"));
    //     else
    //         this.props.SetViewHistoryQuiz(null);

    //     // alert(moment().toString() + "\n\n" + quizDate.toString() + "\n\n" + (moment() > quizDate));

    //     //2021.04.06
    //     await this.props.CheckIfRoomBelongsToEvent(this.props.historyList[id].RoomCode);
    //     // { Result: isFound, EventCode: eventCode, EventModal: eventModal };

    //     setTimeout(() => {
    //         this.props.CloseAlert();
    //         // if (this.props.historyList[id].hasOwnProperty('RoomType')) {
    //         //     switch (this.props.historyList[id].RoomType) {
    //         //         default: this.GotoQuizLive(); break;
    //         //         case 1:
    //         //             this.setState({
    //         //                 redirectLink: '/quiz/roomTypeUploadFile',
    //         //                 redirect: true,
    //         //             });
    //         //             break;
    //         //     }
    //         // }
    //         // else {
    //         //     this.GotoQuizLive();
    //         // }
    //         this.GotoQuizLive();
    //     }, 1000);
    // }

    //#endregion ==== Quiz History List ====

    GotoAdminPage = () => {
        this.setState({
            redirectLink: "/admin",
            // redirectLink: "/admin/quiz/management",            
            redirect: true,
        });
    }

    //#region ==== Edit Profile ====

    //2023.12.06
    CheckProfileEditPermissionAllowed = (alert = true) => {
        const { allowed, organizerNameList } = this.Get_ProfileEditPermissionAllowed_And_OrganizerList();
        if (allowed === false && alert) {
            this.props.SetAlert(
                Locale('title-invalid-operation', this.props.Locale),
                Locale('warning-profile-under-maintain-by-organizer', this.props.Locale)
                + (organizerNameList.length === 0 ? '' :
                    '<br /><br />' + Locale('organizers', this.props.Locale) + ':<br />' + organizerNameList.join('<br />'))
            );
        }
        // if (allowed === null)
        //     allowed = true;
        // this.setState({ ProfileEditPermissionAllowed: allowed, ProfileLinkedOrganizers: organizerList, });
        return allowed;
    }

    //2024.01.31
    Get_ProfileEditPermissionAllowed_And_OrganizerList = () => {
        let organizerNameList = this.state.ProfileLinkedOrganizersNameList;
        let organizerList = this.state.ProfileLinkedOrganizers;
        let allowed = this.state.ProfileEditPermissionAllowed;
        if (allowed === null) {
            allowed = true;
            if (CheckObjectNullValue(this.props.profile, 'OrganizerMappings') !== null) {
                const mappings = this.props.profile.OrganizerMappings;
                if (Array.isArray(mappings)) {
                    if (mappings.length > 0) {
                        organizerList = [];
                        organizerNameList = [];
                        for (let o = 0; o < mappings.length; o++) {
                            organizerList.push({
                                Id: CheckObjectNumber(mappings[o], 'Id'),
                                OrganizerId: CheckObjectNumber(mappings[o], 'OrganizerId'),
                                Name: CheckObjectStringEmpty(mappings[o], 'OrganizerDisplayName', '-N/A-'),
                            });
                            organizerNameList.push('(' + (o + 1) + ') ' + CheckObjectStringEmpty(mappings[o], 'OrganizerDisplayName', '-N/A-'));
                        }
                        allowed = false;
                    }
                }
            }
            this.setState({ ProfileEditPermissionAllowed: CheckBoolean(allowed), ProfileLinkedOrganizers: organizerList, ProfileLinkedOrganizersNameList: organizerNameList });
        }
        return { allowed: CheckBoolean(allowed), organizerList, organizerNameList };
    }

    ToggleEditProfileUi = () => {

        //2023.12.06
        this.CheckProfileEditPermissionAllowed(false);

        this.setState({
            // cachedProfile: this.props.profile,
            cachedProfile: Object.assign({}, this.props.profile),
            // cachedProfile: Object.assign({}, (Object.keys(this.state.cachedProfile) > 0 ? this.state.cachedProfile : this.props.profile)),
            toggleEditProfileUi: !this.state.toggleEditProfileUi,

            isNameEmpty: false,
            isContactNumberEmpty: false,
            isNationalStateUnselected: false,
            isGradeUnselected: false,
            isGuardianNameEmpty: false,
        }, async () => {
            if (this.state.toggleEditProfileUi) {
                await this.UpdateSchoolSelectHeight();

                //2023.12.06
                this.CheckProfileEditPermissionAllowed();
            }
        });
    }

    ResetProfile = () => {

        // let _cachedProfile = this.state.cachedProfile;
        // _cachedProfile.Name = this.props.profile.Name;

        this.setState({
            cachedProfile: Object.assign({}, this.props.profile),
            // cachedProfile: this.props.profile,
            // cachedProfile: _cachedProfile,
            // gradeSelection: this.getGradeOptionText('Standard 5'),
            gradeSelection: this.props.profile.Grade,
            toggleShowHide_GuardianNameField: this.props.profile.Below13,
        });
    }

    //2020.11.28
    ProfileEditValidation = async () => {

        //2023.12.06
        const allowed = this.CheckProfileEditPermissionAllowed();
        if (allowed === false)
            return null;

        let isValid = await this.ProfileEditedDataValidation();

        if (isValid) {
            await this.UpdateProfileOnFirebase();
            await this.UpdateToProfileBackUp();
        }
        else {
            // ScrollToElement('ref_EditProfileUi');
            // this.ref_EditProfileUi.current.scrollTop = 0;
            // ReactDOM.findDOMNode(this.ref_EditProfileUi).scrollTop = 0;

            // this.Toggle_ProfileInfoRequireUpdate_AlertModal();
            this.SetAlert(Locale("invalid-info", this.props.Locale), this.state.errorMessage);
        }

    }

    //2020.12.03
    ProfileEditedDataValidation = async () => {

        // alert(JSON.stringify(this.state.cachedProfile));

        //2021.04.17
        this.setState({
            errorMessage: '',
            isNameEmpty: false,
            isContactNumberEmpty: false,
            isNationalStateUnselected: false,
            isGradeUnselected: false,
            isGuardianNameEmpty: false,
            isDistrictAreaUnselected: false,    //2021.10.12
        });

        let isValid = true;

        //#region old code
        // // let isEmailEmpty = this.state.cachedProfile.Email.length <= 0;
        // let _isNameEmpty = this.state.cachedProfile.Name.length <= 0;
        // let _isContactNumberEmpty = this.state.cachedProfile.Contact.length <= 0;
        // let _isNationalStateUnselected = this.state.cachedProfile.NationalState.length <= 0;
        // // let isSchoolNameEmpty = this.state.cachedProfile.School.length <= 0;    //2021.01.05 - school name not compulsory
        // // let isCenterEmpty = this.state.cachedProfile.Center.length <= 0;
        // let _isGradeUnselected = this.state.cachedProfile.Grade.length <= 0;
        // let _isGuardianChecked = this.state.toggleShowHide_GuardianNameField;
        // let _isGuardianNameEmpty = _isGuardianChecked ? this.state.cachedProfile.Guardian.length <= 0 : false;
        // // let isSimStudentNotSelected = this.state.cachedProfile.SimStudent === 'Not Specify' || this.state.cachedProfile.SimStudent.length <= 0;
        // let _isDistrictAreaUnselected = false;  //this.state.cachedProfile.DistrictArea.length <= 0;  //2021.10.12
        //#endregion

        //2023.10.19
        let _isNameEmpty = CheckObjectStringEmpty(this.state.cachedProfile, 'Name').length <= 0;
        let _isContactNumberEmpty = CheckObjectStringEmpty(this.state.cachedProfile, 'Contact').length <= 0;
        let _isNationalStateUnselected = CheckObjectStringEmpty(this.state.cachedProfile, 'NationalState').length <= 0;
        let _isGradeUnselected = CheckObjectStringEmpty(this.state.cachedProfile, 'Grade').length <= 0;
        let _isGuardianChecked = this.state.toggleShowHide_GuardianNameField;
        let _isGuardianNameEmpty = _isGuardianChecked ? CheckObjectStringEmpty(this.state.cachedProfile, 'Guardian').length <= 0 : false;
        let _isDistrictAreaUnselected = false;  //CheckObjectStringEmpty(this.state.cachedProfile, 'DistrictArea').length <= 0;  //2021.10.12

        if (
            _isNameEmpty

            //2023.12.08 - not compulsory anymore.
            // || _isContactNumberEmpty 

            || _isNationalStateUnselected
            || _isGradeUnselected

            //2023.12.08 - not compulsory anymore.
            // || (_isGuardianChecked && _isGuardianNameEmpty)

            || _isDistrictAreaUnselected    //2021.10.12
            // || isSimStudentNotSelected
            // || isSchoolNameEmpty    //2021.01.05 - school name not compulsory
        ) {
            isValid = false;
        }

        //for testing.
        // isValid = false;

        let msg = "";

        if (_isNameEmpty) {
            // msg += "Please do not leave your <b>Name</b> empty.<br />";
            msg += Locale("name-not-empty", this.props.Locale) + "<br /><br />";
        }

        //2023.12.08 - not compulsory anymore.
        // if (_isContactNumberEmpty) {
        //     // msg += "Please do not leave your <b>Contact Number</b> empty.<br />";
        //     msg += Locale("contact-number-not-empty", this.props.Locale) + "<br /><br />";
        // }

        if (_isGradeUnselected) {
            // msg += "Please do not leave your <b>Grade</b> unselected.<br />";
            msg += Locale("grade-not-selected", this.props.Locale) + "<br /><br />";
        }

        if (_isNationalStateUnselected) {
            // msg += "Please do not leave your <b>National State</b> unselected.<br />";
            msg += Locale("national-state-not-selected", this.props.Locale) + "<br /><br />";
        }

        //2021.01.05 - school name not compulsory
        // if (isSchoolNameEmpty) {
        //     // msg += "Please do not leave your <b>School Name</b> empty.<br />";
        //     msg += Locale("school-name-not-empty", this.props.Locale) + "<br /><br />";
        // }

        //2023.12.08 - not compulsory anymore.
        // if (_isGuardianNameEmpty) {
        //     // msg += "Upon checked on the <b><u>12 years old and below</u></b> option, please do not leave your <b>Guardian Name</b> empty.<br />";
        //     msg += Locale("guardian-name-not-empty", this.props.Locale) + "<br /><br />";
        // }

        // if (isSimStudentNotSelected) {
        //     // msg += "Please do not leave your <b>Are you a SIM Student ?</b> unselected.<br />";
        //     msg += Locale("sim-student-not-selected", this.props.Locale) + "<br /><br />";
        // }

        //2021.10.12
        if (_isDistrictAreaUnselected) {
            // msg += "Please do not leave your <b>District</b> unselected.<br />";
            msg += Locale("district-area-not-selected", this.props.Locale) + "<br /><br />";
        }

        this.setState({
            // errorMessage: msg,

            //2021.04.17
            errorMessage: '<span style="color:red;font-size:18px;"><b>' + msg + '</b></span>',
            isNameEmpty: _isNameEmpty,
            isContactNumberEmpty: _isContactNumberEmpty,
            isNationalStateUnselected: _isNationalStateUnselected,
            isGradeUnselected: _isGradeUnselected,
            isGuardianNameEmpty: _isGuardianNameEmpty,

            //2021.10.12
            isDistrictAreaUnselected: _isDistrictAreaUnselected,

            //for testing
            // isNameEmpty: true,
            // isContactNumberEmpty: true,
            // isNationalStateUnselected: true,
            // isGradeUnselected: true,
            // isGuardianNameEmpty: true,
        }, () => {
            //2021.10.12
            this.FilterByState_DistrictAreaList();
        });

        return isValid;
    }

    // GetGradeOptions = () => {
    //     // if (this.state.gradeOptions.length <= 0) {
    //     let temp = [];

    //     // temp.push(<Dropdown.Item as="button" eventKey={'Pre-School'}>{Locale("pre-school", this.props.Locale)}</Dropdown.Item>);
    //     temp.push({ KeyName: 'Pre-School', Label: Locale("pre-school", this.props.Locale) });

    //     [1, 2, 3, 4, 5, 6].map((stdIdx) => (
    //         // temp.push(<Dropdown.Item as="button" eventKey={'Standard ' + stdIdx}>{this.GetStandard(stdIdx)}</Dropdown.Item>)
    //         temp.push({ KeyName: 'Standard ' + stdIdx, Label: this.GetStandard(stdIdx) })
    //     ));

    //     [1, 2, 3, 4, 5, 6].map((stdIdx) => (
    //         // temp.push(<Dropdown.Item as="button" eventKey={'Form ' + stdIdx}>{this.GetForm(stdIdx)}</Dropdown.Item>)
    //         temp.push({ KeyName: 'Form ' + stdIdx, Label: this.GetForm(stdIdx) })
    //     ));

    //     // temp.push(<Dropdown.Item as="button" eventKey={'Other'}>{Locale("other", this.props.Locale)}</Dropdown.Item>);
    //     temp.push({ KeyName: 'Other', Label: Locale("other", this.props.Locale) });

    //     this.setState({
    //         gradeOptions: temp,
    //     }, () => {
    //         // alert(JSON.stringify(this.state.gradeOptions));
    //     });
    //     // }
    // }

    //2020.12.03
    PopulateGradeOptions = () => {

        //2023.11.03
        this.setState({
            gradeOptions: GradeOptions(this.props.Locale),
        });

        // let temp = [];

        // [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        //     temp.push({ value: 'Standard ' + stdIdx, label: this.GetStandard(stdIdx), id: stdIdx, })
        // ));

        // [1, 2, 3, 4, 5, 6].map((stdIdx) => (
        //     temp.push({ value: 'Form ' + stdIdx, label: this.GetForm(stdIdx), id: stdIdx + 10, })
        // ));

        // temp.push({ value: 'Other', label: Locale("other", this.props.Locale), id: 17, });

        // temp.push({ value: 'Pre-School', label: Locale("pre-school", this.props.Locale), id: 31, });

        // //2022.05.12
        // [1, 2, 3].map((stdIdx) => (
        //     temp.push({ value: 'KD ' + (stdIdx + 3), label: Locale("kindergarden", this.props.Locale) + ' ' + (stdIdx + 3) + ' (' + (stdIdx + 3) + Locale("years-old", this.props.Locale) + ')', id: stdIdx + 27, })
        // ));

        // this.setState({
        //     gradeOptions: temp,
        // }, () => {
        //     // alert(JSON.stringify(this.state.gradeOptions));
        // });
    }

    GetStandard = (idx) => {
        let text = "";
        if (this.props.Locale === Lang.Chinese)
            text = idx + " " + Locale("standard", this.props.Locale);
        else
            text = Locale("standard", this.props.Locale) + " " + idx;
        return text;
    }

    GetForm = (idx) => {
        let text = "";
        if (this.props.Locale === Lang.Chinese) {
            if (idx > 0 && idx < 4)
                text = Locale("form-123", this.props.Locale) + " " + idx + " (" + Locale("form", this.props.Locale) + idx + ")";
            else if (idx > 3 && idx < 6)
                text = Locale("form-45", this.props.Locale) + " " + (idx - 3) + " (" + Locale("form", this.props.Locale) + idx + ")";
            else if (idx === 6)
                text = Locale("form-6", this.props.Locale) + " (" + Locale("form", this.props.Locale) + idx + ")";
        }
        else {
            text = Locale("form", this.props.Locale) + " " + idx;
        }
        return text;
    }

    // SetProfile_NationalState = (event) => {
    //     let _cachedProfile = this.state.cachedProfile;
    //     _cachedProfile.NationalState = event.value;
    //     this.setState({
    //         cachedProfile: _cachedProfile,
    //     });
    // }

    handleSetProfile = (profileOption, val) => {
        let _policyTnc_isNewAgree = this.state.policyTnc_isNewAgree;    //2020.12.02
        let _cachedProfile = this.state.cachedProfile;
        let _gradeSelection = this.state.gradeSelection;
        switch (profileOption) {
            case Profile.Name:
                _cachedProfile.Name = CheckStringEmpty(val.target.value);
                break;
            case Profile.Contact:   //2020.11.21
                _cachedProfile.Contact = CheckStringEmpty(val.target.value);
                break;
            case Profile.Center:
                _cachedProfile.Center = CheckStringEmpty(val.target.value);
                break;
            // case Profile.Grade:
            //     cachedProfile.Grade = val;
            //     gradeSelection = val;
            //     break;

            //2020.12.03
            case Profile.Grade:
                _cachedProfile.Grade = CheckStringEmpty(val.value);
                _gradeSelection = CheckStringEmpty(val.value);
                // console.log('Grade', _gradeSelection, _cachedProfile.Grade);
                break;

            //2020.11.26
            case Profile.Guardian:
                _cachedProfile.Guardian = CheckStringEmpty(val.target.value);
                break;
            case Profile.School:
                _cachedProfile.School = CheckStringEmpty(val.value);
                break;
            case Profile.PolicyTncAgree:
                // let option = String(val.target.value) === 'on' ? true : false;
                // let option = CheckStringEmpty(val).toLowerCase() === 'on' ? true : false;
                const option = CheckBoolean(val);
                if (option) {
                    // this.handleSetProfile(Profile.PolicyTncAgreeDateTime, '');
                    _cachedProfile.PolicyTncAgreeDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
                }
                _cachedProfile.PolicyTncAgree = String(option);
                _policyTnc_isNewAgree = true;
                break;
            case Profile.PolicyTncAgreeDateTime:
                _cachedProfile.PolicyTncAgreeDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
                break;
            case Profile.NationalState:
                _cachedProfile.NationalState = CheckStringEmpty(val.value);
                break;

            //2020.11.28
            case Profile.Below13:
                // alert(val);
                // _cachedProfile.Below13 = val.target.value;
                // cachedProfile.Below13 = String(val.target.value) === 'on' ? true : false;
                _cachedProfile.Below13 = val;   //2020.12.09
                break;

            //2020.12.03
            // case Profile.SimStudent:
            //     _cachedProfile.SimStudent = val.value;
            //     break;

            //2021.07.30
            case Profile.Gender:
                _cachedProfile.Gender = CheckStringEmpty(val.value);
                break;
            case Profile.Race:
                _cachedProfile.Race = CheckStringEmpty(val.value);
                break;
            // case Profile.Religion:
            //     _cachedProfile.Religion = val.value;
            //     break;

            //2021.10.12
            case Profile.DistrictArea:
                _cachedProfile.DistrictArea = CheckStringEmpty(val.value);
                break;

            //2023.06.14
            case Profile.Classroom:
                _cachedProfile.Classroom = CheckStringEmpty(val.target.value);
                break;

            default:
                break;
        }
        this.setState({
            cachedProfile: _cachedProfile,
            gradeSelection: _gradeSelection,
            policyTnc_isNewAgree: _policyTnc_isNewAgree,    //2020.12.02
        }, async () => {
            //2021.10.12
            if (profileOption === Profile.NationalState) {
                this.FilterByState_DistrictAreaList(true);
            }
            //2023.10.25
            else if (profileOption === Profile.School) {
                await this.UpdateSchoolSelectHeight();
            }

            if (this.props.isDevMode)
                console.log('handleSetProfile', profileOption, JSON.stringify(this.state.cachedProfile));
        });
    }

    getProfile = (profileOption) => {
        switch (profileOption) {
            case Profile.Name:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Name');
            case Profile.Contact:      //2020.11.21
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Contact');
            case Profile.Center:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Center');
            case Profile.Grade:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Grade');

            //2020.11.26            
            case Profile.Guardian:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Guardian');
            case Profile.School:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'School');
            case Profile.NationalState:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'NationalState');
            case Profile.PolicyTncAgree:
                // return CheckBoolean(this.state.cachedProfile.PolicyTncAgree);
                return CheckObjectStringEmpty(this.state.cachedProfile, 'PolicyTncAgree').toLowerCase() === 'true' ? true : false;
            case Profile.PolicyTncAgreeDateTime:
                return moment.utc(this.state.cachedProfile.PolicyTncAgreeDateTime).local().format('lll');

            //2020.11.28
            case Profile.Below13:
                // return CheckBoolean(this.state.cachedProfile.Below13);
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Below13').toLowerCase() === 'true' ? true : false;

            //2020.12.03
            // case Profile.SimStudent:
            //     return this.state.cachedProfile.SimStudent;

            //2021.07.30
            case Profile.Gender:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Gender');
            case Profile.Race:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Race');
            // case Profile.Religion:
            //     return this.state.cachedProfile.Religion;

            //2021.10.12
            case Profile.DistrictArea:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'DistrictArea');

            //2023.06.14
            case Profile.Classroom:
                return CheckObjectStringEmpty(this.state.cachedProfile, 'Classroom');

            default:
                return "";
        }
        // this.setState({
        //     cachedProfile: this.state.cachedProfile,
        // });
    }

    //2023.10.25
    UpdateSchoolSelectHeight = async () => {
        await Delay(500);
        const com = document.getElementById('r-select-school-home');
        if (com !== null) {
            let height = 0;
            const child_value_container = com.querySelector('.r-select__control .r-select__value-container');
            const child_placeholder = com.querySelector('.r-select__control .r-select__value-container .r-select__placeholder');
            // console.log(child_placeholder.innerHTML);            //value.
            // console.log(child_placeholder.clientHeight);         //component height.
            if (child_placeholder !== undefined && child_placeholder !== null && child_placeholder.classList.contains('r-select__placeholder'))
                height = Number(child_placeholder.clientHeight);
            if (height > 0)
                if (child_value_container !== undefined && child_value_container !== null && child_value_container.classList.contains('r-select__value-container'))
                    child_value_container.style.height = height + 'px';
        }
    }

    //2020.12.03
    getOptionText = (text) => {
        switch (String(text).toLowerCase()) {
            default: return Locale("not-specify-yes-no", this.props.Locale);
            case 'yes': return Locale("yes", this.props.Locale);
            case 'no': return Locale("no", this.props.Locale);
        }
    }

    //2020.12.03
    getGradeOptionText = (text = '') => {
        text = CheckStringEmpty(text).toLowerCase();
        let _texts = CheckStringEmpty(text).split(' ');
        // alert(_texts);
        let _key = _texts[0];
        // let _idx = _texts[1].toLowerCase();
        let _idx = !text.includes('pre-school') || !text.includes('other') ? String(_texts[1]).toLowerCase() : '0';
        let result = '';
        switch (_key) {
            case 'pre-school': result = Locale("pre-school", this.props.Locale); break;
            case 'other': result = Locale("other", this.props.Locale); break;
            case 'standard': result = this.GetStandard(_idx); break;
            case 'form': result = this.GetForm(_idx); break;
            case 'kd': result = Locale("kindergarden", this.props.Locale) + ' ' + _idx + ' (' + _idx + Locale("years old", this.props.Locale) + ')'; break;
            default: result = Locale("grade", this.props.Locale); break;
        }
        // console.log('group = ' + result);
        return result;
    }

    //2020.12.15
    getGroupAbacusText = (text) => {
        switch (String(text).toLowerCase()) {
            default: return Locale("not-specify-group", this.props.Locale);
            case 'group a': return Locale("group-a-abacus", this.props.Locale);
            case 'group b': return Locale("group-b-abacus", this.props.Locale);
            case 'group c': return Locale("group-c-abacus", this.props.Locale);
            case 'group d': return Locale("group-d-abacus", this.props.Locale);
        }
    }

    //2020.12.02
    handleSetAgreeOnPolicyTnc = (checked = false) => {

        this.SetAlertWithProgressBar(Locale("profile-update-in-progress", this.props.Locale),
            Locale("wait-patiently", this.props.Locale), true);

        // this.SetAlert('', JSON.stringify(val.target.value));

        setTimeout(async () => {
            if (this.state.isProfileSyncDone) {
                this.setState({
                    // cachedProfile: Object.assign({}, this.props.profile),
                    cachedProfile: this.props.profile,
                }, async () => {

                    this.handleSetProfile(Profile.PolicyTncAgree, checked);

                    // this.SetAlert('', val + "<br /><br />" + JSON.stringify(this.state.cachedProfile));

                    // //save PolicyTncState via api.      //2024.05.15
                    // await this.SavePolicyTncState_ViaApi(checked, utcNow);

                    await this.UpdateProfileOnFirebase();
                    await this.UpdateToProfileBackUp();         //2023.10.24

                    this.props.SetIsPolicyTncStateTheLatest(this.getProfile(Profile.PolicyTncAgree));

                    this.props.CloseAlert();

                    //2020.12.05
                    let isValid = await this.ProfileEditedDataValidation();
                    if (!isValid) {
                        this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                    }
                });
            }
            else {
                await this.props.SetErrorLog(new Date(), "QuizHome | handleSetAgreeOnPolicyTnc | Save Policy | Error = Profile failed to update.");
                this.props.CloseAlert();    //error
            }
        }, 1000);
    }

    // //2024.05.15
    // SavePolicyTncState_ViaApi = async (checked = false, utc = '') => {

    // }

    //2021.10.12
    FilterByState_DistrictAreaList = (reset = false) => {
        if (this.state.cachedProfile !== null) {
            let _cachedProfile = this.state.cachedProfile;
            if (reset)
                _cachedProfile.DistrictArea = '';
            this.setState({
                filteredByState_DistrictAreaList: [],
                cachedProfile: _cachedProfile,
            }, () => {
                let findIndex = District.findIndex(x => x.State === this.state.cachedProfile.NationalState);
                if (findIndex > -1) {
                    let districtArea = District[findIndex].Area;
                    let _areas = [];
                    districtArea.map((data, key) => {
                        return _areas.push({ value: data, label: data });
                    });
                    this.setState({
                        filteredByState_DistrictAreaList: _areas,
                    });
                }
            });
        }
    }

    //#endregion ==== Edit Profile ====

    //#region ==== Check on Button Click within HTML ====

    CallbackForClickOnRoomCode = (e) => {
        let _id = String(e.target.id);
        if (_id.includes('groupRoomCode')) {
            let _roomCode = _id.includes('_') ? _id.split('_')[1] : '0';
            if (Number(_roomCode) > 0) {
                this.setState({
                    quizRoomCode: _roomCode,
                });
                this.Toggle_LiveQuiz_EventDetail_AlertModal();
                setTimeout(() => {
                    this.ToggleQuizModal();
                }, 500);
            }
        }
    }

    //#endregion ==== Check on Button Click within HTML ====

    Goto_ParticipatedEventList = () => {
        this.setState({
            redirectLink: "/participatedEventList",
            redirect: true,
        });
    }

    ShowUniqueEventCardElement = (_uniqueEventCode) => {
        if (this.state.UpcomingQuizList.length > 0) {
            let _eventModal = null;
            this.state.UpcomingQuizList.map((data, key) => {
                if (data.EventCode === String(_uniqueEventCode))
                    _eventModal = data;
                return null;
            });
            // alert(_uniqueEventCode + '\n\n' + JSON.stringify(_eventModal));
            if (_eventModal !== null) {
                if (_eventModal.HasJoinedEvent !== undefined) {
                    // alert(_eventModal.HasJoinedEvent);
                    if (_eventModal.HasJoinedEvent === true) {
                        //Not Yet Join the Event.
                        return (
                            <>
                                <div className="card">
                                    <article className="card-body" style={{ padding: 10 }}>

                                        <button type="button" className="border-0"
                                            style={{
                                                backgroundColor: 'transparent',
                                                width: '100%',
                                                padding: 0,
                                                // paddingLeft: 0,
                                                // paddingRight: 0,
                                                margin: 0,
                                            }}
                                            // onClick={this.ToggleQuizModal}
                                            // onClick={this.Goto_ParticipatedEventList}
                                            disabled={!this.state.isProfileSyncDone}
                                            onClick={async () =>
                                                await this.ShowEventDetails(_eventModal.EventCode)
                                            }
                                        >
                                            {/* <span style={{ fontSize: 24, fontWeight: 'bold', fontFamily: 'fantasy' }}>{_eventModal.EventName}</span> */}
                                            <img src={Banner_PrivateEvent_1} alt="Special Private Quiz Events !!!" width={'100%'} />
                                            {/* <img src={Banner_PrivateEvent_2} alt="Special Private Quiz Events !!!" width={'100%'} /> */}
                                        </button>

                                    </article>
                                </div>
                                <span>&nbsp;</span>
                            </>
                        );
                    }
                    else {
                        //Event has been joined.
                        return (
                            <>
                                <div className="card">
                                    <article className="card-body" style={{ padding: 10 }}>

                                        <img src={Banner_PrivateEvent_2} alt="Special Private Quiz Events !!!" width={'100%'} />

                                        <br />Joined - Quiz Paper Progressions<br />

                                        <table cellPadding='5' cellSpacing='5' className='table table-striped custab'
                                            style={{
                                                fontSize: 12,
                                                width: '100%',
                                                // tableLayout: 'fixed',
                                                display: 'block',
                                                // overflowY: 'scroll',
                                                // height: '225px',
                                                // overflowX: 'hidden',
                                            }}>
                                            <tbody>
                                                <tr><td>ttttt</td></tr>
                                            </tbody>
                                        </table>

                                    </article>
                                </div>
                                <span>&nbsp;</span>
                            </>
                        );
                    }
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    //#region === Change Password === 2022.08.08

    ToggleChangePasswordUi = () => {

        //2023.12.06
        const allowed = this.CheckProfileEditPermissionAllowed();
        if (allowed === false)
            return null;

        this.setState({
            toggleChangePasswordUi: !this.state.toggleChangePasswordUi,
            change_newPassword: '',
            change_newPassword_confirm: '',
        });
    }
    ValidateChangePassword = () => {
        let errorMessage = [];
        let password = '';
        let password_confirm = '';

        //password.
        if (this.state.change_newPassword === null)
            errorMessage.push(Locale("change-password-warning-invalid-pass", this.props.Locale));
        else if (this.state.change_newPassword.trim().length === 0)
            errorMessage.push(Locale("change-password-warning-empty-pass", this.props.Locale));
        else if (this.state.change_newPassword.trim().length < 6)
            errorMessage.push(Locale("change-password-warning-too-short-pass", this.props.Locale));
        else
            password = this.state.change_newPassword.trim();     //set

        //password confirm.
        if (this.state.change_newPassword_confirm === null)
            errorMessage.push(Locale("change-password-warning-invalid-pass-confirm", this.props.Locale));
        else if (this.state.change_newPassword_confirm.trim().length === 0)
            errorMessage.push(Locale("change-password-warning-empty-pass-confirm", this.props.Locale));
        else if (this.state.change_newPassword_confirm.trim().length < 6)
            errorMessage.push(Locale("change-password-warning-too-short-pass-confirm", this.props.Locale));
        else
            password_confirm = this.state.change_newPassword_confirm.trim();     //set

        //compare.
        if (password !== password_confirm)
            errorMessage.push(Locale("change-password-warning-not-match", this.props.Locale));

        return { password_confirm, errorMessage };
    }
    ChangePasswordViaApi = async () => {

        this.setState({ isProcessing: true, });
        this.props.SetLoading('', Locale("processing", this.props.Locale), false);

        const { password_confirm, errorMessage } = this.ValidateChangePassword();

        //proceed.
        if (errorMessage.length === 0) {

            //2024.07.10 - changed to custom login, password now self-maintained, can freely update.
            let success = false;
            const { Uid, Email } = this.state.profileFetched;
            if (CheckNullValue(Uid) === null || CheckNullValue(Email) === null) {
                this.props.SetAlert('Invalid Operation', 'invalid uid & email.', true);
            }
            else {
                await fetch(GlobalSetting.ApiUrl
                    + `Api/LearningCentre/User/Profile/Update/Password/${Uid}/${Email}/${password_confirm}`,
                    //Api/LearningCentre/User/Profile/Update/Password/${uid}/${email}/${password}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        success = CheckObjectBoolean(data, 'success');
                        if (this.props.isDevMode)
                            if (success)
                                console.log('api - password - update (success)\n' + JSON.stringify(data));
                            else
                                console.log('Error', 'api - password - update (failed)\n' + JSON.stringify(data));
                    })
                    .catch(error => {
                        //error.
                        this.props.SetAlert('Invalid Operation', error, true);
                        if (this.props.isDevMode)
                            console.log('Error', 'api - password - update (error)\n' + error.message);
                    });
                //done.
                if (success) {
                    //success.
                    const saveLoginCredential = localStorage.getItem('saveLoginCredential');
                    if (saveLoginCredential !== null) {
                        if (saveLoginCredential.length > 0)
                            if (saveLoginCredential.toLowerCase() === 'true')
                                localStorage.setItem('password', password_confirm);
                    }
                    this.props.SetAlert(Locale("success", this.props.Locale), Locale("change-password-result-success", this.props.Locale), true);
                    this.ToggleChangePasswordUi();
                }
            }
            //#region - 2024.07.10 - old code
            // //change password.
            // const user = firebase.auth().currentUser;
            // await user.updatePassword(password_confirm)
            //     .then(() => {
            //         //success.
            //         const saveLoginCredential = localStorage.getItem('saveLoginCredential');
            //         if (saveLoginCredential !== null) {
            //             if (saveLoginCredential.length > 0)
            //                 if (saveLoginCredential.toLowerCase() === 'true')
            //                     localStorage.setItem('password', password_confirm);
            //         }
            //         this.props.SetAlert(Locale("success", this.props.Locale), Locale("change-password-result-success", this.props.Locale), true);
            //         this.ToggleChangePasswordUi();
            //     })
            //     .catch((error) => {
            //         //error.
            //         this.props.SetAlert('Invalid Operation', error, true);
            //     });
            //#endregion
        }
        else {
            //error.
            this.props.SetAlert(Locale("invalid-info", this.props.Locale), errorMessage.join('<br />'), true);
        }
        this.setState({ isProcessing: false, });
    }

    //#endregion

    //#region === Change Email === 2023.11.20

    ToggleChangeEmailUi = () => {

        //2023.12.06
        const allowed = this.CheckProfileEditPermissionAllowed();
        if (allowed === false)
            return null;

        this.setState({
            toggleChangeEmailUi: !this.state.toggleChangeEmailUi,
            change_newEmail: '',
            change_newEmail_confirm: '',
        });
    }

    ChangeEmailViaApi = async () => {
        this.setState({ isProcessing: true, });
        this.props.SetLoading('', Locale("processing", this.props.Locale), false);

        let errorMessage = [];
        let email = '';
        let email_confirm = '';

        //email.
        if (this.state.change_newEmail === null)
            errorMessage.push(Locale("change-email-warning-invalid-format", this.props.Locale));
        else if (this.state.change_newEmail.trim().length === 0)
            errorMessage.push(Locale("change-email-warning-empty", this.props.Locale));
        else
            email = this.state.change_newEmail.trim();     //set

        //email confirm.
        if (this.state.change_newEmail_confirm === null)
            errorMessage.push(Locale("change-email-warning-invalid-format-confirm", this.props.Locale));
        else if (this.state.change_newEmail_confirm.trim().length === 0)
            errorMessage.push(Locale("change-email-warning-empty-confirm", this.props.Locale));
        else
            email_confirm = this.state.change_newEmail_confirm.trim();     //set

        //compare.
        if (email !== email_confirm)
            errorMessage.push(Locale("change-email-warning-not-match", this.props.Locale));

        //proceed.
        if (errorMessage.length === 0) {
            //2024.07.10 - changed to custom login, email now self-maintained, can freely update.
            let success = false;
            const { Uid, Email } = this.state.profileFetched;
            if (CheckNullValue(Uid) === null || CheckNullValue(Email) === null) {
                this.props.SetAlert('Invalid Operation', 'invalid uid & email.', true);
            }
            else {
                await fetch(GlobalSetting.ApiUrl
                    + `Api/LearningCentre/User/Profile/Update/Email/${Uid}/${Email}/${email_confirm}`,
                    //Api/LearningCentre/User/Profile/Update/Email/${uid}/${email}/${newEmail}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        success = CheckObjectBoolean(data, 'success');
                        errorMessage = CheckObjectStringEmpty(data, 'message');
                        if (this.props.isDevMode)
                            if (success)
                                console.log('api - email - update (success)\n' + JSON.stringify(data));
                            else
                                console.log('Error', 'api - email - update (failed)\n' + JSON.stringify(data));
                    })
                    .catch(error => {
                        //error.
                        this.props.SetAlert('Invalid Operation', error, true);
                        if (this.props.isDevMode)
                            console.log('Error', 'api - email - update (error)\n' + error.message);
                    });
                //done.
                if (success) {
                    //success.
                    const saveLoginCredential = localStorage.getItem('saveLoginCredential');
                    if (saveLoginCredential !== null) {
                        if (saveLoginCredential.length > 0)
                            if (saveLoginCredential.toLowerCase() === 'true')
                                localStorage.setItem('email', email_confirm);
                    }
                    this.props.SetAlert(Locale("success", this.props.Locale), Locale("change-email-result-success", this.props.Locale), true);
                    this.ToggleChangeEmailUi();
                    //update.
                    this.ToggleEditProfileUi();
                    await Delay(500);
                    await this.LoadProfile();
                    await Delay(500);
                    this.props.CloseAlert();
                    // let profile = this.state.profileFetched;
                    // profile['Email'] = email_confirm;
                    // let cachedProfile = this.state.cachedProfile;
                    // cachedProfile['Email'] = email_confirm;
                    // this.setState({
                    //     profileFetched: profile,
                    //     cachedProfile: cachedProfile,
                    // });
                    // await this.props.SetProfile(this.state.cachedProfile);
                    await Delay(500);
                    this.ToggleEditProfileUi();
                }
                else {
                    this.props.SetAlert(Locale("failed", this.props.Locale), errorMessage, true);
                }
            }

            //#region - 2024.07.10 - old code
            // //change email.
            // const user = firebase.auth().currentUser;
            // await user.updateEmail(email_confirm)
            //     .then(async () => {
            //         //success.
            //         const saveLoginCredential = localStorage.getItem('saveLoginCredential');
            //         if (saveLoginCredential !== null) {
            //             if (saveLoginCredential.length > 0)
            //                 if (saveLoginCredential.toLowerCase() === 'true')
            //                     localStorage.setItem('email', email_confirm);
            //         }
            //         await this.UpdateToSystemViaApi(email_confirm);
            //         this.ToggleChangeEmailUi();
            //         this.props.SetAlert(Locale("success", this.props.Locale), Locale("change-email-result-success", this.props.Locale), true);
            //     })
            //     .catch((error) => {
            //         //error.
            //         this.props.SetAlert('Invalid Operation', error, true);
            //     })
            //#endregion
        }
        else {
            //error.
            this.props.SetAlert(Locale("invalid-info", this.props.Locale), errorMessage.join('<br />'), true);
        }
        this.setState({ isProcessing: false, });
    }

    UpdateToSystemViaApi = async (email = '') => {
        if (CheckNullValue(email) === null)
            return null;

        let _profile = this.state.profileFetched;
        _profile['Email'] = email;

        let _profile_cached = this.state.cachedProfile;
        _profile_cached['Email'] = email;

        this.setState({
            profileFetched: _profile,
            cachedProfile: _profile_cached,
        });

        await this.ProfileEditValidation();
    }

    //#endregion

    //#region === Student Quiz Room List === 2024.08.05

    GetStudentQuizRoomList = async () => {

        this.setState({
            StudentQuizRoomList_Processing: true,
            StudentQuizRoomList: [],
        });

        let _List = [];
        let success = false;
        let message = '';
        let done = false;

        const uid = String(this.props.user.uid);
        const email = String(this.props.user.email);
        const url = GlobalSetting.ApiUrl + `Api/LearningCentre/Student/QuizRoom/List/${uid}/${email}`;
        if (this.props.isDevMode)
            console.log(`GetStudentQuizRoomList (${uid}) (${email})`);

        await fetch(url,
            // Api/LearningCentre/Student/QuizRoom/List/{uid}/{email}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    _List = CapitalizeJsonKeys(data.data);
                }
                else {
                    message = CheckObjectStringEmpty(data, 'message');
                }
                done = true;
                if (this.props.isDevMode)
                    if (data.success)
                        console.log('api - student quiz room list - fetch (success)\n' + JSON.stringify(data));
                    else
                        console.log('Error', 'api - student quiz room list - fetch (failed)\n' + JSON.stringify(data));
            })
            .catch(error => {
                message = error.message;
                done = true;
                if (this.props.isDevMode)
                    console.log('Error', 'api - student quiz room list - fetch (error)\n' + error.message);
            });
        await DelayUntil(() => done === true);

        this.setState({
            StudentQuizRoomList_Processing: false,
            StudentQuizRoomList: _List,
        }, () => {
            if (success === false)
                console.log('Failed to load student quiz room list. \n (Error) ' + message);
        });
    }

    StudentQuizRoomListComponent = () => {
        // let components = [];

        const formatDate = (date = '') => {
            if (date === '-')
                return '-';
            return moment(date).format('DD/MM/YY');
        };

        const formatTime = (time = '') => {
            if (time === '-')
                return '-';
            return moment(moment().format('YYYY-MM-DD ') + time).format('HH:mm A');
        };

        return (<div className=''>
            <table className="table table-striped custab stuQuizRooms" style={{
                fontSize: 12,
                width: '100%',
                marginTop: 1,
                marginBottom: 20,
            }}>
                <thead>
                    <tr>
                        <th style={{ width: 5 }}>#</th>
                        <th style={{ textAlign: 'left' }}>Room<br />Title</th>
                        <th style={{ width: 75 }}>Date<br />Start</th>
                        <th style={{ width: 75 }}>Date<br />End</th>
                        <th style={{ width: 75 }}>Time<br />Start</th>
                        <th style={{ width: 75 }}>Time<br />End</th>
                        <th style={{ width: 85 }}>Room<br />Code</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.StudentQuizRoomList_Processing ?
                            <tr><td colSpan={15} style={{ textAlign: 'center' }}><LoadingIndicator /></td></tr>
                            :
                            this.state.StudentQuizRoomList.length > 0 ?
                                this.state.StudentQuizRoomList.map((data, key) => {
                                    return <tr>
                                        <td>{key + 1}</td>
                                        {
                                            //2025.02.06
                                            CheckObjectStringEmpty(data, 'QuestionSetRemark') === '' ?
                                                <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'RoomTitle', '-')}</td>
                                                :
                                                <td style={{ textAlign: 'left' }}>
                                                    <span title='click to view question set remark' style={{ cursor: 'pointer' }}
                                                        onClick={() => this.SetAlert(CheckObjectStringEmpty(data, 'RoomTitle', '-'),
                                                            '<div style="display:flex;flex-direction:column;gap:10px;">'
                                                            + '<span><b>Remark:</b></span>'
                                                            + '<span style="padding:10px;border:1px solid gray;border-radius:5px;margin-left:10px;">'
                                                            + CheckObjectStringEmpty(data, 'QuestionSetRemark', '-') + '</span>')}
                                                    >{CheckObjectStringEmpty(data, 'RoomTitle', '-')} <i className='fa fa-info-circle' style={{ fontSize: 16, color: 'blue', paddingLeft: 3, verticalAlign: 'bottom' }}></i></span>
                                                </td>
                                        }
                                        {/* <td style={{ textAlign: 'left' }}>{CheckObjectStringEmpty(data, 'RoomTitle', '-')}</td> */}
                                        <td>{formatDate(CheckObjectStringEmpty(data, 'DateStart', '-'))}</td>
                                        <td>{formatDate(CheckObjectStringEmpty(data, 'DateEnd', '-'))}</td>
                                        <td>{formatTime(CheckObjectStringEmpty(data, 'TimeStart', '-'))}</td>
                                        <td>{formatTime(CheckObjectStringEmpty(data, 'TimeEnd', '-'))}</td>
                                        <td style={{ paddingLeft: 5, paddingRight: 5 }}>
                                            {
                                                CheckObjectBoolean(data, 'AllowToEnter') ?
                                                    <button type='button' className='btn btn-primary' style={{ width: '100%' }}
                                                        onClick={() => {
                                                            this.setState({
                                                                quizRoomCode: CheckObjectStringEmpty(data, 'RoomCode', '-'),
                                                            }, () => {
                                                                this.RoomCodeValidation();
                                                            });
                                                        }}
                                                    >{CheckObjectStringEmpty(data, 'RoomCode', '-')}</button>
                                                    :
                                                    <span>-</span>
                                            }
                                        </td>
                                    </tr>;
                                })
                                :
                                <tr><td colSpan={15} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => this.GetStudentQuizRoomList()} title='click here to refresh list'>- no quiz room available at the moment -</td></tr>
                    }
                </tbody>
            </table>
        </div>);
    }

    //#endregion

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            return (
                <div style={{
                    backgroundColor: 'transparent',
                    // backgroundColor: 'lavender',
                    // height: this.state.toggleShowQuizHistoryList ? 'auto' : '100%',
                    // height: 'auto',
                    // height: this.props.isHistoryListSyncDone ? 'auto' : '100%',
                    minHeight: '100%',
                    width: '100%',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    // paddingTop: 25,
                    paddingTop: window.innerWidth < 576 ? 5 : 25,   //2020.12.29

                    //2021.04.12
                    paddingLeft: window.innerWidth < 576 ? 5 : 15,
                    paddingRight: window.innerWidth < 576 ? 5 : 15,
                }}>
                    {/* <ButtonClickTranslator
                        callbackForClickOnRoomCode={this.CallbackForClickOnRoomCode}
                    /> */}

                    <div className="container" style={{ maxWidth: '650px', backgroundColor: 'lavender', paddingTop: 15, paddingBottom: 15, borderRadius: 5 }}>
                        <div className="row">
                            <aside className="col-sm-12">

                                {/* <p style={{
                                    color: 'black', fontSize: 35, textAlign: 'center', fontWeight: 'bold',
                                }}>iKEY Kidz (WEB)</p> */}

                                {
                                    //admin only. (obsolete)
                                    this.props.isSuperAdmin === true ?
                                        <>
                                            <div className="card" style={{ backgroundColor: '#007bff', }}>
                                                <article className="card-body text-center">
                                                    <button type="button" className="btn btn-warning btn-block"
                                                        onClick={() => this.GotoAdminPage()}
                                                    >Go to Admin</button>
                                                </article>
                                            </div>
                                            <span>&nbsp;</span>
                                        </>
                                        : null
                                }

                                {
                                    //section for profile & localization setting.
                                    !this.props.isFromParentApp ?

                                        <div className="card" style={{ backgroundColor: '#007bff' }}>
                                            <article className="card-body text-center" style={{ paddingBottom: 10 }}>

                                                {/* <span style={{
                                                    color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                    float: "left"
                                                }}>iKEY Kidz (Web)</span> */}

                                                <button type="button"
                                                    className={"btn btn-outline-primary btn-xs float-left"}
                                                    onClick={this.ToggleEditProfileUi}
                                                    style={{ marginTop: '-5px', paddingLeft: 0 }}
                                                >
                                                    <i className="fa fa-user-circle-o" style={{ fontSize: '36px', color: 'white', float: 'left', marginRight: '10px' }}></i>
                                                    <span style={{
                                                        color: 'white', fontSize: 25, textAlign: 'left', fontWeight: 'bold',
                                                        float: "left", marginTop: '-5px',
                                                    }}>
                                                        {
                                                            this.props.profile !== null ?
                                                                CheckObjectStringEmpty(this.props.profile, 'Name').length > 26 ?
                                                                    CheckObjectStringEmpty(this.props.profile, 'Name').substring(0, 26) + "..."
                                                                    :
                                                                    CheckObjectStringEmpty(this.props.profile, 'Name').length > 0 ?
                                                                        CheckStringEmpty(this.props.profile.Name)
                                                                        : CheckStringEmpty(this.props.email)
                                                                :
                                                                this.props.email !== null ?
                                                                    this.props.email
                                                                    :
                                                                    "iKEY Kidz (Web)"
                                                        }
                                                        <br />
                                                        <span
                                                            style={{ fontSize: '12px', padding: 0, fontWeight: "normal", float: "left" }}
                                                        >{Locale("click-to-edit-profile", this.props.Locale)}</span>
                                                    </span>
                                                </button>

                                                <LocalizationSwitcher
                                                    Locale={this.props.Locale}
                                                    SetAlert={this.props.SetAlert}
                                                    CloseAlert={this.props.CloseAlert}
                                                    SetLocaleSetting={this.props.SetLocaleSetting}
                                                    MediaFiles={this.props.MediaFiles}  //2025.03.20
                                                />
                                            </article>
                                        </div>
                                        :
                                        <div className="card" style={{ backgroundColor: '#007bff' }}>
                                            <article className="card-body text-center">
                                                <span style={{
                                                    color: 'white', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                                                    float: "left"
                                                }}>iKEY Live Quiz</span>

                                                {/* <h4 class="card-title mb-4 mt-1"><b>{Locale("signup-register", this.props.Locale)}</b></h4> */}

                                                <LocalizationSwitcher
                                                    Locale={this.props.Locale}
                                                    SetAlert={this.props.SetAlert}
                                                    CloseAlert={this.props.CloseAlert}
                                                    SetLocaleSetting={this.props.SetLocaleSetting}
                                                    MediaFiles={this.props.MediaFiles}  //2025.03.20
                                                />
                                            </article>
                                        </div>
                                }

                                {/* {
                                    // !this.props.isRedirectToHistoryList ?
                                    !this.props.isFromParentApp ?
                                        <> */}
                                <div className="card" style={{ borderRadius: 5 }}>
                                    <article className="card-body" style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>

                                        {/* <Link to="/quiz/home">
                                                    <img src={Banner} alt="Live Quiz" width={'100%'} />
                                                </Link> */}

                                        {
                                            //2024.08.05
                                            this.StudentQuizRoomListComponent()
                                        }

                                        {
                                            //2021.07.01
                                            String(this.props.UniqueEventCode).length > 0 ?
                                                this.ShowUniqueEventCardElement(this.props.UniqueEventCode)
                                                : null
                                        }

                                        {
                                            //section for UpcomingQuizList.
                                            /* 2020.12.04 */
                                            this.state.UpcomingQuizList.length > 0 ?
                                                <>
                                                    <table className="table table-striped custab" style={{
                                                        fontSize: 12,
                                                        width: '100%',
                                                        tableLayout: 'fixed',
                                                        display: 'block',
                                                        overflowY: 'scroll',
                                                        height: '275px',
                                                        // borderTop: 'none',
                                                        borderCollapse: 'separate',
                                                        borderSpacing: 0,
                                                        marginTop: 1,
                                                        // overflowX: 'hidden',
                                                    }}>
                                                        <thead>
                                                            <tr>
                                                                {/* <th width="3%" className="sticky">#</th> */}
                                                                <th width="28%" className='text-left sticky'>{Locale("event", this.props.Locale)}</th>
                                                                <th width="30%" className='text-left sticky'>{Locale("organise-by", this.props.Locale)}</th>
                                                                <th width="24%" className='text-left sticky'>{Locale("room-date", this.props.Locale)}</th>
                                                                <th width="18%" className="sticky">{Locale("details", this.props.Locale)}</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                this.state.UpcomingQuizList.map((data, key) => {
                                                                    if (key < 15) {
                                                                        if (
                                                                            this.props.OrganizerIdentity !== '' ?
                                                                                data.OrganizerIdentity === this.props.OrganizerIdentity
                                                                                : data.IsPublic
                                                                        ) {
                                                                            return (
                                                                                <tr
                                                                                    key={key}
                                                                                    // className={data.IsEnded !== true && data.HasJoinedEvent === false ? 'eventRegisterHighlight' : ''}
                                                                                    style={
                                                                                        data.IsEnded !== true ?
                                                                                            data.HasJoinedEvent === false ?
                                                                                                { cursor: 'pointer', backgroundColor: '#e95d5d', color: 'white', }  //not yet join.
                                                                                                // : { backgroundColor: '#f9d7d7' }
                                                                                                : { cursor: 'pointer', backgroundColor: 'lightyellow' } //joined.
                                                                                            : {} //{ cursor: 'pointer' }
                                                                                    }

                                                                                    //2021.04.12
                                                                                    onClick={async () =>
                                                                                        // this.ShowEventDetails(data.IsEnded, data.Event, data.Organizer)
                                                                                        await this.ShowEventDetails(data.EventCode)
                                                                                    }
                                                                                >
                                                                                    {/* <td>{key + 1}</td> */}
                                                                                    {/* <td className='text-left'>{data.Subject}</td> */}
                                                                                    <td className='text-left'><div dangerouslySetInnerHTML={{ __html: data.Subject }} /></td>
                                                                                    {/* <td className='text-left'>{data.Organizer}</td> */}
                                                                                    <td className='text-left'>{data.OrganizerDisplayName}</td>
                                                                                    <td className='text-left'
                                                                                        style={
                                                                                            data.IsEnded !== true ?
                                                                                                { fontWeight: 'bold' }
                                                                                                : {}
                                                                                        }
                                                                                    >{data.Date}</td>
                                                                                    {/* <td className='text-left'>{data.Date} <b>{String(data.HasJoinedEvent)}</b></td> */}
                                                                                    <td
                                                                                        className={data.IsEnded !== true ? 'pointer' : ''}
                                                                                        style={data.IsEnded !== true ? { color: "lavender", } : {}}
                                                                                    >
                                                                                        <span
                                                                                            // className={data.Date !== 'TBA' ? 'pointer' : ''}
                                                                                            // style={data.Date !== 'TBA' ? { color: "blue", } : {}}
                                                                                            // className={data.IsEnded !== true ? 'pointer' : ''}
                                                                                            style={data.IsEnded !== true ? { color: "blue", } : {}}

                                                                                        // onClick={() => this.GotoEventSignUp(data.Date, data.Event)}
                                                                                        // onClick={async () =>
                                                                                        //     // this.ShowEventDetails(data.IsEnded, data.Event, data.Organizer)
                                                                                        //     await this.ShowEventDetails(data.EventCode)
                                                                                        // }
                                                                                        >
                                                                                            {
                                                                                                // data.Date !== 'TBA' ?
                                                                                                //     <u>{Locale("more-info", this.props.Locale)}</u>
                                                                                                //     :
                                                                                                //     Locale("coming-soon", this.props.Locale)

                                                                                                //2020.12.15
                                                                                                data.Date !== 'TBA' && data.Active !== false ?
                                                                                                    data.IsEnded ?
                                                                                                        Locale("quiz-ended", this.props.Locale)
                                                                                                        :
                                                                                                        data.HasJoinedEvent ?
                                                                                                            // <u>{Locale("click-here", this.props.Locale)}</u>
                                                                                                            <u>
                                                                                                                <div dangerouslySetInnerHTML={{ __html: Locale("click-here", this.props.Locale) }}></div>
                                                                                                            </u>
                                                                                                            :
                                                                                                            <span className="blink" style={{ color: 'yellow' }}><u>{Locale("register-now", this.props.Locale)}</u></span>
                                                                                                    :
                                                                                                    Locale("coming-soon", this.props.Locale)
                                                                                            }
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    }
                                                                    return null;
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div style={{ textAlign: 'center', width: '100%', marginTop: -12, paddingBottom: 15, }}>
                                                        <span style={{ color: 'gray', fontSize: 14 }}>scroll to view for more events.</span>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            //button for enter quiz room.
                                        }
                                        <button type="button" className="border-0"
                                            style={{ outline: '0px', backgroundColor: 'white', width: '100%', }}
                                            onClick={async () => {
                                                if (this.props.isSuperAdmin === false) {
                                                    //2024.05.23 - no more policyTnc checking needed by Calvin request.
                                                    let isValid = this.state.policyTnc_enableChecking === false ? true : await this.CheckPolicyTncAgreeState();
                                                    // let isValid = await this.CheckPolicyTncAgreeState();
                                                    if (isValid) {
                                                        isValid = await this.ProfileEditedDataValidation();
                                                        if (isValid)
                                                            this.ToggleQuizModal();
                                                        else
                                                            this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                                                    }
                                                }
                                                else {
                                                    this.ToggleQuizModal(); //admin can direct enter quiz room regardless of policy/etc.
                                                }
                                            }}
                                            disabled={!this.state.isProfileSyncDone}
                                        >
                                            {
                                                this.state.isProfileSyncDone ?
                                                    <img src={Banner} alt="Live Quiz" width={'100%'} />
                                                    :
                                                    // <Spinner animation="border" variant="primary" />
                                                    <LoadingIndicator />
                                            }
                                        </button>

                                    </article>
                                </div>

                                {/* <div className="card">
                                    <article className="card-body">
                                        <button type="button" className="btn btn-primary btn-block"
                                            onClick={this.handleLogout}
                                        >{Locale("signout-logout", this.props.Locale)}</button>
                                    </article>
                                </div> */}

                                {
                                    //2025.03.11+
                                    //button access for Wordwall.
                                }
                                <div className="card" style={{ borderRadius: 5, padding: 15, margin: '15px 0px' }}>
                                    <button type="button" className="btn-link border-0" title='Enter Mini Game' onClick={() => this.GotoPage('/wordwall/entry')}
                                    ><img src={Banner_MiniGame} alt='wordwall' width='100%' /></button>
                                </div>

                                {
                                    //2025.03.11+ === not needed anymore, confirmed by Calvin.
                                    // //buttons for download ikey app.
                                    // !this.props.isFromParentApp ?
                                    //     <>
                                    //         <span>&nbsp;</span>
                                    //         <div className="card" style={{ backgroundColor: '#fc3868' }} id={'download-app-pos'}>
                                    //             <article className="card-body" style={{ padding: 25 }}>
                                    //                 <div className='row' style={{ paddingBottom: 10 }}>
                                    //                     <div className='col-12 text-center'>
                                    //                         <span
                                    //                             style={{
                                    //                                 color: 'white',
                                    //                                 fontFamily: 'sans-serif',
                                    //                                 fontSize: 14,
                                    //                             }}
                                    //                         >
                                    //                             <div dangerouslySetInnerHTML={{ __html: Locale("download-ikey-parent-app", this.props.Locale) }}></div>
                                    //                         </span>
                                    //                     </div>
                                    //                 </div>
                                    //                 <div className='row'>
                                    //                     <div className='col-6'>
                                    //                         <button type="button" className="border-0"
                                    //                             style={{ float: 'right', outline: '0px', backgroundColor: 'transparent', width: 'auto', height: 'auto' }}
                                    //                             onClick={() => window.open('https://apps.apple.com/us/app/ikey-parent/id1476014901', '_new')}
                                    //                         >
                                    //                             <img src={app_store_logo} alt='app_store_logo' width='100%' />
                                    //                         </button>
                                    //                     </div>
                                    //                     <div className='col-6'>
                                    //                         <button type="button" className="border-0"
                                    //                             style={{ outline: '0px', backgroundColor: 'transparent', width: 'auto', height: 'auto' }}
                                    //                             onClick={() => window.open('https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full', '_new')}
                                    //                         >
                                    //                             <img src={google_play_logo} alt='google_play_logo' width='100%' />
                                    //                         </button>
                                    //                     </div>
                                    //                 </div>
                                    //             </article>
                                    //         </div>
                                    //         <span>&nbsp;</span>
                                    //     </>
                                    //     :
                                    //     this.props.isFromParentApp && this.props.isRedirectToHistoryList ? null : <span>&nbsp;</span>
                                }

                                {
                                    //button for goto participated event list.
                                    <>
                                        {/* <br /> */}
                                        <div style={{ width: '100%', textAlign: 'center', paddingBottom: 2, }}>
                                            <span
                                                style={{
                                                    color: 'blue',
                                                    textShadow: '1px 1px 1px #ffffff',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: Locale("label-notice-participated-event", this.props.Locale) }}></div>
                                            </span>
                                        </div>
                                        <div className="card">
                                            <article className="card-body">

                                                {/* <div style={{
                                                        backgroundColor: '#dedede',
                                                        padding: 10,
                                                    }}>
                                                        <span style={{
                                                            color: 'white',
                                                            fontSize: 28,
                                                            fontWeight: 'bold',
                                                            textShadow: '2px 2px 2px #000000',
                                                            marginBottom: 5,
                                                        }}>Participated Event List</span>
                                                        <br /><br />
                                                        <button type="button" className="btn btn-primary btn-block"
                                                            onClick={this.Goto_ParticipatedEventList}
                                                        >{Locale("enter", this.props.Locale)}</button>
                                                    </div> */}

                                                <button type="button" className="border-0"
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        width: '100%',
                                                        padding: 0,
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                        margin: 0,
                                                    }}
                                                    // onClick={this.ToggleQuizModal}
                                                    onClick={this.Goto_ParticipatedEventList}
                                                    disabled={!this.state.isProfileSyncDone}
                                                >
                                                    {
                                                        this.state.isProfileSyncDone ?
                                                            <img src={PE_Banner} alt="Participated Quiz Events" width={'100%'} />
                                                            :
                                                            <LoadingIndicator />
                                                    }
                                                </button>

                                            </article>
                                        </div>
                                        <span>&nbsp;</span>
                                    </>
                                }

                                {
                                    //section for room history list.
                                    this.props.user !== null ?
                                        this.props.isSuperAdmin === false ?
                                            // this.props.isPolicyTncStateTheLatest ?
                                            this.props.profile !== null ?
                                                <QuizHistoryList

                                                    isDevMode={this.props.isDevMode}
                                                    user={this.props.user}
                                                    profile={this.props.profile}

                                                    //App
                                                    isHistoryListSyncDone={this.props.isHistoryListSyncDone}
                                                    SetHistoryListSyncState={this.props.SetHistoryListSyncState}

                                                    firestore={this.props.firestore}
                                                    auth={this.props.auth}

                                                    Locale={this.props.Locale}
                                                    historyList={this.props.historyList}

                                                    SetAlert={this.props.SetAlert}
                                                    SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                                    CloseAlert={this.props.CloseAlert}
                                                    SetErrorLog={this.props.SetErrorLog}

                                                    SetHistoryList={this.props.SetHistoryList}
                                                    SetViewHistoryQuiz={this.props.SetViewHistoryQuiz}
                                                    setRoomCodeId={this.props.setRoomCodeId}

                                                    //2020.11.26
                                                    isFromParentApp={this.props.isFromParentApp}

                                                    //2024.05.23
                                                    policyTnc_enableChecking={this.state.policyTnc_enableChecking}

                                                    //2020.11.27
                                                    CheckPolicyTncAgreeState={this.CheckPolicyTncAgreeState}
                                                    isPolicyTncStateTheLatest={this.props.isPolicyTncStateTheLatest}
                                                    SetIsPolicyTncStateTheLatest={this.props.SetIsPolicyTncStateTheLatest}

                                                    //2020.11.30
                                                    isInternetReachable={this.props.isInternetReachable}
                                                    ShowInternetNotAvailableAlert={this.props.ShowInternetNotAvailableAlert}

                                                    //2021.04.06
                                                    CheckIfRoomBelongsToEvent={this.props.CheckIfRoomBelongsToEvent}
                                                />
                                                :
                                                <div className="card">
                                                    <article className="card-body">
                                                        <LoadingIndicator />
                                                    </article>
                                                </div>
                                            : null
                                        : null
                                }

                                {
                                    //#region  //diabled on 2020.12.02 = now show the room history list directly.

                                    // this.props.isFromParentApp || (this.props.isFromParentApp && this.props.isRedirectToHistoryList) ?
                                    //     <QuizHistoryList

                                    //         user={this.props.user}

                                    //         //App
                                    //         isHistoryListSyncDone={this.props.isHistoryListSyncDone}
                                    //         SetHistoryListSyncState={this.props.SetHistoryListSyncState}

                                    //         firestore={this.props.firestore}
                                    //         auth={this.props.auth}

                                    //         Locale={this.props.Locale}
                                    //         historyList={this.props.historyList}

                                    //         SetAlert={this.props.SetAlert}
                                    //         SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                    //         CloseAlert={this.props.CloseAlert}
                                    //         SetErrorLog={this.props.SetErrorLog}

                                    //         SetHistoryList={this.props.SetHistoryList}
                                    //         SetViewHistoryQuiz={this.props.SetViewHistoryQuiz}
                                    //         setRoomCodeId={this.props.setRoomCodeId}

                                    //         //2020.11.26
                                    //         isFromParentApp={this.props.isFromParentApp}

                                    //         //2020.11.27
                                    //         CheckPolicyTncAgreeState={this.CheckPolicyTncAgreeState}
                                    //         isPolicyTncStateTheLatest={this.props.isPolicyTncStateTheLatest}
                                    //         SetIsPolicyTncStateTheLatest={this.props.SetIsPolicyTncStateTheLatest}

                                    //         //2020.11.30
                                    //         isInternetReachable={this.props.isInternetReachable}
                                    //         ShowInternetNotAvailableAlert={this.props.ShowInternetNotAvailableAlert}
                                    //     />
                                    //     :
                                    //     //2020.11.21
                                    //     this.state.toggleShowQuizHistoryList || (!this.props.isFromParentApp && this.props.isRedirectToHistoryList) ?
                                    //         <QuizHistoryList

                                    //             user={this.props.user}

                                    //             //App
                                    //             isHistoryListSyncDone={this.props.isHistoryListSyncDone}
                                    //             SetHistoryListSyncState={this.props.SetHistoryListSyncState}

                                    //             firestore={this.props.firestore}
                                    //             auth={this.props.auth}

                                    //             Locale={this.props.Locale}
                                    //             historyList={this.props.historyList}

                                    //             SetAlert={this.props.SetAlert}
                                    //             SetAlertWithProgressBar={this.props.SetAlertWithProgressBar}
                                    //             CloseAlert={this.props.CloseAlert}
                                    //             SetErrorLog={this.props.SetErrorLog}

                                    //             SetHistoryList={this.props.SetHistoryList}
                                    //             SetViewHistoryQuiz={this.props.SetViewHistoryQuiz}
                                    //             setRoomCodeId={this.props.setRoomCodeId}

                                    //             //2020.11.26
                                    //             isFromParentApp={this.props.isFromParentApp}

                                    //             //2020.11.27
                                    //             CheckPolicyTncAgreeState={this.CheckPolicyTncAgreeState}
                                    //             isPolicyTncStateTheLatest={this.props.isPolicyTncStateTheLatest}
                                    //             SetIsPolicyTncStateTheLatest={this.props.SetIsPolicyTncStateTheLatest}

                                    //             //2020.11.30
                                    //             isInternetReachable={this.props.isInternetReachable}
                                    //             ShowInternetNotAvailableAlert={this.props.ShowInternetNotAvailableAlert}
                                    //         />
                                    //         :
                                    //         <>
                                    //             {/* <span>&nbsp;</span> */}
                                    //             <div className="card">
                                    //                 <article className="card-body">

                                    //                     <button type="button" className="btn btn-primary btn-block"
                                    //                         // onClick={() => this.setState({ toggleShowQuizHistoryList: !this.state.toggleShowQuizHistoryList })}
                                    //                         onClick={async () => {
                                    //                             let _state = await this.CheckPolicyTncAgreeState();
                                    //                             if (_state) {
                                    //                                 this.setState({
                                    //                                     toggleShowQuizHistoryList: !this.state.toggleShowQuizHistoryList
                                    //                                 });
                                    //                             }
                                    //                         }}
                                    //                         disabled={!CheckBoolean(this.props.isInternetReachable)}
                                    //                     >{Locale("show-quiz-history", this.props.Locale)}</button>

                                    //                 </article>
                                    //             </div>
                                    //         </>
                                    //#endregion
                                }

                                {
                                    //button for logout.
                                    this.props.isRedirectToHistoryList ?
                                        <>
                                            <span>&nbsp;</span>
                                            <div className="card">
                                                <article className="card-body">
                                                    <button type="button" className="btn btn-primary btn-block"
                                                        onClick={this.handleLogout}
                                                    >{Locale("signout-logout", this.props.Locale)}</button>
                                                </article>
                                            </div>
                                        </>
                                        : null
                                }

                                {
                                    //#region //old codes hidden before 2022.01.21
                                    // this.state.toggleHistoryList ?
                                    //     <>
                                    //         <span>&nbsp;</span>
                                    //         <div className="card">
                                    //             <article className="card-body">

                                    //                 {/* <p><h5><b><u>&nbsp;Quiz History&nbsp;</u></b></h5></p> */}

                                    //                 <div className="row custyle">
                                    //                     <table width="100%" className="table custab pointer">
                                    //                         <thead>
                                    //                             <tr><td colSpan="5"><b>{Locale("today-last-quiz", this.props.Locale)}</b></td></tr>
                                    //                         </thead>
                                    //                         <tbody>
                                    //                             <tr
                                    //                                 onClick={() => this.EnterHistoryRoom(0)}
                                    //                                 title={Locale("click-to-enter-room", this.props.Locale) + " " + this.state.historyListInQuizHome[0].RoomCode}
                                    //                             >
                                    //                                 <td width="5%">&nbsp;</td>
                                    //                                 <td width="20%" style={{ textAlign: "left" }}>{Locale("room-code", this.props.Locale)}<br />{this.state.historyListInQuizHome[0].RoomCode}</td>
                                    //                                 <td width="50%" style={{ textAlign: "left" }}>{Locale("room-title", this.props.Locale)}<br />{this.state.historyListInQuizHome[0].Title}</td>
                                    //                                 <td width="25%">{Locale("room-date", this.props.Locale)}<br />{moment(this.state.historyListInQuizHome[0].Date).format("ll")}</td>
                                    //                                 {/* <td width="3%">&nbsp;</td> */}
                                    //                             </tr>
                                    //                         </tbody>
                                    //                     </table>
                                    //                 </div>

                                    //                 <span height="35px"></span>

                                    //                 <div className="row custyle">
                                    //                     <table className="table table-striped custab">
                                    //                         <thead>
                                    //                             <tr>
                                    //                                 <th width="10%">#</th>
                                    //                                 <th width="20%" style={{ textAlign: "left" }}>{Locale("room-code", this.props.Locale)}</th>
                                    //                                 <th width="45%" style={{ textAlign: "left" }}>{Locale("room-title", this.props.Locale)}</th>
                                    //                                 <th width="25%">{Locale("room-date", this.props.Locale)}</th>
                                    //                             </tr>
                                    //                         </thead>

                                    //                         <tbody>
                                    //                             {
                                    //                                 this.state.historyListInQuizHome.map((data, key) => {
                                    //                                     return (

                                    //                                         <tr
                                    //                                             key={key}
                                    //                                             // onClick={() => this.EnterHistoryRoom(key)} key={key}
                                    //                                             title={Locale("click-to-enter-room", this.props.Locale) + " " + data.RoomCode}
                                    //                                         >
                                    //                                             <td>{key + 1}</td>
                                    //                                             <td style={{ textAlign: "left" }}>
                                    //                                                 <span
                                    //                                                     className={'pointer'}
                                    //                                                     style={{ color: "blue", }}
                                    //                                                     onClick={() => this.EnterHistoryRoom(key)} key={key}
                                    //                                                 ><u>{data.RoomCode}</u></span>
                                    //                                                 {/* <a href="#">
                                    //                                                     {data.RoomCode}
                                    //                                                 </a> */}
                                    //                                                 {/* <span className="spnTooltip">{"Click to enter Room " + data.RoomCode}</span> */}
                                    //                                             </td>
                                    //                                             <td style={{ textAlign: "left" }}>{data.Title}{this.state.debugMode ? "[" + data.RoomId + "]" : ""}</td>
                                    //                                             <td>{moment(data.Date).format("ll")}</td>
                                    //                                         </tr>
                                    //                                     );
                                    //                                 })
                                    //                             }
                                    //                         </tbody>

                                    //                     </table>
                                    //                 </div>

                                    //             </article>
                                    //         </div>

                                    //         {/* <div className="tooltip bs-tooltip-top" role="tooltip">
                                    //             <div className="arrow"></div>
                                    //             <div className="tooltip-inner"> Some tooltip text! </div>
                                    //         </div> */}
                                    //     </>
                                    //     : null
                                    //#endregion
                                }
                            </aside>
                        </div>
                    </div>

                    <div style={{ height: '200px', width: '100%' }}>
                        <span>&nbsp;</span>
                    </div>

                    {/* Live Quiz - Enter Room Code - Modal */}
                    <Modal show={this.state.showQuizModal} onHide={this.ToggleQuizModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Live Quiz</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <div dangerouslySetInnerHTML={this.getMessage()}></div> */}
                            <div className="form-group">
                                <label>{Locale("room-code", this.props.Locale)}</label>
                                <input name="roomcode" className="form-control" placeholder={Locale("room-code-ph", this.props.Locale)} type="email"
                                    onChange={this.handleSetRoomCode}
                                    value={this.state.quizRoomCode}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.ToggleQuizModal}
                                disabled={this.state.isProcessing}
                            >{Locale("close", this.props.Locale)}</Button>
                            <Button variant="secondary" onClick={() => this.setState({ quizRoomCode: "" })}
                                disabled={this.state.isProcessing}
                            >{Locale("clear", this.props.Locale)}</Button>
                            <Button variant="primary" onClick={this.RoomCodeValidation}
                                disabled={this.state.isProcessing}
                            >{Locale("enter", this.props.Locale)}</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Edit Profile - Modal */}
                    <Modal id='ref_EditProfileUi' //scrollable={true}
                        // size='lg'
                        show={this.state.toggleEditProfileUi} onHide={this.ToggleEditProfileUi} centered>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <span>{Locale("profile", this.props.Locale)}</span>
                                <hr width={'100%'} />
                                <span style={{ fontSize: 18, paddingLeft: 0 }}>{CheckObjectStringEmpty(this.state.cachedProfile, 'Email')}</span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <div dangerouslySetInnerHTML={this.getMessage()}></div> */}
                            <div className="form-group">

                                {/* Name */}
                                {/* <div className={"form-group" + (this.state.isNameEmpty ? " highlight" : "")}> */}
                                <div className="form-group">
                                    <label>{Locale("your-name", this.props.Locale)} *</label>
                                    <input name="FullName"
                                        // className="form-control"
                                        className={"form-control" + (this.state.isNameEmpty ? " highlight" : "")}
                                        type="text"
                                        onChange={(val) => this.handleSetProfile(Profile.Name, val)}
                                        placeholder={Locale("full-name", this.props.Locale)}
                                        // placeholder={
                                        //     this.props.profile !== null ?
                                        //         this.props.profile.Name
                                        //         : Locale("full-name", this.props.Locale)
                                        // }
                                        value={this.getProfile(Profile.Name)}
                                        disabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* Contact Number */}
                                {/* <div className={"form-group" + (this.state.isContactNumberEmpty ? " highlight" : "")}> */}
                                <div className="form-group">
                                    <label>{Locale("contact-number", this.props.Locale)} ({Locale("optional", this.props.Locale)})</label>
                                    <input
                                        name="ContactNumber"
                                        // className="form-control"
                                        className={"form-control" + (this.state.isContactNumberEmpty ? " highlight" : "")}
                                        placeholder={Locale("contact-number-sample", this.props.Locale)}
                                        type="text"
                                        onChange={(val) => this.handleSetProfile(Profile.Contact, val)}
                                        value={this.getProfile(Profile.Contact)}
                                        disabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* Gender */}
                                <div className="form-group">
                                    <label>{Locale("label-gender", this.props.Locale)}</label>
                                    <Select
                                        options={this.state.GenderOptions}
                                        // options={this.props.GenderOptions}  //2022.11.01
                                        placeholder={
                                            String(this.getProfile(Profile.Gender)).length > 0 ?
                                                // (Array(this.state.GenderOptions).find(x => x.value === this.getProfile(Profile.Gender))).label
                                                this.GetOptionsLabel(this.state.GenderOptions, this.getProfile(Profile.Gender))
                                                // this.GetOptionsLabel(this.props.GenderOptions, this.getProfile(Profile.Gender))     //2022.11.01
                                                : Locale("not-specify-gender", this.props.Locale)
                                        }
                                        // placeholder={Locale("not-specify-gender", this.props.Locale)}
                                        onChange={(val) => this.handleSetProfile(Profile.Gender, val)}
                                        value={this.getProfile(Profile.Gender)}
                                        isDisabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* Race */}
                                <div className="form-group">
                                    <label>{Locale("label-race", this.props.Locale)}</label>
                                    <Select
                                        options={this.state.RaceOptions}
                                        // options={this.props.RaceOptions}    //2022.11.01
                                        placeholder={
                                            String(this.getProfile(Profile.Race)).length > 0 ?
                                                // (Array(this.state.RaceOptions).find(x => x.value === this.getProfile(Profile.Race))).label
                                                this.GetOptionsLabel(this.state.RaceOptions, this.getProfile(Profile.Race))
                                                // this.GetOptionsLabel(this.props.RaceOptions, this.getProfile(Profile.Race))     //2022.11.01
                                                : Locale("not-specify-race", this.props.Locale)
                                        }
                                        // placeholder={Locale("not-specify-race", this.props.Locale)}
                                        onChange={(val) => this.handleSetProfile(Profile.Race, val)}
                                        value={this.getProfile(Profile.Race)}
                                        isDisabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                {/* NationalState */}
                                {/* <div className={"form-group" + (this.state.isNationalStateUnselected ? " highlight" : "")}> */}
                                <div className="form-group">
                                    <label>{Locale("label-state", this.props.Locale)} *</label>
                                    <Select
                                        classNamePrefix={'r-select'}
                                        className={(this.state.isNationalStateUnselected ? "select-highlight" : "")}
                                        options={this.state.nationalStateListArray}
                                        // menuIsOpen={this.state.showSelectNationalStateOption}
                                        placeholder={
                                            String(this.getProfile(Profile.NationalState)).length > 0 ?
                                                this.getProfile(Profile.NationalState)
                                                : Locale("label-national-state", this.props.Locale)
                                        }
                                        // onInputChange={(e) => {
                                        //     let inputText = String(e);
                                        //     this.setState({
                                        //         showSelectNationalStateOption: inputText.length >= 2 ? true : false,
                                        //     });
                                        // }}
                                        onChange={(val) => this.handleSetProfile(Profile.NationalState, val)}
                                        value={this.getProfile(Profile.NationalState)}
                                        isDisabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* DistrictArea */}
                                <div className="form-group">
                                    <label>{Locale("label-district", this.props.Locale)}</label>
                                    <Select
                                        classNamePrefix={'r-select'}
                                        className={(this.state.isDistrictAreaUnselected ? "select-highlight" : "")}
                                        options={this.state.filteredByState_DistrictAreaList}
                                        // menuIsOpen={this.state.showSelectDistrictAreaOption}
                                        placeholder={
                                            String(this.getProfile(Profile.DistrictArea)).length > 0 ?
                                                this.getProfile(Profile.DistrictArea)
                                                : Locale("label-district-area", this.props.Locale)
                                        }
                                        // onInputChange={(e) => {
                                        //     let inputText = String(e);
                                        //     this.setState({
                                        //         showSelectDistrictAreaOption: inputText.length >= 2 ? true : false,
                                        //     });
                                        // }}
                                        onChange={(val) => this.handleSetProfile(Profile.DistrictArea, val)}
                                        value={this.getProfile(Profile.DistrictArea)}
                                        // isDisabled={this.state.cachedProfile.DistrictArea === ''}
                                        isDisabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* School */}
                                <div className="form-group">
                                    <label>{Locale("label-school", this.props.Locale)}</label>
                                    <Select
                                        id='r-select-school-home'
                                        classNamePrefix={'r-select'}
                                        options={this.state.schoolListArray}
                                        menuIsOpen={this.state.showSelectSchoolListOption}
                                        placeholder={
                                            String(this.getProfile(Profile.School)).length > 0 ?
                                                this.getProfile(Profile.School)
                                                : Locale("text-school-name", this.props.Locale)
                                        }
                                        onInputChange={(e) => {
                                            let inputText = String(e);
                                            this.setState({
                                                showSelectSchoolListOption: inputText.length >= 2 ? true : false,
                                            });
                                        }}
                                        onChange={(val) => this.handleSetProfile(Profile.School, val)}
                                        value={this.getProfile(Profile.School)}
                                        isDisabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* Center */}
                                <div className="form-group">
                                    <label>{Locale("your-center", this.props.Locale)} ({Locale("optional", this.props.Locale)})</label>
                                    <input name="CenterName" className="form-control" placeholder={Locale("center-name", this.props.Locale)} type="text"
                                        onChange={(val) => this.handleSetProfile(Profile.Center, val)}
                                        value={this.getProfile(Profile.Center)}
                                        disabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* <div className="form-group">
                                    <div className="row">
                                        <aside className="col-sm-12">
                                            <label>{Locale("your-grade", this.props.Locale)} *</label>
                                        </aside>
                                    </div>
                                    <div className="row">
                                        <aside className="col-sm-4">
                                            <DropdownButton
                                                id="signup-dropdown-button"
                                                title={Locale("grade", this.props.Locale)}
                                                drop="up"
                                                onSelect={(val) => this.handleSetProfile(Profile.Grade, val)}
                                            >
                                                {
                                                    this.state.gradeOptions.map((data, key) => {
                                                        return (<Dropdown.Item as="button" eventKey={data.KeyName}>{data.Label}</Dropdown.Item>)
                                                    })
                                                }
                                            </DropdownButton>

                                        </aside>
                                        <aside className="col-sm-8" style={{ alignSelf: 'center' }}>
                                            <input name="" className="form-control" placeholder={this.getProfile(Profile.Grade)} type="text" disabled />
                                        </aside>
                                    </div>
                                </div>

                                <hr /> */}

                                {/* Grade */}
                                {/* <div className={"form-group" + (this.state.isGradeUnselected ? " highlight" : "")}> */}
                                <div className="form-group">
                                    <label>{Locale("your-grade", this.props.Locale)} *</label>
                                    <Select
                                        className={(this.state.isGradeUnselected ? "select-highlight" : "")}
                                        options={this.state.gradeOptions}
                                        placeholder={
                                            this.state.gradeSelection.length > 0 ?
                                                this.getGradeOptionText(this.state.gradeSelection)
                                                :
                                                Locale("grade", this.props.Locale)
                                        }
                                        onChange={(option) => this.handleSetProfile(Profile.Grade, option)}
                                        value={this.getProfile(Profile.Grade)}
                                        isDisabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* Classroom */}
                                <div className="form-group">
                                    <label>{Locale("your-classroom", this.props.Locale)} ({Locale("optional", this.props.Locale)})</label>
                                    <input name="ClassroomName" className="form-control" placeholder={Locale("classroom-name", this.props.Locale)} type="text"
                                        onChange={(val) => this.handleSetProfile(Profile.Classroom, val)}
                                        value={this.getProfile(Profile.Classroom)}
                                        disabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                    />
                                </div>

                                <hr />

                                {/* <div className="form-group">
                                    <label>{Locale("label-sim-student", this.props.Locale)} *</label>
                                    <Select
                                        options={[
                                            { value: 'Not Specify', label: this.getOptionText('Not Specify') },
                                            { value: 'Yes', label: this.getOptionText('Yes') },
                                            { value: 'No', label: this.getOptionText('No') },
                                        ]}
                                        placeholder={this.getOptionText(this.getProfile(Profile.SimStudent))}
                                        onChange={(option) => this.handleSetProfile(Profile.SimStudent, option)}
                                        value={this.getProfile(Profile.SimStudent)}
                                    />
                                </div>

                                <hr /> */}

                                {/* Below13 & Guardian */}
                                <div className="form-group">

                                    <div className='row' style={{ padding: 5, paddingLeft: 15 }}>
                                        <div className='col-1'>
                                            <input name="12AndBelow" type="checkbox"
                                                style={{ width: 20, height: 20 }}
                                                onClick={() => {
                                                    this.setState({
                                                        toggleShowHide_GuardianNameField: !this.state.toggleShowHide_GuardianNameField,
                                                    }, async () => {
                                                        // let _profile = this.props.profile;
                                                        // _profile.Below13 = this.state.toggleShowHide_GuardianNameField + '';
                                                        // await this.props.SetProfile(_profile);
                                                        // this.setState({
                                                        //     cachedProfile: _profile,
                                                        // });
                                                        this.handleSetProfile(Profile.Below13, this.state.toggleShowHide_GuardianNameField)
                                                    })
                                                }}
                                                onChange={() => { }}
                                                value={this.state.toggleShowHide_GuardianNameField}
                                                checked={this.state.toggleShowHide_GuardianNameField}
                                                disabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                            />
                                        </div>
                                        <div className='col-11'>
                                            {Locale("label-12-and-below", this.props.Locale)} ({Locale("optional", this.props.Locale)})

                                            {
                                                this.state.toggleShowHide_GuardianNameField ?
                                                    <>
                                                        <hr />
                                                        {/* <div className={(this.state.isGuardianNameEmpty ? " highlight" : "")}> */}
                                                        <label>{Locale("label-guardian-name", this.props.Locale)} ({Locale("optional", this.props.Locale)})</label>
                                                        <input name="GuardianName"
                                                            // className="form-control"
                                                            className={"form-control" + (this.state.isGuardianNameEmpty ? " highlight" : "")}
                                                            placeholder={Locale("label-guardian-name", this.props.Locale)}
                                                            type="text"
                                                            onChange={(val) => this.handleSetProfile(Profile.Guardian, val)}
                                                            value={this.getProfile(Profile.Guardian)}
                                                            disabled={this.state.ProfileEditPermissionAllowed === false}    //2023.12.06
                                                        />
                                                        {/* </div> */}
                                                    </>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                {/* PolicyTncAgree & PolicyTncAgreeDateTime */}
                                <div className="form-group">
                                    <div className='row'>
                                        <div className='col'>
                                            <label>{Locale("label-agree-policy-tnc", this.props.Locale)} *</label>
                                        </div>
                                    </div>
                                    <div className='row' style={{ padding: 5, paddingLeft: 15 }}>
                                        <div className='col-1'>
                                            <input name="PolicyTnc" type="checkbox"
                                                style={{ width: 20, height: 20 }}
                                                onClick={(val) => this.handleSetProfile(Profile.PolicyTncAgree, val.target.value)}
                                                checked={this.getProfile(Profile.PolicyTncAgree)}
                                                disabled={this.getProfile(Profile.PolicyTncAgree)}
                                            />
                                        </div>
                                        <div className='col-11'>
                                            {Locale("text-accept", this.props.Locale)}
                                            <button
                                                style={{
                                                    background: 'none',
                                                    padding: 0,
                                                    color: 'blue',
                                                    fontWeight: 'bold',
                                                    border: 'none',
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => window.open('https://www.ikeyedutech.com.my/privacy-policy', '_new')}
                                            >{Locale("text-privacy-policy", this.props.Locale)}</button>
                                            {Locale("and", this.props.Locale)}
                                            <button
                                                style={{
                                                    background: 'none',
                                                    padding: 0,
                                                    color: 'blue',
                                                    fontWeight: 'bold',
                                                    border: 'none',
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => window.open('https://www.ikeyedutech.com.my/terms', '_new')}
                                            >{Locale("text-tnc", this.props.Locale)}</button>
                                            {Locale("text-accept-1", this.props.Locale)}
                                            <br />
                                            <span style={{ color: 'grey', fontSize: 12, paddingLeft: 5 }}>
                                                ({Locale("policy-tnc-published", this.props.Locale)}: {moment(this.state.policyTncVersion_PublishedDate).format('ll')})
                                            </span>

                                            {
                                                this.getProfile(Profile.PolicyTncAgree) ?
                                                    <>
                                                        <hr />
                                                        <span style={{ color: 'grey', }}>
                                                            {Locale("policy-tnc-agree-datetime", this.props.Locale)}: {this.getProfile(Profile.PolicyTncAgreeDateTime)}
                                                        </span>
                                                    </>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ paddingLeft: 0, paddingRight: 0 }}>
                            {/* <Row style={{ width: '100%' }}>
                                <Col xs={6} style={{ textAlign: 'left', paddingRight: 0, }}>
                                    <Button variant="primary" onClick={this.handleLogout} style={{ marginBottom: 7 }}>{Locale("signout-logout", this.props.Locale)}</Button>&nbsp;&nbsp;
                                    <Button variant="secondary" onClick={this.ToggleChangePasswordUi}
                                        style={this.props.Locale === Lang.Chinese ? { marginBottom: 7 } : this.props.Locale === Lang.Malay ? { fontSize: 'x-small', height: 38, marginBottom: 7 } : { fontSize: 'smaller', height: 38, marginBottom: 7 }}
                                    >{Locale("label-change-password", this.props.Locale)}</Button>
                                    <Button variant="secondary" onClick={this.ToggleChangeEmailUi}
                                        style={this.props.Locale === Lang.Chinese ? {} : this.props.Locale === Lang.Malay ? { fontSize: 'x-small', height: 38, } : { fontSize: 'smaller', height: 38, }}
                                    >{Locale("label-change-email", this.props.Locale)}</Button>
                                </Col>
                                <Col xs={6} style={{ textAlign: 'right', paddingLeft: 0, }}>
                                    <Button variant="primary" onClick={this.ProfileEditValidation} style={this.props.Locale === Lang.Malay ? { fontSize: 'smaller', height: 38, marginBottom: 7 } : { marginBottom: 7 }}>{Locale("save", this.props.Locale)}</Button>&nbsp;&nbsp;
                                    <Button variant="secondary" onClick={this.ResetProfile} style={this.props.Locale === Lang.Malay ? { fontSize: 'smaller', height: 38, marginBottom: 7 } : { marginBottom: 7 }}>{Locale("reset", this.props.Locale)}</Button>&nbsp;&nbsp;
                                    <Button variant="secondary" onClick={this.ToggleEditProfileUi}>{Locale("close", this.props.Locale)}</Button>
                                </Col>
                            </Row> */}

                            {
                                //2023.11.20 - revamped
                            }
                            <Row style={{ width: '100%' }}>
                                <Col xs={6} style={{ paddingRight: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <Button variant="primary" onClick={this.handleLogout} style={{ marginBottom: 7 }}>{Locale("signout-logout", this.props.Locale)}</Button>
                                    <Button variant="secondary" onClick={this.ToggleChangePasswordUi} style={{ marginBottom: 7 }}>{Locale("label-change-password", this.props.Locale)}</Button>
                                    <Button variant="secondary" onClick={this.ToggleChangeEmailUi}>{Locale("label-change-email", this.props.Locale)}</Button>
                                </Col>
                                <Col xs={6} style={{ paddingLeft: 20, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Button variant="primary" onClick={this.ProfileEditValidation} style={{ marginBottom: 7 }}>{Locale("save", this.props.Locale)}</Button>
                                    <Button variant="secondary" onClick={this.ResetProfile} style={{ marginBottom: 7 }}>{Locale("reset", this.props.Locale)}</Button>
                                    <Button variant="secondary" onClick={this.ToggleEditProfileUi}>{Locale("close", this.props.Locale)}</Button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Modal>

                    {/* Edit Profile - Change Password - Modal - 2022.08.08 */}
                    <Modal show={this.state.toggleChangePasswordUi} onHide={this.ToggleChangePasswordUi} centered
                        className='alert-dialog-bordered'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{Locale("label-change-password", this.props.Locale)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group">
                                <label>{Locale("label-password-new", this.props.Locale)}</label>
                                <input name="change_password_pass" className="form-control" placeholder={'(' + Locale("password-min-req", this.props.Locale) + ')'} type="text"
                                    onChange={(val) => this.setState({ change_newPassword: String(val.target.value), })}
                                    value={this.state.change_newPassword}
                                />
                                <br />
                                <label>{Locale("label-password-retype", this.props.Locale)}</label>
                                <input name="change_password_pass_confirm" className="form-control" placeholder={'(' + Locale("password-min-req", this.props.Locale) + ')'} type="text"
                                    onChange={(val) => this.setState({ change_newPassword_confirm: String(val.target.value), })}
                                    value={this.state.change_newPassword_confirm}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.ToggleChangePasswordUi()}
                                disabled={this.state.isProcessing}
                            >{Locale("close", this.props.Locale)}</Button>
                            <Button variant="secondary" onClick={() => this.setState({ change_newPassword: '', change_newPassword_confirm: '' })}
                                disabled={this.state.isProcessing}
                            >{Locale("clear", this.props.Locale)}</Button>
                            <Button variant="primary" onClick={() => this.ChangePasswordViaApi()}
                                disabled={this.state.isProcessing}
                            >{Locale("confirm", this.props.Locale)}</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Edit Profile - Change Email - Modal - 2023.11.20 */}
                    <Modal show={this.state.toggleChangeEmailUi} onHide={this.ToggleChangeEmailUi} centered
                        className='alert-dialog-bordered'
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{Locale("label-change-email", this.props.Locale)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row'>
                                <div className='col' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>{Locale("email-address", this.props.Locale)}:</span>
                                    <span style={{ fontWeight: 'bold', paddingLeft: 25 }}>{CheckObjectStringEmpty(this.state.cachedProfile, 'Email')}</span>
                                </div>
                            </div>
                            <hr />
                            <div className="form-group">
                                <label>{Locale("label-email-new", this.props.Locale)}</label>
                                <input name="change_email_pass" className="form-control" placeholder={'(e.g. emailAddress@gmail.com)'} type="text"
                                    onChange={(val) => this.setState({ change_newEmail: String(val.target.value), })}
                                    value={this.state.change_newEmail}
                                />
                                <br />
                                <label>{Locale("label-email-retype", this.props.Locale)}</label>
                                <input name="change_email_pass_confirm" className="form-control" placeholder={'(e.g. emailAddress@gmail.com)'} type="text"
                                    onChange={(val) => this.setState({ change_newEmail_confirm: String(val.target.value), })}
                                    value={this.state.change_newEmail_confirm}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.ToggleChangeEmailUi}
                                disabled={this.state.isProcessing}
                            >{Locale("close", this.props.Locale)}</Button>
                            <Button variant="secondary" onClick={() => this.setState({ change_newEmail: '', change_newEmail_confirm: '' })}
                                disabled={this.state.isProcessing}
                            >{Locale("clear", this.props.Locale)}</Button>
                            <Button variant="primary" onClick={this.ChangeEmailViaApi}
                                disabled={this.state.isProcessing}
                            >{Locale("confirm", this.props.Locale)}</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Custom Alert Modal for Policy Tnc Agree checking. */}
                    <Modal show={this.state.showPolicyTncAlertModal}
                        onHide={this.CheckBeforeClose_TogglePolicyTncAlertModal}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span
                                    style={{ fontSize: 18 }}
                                ><b>{Locale("title-attention-needed", this.props.Locale)}</b></span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span style={{ fontSize: 14 }} >
                                <div dangerouslySetInnerHTML={{ __html: Locale("text-policy-tnc-agree-needed", this.props.Locale) }}></div>
                            </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ width: '100%', textAlign: 'center', fontSize: 14 }} >
                                <div className='row'>
                                    <div className='col text-left'>
                                        <label>{Locale("label-agree-policy-tnc", this.props.Locale)} *</label>
                                    </div>
                                </div>
                                <div className='row' style={{ padding: 5, paddingLeft: 15 }}>
                                    <div className='col-1'>
                                        <input
                                            name="PolicyTnc_Agree"
                                            type="checkbox"
                                            style={{ width: 20, height: 20 }}
                                            // onClick={(val) => this.handleSetAgreeOnPolicyTnc(val.target.value)}
                                            onClick={(e) => this.handleSetAgreeOnPolicyTnc(e.currentTarget.checked)}
                                            defaultChecked={this.getProfile(Profile.PolicyTncAgree)}
                                            disabled={this.getProfile(Profile.PolicyTncAgree)}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='col-11 text-left'>
                                        {Locale("text-accept", this.props.Locale)}
                                        <button
                                            style={{
                                                background: 'none',
                                                padding: 0,
                                                color: 'blue',
                                                fontWeight: 'bold',
                                                border: 'none',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => window.open('https://www.ikeyedutech.com.my/privacy-policy', '_new')}
                                        >{Locale("text-privacy-policy", this.props.Locale)}</button>
                                        {Locale("and", this.props.Locale)}
                                        <button
                                            style={{
                                                background: 'none',
                                                padding: 0,
                                                color: 'blue',
                                                fontWeight: 'bold',
                                                border: 'none',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => window.open('https://www.ikeyedutech.com.my/terms', '_new')}
                                        >{Locale("text-tnc", this.props.Locale)}</button>
                                        {Locale("text-accept-1", this.props.Locale)}
                                        {
                                            this.getProfile(Profile.PolicyTncAgree) ?
                                                <>
                                                    <hr />
                                                    <span style={{ color: 'grey', }}>
                                                        {Locale("policy-tnc-agree-datetime", this.props.Locale)}: {this.getProfile(Profile.PolicyTncAgreeDateTime)}
                                                    </span>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.isProfileSyncDone && this.getProfile(Profile.PolicyTncAgree) ?
                                    <>
                                        <hr />
                                        <Button variant='secondary'
                                            onClick={() => {
                                                this.TogglePolicyTncAlertModal(true);   //close ui.
                                            }}>{Locale("close", this.props.Locale)}
                                        </Button>
                                    </>
                                    : null
                            }
                        </Modal.Footer>
                    </Modal>

                    {/* Custom Alert Modal for Profile Info Update checking. */}
                    <Modal show={this.state.show_ProfileInfoRequireUpdate_AlertModal}
                        // onHide={this.Toggle_ProfileInfoRequireUpdate_AlertModal}
                        onHide={this.CheckBeforeClose_Toggle_ProfileInfoRequireUpdate_AlertModal}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span
                                    style={{ fontSize: 18 }}
                                ><b>{Locale("title-attention-needed", this.props.Locale)}</b></span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span style={{ fontSize: 14 }} >
                                <div dangerouslySetInnerHTML={{ __html: Locale("text-profile-update-needed", this.props.Locale) }}></div>
                                {
                                    this.state.errorMessage.length > 0 ?
                                        <>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: this.state.errorMessage }}></div>
                                        </>
                                        : null
                                }
                            </span>
                        </Modal.Body>
                        <Modal.Footer>

                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Button variant='primary'
                                    onClick={() => {
                                        this.ToggleEditProfileUi();
                                        this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                                    }}>{Locale("edit-profile", this.props.Locale)}
                                </Button>
                            </div>

                            {/* <div className='row' style={{ width: '100%' }}>
                                <div className='col-6 text-right'>

                                    <Button variant='secondary'
                                        onClick={() => {
                                            this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                                        }}>{Locale("close", this.props.Locale)}
                                    </Button>

                                </div>
                                <div className='col-6'>

                                    <Button variant='primary'
                                        onClick={() => {
                                            this.ToggleEditProfileUi();
                                            this.Toggle_ProfileInfoRequireUpdate_AlertModal();
                                        }}>{Locale("edit-profile", this.props.Locale)}
                                    </Button>

                                </div>
                            </div> */}

                        </Modal.Footer>
                    </Modal>

                    {/* Custom Alert - Live Quiz Event Detail - 2020-12-15 */}
                    <Modal show={this.state.toggle_LiveQuiz_EventDetail_AlertModal} onHide={this.Toggle_LiveQuiz_EventDetail_AlertModal} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>LIVE QUIZ EVENT DETAILS</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                this.state.liveQuiz_EventDetail_ActionInProgress ?
                                    <LoadingIndicator />
                                    :
                                    <span style={{ fontSize: 14 }} >
                                        <div dangerouslySetInnerHTML={{ __html: this.state.liveQuiz_EventDetail_RoomDetail }}></div>
                                        {
                                            this.state.liveQuiz_EventDetail_HasJoined ?
                                                <>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.liveQuiz_EventDetail_ZoomMeetingDetail }}></div>
                                                    <hr />
                                                    {/* <div dangerouslySetInnerHTML={{ __html: Locale("event-reminders", this.props.Locale) }}></div> */}
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: Locale("event-reminders", this.props.Locale)
                                                            + (this.state.liveQuiz_EventDetail_TelegramInfo.length > 0 ? this.state.liveQuiz_EventDetail_TelegramInfo : '')
                                                            + '</ul>'
                                                    }}></div>
                                                    {/* <div dangerouslySetInnerHTML={{ __html: Locale("event-reminders-telegram-info", this.props.Locale) }}></div> */}
                                                    {
                                                        // this.state.liveQuiz_EventDetail_TelegramInfo.length > 0 ?
                                                        //     <div dangerouslySetInnerHTML={{ __html: this.state.liveQuiz_EventDetail_TelegramInfo + '</ul>' }}></div>
                                                        //     : <div dangerouslySetInnerHTML={{ __html: '</ul>' }}></div>
                                                    }
                                                    {
                                                        CheckBoolean(this.state.selectedEventModal.RoomsHeldOnSelfTime) === false ?
                                                            <div className="borderBlink" style={{ padding: '5px', color: 'red', fontWeight: 'bold' }}>
                                                                <table border="0" width="100%" cellPadding="5">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td width="45%" align="right">{Locale("event-period", this.props.Locale)}</td>
                                                                            <td width="10%" align="center">:</td>
                                                                            {/* <td width="45%" align="left">{this.state.selectedEventModal.Date}</td> */}
                                                                            <td width="45%" align="left">{
                                                                                this.state.selectedEventModal.DateStart === this.state.selectedEventModal.DateEnd ?
                                                                                    moment(this.state.selectedEventModal.DateStart).format('D.M.YYYY')
                                                                                    :
                                                                                    moment(this.state.selectedEventModal.DateStart).format('D.M.YYYY')
                                                                                    + ' ' + Locale("time-to", this.props.Locale) + ' ' +
                                                                                    moment(this.state.selectedEventModal.DateEnd).format('D.M.YYYY')
                                                                            }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="45%" align="right">{Locale("start-time", this.props.Locale)}</td>
                                                                            <td width="10%" align="center">:</td>
                                                                            {/* <td width="45%" align="left">{this.state.selectedEventModal.QuizRooms[0].Rooms[0].TimeStart}</td> */}
                                                                            <td width="45%" align="left">{this.state.quizGlobalTimeStart}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="45%" align="right">{Locale("end-time", this.props.Locale)}</td>
                                                                            <td width="10%" align="center">:</td>
                                                                            {/* <td width="45%" align="left">{this.state.selectedEventModal.QuizRooms[0].Rooms[0].TimeEnd}</td> */}
                                                                            <td width="45%" align="left">{this.state.quizGlobalTimeEnd}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            : null
                                                    }
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.liveQuiz_EventDetail_GroupDetail }}
                                                        onClick={this.CallbackForClickOnRoomCode}
                                                    ></div>
                                                </>
                                                :
                                                <>
                                                    <table width='100%'>
                                                        <tbody>
                                                            <tr><td colSpan='3'><hr /></td></tr>
                                                            <tr><td colSpan='3' align='center'>
                                                                {/* <span>Not Yet Join.</span> */}
                                                                {
                                                                    this.state.liveQuiz_EventDetail_UpdateActionInProgress ?
                                                                        <LoadingIndicator />
                                                                        :
                                                                        <>
                                                                            <div className="form-group">
                                                                                <label>
                                                                                    {Locale("label-center-student-1", this.props.Locale)}
                                                                                    <b>{this.state.selectedCenterName}</b>
                                                                                    {/* <b>{
                                                                                        //2022.05.13
                                                                                        this.state.selectedPartnerName !== '' ?
                                                                                            this.state.selectedPartnerName
                                                                                            : this.state.selectedCenterName
                                                                                    }</b> */}
                                                                                    {Locale("label-center-student-2", this.props.Locale)} *
                                                                                </label>
                                                                                <Select
                                                                                    // ref={ref_IsCenterStudent => { this.ref_IsCenterStudent = ref_IsCenterStudent; }}
                                                                                    ref={this.ref_IsCenterStudent}
                                                                                    options={[
                                                                                        // { value: 'Not Specify', label: this.getOptionText('Not Specify') },
                                                                                        { value: 'Yes', label: this.getOptionText('Yes') },
                                                                                        { value: 'No', label: this.getOptionText('No') },
                                                                                    ]}
                                                                                    value={this.state.selectedEventIsCenterStudent}
                                                                                    // placeholder={this.state.selectedEventIsCenterStudent !== '' ? this.state.selectedEventIsCenterStudent : Locale("not-specify-yes-no", this.props.Locale)}
                                                                                    placeholder={this.state.selectedEventIsCenterStudent !== '' ? this.getOptionText(this.state.selectedEventIsCenterStudent) : Locale("not-specify-yes-no", this.props.Locale)}
                                                                                    onChange={(option) => this.setState({ selectedEventIsCenterStudent: option.value })}
                                                                                />
                                                                            </div>
                                                                            <hr />
                                                                            <div className="form-group">
                                                                                <label>
                                                                                    {Locale("label-select-student-group", this.props.Locale)}
                                                                                    <b>{this.state.selectedEventName}</b> *</label>
                                                                                <Select
                                                                                    // ref={ref_EventGroupOption => { this.ref_EventGroupOption = ref_EventGroupOption; }}
                                                                                    ref={this.ref_EventGroupOption}
                                                                                    options={this.getEventGroupOptions()}
                                                                                    value={this.state.selectedEventGroupOption}
                                                                                    placeholder={this.state.selectedEventGroupOption !== '' ? this.state.selectedEventGroupOption : Locale("not-specify-group", this.props.Locale)}
                                                                                    onChange={(option) => this.setState({ selectedEventGroupOption: option.value, selectedEventGroupOptionId: option.id })}
                                                                                />
                                                                            </div>
                                                                            <hr />
                                                                            <Button variant='primary'
                                                                                style={{
                                                                                    width: 250,
                                                                                    height: 75,
                                                                                }}
                                                                                onClick={async () => {
                                                                                    this.setState({
                                                                                        liveQuiz_EventDetail_UpdateActionInProgress: true,
                                                                                    }, async () => {
                                                                                        // await this.Update_Join_LiveQuiz_Event();
                                                                                        await this.ProcessJoinEventViaAPI();
                                                                                    })
                                                                                }}
                                                                                disabled={String(this.state.selectedEventGroupOption).length <= 0 || String(this.state.selectedEventIsCenterStudent).length <= 0}
                                                                            >{Locale("i-want-to-join", this.props.Locale)}</Button>
                                                                            {/* <br /><br />
                                                                        <div className="form-group">
                                                                            <label>
                                                                                {Locale("label-select-student-group", this.props.Locale)}
                                                                                {this.props.eventName} *</label>
                                                                            <Select
                                                                                options={this.getEventGroupOptions()}
                                                                                value={this.state.selectedEventGroupOption}
                                                                                placeholder={Locale("not-specify-group", this.props.Locale)}
                                                                                onChange={(option) => this.setState({ selectedEventGroupOption: option })}
                                                                            />
                                                                        </div> */}
                                                                        </>
                                                                }
                                                            </td></tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                        }
                                    </span>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.Toggle_LiveQuiz_EventDetail_AlertModal}
                            // disabled={this.state.isProcessing}
                            >{Locale("close", this.props.Locale)}</Button>
                        </Modal.Footer>
                    </Modal>

                </div >
            );
        }
    }
}