const chinese = {
    'overview': 'Overview',
    'create': 'Create',
    'and': '与',
    'co-op-ikey': ' 与 iKEY 所联合主办。',
    'optional': '自选项目',

    //===== Common =====//
    'yes': '是',
    'no': '否',
    'not-specify': '未决定',
    'not-specify-yes-no': '（选择 是 或 否）',

    'signout-logout': '登出',
    'back': '返回',
    'close': '关闭',
    'reset': '重置',
    'save': '储存',
    'clear': '清除',
    'enter': '进入',
    'back-to-home': '返回主页',

    'profile': '个人资料',
    'your-email': '电子邮件',
    'email-address': '电子邮件地址',
    'your-password': '密码',
    'password-min-req': '至少六个字元或以上',     //2021.08.25

    'grade': '（选择教育程度）',
    'label-grade': '教育程度',
    'subject': '科目',

    'pre-school': '学前班',
    'standard': '年级',
    'form': '中',
    'form-123': '初中',
    'form-45': '高中',
    'form-6': '先修班',
    'other': '其它',

    'time': '时间',
    'time-hour': '时',
    'time-min': '分',
    'time-sec': '秒',

    'time-limit': '时间限制',
    'time-hour-full': '小时',
    'time-hour-full-s': '小时',
    'time-min-full': '分钟',
    'time-min-full-s': '分钟',
    'time-to': '至',
    'event-period': '活动日期',

    'correct': '答对',
    'wrong': '答错',
    'confirm': '确定',
    'confirm-type-2': '知道了',

    'label-existing-user': '现有用户',
    'label-new-user': '新用户',

    //Alert
    'error': '错误信息',
    'success': '成功',
    'failed': '失败',
    'invalid-info': '错误信息',
    'invalid-ops': '错误操作',
    'invalid-ops-text': '请更新您的个人资料。',

    'search': '搜寻',
    'search-result': '搜寻结果',
    'processing': '处理中。。。',

    //2023.11.15
    'welcome-title': 'Live Quiz Portal',
    'welcome-note': '欢迎使用 Live Quiz Portal',

    'invalid-login-info': '登入信息错误',
    'email-not-empty': '<b>电子邮件</b> 栏位不能空置，请输入 <b>电子邮件</b>。',
    'password-not-empty': '<b>密码</b> 栏位不能空置，请输入 <b>密码</b>。',
    'name-not-empty': '<b>姓名</b> 栏位不能空置，请输入 <b>姓名</b>。',
    'center-not-empty': '<b>教育中心</b> 栏位不能空置，请输入 <b>教育中心</b>。',
    'grade-not-selected': '<b>教育程度</b> 未选取，请选取 <b>教育程度</b>。',

    //Internet Not Available.
    'label-internet-not-avai': '没有网络',
    'text-internet-not-avai': '没有任何能够用以连接的网络。',
    'label-internet-down': '网络断线',
    'text-internet-down': '网络已断线，未能进一步执行任务。',
    'label-internet-recovering': '网络恢复中',
    'text-internet-recovering': '正在尝试重连网络。。。',
    'label-internet-reconnected': '网络已连接',
    'text-internet-reconnected': '网络连线已成功恢复。',

    //Error - update failed.
    'update-failed': '线上资料更新失败',
    'update-failed-msg': '网络连线遭遇阻断，最近一次更新失败，请刷新浏览器并重试。',   //<br />已保存资料仍然完好无损，敬请放心。',

    //subject.
    'english': '英语',
    'chinese': '华语',
    'malay': '国语',
    'science': '科学',
    'mathematics': '数学',

    //quiz
    'quiz-ended': '已结束',
    'quiz-enter-ended': '已结束',
    'category': '类别',

    //2021.08.26
    //country
    'label-country-my': '+60 (马来西亚)',
    'label-country-sg': '+65 (新加坡)',
    'label-country-cn': '+86 (中国))',
    'label-country-tw': '+886 (台湾)',
    'label-country-jp': '+81 (日本)',

    //2022.08.08
    'label-change-password': '更新密码',
    'label-password-retype': '密码确认',
    'reset-password-warning-text': '在索取密码重置链接前，<br />请确保您的电邮地址能够正常的接收电子邮件。<br />密码只能通过此方法方能重置，<br />如欲更改密码则需要先登入后于个人资料处更改。',
    'change-password-warning-invalid-pass': '请填入正确的<b>新密码</b>。',
    'change-password-warning-invalid-pass-confirm': '请填入正确的<b>新密码确认</b>。',
    'change-password-warning-empty-pass': '请填入<b>新密码</b>。',
    'change-password-warning-empty-pass-confirm': '请填入<b>新密码确认</b>。',
    'change-password-warning-not-match': '<b>密码比对错误</b> 请检查所填入密码。',
    'change-password-result-success': '<b>新密码</b>已成功更改。',

    //2022.11.01
    'label-password-new': '新密码',
    'change-password-warning-too-short-pass': '<b>新密码</b> 过短，请输入长度为<b>至少六个字元或以上</b>的<b>密码</b>。',
    'change-password-warning-too-short-pass-confirm': '<b>确认密码</b> 过短，请输入长度为<b>至少六个字元或以上</b>的<b>密码</b>。',

    //2023.12.01
    // 'label-password-current': '使用中密码',
    // 'label-password-new-confirm': '新密码确认',
    // 'author-change-password-warning-text': '',

    //2023.11.20
    'label-change-email': '更改电子邮件地址',
    'label-email-new': '新电子邮件地址',
    'label-email-retype': '电子邮件地址 (确认)',
    // 'reset-email-warning-text': 'Please make sure your email address is valid<br />and able to receive email before proceed to request for a password reset link.<br />Email can only be update via this method.<br />If you only want to change email again, you may do it again in profile section after login.',
    'change-email-warning-invalid-format': '请填入正确的 <b>新电子邮件地址</b>。',
    'change-email-warning-invalid-format-confirm': '请填入正确的 <b>电子邮件地址 (确认)</b>。',
    'change-email-warning-empty': '请填入 <b>新电子邮件地址</b>。',
    'change-email-warning-empty-confirm': '请填入 <b>电子邮件地址 (确认)</b>。',
    'change-email-warning-not-match': '电子邮件地址比对错误，请检查所填入电子邮件地址。',
    'change-email-result-success': '<b>新电子邮件地址</b> 已成功更改。',

    //2023.11.29
    'label-file-download-success': '文件已成功下载！',
    'notice-file-download-success': "您的文件已成功下载。请至下载资料夹内搜寻该档案。",

    //===== Login Screen =====//
    'event': '活动',
    'organise-by': '主办单位',
    'partnered-with': '合作伙伴', //2022.05.13
    'click-here': '点击<br />此处',
    'click-here-link': '点击此处',
    'coming-soon': '有待公布',  //即将到来
    'private-distribution': '有待公布', //2021.07.14
    'details': '内容',
    'more-info': '更多信息',
    'label-already-have-account-notice': '已有账号 ? 请在下处登入。',

    'signup': '注册',
    'signup-register': '注册',
    'signin-login': '登录',
    'forgot-password': '忘记密码?',
    'keep-email-password': '记忆电邮与密码。',
    'logging-in': '登入中',
    'please-wait': '请稍等。',
    'shared-login-notice': '登入账号与 iKey Kidz App 共用。',

    //2021.04.14
    // 'label-login-error': 'Error signing in with password and email !',
    'notice-login-wrong-password': '糟糕！密码错误！<br /><br />请重试，或点击 “忘记密码” 以通过电子邮件取得密码重置方式',
    'notice-login-user-not-found': '此电子邮件还没登记。<br /><br />请登记您的电子邮件以便登录系统。',


    //===== SignUp Screen =====//
    'your-name': '参与者姓名',
    'your-center': '所属教育中心',
    'your-grade': '现个人教育程度',
    'your-classroom': '课室',
    'full-name': '姓名',
    'center-name': '教育中心',
    'classroom-name': '课室名称',
    'user-creation-in-progress': '个人账号创建中',
    'wait-patiently': '请耐心等候，并在此页面等待该过程结束。',
    'invalid-signup': '登记错误',
    'unable-to-create-user': '账号创建失败。',
    'creating-user-profile': '个人资料创建中',
    'user-profile-created': '个人资料创建成功',
    'redirect-to-login': '转至登入页面。。。',

    'skip-this-if-registered-in-app': '如果您之前已经透过 iKEY Kidz app 或在此网站已注册过帐户，请跳过此页面。',
    'fill-up-required-fields': '所有标注着星号（*）的栏位或选项均为必填项。',

    'email-confirm-not-empty': '<b>电子邮件(确认)</b> 栏位不能空置，请输入 <b>电子邮件(确认)</b>。',
    'password-confirm-not-empty': '<b>密码(确认)</b> 栏位不能空置，请输入 <b>密码(确认)</b>。',
    'email-bad-format': '请以正确规格填入 <b>电子邮件</b>。<br />(例: user@company.com)',
    'email-confirm-bad-format': '请以正确规格填入 <b>电子邮件 (确认)</b>。<br />(例: user@company.com)',
    'email-not-matched': '<b>电子邮件</b> 比对失败，请确保电子邮件一致。',
    'password-not-matched': '<b>密码</b> 比对失败，请确定密码一致。',

    'contact-number': '联络号码',
    'contact-number-sample': '(例: +60167552525)',
    'contact-number-not-empty': '<b>联络号码</b> 栏位不能空置，请输入 <b>联络号码</b>。',
    'contact-number-bad-format': '请以正确规格填入 <b>联络号码</b>。<br />(例: +60167552525)',

    //2021.08.23
    'password-too-short': '<b>密码</b> 过短，请输入长度为<b>至少六个</b>字元或以上的<b>密码</b>。',
    'password-confirm-too-short': '<b>密码(确认)</b> 过短，请输入长度为<b>至少六个</b>字元或以上的<b>密码</b>。',

    //2021.08.26
    'phone-number-sample': '(例: 127552525)',
    'contact-number-too-short': '<b>联络号码</b> 过短，请输入长度为<b>至少八个</b>或总长度不超过十五个字元的号码。',


    //===== SignUp Screen (National Quiz Event) =====//
    'label-state': '州属',
    'label-national-state': '(填入州属, 例: Pulau Pinang)',
    'label-school': '学校',
    'text-school-name': '(以马来文填入学校名字)',
    'text-school-sample': '(例: Chung Hwa 或 以 <b><i>Other</i></b> 表示其它。)',
    'label-agree-policy-tnc': '隐私条例与条款及细则',
    'text-accept': '我接受',
    'text-accept-1': '。',
    'text-privacy-policy': '隐私条例',
    'text-tnc': '条款及细则',
    'national-state-not-selected': '<b>州属</b> 未选取，请选取所属 <b>州属</b>。',
    'school-name-not-empty': '<b>学校</b> 栏位不能空置，请输入所属 <b>学校</b>。',
    'policy-tnc-not-checked': '您必须同意该 <b><u>隐私条例</u></b> 与 <b><u>条款及细则</u></b> 方能继续执行登记程序。',
    'label-guardian-name': '监护人姓名',
    'label-12-and-below': '12岁或以下。',
    'guardian-name-not-empty': '因 <b><u>12岁或以下</u></b> 之选项已勾选，请务必输入<b><u>监护人姓名</u></b>。',
    'label-center-student-1': '你是否为 ',
    'label-center-student-2': ' 的学生 ?',
    'center-student-not-selected-1': '<b>你是否为 ',
    'center-student-not-selected-2': ' 的学生 ?</b> 未选取，请选取 <b>是</b> 或 <b>否</b>。',

    //2020.12.14
    'label-select-student-group': '所属组别 ',
    'not-specify-group': '(选择组别)',
    'student-group-not-selected': '请选择你的 <b>组别</b>。',
    'group-a-abacus': '组别 A (6 岁或以下)',
    'group-b-abacus': '组别 B (7 - 9 岁)',
    'group-c-abacus': '组别 C (10 - 12 岁)',
    'group-d-abacus': '组别 D (13 岁或以上)',

    //2021.07.30
    'label-gender': '性别',
    'not-specify-gender': '(选择性别)',
    'label-gender-male': '男',
    'label-gender-female': '女',
    'label-gender-other': '其他',
    'label-race': '种族',
    'not-specify-race': '(选择种族)',
    'label-race-0': '其他',
    'label-race-1': '华人',
    'label-race-2': '马来人',
    'label-race-3': '印度人',
    // 'label-religion': '信仰',
    // 'not-specify-religion': '(选择信仰)',
    // 'label-religion-0': '其他',
    // 'label-religion-1': '佛教',
    // 'label-religion-2': '基督教',
    // 'label-religion-3': '天主教',
    // 'label-religion-4': '回教',
    // 'label-religion-5': '印度教',

    //2021.10.12
    'label-district': '区域',
    'label-district-area': '(E.g. PPD TIMUR LAUT)',
    'district-area-not-selected': '请选择你的所属 <b>区域</b>。',

    //2022.05.12
    'years-old': '岁',
    'kindergarden': '幼儿班',


    //===== Password Reset Screen =====//
    'password-reset': '密码重置',
    'confirm-your-email': '再次确认您的电子邮件',
    'get-password-reset-link': '取得密码重置电子邮件',
    'confirm-email-not-empty': '<b>确认电子邮件</b> 栏位不能空置，请输入 <b>确认电子邮件</b>。',
    'enter-email-correctly': '请输入正确的<u>电子邮件</u>。',
    'enter-confirm-email-correctly': '请输入正确的<u>确认电子邮件</u>。',
    'reset-password-sent': '密码重置链接已送至您的电子邮件地址。',
    'reset-password-failed': '密码重置失败，请确认输入信息并重新尝试。',
    'email-not-match': '电子邮件比对失败，请确认所输入的电子邮件地址。',
    // 'reset-pass-failed-label-possible-reason': 'Possible reason:',
    'reset-pass-failed-user-not-found': '您所提供的电子邮件地址<u>并没有在我们的系统内进行过注册</u>，请转至注册页面以进行注册。',
    'reset-pass-failed-invalid-email': '您所提供的电子邮件地址格式有<u>错误</u>或<u>不符合规格</u>，请输入<u>符合规格</u>的电子邮件地址并重新尝试。',


    //===== Home Screen =====//
    'today-last-quiz': '最近参加的测验',
    'click-to-enter-room': '点击进入房间',
    'room-code-1': 'Room Code',
    'room-code': '房号',
    'room-title': '房间',
    'room-date': '日期',
    'room-date-of-enter': '进入日期',       //2023.11.16
    'room-submitted-date': '提交日期',      //2023.11.16
    'room-score': '成绩',
    'room-code-ph': '请在此处输入 Room Code。',
    'entering-room': '进入房间',
    'room-code-required': '请输入 Room Code',
    'invalid-room-code-entered': 'Room Code 错误',
    'loading-room-info': '读取房间讯息中',
    'room-not-found': '房间不存在。<br /><br />可能性：<br />(1) 房间只能在当天才能进入。<br />(2) 此装置的日期与时间设置错误。',
    'profile-update-in-progress': '个人资料更新中',
    'click-to-edit-profile': '点击此处以更改个人资料',
    'show-quiz-history': '显示试卷测验历史',
    'title-upcoming-quiz': '临近活动',
    'policy-tnc-agree-datetime': '同意日期',
    'policy-tnc-published': '公布日期',
    'edit-profile': '编辑个人资料',
    'title-attention-needed': '请注意',
    'text-policy-tnc-agree-needed':
        '致所有敬爱的用户，最新的<b><u>隐私条例</u></b>与<b><u>条款及细则</u></b>之内容已更新。'
        + '<br /><br />'
        + '并发现用户未同意与此最新的<b><u>隐私条例</u></b>与<b><u>条款及细则</u></b>之内容。'
        + '<br /><br />'
        + '建议用户请同意与此最新的<b><u>隐私条例</u></b>与<b><u>条款及细则</u></b>之内容以继续使用该服务。',
    'text-history-list-is-empty': '无试卷测验记录。',
    'text-load-history-list': '载入试卷测验记录。',
    'download-ikey-parent-app': "下载 <b><u>iKEY</u></b> 应用以了解更多有关孩子试卷测验成绩的信息。",
    'text-profile-update-needed': '数项空置资料等待更新，请尽快更新您的个人资料。',

    //2020.12.15
    'i-want-to-join': '我想要参加！',
    'quiz-event-join-facebook': '点击此连接 加入我们的 Facebook 群组以了解更多信息。',

    //2021.01.19    //2021.02.01
    'cannot-enter-room-today-title': '该房间在此时间段不可进入。',
    'cannot-enter-room-today-content': '提示: <ul style="list-style-type:decimal;padding-left:20px;"><li>请确保您连接上强力讯号的无线网络(Wifi)以获得更好的体验。</li><li>强烈建议使用Google Chrome浏览器。</li><li>请仅加入适合的组别。</li><li>请在正确的日期和开始时间加入。</li></ul>',

    //2022.10.15
    'event-reminders': '提示: <ul style="list-style-type:decimal;padding-left:20px;"><li>请确保您连接上强力讯号的无线网络(Wifi)以获得更好的体验。</li><li>强烈建议使用 Google Chrome 浏览器。</li><li>请确保比赛当天进入对的组别。</li><li>请在正确的日期与时间作答。</li><li>请在进入房间前<b>确保登入装置的日期与时间正确</b>。</li>',
    'event-reminders-telegram-info': '<li>请加入我们的Telegram群以获得最新消息❗<br />华小: <a href="https://t.me/SPOT_SJKC" target="_blank">https://t.me/SPOT_SJKC</a><br />国小: <a href="https://t.me/SPOT_SK" target="_blank">https://t.me/SPOT_SK</a><br />幼儿园: <a href="https://t.me/SPOT_PreSchool" target="_blank">https://t.me/SPOT_PreSchool</a></li>',

    //2021.02.01
    // 'title-attention': '注意',
    'event-registration-end-datetime': '<span class="blink" style="color:red;"><i class="fa fa-exclamation-triangle"></i> <b>注意</b> <i class="fa fa-exclamation-triangle"></i></span><br /><br />报名截止日期和时间：',
    'event-registration-ended': '此活动报名已截止於 ',

    //2021.11.10
    'event-registration-overloaded': '抱歉，报名截止。',

    //2021.02.17
    'register-now': '立刻报名',

    //2023.12.06
    'title-invalid-operation': '个人资料修改已被禁止',
    'warning-profile-under-maintain-by-organizer': '个人资料目前由主办方负责维护，如有任何更改需求，请联系主办方。',
    'organizers': '主办方',

    //===== Quiz Live Screen =====//
    'no-of-qs': '题目数量',
    'total-duration': '时间限制',
    'begin': '开始',
    // 'waiting-to-start': '等待开始',
    'waiting-to-start': '等待开始<br /><br />如果已到开始时间却仍未显示开始按键，<br />请刷新页面，或返回前页并重新进入房间。',     //2021.08.28
    'loading-qs': '载入题目中。。。',
    'submit-and-show-result': '提交试卷与显示成绩',
    'quiz-result': '试卷成绩',
    'quiz-score': '分数',
    'quiz-total-time-consumed': '所花时间',
    // 'quiz-total-correct': '回答正确',
    // 'quiz-total-wrong': '回答错误',
    'quiz-total-correct': '总共回答正确',
    'quiz-total-wrong': '总共回答错误',
    'question': '题目',
    'question-to': '至',
    'result': '成绩',
    'goto': '捷径',
    'goto-qs': '跳至题目',
    'processing-result': '成绩正在处理中...',
    'time-left': '剩余时间',
    'quiz-time-left': '试卷截止剩余时间',
    'created-date': '试卷创建日期',
    'held-date': '试卷日期',
    'start-time': '开始时间',
    'end-time': '结束时间',
    'participated-date': '参与日期',        //2021.04.05

    //2023.10.18
    'quiz-room-access-restricted-label': '该房间已被禁止',
    'quiz-room-access-restricted-notice': '该房间已被暂时禁止进入<br />请稍后再尝试。',

    //===== Quiz Question component =====//
    'quiz-times-up': "时间结束，此题目未作答",
    // 'quiz-qs-comprehension': '仔细阅读下方的文章，并回答题目',
    'quiz-qs-comprehension': '根据以下文章，选出适合的答案。<br />(题目',
    'quiz-qs-comp-to': '至题目',
    // 'quiz-qs-comp-end': '的问题。',
    'quiz-qs-comp-end': ')',
    'hide-article': '隐藏文章',
    'show-article': '显示文章',
    'next': '下一题',
    'goto-result': '成绩',
    'fill-in-the-blanks': '填充题',
    'comprehension': '理解文',
    'your-answer': '选择答案',
    'correct-answer': '正确答案',
    'times-up': "时间结束",
    'options-for-qs': '答案选项：题目',
    'FITB-instruction-1': '点击 ',
    'FITB-instruction-2': ' 按钮 以选择答案。',
    'subjective': '填充题',
    'enter-answer-here': '在此处填入答案',
    'subjective-instruction': '心算总数，并填入正确答案<br />Enter 或点击 confirm 至下一题',

    //2021.04.30
    'quiz-ans-empty': "此题目无作答",
    'label-quiz-sub-possible-answers': '可能的答案',
    'label-quiz-sub-possible-answers-combination': '可能的答案组合',


    //===== ParticipatedEventList =====//
    'no-event-found': '没找着已参加过的活动。',
    'label-download-certificate': '下载 电子证书 e-Cert (PDF)',
    'label-download-certificate-delayed': '电子证书下载功能尚未开放。',
    'label-download-certificate-delayed-deadline': '电子证书将於 <> 后开放下载。',      //2021.11.16
    'label-download-certificate-delayed-deadline-1': '(有可能提前开放下载)',            //2022.06.11
    'label-view-history': '浏览<br />试卷',
    'label-participated-on-date': '* Participated on ',
    'label-participated-on-date-1': '* 於 ',
    'label-participated-on-date-2': ' 参与了这项活动',
    // 'label-notice-participated-event': '点击下图以下载证书。',
    //2021.08.25
    'label-notice-participated-event': '点击下图以获知如何下载参赛证书。',
    'label-notice-participated-event-innerpagebtn': '点击这里以获知如何下载参赛证书。',

    'label-pe-feature': '此功能需通过 &#60;iKEY&#62; app 联动方能使用',
    // 'notice-pe-download-pdf': '下载证书功能只可通过联动 <b>iKEY</b> app 方能使用。<br /><br />请下载并安装 <b>iKEY</b> app 后<u>通过 app 返回此页面</u>以使用下载证书功能。',
    'notice-pe-download-pdf': '<b>快下载 iKEY APP 以获取电子证书！</b><br /><br />点击<a>这里</a>获取更多详情吧！',
    'notice-pe-load-more': '此功能只可通过联动 <b>iKEY</b> app 方能使用。<br /><br />请下载并安装 <b>iKEY</b> app 后<u>通过 app 返回此页面</u>以载入更多的<u>已参与活动项目</u>。',
    'notice-pe-list-limit-5': '* 下载并安装 <b><u>iKEY app</u></b> 以查看完整的已参与活动之历史目录。',
    'label-load-more': '更多',
    'pdf-download-success': '电子证书已成功下载！',
    'label-pdf-download-reminder': '提醒',
    'notice-pdf-download-reminder': '如果电子证书下载尚未开始，请更新 iKEY app 至最新版本，然后重试。',
    'label-pdf-download-manual-btn': '手动下载电子证书',

    //2021.08.30
    'label-pdf-download-success': '电子证书已成功下载！',
    'notice-pdf-download-success': "您的电子证书已成功下载。请至下载资料夹内搜寻该档案。",

    //2021.09.10
    'label-pdf-download-failed': '下载失败',
    'notice-pdf-download-failed': "下载未能成功。请在稍后时间再重新尝试下载。",

    //2021.12.09
    'click-to-view-participant-works': '点击以查看学生参赛作品',
    'participant-submitted-entries': '学生参赛作品',

    //===== Others =====//
    'quiz-history': 'iKEY 试卷测验历史',
    'last-update': '最后更新',
    'download-ikey-parent-for-complete-history': '* 下载并安装 <a href="#download-app-pos"><b><u>iKEY</u></b></a> app 以查看完整的试卷测验历史目录。',

    //===== QuizRoomTypeFileUpload =====//
    'room-essay-title': '作文题目',
    'room-essay-report-label': '官方报告',
    'room-essay-report-url-pending': '官方报告链接尚未开放。',
    'file-uploaded': '文件',
    'file-upload-date': '文件上载日',
    'file-upload-success': '文件已成功上载。',
    'score-issue-date': '分数揭晓日',
    'not-yet-specify-result': '尚未揭晓',
    'room-essay-upload-ended': '截止日期已至，上载功能已关闭。',      //2021.11.15
    'room-essay-cert-sn-label': '电子证书序号',     //2021.12.07
    'pending': '尚未揭晓',   //2021.12.10

    //===== ManageStudentProfile =====//
    'label-custom-group': '自订组别 (Custom Group)',
    'placeholder-custom-group-name': '(自订组别名称)',
    'placeholder-custom-group-alwaysOnTop': '(置顶)',
    'placeholder-custom-group-displayOrder': '(排序权重)',
    'placeholder-custom-group-active': '(开放选项)',
};
export default chinese;