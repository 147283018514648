
import React from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
// import _ from 'lodash';
// import { ProgressBar } from 'react-bootstrap';

import { Locale } from '../../Localization/CustomLocalization.js';
import LoadingIndicator from './LoadingIndicator.js';
import { CapitalizeJsonKeys, CheckObjectBoolean, CheckObjectNullValue, CheckObjectStringEmpty, DelayUntil, GetPropIds } from '../../components/GlobalFunctions.js';
import { GlobalSetting } from '../../components/GlobalSetting.js';

// import './css/LoadingIndicatorCSS.css';

// import { Delay, DelayUntil, ScrollToElement, GetPropIds } from '../../components/GlobalFunctions';      //2023.09.11


let SyncHistory = {
    None: 'None',
    ToLocal: 'ToLocal',
    ToFireStore: 'ToFireStore',
    Combine: 'Combine',
    DirectSync: 'DirectSync',   //2021.03.27
}


//2020.11.21
export default class QuizHistoryList extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({

        // state = {

        quizRoomCode: '',
        quizRoomId: '',

        todayDT: '',
        isCheckingRoom: false,

        firstLoad: true,        //2025.03.12
        toggleHistoryList: false,
        // isHistoryListSyncDone: false,

        historyList_Local: [],
        isHistoryExists_Local: false,
        historyList_Local_LU: '',

        // hasNewPropertyInProfile: false,
        // toggleShowQuizHistoryList: false,

        //2020.11.23
        historyList_FireStore: [],
        isHistoryExists_FireStore: false,
        historyList_FireStore_LU: '',
        historyList_Final: [],

        update_maxLength: 0,
        update_current: 0,
        update_progressText: '',

        SyncHistory: SyncHistory.None,

        targetRoomInfo: null,     //2021.07.16

        // }
    });

    componentDidMount = async () => {

        // let test = localStorage.getItem('trynullkey');
        // alert(test);

        //#region === obsolete function, checked 2023.11.22
        /*
        // await this.LoadQuizHistoryFromLocal();

        // await this.LoadQuizHistoryFromFireStore();

        // // await this.CompareAndUpdateHistoryList();

        // await this.CompareAndCombine_HistoryList();

        // // await Delay(5000);

        // await this.SyncAndUpdateHistoryList();

        // // this.props.SetAlert("", JSON.stringify(this.state.historyList_Final));
        */
        //#endregion

        //2024.05.13
        await DelayUntil(() => this.props.user !== null);
        await DelayUntil(() => this.props.profile !== null);
        // console.log('QuizHistoryList');
        // await Delay(1000);
        // await this.LoadHistoryItems_ViaApi();
        
        //2025.03.12
        this.setState({
            historyList_Final: [],
            isHistoryListSyncDone: false,
            toggleHistoryList: true,
        });
        this.props.SetHistoryListSyncState(true);

        // //2021.10.14
        // await this.LoadHistoryItemsFromFireStore();

        // this.setState({
        //     toggleHistoryList: true,
        // }, () => {
        //     if (this.props.isFromParentApp) {
        //         this.props.SetHistoryListSyncState(true);
        //     }
        //     else {
        //         this.props.SetHistoryListSyncState(true, this.state.isHistoryExists_Local, this.state.isHistoryExists_FireStore);
        //     }
        //     // this.props.SetHistoryExistanceState(true);

        //     //2020.11.27 = remove old list.
        //     localStorage.removeItem('LiveQuizHistoryList');
        // });

        //2025.03.12 - disabled.
        // if (this.props.isFromParentApp) {
        //     this.props.SetHistoryListSyncState(true);
        // }
        // else {
        //     this.props.SetHistoryListSyncState(true, this.state.isHistoryExists_Local, this.state.isHistoryExists_FireStore);
        // }
        // this.props.SetHistoryExistanceState(true);

        //2020.11.27 = remove old list.
        localStorage.removeItem('LiveQuizHistoryList');

        // alert(JSON.stringify(this.state.historyList_Final));

        // this.setState({
        //     // toggleHistoryList: true,
        //     // toggleHistoryList: this.state.historyList_Final.length > 0 ? true : false,
        // });


        // setTimeout(() => {
        //     this.setState({
        //         toggleHistoryList: true,
        //     }, () => {
        //         // this.props.SetHistoryListSyncState(true);
        //     });
        // }, 2000);
    }

    //2020.12.12
    Delay = ms => new Promise(res => setTimeout(res, ms));

    //=== Alert === starts.
    SetAlert = (title, content) => {
        this.props.SetAlert(title, content);
        this.setState({
            isCheckingRoom: false,
        });
    }

    SetAlertWithProgressBar = (title, content, showBar) => {
        this.props.SetAlertWithProgressBar(title, content, showBar);
        this.setState({
            submitInProgress: false,
        });
    }
    //=== Alert === ends.

    GotoQuizLive = () => {
        this.setState({
            redirectLink: "/quiz/live",
            redirect: true,
        });
        // this.setState(this.getInitState(), () => {
        //     this.setState({
        //         redirectLink: "/quiz/live",
        //         redirect: true,
        //     });
        // });
    }

    GotoLoginPage = () => {
        this.setState({
            redirectLink: "/",
            redirect: true,
        });
    }

    //#region ==== Quiz History List === starts.

    //2024.05.13
    LoadHistoryItems_ViaApi = async () => {

        const { uid, email } = GetPropIds(this.props);

        let success = false;
        let errorMessage = '';
        let list = null;

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/User/RoomHistory/Get/' + uid + '/' + email;
        if (this.props.isDevMode)
            console.log('LoadHistoryItems_ViaApi (url) =\n' + url);

        await fetch(url,
            // Api/LearningCentre/User/RoomHistory/Get/{uid}/{email}
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
            .then(res => res.json())
            .then(data => {
                if (this.props.isDevMode)
                    console.log('LoadHistoryItems_ViaApi (response) =\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    if (CheckObjectNullValue(data, 'data') !== null)
                        if (Array.isArray(data.data))
                            list = data.data;
                }
                else {
                    errorMessage = CheckObjectStringEmpty(data, 'message');
                    if (this.state.isDevMode)
                        console.log('Error', 'api - history list (failed)\n' + JSON.stringify(data));
                }
            })
            .catch(error => {
                errorMessage = CheckObjectStringEmpty(error, 'message');
                if (this.state.isDevMode)
                    console.log('Error', 'api - history list (error)\n' + error.message);
            });

        if (Array.isArray(list))
            list = CapitalizeJsonKeys(list);

        if (errorMessage.length > 0)
            this.props.SetAlert('Failed to load room history.', errorMessage, true);

        //Save.
        this.setState({
            historyList_Final: Array.isArray(list) ? list : [],
            isHistoryListSyncDone: true,
            toggleHistoryList: true,
            firstLoad: false,   //2025.03.12
        }, async () => {
            if (this.state.historyList_Final.length === 0) {
                //temp as backup.
                this.setState({
                    historyList_Final: [],
                    isHistoryListSyncDone: false,
                    toggleHistoryList: false,
                });
                await this.props.SetHistoryListSyncState();
                await this.LoadHistoryItemsFromFireStore();
            }
            await this.props.SetHistoryList(JSON.parse(JSON.stringify(this.state.historyList_Final)));
            await this.props.SetHistoryListSyncState(true);
        });
    }

    //2024.05.13 - now use as backup.
    //2021.10.14
    LoadHistoryItemsFromFireStore = async () => {
        if (this.state.historyList_Final.length <= 0) {
            let _List = [];

            //revamped 2023.08.23
            let colRef = this.props.firestore
                .collection("LiveQuizHistory")
                .doc(String(this.props.user.uid))
                .collection('List');
            //as request by Users to read all room history, agreed by Calvin, edited on 2023.08.23
            // if (this.props.isFromParentApp) {
            //     //get whole list for iKEY app mode.
            //     //do nothing.
            // }
            // else {
            //     //only 5 items for webpage mode.
            //     colRef = colRef.limit(5);
            // }
            if (this.props.user.uid === 'Y2RfhtrgDxg1uKBNAxp3mifGQjC3') {   //limit testing account, save quota.
                colRef = colRef.limit(10);
            }
            await colRef.orderBy('Date', 'desc')
                .get()
                .then(querySnapshot => {
                    let dataArray = [];
                    if (querySnapshot !== null) {
                        querySnapshot.forEach((doc) => {
                            dataArray.push(doc.data());
                        });
                        if (dataArray.length > 0) {
                            dataArray.map((data, key) => {
                                return _List.push(data);
                            });
                        }
                    }
                })
                .catch(error => {
                    if (this.props.isDevMode)
                        console.log(error.message);
                });

            //#region old codes
            // if (this.props.isFromParentApp) {
            //     //get whole list for iKEY app mode.
            //     await this.props.firestore
            //         .collection("LiveQuizHistory")
            //         .doc(String(this.props.user.uid))
            //         .collection('List')
            //         .orderBy('Date', 'desc')
            //         .get()
            //         .then(querySnapshot => {
            //             let dataArray = [];
            //             if (querySnapshot !== null) {
            //                 querySnapshot.forEach((doc) => {
            //                     dataArray.push(doc.data());
            //                 });
            //                 if (dataArray.length > 0) {
            //                     dataArray.map((data, key) => {
            //                         return _List.push(data);
            //                     });
            //                 }
            //             }
            //         })
            //         .catch(error => {
            //             if (this.props.isDevMode)
            //                 console.log(error.message);
            //         });
            // }
            // else {
            //     //only 5 items for webpage mode.
            //     await this.props.firestore
            //         .collection("LiveQuizHistory")
            //         .doc(String(this.props.user.uid))
            //         .collection('List')
            //         .limit(5)
            //         .orderBy('Date', 'desc')
            //         .get()
            //         .then(querySnapshot => {
            //             let dataArray = [];
            //             if (querySnapshot !== null) {
            //                 querySnapshot.forEach((doc) => {
            //                     dataArray.push(doc.data());
            //                 });
            //                 if (dataArray.length > 0) {
            //                     dataArray.map((data, key) => {
            //                         return _List.push(data);
            //                     });
            //                 }
            //             }
            //         })
            //         .catch(error => {
            //             if (this.props.isDevMode)
            //                 console.log(error.message);
            //         });
            // }
            //#endregion

            //Sort.
            // _List.sort((a, b) => a.Id - b.Id);
            // _List.sort((a, b) => moment(a.DisplayOrder) - moment(b.DisplayOrder));

            //Save.
            this.setState({
                historyList_Final: _List,
                isHistoryListSyncDone: true,
                toggleHistoryList: true,
            }, async () => {
                await this.props.SetHistoryList(JSON.parse(JSON.stringify(this.state.historyList_Final)));
            });
        }
    }

    //#region === obsolete function, checked 2023.11.22
    /*
    //2020.11.23
    LoadQuizHistoryFromFireStore = async () => {
        // alert(JSON.stringify(this.props.user));

        let is_FS_history_exists = false;
        let FS_last_update = '';
        let FS_history_list = [];

        //Get FireStore LastUpdate.
        await this.props.firestore
            .collection('LiveQuizHistory')
            .where('Uid', '==', this.props.user.uid)
            .get()
            .then((querySnapshot) => {
                let profiles = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        profiles.push(doc.data());
                    });
                }
                // alert(JSON.stringify(profiles));
                // alert(profiles[0]['LastUpdate'] + "\n" + profiles[0]['Uid'] + "\n" + is_FS_history_exists);
                FS_last_update = profiles[0]['LastUpdate'];

                is_FS_history_exists = querySnapshot !== null && FS_last_update.length > 0;
            })
            .catch(async (error) => {
                // this.SetAlert("Error", error.code + "<br />" + error.message);
                // await this.props.SetErrorLog(new Date(), "LoadQuizHistoryFromFireStore | Error = " + error);
                // alert(error);
            });

        // alert(FS_last_update);
        //2020.11.30
        // if (FS_last_update.toLowerCase() === 'invalid date')
        //2021.03.27
        if (FS_last_update.toLowerCase().includes('invalid'))
            FS_last_update = moment().format('YYYY-MM-DD HH:mm:ss');

        //Get FireStore History List.
        if (is_FS_history_exists) {

            //only fetch FS list if FS LU is latest date.
            if (moment(this.state.historyList_Local_LU) < moment(FS_last_update) || this.state.isHistoryExists_Local === false) {

                await this.props.firestore
                    .collection('LiveQuizHistory')
                    .doc(this.props.user.uid)
                    .collection('List')
                    .get()
                    .then((querySnapshot) => {
                        let dataArray = [];
                        if (querySnapshot !== null) {
                            querySnapshot.forEach((doc) => {
                                dataArray.push(doc.data());
                            });
                            // alert(JSON.stringify(dataArray));
                            // dataArray.sort(function (a, b) {
                            //     if (moment(a.Date) < moment(b.Date)) {
                            //         return 1;
                            //     }
                            //     if (moment(a.Date) > moment(b.Date)) {
                            //         return -1;
                            //     }
                            //     return 0;
                            // });

                            // //2020.12.02
                            // if (dataArray.length > 0) {
                            //     //assign Score property if unavailable.
                            //     dataArray.map((data, key) => {
                            //         if (typeof data !== 'string' && data.hasOwnProperty("Score") === false)
                            //             data.Score = 0;
                            //         return null;
                            //     });
                            // }
                            dataArray = this.SortAndSetDefaultScore(dataArray);
                        }
                        FS_history_list = dataArray;
                    })
                    .catch(async (error) => {
                        // this.SetAlert("Error", error.code + "<br />" + error.message);
                        // await this.props.SetErrorLog(new Date(), "LoadQuizHistoryFromFireStore | Error = " + error);
                        alert(error);
                    });
            }

            //2020.12.11
            FS_history_list = FS_history_list.filter((el) => { return (String(el) !== 'null'); });    //remove null or empty item.
        }

        this.setState({
            isHistoryExists_FireStore: is_FS_history_exists,
            historyList_FireStore: FS_history_list,
            historyList_FireStore_LU: FS_last_update,
        });
    }
    */
    //#endregion

    //#region === obsolete function, checked 2024.05.13
    // //2021.03.27
    // SortAndSetDefaultScore = (dataArray) => {

    //     //sort by date desc.
    //     dataArray.sort(function (a, b) {
    //         if (moment(a.Date) < moment(b.Date)) {
    //             return 1;
    //         }
    //         if (moment(a.Date) > moment(b.Date)) {
    //             return -1;
    //         }
    //         return 0;
    //     });

    //     //2020.12.02
    //     if (dataArray.length > 0) {
    //         //assign Score property if unavailable.
    //         dataArray.map((data, key) => {
    //             if (typeof data !== 'string' && data.hasOwnProperty("Score") === false)
    //                 data.Score = 0;
    //             return null;
    //         });
    //     }

    //     return dataArray;
    // }
    // LoadQuizHistoryFromLocal = async () => {
    //     // let historyInJson = localStorage.getItem("liveQuizHistoryList");
    //     // alert(historyInJson);

    //     let lastUpdate_Local = null;
    //     let isLocalHistoryExists = false;
    //     let historyInJson_Uid = null;
    //     let historyInJson_Legacy = null;
    //     let historyInJson = null;

    //     lastUpdate_Local = localStorage.getItem("LiveQuizHistoryList_LU_" + this.props.user.uid);   //2020.11.23

    //     if (this.props.historyList.length <= 0) {

    //         historyInJson_Legacy = localStorage.getItem("LiveQuizHistoryList");
    //         historyInJson_Uid = localStorage.getItem("LiveQuizHistoryList_List_" + this.props.user.uid);    //new list - 2020.11.23

    //         // alert(lastUpdate_Local);

    //         //old list not needed anymore thus remove it to avoid future possible conflicts, only keep the new list.
    //         if (historyInJson_Legacy !== null && historyInJson_Uid !== null) {
    //             // localStorage.removeItem('LiveQuizHistoryList');
    //             historyInJson = historyInJson_Uid;
    //         }

    //         //if old list exists & new list not found, use back old list as ref.
    //         else if (historyInJson_Legacy !== null && historyInJson_Uid === null) {
    //             historyInJson = historyInJson_Legacy;
    //         }

    //         //if old list removed & new list found, use new list as red.
    //         else if (historyInJson_Legacy === null && historyInJson_Uid !== null) {
    //             historyInJson = historyInJson_Uid;
    //         }

    //     }
    //     else {
    //         historyInJson = JSON.stringify(this.props.historyList);
    //     }
    //     // alert(historyInJson);
    //     // alert(JSON.stringify(historyInJson));

    //     // alert(lastUpdate_Local);
    //     isLocalHistoryExists = historyInJson !== null && historyInJson.length > 0 ? true : false;

    //     //2020.12.12
    //     // let historyInLocal = [];
    //     // if (isLocalHistoryExists) {
    //     //     historyInLocal = JSON.parse(historyInJson);
    //     //     historyInLocal = historyInLocal.filter((el) => { return (String(el) !== 'null'); });    //remove null or empty item.
    //     //     // alert(JSON.stringify(historyInJson));
    //     //     if (historyInLocal.length <= 0)
    //     //         isLocalHistoryExists = false;
    //     // }

    //     // alert(saveLogin + " " + this.state.saveLoginCredential)
    //     if (isLocalHistoryExists) {
    //         // let historyInLocal = isLocalHistoryExists ? JSON.parse(historyInJson) : [];
    //         // let tempList = [];
    //         // historyInLocal.map((data, key) => {
    //         //     return tempList.push(data);
    //         // });
    //         // alert(JSON.stringify(tempList));
    //         // alert(JSON.stringify(historyInLocal));

    //         let historyInLocal = JSON.parse(historyInJson);
    //         historyInLocal = historyInLocal.filter((el) => { return (String(el) !== 'null'); });    //remove null or empty item.
    //         // alert(JSON.stringify(historyInLocal));

    //         if (historyInLocal.length <= 0)
    //             isLocalHistoryExists = false;

    //         if (historyInLocal.length > 0) {

    //             // historyInLocal = historyInLocal.filter((el) => { return (String(el) !== 'null'); });    //remove null or empty item.
    //             // alert(JSON.stringify(historyInLocal));

    //             //Sorted list by Date desc, latest on top.
    //             historyInLocal.sort(function (a, b) {
    //                 if (moment(a.Date) < moment(b.Date)) {
    //                     return 1;
    //                 }
    //                 if (moment(a.Date) > moment(b.Date)) {
    //                     return -1;
    //                 }
    //                 return 0;
    //             });

    //             //2020.12.02
    //             //assign Score property if unavailable.
    //             historyInLocal.map((data, key) => {
    //                 if (typeof historyInLocal[key] !== 'string' &&
    //                     historyInLocal[key].hasOwnProperty("Score") === false)
    //                     historyInLocal[key].Score = '0';
    //                 return null;
    //             });
    //         }

    //         // setTimeout(() => {

    //         this.setState({
    //             historyList_Local: historyInLocal,
    //             // toggleHistoryList: true,     //disabled - 2020.11.23
    //             isHistoryExists_Local: isLocalHistoryExists,
    //             // historyList_Local_LU: lastUpdate_Local === null ? '2020-10-20 15:55:55' : lastUpdate_Local,
    //             historyList_Local_LU: lastUpdate_Local === null ? '' : lastUpdate_Local,
    //         }, () => {
    //             //Save history list to App.js
    //             // this.props.SetHistoryList(this.state.historyList_Local);     //disabled - 2020.11.23

    //             // this.props.editHistory();
    //             // alert(this.state.historyList_Local_LU);

    //             // alert(JSON.stringify(this.state.historyList_Local));

    //             // //test
    //             // let expText = 'Testing\n' + this.state.historyList_Local.length + '\n\n';
    //             // this.state.historyList_Local.map((data, key) => {
    //             //     return expText += "Room : " + String(data.RoomCode) + "\n"
    //             //         + "Id : " + String(data.RoomId) + "\n"
    //             //         + "Time : " + String(data.Date)
    //             //         + " vs "
    //             //         + moment(Number(data.RoomId)).format('YYYY-MM-DD HH:mm:ss') + " (converted)"
    //             //         + "\n\n";
    //             // });
    //             // // alert(expText);
    //             // setTimeout(() => {
    //             //     expText = expText.replace(new RegExp('\n', 'g'), '<br />');
    //             //     this.props.SetAlert('', expText);
    //             // }, 3000);

    //         });

    //         // }, 500);
    //     }
    // }
    //#endregion

    //#region === obsolete function, checked 2023.11.22
    /*
    //2020.11.23
    // ArrayUnique = (array) => {
    //     var a = array.concat();
    //     for (var i = 0; i < a.length; ++i) {
    //         for (var j = i + 1; j < a.length; ++j) {
    //             if (a[i] === a[j])
    //                 a.splice(j--, 1);
    //         }
    //     }

    //     return a;
    // }

    //2020.11.23
    // CompareAndUpdateHistoryList = async () => {
    //     // alert(this.state.historyList_Local_LU + "\n"
    //     //     + this.state.historyList_FireStore_LU + "\n"
    //     //     + "this.state.historyList_Local !== null === " + (this.state.historyList_Local.length) + "\n"
    //     //     + "this.state.historyList_FireStore !== null === " + (this.state.historyList_FireStore.length) + "\n"
    //     //     + "local exists = " + this.state.isHistoryExists_Local + "\n"
    //     //     + "firestore exists = " + this.state.isHistoryExists_FireStore
    //     // );

    //     let finalHistoryList = [];
    //     let syncHistory = SyncHistory.None;
    //     if (
    //         this.state.historyList_FireStore_LU === this.state.historyList_Local_LU
    //         && this.state.isHistoryExists_Local && this.state.isHistoryExists_FireStore
    //     ) {
    //         finalHistoryList = this.state.historyList_Local;
    //         // alert('!');
    //     }
    //     else {
    //         // finalHistoryList = [...new Set(this.state.historyList_FireStore, this.state.historyList_Local)];

    //         let tempList = [];
    //         let isFound = false;
    //         let data2_temp = null;
    //         // if (this.state.historyList_FireStore.length < this.state.historyList_Local.length) {
    //         //     this.state.historyList_FireStore.map((data1, key1) => {
    //         //         isFound = false;
    //         //         data2_temp = null;
    //         //         this.state.historyList_Local.map((data2, key2) => {
    //         //             if (data2.hasOwnProperty('RoomCode')) {
    //         //                 if (data1.RoomCode === data2.RoomCode) {
    //         //                     isFound = true;
    //         //                     data2_temp = data2;
    //         //                 }
    //         //             }
    //         //             return null;
    //         //         });
    //         //         if (!isFound)
    //         //             tempList.push(data2_temp);
    //         //         else
    //         //             tempList.push(data1);
    //         //         return null;
    //         //     });
    //         // }
    //         // else {
    //         //     this.state.historyList_Local.map((data1, key1) => {
    //         //         isFound = false;
    //         //         data2_temp = null;
    //         //         this.state.historyList_FireStore.map((data2, key2) => {
    //         //             if (data2.hasOwnProperty('RoomCode')) {
    //         //                 if (data1.RoomCode === data2.RoomCode) {
    //         //                     isFound = true;
    //         //                     data2_temp = data2;
    //         //                 }
    //         //             }
    //         //             return null;
    //         //         });
    //         //         if (!isFound)
    //         //             tempList.push(data2_temp);
    //         //         else
    //         //             tempList.push(data1);
    //         //         return null;
    //         //     });
    //         // }
    //         this.state.historyList_FireStore.map((data1, key1) => {
    //             isFound = false;
    //             data2_temp = null;
    //             this.state.historyList_Local.map((data2, key2) => {
    //                 // if (data2.hasOwnProperty('RoomCode')) {
    //                 if (data1.RoomCode === data2.RoomCode) {
    //                     isFound = true;
    //                     data2_temp = data2;
    //                 }
    //                 // }
    //                 return null;
    //             });
    //             if (!isFound)
    //                 tempList.push(data2_temp);
    //             else
    //                 tempList.push(data1);
    //             return null;
    //         });
    //         finalHistoryList = tempList;


    //         if (this.state.isHistoryExists_Local && this.state.isHistoryExists_FireStore) {

    //             // //Compare with Last Update if both exists.
    //             // if (moment(this.state.historyList_FireStore_LU) > moment(this.state.historyList_Local_LU)) {
    //             //     // finalHistoryList = this.state.historyList_FireStore;
    //             //     // finalHistoryList = ArrayUnique(finalHistoryList.concat(this.state.historyList_Local));

    //             //     finalHistoryList = _.union(this.state.historyList_FireStore, this.state.historyList_Local);
    //             //     // finalHistoryList = [...new Set([this.state.historyList_FireStore, this.state.historyList_Local])];
    //             // }

    //             //Combine 2 list into 1.
    //             // finalHistoryList = _.union(this.state.historyList_FireStore, this.state.historyList_Local);
    //             // finalHistoryList = [...new Set(this.state.historyList_FireStore, this.state.historyList_Local)];


    //             // let tempList = [...new Set(this.state.historyList_FireStore, this.state.historyList_Local)];
    //             // let tempList = [];
    //             // let isFound = false;
    //             // let data2_temp = null;
    //             // this.state.historyList_FireStore.map((data1, key1) => {
    //             //     isFound = false;
    //             //     data2_temp = null;
    //             //     this.state.historyList_Local.map((data2, key2) => {
    //             //         if (data2.hasOwnProperty('RoomCode')) {
    //             //             if (data1.RoomCode === data2.RoomCode) {
    //             //                 isFound = true;
    //             //                 data2_temp = data2;
    //             //             }
    //             //         }
    //             //         return null;
    //             //     });
    //             //     if (!isFound)
    //             //         tempList.push(data2_temp);
    //             //     else
    //             //         tempList.push(data1);
    //             //     return null;
    //             // });
    //             // finalHistoryList = tempList;


    //             if (this.state.historyList_Local_LU === null || this.state.historyList_FireStore_LU === null) {
    //                 if (this.state.historyList_Local_LU === null && this.state.historyList_FireStore_LU !== null) {
    //                     syncHistory = SyncHistory.ToLocal;
    //                 }
    //                 else if (this.state.historyList_Local_LU !== null && this.state.historyList_FireStore_LU === null) {
    //                     syncHistory = SyncHistory.ToFireStore;
    //                 }
    //                 else {
    //                     syncHistory = SyncHistory.Combine;
    //                 }
    //             }
    //             else {
    //                 if (moment(this.state.historyList_FireStore_LU) > moment(this.state.historyList_Local_LU)) {
    //                     syncHistory = SyncHistory.ToLocal;
    //                 }
    //                 else if (moment(this.state.historyList_FireStore_LU) < moment(this.state.historyList_Local_LU)) {
    //                     syncHistory = SyncHistory.ToFireStore;
    //                 }
    //                 else {
    //                     //Combine 2 list into 1.
    //                     syncHistory = SyncHistory.Combine;
    //                 }
    //             }
    //         }
    //         else {
    //             if (this.state.isHistoryExists_Local && !this.state.isHistoryExists_FireStore) {
    //                 // finalHistoryList = this.state.historyList_Local;
    //                 syncHistory = SyncHistory.ToFireStore;
    //             }
    //             else if (!this.state.isHistoryExists_Local && this.state.isHistoryExists_FireStore) {
    //                 // finalHistoryList = this.state.historyList_FireStore;
    //                 syncHistory = SyncHistory.ToLocal;
    //             }
    //             // else {
    //             //     syncHistory = SyncHistory.Combine;
    //             // }
    //         }
    //     }

    //     // if (this.state.SyncHistory === SyncHistory.Combine) {
    //     //     // Combine 2 list into 1.
    //     //     // finalHistoryList = _.union(this.state.historyList_FireStore, this.state.historyList_Local);
    //     //     finalHistoryList = [...new Set(this.state.historyList_FireStore, this.state.historyList_Local)];
    //     // }


    //     // //2020.12.01
    //     // if (finalHistoryList.length > 0) {
    //     //     //2020.12.10
    //     //     finalHistoryList = finalHistoryList.filter((el) => { return (String(el) !== 'null'); });    //remove null or empty item.

    //     //     //Sorted list by Date desc, latest on top.
    //     //     finalHistoryList.map((data, key) => {
    //     //         if (finalHistoryList[key] !== null &&
    //     //             typeof finalHistoryList[key] !== 'string' &&
    //     //             finalHistoryList[key].hasOwnProperty("Score") === false)
    //     //             finalHistoryList[key].Score = '0';
    //     //         return null;
    //     //     });
    //     // }

    //     // let dataString = JSON.stringify(finalHistoryList);
    //     // navigator.clipboard.writeText(dataString);
    //     // alert(this.state.historyList_Local.length + "\n\n" + this.state.historyList_FireStore.length + "\n\n" + finalHistoryList.length);

    //     this.props.SetHistoryList(finalHistoryList);

    //     this.setState({
    //         historyList_Final: finalHistoryList,
    //         update_maxLength: finalHistoryList.length,
    //         update_current: 0,
    //         update_progressText: 'Processing history list...',
    //         SyncHistory: syncHistory,
    //     }, () => {
    //         //Save history list to App.js
    //         // this.props.SetHistoryList(this.state.historyList_Final);
    //         // this.props.SetHistoryList(finalHistoryList);

    //         // alert(String(this.state.SyncHistory));

    //         alert(this.state.historyList_Local.length + "\n\n"
    //             + this.state.historyList_FireStore.length + "\n\n"
    //             + finalHistoryList.length + "\n\n"
    //             + this.state.historyList_Final.length + "\n\n"
    //             + this.props.historyList.length);
    //     });
    // }


    //2020.11.23
    SyncAndUpdateHistoryList = async () => {
        let isUpdateSuccess = true;

        // //Update to FireStore data.
        // await this.props.firestore.collection('LiveQuizHistory')
        //     .doc(this.props.user.uid)
        //     .collection('List')
        //     .set(this.state.historyList_Final)
        //     .then(() => {
        //         isUpdateSuccess = true;
        //     })
        //     .catch(async (error) => {
        //         // this.SetAlert("Error", error.code + "<br />" + error.message);
        //         await this.props.SetErrorLog(new Date(), "SyncAndUpdateHistoryList | FireStore | Error = " + error);
        //     });

        if (this.state.SyncHistory === SyncHistory.ToFireStore || this.state.SyncHistory === SyncHistory.Combine) {
            //Update to FireStore data.
            let itemId = '';
            this.state.historyList_Final.map(async (data, key) => {
                itemId = moment(data.Date).format('YYYYMMDDHHmmss');
                await this.props.firestore.collection('LiveQuizHistory')
                    .doc(this.props.user.uid)
                    .collection('List')
                    .doc(itemId)    //e,g, 20201123142035
                    .set({
                        "Date": data.Date,
                        "RoomCode": data.RoomCode,
                        "RoomId": data.RoomId,
                        "Title": data.Title,
                        "Score": data.Score,
                    })
                    .then(() => {
                        // isUpdateSuccess = true;
                        let current = this.state.update_current + 1;
                        let percent = (current / this.state.update_maxLength) * 100;
                        this.setState({
                            update_current: current,
                            update_progressText: 'Processing history list...(' + percent.toFixed(0) + '%)',
                        })
                    })
                    .catch(async (error) => {
                        isUpdateSuccess = false;
                        // this.SetAlert("Error", error.code + "<br />" + error.message);
                        await this.props.SetErrorLog(new Date(), "SyncAndUpdateHistoryList | FireStore | Error = " + error);
                    });
                return null;
            });

            if (isUpdateSuccess) {

                //Update new Last Update DT on FireStore.
                await this.props.firestore.collection('LiveQuizHistory')
                    .doc(this.props.user.uid)
                    .set({
                        'LastUpdate': moment(this.state.historyList_Local_LU).format('YYYY-MM-DD HH:mm:ss'),
                        'Uid': this.props.user.uid
                    })
                    .then(() => {
                        // isUpdateSuccess = true;
                    })
                    .catch(async (error) => {
                        // this.SetAlert("Error", error.code + "<br />" + error.message);
                        await this.props.SetErrorLog(new Date(), "SyncAndUpdateHistoryList | FireStore | Error = " + error);
                    });
            }
        }
        if (this.state.SyncHistory === SyncHistory.ToLocal || this.state.SyncHistory === SyncHistory.Combine) {
            // alert(this.state.historyList_FireStore_LU);
            //Update new Last Update in localStorage.
            localStorage.setItem("LiveQuizHistoryList_LU_" + this.props.user.uid, moment(this.state.historyList_FireStore_LU).format('YYYY-MM-DD HH:mm:ss'));
            localStorage.setItem("LiveQuizHistoryList_List_" + this.props.user.uid, JSON.stringify(this.state.historyList_Final));
        }
        this.setState({
            toggleHistoryList: true,
        });
        // this.props.SetAlert("", JSON.stringify(this.state.historyList_Final));
    }

    //2020.12.11    //revamp whole logic.
    CompareAndCombine_HistoryList = async () => {

        let _local_HistoryList = [];
        let _fs_HistoryList = [];
        let _final_HistoryList = [];
        let _syncHistory = SyncHistory.None;

        //No Local & No FS.
        if (!this.state.isHistoryExists_Local && !this.state.isHistoryExists_FireStore) {
            _final_HistoryList = [];
            _syncHistory = SyncHistory.None;
        }

        //Has Local, no FS.
        if (this.state.isHistoryExists_Local && !this.state.isHistoryExists_FireStore) {
            _final_HistoryList = JSON.parse(JSON.stringify(this.state.historyList_Local));
            _syncHistory = SyncHistory.ToFireStore;
        }

        //No Local, Has FS.
        if (!this.state.isHistoryExists_Local && this.state.isHistoryExists_FireStore) {
            _final_HistoryList = JSON.parse(JSON.stringify(this.state.historyList_FireStore));
            _syncHistory = SyncHistory.ToLocal;
        }

        //Has Local, Has FS. Compare LastUpdate.
        if (this.state.isHistoryExists_Local && this.state.isHistoryExists_FireStore) {

            // _local_HistoryList = JSON.parse(JSON.stringify(this.state.historyList_Local));
            // _fs_HistoryList = JSON.parse(JSON.stringify(this.state.historyList_FireStore));
            _local_HistoryList = this.state.historyList_Local;
            _fs_HistoryList = this.state.historyList_FireStore;

            let _local_LastUpdate = moment(this.state.historyList_Local_LU);
            let _fs_LastUpdate = moment(this.state.historyList_FireStore_LU);

            let _is_local_LU_invalid = String(_local_LastUpdate).toLowerCase().includes('invalid');
            if (_is_local_LU_invalid)
                _local_HistoryList = moment('2020-01-01 15:55:55');

            let _is_fsLU_invalid = String(_fs_LastUpdate).toLowerCase().includes('invalid');
            if (_is_fsLU_invalid)
                _fs_LastUpdate = moment('2020-01-01 15:55:55');

            //set loop.
            let list1 = [];
            let list2 = [];
            if (_local_HistoryList.length >= _fs_HistoryList.length) {
                list1 = _local_HistoryList;
                list2 = _fs_HistoryList;
            }
            else {
                list1 = _fs_HistoryList;
                list2 = _local_HistoryList;
            }

            // alert(JSON.stringify(list1) + "\n\n" + JSON.stringify(list2));
            // this.props.SetAlert('', JSON.stringify(list1));
            // this.props.SetAlert('', JSON.stringify(list2));

            //Combine both list into one.
            let tempList = [];
            let isFound = false;
            let data2_temp = null;
            list1.map((data1, key1) => {
                isFound = false;
                data2_temp = null;
                list2.map((data2, key2) => {
                    // if (data2.hasOwnProperty('RoomCode')) {                    
                    if (data1.RoomCode === data2.RoomCode) {
                        isFound = true;
                        data2_temp = data2;
                    }
                    // }
                    return null;
                });
                if (!isFound)
                    tempList.push(data1);
                else
                    tempList.push(data2_temp);
                return null;
            });
            _final_HistoryList = tempList;
            // this.props.SetAlert('', JSON.stringify(_final_HistoryList));

            //Local is newer than FS.
            if (_local_LastUpdate > _fs_LastUpdate) {
                _syncHistory = SyncHistory.ToFireStore;
            }
            //FS is newer than Local.
            else if (_local_LastUpdate < _fs_LastUpdate) {
                _syncHistory = SyncHistory.ToLocal;
            }
            //Local is same as FS.
            else if (_local_LastUpdate === _fs_LastUpdate) {

                if (!_is_local_LU_invalid && !_is_local_LU_invalid) {
                    //both LU are the same, but also valid.

                    //do nothing.

                    //if Local list is empty by chance, combine list.
                    if (_local_HistoryList.length <= 0 && _fs_HistoryList.length > 0) {
                        _syncHistory = SyncHistory.Combine;
                    }
                }
                else {
                    //both LU are the same, but both also are Invalid Date, so do nothing.
                    // _final_HistoryList = [];
                    // _syncHistory = SyncHistory.Combine;
                }
            }
            else {
                // _final_HistoryList = [];
                // _syncHistory = SyncHistory.Combine;
            }
        }

        //remove null or empty item.
        // if (_final_HistoryList.length > 0)
        //     _final_HistoryList = _final_HistoryList.filter((el) => { return (el !== null); });

        // navigator.clipboard.writeText(JSON.stringify(_final_HistoryList));
        // this.props.SetAlert('', JSON.stringify(_final_HistoryList));

        await this.props.SetHistoryList(JSON.parse(JSON.stringify(_final_HistoryList)));
        // this.props.SetAlert('', JSON.stringify(this.props.historyList));
        // this.props.SetAlert('', JSON.stringify(_final_HistoryList));
        // return null;

        // await Delay(2000);

        //save states.
        this.setState({
            historyList_Final: _final_HistoryList,
            SyncHistory: _syncHistory,
        }, () => {
            // this.props.SetHistoryList(this.state.historyList_Final);
            // this.props.SetAlert('', JSON.stringify(this.props.historyList));

            // alert("historyList_Local : " + this.state.historyList_Local.length + "\n"
            //     + this.state.isHistoryExists_Local + "\n"
            //     + this.state.historyList_Local_LU + "\n\n"

            //     + "historyList_FireStore : " + this.state.historyList_FireStore.length + "\n"
            //     + this.state.isHistoryExists_FireStore + "\n"
            //     + this.state.historyList_FireStore_LU + "\n\n"

            //     + "historyList_Final : " + this.state.historyList_Final.length + "\n\n"

            //     + "SyncHistory : " + SyncHistory[this.state.SyncHistory] + "\n\n"

            //     + "App > historyList : " + this.props.historyList.length);
        });
    }
    */
    //#endregion

    //#region === obsolete function, checked 2024.05.13
    // //2021.03.27 - continue coded. fetch directly from FS & not reading local cached histories, no sync is carry out.
    // //2020.12.12 //direct grab from FS & show in list.
    // Fetch_HistoryList_FS_Directly_NoReadLocalCache = async () => {
    //     let FS_history_list = [];
    //     await this.props.firestore
    //         .collection('LiveQuizHistory')
    //         .doc(this.props.user.uid)
    //         .collection('List')
    //         .get()
    //         .orderBy("Date", "desc")
    //         .limit(5)
    //         .then((querySnapshot) => {
    //             let dataArray = [];
    //             if (querySnapshot !== null) {
    //                 querySnapshot.forEach((doc) => {
    //                     dataArray.push(doc.data());
    //                 });
    //                 // alert(JSON.stringify(dataArray));
    //                 // dataArray = this.SortAndSetDefaultScore(dataArray);
    //                 if (dataArray.length > 0) {
    //                     //assign Score property if unavailable.
    //                     dataArray.map((data, key) => {
    //                         if (typeof data !== 'string' && data.hasOwnProperty("Score") === false)
    //                             data.Score = 0;
    //                         return null;
    //                     });
    //                 }
    //             }
    //             FS_history_list = dataArray;
    //         })
    //         .catch(async (error) => {
    //             // this.SetAlert("Error", error.code + "<br />" + error.message);
    //             // await this.props.SetErrorLog(new Date(), "Fetch_HistoryList_FS_Directly_NoReadLocalCache | Error = " + error);
    //             alert(error);
    //         });
    //     if (FS_history_list.length > 0) {
    //         this.setState({
    //             historyList_Final: FS_history_list,
    //             isHistoryListSyncDone: true,
    //             toggleHistoryList: true,
    //         });
    //     }
    // }
    // //2021.03.27 - save History directlt to FS. single transaction.
    // SaveTo_HistoryList_FS_Directly_NoLocalCache = async (data) => {
    //     //save item.
    //     await this.props.firestore
    //         .collection('LiveQuizHistory')
    //         .doc(this.props.user.uid)
    //         .collection('List')
    //         .doc(moment(data.Date).format('YYYYMMDDHHmmss'))    //e,g, 20201123142035
    //         .set({
    //             "Date": data.Date,
    //             "RoomCode": data.RoomCode,
    //             "RoomId": data.RoomId,
    //             "Title": data.Title,
    //             "Score": data.Score,
    //         })
    //         .catch(async (error) => {
    //             // this.SetAlert("Error", error.code + "<br />" + error.message);
    //             await this.props.SetErrorLog(new Date(), "SaveTo_HistoryList_FS_Directly_NoLocalCache | Error = " + error);
    //         });

    //     //save Last Update.
    //     await this.props.firestore
    //         .collection('LiveQuizHistory')
    //         .doc(this.props.user.uid)
    //         // .doc('LastUpdate')
    //         // .set(moment().format('YYYY-MM-DD HH:mm:ss'))
    //         .update({ 'LastUpdate': moment().format('YYYY-MM-DD HH:mm:ss') })
    //         .catch(async (error) => {
    //             // this.SetAlert("Error", error.code + "<br />" + error.message);
    //             await this.props.SetErrorLog(new Date(), "SaveTo_HistoryList_FS_Directly_NoLocalCache | Error = " + error);
    //         });
    // }
    //#endregion

    EnterHistoryRoom = async (id, roomCode) => {
        // alert(this.props.historyList.length + "\n\n" + id);

        //2020.11.30
        if (this.props.isInternetReachable === false) {
            this.props.ShowInternetNotAvailableAlert();
            return null;
        }

        //2020.11.27
        // let _state = await this.props.CheckPolicyTncAgreeState();

        //2024.05.23
        let _state = this.props.policyTnc_enableChecking === false ? true : await this.props.CheckPolicyTncAgreeState();

        if (this.props.historyList.length > 0 && _state) {

            // alert("Enter Room #" + (id + 1) + " (" + this.props.historyList[id].RoomCode + ")");
            // this.SetAlertWithProgressBar("Entering Room #" + (id + 1) + " (" + this.props.historyList[id].RoomCode + ")...", "Please wait for awhile.", true);
            this.SetAlertWithProgressBar(Locale("entering-room", this.props.Locale) + (id + 1) + " (" + this.props.historyList[id].RoomCode + ")...",
                Locale("please-wait", this.props.Locale), true);

            //Update Room Code & Room Id.
            this.props.setRoomCodeId(this.props.historyList[id].RoomCode, this.props.historyList[id].RoomTimeStampId);

            //Check if Viewing history Quiz.
            // let quizDate = moment(this.props.historyList[id].Date);

            //2021.07.16
            await this.GetRoomInfo(this.props.historyList[id].RoomCode);
            let quizDate = this.state.targetRoomInfo === null ? moment(this.props.historyList[id].Date) : moment(this.state.targetRoomInfo.DateStart);

            if (moment().format("YYYYMMDD") > quizDate.format("YYYYMMDD"))
                this.props.SetViewHistoryQuiz(quizDate.format("YYYYMMDD"));
            else
                this.props.SetViewHistoryQuiz(null);

            // console.log(moment().toString() + "\n\n" + quizDate.toString() + "\n\n" + (moment() > quizDate));

            //2021.04.06
            await this.props.CheckIfRoomBelongsToEvent(this.props.historyList[id].RoomCode);

            setTimeout(() => {
                this.props.CloseAlert();

                //2021.11.10 - revamped.
                if (this.state.targetRoomInfo !== null) {
                    if (this.state.targetRoomInfo.hasOwnProperty('RoomType')) {
                        if (Number(this.state.targetRoomInfo.RoomType) === 1) {
                            this.setState({
                                redirectLink: '/quiz/roomTypeUploadFile',
                                redirect: true,
                            });
                        }
                        else {
                            this.GotoQuizLive();
                        }
                    }
                    else {
                        this.GotoQuizLive();
                    }
                }
                else {
                    this.props.SetAlert('', 'Quiz room is unavailable.', false);
                }
            }, 1000);
        }
    }

    GetHistoryListItemComponent = (data, key) => {
        return (
            <tr
                key={key}
                // onClick={() => this.EnterHistoryRoom(key)} key={key}
                title={Locale("click-to-enter-room", this.props.Locale) + " " + data.RoomCode}
            >
                <td>{key + 1}</td>
                <td style={{ textAlign: "left" }}>
                    <span
                        className={'pointer'}
                        style={{ color: "blue", }}
                        onClick={() => this.EnterHistoryRoom(key, data.RoomCode)} key={key}
                    ><u>{data.RoomCode}</u></span>
                    {/* <a href="#">
                    {data.RoomCode}
                </a> */}
                    {/* <span className="spnTooltip">{"Click to enter Room " + data.RoomCode}</span> */}
                </td>
                {/* <td style={{ textAlign: "left" }}>{data.Title}{this.state.debugMode ? "[" + data.RoomId + "]" : ""}</td> */}
                <td style={{ textAlign: "left" }}>{CheckObjectStringEmpty(data, 'RoomTitle', CheckObjectStringEmpty(data, 'Title'))}{this.state.debugMode ? "[" + data.RoomTimeStampId + "]" : ""}</td>
                <td>{this.GetScore(data.Score)}</td>
                {/* <td>{this.GetScore(data.Score)} {String(typeof data !== 'string')} {String(typeof data.Score)}</td> */}
                {/* <td>{moment(data.Date).format("ll")}</td> */}
                <td>{this.GetDateEnter(data)}</td>
            </tr>
        );
    }

    //2024.05.14
    GetDateEnter = (data = null) => {
        if (data === null)
            return '';

        const firstEnterDateUtc = CheckObjectStringEmpty(data, 'FirstEnteredDateTimeOnUtc');
        const date = CheckObjectStringEmpty(data, 'Date');

        if (firstEnterDateUtc !== '')
            return moment(firstEnterDateUtc).local().format('ll');
        if (date !== '')
            return moment(date).format('ll');
        return '';
    }

    //#endregion ==== Quiz History List === ends.

    //2021.07.16
    GetRoomInfo = async (roomCode) => {
        var isRoomFound = false;
        var _data = null;

        //Search Room in new FS locaition.
        await this.props.firestore.collection("LiveQuiz_UniqueRoomCode")
            .where('RoomCode', '==', Number(roomCode))
            .get()
            .then(querySnapshot => {
                // isEmailNotExist = !querySnapshot.exists;
                let data = [];
                if (querySnapshot !== null) {
                    querySnapshot.forEach((doc) => {
                        data.push(doc.data());
                    });
                    if (data.length > 0)
                        data = data[0];
                    else
                        data = null;
                }
                // alert(this.state.quizRoomCode + "\n\n" + data.hasOwnProperty('RoomId') + "\n\n" + JSON.stringify(data));
                if (data !== null && data.hasOwnProperty('RoomId')) {
                    isRoomFound = true;
                    _data = data;
                }
            });
        // .catch(() => {
        //     // isRoomFound = false;
        //     alert('Room ' + this.state.quizRoomCode + ' - no data found');
        // });
        if (isRoomFound) {
            if (_data.hasOwnProperty('DateStart')) {
                this.setState({ targetRoomInfo: _data });
            }
        }
    }

    //2020.11.23
    GetScore = (score) => {
        return score !== undefined
            && score !== null
            && String(score) !== ''
            && String(score).length > 0
            && score > 0    //2020.12.01
            ? score : '-';
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        else {
            if (!this.props.isHistoryListSyncDone) {
                return (
                    <div className="card">
                        <article className="card-body">

                            <LoadingIndicator />
                            <center><span>{this.state.update_progressText}</span></center>

                        </article>
                    </div>
                );
            }
            else {
                return (
                    this.state.toggleHistoryList ?
                        this.state.historyList_Final.length > 0 ?
                            <>
                                {/* <span>&nbsp;</span> */}
                                <div className="card">
                                    <article className="card-body">

                                        {/* <p><h5><b><u>&nbsp;Quiz History&nbsp;</u></b></h5></p> */}

                                        <div className="row custyle">
                                            <table className="table custab pointer">
                                                <thead>
                                                    <tr><td colSpan="4"><b>{Locale("today-last-quiz", this.props.Locale)}</b></td></tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        onClick={() => this.EnterHistoryRoom(0, this.state.historyList_Final[0].RoomCode)}
                                                        title={Locale("click-to-enter-room", this.props.Locale) + " " + this.state.historyList_Final[0].RoomCode}
                                                    >
                                                        {/* <td width="5%">&nbsp;</td>
                                                        <td width="20%" style={{ textAlign: "left" }}>{Locale("room-code", this.props.Locale)}<br />{this.state.historyListInQuizHome[0].RoomCode}</td>
                                                        <td width="50%" style={{ textAlign: "left" }}>{Locale("room-title", this.props.Locale)}<br />{this.state.historyListInQuizHome[0].Title}</td>
                                                        <td width="25%">{Locale("room-date", this.props.Locale)}<br />{moment(this.state.historyListInQuizHome[0].Date).format("ll")}</td>
                                                        <td width="3%">&nbsp;</td> */}

                                                        <td width="15%" style={{ textAlign: "left", paddingLeft: 15 }}>{Locale("room-code", this.props.Locale)}<br />{this.state.historyList_Final[0].RoomCode}</td>
                                                        <td width="50%" style={{ textAlign: "left" }}>{Locale("room-title", this.props.Locale)}<br />{CheckObjectStringEmpty(this.state.historyList_Final[0], 'RoomTitle', CheckObjectStringEmpty(this.state.historyList_Final[0], 'Title'))}</td>
                                                        <td width="15%">{Locale("room-score", this.props.Locale)}<br />{this.GetScore(this.state.historyList_Final[0].Score)}</td>
                                                        {/* <td width="20%">{Locale("room-date-of-enter", this.props.Locale)}<br />{moment(this.state.historyList_Final[0].Date).format("ll")}</td> */}
                                                        <td width="20%">{Locale("room-date-of-enter", this.props.Locale)}<br />{this.GetDateEnter(this.state.historyList_Final[0])}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <span height="35px"></span>

                                        <div className="row custyle">
                                            <table className="table table-striped custab table-text" style={{ tableLayout: 'fixed', }}>
                                                <thead>
                                                    <tr>
                                                        {/* <th width="10%">#</th>
                                                        <th width="20%" style={{ textAlign: "left" }}>{Locale("room-code", this.props.Locale)}</th>
                                                        <th width="45%" style={{ textAlign: "left" }}>{Locale("room-title", this.props.Locale)}</th>
                                                        <th width="25%">{Locale("room-date", this.props.Locale)}</th> */}

                                                        {/* <th width="5%">#</th>
                                                        <th width="15%" style={{ textAlign: "left" }}>{Locale("room-code", this.props.Locale)}</th>
                                                        <th width="45%" style={{ textAlign: "left" }}>{Locale("room-title", this.props.Locale)}</th>
                                                        <th width="15%">{Locale("room-score", this.props.Locale)}</th>
                                                        <th width="20%">{Locale("room-date", this.props.Locale)}</th> */}

                                                        <th width="10%">#</th>
                                                        <th width="12%" style={{ textAlign: "left" }}>{Locale("room-code", this.props.Locale)}</th>
                                                        <th width="45%" style={{ textAlign: "left" }}>{Locale("room-title", this.props.Locale)}</th>
                                                        <th width="13%">{Locale("room-score", this.props.Locale)}</th>
                                                        <th width="20%">{Locale("room-date-of-enter", this.props.Locale)}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        this.state.historyList_Final.map((data, key) => {
                                                            //as request by Users to read all room history, agreed by Calvin, edited on 2023.08.23
                                                            return (this.GetHistoryListItemComponent(data, key));
                                                            // if (this.props.isFromParentApp === true) {
                                                            //     return (this.GetHistoryListItemComponent(data, key));
                                                            // }
                                                            // else {
                                                            //     if (key < 5) {
                                                            //         return (this.GetHistoryListItemComponent(data, key));
                                                            //     }
                                                            //     else {
                                                            //         return null;
                                                            //     }
                                                            // }
                                                        })
                                                    }
                                                </tbody>

                                            </table>
                                        </div>

                                        {
                                            // this.state.historyList_Local_LU !== null ?
                                            //     <>
                                            //         {
                                            //             this.props.isFromParentApp ?
                                            //                 null
                                            //                 :
                                            //                 <span style={{ fontSize: 12, float: 'left' }}><div dangerouslySetInnerHTML={{ __html: Locale("download-ikey-parent-for-complete-history", this.props.Locale) }}></div></span>
                                            //         }
                                            //         <span style={{ fontSize: 12, float: 'right' }}>{Locale("last-update", this.props.Locale)} : {this.state.historyList_Local_LU.split(' ')[0]}</span>
                                            //     </>
                                            //     :
                                            //     this.state.historyList_FireStore_LU !== null ?
                                            //         <>
                                            //             {
                                            //                 this.props.isFromParentApp ?
                                            //                     null :
                                            //                     <span style={{ fontSize: 12, float: 'left' }}><div dangerouslySetInnerHTML={{ __html: Locale("download-ikey-parent-for-complete-history", this.props.Locale) }}></div></span>
                                            //             }
                                            //             <span style={{ fontSize: 12, float: 'right' }}>{Locale("last-update", this.props.Locale)} : {this.state.historyList_FireStore_LU.split(' ')[0]}</span>
                                            //         </>
                                            //         :
                                            //         null
                                        }
                                        {
                                            this.state.historyList_Final.length > 0 ?
                                                <>
                                                    {/* {
                                                        this.props.isFromParentApp ?
                                                            null
                                                            :
                                                            <span style={{ fontSize: 12, float: 'left' }}><div dangerouslySetInnerHTML={{ __html: Locale("download-ikey-parent-for-complete-history", this.props.Locale) }}></div></span>
                                                    } */}
                                                    <span style={{ fontSize: 12, float: 'right' }}>{Locale("last-update", this.props.Locale)} : {moment(this.state.historyList_Final[0].Date).format('ll')}</span>
                                                </>
                                                : null
                                        }

                                    </article>
                                </div>

                                {/* <div className="tooltip bs-tooltip-top" role="tooltip">
                                    <div className="arrow"></div>
                                    <div className="tooltip-inner"> Some tooltip text! </div>
                                </div> */}
                            </>
                            :
                            <div className="card">
                                <article className="card-body">

                                    <center>
                                        <span style={{ fontSize: 14 }} >
                                            {
                                                //2025.03.12
                                                this.state.firstLoad ?
                                                    Locale("text-load-history-list", this.props.Locale)
                                                    :
                                                    Locale("text-history-list-is-empty", this.props.Locale)
                                            }
                                        </span>
                                        <button type="button" className="btn btn-outline btn1" title='refresh'
                                            onClick={async () => {
                                                this.setState({
                                                    historyList_Final: [],
                                                    isHistoryListSyncDone: false,
                                                    toggleHistoryList: false,
                                                });
                                                await this.props.SetHistoryListSyncState();
                                                this.LoadHistoryItems_ViaApi();
                                            }}
                                        ><i className="fa fa-refresh icon1"></i></button>
                                    </center>

                                </article>
                            </div>
                        // :
                        // this.state.historyList_Final.length > 0 ?
                        //     <div className="card">
                        //         <article className="card-body">
                        //             {this.state.historyList_Final.length}
                        //             <div className="row">
                        //                 <div className="col col-sm-12"><p>{Locale("processing", this.props.Locale)}</p></div>
                        //             </div>
                        //             <div className="row">
                        //                 <div className="col col-sm-12">
                        //                     <ProgressBar animated now={100} />
                        //                 </div>
                        //             </div>

                        //         </article>
                        //     </div>
                        :
                        <div className="card">
                            <article className="card-body">

                                <center>
                                    <span style={{ fontSize: 14 }} >
                                        {
                                            //2025.03.12
                                            this.state.firstLoad ?
                                                Locale("text-load-history-list", this.props.Locale)
                                                :
                                                Locale("text-history-list-is-empty", this.props.Locale)
                                        }
                                    </span>
                                </center>

                            </article>
                        </div>
                );
            }
        }
    }

}