import React from 'react';
// import moment from 'moment';
import { Lang } from '../Localization/CustomLocalization.js';
import { CheckObjectStringEmpty } from '../components/GlobalFunctions.js';
import { Button } from 'react-bootstrap';

export default class LocalizationSwitcher extends React.Component {

    // eslint-disable-next-line
    constructor(props) {
        super(props);

        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        MediaFileModal_Toggle: false,
    })

    SetLocaleSetting(setting) {
        this.props.SetLocaleSetting(setting);
    }

    TogglePreviewMediaFileModal = () => {
        this.setState({ MediaFileModal_Toggle: !this.state.MediaFileModal_Toggle });    //2025.03.24
    }

    render() {
        return (
            <table cellPadding="2" className="float-right">
                <tbody>
                    <tr>

                        {/* Media Files - added 2025.03.24 */}
                        {
                            Array.isArray(this.props.MediaFiles) ?
                                <td>
                                    <div className="icon-color pointer" style={{ margin: '0px 10px 0px -20px' }} title="Help files"
                                        onClick={() => {
                                            if (
                                                Array.isArray(this.props.MediaFiles)
                                                // && this.props.MediaFiles.length > 0
                                            ) {
                                                let mediaFileComponents = [];
                                                this.props.MediaFiles.forEach((item) => {
                                                    mediaFileComponents.push(<tr>
                                                        <td style={{ userSelect: 'none', textAlign: 'left' }}>{CheckObjectStringEmpty(item, 'Name', '-')}</td>
                                                        <td width={75} align="center"><Button variant='info' onClick={() => {
                                                            // this.TogglePreviewMediaFileModal(item);
                                                            const url = CheckObjectStringEmpty(item, 'FileLocation');
                                                            if (url !== '')
                                                                window.open(url, '_new');
                                                        }}>View</Button></td>
                                                    </tr>)
                                                });
                                                this.props.SetAlert('Help files', <table className="table table-hover table-borderless" style={{ marginBottom: 0 }}><tbody>{mediaFileComponents}</tbody></table>);
                                            }
                                        }}>
                                        {/* <span className="badge rounded-pill bg-info"
                                            style={{ height: 30, display: 'flex', gap: 5, alignItems: 'center', fontSize: 14, color: '#fff', padding: 10, border: '1px solid white' }}
                                        >User Guide<i className="fa fa-info-circle"></i></span> */}
                                        <span className="user-guide-span badge rounded-pill bg-info"><i className="fa fa-info-circle icon"></i>User<br />Guide</span>
                                    </div>
                                </td>
                                : null
                        }

                        {/* Language Buttons */}
                        <td>
                            <button type="button"
                                className={this.props.Locale === Lang.English ? "btn btn-primary btn-block btn-xs" : "btn btn-outline-primary btn-xs"}
                                onClick={() => this.SetLocaleSetting(Lang.English)}
                                style={{
                                    backgroundColor: this.props.Locale === Lang.English ? "#007bff" : "white",
                                    borderColor: "white",
                                }}
                            ><span style={{ color: this.props.Locale === Lang.English ? "white" : "#007bff" }}>EN</span></button>
                        </td>
                        <td>
                            <button type="button"
                                className={this.props.Locale === Lang.Chinese ? "btn btn-primary btn-block btn-xs" : "btn btn-outline-primary btn-xs"}
                                onClick={() => this.SetLocaleSetting(Lang.Chinese)}
                                style={{
                                    backgroundColor: this.props.Locale === Lang.Chinese ? "#007bff" : "white",
                                    borderColor: "white",
                                }}
                            ><span style={{ color: this.props.Locale === Lang.Chinese ? "white" : "#007bff" }}>CN</span></button>
                        </td>
                        <td>
                            <button type="button"
                                className={this.props.Locale === Lang.Malay ? "btn btn-primary btn-block btn-xs" : "btn btn-outline-primary btn-xs"}
                                onClick={() => this.SetLocaleSetting(Lang.Malay)}
                                style={{
                                    backgroundColor: this.props.Locale === Lang.Malay ? "#007bff" : "white",
                                    borderColor: "white",
                                }}
                            ><span style={{ color: this.props.Locale === Lang.Malay ? "white" : "#007bff" }}>BM</span></button>
                        </td>
                    </tr>

                    {/* <tr>
                        <td colSpan={3} align='center' style={{ padding: 0 }}>
                            <span
                                style={{ fontSize: '12px', color: 'white', fontWeight: "normal", }}
                            >Login: {moment().format('lll')}</span>
                        </td>
                    </tr> */}

                </tbody>
            </table >
        )
    }
}